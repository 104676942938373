import { Route, Routes } from '@solidjs/router';
import { ReportsProvider, LeasesListProvider } from '~/contexts/local';
import { BankAccountActivityReportPage } from '~/pages/reports/BankAccountActivityReport';
import { OwnerStatementReportPage } from '~/pages/reports/OwnerStatementReportPage';
import { RentRollReportPage } from '~/pages/reports/RentRollReportPage';
import { BalanceSheetReportPage } from './BalanceSheetReportPage';
import { CashFlowReportPage } from './CashFlowReportPage';
import { GeneralLedgerReportPage } from './GeneralLedgerReportPage';
import { IncomeStatementReportPage } from './IncomeStatementReportPage';
import { LeaseUnpaidBillsReportPage } from './LeaseUnpaidBillsReportPage';
import { ListReports } from './ListReports';
import { OwnerDirectoryReportPage } from './OwnerDirectoryReport';
import { PropertyDirectoryReportPage } from './PropertyDirectoryReport';
import { TenantDirectoryReportPage } from './TenantDirectoryReport';

const ReportsRoutes = () => {
  return (
    <ReportsProvider>
      <LeasesListProvider>
        <Routes>
          <Route path="/cash-flow" component={CashFlowReportPage} />
          <Route path="/general-ledger" component={GeneralLedgerReportPage} />
          <Route path="/balance-sheet" component={BalanceSheetReportPage} />
          <Route path="/income-statement" component={IncomeStatementReportPage} />
          <Route path="/owner-statement" component={OwnerStatementReportPage} />
          <Route path="/rent-roll" component={RentRollReportPage} />
          <Route path="/property-directory" component={PropertyDirectoryReportPage} />
          <Route path="/owner-directory" component={OwnerDirectoryReportPage} />
          <Route path="/tenant-directory" component={TenantDirectoryReportPage} />
          <Route path="/bank-account-activity" component={BankAccountActivityReportPage} />
          <Route path="/lease-unpaid-bills" component={LeaseUnpaidBillsReportPage} />
          <Route path="*" component={ListReports} />
        </Routes>
      </LeasesListProvider>
    </ReportsProvider>
  );
};

export default ReportsRoutes;

import { createContext, useContext, createSignal } from 'solid-js';
import type { JSX } from 'solid-js';

type NotificationModalContextType = {
  getChatFiles: () => File[];
  getEmailFiles: () => File[];
  setChatFiles: (files: File[]) => void;
  setEmailFiles: (files: File[]) => void;
};

const NotificationModalContext = createContext<NotificationModalContextType | undefined>(undefined);

export const NotificationModalProvider = (props: { children: JSX.Element }) => {
  const [chatFiles, setChatFiles] = createSignal<File[]>([]);
  const [emailFiles, setEmailFiles] = createSignal<File[]>([]);

  return (
    <NotificationModalContext.Provider
      value={{
        getChatFiles: () => chatFiles() || [],
        getEmailFiles: () => emailFiles() || [],
        setChatFiles,
        setEmailFiles,
      }}>
      {props.children}
    </NotificationModalContext.Provider>
  );
};

export const useNotificationModal = () => {
  const context = useContext(NotificationModalContext);
  if (!context) {
    throw new Error('useNotificationModal must be used within a NotificationModalProvider');
  }
  return context;
};

import { Route, Routes, useParams } from '@solidjs/router';
import { EmailDetailsModal } from '~/components/emails/EmailModal';
import { EmailsListWithType } from '~/components/emails/EmailsListWithType';

export const OwnersEmailsTab = () => {
  const params = useParams<{ ownerId: string }>();

  return (
    <>
      <EmailsListWithType id={params.ownerId} type="owner" />
      <Routes>
        <Route path="/:emailId" element={<EmailDetailsModal />} />
      </Routes>
    </>
  );
};

import { createSignal, For, Show, mergeProps, createEffect } from 'solid-js';
import type { Component } from 'solid-js';
import type { DataPathNode } from '~/pdfsigner/usecases/types/dataPathNode';

const ToggleButton: Component<{ expanded: boolean; onClick: () => void }> = (props) => {
  return (
    <button onClick={() => props.onClick()} class="mr-1">
      {props.expanded ? '-' : '+'}
    </button>
  );
};

const TreeDropdown: Component<{
  node: DataPathNode;
  level?: number;
  onSelect: (node: DataPathNode, path: string) => void;
  selectedPath: string;
  currentPath: string;
}> = (_props) => {
  const props = mergeProps({ level: 0 }, _props);
  const [expanded, setExpanded] = createSignal<boolean>(false);

  const toggleExpand = () => {
    setExpanded(!expanded());
  };

  const getFullPath = () => {
    return props.currentPath ? `${props.currentPath}/${props.node.key}` : props.node.key;
  };

  createEffect(() => {
    setExpanded(props.selectedPath.includes(getFullPath()));
  });

  const isSelected = () => {
    return getFullPath() === props.selectedPath;
  };

  return (
    <div class={`ml-${props.level}`}>
      <div class="flex items-center">
        <Show when={props.node.children && props.node.children.length > 0}>
          <ToggleButton expanded={expanded()} onClick={toggleExpand} />
        </Show>
        <Show
          when={props.node.canSelect}
          fallback={
            <span onClick={toggleExpand} class={`grow cursor-pointer p-1 ${isSelected() ? 'bg-gray-200' : 'bg-white'}`}>
              {props.node.name}
            </span>
          }>
          <button
            onClick={() => props.onSelect(props.node, getFullPath())}
            class={`flex-1 p-2 text-left ${isSelected() ? 'bg-gray-200' : 'bg-white'} hover:bg-gray-200 `}>
            {props.node.name}
          </button>
        </Show>
      </div>
      <Show when={expanded() && props.node.children && props.node.children.length > 0}>
        <div class={`ml-2`}>
          <For each={props.node.children}>
            {(child) => (
              <TreeDropdown
                node={child}
                level={props.level + 1}
                onSelect={props.onSelect}
                selectedPath={props.selectedPath}
                currentPath={getFullPath()}
              />
            )}
          </For>
        </div>
      </Show>
    </div>
  );
};

export const DropdownWithTree: Component<{
  data?: DataPathNode[];
  class?: string;
  onSelect?: (node: DataPathNode) => void;
  selectedNode?: DataPathNode;
  enabled?: boolean;
}> = (props) => {
  const [selectedNode, setSelectedNode] = createSignal<DataPathNode>();
  const [selectedPath, setSelectedPath] = createSignal<string>('');
  const [dropdownOpen, setDropdownOpen] = createSignal(false);

  const handleSelect = (node: DataPathNode, path: string) => {
    setSelectedNode(node);
    setSelectedPath(path);
    setDropdownOpen(false);
    props.onSelect?.(node);
  };

  const findPath = (node: DataPathNode, targetNode: DataPathNode, currentPath: string): string | null => {
    if (node.key === targetNode.key) return currentPath;
    if (node.children) {
      for (const child of node.children) {
        const path = findPath(child, targetNode, `${currentPath}/${child.key}`);
        if (path) return path;
      }
    }
    return null;
  };

  createEffect(() => {
    if (props.enabled === false) {
      setDropdownOpen(false);
      return;
    }
    if (props.selectedNode) {
      for (const rootNode of props.data || []) {
        const path = findPath(rootNode, props.selectedNode, rootNode.key);
        if (path) {
          setSelectedNode(props.selectedNode);
          setSelectedPath(path);
          break;
        }
      }
    }
  });

  return (
    <div class={`relative ${props.class ?? ''}`}>
      <div
        class={`flex size-full cursor-pointer items-center justify-between p-1 text-left ${
          props.enabled === false ? 'cursor-not-allowed' : ''
        }`}
        onClick={() => setDropdownOpen(!dropdownOpen())}>
        <span>{selectedNode() ? selectedNode()?.name : ' '}</span>
        <span class={`ml-2 transition-transform ${dropdownOpen() ? 'rotate-180' : ''}`}>
          <svg class="size-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </span>
      </div>
      <Show when={dropdownOpen() && props.data && props.data.length > 0}>
        <div class="absolute left-0 top-full mt-2 w-full border border-gray-300 bg-white p-2 shadow-lg">
          <For each={props.data}>
            {(node) => <TreeDropdown node={node} onSelect={handleSelect} selectedPath={selectedPath()} currentPath="" />}
          </For>
        </div>
      </Show>
    </div>
  );
};

import { Show } from 'solid-js';
import { Markdown } from '~/components/common/Mardown';
import { Skeleton } from '~/components/ui';
import { useRunBooks } from '~/contexts/local';

export const MaintenanceAiRunbookTab = () => {
  const { runBook } = useRunBooks();

  return (
    <>
      <div class="rounded-lg bg-white p-6 text-text-level02">
        <Show
          when={!runBook.loading && runBook()}
          fallback={
            <div class="flex flex-col gap-3">
              <Skeleton class="w-20" />
              <Skeleton class="w-46" />
              <Skeleton class="w-46" />
            </div>
          }>
          <Markdown content={runBook()?.script ?? ''} />
        </Show>
      </div>
    </>
  );
};

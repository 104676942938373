import { A } from '@solidjs/router';
import { createMemo, For, Show } from 'solid-js';
import ImgNoLease from '~/assets/images/empty/noLease.png';
import { TinToggle } from '~/components/TinToggle';
import { Button } from '~/components/common/Buttons';
import DisplayAddress from '~/components/common/DisplayAddress';
import IconField from '~/components/common/IconField';
import UnderConstruction from '~/components/common/UnderConstruction';
import { getDeposit } from '~/components/leases/utils';
import { TenantTitle } from '~/components/tenants';
import {
  IconCalendar,
  IconChevronRight,
  IconCircleChevronRight,
  IconCircleEllipsis,
  IconHash,
  IconHistory,
  IconLanguages,
  IconMail,
  IconPhone,
  IconSquarePen,
  Skeleton,
} from '~/components/ui';
import { UnitImage } from '~/components/units';
import { useLocalization, useTranslations } from '~/contexts/global';
import { IconLeaseBackground } from '~/pages/tenants/assets';
import { cn } from '~/utils/classnames';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import { ActivityEmpty, ActivityItem, ActivitySkeleton } from './TenantActivity';
import { TenantPortalStatus } from './TenantPortalStatus';
import type { Component } from 'solid-js';

const InfoSection: Component<{ class?: string; loading?: boolean; tenant?: MagicDoor.Api.HydratedTenantDto }> = (props) => {
  const { t } = useLocalization();
  const { languages } = useTranslations();
  const language = createMemo(() => languages()?.find((l) => l.code === props.tenant?.language));

  return (
    <div class="divide-y divide-partingline overflow-hidden rounded-lg border border-partingline bg-white">
      <div class="space-y-1 px-5 py-4">
        <div class="flex items-center gap-2">
          <TenantTitle feedbackIconMode="col" loading={props.loading} tenant={props.tenant} />
          <A href={`/users/tenants/${props.tenant?.id}/edit`} class="ml-auto">
            <IconSquarePen class="size-5 text-link" />
          </A>
        </div>
      </div>
      <div class="flex flex-wrap gap-5 p-4 *:flex-1 *:basis-56">
        <IconField loading={props.loading} name={t('Phone')} value={props.tenant?.phone} src={IconPhone} />
        <IconField loading={props.loading} name={t('Email')} value={props.tenant?.email} src={IconMail} />
        <IconField
          loading={props.loading}
          name={t('Date of birth')}
          value={props.tenant?.dateOfBirth ? dateFormat('MM/DD/YYYY', props.tenant?.dateOfBirth) : '--'}
          src={IconCalendar}
        />
        <IconField
          loading={props.loading}
          name={t('Social security')}
          value={
            <Show when={props.tenant?.ssn} fallback="--">
              <TinToggle number={props.tenant?.ssn as string} type="ssn" />
            </Show>
          }
          src={IconHash}
        />
        <IconField
          loading={props.loading}
          name={t('Driver license number')}
          value={
            <Show when={props.tenant?.driversLicense?.number} fallback="--">
              <TinToggle number={props.tenant?.driversLicense?.number || ''} type="others" />
            </Show>
          }
          src={IconHash}
        />
        <IconField loading={props.loading || language() == null} name={t('Language')} value={language()?.name} src={IconLanguages} />
        <IconField
          loading={props.loading}
          name={t('Last seen')}
          value={props.tenant?.lastSeen ? dateFormat('MM/DD/YYYY', props.tenant.lastSeen) : '--'}
          src={IconHistory}
        />
      </div>
      <div class="flex items-center justify-between px-5 py-4 text-xs">
        <span class="text-sm font-medium text-title-gray">{t('Portal status')}</span>
        <Show when={!props.loading} fallback={<Skeleton class="w-20 rounded-full" />}>
          <TenantPortalStatus status={props.tenant?.portalStatus} />
        </Show>
      </div>
    </div>
  );
};

const CurrentLeaseSection: Component<{ class?: string; loading?: boolean; tenant?: MagicDoor.Api.HydratedTenantDto }> = (props) => {
  const { t } = useLocalization();
  const currentLease = createMemo(() => {
    let result: any;
    if (props.tenant?.currentLeases && props.tenant?.currentLeases.length) {
      result = {
        ...props.tenant?.currentLeases.find((lease) => lease.id === props.tenant?.primaryLeaseId),
        unit: props.tenant.primaryLeaseUnit,
        property: props.tenant.primaryLeaseProperty,
      };
    }
    return result;
  });
  return (
    <div class="divide-y divide-partingline overflow-hidden rounded-lg border border-partingline bg-white">
      <div class="relative flex items-center bg-green px-6 py-5 text-white">
        <IconLeaseBackground class="pointer-events-none absolute inset-y-0 right-0" />
        <div class="flex-1 text-lg font-semibold capitalize">{t('Current lease')}</div>
        <A href="#" class="flex cursor-not-allowed items-center gap-1" title={t('Under construction')}>
          <IconCircleEllipsis class="size-4" />
        </A>
      </div>
      <div class="space-y-5 px-6 py-5 text-sm">
        <Show
          when={!props.loading}
          fallback={
            <>
              <Skeleton class="h-15" />
              <div class="space-y-3">
                <Skeleton />
                <Skeleton />
              </div>
            </>
          }>
          <Show
            when={currentLease()}
            fallback={
              <div class="flex flex-col items-center">
                <img class="h-20" src={ImgNoLease} alt="ImgNoLease" />
                <h4 class="mt-5 text-sm text-text-level02">{t('No lease yet')}</h4>
                <p class="mt-0.5 text-xs text-text-level03">{t('Click the button below to create one')}</p>
                <Button class="mt-3 w-40" href={`/leasing/leases/new?tenantId=${props.tenant?.id}`}>
                  {t('Set up a lease')}
                </Button>
              </div>
            }>
            <A
              href={`/leasing/leases/${currentLease().id}`}
              class="flex items-center justify-between gap-2 rounded-lg bg-input p-2 text-text-level02">
              <UnitImage class="size-11 shrink-0" unit={currentLease()?.unit} data-slot="image" />
              <div class="line-clamp-2 flex-1 space-y-1">
                <div class="text-text-level01">{currentLease().unit?.name ? `# ${currentLease()?.unit?.name}` : '--'}</div>
                <DisplayAddress class="text-xs" address={currentLease()?.property?.address} />
              </div>
              <IconChevronRight class="size-5 shrink-0" />
            </A>
            <div class="space-y-3">
              <div class="flex justify-between">
                <div class="text-text-level02">{t('Monthly rent')}</div>
                <div class="font-medium text-title-gray">
                  {currentLease()?.currentRent != null ? currency(currentLease()?.currentRent) : '-'}
                </div>
              </div>
              <div class="flex justify-between">
                <div class="text-text-level02">{t('Deposit')}</div>
                <div class="font-medium text-title-gray">{getDeposit(currentLease())}</div>
              </div>
              <div class="flex justify-between">
                <div class="text-text-level02">{t('Tenancy term')}</div>
                <div class="font-medium text-title-gray">
                  <Show when={currentLease()?.start} fallback={t('-')}>
                    {`${dateFormat('MM/DD/YYYY', currentLease()?.start)} - ${
                      currentLease()?.end ? dateFormat('MM/DD/YYYY', currentLease()?.end) : t('No end date')
                    }`}
                  </Show>
                </div>
              </div>
            </div>
          </Show>
        </Show>
      </div>
    </div>
  );
};

const ActivitySection: Component<{ class?: string; loading?: boolean; tenant?: MagicDoor.Api.HydratedTenantDto }> = (props) => {
  const { t } = useLocalization();

  const activities = [
    { type: 'update', log: 'Logged a request for a leaking kitchen faucet', datetime: '2024-05-16T12:11:00' },
    { type: 'pay', log: 'remaining for Lease renewal', datetime: '2024-05-15T21:22:00' },
    { type: 'pay', log: 'just paid 1500 rent payment', datetime: '2024-05-15T21:22:00' },
    { type: 'chat', log: 'just paid 1500 rent payment', datetime: '2023-08-10T15:33:00' },
  ] as const;

  return (
    <div class="hidden divide-y divide-partingline overflow-hidden rounded-lg border border-partingline bg-white lg:block">
      <div class="flex items-center px-6 py-5">
        <div class="flex-1 text-lg font-semibold text-text-level02">{t('Activity')}</div>
        <A href="#" class="flex cursor-not-allowed items-center gap-1 text-xs text-link" title={t('Under construction')}>
          {t('Detail')}
          <IconCircleChevronRight class="size-3.5 text-link" />
        </A>
      </div>
      <div class="relative flex flex-col gap-6 px-6 py-5 before:absolute before:ml-[18px] before:h-full before:w-px before:bg-slate-200">
        <Show when={!props.loading} fallback={<ActivitySkeleton />}>
          <Show when={activities.length > 0} fallback={<ActivityEmpty />}>
            <For each={activities}>{(activity) => <ActivityItem activity={activity} />}</For>
          </Show>
        </Show>
        <UnderConstruction />
      </div>
    </div>
  );
};

export const TenantSummary: Component<{ class?: string; loading?: boolean; tenant?: MagicDoor.Api.HydratedTenantDto }> = (props) => (
  <div class={cn('flex flex-col gap-4', props.class)}>
    <InfoSection loading={props.loading} tenant={props.tenant} />
    <CurrentLeaseSection loading={props.loading} tenant={props.tenant} />
    <ActivitySection loading={props.loading} tenant={props.tenant} />
  </div>
);

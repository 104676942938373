import { createMemo, createSignal, Show } from 'solid-js';
// import IconAddress from '~/assets/images/contact/address.svg?component-solid';
// import IconCall from '~/assets/images/contact/call.svg?component-solid';
import IconEmail from '~/assets/images/contact/email.svg?component-solid';
import IconAccountInformation from '~/assets/images/settings/accountInformation.svg';
// import IconJob from '~/assets/images/settings/jobTitle.svg?component-solid';
import IconUser from '~/assets/images/settings/user.svg?component-solid';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { IconLoader } from '~/components/ui';
import { useLocalization, useAuth } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { AvatarEditor } from './components/AvatarEditor';
import { InformationForm } from './components/InformationForm';
import { InformationOverview } from './components/InformationOverview';
import type { Section } from './types';

export const AccountInformation = () => {
  const { t } = useLocalization();
  const [editing, setEditing] = createSignal<boolean>(false);
  const { me, updateProfile, updateAvatar } = useAuth();

  const sections = createMemo<Section[]>(() => {
    const model = me();
    if (model == null) return [];
    return [
      {
        field: 'firstName',
        value: model.firstName,
        label: 'First name',
        icon: IconUser,
        required: true,
        rules: [
          { required: true, message: t('Please input first name') },
          { length: 150, message: t('{name} must be {length} characters or less', { name: 'First name', length: '150' }) },
        ],
      },
      { field: 'lastName', value: model.lastName, label: 'Last name', icon: IconUser },
      // { field: 'jobTitle', value: model.jobTitle, label: 'Job title', icon: IconJob },
      {
        field: 'email',
        value: model.email,
        label: 'Email',
        icon: IconEmail,
        rules: [
          { required: true, message: t('Please input email') },
          { type: 'email', message: t('Email is not valid') },
        ],
      },
      // { field: 'phone', value: model.phone, label: 'Phone', icon: IconCall },
      // { field: 'address', value: model.address, label: 'Address', icon: IconAddress },
      // { field: 'memo', value: model.address, label: 'Address', icon: IconAddress, class: 'col-span-2' },
    ];
  });

  return (
    <ThreeSectionBox
      coloredClass="border-input-border px-6"
      topTitle={<SettingTitle icon={IconAccountInformation} title={t('Information')} />}
      middle={
        <div class="flex flex-col gap-6 px-6 py-5">
          <Show when={me()} fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
            {(profile) => (
              <>
                <div class="flex items-center justify-start gap-3">
                  <AvatarEditor avatar={profile().avatar} name={profile().name} onChange={updateAvatar} />
                  <div class="flex flex-col text-left">
                    <div class="text-xl	font-semibold text-[#333]">{profile().name}</div>
                    <div class="text-xs text-text-level03">{profile().jobTitle}</div>
                  </div>
                </div>
                <Show when={editing()} fallback={<InformationOverview sections={sections()} onEdit={() => setEditing(true)} />}>
                  <InformationForm sections={sections()} onClose={() => setEditing(false)} onSubmit={updateProfile} />
                </Show>
              </>
            )}
          </Show>
        </div>
      }
    />
  );
};

import IconArchiveBg from '~/assets/images/units/archiveBg.svg';
import { MessageModal } from '~/components/modals/MessageModal';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
import type { MessageModalProps } from '~/components/modals/MessageModal';

type ArchiveUnitModalProps = Pick<MessageModalProps, 'visible' | 'onClose'> & {
  loading?: boolean;
  onArchive: () => void;
};

export const ArchiveUnitModal: Component<ArchiveUnitModalProps> = (props) => {
  const { t } = useLocalization();
  return (
    <MessageModal
      title={t('Archive Unit')}
      doneText={t('Archive')}
      visible={props.visible}
      loading={props.loading}
      onClose={props.onClose}
      onDone={props.onArchive}>
      <img src={IconArchiveBg} />
      <div class="h-24 p-6 text-sm leading-5 text-text-level02">{t('Archive_unit_modal_message')}</div>
    </MessageModal>
  );
};

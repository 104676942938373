import { BaseRestRepository } from './baseRestRepository';

const LEASES_URL = '/api/leases';

export class LeaseTenantRepository extends BaseRestRepository {
  public async deleteLeaseTenant(leaseId: string, tenantId: string) {
    const url = `${LEASES_URL}/${leaseId}/tenants/${tenantId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }
}

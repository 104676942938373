export const chartOfAccountNameMap = new Map([
  ['Rent', 'Rent'],
  ['Utilities', 'Utilities'],
  ['Late Fees', 'Late fees'],
  ['Security Deposit', 'Security deposit'],
  ['Processing Fees', 'Processing fees'],
  ['Return Fees', 'Return fees'],
  ['Credit Reports', 'Credit reports'],
  ['Rental Applications', 'Rental applications'],
  ['Stripe Distribute Fee', 'Stripe distribute fee'],
  ['Utilities', 'Utilities'],
  ['Electricity', 'Electricity'],
  ['Water', 'Water'],
  ['Gas', 'Gas'],
  ['Sewage', 'Sewage'],
  ['Repairs', 'Repairs'],
  ['Plumbing', 'Plumbing'],
  ['Electrical', 'Electrical'],
  ['Other', 'Other'],
]);

export const chartOfAccountTypeMap: Record<`${MagicDoor.Api.ChartOfAccountType}`, string> = {
  asset: 'Asset',
  equity: 'Equity',
  liability: 'Liability',
  revenue: 'Revenue',
  expense: 'Expense',
  capital: 'Capital',
};

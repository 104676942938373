import { Show } from 'solid-js';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization, useVendors } from '~/contexts/global';

const VendorMemo = () => {
  const { t } = useLocalization();
  const { current } = useVendors();

  return (
    <ThreeSectionBox
      topTitle={<div>{t('Memo')}</div>}
      middle={
        <Show
          when={current()?.memo}
          fallback={<div class="flex h-16 items-center px-4 text-text-level03"> {t('No notes from customer')}</div>}>
          <div class="flex items-center break-all p-4 text-base text-text-level01">{current()?.memo}</div>
        </Show>
      }
    />
  );
};

export default VendorMemo;

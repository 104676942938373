import { FormItem, FormWrapper } from '~/components/common/BetterForm/Form';
import { Button } from '~/components/common/Buttons';
import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
import type { BetterForm } from '~/components/common/BetterForm';

interface BankAccountFormProps {
  onSubmit: () => Promise<void> | void;
  title?: string;
  form: BetterForm.form;
}

function BankAccountForm(props: BankAccountFormProps) {
  const { t } = useLocalization();

  return (
    <FormWrapper
      defaultForm={props.form}
      class="flex  size-full flex-col items-center justify-center"
      onFormSubmit={() => {
        props.onSubmit();
      }}>
      <div class="flex w-full justify-center gap-4 py-4">
        <div class="w-2/3">
          <FormItem
            formFieldName="name"
            class="mb-6"
            label={t('Name')}
            placeholder={t('Please enter the name')}
            component={LabeledTextInput}
            rules={[
              {
                required: true,
                message: t('Please enter the name'),
              },
              {
                length: 200,
                message: t('The name must be less than 200 characters'),
              },
            ]}
          />
          <FormItem
            formFieldName="description"
            placeholder={t('Please enter')}
            label={t('Description')}
            rows={5}
            component={LabeledTextArea}
          />
        </div>
      </div>
      <div class="sticky bottom-0 mt-auto flex w-full justify-end gap-2 border-t border-partingline bg-white px-6 py-5">
        <Button disabled={!props.form.isValidate} type="submit">
          {t('Submit')}
        </Button>
      </div>
    </FormWrapper>
  );
}

export default BankAccountForm;

import { useParams } from '@solidjs/router';
import dayjs from 'dayjs';
import { createEffect, createMemo, createSignal, Show } from 'solid-js';
import { AIScoreCell } from '~/components/common/AiScoreCell';
import { Avatar } from '~/components/common/Avatar';
import { Empty } from '~/components/common/Empty';
import { Panel } from '~/components/common/Panels';
import { MaintenanceRequestUrgency } from '~/components/maintenance-requests';
import { ListView, Select } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequests } from '~/contexts/local';
import { useLocalPagination } from '~/hooks';
import { dateFormat, formatAgo, getDateRange, checkAuditTime } from '~/utils/date';

export const TenantMaintenanceTab = () => {
  const { t } = useLocalization();
  const params = useParams();
  const { maintenanceRequests, getMaintenanceRequests } = useMaintenanceRequests();
  const {
    page: currentListPage,
    pageSize: currentListPageSize,
    setPage: setCurrentListPage,
    setPageSize: setCurrentListPageSize,
  } = useLocalPagination('CurrentMaintenanceRequestsListView');
  const {
    page: completeListPage,
    pageSize: completeListPageSize,
    setPage: setCompleteListPage,
    setPageSize: setCompleteListPageSize,
  } = useLocalPagination('CompletedMaintenanceRequestsListView');

  const [urgency, setUrgency] = createSignal<number | string>('');
  const [completedUrgency, setCompletedUrgency] = createSignal<number | string>('');
  const [time, setTime] = createSignal<number | string>('');

  const urgencys = createMemo(() => [
    { label: t('All'), value: '' },
    { label: t('None'), value: 'none' },
    { label: t('Low'), value: 'low' },
    { label: t('Medium'), value: 'medium' },
    { label: t('High'), value: 'high' },
    { label: t('Urgent'), value: 'urgent' },
  ]);

  const currentMaintenanceRequest = createMemo(() => {
    return maintenanceRequests()
      ?.items.filter((item) => item.status !== 'closed')
      .filter((item) => (urgency() ? item.urgency === urgency() : true));
  });

  const timeOptions = createMemo(() => [
    { label: t('All'), value: '' },
    { label: t('Last 30 days'), value: '30' },
    { label: t('Last 6 months'), value: '183' },
    { label: t('Last year'), value: '365' },
  ]);

  const completedMaintenanceRequest = createMemo(() => {
    return maintenanceRequests()
      ?.items.filter((item) => item.status === 'closed')
      .filter((item) => (completedUrgency() ? item.urgency === completedUrgency() : true))
      .filter((item) => {
        if (!time()) return true;
        const nowDate = dayjs();
        const dateRange = getDateRange(nowDate, Number(time()), true);
        return checkAuditTime(dateRange[0], dateRange[1], item.lastUpdated);
      });
  });

  createEffect(() => getMaintenanceRequests({ tenantId: params.tenantId, pageSize: 100 }));

  return (
    <>
      <Show when={!maintenanceRequests()?.totalCount && !maintenanceRequests.loading}>
        <Panel>
          <Empty description={t("There's no record of the maintenance request")} />
        </Panel>
      </Show>
      <Show when={maintenanceRequests()?.totalCount || maintenanceRequests.loading}>
        <ListView
          class="mb-4"
          page={currentListPage()}
          pageSize={currentListPageSize()}
          onChange={({ page, pageSize }) => {
            setCurrentListPage(page);
            setCurrentListPageSize(pageSize);
          }}
          title={t('Current maintenance request')}
          titleActions={<Select class="max-w-28 text-xs" options={urgencys} prefix={t('Filter')} value={urgency()} onChange={setUrgency} />}
          loading={maintenanceRequests.loading}
          columns={[
            {
              title: t('Priority'),
              render: (row) => <MaintenanceRequestUrgency urgency={row.urgency} />,
            },
            {
              title: t('Title'),
              render: (row) => (
                <>
                  <div class="mb-1 font-medium">{row.title}</div>
                  <div class="text-gray-400">#{row.id}</div>
                </>
              ),
            },
            {
              title: t('Status'),
              render: (row) => <div class="text-success">{row.status}</div>,
            },
            {
              title: t('Assigned to'),
              render: (row) => (
                <Show when={row?.propertyManager} fallback={<div>-</div>}>
                  <div class="flex">
                    <Avatar name={row?.propertyManager?.name || ''} />
                    <div class="ml-2">
                      <div class="text-nowrap">{row?.propertyManager?.name || ''}</div>
                      <div class="text-gray-400">Manager</div>
                    </div>
                  </div>
                </Show>
              ),
            },
            {
              title: t('AI score'),
              render: (row) => <AIScoreCell score={row.aiUrgencyScore} />,
            },
          ]}
          data={currentMaintenanceRequest()}
          rowLink={(row) => `/maintenance/maintenance-request/${row.id}`}
        />
        <ListView
          page={completeListPage()}
          pageSize={completeListPageSize()}
          onChange={({ page, pageSize }) => {
            setCompleteListPage(page);
            setCompleteListPageSize(pageSize);
          }}
          title={t('Completed maintenance request')}
          titleActions={
            <>
              <Select
                class="max-w-28 text-xs"
                options={urgencys}
                prefix={t('Filter')}
                value={completedUrgency()}
                onChange={setCompletedUrgency}
              />
              <Select class="max-w-28 text-xs" options={timeOptions()} prefix={t('Time')} value={time()} onChange={setTime} />
            </>
          }
          columns={[
            {
              title: t('Priority'),
              render: (row) => <MaintenanceRequestUrgency urgency={row.urgency} />,
            },
            {
              title: t('Title'),
              render: (row) => (
                <>
                  <div class="mb-1 font-medium">{row.title}</div>
                  <div class="text-gray-400">#{row.id}</div>
                </>
              ),
            },
            {
              title: t('Assigned to'),
              render: (row) => (
                <Show when={row?.propertyManager} fallback={<div>-</div>}>
                  <div class="flex">
                    <Avatar name={row.propertyManager?.name || ''} />
                    <div class="ml-2">
                      <div class="text-nowrap">{row.propertyManager?.name}</div>
                      <div class="text-gray-400">Manager</div>
                    </div>
                  </div>
                </Show>
              ),
            },
            {
              title: t('Last updated'),
              render: (row) => (
                <div>
                  <div class="mb-1 font-medium">{dateFormat(t('MM/DD/YYYY hh:mm A'), row.lastUpdated)}</div>
                  <div class="text-gray-400">{formatAgo(row.lastUpdated, 2)}</div>
                </div>
              ),
            },
          ]}
          loading={maintenanceRequests.loading}
          data={completedMaintenanceRequest()}
          rowLink={(row) => `/maintenance/maintenance-request/${row.id}`}
        />
      </Show>
    </>
  );
};

import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

export type ChecksFilter = {
  page?: number;
  pageSize?: number;
  checkIdentity?: `${MagicDoor.Api.CheckIdentity}`;
  vendorId?: string;
  ownerId?: string;
  bankAccountId?: string;
  memo?: string;
  transactionId?: string;
};

export const BASE_URL = '/api/checks';
export class ChecksRepository extends BaseRestRepository {
  public async getChecks(filter?: ChecksFilter): Promise<MagicDoor.Api.PaginatedChecksDto> {
    const url = urlWithQuery(BASE_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getCheck(checkId: string): Promise<MagicDoor.Api.HydratedCheckDto> {
    const url = `${BASE_URL}/${checkId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async deleteCheck(checkId: string): Promise<void> {
    const url = `${BASE_URL}/${checkId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }
  public async getNextCheckNumber(): Promise<MagicDoor.Api.NextCheckNumberDto> {
    const url = `${BASE_URL}/next-check-number`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async updateNextCheckNumber(payload: MagicDoor.Api.NextCheckNumberDto): Promise<void> {
    const url = `${BASE_URL}/next-check-number`;
    await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
  }
}

import { Route, Routes } from '@solidjs/router';
import { AnnouncementProvider } from '~/contexts/local';
import { ListAnnouncements } from './ListAnnouncements';
import { AddAnnouncement } from './add-announcement';
import { AnnouncementDetails } from './detail-announcement/AnnouncementDetails';

const AnnouncementRoutes = () => {
  return (
    <AnnouncementProvider>
      <Routes>
        <Route path="*" component={ListAnnouncements} />
        <Route path="add" component={AddAnnouncement} />
        <Route path=":id" component={AnnouncementDetails} />
      </Routes>
    </AnnouncementProvider>
  );
};

export default AnnouncementRoutes;

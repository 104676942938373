import { createMemo, Show } from 'solid-js';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
import { verifyRequiredValidator } from '~/utils/validations';
import { MessageInput } from './MesssageInput';
import type { Component } from 'solid-js';

type ContentProps = {
  model: any;
  onUpdate: (updates: Partial<any>) => void;
  errors: any;
};

export const Content: Component<ContentProps> = (props) => {
  const { t } = useLocalization();

  const chatMessageError = createMemo(() =>
    props.model.chatMessage !== '' ? verifyRequiredValidator(props.model.chatMessage, 'Chat Message', true) : undefined
  );
  const emailMessageError = createMemo(() =>
    props.model.emailMessage !== '' ? verifyRequiredValidator(props.model.emailMessage, 'Email Message', true) : undefined
  );

  const handleFilesChange = (type: 'chat' | 'email') => (files: File[]) => {
    props.onUpdate({ [`${type}Attachments`]: files.length > 0 ? files : undefined });
  };

  return (
    <div class="flex flex-col gap-4">
      <h4 class="text-lg font-semibold">{t('Content')}</h4>

      <MessageInput
        rows={4}
        type="chat"
        label={t('Chat message')}
        value={props.model.chatMessage}
        onInput={(value: any) => props.onUpdate({ chatMessage: value || undefined })}
        error={chatMessageError()}
        onFilesChange={handleFilesChange('chat')}
      />

      <MessageInput
        rows={4}
        type="email"
        label={t('Email message')}
        value={props.model.emailMessage}
        onInput={(value: any) => props.onUpdate({ emailMessage: value || undefined })}
        error={emailMessageError()}
        onFilesChange={handleFilesChange('email')}
      />

      <LabeledTextInput
        label={t('Subject')}
        value={props.model.subject}
        onInput={(value) => props.onUpdate({ subject: value || undefined })}
      />

      <Show when={props.errors.chatMessage || props.errors.emailMessage}>
        <p class="text-red-500">{props.errors.chatMessage || props.errors.emailMessage}</p>
      </Show>
    </div>
  );
};

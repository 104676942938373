import VendorItemIcon from '~/assets/images/bill/vendorItem.svg?component-solid';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import type { Component } from 'solid-js';
interface VendorItemProps {
  class?: string;
  vendor?: MagicDoor.Api.HydratedVendorDto | MagicDoor.Api.VendorDto;
}

const VendorItem: Component<VendorItemProps> = (props) => {
  return (
    <div class={cn('flex items-center rounded-lg p-3 text-left', props.class)}>
      <VendorItemIcon class="mr-2" />
      <span>{props.vendor?.name || emptyPlaceholder}</span>
    </div>
  );
};

export default VendorItem;

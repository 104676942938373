import { createSignal, onMount } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { contributionsSettingsRepository } from '~/repositories/settings/contributionsSettingsRepository';
import { createLazyResource } from '~/utils/resource';
import type { ContributionSettingsDtoSettingsWithOverridesDto } from '~/swagger/Api';

export const [ContributionsSettingsProvider, useContributionsSettings] = createMagicDoorContext('ContributionsSettings', () => {
  const repo = new contributionsSettingsRepository();
  const [isLoading, setIsLoading] = createSignal<boolean>(false);
  const [error, setError] = createSignal<Error | undefined>(undefined);

  const [allContributionsSettings, allContributionsSettingsActions] = createLazyResource<ContributionSettingsDtoSettingsWithOverridesDto>(
    () => repo.getAllContributionsSettings()
  );

  // change update delete text setting like chats
  const updateContributionSettingsByLevel = async (
    level: string,
    levelId: string,
    payload: MagicDoor.Api.ContributionSettingsDto
  ): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.updateContributionsSettingsByLevel(level, levelId, payload);
      setTimeout(() => {
        allContributionsSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteContributionsSettingsByLevel = async (level: string, levelId: string): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.deleteContributionsSettingsByLevel(level, levelId);
      setTimeout(() => {
        allContributionsSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const updateContributionsSettings = async (payload: MagicDoor.Api.ContributionSettingsDto): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.changeContributionsSettings(payload);
      setTimeout(() => {
        allContributionsSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  onMount(() => {
    allContributionsSettingsActions.fetch();
  });

  return {
    isLoading,
    error,

    updateContributionsSettings,
    allContributionsSettings,
    updateContributionSettingsByLevel,
    deleteContributionsSettingsByLevel,
  };
});

import { useLocation } from '@solidjs/router';
import { createMemo, Show } from 'solid-js';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons/LinkButton';
import { Empty } from '~/components/common/Empty';
import IconField from '~/components/common/IconField';
import { MaintenanceRequestStatus } from '~/components/maintenance-requests/MaintenanceRequestStatus';
import { IconClock, IconHash } from '~/components/ui';
import { Skeleton } from '~/components/ui/Skeleton';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import aiRunbook from './aiRunbook.png';
import type { Component } from 'solid-js';

const Learn = () => {
  return (
    <div class="flex flex-col rounded-lg bg-[#1A68DC] p-6 capitalize text-white">
      <img src={aiRunbook} alt="ai-runbook" class="mb-5 w-44 self-center" />
      <div class="mb-2 text-lg font-medium">Learn to master magicdoor quickly</div>
      <div class="text-xs text-white/70">AI-Enabled property management software that honors your time</div>

      <div class="mt-6 text-sm">Watch video</div>
    </div>
  );
};

const LatestMaintenanceRequest = () => {
  const { t } = useLocalization();
  const { runBook } = useRunBooks();
  return (
    <Show
      when={runBook()?.lastUseMaintenanceRequestId}
      fallback={
        <div class="rounded-lg bg-white">
          <Empty description={t('No maintenance request yet')} />
        </div>
      }>
      <div class="rounded-lg bg-white">
        <div class="px-4 py-5">
          <div class="text-xs font-medium capitalize text-text-level03">{t('Latest maintenance request')}</div>

          <div class="my-2 text-base font-semibold text-text-level01">
            <Show when={!runBook.loading} fallback={<Skeleton class="h-6 w-full" />}>
              {runBook()?.lastUseMaintenanceRequest?.title ?? emptyPlaceholder}
            </Show>
          </div>

          <div class="mb-1">
            <Show when={!runBook.loading} fallback={<Skeleton class="h-4 w-32" />}>
              <Show when={runBook()?.lastUseMaintenanceRequest?.status} fallback={emptyPlaceholder}>
                <MaintenanceRequestStatus status={runBook()?.lastUseMaintenanceRequest?.status as MagicDoor.Api.MaintenanceRequestStatus} />
              </Show>
            </Show>
          </div>
          <div class="flex items-center gap-1 text-xs text-text-level03">
            {t('Last update')}:
            <Show when={!runBook.loading} fallback={<Skeleton class="h-4 w-32" />}>
              <span>
                {runBook()?.lastUseMaintenanceRequest?.lastUpdated
                  ? dateFormat(t('MM/DD/YYYY hh:mm A'), runBook()?.lastUseMaintenanceRequest?.lastUpdated)
                  : emptyPlaceholder}
              </span>
            </Show>
          </div>
          <IconField
            class="mt-5"
            name={t('Request number')}
            loading={runBook.loading}
            value={runBook()?.lastUseMaintenanceRequest?.tenantId}
            src={IconHash}
          />
          <IconField
            class="mt-5"
            name={t('Created at')}
            loading={runBook.loading}
            value={
              runBook()?.lastUseMaintenanceRequest?.created
                ? dateFormat(t('MM/DD/YYYY hh:mm A'), runBook()?.lastUseMaintenanceRequest?.created)
                : emptyPlaceholder
            }
            src={IconClock}
          />
        </div>
        <Show when={runBook()?.lastUseMaintenanceRequestId}>
          <div class="border-t p-4">
            <LinkButton variant="outlined" href={`/maintenance/maintenance-request/${runBook()?.lastUseMaintenanceRequestId}`}>
              <span class="text-nowrap"> {t('Maintenance request')}</span>
              <IconViewArrow class="size-5" />
            </LinkButton>
          </div>
        </Show>
      </div>
    </Show>
  );
};

export const RunbookSummary: Component = () => {
  const location = useLocation();

  const isScript = createMemo<boolean>(() => location.pathname.includes('script'));

  return (
    <div class="w-full lg:w-[267px]">
      <Show when={isScript()} fallback={<LatestMaintenanceRequest />}>
        <Learn />
      </Show>
    </div>
  );
};

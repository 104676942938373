import { createMagicDoorContext } from '~/contexts/utils';
import { DebugRepository } from '~/repositories/debugRepository';

const repo = new DebugRepository();

export const [DebugProvider, useDebug] = createMagicDoorContext('Debug', () => {
  const setPlaidStatusDebug = async (plaidId: string, status: 'settled' | 'failed' | 'returned') => {
    try {
      if (status === 'settled') {
        await repo.setPlaidStatusSettled(plaidId);
      } else if (status === 'failed') {
        await repo.setPlaidStatusFailed(plaidId);
      } else if (status === 'returned') {
        await repo.setPlaidStatusReturned(plaidId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    setPlaidStatusDebug,
  };
});

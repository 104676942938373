import { createMemo } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { dateDiffOfDay } from '~/utils/date';
import type { Component } from 'solid-js';

export const getAutoPaymentStatus = (entry: { end?: string | null }) => {
  const days = dateDiffOfDay(entry.end, null);
  if (days < 0) return { label: 'Expired', color: 'text-text-level03' };
  if (days < 7) return { label: 'Due Soon', color: 'text-error' };
  return { label: 'Active', color: 'text-success' };
};

export const AutoPaymentStatus: Component<{ class?: string; entry: { end?: string | null } }> = (props) => {
  const { t } = useLocalization();
  const status = createMemo(() => getAutoPaymentStatus(props.entry));
  return (
    <span class={cn('flex w-fit items-center gap-1 rounded-full bg-current-alpha px-2.5 py-0.5', status().color, props.class)}>
      <i class="block size-1.5 rounded-full bg-current" />
      {t(status().label)}
    </span>
  );
};

import { runWithOwner } from 'solid-js';
import { isEmptyData } from '~/utils/tool';
import {
  getCityValidationError,
  getStateValidationError,
  getStreetAddress1ValidationError,
  getZipCodeValidationError,
  getCountryValidationError,
} from '~/utils/validations';
import type { Owner } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm/type';

export function validateAddress(address: MagicDoor.Api.AddressDto): boolean | string {
  const validationErrors: MagicDoor.Api.AddressDto = {
    streetAddress1: getStreetAddress1ValidationError(address.streetAddress1, true),
    city: getCityValidationError(address.city, true),
    state: getStateValidationError(address.state, true),
    zipCode: getZipCodeValidationError(address.zipCode, true),
    country: getCountryValidationError(address.country, true),
  };

  if (isEmptyData(validationErrors)) {
    return true;
  }

  return JSON.stringify(validationErrors);
}

type getRules = (t: (str: string) => string) => BetterForm.Rule[];

export const email: getRules = (t) => [
  { message: t('Please input email'), required: true },
  { message: t('Email is not valid'), type: 'email' },
];

export const phone: getRules = (t) => [
  { message: t('Please input phone'), required: true },
  { type: 'phone', message: t('Phone is not valid') },
];

export const dateOfBirth: getRules = (t) => [
  {
    message: t('Please input date of birth'),
    required: true,
  },
  {
    message: t('Date of birth is not valid'),
    validator: (value) => {
      return /^\d{4}-\d{2}-\d{2}$/.test(value);
    },
  },
];

export const getAddress1ValidError = (address1: string, t: Translate): string => {
  if (!address1) {
    return t('Street address1 is required');
  } else if (address1.length > 50 || /[^a-zA-Z0-9 #()&,.\\-_'~+/*]+/.test(address1)) {
    return t('Street address1 is not valid');
  }
  return 'No errors';
};

export const getAddress2ValidError = (address2: string, t: Translate): string => {
  if (address2 && (/[^a-zA-Z0-9 #()&,.\\-_'~+/*]+/.test(address2) || address2.length > 100)) {
    return t('Street address2 is not valid');
  }
  return 'No errors';
};
export const getCityValidError = (city: string, t: Translate): string => {
  if (!city) {
    return t('City is required');
  } else if (city.length > 27 || city.length < 2 || /[><=$?|%"`]|&#+/.test(city)) {
    return t('City is not valid');
  }
  return 'No errors';
};
export const getStateValidError = (state: string, t: Translate): string => {
  if (!state) {
    return t('State is required');
  } else if (!/^[A-Za-z]{2}$/.test(state)) {
    return t('State must be 2 characters');
  }
  return 'No errors';
};
export const getZipCodeValidError = (zipCode: string, t: Translate): string => {
  if (!zipCode) {
    return t('Zip Code is required');
  } else if (!/^\d{5}$/.test(zipCode)) {
    return t('Zip Code must be 5 digits');
  }
  return 'No errors';
};

export const getCountryValidError = (country: string, t: Translate): string => {
  if (!country || country.trim() === '') {
    return t('Country is required');
  } else if (!/^[a-zA-ZÀ-ÖØ-öø-ÿ' -]{2,50}$/.test(country)) {
    return t('Country is not valid');
  }
  return 'No errors';
};

export const addressRequired = (t: Translate): BetterForm.Rule[] => {
  return [
    { required: true, message: JSON.stringify({ streetAddress1: t('Please input address') }) },
    {
      validator: (value: any, specificFieldPath?: string) => {
        if (!value) {
          return t('{name} is required', { name: t('address') });
        }
        const errors: Record<string, string> = {};
        const validList = {
          streetAddress1: getAddress1ValidError,
          streetAddress2: getAddress2ValidError,
          state: getStateValidError,
          city: getCityValidError,
          zipCode: getZipCodeValidError,
          country: getCountryValidError,
        };

        if (specificFieldPath && Object.keys(validList).includes(specificFieldPath ?? '')) {
          const attr = validList[specificFieldPath](value[specificFieldPath], t);

          if (attr === 'No errors') {
            return true;
          }
          errors[specificFieldPath] = attr;
        } else {
          const { streetAddress1, streetAddress2, city, state, zipCode, country } = value;
          const validators = {
            streetAddress1: getAddress1ValidError(streetAddress1, t),
            streetAddress2: getAddress2ValidError(streetAddress2, t),
            city: getCityValidError(city, t),
            state: getStateValidError(state, t),
            zipCode: getZipCodeValidError(zipCode, t),
            country: getCountryValidError(country, t),
          };

          Object.keys(validators).forEach((field) => {
            const error = validators[field];
            if (error !== 'No errors') {
              errors[field] = error;
            }
          });
        }

        if (Object.keys(errors).length > 0) {
          return JSON.stringify(errors);
        }
        return true;
      },
    },
  ];
};

type Translate = (s: string, params?: Record<string, string>) => string;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const addressFormat = (owner: Owner | null, t: Translate): BetterForm.Rule => {
  return {
    validator: (value) => {
      if (!value) {
        return true;
      }
      return runWithOwner(owner, () => validateAddress(value)) as string;
    },
  };
};

import { createMagicDoorContext } from '~/contexts/utils';
import { PropertyCreditRepository } from '~/repositories/propertyCreditRepository';
import { createMutation, createTriggerResource } from '~/utils/resource';
import type { ManagementFeeListFilter } from '~/repositories/managementFeesRepository';
import type { PropertyCreditListFilter } from '~/repositories/propertyCreditRepository';

const repo = new PropertyCreditRepository();

export const [PropertyCreditProvider, usePropertyCredit] = createMagicDoorContext('PropertyCredit', () => {
  const [filtered, setFilter, actions] = createTriggerResource(async (filter: PropertyCreditListFilter) => {
    const res = await repo.getPropertyCredits(filter);
    const items = res.items.map((item) => {
      return {
        ...item,
        portfolio: res.portfolios?.find((portfolio) => portfolio.id === item.portfolioId),
        property: res.properties?.find((property) => property.id === item.propertyId),
        unit: res.units?.find((unit) => unit.id === item.unitId),
        chartOfAccountName: res.chartOfAccounts?.find((chartOfAccount) => chartOfAccount.id === item.lineItems[0].chartOfAccountId)?.name,
      };
    });

    return {
      ...res,
      items,
    };
  });

  const addPropertyCredit = createMutation(async (propertyId: string, payload: MagicDoor.Api.GivePropertyCreditDto) => {
    await repo.addPropertyCredit(propertyId, payload);
    actions.refetch();
  });

  const deletePropertyCredit = createMutation(async (billId: string) => {
    await repo.deletePropertyCredit(billId);
    actions.refetch();
  });

  return {
    filtered: filtered,
    setFilter: (filter: ManagementFeeListFilter) => setFilter(filter),
    addPropertyCredit,
    deletePropertyCredit,
  };
});

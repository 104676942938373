import { useParams } from '@solidjs/router';
import { Show, createEffect, createSignal, onCleanup, onMount } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { FilesListProvider, useChat, useChatsList } from '~/contexts/local';
import { ChatWindow } from '~/pages/chats/chat-details/ChatWindow';
import { useLockBodyScroll } from '~/utils/hooks';
import { ChatSidebar } from './list-components/ChatSidebar';

export const ChatListView = () => {
  const params = useParams<{ chatId?: `${number}` | 'add' }>();
  const { t } = useLocalization();

  const { isLoading, fetchChats, chats, error } = useChatsList();
  const { chatId, initializeChat, resetChat } = useChat();

  const [isSidebarVisible, setIsSidebarVisible] = createSignal<boolean>(window.innerWidth > 1023);
  const [isMobileView, setIsMobileView] = createSignal<boolean>(window.innerWidth <= 1023);

  onMount(() => fetchChats());

  onMount(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);
  });

  onCleanup(() => {
    document.removeEventListener('touchstart', handleTouchStart);
    document.removeEventListener('touchend', handleTouchEnd);
  });

  let touchstartX = 0;
  let touchendX = 0;

  const handleTouchStart = (e: any): void => {
    touchstartX = e.changedTouches[0].screenX;
  };

  const handleTouchEnd = (e: any): void => {
    const threshold = 75;

    touchendX = e.changedTouches[0].screenX;
    if (touchendX < touchstartX - threshold && isSidebarVisible()) setIsSidebarVisible(false);
    if (touchendX > touchstartX + threshold && !isSidebarVisible()) setIsSidebarVisible(true);
  };

  const handleBackToChatList = () => {
    setIsSidebarVisible(true);
  };

  useLockBodyScroll();

  createEffect(() => {
    const chatIdFromUrl = params.chatId;
    if (chatIdFromUrl && chatIdFromUrl !== 'add') {
      initializeChat(chatIdFromUrl);
    } else {
      resetChat();
    }
  });

  createEffect(() => {
    if (isMobileView()) setIsSidebarVisible(false);
  });

  createEffect(() => {
    if (params.chatId == null || params.chatId === 'add') return;
    if (isLoading() || chats().some((c) => c.id === params.chatId)) return;
    fetchChats();
  });

  createEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const newIsMobileView = window.innerWidth <= 1023;
      setIsMobileView(newIsMobileView);

      if (!isMobileView()) setIsSidebarVisible(true);
    });

    resizeObserver.observe(document.body);

    onCleanup(() => resizeObserver.disconnect());
  });

  createEffect(() => {
    if (error instanceof Error) {
      throw error();
    }
  });

  return (
    <div class="relative flex text-sm" classList={{ 'cursor-wait': isLoading() }}>
      <ChatSidebar
        class="absolute inset-y-0 left-0 z-20 h-section shrink-0 -translate-x-full transition-transform lg:relative lg:translate-x-0"
        classList={{ '-translate-x-full': !isSidebarVisible() }}
      />
      <div class="grow" onClick={() => window.innerWidth <= 1023 && setIsSidebarVisible(false)}>
        <Show when={!isSidebarVisible()}>
          <button
            onClick={handleBackToChatList}
            class="absolute top-20 z-10 translate-x-0 rounded-r-md bg-link p-2 text-left text-white transition-transform">
            {t('All chats')}
          </button>
        </Show>
        <FilesListProvider entityType={'chat' as MagicDoor.Api.EntityType} entityId={chatId() as string}>
          <ChatWindow chatId={chatId()} class="border-t border-partingline" />
        </FilesListProvider>
      </div>
    </div>
  );
};

import { createSignal } from 'solid-js';
import { useForm } from '~/components/common/BetterForm';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization, useTenants } from '~/contexts/global';
import { AddTenantForm } from '~/pages/leases/add-tenant/AddTenantForm';
import type { Component } from 'solid-js';

export const AddTenantModal: Component<{
  class?: string;
  visible?: boolean;
  onClose?: (tenant?: MagicDoor.Api.TenantCreatedDto) => void;
}> = (props) => {
  const { t } = useLocalization();
  const form = useForm();
  const { addTenant } = useTenants();
  const [addTenantLoading, setAddTenantLoading] = createSignal<boolean>(false);

  const onDone = async () => {
    const { validateStatus } = form.validateForm();

    if (!validateStatus) return;

    try {
      setAddTenantLoading(true);
      const tenant = await addTenant(form.formStore as MagicDoor.Api.TenantCreatedDto);
      toast.success(t('{name} has been added successfully', { name: tenant.firstName }));
      props.onClose?.(tenant);
    } catch (err: any) {
      toast.error(t('Operation failed, please try again later') + ' ' + err?.json?.detail);
    } finally {
      setAddTenantLoading(false);
    }
  };

  return (
    <Modal
      title={t('Add tenant')}
      doneText={t('Confirm')}
      visible={!!props.visible}
      class="w-11/12 lg:w-modalMd"
      confirmation={false}
      onDone={onDone}
      loading={addTenantLoading()}
      onCancel={() => {
        props.onClose?.();
      }}>
      <AddTenantForm form={form} class="max-h-[480px] overflow-y-auto p-6" />
    </Modal>
  );
};

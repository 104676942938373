import { AttachmentsProvider } from '~/contexts/local';
import { AddBillView as AddBill } from './AddBillView';
import type { Component } from 'solid-js';

export const AddBillView: Component = () => {
  return (
    <AttachmentsProvider>
      <AddBill />
    </AttachmentsProvider>
  );
};

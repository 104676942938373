import { BankAccountsListProvider, WorkOrderSettingsProvider } from '~/contexts/local';
import { WorkOrderSettings } from './Settings';

export const WorkOrderSettingsWrapper = () => {
  return (
    <WorkOrderSettingsProvider>
      <BankAccountsListProvider>
        <WorkOrderSettings />
      </BankAccountsListProvider>
    </WorkOrderSettingsProvider>
  );
};

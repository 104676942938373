import { FormItem } from '~/components/common/BetterForm';
import { useCategoryComponentWrapper } from '~/components/common/Category';
import LabeledBankingInput from '~/components/common/Inputs/form-inputs/LabeledBankingInput';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

export const OwnerBankInformationSection: Component = () => {
  const { t } = useLocalization();

  const { onClickSection } = useCategoryComponentWrapper();

  const handleInteraction = () => {
    onClickSection('bankInformation');
  };

  return (
    <div id="bankInformation" onClick={handleInteraction}>
      <div class="sticky top-0 z-10 border-b bg-white py-3 text-lg font-semibold text-text-level01">{t('Bank information')}</div>

      <div class="py-8">
        <FormItem
          class="col-span-3"
          formFieldName="bankAccountInformation"
          fieldName="bankAccountInformation"
          component={LabeledBankingInput}
        />
      </div>
    </div>
  );
};

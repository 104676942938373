import { RentalApplicationChartOfAccountsSettingsProvider } from '~/contexts/local';
import { ChartOfAccount } from './ChartOfAccount';

export const ChartOfAccountWrap = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <RentalApplicationChartOfAccountsSettingsProvider>
        <ChartOfAccount />
      </RentalApplicationChartOfAccountsSettingsProvider>
    </div>
  );
};

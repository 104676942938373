import { Show } from 'solid-js';
import { Skeleton } from '~/components/ui';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import type { Component, JSX, ParentProps } from 'solid-js';

interface IconFieldProps {
  name: string;
  value?: string | undefined | JSX.Element;
  src?: Component<{ class?: string }>;
  classLabel?: string;
  class?: string;
  iconRounded?: boolean;
  loading?: boolean;
  iconBoxClass?: string;
}

const IconField = (props: ParentProps<IconFieldProps>) => {
  return (
    <div class={cn('flex h-9 w-full flex-row items-center gap-2 text-sm', props.class)}>
      <Show when={props.src}>
        <div
          class={cn(
            'rounded-lg border border-solid bg-white p-2',
            {
              'rounded-full': props.iconRounded,
            },
            props.iconBoxClass
          )}>
          {props.src && <props.src class="size-5 text-text-level03" stroke-width={1.5} />}
        </div>
      </Show>
      <div class="flex-1 overflow-hidden">
        <div class={cn('text-xs uppercase text-text-level03', props.classLabel)}>{props.name}</div>
        <Show when={!props.loading} fallback={<Skeleton class="w-40" />}>
          <div class="max-w-full truncate normal-case text-text-level01">{props.value || props.children || emptyPlaceholder}</div>
        </Show>
      </div>
    </div>
  );
};

export default IconField;

import { useNavigate, useParams } from '@solidjs/router';
import dayjs from 'dayjs';
import { Show, createEffect, createMemo, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Button } from '~/components/common/Buttons';
import { Panel } from '~/components/common/Panels';
import { LeaseTitle } from '~/components/leases';
import { IconLoader, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLease } from '~/contexts/local';
import { EditLeaseForm } from './EditLeaseForm';
import type { EditLeaseFormprops } from './EditLeaseForm';

export const EditLeaseView = () => {
  const { t } = useLocalization();
  const { leaseId } = useParams();
  const navigate = useNavigate();
  const { lease, editLease, getLease, loading } = useLease();
  const [submitLoading, setSubmitLoading] = createSignal(false);
  const breadcrumbItems = createMemo(() => [{ label: t('Lease'), link: `/leasing/leases/${leaseId}` }, { label: t('Edit lease') }]);
  const [dataStore, setDataStore] = createStore<Omit<EditLeaseFormprops, 'onUpdate'>>({
    formData: {
      type: '',
      start: '',
      end: '',
      monthToMonth: false,
      eviction: false,
      icons: [],
    },
    formError: {
      start: undefined,
    },
  });

  const onUpdate = (field: string, value: any) => {
    setDataStore('formData', {
      ...dataStore.formData,
      [field]: value,
    });
  };
  const validator = (): boolean => {
    let result = true;

    const startError = (() => {
      const value = dataStore.formData.start;
      const translatedName = t('Start date');
      if (!value) {
        return t('{name} is required', { name: translatedName });
      }
      if (!dayjs(value, 'YYYY-MM-DD').isValid()) {
        return t('{name} date string is incorrect', { name: translatedName });
      }
      return undefined;
    })();

    if (startError) result = false;

    setDataStore('formError', {
      start: startError,
    });
    return result;
  };
  const back = () => {
    navigate(-1);
  };
  const handleSubmit = async () => {
    const validateResult = validator();
    if (!validateResult) return;
    const leaseId = lease()?.id;
    if (!leaseId) return;

    setSubmitLoading(true);
    try {
      const params = {
        start: dataStore.formData.start,
        end: dataStore.formData.type === 'monthToMonth' ? null : dataStore.formData.end,
        monthToMonth: dataStore.formData.monthToMonth,
        eviction: dataStore.formData.eviction,
        icons: dataStore.formData.icons,
      };
      await editLease(leaseId, params);
      back();
      toast.success(t('{name} has been edited successfully', { name: t('Lease') }));
    } finally {
      setSubmitLoading(false);
    }
  };

  createEffect(() => {
    getLease(leaseId);
  });
  createEffect(() => {
    if (lease()) {
      setDataStore('formData', {
        type: lease()?.end ? 'fixedTerm' : 'monthToMonth',
        start: lease()?.start || '',
        end: lease()?.end || '',
        monthToMonth: lease()?.monthToMonth || false,
        eviction: lease()?.eviction || false,
        icons: lease()?.icons || [],
      });
    }
  });
  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <Show
        when={!loading()}
        fallback={
          <Panel class="m-8 flex h-section1 flex-col items-center justify-center gap-2">
            <IconLoader class="animate-spin text-title-gray" />
            {t('Loading')}...
          </Panel>
        }>
        <Panel class="m-8 flex h-section1 flex-col" title={t('Edit lease')}>
          <div class="thinscroll flex-1 overflow-auto">
            <div class="mx-auto w-3/4 py-3">
              <LeaseTitle class="w-full bg-inputbox-bg p-3" lease={lease()} />
            </div>
            <form class="m-auto w-3/4" onSubmit={handleSubmit}>
              <EditLeaseForm isDraft={lease()?.draft} formData={dataStore.formData} formError={dataStore.formError} onUpdate={onUpdate} />
            </form>
          </div>

          <div class="flex h-20 w-full items-center justify-end gap-2 border-t px-8">
            <Button variant="outlined" onClick={back}>
              {t('Cancel')}
            </Button>
            <Button loading={submitLoading()} onClick={handleSubmit}>
              {t('Save')}
            </Button>
          </div>
        </Panel>
      </Show>
    </>
  );
};

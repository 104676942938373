import dayjs from 'dayjs';
import { createMemo, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { PayOutsRepository } from '~/repositories/payOutsRepository';
import { createLazyResource, createMutation, createTriggerResource } from '~/utils/resource';
import type { ManagementFeeListFilter } from '~/repositories/managementFeesRepository';
import type { CompanyPayOutBankAccountDto, LineItemDto } from '~/swagger/Api';

const payOutsRepo = new PayOutsRepository();

export interface MixedPayOutBankAccountDto extends Omit<CompanyPayOutBankAccountDto, 'lineItems'> {
  paymentType?: string;
  paymentMethod?: string;
  externalTransactionId?: string;

  memo?: string;

  lineItems?: (LineItemDto & { paymentType?: string; paymentMethod?: string; externalTransactionId?: string; memo: string })[];
}

export const [PayOutsProvider, usePayOuts] = createMagicDoorContext('PayOuts', () => {
  const [filtered, setFilter, actions] = createTriggerResource((filter: ManagementFeeListFilter) => payOutsRepo.getPayOuts(filter));
  const [runDate, runDateActions] = createLazyResource(() => payOutsRepo.getRunDate());
  const [canNext, setCanNext] = createSignal(true);
  const { t } = useLocalization();
  const addPayOut = createMutation((payload: MagicDoor.Api.AddCompanyPayOutsDto) => payOutsRepo.addPayOut(payload));

  const [payoutForm, setPayoutForm] = createStore<
    Omit<MagicDoor.Api.CompanyPayOutDto, 'bankAccountPayOuts'> & {
      paymentDate?: string;
      bankAccountPayOuts?: MixedPayOutBankAccountDto[];
      setPayOutDate?: boolean;
    }
  >({});

  const deletePayOut = createMutation(async (payOutId: string) => {
    await payOutsRepo.deletePayOut(payOutId);
    actions.refetch();
  });

  const [calculatePayOutsLoading, setCalculatePayOutsLoading] = createSignal(false);

  async function initPayouts() {
    setCalculatePayOutsLoading(true);
    const res = await payOutsRepo.calculatePayOuts();
    setCalculatePayOutsLoading(false);

    for (const item of res.bankAccountPayOuts as MixedPayOutBankAccountDto[]) {
      item.paymentType = 'printCheck';
      item.paymentMethod = 'check';
      item.lineItems?.forEach((lineItem) => {
        lineItem.paymentType = 'printCheck';
        lineItem.paymentMethod = 'check';
        lineItem.memo = lineItem.bill?.memo ?? '';
      });
    }

    if (res) {
      setPayoutForm({
        paymentDate: dayjs().format('YYYY-MM-DD'),
        setPayOutDate: false,
        bankAccountPayOuts: res.bankAccountPayOuts as MixedPayOutBankAccountDto[],
        chartOfAccounts: res.chartOfAccounts,
      });
    }
  }

  const steps = createMemo(() => [t('Payouts'), t('Payment'), t('Review & print')]);

  return {
    filtered: filtered,
    setFilter: (filter: ManagementFeeListFilter) => setFilter(filter),
    addPayOut,
    deletePayOut,
    runDate: () => {
      runDateActions.fetch();
      return runDate();
    },
    calculatePayOutsLoading,
    payoutForm,
    setPayoutForm,
    initPayouts: initPayouts,
    steps,
    canNext,
    setCanNext,
  };
});

import { PropertySettingsProvider } from '~/contexts/local';
import { PropertySettings } from './PropertySettings';

export const PropertyPropertySettingsWrapper = () => {
  return (
    <PropertySettingsProvider>
      <PropertySettings />
    </PropertySettingsProvider>
  );
};

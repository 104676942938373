import { createEffect, createResource } from 'solid-js';
import { createStore } from 'solid-js/store';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { BankAccountRepository } from '~/repositories/bankAccountRepository';
import { OwnersRepository } from '~/repositories/ownersRepository';
import { PropertiesRepository } from '~/repositories/propertiesRepository';
import { TransactionPaymentMethod, OwnerDistributionPaymentType } from '~/swagger/Api';
import { paymentMethodOptions, paymentTypeOptions } from '~/utils/constant';
import { createLazyResource } from '~/utils/resource';
import type { CreateOwnerDistributionDto, PropertyDto } from '~/swagger/Api';

export interface EditingDistribution {
  propertyId: string;
  ownerId: string;
  ownerName: string;
  distributionAmount: number;
  memo: string;
  bankAccountId: string;
  paymentType: OwnerDistributionPaymentType;
  paymentMethod: TransactionPaymentMethod;
  externalTransactionId?: string;
  defaultMemo?: string;
  checkNumber?: number;
}

export interface AddOwnerDistributionsStore {
  currentStep: number;
  selectedProperties: (MagicDoor.Api.PropertyDto | null)[];
  selectedCategory: PropertiesCategory;
  isValidate: boolean;
  editingDistributions: EditingDistribution[];
  isSubmitting: boolean;
  response: any;
}

export enum PropertiesCategory {
  ALL,
  SOME,
}

export type PropertyReviewRecord = {
  property?: MagicDoor.Api.PropertyDto;
  isMissingOwnerInformation?: boolean;
  ownershipPercentage?: number;
  checked?: boolean;
};

const getInitialStore = () => ({
  selectedCategory: PropertiesCategory.ALL,
  currentStep: 0,
  selectedProperties: [],
  isValidate: true,
  editingDistributions: [],
  isSubmitting: false,
  response: undefined,
});

export const [AddOwnerDistributionsProvider, useAddOwnerDistributions] = createMagicDoorContext('AddOwnerDistributions', () => {
  const propertiesRepo = new PropertiesRepository();
  const ownersRepo = new OwnersRepository();
  const bankAccountsRepo = new BankAccountRepository();
  const { t } = useLocalization();
  const [store, setStore] = createStore<AddOwnerDistributionsStore>(getInitialStore());
  const [allProperties, allPropertiesActions] = createResource(async () => {
    const response = await propertiesRepo.getAllProperties();
    return response ?? [];
  });
  const [propertyValidationRecords, propertyValidationRecordsActions] = createLazyResource<PropertyReviewRecord[]>(async () => {
    const response = await ownersRepo.getOwnersDistributionsValidation({
      propertyIds: store.selectedProperties.filter((property) => !!property).map((property) => property?.id),
    });
    const results = [
      ...(response.needsAttentionProperties ?? []).map((record) => ({
        property: record.property,
        isMissingOwnerInformation: record.isMissingOwnerInformation,
        ownershipPercentage: record.calculatedOwnershipPercentage,
        checked: record.isMissingOwnerInformation ? false : true,
      })),
      ...(response.validProperties ?? []).map((record) => ({
        property: record.property,
        isMissingOwnerInformation: false,
        ownershipPercentage: 100,
        checked: true,
      })),
    ];

    return results;
  });
  const [bankAccountOptions, bankAccountOptionsActions] = createLazyResource(async () => {
    const response = await bankAccountsRepo.getBankAccounts();
    const options = (response ?? []).map((account) => ({
      label: account.name,
      value: account.id,
    }));

    return options;
  });
  const [ownerDistributions, ownerDistributionsActions] = createLazyResource(async () => {
    const validatedProperties = propertyValidationRecords()?.filter((record) => !record.isMissingOwnerInformation && record.checked);
    const response = await ownersRepo.getOwnersDistributionsCalculation({
      propertyIds: validatedProperties?.filter((record) => !!record.property).map((record) => (record.property as PropertyDto).id),
    });

    return response.properties?.map((item) => ({
      ...item,
      checked: true,
    }));
  });

  const onChangeSelectedProperties = (properties: (MagicDoor.Api.PropertyDto | null)[]) => {
    setStore('selectedProperties', properties);
  };

  const onChangeIsValidate = (step: number) => {
    let isValidate = store.isValidate;

    switch (step) {
      case 0: {
        isValidate = [...new Set(store.selectedProperties)].filter((id) => !!id).length > 0;
        break;
      }

      case 1: {
        isValidate = (propertyValidationRecords() ?? []).some((record) => record.checked);

        break;
      }

      default: {
        isValidate = true;
        break;
      }
    }

    setStore('isValidate', isValidate);
  };

  const onStepChange = (step: number, isBack?: boolean) => {
    if (store.currentStep === 2 && step === 3 && !isBack) {
      onSubmitOwnerDistributions();
      return;
    }

    setStore('currentStep', step);

    if (step === 1 && !isBack) {
      setStore(
        'selectedProperties',
        store.selectedProperties.filter((property) => !!property)
      );
    }
  };

  const onChangePropertiesCategory = (category: PropertiesCategory) => {
    setStore('selectedCategory', category);
  };

  const onChangePropertyValidationRecords = (records: PropertyReviewRecord[]) => {
    propertyValidationRecordsActions.mutate(records);
  };

  const onChangeOwnerDistributionChecked = (checked: boolean, propertyId?: string) => {
    ownerDistributionsActions.mutate(
      ownerDistributions()?.map((record) => ({
        ...record,
        checked: record.property?.id === propertyId ? checked : record.checked,
      }))
    );
  };

  const updateOwnerDistributionRecord = (
    propertyId: string,
    ownerId: string,
    { key, value }: { key: keyof EditingDistribution; value: any }
  ) => {
    const editingRecordIndex = store.editingDistributions.findIndex(
      (record) => record.propertyId === propertyId && record.ownerId === ownerId
    );
    const editingRecord = store.editingDistributions[editingRecordIndex];

    if (!editingRecord) {
      return;
    }

    setStore('editingDistributions', store.editingDistributions.toSpliced(editingRecordIndex, 1, { ...editingRecord, [key]: value }));
  };

  const onSubmitOwnerDistribution = async (distribution: CreateOwnerDistributionDto) => {
    const res = await ownersRepo.submitOwnerDistributions({ distributions: [distribution] });
    toast.success(t('Owner distribution(s) have been added successfully'));
    return res;
  };

  const onSubmitOwnerDistributions = async () => {
    setStore('isSubmitting', true);
    try {
      const ownerDistributions = store.editingDistributions
        .filter((record) => record.distributionAmount > 0)
        .map((record) => ({
          ownerId: record.ownerId,
          propertyId: record.propertyId,
          distributionAmount: record.distributionAmount,
          memo: record.paymentType === OwnerDistributionPaymentType.PrintCheck ? record.memo : '',
          bankAccountId: record.bankAccountId,
          paymentType: record.paymentType,
          paymentMethod: record.paymentType === OwnerDistributionPaymentType.Manual ? record.paymentMethod : TransactionPaymentMethod.Ach,
          externalTransactionId: record.paymentType === OwnerDistributionPaymentType.Manual ? record.externalTransactionId : '',
        }));

      const response = await ownersRepo.submitOwnerDistributions({ distributions: ownerDistributions });
      setStore('response', response);
      toast.success(t('Owner distribution(s) have been added successfully'));
      const timer = window.setTimeout(() => {
        setStore('currentStep', store.currentStep + 1);
        window.clearTimeout(timer);
      }, 100);
    } finally {
      setStore('isSubmitting', false);
    }
  };

  const onResetStore = () => {
    setStore(() => ({ ...getInitialStore() }));
  };

  createEffect(() => {
    if (ownerDistributions.loading || (bankAccountOptions.loading && !store.editingDistributions.length)) {
      return;
    }

    const editingDistributions: EditingDistribution[] = [];

    ownerDistributions()?.forEach((property) => {
      property.distributionOwners?.forEach((record) => {
        if (property.property && record.owner && property.checked) {
          editingDistributions.push({
            propertyId: property.property.id,
            ownerId: record.owner.id,
            ownerName: `${record.owner.firstName} ${record.owner.lastName || ''}`,
            memo: property.defaultMemo || '',
            bankAccountId: property.defaultBankAccountId ?? bankAccountOptions()?.[0]?.value ?? '',
            paymentMethod: TransactionPaymentMethod.Ach,
            distributionAmount: record.distributionAmount || 0,
            paymentType: OwnerDistributionPaymentType.PrintCheck,
          });
        }
      });
    });

    setStore('editingDistributions', editingDistributions);
  });

  return {
    store,
    allProperties,
    allPropertiesActions,
    onChangeSelectedProperties,
    onChangeIsValidate,
    onStepChange,
    onChangePropertiesCategory,
    get propertyValidationRecords() {
      propertyValidationRecordsActions.fetch();
      return propertyValidationRecords;
    },
    propertyValidationRecordsActions,
    get ownerDistributions() {
      ownerDistributionsActions.fetch();
      return ownerDistributions;
    },
    ownerDistributionsActions,
    get bankAccountOptions() {
      bankAccountOptionsActions.fetch();
      return bankAccountOptions;
    },
    updateOwnerDistributionRecord,
    onChangeOwnerDistributionChecked,
    onSubmitOwnerDistributions,
    onChangePropertyValidationRecords,
    onResetStore,
    paymentMethodOptions: paymentMethodOptions(t),
    paymentTypeOptions: paymentTypeOptions(t),
    onSubmitOwnerDistribution,
  };
});

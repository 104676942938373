import { createMemo, createSignal, onMount } from 'solid-js';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { BankAccountRepository } from '~/repositories/bankAccountRepository';
import type { Accessor } from 'solid-js';
import type { FilterItems, ListViewArgs } from '~/components/ui';

type FilterValue = {
  plaidLinkStatus?: 'All' | PlaidLinkStatus;
  stripeLinkStatus?: 'All' | StripeLinkStatus;
};

interface BankAccountsListContextValue {
  fetchBankAccounts: () => Promise<void>;
  bankAccounts: Accessor<PresentableBankAccount[]>;
  filteredBankAccounts: Accessor<PresentableBankAccount[]>;
  onBankAccountAdded: (bankAccount: MagicDoor.Api.HydratedBankAccountDto) => void;
  onBankAccountDeleted: (bankAccountId: string) => void;
  isLoading: Accessor<boolean>;
  error: Accessor<string | undefined>;
  bankAccountCount: Accessor<number>;
  filterItems: Accessor<FilterItems<FilterValue>>;
  filterValue: Accessor<FilterValue>;
  onResetFilter: () => void;
  onFilterChange: (args: ListViewArgs<FilterValue>, action: 'search' | 'filter' | 'paginate') => void;
}

const DEFAULT_FILTER_VALUE: FilterValue = { plaidLinkStatus: 'All', stripeLinkStatus: 'All' };

export enum PlaidLinkStatus {
  UNCONNECTED = 'Unconnected',
  PENDING_QUESTIONNAIRE = 'Pending questionnaire',
  PENDING_ORIGINATOR = 'Pending originator',
  CONNECTED = 'Connected',
}

export enum StripeLinkStatus {
  UNCONNECTED = 'Unconnected',
  PENDING = 'Pending',
  CONNECTED = 'Connected',
}

export type PresentableBankAccount = MagicDoor.Api.HydratedBankAccountDto & { plaidLinkStatus: string; stripeLinkStatus: string };

export const [BankAccountsListProvider, useBankAccountsList] = createMagicDoorContext<BankAccountsListContextValue>(
  'BankAccountsList',
  () => {
    const { t } = useLocalization();
    const repo = new BankAccountRepository();
    const [bankAccounts, setBankAccounts] = createSignal<PresentableBankAccount[]>([]);
    const [isLoading, setIsLoading] = createSignal<boolean>(false);
    const [error, setError] = createSignal<string | undefined>(undefined);
    const [bankAccountCount, setBankAccountCount] = createSignal<number>(0);
    const [search, setSearch] = createSignal('');
    const [filterValue, setFilterValue] = createSignal<FilterValue>(Object.assign({}, DEFAULT_FILTER_VALUE));
    const filteredBankAccounts = createMemo(() => {
      return bankAccounts().filter((account) => {
        const isSearchPassed =
          !search() || account.name.toLowerCase().includes(search().toLowerCase()) || account.description?.includes(search().toLowerCase());
        const isPlaidLinkStatusPassed =
          filterValue().plaidLinkStatus === 'All' || !filterValue().plaidLinkStatus
            ? true
            : account.plaidLinkStatus === filterValue().plaidLinkStatus;
        const isStripeLinkStatusPassed =
          filterValue().stripeLinkStatus === 'All' || !filterValue().stripeLinkStatus
            ? true
            : account.stripeLinkStatus === filterValue().stripeLinkStatus;

        return isSearchPassed && isPlaidLinkStatusPassed && isStripeLinkStatusPassed;
      });
    });

    const filterItems = createMemo(() => [
      {
        type: 'select',
        key: 'plaidLinkStatus',
        label: t('Plaid link status'),
        options: [
          { label: t('All'), value: 'All' },
          { label: t('Unconnected'), value: PlaidLinkStatus.UNCONNECTED },
          { label: t('Pending questionnaire'), value: PlaidLinkStatus.PENDING_QUESTIONNAIRE },
          { label: t('Pending originator'), value: PlaidLinkStatus.PENDING_ORIGINATOR },
          { label: t('Connected'), value: PlaidLinkStatus.CONNECTED },
        ],
      },
      {
        type: 'select',
        key: 'stripeLinkStatus',
        label: t('Stripe link status'),
        options: [
          { label: t('All'), value: 'All' },
          { label: t('Unconnected'), value: StripeLinkStatus.UNCONNECTED },
          { label: t('Pending'), value: StripeLinkStatus.PENDING },
          { label: t('Connected'), value: StripeLinkStatus.CONNECTED },
        ],
      },
    ]) as Accessor<FilterItems<FilterValue>>;

    const fetchBankAccounts = async () => {
      setIsLoading(true);
      try {
        const response = await repo.getBankAccounts();
        const list = response.map(map);

        setBankAccounts(list);

        setBankAccountCount(list.length);
        setError(undefined);
      } catch (e) {
        setError(e instanceof Error ? e.message : String(e));
      } finally {
        setIsLoading(false);
      }
    };

    onMount(() => {
      fetchBankAccounts();
    });

    const map = (bankAccount: MagicDoor.Api.HydratedBankAccountDto): PresentableBankAccount => {
      const getPlaidLinkStatus = () => {
        const shouldPerformQuestionnaire = bankAccount?.plaid?.shouldPerformQuestionnaire;
        const isPlaidConnected = bankAccount?.plaid?.hasOriginator;
        const notSubmittedOriginator = bankAccount?.plaid?.originatorStatus === 'notSubmitted';
        const pendingOriginator =
          bankAccount?.plaid?.originatorStatus === 'submitted' || bankAccount?.plaid?.originatorStatus === 'underReview';
        const approvedOriginator = bankAccount?.plaid?.originatorStatus === 'approved';

        if (!shouldPerformQuestionnaire && !isPlaidConnected) {
          return PlaidLinkStatus.UNCONNECTED;
        }

        if (shouldPerformQuestionnaire && notSubmittedOriginator) {
          return PlaidLinkStatus.PENDING_QUESTIONNAIRE;
        }

        if (pendingOriginator) {
          return PlaidLinkStatus.PENDING_ORIGINATOR;
        }

        if (approvedOriginator) {
          return PlaidLinkStatus.CONNECTED;
        }

        return '';
      };
      const getStripeLinkStatus = () => {
        const isStripeConnected = bankAccount?.stripe?.connected;
        const isDetailSubmitted = bankAccount?.stripe?.detailsSubmitted;
        const isChargesEnabled = bankAccount?.stripe?.chargesEnabled;

        if (!isChargesEnabled && !isDetailSubmitted) {
          return StripeLinkStatus.UNCONNECTED;
        }

        if (!isChargesEnabled && isStripeConnected && isDetailSubmitted) {
          return StripeLinkStatus.PENDING;
        }

        if (isChargesEnabled) {
          return StripeLinkStatus.CONNECTED;
        }

        return '';
      };

      return { ...bankAccount, plaidLinkStatus: getPlaidLinkStatus(), stripeLinkStatus: getStripeLinkStatus() }; // No transformation is required for now
    };

    const onBankAccountAdded = (bankAccount: MagicDoor.Api.HydratedBankAccountDto) => {
      setBankAccounts((prevBankAccounts) => [map(bankAccount), ...prevBankAccounts]);
      setBankAccountCount((count) => count + 1);
    };

    const onBankAccountDeleted = (bankAccountId: string) => {
      setBankAccounts((prevBankAccounts) => prevBankAccounts.filter((t) => t.id?.toString() !== bankAccountId));
      setBankAccountCount((count) => count - 1);
    };

    const onResetFilter = () => {
      setFilterValue(Object.assign({}, DEFAULT_FILTER_VALUE));
    };

    const onFilterChange = (args: ListViewArgs<FilterValue>) => {
      const { search, filter } = args;

      setFilterValue(filter);
      setSearch(search ?? '');
    };

    return {
      fetchBankAccounts,
      bankAccounts,
      onBankAccountAdded,
      onBankAccountDeleted,
      isLoading,
      error,
      bankAccountCount,
      filterItems,
      filterValue,
      onResetFilter,
      onFilterChange,
      filteredBankAccounts,
    };
  }
);

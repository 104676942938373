import { Panel } from '~/components/common/Panels';
import { LeasePeriod, LeaseStatus } from '~/components/leases';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

type BalancesProps = {
  class?: string;
  lease?: MagicDoor.Api.HydratedLeaseDto;
};

export const LeaseTerm: Component<BalancesProps> = (props) => {
  const { t } = useLocalization();

  return (
    <Panel title={t('Lease term')} class={cn('p-2', props.class)}>
      <div
        class="mt-1 flex flex-col items-center justify-center rounded-lg  py-3"
        classList={{
          'bg-warning-light': !props.lease?.eviction,
          'bg-[#F3F2F9]': props.lease?.eviction || props.lease?.ended,
        }}>
        <LeaseStatus class="text-base font-bold" lease={props.lease} />
        <LeasePeriod class="text-sm text-text-level03" lease={props.lease} />
      </div>
    </Panel>
  );
};

import { useParams } from '@solidjs/router';
import { useLeaseRentTransactions } from '~/contexts/local';
import { LeaseReceivePaymentModal } from '~/pages/leases/lease-details/components/lease-deposit-transaction/LeaseReceivePaymentModal';
import type { ModalProps } from '~/components/modals/Modal';
import type { ReceivePaymentDataType } from '~/pages/leases/lease-details/components/lease-deposit-transaction/LeaseReceivePaymentModal';

type LeaseReceivePaymentModalProps = Omit<ModalProps, 'title'> & {
  currentLease: MagicDoor.Api.HydratedLeaseDto;
};

export const LeaseTransactionReceivePaymentModal = (props: LeaseReceivePaymentModalProps) => {
  const { leaseId: id } = useParams();
  const { offlinePayment } = useLeaseRentTransactions();

  const handleSubmit = async (data: ReceivePaymentDataType) => {
    const leaseId = props.currentLease.id ?? id;
    if (!leaseId) return;

    await offlinePayment(leaseId, {
      date: data.date as string,
      amount: Number(data.amount),
      description: data.description || null,
      tenantId: data.tenantId || null,
      paymentMethod: data.paymentMethod,
      reference: data.reference || null,
    });
  };

  return <LeaseReceivePaymentModal {...props} onSubmit={handleSubmit} />;
};

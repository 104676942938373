import { createMemo } from 'solid-js';
import IconRentCharges from '~/assets/images/leases/rent-charges.svg?component-solid';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import { Panel } from '~/components/common/Panels';
import UnderConstruction from '~/components/common/UnderConstruction';
import { Table, TableActions } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { TableColumns } from '~/components/ui';

const BankTransactionsTable = () => {
  const { t } = useLocalization();

  type tempTransactions = {
    id: number;
    description: string;
    chargeFrequency: string;
    status: 'active' | 'dueSoon' | 'ended';
    nextCharge: Date;
    chartOfAccount: { name: string };
    amount: number;
    startFrom: Date;
    end?: Date;
  };

  const generateTenantData = (): tempTransactions[] => [
    {
      id: 1,
      description: 'Monthly rent for apartment',
      chargeFrequency: 'Monthly',
      status: 'active',
      nextCharge: new Date('2024-05-01'),
      chartOfAccount: { name: 'Rental Income' },
      amount: 1200,
      startFrom: new Date('2023-01-01'),
    },
    {
      id: 2,
      description: 'Quarterly heating bill',
      chargeFrequency: 'Quarterly',
      status: 'dueSoon',
      nextCharge: new Date('2024-04-15'),
      chartOfAccount: { name: 'Utilities' },
      amount: 300,
      startFrom: new Date('2023-01-01'),
      end: new Date('2024-12-31'),
    },
    {
      id: 3,
      description: 'Utils',
      chargeFrequency: 'Quarterly',
      status: 'dueSoon',
      nextCharge: new Date('2024-04-15'),
      chartOfAccount: { name: 'Utilities' },
      amount: 300,
      startFrom: new Date('2023-01-01'),
      end: new Date('2024-11-20'),
    },
  ];

  const columns = createMemo<TableColumns<tempTransactions>>(() => [
    {
      title: t('Description/memo'),
      headerClass: 'w-[26%]',
      render: (item) => (
        <div class="flex items-center gap-2">
          <IconRentCharges />
          <div class="flex-1">
            <h4 class="font-medium normal-case text-text-level01">{item.description}</h4>
            <p class="text-text-level03">{item.chargeFrequency}</p>
          </div>
        </div>
      ),
    },
    {
      title: t('Status'),
      headerClass: 'w-[20%]',
      render: (item) => (
        <div class="flex flex-col items-start gap-1 text-xs">
          <span
            class={`rounded-full px-2 py-1 capitalize ${
              item.status === 'active'
                ? 'bg-green/20 text-green'
                : item.status === 'dueSoon'
                ? 'bg-error/20 text-error'
                : 'bg-disabled/20 text-disabled'
            }`}>
            {item.status}
          </span>
          <span class="text-text-level03">
            {t('Next payment')}: {dateFormat('', item.nextCharge)}
          </span>
        </div>
      ),
    },
    {
      title: t('Chart of account'),
      headerClass: 'w-[20%]',
      class: 'text-sm',
      render: (item) => item.chartOfAccount?.name || '',
    },
    {
      title: t('Amount'),
      headerClass: 'w-[10%] text-right',
      class: 'text-sm text-right',
      render: (item) => `${currency(item.amount)}`,
    },
    {
      title: t('Start and expire date'),
      headerClass: 'w-[18%]',
      class: 'text-sm',
      render: (item) => `${dateFormat('', item.startFrom)}${item.end ? ` - ${dateFormat('', item.end)}` : ''}`,
    },
    {
      headerClass: 'w-[6%]',
      class: 'text-sm',
      render: (item) => (
        <TableActions
          actions={[
            { label: t('Edit'), icon: IconPencil, onClick: () => item.id },
            { label: t('Delete'), icon: IconDelete, onClick: () => item.id },
          ]}
        />
      ),
    },
  ]);

  return (
    <Panel title={t('Transactions')}>
      <div class="relative flex max-h-64">
        <Table columns={columns()} data={generateTenantData()} />
        <UnderConstruction />
      </div>
    </Panel>
  );
};

export default BankTransactionsTable;

import { Outlet, useNavigate, useParams } from '@solidjs/router';
import { createEffect } from 'solid-js';
import { usePortfolios } from '~/contexts/global';
import { NotFoundError } from '~/errors';
import { PortfolioTabs } from './components/PortfolioTabs';
import type { Component } from 'solid-js';

export const PortfolioLayout: Component = () => {
  const params = useParams<{ portfolioId?: 'add' | `${number}` }>();
  const navigate = useNavigate();

  const { portfolios, current, setCurrentId } = usePortfolios();

  createEffect(() => {
    if (params.portfolioId && /^\d+$/.test(params.portfolioId)) {
      setCurrentId(params.portfolioId);
      if (!portfolios.loading && current() == null) throw new NotFoundError();
    } else if (portfolios()?.[0]?.id) {
      const currentId = current()?.id ?? setCurrentId(portfolios()?.[0]?.id);
      params.portfolioId == null && navigate(`/portfolios/${currentId}`, { replace: true });
    }
  });

  return (
    <div class="space-y-5 p-8 pt-5">
      <PortfolioTabs portfolios={portfolios()} selected={current()} />
      <Outlet />
    </div>
  );
};

import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

type UnitSummaryProps = {
  class?: string;
};

export const UnitSummarySkeleton: Component<UnitSummaryProps> = (props) => {
  const { t } = useLocalization();

  return (
    <Panel title={t('Basic information')} class={props.class}>
      <div class="flex space-x-9 px-6 py-5">
        <div class="skeleton-shimmer h-11 w-16" />
        <div class="skeleton-shimmer h-11 w-16" />
        <div class="skeleton-shimmer h-11 w-16" />
      </div>
    </Panel>
  );
};

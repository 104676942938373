import { createMagicDoorContext } from '~/contexts/utils';
import { MaintenanceRequestRepository } from '~/repositories/maintenanceRequestRepository';
import { createLazyResource, createMutation, createTriggerResource } from '~/utils/resource';

const repo = new MaintenanceRequestRepository();

export const [MaintenanceRequestsProvider, useMaintenanceRequests] = createMagicDoorContext('MaintenanceRequests', () => {
  const [maintenanceRequests, getMaintenanceRequests, maintenanceRequestsActions] = createTriggerResource(
    repo.getMaintenanceRequests.bind(repo)
  );
  const [maintenanceRequest, getMaintenanceRequest, maintenanceRequestActions] = createTriggerResource(
    repo.getMaintenanceRequest.bind(repo)
  );
  const [maintenanceRequestCategory, maintenanceRequestCategoryActions] = createLazyResource(repo.getMaintenanceRequestCategory.bind(repo));
  const [maintenanceRequestStats, maintenanceRequestStatsActions] = createLazyResource(repo.getMaintenanceRequestStats.bind(repo));

  const addMaintenanceRequest = createMutation(async (payload: MagicDoor.Api.MaintenanceRequestDto) => {
    const response = await repo.addMaintenanceRequest(payload);
    maintenanceRequestsActions.refetch();
    maintenanceRequestStatsActions.refetch();
    return response;
  });

  const deleteMaintenanceRequest = createMutation(async (id: string) => {
    await repo.deleteMaintenanceRequest(id);
    id === maintenanceRequest()?.id && maintenanceRequestActions.mutate(undefined);
    maintenanceRequestsActions.refetch();
    maintenanceRequestStatsActions.refetch();
  });

  const updateMaintenanceRequest = createMutation(async (id: string, payload: MagicDoor.Api.MaintenanceRequestDto) => {
    await repo.updateMaintenanceRequest(id, payload);
    id === maintenanceRequest()?.id && maintenanceRequestActions.refetch();
    maintenanceRequestsActions.refetch();
    maintenanceRequestStatsActions.refetch();
  });

  const updateMaintenanceRequestStatus = createMutation(async (id: string, status: MagicDoor.Api.Status) => {
    await repo.updateMaintenanceRequestStatus(id, status);
    id === maintenanceRequest()?.id && maintenanceRequestActions.refetch();
    maintenanceRequestsActions.refetch();
    maintenanceRequestStatsActions.refetch();
  });

  const updateMaintenanceRequestUrgency = createMutation(async (id: string, urgency: MagicDoor.Api.Urgency) => {
    await repo.updateMaintenanceRequestUrgency(id, urgency);
    id === maintenanceRequest()?.id && maintenanceRequestActions.refetch();
    maintenanceRequestsActions.refetch();
    maintenanceRequestStatsActions.refetch();
  });

  const disableMaintenanceRequestAi = createMutation(async () => {
    const maintenanceRequestId = maintenanceRequest()?.id;
    if (!maintenanceRequestId) return;
    await repo.disableMaintenanceRequestAi(maintenanceRequestId);
    maintenanceRequestActions.refetch();
  });

  const convertToWorkOrder = createMutation(async (id: string, payload: MagicDoor.Api.CreateWorkOrderFromMaintenanceRequestRequestDto) => {
    const response = await repo.convertToWorkOrder(id, payload);
    maintenanceRequestActions.refetch();
    return response;
  });

  return {
    maintenanceRequests,
    getMaintenanceRequests,
    maintenanceRequest,
    getMaintenanceRequest,
    get maintenanceRequestStats() {
      maintenanceRequestStatsActions.fetch();
      return maintenanceRequestStats;
    },
    addMaintenanceRequest,
    deleteMaintenanceRequest,
    updateMaintenanceRequest,
    updateMaintenanceRequestStatus,
    updateMaintenanceRequestUrgency,
    get maintenanceRequestCategory() {
      maintenanceRequestCategoryActions.fetch();
      return maintenanceRequestCategory;
    },

    disableMaintenanceRequestAi,

    maintenanceRequestActions,
    convertToWorkOrder,
  };
});

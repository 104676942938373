import { FormItem } from '~/components/common/BetterForm';
import { useCategoryComponentWrapper } from '~/components/common/Category';
import LabeledPaymentInput from '~/components/common/Inputs/form-inputs/LabeledPaymentInput';
import { useLocalization } from '~/contexts/global';

const PaymentInformationSection = () => {
  const { onClickSection } = useCategoryComponentWrapper();

  const { t } = useLocalization();

  return (
    <div id="payment-information">
      <div class="sticky top-0 z-10 border-b bg-white py-3 text-lg font-semibold text-text-level01">{t('Payment information')}</div>
      <FormItem
        onClick={() => onClickSection('paymentInformation')}
        formFieldName={'irs1099Form'}
        fieldName="irs1099Form"
        component={LabeledPaymentInput}
        customGetValue={(context) => {
          return context.formStore.irs1099Form || {};
        }}
      />
    </div>
  );
};

export default PaymentInformationSection;

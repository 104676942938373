import { DeactivateModal } from '~/components/modals';
import { toast } from '~/components/ui';
import { useLocalization, useUnits } from '~/contexts/global';
import type { Component } from 'solid-js';

export const DeactivateUnit: Component<{ unit?: { id: string; name: string }; onClose?: () => void }> = (props) => {
  const { t } = useLocalization();
  const { deactivateUnit } = useUnits();

  const handleConfirm = async () => {
    if (props.unit == null) return;
    await deactivateUnit(props.unit.id, true);
    toast.success(t('{name} has been deactivated successfully', { name: t('Unit') }));
    props.onClose?.();
  };

  return (
    <DeactivateModal visible={props.unit != null} onConfirm={handleConfirm} onCancel={props.onClose}>
      {t(
        'Deactivating <b>“{name}”</b> Unit will also <b>terminate all Leases under it</b>, and the data will not be displayed in the report.',
        { name: props.unit?.name ?? '-' }
      )}
    </DeactivateModal>
  );
};

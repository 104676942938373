import { createUniqueId } from 'solid-js';
import { createStore } from 'solid-js/store';
import type { JSX, ValidComponent } from 'solid-js';
import type { Merge, Promisable } from '~/utils/types';

type DialogType = 'alert' | 'confirm' | 'prompt';

type DialogOptions<T extends DialogType> = {
  content: JSX.Element | (() => JSX.Element);
  title?: JSX.Element;
  icon?: ValidComponent;
  doneText?: string;
  cancelText?: string;
  class?: string;
  footerClass?: string;
  titleClass?: string;
  onResolve?: (value: ResolveValue<T>) => Promisable<void>;
  confirmDisabled?: () => boolean;
};

type ResolveValue<T extends DialogType> = T extends 'prompt' ? string | undefined : T extends 'confirm' ? boolean : void;

type DialogItem<T extends DialogType> = Merge<
  DialogOptions<T>,
  {
    type: T;
    id: string;
    resolve: (value: ResolveValue<T>) => Promise<void>;
  }
>;

const [dialogs, setDialogs] = createStore<DialogItem<DialogType>[]>([]);

const remove = (id: string) => setDialogs((prev) => prev.filter((i) => i.id !== id));

const isDialogOptions = <T extends DialogType>(x: unknown): x is DialogOptions<T> => x != null && typeof x === 'object' && 'content' in x;

const create = <T extends DialogType>(type: T, options: DialogOptions<T> | JSX.Element) =>
  new Promise<ResolveValue<T>>((resolve) => {
    const item: DialogItem<T> = {
      ...(isDialogOptions(options) ? options : { content: options }),
      type: type ?? 'alert',
      id: createUniqueId(),
      resolve: async (value) => {
        await item.onResolve?.(value);
        remove(item.id);
        resolve(value);
      },
    };
    setDialogs((prev) => [...prev, item] as any);
  });

const alert = (options: DialogOptions<'alert'> | JSX.Element) => create('alert', options);
const confirm = (options: DialogOptions<'confirm'> | JSX.Element) => create('confirm', options);
const prompt = (options: DialogOptions<'prompt'> | JSX.Element) => create('prompt', options);

export { dialogs, alert, confirm, prompt, remove };
export type { DialogType, DialogItem, DialogOptions };

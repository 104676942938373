import { BaseRestRepository } from '~/repositories/baseRestRepository';

const RENTAL_APPLICATION_SCREEN_URL = '/api/settings/rental-application/screening';

export class RentalApplicationScreeningRepository extends BaseRestRepository {
  public async getRentalApplicationScreeningSettings(): Promise<MagicDoor.Api.RentalApplicationScreeningSettingsDto> {
    const url = RENTAL_APPLICATION_SCREEN_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async deleteRentalApplicationScreeningSettings() {
    const url = RENTAL_APPLICATION_SCREEN_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }

  public async updateRentalApplicationScreeningSettings(
    payload: MagicDoor.Api.RentalApplicationScreeningSettingsDto
  ): Promise<MagicDoor.Api.RentalApplicationScreeningSettingsDto> {
    const url = RENTAL_APPLICATION_SCREEN_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }
}

import { useParams } from '@solidjs/router';
import { Show, For, createResource, createMemo } from 'solid-js';
import { createStore } from 'solid-js/store';
import IconDelete from '~/assets/images/common/minus.svg?component-solid';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconTempUnit from '~/assets/images/common/tempUnit.png';
import { Button } from '~/components/common/Buttons';
import DisplayAddress from '~/components/common/DisplayAddress';
import { BaseModal } from '~/components/modals/BaseModal';
import { Footer } from '~/components/modals/Footer';
import { Image, toast } from '~/components/ui';
import { UnitSearch } from '~/components/units';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { UnitRepository } from '~/repositories/unitRepository';

const unitRepo = new UnitRepository();

interface UnitProps {
  address?: MagicDoor.Api.AddressDto;
  name?: string;
}

export const Unit = (props: UnitProps) => {
  return (
    <div class="flex items-center">
      <Image class="size-10" src={IconTempUnit} />
      <div class="ml-3 flex flex-col truncate text-xs font-semibold text-text-level03">
        <div class="text-sm text-text-level01">{props.name}</div>
        <DisplayAddress address={props.address} />
      </div>
    </div>
  );
};

export const PotentialUnitsModal = () => {
  const { t } = useLocalization();

  const { propertyId } = useParams();
  const { applicationStore, setApplicationStore, updatePotentialUnits } = useRentalApplication();
  const [store, setStore] = createStore<{
    readyToAddUnit: Array<MagicDoor.Api.HydratedUnitDto>;
    addNew: boolean;
  }>({
    readyToAddUnit: [],
    addNew: true,
  });

  const [units] = createResource({ propertyId }, (filter) => unitRepo.getUnits(filter));

  const filteredUnits = createMemo(() => {
    return (units()?.items ?? []).filter(
      (unit) =>
        !applicationStore.application?.interests?.some((interest) => interest.unit.id === unit.id) &&
        !store.readyToAddUnit.some((u) => u.id === unit.id)
    );
  });

  const onUpdatePotentialUnits = async () => {
    await updatePotentialUnits(
      'add',
      store.readyToAddUnit.map((unit) => unit.id)
    );
    onHide();
    toast.success(t('{name} has been added successfully', { name: t('Potential unit') }));
  };

  const onHide = () => {
    setApplicationStore('addPotentialUnit', false);
    setStore('readyToAddUnit', []);
  };
  return (
    <BaseModal
      size="md"
      title={t('Add potential unit')}
      visible={applicationStore.addPotentialUnit}
      onClose={() => {
        setApplicationStore('addPotentialUnit', false);
      }}>
      <div class="flex flex-col gap-2 p-6">
        <Show when={store.readyToAddUnit.length > 0}>
          <div class="mb-4 self-start">
            <Button
              variant="outlined"
              disabled={store.addNew}
              onClick={() => {
                setStore('addNew', true);
              }}>
              <IconPlus />
              {t('Add other')}
            </Button>
          </div>
        </Show>
        <For each={store.readyToAddUnit}>
          {(item) => {
            return (
              <div class="flex items-center justify-between gap-3">
                <div class="flex-1 rounded border border-partingline p-4">
                  <Unit name={item.name} address={item.property.address} />
                </div>
                <div class="flex gap-2">
                  <Button
                    variant="text"
                    onClick={() => {
                      setStore('readyToAddUnit', (units) => {
                        return units.filter((unit) => unit.id !== item.id);
                      });
                    }}>
                    <IconDelete />
                  </Button>
                </div>
              </div>
            );
          }}
        </For>
        <Show when={store.addNew || store.readyToAddUnit.length === 0}>
          <div class="flex items-center gap-3">
            <div class="flex-1">
              <UnitSearch
                doNotRenderSelected
                onSelect={(item) => {
                  if (!item?.id) return;
                  setStore('readyToAddUnit', (units) => {
                    return [...units, item];
                  });
                  setStore('addNew', false);
                }}
                filter={{ propertyId }}
                exclude={(unit) => {
                  return (
                    applicationStore.application?.interests?.some((interest) => interest.unit.id === unit.id) ||
                    store.readyToAddUnit.some((u) => u.id === unit.id)
                  );
                }}
              />
            </div>
            <Show when={store.readyToAddUnit.length > 0}>
              <Button
                variant="text"
                onClick={() => {
                  setStore('addNew', false);
                }}>
                <IconDelete />
              </Button>
            </Show>
          </div>
        </Show>
      </div>
      <Footer
        onOk={() => {
          onUpdatePotentialUnits();
        }}
        okProps={{ loading: applicationStore.updateLoading }}
        onCancel={() => {
          onHide();
        }}
      />
    </BaseModal>
  );
};

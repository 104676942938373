import { ValidationError } from '~/errors';
import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

const ANNOUNCEMENT_URL = '/api/announcements';

export function validateId(id: string, entityName = 'Entity'): void {
  if (!id) throw new ValidationError(`${entityName} ID must be provided`);
}

export type AnnouncementListQuery = {
  'sendAt.start'?: string;
  'sendAt.end'?: string;
};
export class AnnouncementRepository extends BaseRestRepository {
  public async getAnnouncementTargets(
    filter?: MagicDoor.Api.GetAnnouncementTargetsRequestDto
  ): Promise<MagicDoor.Api.HydratedAnnouncementTargetsDto> {
    const url = ANNOUNCEMENT_URL + '/targets';
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(filter),
    });
    return this.getJsonResponse(response);
  }

  public async getAnnouncements(filter?: AnnouncementListQuery): Promise<MagicDoor.Api.AnnouncementsDto[]> {
    const url = urlWithQuery(ANNOUNCEMENT_URL, filter);

    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getAnnouncementDetails(announcementId: string): Promise<MagicDoor.Api.HydratedAnnouncementDto> {
    validateId(announcementId);
    const url = `${ANNOUNCEMENT_URL}/${announcementId}`;

    const response = await this.fetchWithAuth(url);

    return this.getJsonResponse(response);
  }

  public async addAnnouncement(announcement: FormData): Promise<MagicDoor.Api.HydratedAnnouncementDto> {
    const response = await this.fetchWithAuth(ANNOUNCEMENT_URL, {
      method: 'POST',
      body: announcement,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return this.getJsonResponse(response);
  }
}

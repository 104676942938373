import { ChatWindow } from '~/components/common/ChatWindow';
import { useMaintenanceRequests } from '~/contexts/local';

export const MaintenanceCommunicationsTab = () => {
  const { maintenanceRequest } = useMaintenanceRequests();

  return (
    <ChatWindow
      participantId={maintenanceRequest()?.id}
      chatId={maintenanceRequest()?.chatId}
      participantType="maintenanceRequest"
      class="h-section2 overflow-hidden rounded-lg border-none"
    />
  );
};

import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { StripeRepository } from '~/repositories/stripeRepository';

interface StipeContextValue {
  linkStripeWithAccount: (bankAccountId: string | undefined) => Promise<MagicDoor.Api.StripeLinkDto | undefined>;
  getStripeDetails: (bankAccountId: string | undefined) => Promise<MagicDoor.Api.StripeAccountDetailsDto | undefined>;
  checkStripeConnection: (bankAccountId: string | undefined) => Promise<MagicDoor.Api.StripeIsConnectedDto | undefined>;
  isStripeLoading: () => boolean;
  error: () => Error | undefined;
  stripeUrl: () => string | undefined;
}

export const [StripeProvider, useStripe] = createMagicDoorContext<StipeContextValue>('Stripe', () => {
  const [isStripeLoading, setIsStripeLoading] = createSignal<boolean>(false);
  const [error, setError] = createSignal<Error | undefined>(undefined);
  const [stripeUrl, setStripeUrl] = createSignal<string | undefined>(undefined);
  const repo = new StripeRepository();

  const linkStripeWithAccount = async (bankAccountId: string | undefined) => {
    if (bankAccountId === undefined) return;

    try {
      setIsStripeLoading(true);
      const accounts = await repo.linkStripe(bankAccountId);
      setStripeUrl(accounts?.url);
      return accounts;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsStripeLoading(false);
    }
  };

  const getStripeDetails = async (bankAccountId: string | undefined) => {
    if (bankAccountId === undefined) return;

    console.log('getStripeDetails', bankAccountId);
    try {
      setIsStripeLoading(true);
      const stripeDetails = await repo.getStripeDetails(bankAccountId);
      return stripeDetails;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsStripeLoading(false);
    }
  };

  const checkStripeConnection = async (bankAccountId: string | undefined) => {
    if (bankAccountId === undefined) return;

    try {
      setIsStripeLoading(true);
      const stripeConnection = await repo.checkStripeConnection(bankAccountId);
      return stripeConnection;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsStripeLoading(false);
    }
  };

  return {
    linkStripeWithAccount,
    getStripeDetails,
    stripeUrl,
    checkStripeConnection,
    isStripeLoading,
    error,
  };
});

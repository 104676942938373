import dayjs from 'dayjs';
import { createEffect, createMemo, createSignal } from 'solid-js';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import leasesWithBalanceDueBg from '~/assets/images/dashboard/leasesWithBalanceDueBg.svg';
import { LinkButton } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import { LeasePeriod } from '~/components/leases';
import { LeaseName } from '~/components/leases/LeaseName';
import { Select, Table } from '~/components/ui';
import { UnitImage } from '~/components/units';
import { useLocalization, usePortfolios } from '~/contexts/global';
import { useLeasesList } from '~/contexts/local';
import Card from '~/pages/dashboard/components/Card';
import { QuickLeaseNotifyBalanceDue } from '~/pages/leases/quick-lease-notify-balance-due';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type LeasesWithBalanceDueProps = {
  class?: string;
};

const LeasesWithBalanceDue: Component<LeasesWithBalanceDueProps> = (props) => {
  const { t } = useLocalization();
  const [portfolioId, setPortfolioId] = createSignal<string>('');
  const { portfolios } = usePortfolios();
  const { leases, getLeases, loading } = useLeasesList();

  const portfolioOptions = createMemo(() => [
    { value: '', label: t('All') },
    ...(portfolios()?.map((cur) => ({ value: cur.id, label: cur.name })) ?? []),
  ]);

  const columns = createMemo<TableColumns<MagicDoor.Api.HydratedLeaseDto>>(() => [
    {
      title: t('Lease'),
      render: (item) => (
        <div class="flex">
          <UnitImage class="mr-2 hidden size-11 rounded-lg md:flex" unit={item.unit} />
          <div>
            <LeaseName lease={item} />
            <LeasePeriod class="text-xs" lease={item} />
          </div>
        </div>
      ),
    },
    {
      title: t('Balance'),
      class: 'text-nowrap text-right',
      headerClass: 'text-right',
      render: (item) => (
        <div>
          <CnMoney money={item.balance} />
          <div class="flex justify-end text-xs text-text-level03">
            <span class="hidden md:flex">{t('Last payment date')}:&nbsp</span>
            <span class="flex md:hidden">{t('Last paid')}:&nbsp</span>
            <span class="font-medium text-danger"> {dayjs(item.lastPaymentDate).format('MMM DD')}</span>
          </div>
        </div>
      ),
    },
    {
      title: t('Operation'),
      headerClass: 'text-left',
      class: 'w-16',
      render: (item) => (
        <div class="flex w-24 justify-end gap-3">
          <QuickLeaseNotifyBalanceDue targets={[item.id]} />
        </div>
      ),
    },
  ]);

  createEffect(() => {
    getLeases({
      portfolioId: portfolioId(),
      ended: false,
      withBalance: true,
    });
  });

  return (
    <Card
      class={props.class}
      title={t('Leases with balance due')}
      description={t('{count} in total', { count: loading() ? '-' : leases().length.toString() })}
      bgClass="bg-[#FEF9FF]"
      bgImgSrc={leasesWithBalanceDueBg}
      actions={
        <div class="flex items-center gap-3">
          <Select
            prefix={t('Portfolio')}
            filterable
            options={portfolioOptions()}
            value={portfolioId()}
            onChange={setPortfolioId}
            class="w-32 md:w-44"
          />
          <LinkButton href="/leasing/leases" class="px-3 py-1 text-xs" rounded="full" variant="outlined">
            {t('View all')}
            <IconViewArrow class="size-4 text-essential-colour" />
          </LinkButton>
        </div>
      }>
      <Table data={leases()} columns={columns()} rowLink={(item) => `/leasing/leases/${item.id}`} loading={loading()} />
    </Card>
  );
};

export default LeasesWithBalanceDue;

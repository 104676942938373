import { useNavigate } from '@solidjs/router';
import { createEffect, createMemo, onMount } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { PageCloseGuard } from '~/components/common/PageCloseGuard';
import { Panel } from '~/components/common/Panels';
import { MultiSteps } from '~/components/common/Panels/MultiStepsPanel';
import { Stepper } from '~/components/common/Stepper';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions } from '~/contexts/local';
import { ChoseProperty, PropertiesReview, OwnerDistributionsEdit, SummaryTable } from './components';

export const AddDistribution = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { store, onChangeIsValidate, onStepChange, onChangeSelectedProperties, allProperties, allPropertiesActions, onResetStore } =
    useAddOwnerDistributions();

  const breadcrumbItems = createMemo(() => [
    { label: t('Owner distributions'), link: '/accounting/owner-funds/distributions' },
    { label: t('Calculate a owner distribution') },
  ]);
  const steps = [t('Choose property'), t('Property review'), t('Owner distributions'), t('Table')];
  const stepComponents = createMemo(() => [ChoseProperty, PropertiesReview, OwnerDistributionsEdit, SummaryTable]);

  onMount(() => {
    onResetStore();
    allPropertiesActions.refetch();
  });

  createEffect(() => {
    onChangeIsValidate(store.currentStep);
  });

  createEffect(() => {
    onChangeSelectedProperties(allProperties() ?? []);
  });

  return (
    <div>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="m-8 h-section1">
        <PageCloseGuard isDirty={store.currentStep < 3}>
          <Panel title={t('Calculate a owner distribution')} class="flex size-full flex-col p-0">
            <MultiSteps
              showPrevious
              hideSubButtonsOnLast
              loading={store.isSubmitting}
              nextText={store.currentStep === 2 ? t('Submit') : undefined}
              submitText={t('Close')}
              onSubmit={() => navigate(-1)}
              steps={stepComponents()}
              disableNext={!store.isValidate}
              currentStep={store.currentStep}
              contentClass="py-9 [&>div]:mx-auto"
              stepper={<Stepper class="flex justify-center pb-[72px]" steps={steps} step={store.currentStep} />}
              onClose={() => navigate(-1)}
              onStepChange={(step, isBack) => onStepChange(step, isBack)}
            />
          </Panel>
        </PageCloseGuard>
      </div>
    </div>
  );
};

import { onMount } from 'solid-js';
import CnMoney from '~/components/common/CnMoney';
import { Empty } from '~/components/common/Empty';
import { LeasePeriod, LeaseStatus, LeaseTitle } from '~/components/leases';
import { formatTenantNames } from '~/components/tenants/utils';
import { ListView } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLeasesList } from '~/contexts/local';
import { useLocalPagination } from '~/hooks';
export const LeasesDraft = () => {
  const { t } = useLocalization();
  const { getLeases, leases, pagination, loading } = useLeasesList();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('LeaseDraftListView');

  const necessaryParams = { draft: true };
  onMount(() => getLeases({ page: page(), pageSize: pageSize(), ...necessaryParams }));

  return (
    <ListView
      onChange={({ page, pageSize, search }) => {
        setPage(page);
        setPageSize(pageSize);
        getLeases({ page, pageSize, search, ...necessaryParams });
      }}
      title={t('Draft leases')}
      loading={loading()}
      totalPages={pagination()?.totalPages}
      page={pagination()?.current}
      pageSize={pagination()?.pageSize}
      searchPlaceholder={t('Search draft leases')}
      data={leases()}
      rowLink={(item) => `/leasing/leases/${item.id}`}
      empty={<Empty description={t('No draft leases')} />}
      columns={[
        {
          title: t('Lease'),
          render: (item) => <LeaseTitle lease={item} />,
        },
        {
          title: t('Lease term'),
          headerClass: 'w-56',
          render: (item) => (
            <>
              <LeaseStatus lease={item} />
              <LeasePeriod lease={item} />
            </>
          ),
        },
        {
          title: t('Tenants'),
          headerClass: 'w-64',
          render: (item) => <span class="text-sm font-medium text-text-level01">{formatTenantNames(item.tenants)}</span>,
        },
        {
          title: t('Balance'),
          headerClass: 'w-36 text-right',
          class: 'text-right',
          render: (item) => <CnMoney money={item.balance} />,
        },
      ]}
    />
  );
};

import { Show } from 'solid-js';
import { Html } from '~/components/ui';
import type { Component, JSX } from 'solid-js';

export const TextMessage: Component<{ children?: JSX.Element }> = (props) => (
  <Show when={typeof props.children === 'string'} fallback={props.children}>
    <Html as="div" class="px-6 py-5 text-sm/6 text-text-level02 *:mx-0.5 *:font-bold *:not-italic [&>b]:text-primary [&>i]:text-danger">
      {props.children as string}
    </Html>
  </Show>
);

import { useNavigate } from '@solidjs/router';
import { createMemo, createSignal, onMount } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import { Stepper } from '~/components/common/Stepper';
import { useLocalization, usePayOuts } from '~/contexts/global';
import ReviewPrintForm from '~/pages/company-revenue/pay-outs-tab/pay-outs-calculate/components/ReviewPrintForm';
import AllocationsForm from './components/AllocationsForm';
import PaymentForm from './components/PaymentForm';
import type { PayOutResultsDto } from '~/swagger/Api';

export const PayoutsCalculate = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const breadcrumbItems = createMemo(() => [
    { label: t('Company revenue'), link: '/accounting/company-revenue/pay-outs' },
    { label: t('Calculate pay outs') },
  ]);
  const [currentStep, setCurrentStep] = createSignal<number>(0);
  const [responseData, setResponseData] = createSignal<PayOutResultsDto>();

  const { initPayouts, addPayOut, payoutForm, steps, canNext } = usePayOuts();

  const stepValidator = (): boolean => {
    return !!(payoutForm.bankAccountPayOuts && payoutForm.bankAccountPayOuts?.length > 0);
  };

  const handlePayouts = async () => {
    const res = await addPayOut({
      setPayOutDate: payoutForm.setPayOutDate,
      paymentDate: payoutForm.paymentDate,
      payOuts: payoutForm.bankAccountPayOuts?.map(
        (item) =>
          ({
            bankAccountId: item.bankAccount?.id || '',
            amount: item.amount,
            memo: item.memo,
            paymentType: item.paymentType,
            paymentMethod: item.paymentMethod,
            externalTransactionId: item.externalTransactionId,
          } as MagicDoor.Api.AddCompanyPayOutDto)
      ),
    });

    setResponseData(res);
  };

  const onStepChange = async (step: number) => {
    setCurrentStep(step);
  };

  onMount(() => {
    initPayouts();
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <MultiStepsPanel
        stepper={
          <div class="flex items-center justify-center pb-10">
            <Stepper class="p-9" steps={steps()} step={currentStep()} />
          </div>
        }
        currentStep={currentStep()}
        showPrevious
        onClose={() => navigate(-1)}
        title={t('Calculate pay outs')}
        submitText={t('Submit')}
        onSubmit={handlePayouts}
        disableNext={!stepValidator() || !canNext()}
        successPage={<ReviewPrintForm payment={responseData()} />}
        onStepChange={(step) => onStepChange(step)}>
        <AllocationsForm />
        <PaymentForm />
      </MultiStepsPanel>
    </>
  );
};

import IconBathroom from '~/assets/images/property/bathroom.svg';
import IconBedroom from '~/assets/images/property/bedroom.svg';
import IconUnitSize from '~/assets/images/property/unitSize.svg';
import DisplayAddress from '~/components/common/DisplayAddress';
import { Panel } from '~/components/common/Panels';
import { useLocalization, useProperties } from '~/contexts/global';
import type { Component } from 'solid-js';

export const PropertyInformation: Component = () => {
  const { t } = useLocalization();
  const { current } = useProperties();

  return (
    <div class="px-8 text-left">
      <Panel title={t('Property information')}>
        <div class="flex w-full flex-col p-6">
          <div class="flex w-full gap-4 border-b">
            <div class="flex  w-1/4 flex-col gap-2 rounded-md">
              <div class="h-60 w-full rounded-md bg-text-level03" />
              <div class="flex gap-2">
                <div class="h-20 w-1/3 rounded-md bg-text-level03" />
                <div class="h-20 w-1/3 rounded-md bg-text-level03" />
                <div class="h-20 w-1/3 rounded-md bg-text-level03" />
              </div>
            </div>
            <div class=" w-3/4">
              <div class="flex flex-col gap-8 border-b pb-4">
                <div>
                  <div class="text-2xl	font-semibold">{current()?.name}</div>
                  <div class="text-text-level03">
                    <DisplayAddress address={current()?.address} />
                  </div>
                </div>
                <div class="flex gap-10">
                  <div class="flex flex-col">
                    <span class="text-text-level03">{t('Property type')}</span>
                    <span class="text-xl	font-medium text-essential-colour">{t(current()?.type || '')}</span>
                  </div>
                  <div class="flex flex-col">
                    <span class="text-text-level03">{t('Stories')}</span>
                    <span class="text-xl	font-medium">3</span>
                  </div>
                </div>
              </div>
              <div class="flex gap-6 border-b py-4">
                <PropertyAmenities icon={IconBedroom} title={t('Bedroom')} />
                <PropertyAmenities icon={IconBathroom} title={t('Bathroom')} />
                <PropertyAmenities icon={IconUnitSize} title={t('Unit size')} />
              </div>
              <div class=" flex w-full flex-col gap-2 py-4">
                <div class="text-base	font-medium">{t('Property description')}</div>
                <div class="text-text-level02">Built in: 2015</div>
                <div class="w-full whitespace-normal break-normal text-text-level03	">
                  BRAND NEW - Lyndon Square Senior Apartments for 55+ Active Living with ALL utilities included! We are located in a quiet
                  place.
                </div>
              </div>
            </div>
          </div>
          <div class="pt-4">
            <div class="font-medium">{t('View all information')}</div>
            <div class="text-danger">
              {t('Some information is incomplete, which may result in incomplete information posted to the rental website')}
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
};

type PropertyAmenitiesProps = {
  icon: string;
  title: string;
  suffix?: string;
};

const PropertyAmenities: Component<PropertyAmenitiesProps> = (props) => {
  return (
    <div class="flex flex-col items-center gap-2 text-center">
      <div class="size-9 rounded-full bg-inputbox-bg p-2">
        <img src={props.icon} alt={props.title} />
      </div>
      <div>
        <div class="text-xs text-text-level03">{props.title}</div>
      </div>
    </div>
  );
};

import { BankAccountProvider } from '~/contexts/local';
import BankAccountDetails from './BankAccountDetails';

const BankAccountDetailsPage = () => {
  return (
    <BankAccountProvider>
      <BankAccountDetails />
    </BankAccountProvider>
  );
};

export default BankAccountDetailsPage;

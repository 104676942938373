import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Modal } from '~/components/modals/Modal';
import { useLocalization } from '~/contexts/global';

export interface Props {
  id: string;
  onClose: () => void;
  onDone: (id: string, currentPassword: string, newPassword: string) => void;
  loading?: boolean;
}

export const ChangeManagerPasswordModal = (props: Props) => {
  const form = useForm();
  const { t } = useLocalization();

  const handleOnDone = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;
    await props.onDone(props.id, form.formStore.currentPassword, form.formStore.newPassword);
    handleCancel();
  };

  const handleCancel = () => {
    form.resetFields();
    props?.onClose();
  };

  return (
    <Modal visible title={t('Password change')} onCancel={handleCancel} onDone={handleOnDone} loading={props.loading}>
      <Form defaultForm={form} class=" space-y-5 p-6">
        <FormItem
          label={t('Current password')}
          type="password"
          placeholder={t('Please enter the {name}', { name: t('Current password') })}
          component={LabeledTextInput}
          formFieldName="currentPassword"
          rules={[
            {
              required: true,
              message: t('Please enter the {name}', { name: t('Current password') }),
            },
            {
              length: 150,
              message: t('cannot exceed 150 characters'),
            },
          ]}
        />
        <FormItem
          label={t('New password')}
          type="password"
          placeholder={t('Please enter the {name}', { name: t('New password') })}
          component={LabeledTextInput}
          formFieldName="newPassword"
          rules={[
            {
              required: true,
              message: t('Please enter the {name}', { name: t('New password') }),
            },
            {
              length: 150,
              message: t('cannot exceed 150 characters'),
            },
          ]}
        />
      </Form>
    </Modal>
  );
};

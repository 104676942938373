import { A } from '@solidjs/router';
import { createSignal } from 'solid-js';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import { Section } from '~/components/common/Section';
import UnderConstruction from '~/components/common/UnderConstruction';
import { FilePreviewModal } from '~/components/file-attachments/FilePreviewModal';
import { FileTitle } from '~/components/file-attachments/FileTitle';
import { IconCircleChevronRight, Table } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useAttachments, useOwners } from '~/contexts/local';
import type { Component } from 'solid-js';

const Property: Component<{ class?: string; loading?: boolean; owner?: MagicDoor.Api.OwnerDto }> = (props) => {
  const { t } = useLocalization();
  // TODO: Waiting for API development
  return (
    <Section
      title={t('Property')}
      class={props.class}
      titleClass="border-0"
      contentClass="thinscroll overflow-x-auto whitespace-nowrap text-text-level01">
      <Table
        loading={props.loading}
        class="relative"
        data={[]}
        columns={[
          {
            title: t('Property'),
            headerClass: 'w-32',
            render: () => '',
          },
          {
            title: t('OwnershipPercentage'),
            headerClass: 'w-40',
            render: () => '',
          },
        ]}
      />
      <UnderConstruction />
    </Section>
  );
};

const LatestContributions: Component<{ class?: string; loading?: boolean; owner?: MagicDoor.Api.OwnerDto }> = (props) => {
  const { t } = useLocalization();

  return (
    <Section
      title={t('Latest Contributions')}
      class={props.class}
      titleClass="border-0"
      contentClass="thinscroll overflow-x-auto whitespace-nowrap text-text-level01">
      <Table
        data={[]}
        columns={[
          {
            title: t('Date'),
            render: () => '',
          },
          {
            title: t('Transaction type'),
            render: () => '',
          },
          {
            title: t('Lease'),
            render: () => '',
          },
          {
            title: t('Amount'),
            render: () => '',
          },
          {
            title: t('Status'),
            render: () => '',
          },
          {
            render: () => (
              <div>
                <A href={''} class="flex items-center gap-1 text-xs text-link ">
                  {t('View application')}
                  <IconViewArrow class="size-4 text-link" />
                </A>
              </div>
            ),
          },
        ]}
      />
      <UnderConstruction />
    </Section>
  );
};

const Files: Component<{ class?: string; loading?: boolean; owner?: MagicDoor.Api.OwnerDto }> = (props) => {
  const { t } = useLocalization();
  const { attachments } = useAttachments();
  const [preview, setPreview] = createSignal<MagicDoor.Api.HydratedFileAttachmentDto>();

  return (
    <Section
      title={
        <>
          {t('Files')}
          <A href="files" class="flex items-center gap-1 text-xs font-normal text-link">
            {t('View all')}
            <IconCircleChevronRight class="size-3.5 text-link" />
          </A>
        </>
      }
      class={props.class}
      titleClass="flex justify-between border-0"
      contentClass="thinscroll overflow-x-auto whitespace-nowrap text-text-level01">
      <Table
        loading={props.loading || attachments.loading}
        data={[]}
        onRowClick={setPreview}
        columns={[
          {
            title: t('Type'),
            render: (item) => <FileTitle file={item} />,
          },
          {
            title: t('From'),
            class: 'space-y-1 text-xs',
            render: () => (
              <>
                <p class="text-text-level03">
                  {t('by')} {props.owner?.firstName}
                </p>
              </>
            ),
          },
        ]}
      />
      <FilePreviewModal file={preview()} onClose={setPreview} />
      <UnderConstruction />
    </Section>
  );
};

export const OwnerOverviewTab = () => {
  const { current } = useOwners();
  return (
    <div class="grid gap-5 lg:grid-cols-2">
      <Property class="relative col-span-full" loading={current.loading} owner={current()} />
      <LatestContributions class="relative col-span-full" loading={current.loading} owner={current()} />
      <Files class="relative col-span-full" loading={current.loading} owner={current()} />
    </div>
  );
};

import { BaseRestRepository } from './baseRestRepository';

const LEASE_TEMPLATE_URL = '/api/lease-template';

export class LeaseTemplateRepository extends BaseRestRepository {
  public async getLeaseTemplates(): Promise<MagicDoor.Api.LeaseTemplateDto[]> {
    const response = await this.fetchWithAuth(LEASE_TEMPLATE_URL);
    return this.getJsonResponse(response);
  }

  public async getLeaseTemplate(id: string): Promise<MagicDoor.Api.LeaseTemplateDto> {
    const url = `${LEASE_TEMPLATE_URL}/${id}`;

    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async createLeaseTemplate(
    categoryId: string,
    file: File,
    name: string,
    description: string
  ): Promise<MagicDoor.Api.LeaseTemplateDto> {
    const formData = new FormData();
    formData.append('LeaseTemplateCategoryId', categoryId);
    formData.append('Name', name);
    formData.append('File', file);
    formData.append('Description', description || '');

    const response = await this.fetchWithAuth(LEASE_TEMPLATE_URL, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return this.getJsonResponse(response);
  }

  public async updateLeaseTemplate(id: string, payload: MagicDoor.Api.UpdateLeaseTemplateDto): Promise<MagicDoor.Api.LeaseTemplateDto> {
    const url = `${LEASE_TEMPLATE_URL}/${id}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async deleteLeaseTemplate(id: string): Promise<void> {
    const url = `${LEASE_TEMPLATE_URL}/${id}`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async addLeaseTemplateAttachment(id: string, file: File): Promise<MagicDoor.Api.LeaseTemplateDto> {
    const url = `${LEASE_TEMPLATE_URL}/${id}/file`;
    const formData = new FormData();
    formData.append('File', file);

    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return this.getJsonResponse(response);
  }
}

import { For, Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { Stepper } from '~/components/common/Stepper';
import { useLocalization, usePayOuts } from '~/contexts/global';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';
import type { PayOutResultsDto } from '~/swagger/Api';

interface ReviewPrintFormProps {
  payment?: PayOutResultsDto;
}

const ReviewPrintForm: Component<ReviewPrintFormProps> = (props) => {
  const { t } = useLocalization();
  const { steps } = usePayOuts();

  const handlePrint = () => {
    const printWindow = window.open(' ', ' ', 'width=' + '1024px' + ', height=' + '800px');

    let checkContainers = '';
    for (const item of props.payment?.payOuts ?? []) {
      const checkData = item.check;

      const lineHeaders = checkData?.dataHeaders?.map((header) => `<th>${header}</th>`).join('');
      const lineItems = checkData?.billPayments
        ?.map(
          (bill) => `
      <tr>
        ${checkData.dataHeaders?.map((key) => `<td>${bill.data?.[key] ?? ''}</td>`).join('')}
        <td>$${bill.amount?.toFixed(2)}</td>
      </tr>`
        )
        .join('');
      checkContainers += `<div class="check-container">
        <div class="top-routing">${checkData?.checkNumber}</div>
        <div class="check-header">
          <div>
            <div>${checkData?.company?.name}</div>
            <div>${checkData?.company?.address?.streetAddress1 || ''}</div>
            <div>${checkData?.company?.address?.city || ''}, ${checkData?.company?.address?.state || ''} ${
        checkData?.company?.address?.zipCode || ''
      }</div>
          </div>
          <div>Date: ${dateFormat('MM/DD/YYYY', checkData?.date)}</div>
        </div>

        <div class="payee-section">
          <div class="half-size">This amount:</div>
          <div class="amount-section-wrapper">
            <div> **** ${checkData?.amountString}</div>
            <div class="amount-section">
              $${checkData?.amount?.toFixed(2)}
            </div>
          </div>
        </div>
        <div class="memo-section">
          ${checkData?.memo}
        </div>
        <div class="micr-font">
          c${checkData?.checkNumber}c A${checkData?.bankAccount?.routingNumber}A ${checkData?.bankAccount?.accountNumber}C
        </div>
        <div class="check-info-wrapper">
          <div class="check-information">
            <div class="date-section">
              <div>Date: ${dateFormat('MM/DD/YYYY', checkData?.date)}</div>
              <div>Check #${checkData?.checkNumber}</div>
              <div>Account: ${checkData?.bankAccount?.name}</div>
            </div>
          </div>
          <div class="check-routing">${checkData?.checkNumber}</div>
        </div>
        <table class="line-items-table">
          <thead>
           <tr>
              ${lineHeaders}
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            ${lineItems}
            <tr>
              <td class="line-items-total" colspan="1">Total:</td>
              <td>$${checkData?.amount?.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>`;
    }

    printWindow?.document.write(`
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <style>
  @font-face {
          font-family: 'MICR';
          src: url('/fonts/micr-encoding.ttf') format('truetype');
        }

        @page { size: auto;  margin: 0mm; }


        body {
          font-family: Arial, sans-serif;
          font-size: 14px;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }
        .check-container {
          width: 1000px;
          margin: 20px auto;
          padding: 30px;
          padding-top: 10px;
          padding-bottom: 20px;
          position: relative;
                              font-size: 15px;

          page-break-before: always;
        }
        .check-header {
          display: flex;
          justify-content: space-between;
             margin-bottom: 64px;
          padding: 0 60px;
        }
        .check-header div {
          text-align: left;
        }
        .half-size {
          width: 80px;
        }
        .top-routing {
          text-align: right;
          padding-bottom: 10px;
                padding: 0 60px;
        }
        .payee-section {
          margin: 0 20px 10px 0;
          display: flex;
        }
        .payee-section strong {
          font-size: 16px;
          display: block;
        }
        .amount-section-wrapper {
          display: flex;
          justify-content: space-between;
          width: 100%;
             font-size: 16px;
        }
        .amount-section {
          font-size: 16px;
          font-weight: bold;
                padding: 0 60px;
        }

        .payee-address {
          font-size: 16px;
          margin-left: 80px;
          height: 74px;
        }
        .memo-section {
          margin-top: 20px;
                              font-size: 15px;

        }
        .memo-section:before {
          content: 'MEMO';
          display: inline-block;
          margin-right: 10px;
        }
        .date-section {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          gap: 50px;
        }
        .account-section {
          margin-bottom: 10px;
          font-size: 14px;
        }
        .check-info-wrapper {
          display: flex;
          justify-content: space-between;
          margin-top: 60px;
        }
        .check-number {
          font-size: 20px;
          font-weight: bold;
          position: absolute;
          right: 30px;
          top: 30px;
        }
        .check-routing {
          font-size: 20px;
        }
        .line-items-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 10px;
          font-size: 14px;
        }
        .line-items-table th,
        .line-items-table td {
          border: 1px solid #000;
          padding: 8px;
          text-align: left;
        }
        .line-items-table th {
          background-color: #f2f2f2;
        }
        .line-items-total {
          text-align: right;
          padding-right: 10px;
          font-weight: bold;
          font-size: 14px;
        }
      .micr-font {
          font-family: 'MICR', monospace;
          font-size: 32px;
          text-align: center;
          margin-top: 30px;
          letter-spacing: 2px;
        }
      </style>
      <title>Print check</title>
    </head>
    <body>
      ${checkContainers}
    </body>
    </html>
    `);

    printWindow?.document.close();

    setTimeout(() => {
      printWindow?.print();
      printWindow?.close();
    }, 500);
  };

  return (
    <div class="thinscroll relative mx-auto flex h-full w-10/12 min-w-[710px] flex-col gap-5 overflow-x-auto">
      <div class="flex items-center justify-center pb-10">
        <Stepper class="p-9" steps={steps()} step={3} />
      </div>

      <h4 class="border-b border-partingline py-3 text-base font-semibold">{t('Review & print')}</h4>

      <Show when={props.payment?.payOuts && props.payment.payOuts.length > 0}>
        <table class="w-full table-auto border-collapse text-sm">
          <thead>
            <tr>
              <th class="border-b p-3 text-left font-medium text-slate-400">Check #</th>
              <th class="border-b p-3 text-left font-medium text-slate-400">Bank Account</th>
              <th class="border-b p-3 text-left font-medium text-slate-400">Amount</th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <For each={props.payment?.payOuts}>
              {(result) => (
                <tr>
                  <td class="border-b border-slate-100 p-3 text-slate-500">{result.check?.checkNumber}</td>
                  <td class="border-b border-slate-100 p-3 text-slate-500">{result.bankAccount?.name}</td>
                  <td class="border-b border-slate-100 p-3 text-slate-500">{currency(result.amount)}</td>
                </tr>
              )}
            </For>
          </tbody>
        </table>
        <div class="mt-4">
          <Button onClick={handlePrint}>{t('Print checks')}</Button>
        </div>
      </Show>

      <Show when={props.payment?.payOuts && Number(props.payment.payOuts?.length) > 0}>
        <table class="w-full table-auto border-collapse text-sm">
          <thead>
            <tr>
              <th class="border-b p-3 text-left font-medium text-slate-400">{t('Bank account')}</th>
              <th class="border-b p-3 text-left font-medium text-slate-400">{t('Memo')}</th>
              <th class="border-b p-3 text-left font-medium text-slate-400">{t('Amount')}</th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <For each={props.payment?.payOuts}>
              {(result) => (
                <tr>
                  <td class="border-b border-slate-100 p-3 text-slate-500">{result.bankAccount?.name}</td>
                  <td class="border-b border-slate-100 p-3 text-slate-500">{result.check?.memo}</td>
                  <td class="border-b border-slate-100 p-3 text-slate-500">{currency(result.amount)}</td>
                </tr>
              )}
            </For>
          </tbody>
        </table>
      </Show>

      <div class="sticky bottom-0 mt-auto flex justify-end gap-2 border-t border-partingline bg-white px-3 py-5 lg:px-6">
        <Button class="rounded-lg bg-primary px-4 py-2 text-white" href={'/accounting/company-revenue/pay-outs'}>
          {t('Back to pay outs')}
        </Button>
      </div>
    </div>
  );
};

export default ReviewPrintForm;

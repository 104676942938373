import { createSignal, Show, For } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
import type { PresentableOption } from '~/pdfsigner/ui/types/presentableOption';

type FontSizeSelectorProps = {
  options?: PresentableOption[];
  selectedValue?: number;
  onChange: (value: string) => void;
};

const FontSizeSelector: Component<FontSizeSelectorProps> = (props) => {
  const [isDropdownVisible, setDropdownVisible] = createSignal(false);
  const { t } = useLocalization();

  const handleClickOutside = (e: MouseEvent) => {
    if (!(e.target as HTMLElement).closest('#font-size-container')) {
      setDropdownVisible(false);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement;
      props.onChange(target.value);
      setDropdownVisible(false);
    }
  };

  document.addEventListener('click', handleClickOutside);

  return (
    <div id="font-size-container" class="relative ml-5 flex items-center space-x-2 align-middle">
      <label for="font-size-input" class="text-white">
        {t('Font size')}:
      </label>
      <div class="relative w-18">
        <input
          id="font-size-input"
          class="mt-1 block w-full rounded-md border border-gray-300 p-1 pr-8"
          value={props.selectedValue}
          onInput={(event) => props.onChange((event.target as HTMLInputElement).value)}
          onClick={() => setDropdownVisible(!isDropdownVisible())}
          onKeyDown={handleKeyDown}
        />
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <svg class="size-4 text-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-Rule="evenodd"
              d="M10 14a1 1 0 01-.707-.293l-5-5a1 1 0 111.414-1.414L10 11.586l4.293-4.293a1 1 0 111.414 1.414l-5 5A1 1 0 0110 14z"
              clip-Rule="evenodd"
            />
          </svg>
        </span>
        <Show when={isDropdownVisible()}>
          <ul class="absolute z-[100] mt-1 max-h-60 w-full overflow-auto rounded-md border border-gray-300 bg-white shadow-lg">
            <For each={props.options}>
              {(option) => (
                <li
                  class="cursor-pointer p-2 hover:bg-gray-200"
                  onMouseDown={() => {
                    props.onChange(option.text);
                    setDropdownVisible(false);
                  }}>
                  {option.text}
                </li>
              )}
            </For>
          </ul>
        </Show>
      </div>
    </div>
  );
};

export default FontSizeSelector;

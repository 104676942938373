import type { BoundingBox } from '~/pdfsigner/usecases/types/boundingBox';
import type { PageCoordinates } from '~/pdfsigner/usecases/types/pageCoordinates';
import type { Position } from '~/pdfsigner/usecases/types/position';
import type { RelativePosition } from '~/pdfsigner/usecases/types/relativePosition';

export const getPositionOfEvent = (event: MouseEvent): Position => {
  return {
    x: event.clientX,
    y: event.clientY,
  };
};

export const getPageCoordinatesForPosition = (position: Position, numberOfPages: number): PageCoordinates | undefined => {
  for (let i = 0; i < numberOfPages; i++) {
    const relativePosition = getRelativePosition(position, i);
    if (isPositionInRect(position, relativePosition.boundingBox)) {
      return {
        relativePosition,
        page: i,
      };
    }
  }
  return undefined;
};

export const getRelativePosition = (position: Position, page: number): RelativePosition => {
  const rect: DOMRect | undefined = document.getElementById('pdfPage' + page)?.getBoundingClientRect();
  return {
    position,
    boundingBox: {
      x: rect?.x || 0,
      y: rect?.y || 0,
      width: rect?.width || 0,
      height: rect?.height || 0,
    },
  };
};

const isPositionInRect = (position: Position, rect: BoundingBox) => {
  return position.x >= rect.x && position.x <= rect.x + rect.width && position.y >= rect.y && position.y <= rect.y + rect.height;
};

import { For, Show } from 'solid-js';
import AllPropertiesIcon from '~/assets/images/bill/allProperties.svg';
import AllVendorsIcon from '~/assets/images/bill/allVendors.svg';
import SomePropertiesIcon from '~/assets/images/bill/someProperties.svg';
import SomeVendorsIcon from '~/assets/images/bill/someVendors.svg';
import PlusIcon from '~/assets/images/common/plus.svg?component-solid';
import IconRemove from '~/assets/images/common/remove.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { LabeledInputSearch } from '~/components/common/Inputs/LabeledInputSearch';
import { OptionButton } from '~/components/common/OptionButton';
import { PropertyTitle } from '~/components/properties';
import { useLocalization } from '~/contexts/global';
import VendorItem from '~/pages/bills/components/VendorItem';
import { isUndefined } from '~/utils/tool';
import type { Component } from 'solid-js';
import type { PayBillsSubForm } from '~/pages/bills/pay-bill/types';

const ChooseBillsForm: Component<PayBillsSubForm> = (props) => {
  const { t } = useLocalization();

  const setItemData = (key: 'properties' | 'vendors', index?: number, value?: any) => {
    props.setFormData(key, (prev) => {
      const start = index ?? prev.length + 1;
      const deleteCount = isUndefined(index) ? 0 : 1;
      const item = value ?? '';
      return prev.toSpliced(start, deleteCount, item);
    });
    props.setNeedToUpdate && props.setNeedToUpdate(true);
  };

  const removeLine = (key: 'properties' | 'vendors', index: number) => {
    props.setFormData(key, (prev) => {
      return prev.toSpliced(index, 1);
    });
    props.setNeedToUpdate && props.setNeedToUpdate(true);
  };

  return (
    <div class="mx-auto flex w-10/12 flex-col gap-5">
      <div>
        <h4 class="border-b border-partingline py-3 text-base font-semibold">{t('Properties')}</h4>
        <div class="grid grid-cols-2 gap-4 py-4">
          <OptionButton
            class="h-20"
            Icon={AllPropertiesIcon}
            label={t('All properties')}
            isActive={props.form.isAllProperties}
            onClick={() => {
              props.setFormData('isAllProperties', true);
              props.setFormData('properties', []);
              props.setNeedToUpdate && props.setNeedToUpdate(true);
            }}
          />
          <OptionButton
            class="h-20"
            Icon={SomePropertiesIcon}
            label={t('Some properties')}
            isActive={!props.form.isAllProperties}
            onClick={() => {
              props.setFormData('isAllProperties', false);
              props.setFormData('properties', ['']);
              props.setNeedToUpdate && props.setNeedToUpdate(true);
            }}
          />
          <Show when={!props.form.isAllProperties}>
            <For each={props.form.properties}>
              {(item, index) => (
                <>
                  <LabeledInputSearch
                    allowClear={false}
                    required
                    label={t(`Property`)}
                    options={props.store.properties}
                    renderItem={(item) => <PropertyTitle feedbackIconReadonly property={item} />}
                    loading={props.store.propertiesLoading}
                    placeholder={t('Select property')}
                    value={item}
                    onSelect={(val) => setItemData('properties', index(), val)}
                  />
                  <Show
                    when={index() === props.form.properties.length - 1}
                    fallback={
                      <div class="ml-5 flex items-center">
                        <IconRemove class="cursor-pointer text-link" onClick={() => removeLine('properties', index())} />
                      </div>
                    }>
                    <div class="flex items-center">
                      <Button color="link" variant="outlined" onClick={() => setItemData('properties')}>
                        <PlusIcon class="hidden sm:block" /> {t('Add a new property')}
                      </Button>
                    </div>
                  </Show>
                </>
              )}
            </For>
          </Show>
        </div>
      </div>
      <div>
        <h4 class="border-b border-partingline py-3 text-base font-semibold">{t('Vendors')}</h4>
        <div class="grid grid-cols-2 gap-4 py-4">
          <OptionButton
            class="h-20"
            Icon={AllVendorsIcon}
            label={t('All vendors')}
            isActive={props.form.isAllVendors}
            onClick={() => {
              props.setFormData('isAllVendors', true);
              props.setFormData('vendors', []);
              props.setNeedToUpdate && props.setNeedToUpdate(true);
            }}
          />
          <OptionButton
            class="h-20"
            Icon={SomeVendorsIcon}
            label={t('Some vendors')}
            isActive={!props.form.isAllVendors}
            onClick={() => {
              props.setFormData('isAllVendors', false);
              props.setFormData('vendors', ['']);
              props.setNeedToUpdate && props.setNeedToUpdate(true);
            }}
          />
          <Show when={!props.form.isAllVendors}>
            <For each={props.form.vendors}>
              {(item, index) => (
                <>
                  <LabeledInputSearch
                    allowClear={false}
                    required
                    label={t(`Vendor`)}
                    options={props.store.vendors}
                    renderItem={(item) => <VendorItem vendor={item} />}
                    loading={props.store.vendorLoading}
                    placeholder={t('Please select vendor')}
                    value={item}
                    onSelect={(val) => setItemData('vendors', index(), val)}
                  />
                  <Show
                    when={index() === props.form.vendors.length - 1}
                    fallback={
                      <div class="ml-5 flex items-center">
                        <IconRemove class="cursor-pointer text-link" onClick={() => removeLine('vendors', index())} />
                      </div>
                    }>
                    <div class="flex items-center">
                      <Button variant="outlined" color="link" onClick={() => setItemData('vendors')}>
                        <PlusIcon class="hidden sm:block" /> {t('Add a new vendor')}
                      </Button>
                    </div>
                  </Show>
                </>
              )}
            </For>
          </Show>
        </div>
      </div>
    </div>
  );
};

export default ChooseBillsForm;

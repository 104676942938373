import { createMemo } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

export const TenantPortalStatus: Component<{ class?: string; status?: `${MagicDoor.Api.PortalStatus}` }> = (props) => {
  const { t } = useLocalization();
  const status = createMemo(() => {
    const map: Record<MagicDoor.Api.PortalStatus, { label: string; color: string }> = {
      active: { label: t('Active'), color: 'text-active' },
      invited: { label: t('Invited'), color: 'text-blue' },
      notInvited: { label: t('Not invited'), color: 'text-warning' },
    };
    return props?.status && map[props.status];
  });
  return (
    <div class={cn('flex items-center gap-1 rounded-full bg-current-alpha px-2 py-0.5', status()?.color, props.class)}>
      <i class="block size-1.5 rounded-full bg-current" />
      {status()?.label}
    </div>
  );
};

import { createMemo, splitProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { DeactivatedFlag } from '~/components/common';
import { Avatar } from '~/components/common/Avatar';
import { pickProps, TitleWithFeedbackIcon } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import { cn } from '~/utils/classnames';
import { getTenantFullName } from './utils';
import type { TitleWithFeedbackIconProps } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import type { PolymorphicComponent } from '~/utils/types';

export const TenantTitle: PolymorphicComponent<
  'div',
  {
    class?: string;
    tenant?: MagicDoor.Api.HydratedTenantDto;
  } & Partial<TitleWithFeedbackIconProps>
> = (props) => {
  const [, rest] = splitProps(props, ['as', 'class', 'tenant']);
  const initials = createMemo(() => getTenantFullName(props.tenant));

  return (
    <TitleWithFeedbackIcon {...pickProps(rest)} type="tenants" entityId={props.tenant?.id} initalSelectedIcons={props.tenant?.icons}>
      <Dynamic component={props.as || 'div'} class={cn('flex items-center gap-2', props.class)} {...rest}>
        <Avatar name={initials()} data-slot="avatar" />
        <span data-slot="name">{initials()}</span>
        <DeactivatedFlag active={props.tenant?.active} />
      </Dynamic>
    </TitleWithFeedbackIcon>
  );
};

import { Show } from 'solid-js';
import { Popover } from '~/components/ui';
import { cn } from '~/utils/classnames';
import type { Component, JSX } from 'solid-js';

export type DropdownProps = {
  class?: string;
  contentClass?: string;
  /** @deprecated unnecessary */
  contentPosition?: ('top' | 'left' | 'right' | 'bottom')[];
  renderLabel?: (isOpen: boolean, open: (visible: boolean) => void) => JSX.Element;
  children?: (isOpen: boolean, open: (visible: boolean) => void) => JSX.Element | JSX.Element[];
  /** @deprecated unnecessary */
  onClick?: (isMouseDown: boolean) => void;
  triggerElement?: JSX.Element;
  tiggerAs?: string;
  triggerClass?: string;
  disabled?: boolean;
};

export const Dropdown: Component<DropdownProps> = (props) => {
  return (
    <Popover class={props.class}>
      {({ open, setOpen }) => (
        <>
          {props.renderLabel?.(open(), setOpen)}
          <Show when={!props.renderLabel && props.triggerElement}>
            <Popover.Trigger disabled={props.disabled} class={props.triggerClass} as={props.tiggerAs}>
              {props.triggerElement}
            </Popover.Trigger>
          </Show>
          <Popover.Content
            align="start"
            class={cn(
              'thinscroll my-2 min-w-[--reference-width] rounded-md border border-gray-300 bg-white px-2 py-3 text-sm text-text-level02 shadow-lg',
              props.contentClass
            )}>
            {props.children?.(open(), setOpen)}
          </Popover.Content>
        </>
      )}
    </Popover>
  );
};

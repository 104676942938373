import { createEffect } from 'solid-js';
import type { JSX } from 'solid-js';
import type { PresentableAnnotation } from '~/pdfsigner/presenters/PdfPresenter';

export interface AnnotationProps {
  parentRect: DOMRect | undefined;
  presentableAnnotation: PresentableAnnotation;
  onStartDragging?: (id: string, event: MouseEvent) => void;
  onKeyPressed?: (id: string, event: KeyboardEvent) => void;
  onInput?: (id: string, event: InputEvent, value: string) => void;
  onStartEditting?: (id: string, event: MouseEvent) => void;
  onStartResizing?: (id: string, event: MouseEvent) => void;
}

export const Annotation = (props: AnnotationProps): JSX.Element => {
  let textArea: HTMLTextAreaElement;

  createEffect(() => {
    if (textArea && props.presentableAnnotation.isSelected) {
      setTimeout(() => {
        textArea?.focus();
      }, 0);
    }
  });

  return (
    <div
      onMouseDown={(event) => props.onStartDragging?.(props.presentableAnnotation.id, event)}
      onKeyDown={(event) => props.onKeyPressed?.(props.presentableAnnotation.id, event)}
      class="absolute z-30 flex cursor-grab border border-solid p-0.5"
      style={{
        left: `${props.presentableAnnotation.x}px`,
        top: `${props.presentableAnnotation.y}px`,
        width: `${props.presentableAnnotation.width}px`,
        height: `${props.presentableAnnotation.height}px`,
        'background-color': `${props.presentableAnnotation.backgroundColor}`,
        'border-color': `${props.presentableAnnotation.borderColor}`,
      }}>
      <textarea
        class="grow resize-none bg-transparent"
        ref={(el) => (textArea = el)}
        value={props.presentableAnnotation.text}
        readOnly={props.presentableAnnotation.isReadOnly}
        onMouseDown={(event: MouseEvent) => props.onStartEditting?.(props.presentableAnnotation.id, event)}
        onInput={(event: InputEvent) => props.onInput?.(props.presentableAnnotation.id, event, textArea?.value || '')}
        style={{
          'font-family': `${props.presentableAnnotation.fontType}`,
          'font-size': `${props.presentableAnnotation.fontSize}pt`,
        }}
      />
      <div
        class="absolute bottom-0 right-0 size-0 cursor-nwse-resize border-b-[12px] border-l-[12px] border-b-gray-500 border-l-transparent bg-transparent"
        onMouseDown={(event) => props.onStartResizing?.(props.presentableAnnotation.id, event)}
      />
    </div>
  );
};

import { BaseRestRepository } from '~/repositories/baseRestRepository';

const RENTAL_APPLICATION_PAYMENT_URL = '/api/settings/rental-application/payment-account';

export class RentalApplicationPaymentSettingRepository extends BaseRestRepository {
  public async getRentalApplicationPaymentAccountSettings(): Promise<MagicDoor.Api.RentalApplicationPaymentAccountSettingsDto> {
    const url = RENTAL_APPLICATION_PAYMENT_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async deleteRentalApplicationPaymentAccountSettings() {
    const url = RENTAL_APPLICATION_PAYMENT_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }

  public async updateRentalApplicationAccountPaymentSettings(
    payload: MagicDoor.Api.RentalApplicationPaymentAccountSettingsDto
  ): Promise<MagicDoor.Api.RentalApplicationPaymentAccountSettingsDto> {
    const url = RENTAL_APPLICATION_PAYMENT_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }
}

import { createSignal, createEffect } from 'solid-js';
import { Section } from '~/components/common/Section';
import { Skeleton } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';

type RentReceivableProps = {
  loading?: boolean;
  class?: string;
  totalRent?: number;
  outstandingBalances?: number;
};

export const RentReceivable: Component<RentReceivableProps> = (props) => {
  const { t } = useLocalization();
  const [width, setWidth] = createSignal<number>();

  createEffect(() => {
    const rent = props?.totalRent ?? 0;
    const balance = props?.outstandingBalances ?? 0;
    const total = rent + balance;
    let percentage = rent != 0 ? (total / rent) * 100 : 0;
    percentage = Math.min(percentage, 100);
    percentage = Math.max(percentage, 0);
    setWidth(percentage);
  });

  return (
    <Section title={t('Rent receivable')} class={`flex-1 ${props.class}`} contentClass="p-5">
      <div class="flex overflow-hidden rounded-md">
        <i class={cn('h-3 bg-success transition-all duration-1000')} style={{ width: `${width()}%` }} aria-label={t('Rent proportion')} />
        <i class={cn('h-3 grow bg-danger')} aria-label={t('Outstanding balances proportion')} />
      </div>
      <div class="mt-3 flex gap-2">
        <div class="flex-1 bg-light-gray px-3 py-2.5 text-left">
          <i class="mr-1 inline-block size-2 rounded-sm bg-success" />
          <span class="text-xs">{t('Rent')}</span>
          <p class="mt-1.5 text-sm font-semibold text-title-gray">
            {props.loading ? <Skeleton class="w-24" /> : currency(props?.totalRent ?? 0)}
          </p>
        </div>
        <div class="flex-1 rounded-lg bg-light-gray px-3 py-2.5 text-left">
          <i class="mr-1 inline-block size-2 rounded-sm bg-danger" />
          <span class="text-xs">{t('Outstanding balances')}</span>
          <p class="mt-1.5 text-sm font-semibold text-title-gray">
            {props.loading ? <Skeleton class="w-24" /> : currency(props?.outstandingBalances ?? 0)}
          </p>
        </div>
      </div>
    </Section>
  );
};

import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { tenantBalanceDueNotificationRepository } from '~/repositories/settings/tenantBalanceDueNotificationRepository';
import type { Accessor } from 'solid-js';

interface TenantBalanceDueNotificationContextValue {
  updateTenantBalanceDueNotificationSettingsByLevel: (
    level: string,
    levelId: string,
    payload: MagicDoor.Api.TenantBalanceDueNotificationSettingsDto
  ) => Promise<void>;
  deleteTenantBalanceDueNotificationSettingsByLevel: (level: string, levelId: string) => Promise<void>;
  tenantBalanceDueNotificationSettings: Accessor<MagicDoor.Api.TenantBalanceDueNotificationSettingsDtoSettingsWithOverridesDto>;
  isLoading: Accessor<boolean>;
  getTenantBalanceDueNotificationSettings: () => void;
  updateTenantBalanceDueNotificationSettings: (data: MagicDoor.Api.TenantBalanceDueNotificationSettingsDto) => void;
  error: Accessor<Error | undefined>;
}

export const [TenantBalanceDueNotificationProvider, useTenantBalanceDueNotification] =
  createMagicDoorContext<TenantBalanceDueNotificationContextValue>('TenantBalanceDueNotification', () => {
    const repo = new tenantBalanceDueNotificationRepository();

    const [isLoading, setIsLoading] = createSignal<boolean>(false);
    const [tenantBalanceDueNotificationSettings, setTenantBalanceDueNotificationSettings] =
      createSignal<MagicDoor.Api.TenantBalanceDueNotificationSettingsDtoSettingsWithOverridesDto>(
        {} as MagicDoor.Api.TenantBalanceDueNotificationSettingsDtoSettingsWithOverridesDto
      );

    const [error, setError] = createSignal<Error | undefined>(undefined);

    const getTenantBalanceDueNotificationSettings = async () => {
      setIsLoading(true);
      try {
        const result = await repo.getTenantBalanceDueNotificationAll();
        setTenantBalanceDueNotificationSettings(result);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const updateTenantBalanceDueNotificationSettings = async (data: MagicDoor.Api.TenantBalanceDueNotificationSettingsDto) => {
      setIsLoading(true);
      try {
        await repo.updateTenantBalanceDueNotification(data);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const updateTenantBalanceDueNotificationSettingsByLevel = async (
      level: string,
      levelId: string,
      payload: MagicDoor.Api.TenantBalanceDueNotificationSettingsDto
    ) => {
      setIsLoading(true);
      try {
        await repo.updateTenantBalanceDueNotificationByLevel(level, levelId, payload);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const deleteTenantBalanceDueNotificationSettingsByLevel = async (level: string, levelId: string) => {
      setIsLoading(true);
      try {
        await repo.deleteTenantBalanceDueNotificationByLevel(level, levelId);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    return {
      error,
      tenantBalanceDueNotificationSettings,
      isLoading,
      getTenantBalanceDueNotificationSettings,
      updateTenantBalanceDueNotificationSettings,
      updateTenantBalanceDueNotificationSettingsByLevel,
      deleteTenantBalanceDueNotificationSettingsByLevel,
    };
  });

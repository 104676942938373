import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization } from '~/contexts/global';

const VendorMemoSkeleton = () => {
  const { t } = useLocalization();

  return (
    <ThreeSectionBox
      topTitle={<div>{t('Memo')}</div>}
      middle={
        <div class="flex items-center p-4">
          <div class="skeleton-shimmer h-6 w-32" />
        </div>
      }
    />
  );
};

export default VendorMemoSkeleton;

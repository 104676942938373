import { ApplicationsTable } from './ApplicationsTable';
import type { Component } from 'solid-js';

export const RentalApplication: Component = () => {
  return (
    <div class="text-left">
      <ApplicationsTable />
    </div>
  );
};

import IconCoin from '~/assets/images/property/coin.png';
import IconRevenueBg from '~/assets/images/property/revenueBg.png';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';

export const NetOperatingIncome = () => {
  const { t } = useLocalization();

  return (
    <Panel title={t('Net operating income')} class="h-full">
      <div class="flex flex-col gap-3 px-6 py-3">
        <div
          class="relative flex h-2/3 w-full flex-col items-start	 rounded-md bg-[#F64852] bg-left-bottom bg-no-repeat text-white"
          style={{ 'background-image': `url(${IconRevenueBg})` }}>
          <div class="p-4">
            <div class="text-2xl font-semibold">$25,268</div>
            <div class="text-sm text-white/60">{t('Net operating income')}</div>
          </div>

          <div class=" absolute bottom-0 right-0 flex w-full items-end justify-end">
            <img src={IconCoin} class="h-20 w-auto" />
          </div>
        </div>

        <div class="flex h-1/3 flex-col rounded-md border  border-partingline  p-4">
          <div class="text-lg font-semibold">
            <span class="text-danger">10</span> {t('Units')}
          </div>
          <div class="text-sm text-text-level03">{t('With more expenses than last month')} </div>
        </div>
      </div>
    </Panel>
  );
};

import { For } from 'solid-js';
import type { JSX } from 'solid-js';
import type { PresentableListBox } from '~/pdfsigner/ui/types/presentableListBox';
import type { PresentableOption } from '~/pdfsigner/ui/types/presentableOption';

export interface ListBoxProps {
  listBox?: PresentableListBox;
  onChange: (vaue: number) => void;
}

const ListBox = (props: ListBoxProps): JSX.Element => {
  const handleSelectionChange = async (event: Event) => {
    const target = event.target as HTMLSelectElement;
    props.onChange(Number(target.value));
  };

  return (
    <select class="ml-2 w-[200px] rounded-md border-solid border-white p-0.5" onChange={handleSelectionChange}>
      <For each={props.listBox?.options}>
        {(option: PresentableOption) => (
          <option value={option.value} selected={option.isSelected}>
            {option.text}
          </option>
        )}
      </For>
    </select>
  );
};

export default ListBox;

import { For } from 'solid-js';
import IconSummaryBill from '~/assets/images/vendors/summaryBill.png';
import IconSummaryCommunication from '~/assets/images/vendors/summaryCommunications.png';
import IconSummaryTransaction from '~/assets/images/vendors/summaryTransaction.png';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization } from '~/contexts/global';

interface VendorSummaryInformationProps {
  name: string;
  src: string;
}

const VendorSummaryInformationSkeleton = (props: VendorSummaryInformationProps) => {
  return (
    <ThreeSectionBox
      class="w-full"
      topTitle={<div class="text-sm font-semibold text-text-level02">{props.name}</div>}
      middle={
        <div class="flex justify-between p-4">
          <div class="flex w-full flex-col gap-2">
            <div class="skeleton-shimmer h-6 w-20" />
            <div class="skeleton-shimmer flex h-4 w-28" />
          </div>
          <img class="size-12" src={props.src} alt={props.name} />
        </div>
      }
    />
  );
};

const VendorSummarySkeleton = () => {
  const { t } = useLocalization();

  const vendorSummaryData = [
    {
      name: t('Total bill'),
      src: IconSummaryBill,
    },
    {
      name: t('Gross transaction'),
      src: IconSummaryTransaction,
    },
    {
      name: t('Communications'),
      src: IconSummaryCommunication,
    },
  ];

  return (
    <div class="flex w-full gap-x-4">
      <For each={vendorSummaryData}>{(info) => <VendorSummaryInformationSkeleton {...info} />}</For>
    </div>
  );
};

export default VendorSummarySkeleton;

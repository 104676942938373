import { Route, Routes } from '@solidjs/router';
import { ContributionsWrap } from './contributions';
import { PropertyDepositPaymentSettingsWrapper } from './deposit-payment';
import { PropertyMaintenanceRequestSettings } from './maintenance-request';
import { ManagementFees } from './management-fees';
import { PropertyPropertySettingsWrapper } from './property';
import { PropertyLateFees } from './property-late-fees';
import { PropertyRentNotification } from './property-rent-notification';
import { PropertyRentPaymentSettingsWrapper } from './rent-payment';
import { RentPaymentProcessingFeesSettingsPage } from './rent-payment-processing-fees';
import { WorkOrderSettingsWrapper } from './work-order';

const PropertyRoutes = () => {
  return (
    <Routes>
      <Route path="/general" component={PropertyPropertySettingsWrapper} />
      <Route path="/rent-notifications" component={PropertyRentNotification} />
      <Route path="/rent-payment" component={PropertyRentPaymentSettingsWrapper} />
      <Route path="/late-fees" component={PropertyLateFees} />
      <Route path="/management-fees" component={ManagementFees} />
      <Route path="/settings-maintenance" component={PropertyMaintenanceRequestSettings} />
      <Route path="/rent-payment-processing-fees" component={RentPaymentProcessingFeesSettingsPage} />
      <Route path="/deposit-payment" component={PropertyDepositPaymentSettingsWrapper} />
      <Route path="/work-order" component={WorkOrderSettingsWrapper} />
      <Route path="/contributions" component={ContributionsWrap} />
    </Routes>
  );
};

export default PropertyRoutes;

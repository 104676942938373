import { For, Show } from 'solid-js';
import { FileThumbnail } from '~/components/file-attachments/FileThumbnail';
import { Skeleton } from '~/components/ui';
import type { Component } from 'solid-js';

export const ListFileThumbnailWithPreview: Component<{
  class?: string;
  loading?: boolean;
  files: MagicDoor.Api.FileDto[] | undefined;
}> = (props) => {
  return (
    <Show
      when={!props.loading}
      fallback={
        <div class="mt-2 grid grid-cols-[repeat(auto-fill,minmax(116px,1fr))] gap-2">
          <Skeleton class="aspect-square w-full" />
          <Skeleton class="aspect-square w-full" />
        </div>
      }>
      <Show when={props.files?.length}>
        <div class="mt-2 grid grid-cols-[repeat(auto-fill,minmax(116px,1fr))] gap-2">
          <For each={props.files}>{(file) => <FileThumbnail file={file} />}</For>
        </div>
      </Show>
    </Show>
  );
};

import { createMemo, createSignal, For, Show } from 'solid-js';
import CircleArrowIcon from '~/assets/images/common/circleArrow.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { PropertyTitle } from '~/components/properties';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions } from '~/contexts/local';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import { DistributionRow } from './DistributionRow';

type Record = MagicDoor.Api.CalculatedDistributionsForPropertyDto;

export const OwnerDistributionsEditListView = () => {
  const { t } = useLocalization();
  const { ownerDistributions, store, onChangeOwnerDistributionChecked, updateOwnerDistributionRecord, paymentTypeOptions } =
    useAddOwnerDistributions();
  const [collapsedRecordIds, setCollapsedRecordIds] = createSignal<Set<string>>(new Set());
  const totalDistributedAmount = createMemo(() => store.editingDistributions.reduce((acc, cur) => acc + cur.distributionAmount, 0));

  const toggleRecordExpansion = (recordId: string | undefined) => {
    if (!recordId) return;
    setCollapsedRecordIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(recordId)) {
        newSet.delete(recordId);
      } else {
        newSet.add(recordId);
      }
      return newSet;
    });
  };

  const isRecordExpanded = (recordId: string | undefined) => {
    return recordId ? !collapsedRecordIds().has(recordId) : false;
  };

  const toggleAllRecords = () => {
    const allRecordIds = ownerDistributions()
      ?.map((record) => record.property?.id)
      .filter(Boolean) as string[];
    if (collapsedRecordIds().size === 0) {
      setCollapsedRecordIds(new Set(allRecordIds));
    } else {
      setCollapsedRecordIds(new Set<string>());
    }
  };

  const isAllExpanded = createMemo(() => collapsedRecordIds().size === 0);
  const getPaymentTypeForProperty = (propertyId: string) => {
    const distributions = store.editingDistributions.filter((d) => d.propertyId === propertyId);
    const uniquePaymentTypes = [...new Set(distributions.map((d) => d.paymentType))];
    return uniquePaymentTypes.length === 1 ? uniquePaymentTypes[0] : null;
  };

  const columns = [
    {
      title: t('Property'),
      class: 'w-[30%]',
      render: (item: Record) => (
        <PropertyTitle
          property={item.property}
          class={cn('h-9 max-w-[320px] [&>img]:size-9 [&_h4]:text-xs [&_h4]:font-medium', { 'max-w-[232px]': item.property?.icons.length })}
        />
      ),
    },
    {
      title: t('Payment type'),
      class: 'w-[14%] text-xs font-normal text-title-gray',
      render: (item: Record) => {
        const paymentType = createMemo(() => getPaymentTypeForProperty(item.property?.id ?? ''));
        return (
          <Show when={paymentType() !== null} fallback={<span class="text-text-level02">{t('Multiple')}</span>}>
            <DropdownMenu
              value={paymentType() || 'manual'}
              options={paymentTypeOptions}
              onChange={(value) => {
                store.editingDistributions
                  .filter((d) => d.propertyId === item.property?.id)
                  .forEach((d) => updateOwnerDistributionRecord(d.propertyId, d.ownerId, { key: 'paymentType', value }));
              }}
            />
          </Show>
        );
      },
    },
    {
      title: t('Property funds'),
      class: 'text-right w-[14%] text-xs font-normal text-title-gray',
      render: (item: Record) => currency(item.currentPropertyBalance),
    },
    {
      title: t('Reserve'),
      class: 'text-right w-[14%] text-xs font-normal text-title-gray',
      render: (item: Record) => currency(item.propertyReserve),
    },
    {
      title: t('Balance'),
      class: 'text-right w-[14%] text-xs font-normal text-title-gray',
      render: (item: Record) => currency(item.propertyBalance),
    },
    {
      title: t('Distribution amount'),
      class: 'text-right w-[18%] text-xs font-normal text-title-gray',
      render: (item: Record) => currency(item.availableForDistribution),
    },
    {
      title: '',
      class: 'w-[10%]',
      render: (item: Record) => (
        <div class="flex items-center gap-3">
          <div class="w-fit cursor-pointer rounded bg-transparent p-1 transition-all hover:bg-input-border">
            <CircleArrowIcon
              onClick={() => toggleRecordExpansion(item.property?.id)}
              classList={{
                'text-text-level02': collapsedRecordIds().has(item.property?.id || ''),
                'text-primary rotate-180': !collapsedRecordIds().has(item.property?.id || ''),
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div class="flex flex-col">
      <div class="mb-4 flex items-end justify-end">
        <Button onClick={toggleAllRecords} variant="outlined" size="sm">
          {isAllExpanded() ? t('Collapse all') : t('Expand all')}
        </Button>
      </div>
      <div class="flex border-t border-input-border bg-white text-xs text-text-level02">
        <For each={columns}>
          {(column) => <div class={cn('whitespace-nowrap p-4 uppercase text-text-level02', column.class)}>{column.title}</div>}
        </For>
      </div>
      <div class="flex flex-col gap-4">
        <For each={ownerDistributions()}>
          {(record) => (
            <div class="flex items-center gap-3">
              <Checkbox checked={record.checked} onInput={(checked) => onChangeOwnerDistributionChecked(checked, record.property?.id)} />
              <div class="flex-1">
                <DistributionRow
                  isExpanded={isRecordExpanded(record.property?.id)}
                  isLoading={ownerDistributions.loading}
                  columns={columns}
                  record={record}
                  onToggleExpand={() => toggleRecordExpansion(record.property?.id)}
                />
              </div>
            </div>
          )}
        </For>
      </div>
      <div class="mt-[18px] text-right">
        <span class="text-sm text-text-level02">{t('PAYMENT DISTRIBUTION')}: </span>
        <span class="text-base font-semibold text-essential-colour">{currency(totalDistributedAmount())}</span>
      </div>
    </div>
  );
};

import { useNavigate, useSearchParams } from '@solidjs/router';
import dayjs from 'dayjs';
import { createEffect, Show, createMemo, createSignal, on, untrack } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { PrintButton } from '~/components/common/Buttons';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { DatePicker, IconLoader } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { BankAccountsListProvider, useBankAccountsList, useReports } from '~/contexts/local';
import { compareTwoDateRange } from '~/pages/reports/utils';
import { parseColumns, ReportTable } from './components/Report';
import type { LineItem } from './components/Report';
import type { Component } from 'solid-js';

const BankAccountActivityReport: Component<{ class?: string; report?: MagicDoor.Api.BankAccountActivityReportDto }> = (props) => {
  const { t } = useLocalization();

  function renderExternalTransaction(activity: MagicDoor.Api.BankAccountActivityDto) {
    return (
      <div>
        <span class="capitalize">{activity.externalTransactionType}</span>
        <Show when={activity.externalTransactionId}>{` - ${activity.externalTransactionId}`}</Show>
      </div>
    );
  }

  function renderTransactionPayment(activity: MagicDoor.Api.BankAccountActivityDto) {
    return (
      <div>
        <span class="capitalize">{activity.transactionPaymentMethod}</span>
      </div>
    );
  }

  function renderTransactionStatus(activity: MagicDoor.Api.BankAccountActivityDto) {
    return (
      <div>
        <span class="capitalize">{activity.transactionStatus}</span>
      </div>
    );
  }

  function getTransactionLines() {
    const res: LineItem[] = [];

    if (!props.report) return res;

    res.push({
      label: t('Starting balance'),
      level: 0,
      type: 'item',
      values: {
        balance: props.report.startingBalance,
      } as any,
    });

    props.report.activities?.forEach((activity) => {
      res.push({
        label: dayjs(activity.date).format('MM/DD/YYYY'),
        level: 0,
        values: {
          ...activity,
          cleared: activity.cleared ? 'Yes' : 'No',
          companyBill: activity.companyBill ? 'Yes' : 'No',
          externalTransaction: renderExternalTransaction(activity),
          transactionPaymentMethod: renderTransactionPayment(activity),
          transactionStatus: renderTransactionStatus(activity),
        } as any,
      });
    });

    res.push({
      label: t('Net change'),
      type: 'item',
      level: 0,
      values: {
        balance: props.report.netChange,
      } as any,
    });

    res.push({
      label: t('Total'),
      type: 'total',
      level: 0,
      values: {
        ...props.report.activitiesTotal,
      } as any,
    });

    return res;
  }

  return (
    <>
      <style>
        {`[data-slot='label'] {
        min-width: 160px!important;
      }`}
      </style>
      <ReportTable
        label={t('Date')}
        lines={getTransactionLines()}
        columns={parseColumns([
          {
            name: 'Payee or payer',
            id: 'payeeOrPayerName',
            type: 'string',
          },
          {
            name: 'Type',
            id: 'transactionPaymentMethod',
            type: 'string',
          },
          {
            name: 'Status',
            id: 'transactionStatus',
            type: 'string',
          },
          {
            name: 'External transaction',
            id: 'externalTransaction',
            type: 'string',
          },
          {
            name: 'Company bill',
            id: 'companyBill',
            type: 'string',
          },
          {
            name: 'Cleared',
            id: 'cleared',
            type: 'string',
          },
          {
            name: 'Cash in',
            id: 'cashIn',
          },
          {
            name: 'Cash out',
            id: 'cashOut',
          },
          {
            name: 'Balance',
            id: 'balance',
          },
          {
            name: 'Description',
            id: 'description',
            type: 'string',
          },
        ])}
      />
    </>
  );
};

const BankAccountActivityReportPageInner = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const [printContainer, setPrintContainer] = createSignal<HTMLDivElement>();

  const [searchParams, setSearchParams] = useSearchParams<{
    bankAccountId?: string;
    start: string;
    end: string;
  }>();

  const { bankAccounts, isLoading: bankAccountsLoading } = useBankAccountsList();
  const { bankAccountActivityReport, getBankAccountActivityReport } = useReports();

  const breadcrumbItems = createMemo(() => [{ label: t('Reports'), link: '/reports' }, { label: t('Bank account activity report') }]);

  const handleBankAccountChange = (value: string) => {
    setSearchParams({ bankAccountId: value });
  };

  function shouldNotUpdate(params: any, preParams: any) {
    return (
      params?.bankAccountId === preParams?.bankAccountId && compareTwoDateRange([params, untrack(bankAccountActivityReport)?.dateRange])
    );
  }

  createEffect(
    on(
      () => ({ ...searchParams }),
      (params, preParams) => {
        if (shouldNotUpdate(params, preParams)) return;
        const { bankAccountId } = searchParams;

        if (bankAccountId) {
          getBankAccountActivityReport({
            dateRange: { start: searchParams.start, end: searchParams.end },
            bankAccountId,
          });
        }
      }
    )
  );

  createEffect(
    on(bankAccounts, () => {
      const _items = bankAccounts();
      if (_items && _items.length > 0) {
        setSearchParams({ bankAccountId: _items[0].id });
      }
    })
  );

  createEffect(() => {
    const report = bankAccountActivityReport();
    if (report?.dateRange == null) return;
    setSearchParams({ start: report.dateRange.start, end: report.dateRange.end });
  });

  const printHeader = createMemo(() => {
    const start = searchParams.start;
    const end = searchParams.end;
    return (
      <div>
        <div class="flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Bank account activity report')}</h1>
        </div>
        <div class="my-6 flex flex-wrap items-center gap-4">
          <div>
            {t('Bank account')} : {bankAccountActivityReport()?.account?.name}
          </div>
          <div>
            {t('Date range start')} : {start}
          </div>
          <div>
            {t('Date range end')} : {end}
          </div>
        </div>
      </div>
    );
  });

  const [printContainerWidth, setPrintContainerWidth] = createSignal<string>('2000px');

  createEffect(() => {
    if (!bankAccountActivityReport.loading) {
      const clientWidth = printContainer()?.children[1]?.children[1]?.clientWidth;
      clientWidth && setPrintContainerWidth(`${clientWidth + clientWidth * 0.2}px`);
    }
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="m-8 rounded-lg border border-partingline bg-white p-8">
        <div class="flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Bank account activity report')}</h1>
          <PrintButton
            // disabled={bankAccountActivityReport.loading || bankAccountsLoading()}
            printContainer={printContainer()!}
            isReport={true}
            printHeader={printHeader() as Element}
            printWrapClass="w-auto"
            extraStyle={`width:${printContainerWidth()};zoom:0.4`}
          />
        </div>
        <div class="my-6 flex flex-wrap items-center gap-3">
          <DropdownMenu
            value={searchParams.bankAccountId}
            options={bankAccounts().map((account) => ({ label: account.name, value: account.id }))}
            onChange={handleBankAccountChange}
          />
          <DatePicker
            class="min-w-64 grow"
            placeholder={t('Date range start')}
            value={searchParams.start}
            onChange={(value) => {
              if (value !== null && searchParams.end <= value) {
                const start = new Date(value);
                start.setDate(start.getDate() + 30);
                searchParams.end = start.toISOString().split('T')[0];
              }
              setSearchParams({ ...searchParams, start: value });
            }}
          />
          <DatePicker
            class="min-w-64 grow"
            placeholder={t('Date range end')}
            value={searchParams.end}
            onChange={(value) => {
              if (value !== null && searchParams.start >= value) {
                const end = new Date(value);
                end.setDate(end.getDate() - 30);
                searchParams.start = end.toISOString().split('T')[0];
              }
              setSearchParams({ ...searchParams, end: value });
            }}
          />
        </div>
        <div ref={(e) => setPrintContainer(e)}>
          <Show
            when={bankAccountActivityReport.loading || bankAccountsLoading()}
            fallback={<BankAccountActivityReport report={bankAccountActivityReport()} />}>
            <IconLoader class="mx-auto my-56 animate-spin" />
          </Show>
        </div>
      </div>
    </>
  );
};

export const BankAccountActivityReportPage = () => {
  return (
    <BankAccountsListProvider>
      <BankAccountActivityReportPageInner />
    </BankAccountsListProvider>
  );
};

import { createEffect, onMount, onCleanup, splitProps, untrack } from 'solid-js';
import { createStore } from 'solid-js/store';
import { contextCreator } from '~/utils/contextCreator';
import { scrollToSection } from '~/utils/scrollToSection';
import { debounce } from '~/utils/tool';
import type { Component, ParentProps } from 'solid-js';
import type { SidebarProgress } from '~/components/common/Category';

export type Section = {
  key: string;
  component: Component;
  name: string;
  error?: any;
  progress?: SidebarProgress;
  icon?: Component;
  titleActions?: any;

  fields?: string[];
  required?: boolean;
};

type WrapperProps = ParentProps<{
  sections: Section[];
  defaultSelection?: string;
  getScrollElement?: () => any;
  onActiveCategoryChange?: (key: string, evt: 'click' | 'scroll') => void;
  activeCategory?: string;
}>;

export const [CategoryComponentWrapperProvider, useCategoryComponentWrapper] = contextCreator(
  'CategoryComponentWrapper',
  (props: WrapperProps) => {
    const [store, setStore] = createStore<{
      activeCategory: string;
      sections: Section[];
    }>({
      activeCategory: '',
      sections: [],
    });

    createEffect(() => {
      setStore({ sections: props.sections });

      if (!store.activeCategory) {
        setStore({ activeCategory: props.sections[0]?.key || props.defaultSelection || '' });
      }
    });

    createEffect(() => {
      if (props.activeCategory && store.activeCategory !== props.activeCategory) {
        setStore({ activeCategory: props.activeCategory });
      }
    });

    const refs = new Map();

    const onClickSection = (categoryKey: string) => {
      setStore({ activeCategory: categoryKey });
      scrollToSection(refs.get(categoryKey));
      props.onActiveCategoryChange?.(categoryKey, 'click');
    };

    const setContentRef = (key: string, ref: HTMLDivElement) => {
      refs.set(key, ref);
    };

    const onScroll = debounce(function () {
      let lastKey = '';
      let lastTopValue = 0;
      for (const [key, ref] of refs) {
        const currentTop = ref.getBoundingClientRect().top;
        if (currentTop < 248 && (currentTop > lastTopValue || !lastKey)) {
          lastKey = key;
          lastTopValue = currentTop;
          continue;
        }
      }
      const nextKey = lastKey || props.defaultSelection || '';
      setStore({ activeCategory: nextKey });
      untrack(() => props.onActiveCategoryChange)?.(nextKey, 'scroll');
    }, 150);

    onMount(() => {
      props.getScrollElement?.()?.addEventListener('scroll', onScroll);
    });

    onCleanup(() => {
      props.getScrollElement?.()?.removeEventListener('scroll', onScroll);
    });

    return {
      store,
      setStore,
      onClickSection,
      setContentRef,
    };
  }
);

export const CategoryComponentWrapper = (props: WrapperProps) => {
  const [, _props] = splitProps(props, ['children']);
  return <CategoryComponentWrapperProvider {..._props}>{props.children}</CategoryComponentWrapperProvider>;
};

import { createEffect, createMemo, createSignal, onMount } from 'solid-js';
import IconWideHeader from '~/assets/images/vendors/wideHeaderImg.png';
import { FormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import { useCategoryComponentWrapper } from '~/components/common/Category';
import LabeledVendorInformationInput from '~/components/common/Inputs/form-inputs/LabeledVendorInformation';
import { useTranslations, useVendors } from '~/contexts/global';

const VendorInformationSection = () => {
  const { current } = useVendors();
  const { userDefaultLanguage } = useTranslations();
  const { onClickSection } = useCategoryComponentWrapper();
  const { formStore, setFieldsValue } = useFormContext();

  const [nameError] = createSignal<string>('');

  const [currentPath, setCurrentPath] = createSignal(window.location.pathname);
  const isEdit = createMemo(() => currentPath() === `/maintenance/vendors/${current()?.id}/edit`);

  onMount(() => {
    const updatePath = () => setCurrentPath(window.location.pathname);
    window.addEventListener('popstate', updatePath);
    return () => window.removeEventListener('popstate', updatePath);
  });

  createEffect(() => {
    const _userDefaultLanguage = userDefaultLanguage();
    if (!formStore.language && _userDefaultLanguage?.code) {
      setFieldsValue({ language: _userDefaultLanguage.code });
    }
  });

  return (
    <FormItem
      header={
        isEdit() && (
          <div class="relative flex h-28 flex-col justify-center p-4 text-left">
            <h2 class="text-lg font-semibold">{current()?.name}</h2>
            <div class="text-xs uppercase text-text-level03 ">{current()?.category?.name || 'Placeholder'}</div>
            <img class="absolute left-0 top-0 size-full" src={IconWideHeader} alt="" />
          </div>
        )
      }
      customGetValue={(context) => {
        return {
          name: context.formStore.name,
          memo: context.formStore.memo,
          language: context.formStore.language,
        };
      }}
      nameError={nameError()}
      onClick={() => onClickSection('vendorInformation')}
      customSyncValue={(context, value) => {
        context.setFieldsValue(value);
      }}
      component={LabeledVendorInformationInput}
    />
  );
};

export default VendorInformationSection;

import { useParams , Route, Routes } from '@solidjs/router';
import { FileListView } from '~/components/file-attachments';
import { useUnits } from '~/contexts/global';
import { AttachmentsProvider } from '~/contexts/local';
import { AddUnit } from '~/pages/units/add-unit';
import type { Component } from 'solid-js';

export const UnitFiles: Component = () => {
  const params = useParams();
  const { unit } = useUnits();
  return (
    <>
      <div class="text-left">
        <AttachmentsProvider>
          <FileListView entryType={'unit' as MagicDoor.Api.EntityType} entryId={unit()?.id || params.unitId} />
        </AttachmentsProvider>
      </div>
      <Routes>
        <Route path="/edit-unit" component={AddUnit} />
      </Routes>
    </>
  );
};

import { createMemo, createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { LeaseTemplateCategoryRepository } from '~/repositories/leaseTemplateCategoryRepository';
import { createLazyResource, createMutation } from '~/utils/resource';

const repo = new LeaseTemplateCategoryRepository();

export const [LeaseTemplateCategoryProvider, useLeaseTemplateCategory] = createMagicDoorContext('LeaseTemplateCategory', () => {
  const [leasetemplatecategories, { fetch, refetch, mutate }] = createLazyResource(async () => {
    return repo.getLeaseTemplateCategories();
  });

  const [currentId, setCurrentId] = createSignal<string>();

  const current = createMemo(() => (currentId() ? leasetemplatecategories()?.find((p) => p.id === currentId()) : undefined));

  const addLeaseTemplateCategory = createMutation(async (model: MagicDoor.Api.CreateLeaseTemplateCategoryDto) => {
    const created = await repo.createLeaseTemplateCategory(model);
    mutate((prev) => [...(prev ?? []), created]);
    return created;
  });

  const updateLeaseTemplateCategory = createMutation(
    async (leaseTemplateCategoryId: string, model: MagicDoor.Api.CreateLeaseTemplateCategoryDto) => {
      const updated = await repo.updateLeaseTemplateCategory(leaseTemplateCategoryId, model);
      mutate((prev) => prev?.map((t) => (t.id === leaseTemplateCategoryId ? updated : t)));
    }
  );

  const deleteLeaseTemplateCategory = createMutation(async (leaseTemplateCategoryId: string) => {
    await repo.deleteLeaseTemplateCategory(leaseTemplateCategoryId);
    mutate((prev) => prev?.filter((t) => t.id !== leaseTemplateCategoryId));
  });

  return {
    get leasetemplatecategories() {
      fetch();
      return leasetemplatecategories;
    },
    get loading() {
      fetch();
      return () => leasetemplatecategories.loading;
    },
    refetch,
    setCurrentId,
    current,
    addLeaseTemplateCategory,
    updateLeaseTemplateCategory,
    deleteLeaseTemplateCategory,
  };
});

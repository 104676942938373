import { For } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/pdfsigner/hooks/usePresenter';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { SignerListPresenter } from '~/pdfsigner/presenters/SignerListPresenter';
import { signerColors } from '~/pdfsigner/ui/types/signerColors';
import { PMSelectionChangedUseCase } from '~/pdfsigner/usecases/pmSelectionChangedUseCase';
import { SelectSignerUseCase } from '~/pdfsigner/usecases/selectSignerUseCase';
import type { JSX } from 'solid-js';

export const SignerList = (): JSX.Element => {
  const { execute: selectSigners } = useUseCase(SelectSignerUseCase);
  const { execute: pmSelectionChanged } = useUseCase(PMSelectionChangedUseCase);
  const { model: presentableSignerList } = usePresenter(SignerListPresenter);
  const { t } = useLocalization();

  return (
    <div class="right-0 top-0 rounded-md border border-gray-950 bg-white p-4">
      <For each={presentableSignerList()?.signers}>
        {(signer, index) => (
          <div class="mb-2 flex items-center">
            <input
              type="radio"
              id={`signer-${index()}`}
              name="signer"
              class="mr-2"
              checked={presentableSignerList()?.selectedSigner.id === signer.id}
              onChange={() => selectSigners(index())}
            />
            <label for={`signer-${index()}`} class="mr-4 w-16 text-sm" style={{ color: `${signerColors[index()]}` }}>
              {signer.name}
            </label>
            <div class="flex items-center">
              <input
                type="checkbox"
                id={`pm-checkbox-${index()}`}
                class="mr-1"
                checked={signer.isPropertyManager}
                onChange={() => pmSelectionChanged({ index: index(), isPropertyManager: !signer.isPropertyManager })}
              />
              <label for={`pm-checkbox-${index()}`} class="text-sm">
                {t('PM')}
              </label>
            </div>
          </div>
        )}
      </For>
    </div>
  );
};

import { For, Show } from 'solid-js';
import AllPropertiesIcon from '~/assets/images/bill/allProperties.svg';
import SomePropertiesIcon from '~/assets/images/bill/someProperties.svg';
import PlusIcon from '~/assets/images/common/plus.svg?component-solid';
import IconRemove from '~/assets/images/common/remove.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { LabeledInputSearch } from '~/components/common/Inputs/LabeledInputSearch';
import { OptionButton } from '~/components/common/OptionButton';
import { PropertyTitle } from '~/components/properties';
import { useLocalization } from '~/contexts/global';
import { isUndefined } from '~/utils/tool';
import { dateTimeRangeOptions } from './store';
import type { ManagementFeeSubForm } from './types';
import type { Component } from 'solid-js';

const ChoosePropertyForm: Component<ManagementFeeSubForm> = (props) => {
  const { t } = useLocalization();

  const setItemData = (index?: number, value?: any) => {
    props.setFormData('properties', (prev) => {
      const start = index ?? prev.length + 1;
      const deleteCount = isUndefined(index) ? 0 : 1;
      const item = value ?? '';
      return prev.toSpliced(start, deleteCount, item);
    });
    props.setNeedToUpdate && props.setNeedToUpdate(true);
  };

  const removeLine = (index: number) => {
    props.setFormData('properties', (prev) => {
      return prev.toSpliced(index, 1);
    });
    props.setNeedToUpdate && props.setNeedToUpdate(true);
  };

  return (
    <div class="mx-auto flex w-10/12 flex-col gap-5">
      <div>
        <h4 class="border-b border-partingline py-3 text-base font-semibold">{t('Properties')}</h4>
        <div class="grid grid-cols-2 gap-4 py-4">
          <OptionButton
            class="h-20"
            Icon={AllPropertiesIcon}
            label={t('All properties')}
            isActive={props.form.isAllProperties}
            onClick={() => {
              props.setFormData('isAllProperties', true);
              props.setFormData('properties', []);
              props.setNeedToUpdate && props.setNeedToUpdate(true);
            }}
          />
          <OptionButton
            class="h-20"
            Icon={SomePropertiesIcon}
            label={t('Some properties')}
            isActive={!props.form.isAllProperties}
            onClick={() => {
              props.setFormData('isAllProperties', false);
              props.setFormData('properties', ['']);
              props.setNeedToUpdate && props.setNeedToUpdate(true);
            }}
          />
          <Show when={!props.form.isAllProperties}>
            <For each={props.form.properties}>
              {(item, index) => (
                <>
                  <LabeledInputSearch
                    allowClear={false}
                    required
                    label={t(`Property`)}
                    options={props.store.properties}
                    renderItem={(item) => <PropertyTitle feedbackIconReadonly property={item} />}
                    loading={props.store.propertiesLoading}
                    placeholder={t('Select property')}
                    value={item}
                    onSelect={(val) => setItemData(index(), val)}
                  />
                  <Show
                    when={index() === props.form.properties.length - 1}
                    fallback={
                      <div class="ml-5 flex items-center pt-2">
                        <IconRemove class="cursor-pointer text-link" onClick={() => removeLine(index())} />
                      </div>
                    }>
                    <div class="flex items-center">
                      <Button color="link" variant="outlined" onClick={() => setItemData()}>
                        <PlusIcon class="hidden sm:block" /> {t('Add a new property')}
                      </Button>
                    </div>
                  </Show>
                </>
              )}
            </For>
          </Show>
        </div>
      </div>
      <div>
        <h4 class="border-b border-partingline py-3 text-base font-semibold">{t('Run as of')}</h4>
        <div class="grid grid-cols-2 gap-4 py-4">
          <DropdownMenu
            class="col-span-1 lg:col-span-1"
            label={t('Run as of')}
            value={props.form.dateTimeRange}
            options={dateTimeRangeOptions}
            onChange={(value) => {
              props.setFormData('dateTimeRange', value);
              props.setNeedToUpdate && props.setNeedToUpdate(true);
            }}
            placeholder={t('Please select')}
          />
        </div>
      </div>
    </div>
  );
};

export default ChoosePropertyForm;

import { createMagicDoorContext } from '~/contexts/utils';
import { ServiceBillRepository } from '~/repositories/serviceBillRepository';
import { createMutation } from '~/utils/resource';

const repo = new ServiceBillRepository();

export const [ServiceBillProvider, useServiceBill] = createMagicDoorContext('serviceBill', () => {
  const addServiceBill = createMutation(async (data: MagicDoor.Api.AddServiceBillDto) => {
    return await repo.addServiceBill(data);
  });

  return {
    addServiceBill,
  };
});

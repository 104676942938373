import { Show, createContext, createSignal, splitProps, useContext } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import type { Component, ComponentProps, JSX, ValidComponent } from 'solid-js';
import type { Merge } from '~/utils/types';

const TabsContext = createContext<{
  selected: () => string | undefined;
  select: (value: string) => void;
}>();

const Tabs: Component<{ defaultSelected?: string; onChange?: (value: string) => void; children: JSX.Element }> = (props) => {
  const [selected, setSelected] = createSignal<string | undefined>(props.defaultSelected);

  const select = (value: string) => {
    setSelected(value);
    props.onChange?.(value);
  };

  return <TabsContext.Provider value={{ selected, select }}>{props.children}</TabsContext.Provider>;
};

const TabsList = <T extends ValidComponent = 'div'>(props: ComponentProps<T> & { as?: T }) => {
  const [params, rest] = splitProps(props, ['as']);
  return <Dynamic {...rest} component={params.as ?? 'div'} role="tablist" />;
};

const TabsTrigger = <T extends ValidComponent = 'button'>(
  props: Merge<ComponentProps<T>, { as?: T; value: string; disabled?: boolean; onClick?: (e: MouseEvent) => void }>
) => {
  const ctx = useContext(TabsContext);
  if (ctx == null) throw new Error('`<TabsTrigger />` must be used within `<Tabs />`');

  const [params, rest] = splitProps(props, ['as', 'value', 'disabled', 'onClick']);

  const handleClick = (e: MouseEvent) => {
    if (params.disabled) return;
    params.onClick?.(e);
    e.defaultPrevented || ctx.select(params.value);
  };

  return (
    <Dynamic
      {...rest}
      component={params.as ?? 'button'}
      role="tab"
      onClick={handleClick}
      disabled={params.disabled}
      aria-disabled={params.disabled}
      aria-selected={params.value === ctx.selected()}
    />
  );
};

const TabsContent = <T extends ValidComponent = 'div'>(props: Merge<ComponentProps<T>, { as?: T; value: string }>) => {
  const ctx = useContext(TabsContext);
  if (ctx == null) throw new Error('`<TabsContent />` must be used within `<Tabs />`');

  const [params, rest] = splitProps(props, ['as', 'value']);

  return (
    <Show when={params.value === ctx.selected()}>
      <Dynamic {...rest} component={params.as ?? 'div'} role="tabpanel" />
    </Show>
  );
};

const TabsWrapper = Object.assign(Tabs, { List: TabsList, Trigger: TabsTrigger, Content: TabsContent });

export { TabsWrapper as Tabs, TabsList, TabsTrigger, TabsContent };

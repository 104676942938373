import IconAiBot from '~/assets/images/chat/aiChatBot.svg';
import IconAiGradientBg from '~/assets/images/chat/aiMagicItGradientBg.png';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import { FreeListingSites } from '~/pages/listing/components/FreeListingSites';
import { ListingSyndications } from '~/pages/listing/components/ListingSyndications';
import { PaidListingSites } from '~/pages/listing/components/PaidListingSites';
export const ListingSyndicationsTab = () => {
  const { t } = useLocalization();

  return (
    <div class="flex flex-col gap-5">
      <Panel
        title={t('Master listing descriprion')}
        class="col-span-full"
        actions={
          <div class="mb-1.5 mr-1 rounded-lg bg-gradient-to-tl">
            <button
              class="flex h-10 w-fit items-center justify-center gap-2 bg-contain bg-left bg-no-repeat px-4	text-white"
              style={{
                'background-image': `url(${IconAiGradientBg})`,
                'background-position': 'left, right',
                'background-repeat': 'no-repeat, no-repeat',
              }}>
              <img src={IconAiBot} class="hide-on-mobile" />
              <span class="flex whitespace-nowrap text-base	font-medium">{t('Magic AI')}</span>
            </button>
          </div>
        }>
        <div class="px-12 py-7 text-sm text-[#575757]">
          Experience luxurious living at 150 Main Ave, Unit 1023. This spacious 2-bedroom, 2-bath apartment features an open-concept design,
          modern kitchen with stainless steel appliances, and a private balcony with city views. Enjoy access to premium amenities such as a
          fitness center, swimming pool, and resident lounge. Ideally located near shopping, dining, and public transport, making urban
          living a breeze. Schedule your tour today and discover the magic of this exceptional home.
        </div>
      </Panel>
      <div>
        <ListingSyndications />
        <PaidListingSites />
        <FreeListingSites />
      </div>
    </div>
  );
};

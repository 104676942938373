import LearnDueBg from '~/assets/images/dashboard/learnBg.png';
import learnIcon from '~/assets/images/dashboard/learnIcon.png';
import UnderConstruction from '~/components/common/UnderConstruction';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

type LearnProps = {
  class?: string;
};

const Learn: Component<LearnProps> = (props) => {
  const { t } = useLocalization();
  return (
    <div
      class={cn('relative h-full rounded-lg bg-cover bg-no-repeat', props.class)}
      style={{
        'background-image': `url(${LearnDueBg})`,
      }}>
      <div class="py-12">
        <img class="mx-auto" src={learnIcon} alt="learnIcon" />
      </div>
      <div class="px-5 text-white">
        <div class="w-48 text-lg font-semibold">{t('Learn to master magicdoor quickly')}</div>
        <div class="mt-2 w-56 text-xs text-white/70">{t('AI-enabled property management software that honors your time')}</div>
        <div class="mt-6 cursor-pointer text-sm">{t('Learn now')}</div>
      </div>
      <UnderConstruction />
    </div>
  );
};

export default Learn;

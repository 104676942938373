import { Show } from 'solid-js';
import { emptyPlaceholder } from '~/utils/constant';

export const BankAccountMask = (props: { accountMask?: string | null }) => {
  return (
    <Show when={props.accountMask} fallback={emptyPlaceholder}>
      XXXX XXXX XXXX {props.accountMask?.slice(-4)}
    </Show>
  );
};

import { Show } from 'solid-js';
import Tooltip from '~/components/common/Tooltip';
import { cn } from '~/utils/classnames';
import type { Component, JSX } from 'solid-js';

interface IconTagProps {
  icon?: JSX.Element;
  iconBgColor?: string;
  iconTip?: string;
  text: string;
  textClass?: string;
}

const IconTag: Component<IconTagProps> = (props) => {
  return (
    <div class="flex items-center">
      <Show when={!!props.icon}>
        <Tooltip disabled={!props.iconTip} message={props.iconTip} align="right">
          <div
            style={{
              'background-color': props.iconBgColor,
            }}
            class="flex h-8 items-center rounded-l-full pl-2.5 pr-2">
            {props.icon}
          </div>
        </Tooltip>
      </Show>
      <div
        class={cn('h-8 whitespace-nowrap rounded-r-full pl-2 pr-2.5 text-center text-xs font-medium leading-8', props.textClass)}
        classList={{ 'rounded-l-full': !props.icon }}>
        {props.text}
      </div>
    </div>
  );
};

export default IconTag;

import { createMagicDoorContext } from '~/contexts/utils';
import { OwnersRepository } from '~/repositories/ownersRepository';

const repo = new OwnersRepository();
export const [OwnerContributionProvider, useOwnerContribution] = createMagicDoorContext('OwnerContribution', () => {
  const addOwnerContribution = async (payload: MagicDoor.Api.RegisterOwnerContributionDto, ownerId: string) => {
    await repo.addOwnerContribution(payload, ownerId);
  };

  return {
    addOwnerContribution,
  };
});

import { For, Match, Switch } from 'solid-js';
import ImgNoActivity from '~/assets/images/empty/noActivity.png';
import { Skeleton } from '~/components/ui';
import { formatAgo } from '~/utils/date';
import type { Component } from 'solid-js';

type TenantActivityType = 'update' | 'pay' | 'chat';

export type TenantActivity = { type: TenantActivityType; log: string; datetime: string };

// Temp for UI
const ActivityIcon: Component<{ type?: TenantActivityType }> = (props) => (
  <Switch
    fallback={
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="18" fill="#FFCB97" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.3764 27.3868L24.1628 13.5848C24.6403 12.8405 24.8101 11.98 24.6509 11.1038C24.513 10.3073 24.1097 9.54994 23.5049 8.97539L22.0299 7.5521C20.7459 6.31161 19.1541 6.44219 18.2415 7.86548L17.2546 9.42066C17.1273 9.61522 17.1591 9.9025 17.3183 10.0592C17.3183 10.0592 19.812 12.4879 19.8651 12.5402C20.0349 12.736 20.1622 12.9972 20.1941 13.3106C20.2471 13.9243 19.8969 14.4988 19.377 14.5772C19.1329 14.6163 18.8994 14.5249 18.7297 14.3552L16.1086 11.822C15.9813 11.7058 15.7902 11.7306 15.6841 11.8873L9.45514 21.6806C9.0519 22.2943 8.91395 23.0908 9.0519 23.8612L9.84777 28.0528C9.89021 28.2748 10.0494 28.4315 10.2404 28.4315L13.7422 28.3792C14.3789 28.3662 14.9732 28.0136 15.3764 27.3868ZM20.2797 26.0815H25.9898C26.5469 26.0815 27 26.639 27 27.3245C27 28.0114 26.5469 28.5676 25.9898 28.5676H20.2797C19.7226 28.5676 19.2695 28.0114 19.2695 27.3245C19.2695 26.639 19.7226 26.0815 20.2797 26.0815Z"
          fill="white"
        />
      </svg>
    }>
    <Match when={props.type === 'pay'}>
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="18" fill="#8CEEDA" />
        <path
          d="M24.91 13.8H23.67L23.89 13.16C24.16 12.34 23.71 11.45 22.9 11.17L16.76 9.14001C16.36 9.01001 15.93 9.04001 15.56 9.23001C15.18 9.41001 14.91 9.74001 14.78 10.14C14.77 10.18 14.69 10.44 14.67 10.54L14.63 10.53C14.23 10.43 13.8 10.49 13.44 10.71C13.09 10.92 12.82 11.28 12.73 11.68L12.21 13.8H11.28C10.49 13.8 9.84998 14.44 9.84998 15.23V23.32C9.84998 24.84 11.09 26.08 12.61 26.08H24.91C25.58 26.08 26.12 25.54 26.12 24.87V15.01C26.13 14.34 25.58 13.8 24.91 13.8ZM18.81 22.7V23.37C18.81 23.45 18.74 23.52 18.66 23.52H18C17.92 23.52 17.85 23.45 17.85 23.37V22.75C17.47 22.74 17.17 22.68 16.9 22.63C16.83 22.62 16.76 22.56 16.76 22.48V21.95C16.76 21.87 16.83 21.81 16.9 21.81H16.91C17.2 21.85 17.93 21.88 18.2 21.88C18.85 21.88 19.05 21.65 19.05 21.22C19.05 20.93 18.91 20.79 18.43 20.5L17.47 19.92C16.79 19.52 16.61 19.09 16.61 18.58C16.61 17.91 16.94 17.38 17.85 17.23V16.62C17.85 16.54 17.92 16.47 18 16.47H18.67C18.75 16.47 18.82 16.54 18.82 16.62V17.2C19.23 17.22 19.66 17.27 19.85 17.32C19.92 17.33 19.98 17.39 19.98 17.46V18C19.98 18.07 19.93 18.13 19.86 18.13H19.85C19.37 18.09 18.89 18.06 18.41 18.06C17.87 18.06 17.64 18.25 17.64 18.58C17.64 18.82 17.76 18.97 18.24 19.23L19.12 19.72C19.92 20.17 20.14 20.63 20.14 21.21C20.12 21.85 19.79 22.51 18.81 22.7ZM21.08 13.73C21.07 13.75 21.07 13.77 21.06 13.8H13.3L13.75 11.95L13.76 11.93C13.8 11.79 13.88 11.68 14.01 11.6C14.13 11.53 14.27 11.5 14.41 11.54L20.69 13.08C20.83 13.12 20.94 13.2 21.02 13.33C21.09 13.44 21.12 13.59 21.08 13.73ZM22.91 12.82L22.58 13.8H22.1C22.15 13.44 22.07 13.08 21.89 12.77C21.68 12.42 21.33 12.15 20.92 12.06L15.65 10.77C15.66 10.75 15.67 10.73 15.68 10.7L15.76 10.46C15.85 10.19 16.15 10.03 16.43 10.13L22.57 12.16C22.7 12.2 22.82 12.3 22.88 12.42C22.94 12.54 22.95 12.69 22.91 12.82Z"
          fill="white"
        />
      </svg>
    </Match>
    <Match when={props.type === 'chat'}>
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="18" fill="#EEC9FF" />
        <path
          d="M11.0059 23.1406C11.0527 23.002 11.0215 22.8516 10.9316 22.7363C9.88477 21.4277 9.26758 19.8164 9.26758 18.0742C9.26758 13.6836 13.1836 10.123 18.0137 10.123C22.8438 10.123 26.7559 13.6816 26.7559 18.0723C26.7559 22.4629 22.8398 26.0234 18.0098 26.0234C16.2363 26.0234 14.5859 25.543 13.207 24.7168C13.0918 24.6484 12.959 24.623 12.8281 24.6504L10.666 25.0918C10.5156 25.123 10.3887 24.9785 10.4375 24.834L11.0059 23.1406ZM19.2031 21.6504H21.5898C22.248 21.6504 22.7832 21.1172 22.7832 20.457C22.7832 19.7988 22.25 19.2637 21.5898 19.2637H19.2051C18.5469 19.2637 18.0117 19.7969 18.0117 20.457C18.0117 21.1172 18.5449 21.6504 19.2031 21.6504ZM14.4336 17.6758H21.5898C22.248 17.6758 22.7832 17.1426 22.7832 16.4824C22.7832 15.8242 22.25 15.2891 21.5898 15.2891H14.4355C13.7773 15.2891 13.2422 15.8223 13.2422 16.4824C13.2422 17.1426 13.7754 17.6758 14.4336 17.6758Z"
          fill="white"
        />
      </svg>
    </Match>
  </Switch>
);

export const ActivityItem: Component<{ activity?: TenantActivity }> = (props) => {
  return (
    <div class="relative flex gap-4">
      <ActivityIcon type={props.activity?.type} />
      <div class="flex-1 space-y-2">
        <div class="text-sm text-text-level02">{props.activity?.log}</div>
        <div class="text-xs text-text-level03">{formatAgo(props.activity?.datetime)}</div>
      </div>
    </div>
  );
};

export const ActivitySkeleton: Component = () => (
  <For each={Array(4)}>
    {() => (
      <div class="relative flex gap-4">
        <Skeleton class="size-9" />
        <div class="flex-1 space-y-2">
          <Skeleton class="w-full" />
          <Skeleton class="h-4 w-32" />
        </div>
      </div>
    )}
  </For>
);

export const ActivityEmpty: Component = () => <img class="mx-auto my-2" src={ImgNoActivity} />;

import { Show } from 'solid-js';
import IconBanner from '~/assets/images/maintenance/stopAibannerIcon.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { Skeleton } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequests, useRunBooks } from '~/contexts/local';
import { dateFormat } from '~/utils/date';

export const StopAiBanner = () => {
  const { maintenanceRequest, disableMaintenanceRequestAi } = useMaintenanceRequests();
  const { runBook } = useRunBooks();
  const { t } = useLocalization();

  return (
    <Show when={maintenanceRequest() && !maintenanceRequest()?.disableAi && maintenanceRequest()?.runBookId}>
      <div class="flex flex-col items-center gap-2 rounded-lg bg-gradient-to-r from-[#552fed] to-[#b011ff] p-4 text-white xl:flex-row">
        <div class="relative size-20 overflow-hidden">
          <IconBanner class="size-25 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
        </div>
        <div class="flex flex-1 flex-col gap-1">
          <Show
            when={!runBook.loading && runBook()}
            fallback={
              <div class="flex flex-col gap-2">
                <Skeleton class="w-20" />
                <Skeleton class="w-46" />
              </div>
            }>
            <span class="text-sm capitalize">
              {t(`AI running run book`)}: {runBook()?.title}
            </span>

            <span class="text-xs">{runBook()?.description}</span>
            <span class="text-[8px] text-white/80">{dateFormat(t('MM/DD/YYYY hh:mm A'), runBook()?.lastUsed)}</span>
          </Show>
        </div>
        <Button
          variant="white"
          class="rounded-full text-xs"
          loading={disableMaintenanceRequestAi.loading}
          onClick={() => {
            disableMaintenanceRequestAi();
          }}>
          {t(`Stop AI for this request`)}
        </Button>
      </div>
    </Show>
  );
};

import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { tenantAutoPayFailedNotificationRepository } from '~/repositories/settings/tenantAutoPayFailedNotificationRepository';
import type { Accessor } from 'solid-js';

interface TenantAutoPayFailedNotificationContextValue {
  allTenantAutoPayFailedNotificationSettings: Accessor<
    MagicDoor.Api.TenantAutoPayFailedNotificationSettingsDtoSettingsWithOverridesDto | undefined
  >;
  getTenantAutoPayFailedNotificationSettings: () => Promise<
    MagicDoor.Api.TenantAutoPayFailedNotificationSettingsDtoSettingsWithOverridesDto | undefined
  >;
  updateTenantAutoPayFailedNotificationSettings: (payload: MagicDoor.Api.TenantAutoPayFailedNotificationSettingsDto) => Promise<void>;
  updateTenantAutoPayFailedNotificationSettingsByLevel: (
    level: string,
    levelId: string,
    payload: MagicDoor.Api.TenantAutoPayFailedNotificationSettingsDto
  ) => Promise<void>;
  deleteTenantAutoPayFailedNotificationSettingsByLevel: (level: string, levelId: string) => Promise<void>;
  isLoading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
}

export const [TenantAutoPayFailedNotificationProvider, useTenantAutoPayFailedNotification] =
  createMagicDoorContext<TenantAutoPayFailedNotificationContextValue>('TenantAutoPayFailedNotification', () => {
    const repo = new tenantAutoPayFailedNotificationRepository();

    const [isLoading, setIsLoading] = createSignal<boolean>(false);
    const [error, setError] = createSignal<Error | undefined>(undefined);
    const [allTenantAutoPayFailedNotificationSettings, setAllTenantAutoPayFailedNotificationSettings] = createSignal<
      MagicDoor.Api.TenantAutoPayFailedNotificationSettingsDtoSettingsWithOverridesDto | undefined
    >(undefined);

    const getTenantAutoPayFailedNotificationSettings = async (): Promise<
      MagicDoor.Api.TenantAutoPayFailedNotificationSettingsDtoSettingsWithOverridesDto | undefined
    > => {
      setIsLoading(true);
      try {
        const result = await repo.getTenantAutoPayFailedNotificationAll();
        setAllTenantAutoPayFailedNotificationSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const updateTenantAutoPayFailedNotificationSettings = async (
      payload: MagicDoor.Api.TenantAutoPayFailedNotificationSettingsDto
    ): Promise<void> => {
      setIsLoading(true);
      try {
        await repo.updateTenantAutoPayFailedNotification(payload);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    const updateTenantAutoPayFailedNotificationSettingsByLevel = async (
      level: string,
      levelId: string,
      payload: MagicDoor.Api.TenantAutoPayFailedNotificationSettingsDto
    ): Promise<void> => {
      setIsLoading(true);
      try {
        await repo.updateTenantAutoPayFailedNotificationByLevel(level, levelId, payload);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    const deleteTenantAutoPayFailedNotificationSettingsByLevel = async (level: string, levelId: string): Promise<void> => {
      setIsLoading(true);
      try {
        await repo.deleteTenantAutoPayFailedNotificationByLevel(level, levelId);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    return {
      allTenantAutoPayFailedNotificationSettings,
      getTenantAutoPayFailedNotificationSettings,
      updateTenantAutoPayFailedNotificationSettings,
      updateTenantAutoPayFailedNotificationSettingsByLevel,
      deleteTenantAutoPayFailedNotificationSettingsByLevel,
      isLoading,
      error,
    };
  });

import { createSignal } from 'solid-js';
import { useUnits } from '~/contexts/units';
import { createMagicDoorContext } from '~/contexts/utils';
import { LeaseRepository } from '~/repositories/leaseRepository';
import { LeaseTenantRepository } from '~/repositories/leaseTenantRepository';
import { createMutation } from '~/utils/resource';
import type { SuggestedEndDateResponse } from '~/repositories/leaseRepository';

export const [LeaseProvider, useLease] = createMagicDoorContext('Lease', () => {
  const [lease, setLease] = createSignal<MagicDoor.Api.HydratedLeaseDto>();
  const [loading, setLoading] = createSignal<boolean>(false);
  const [error, setError] = createSignal<Error>();
  const { refetchUnit, refetchUnitsIf } = useUnits();

  const repo = new LeaseRepository();
  const tenantRepo = new LeaseTenantRepository();

  const getLease = async (leaseId?: string) => {
    if (!leaseId) return;
    try {
      setLoading(true);
      const res = await repo.getLease(leaseId);
      setLease(res);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const addLease = async (payload: MagicDoor.Api.CreateLeaseDto) => {
    try {
      const added = await repo.addLease(payload);
      refetchUnit(added.unitId);
      return added;
    } catch (err: any) {
      setError(err);
      throw new Error(err);
    }
  };

  const moveoutTenant = async (leaseId: string, tenantId: string) => {
    try {
      await tenantRepo.deleteLeaseTenant(leaseId, tenantId);
      if (lease()) {
        setLease(
          (prev) =>
            ({
              ...prev,
              tenants: prev?.tenants?.filter((t) => t.tenant?.id !== tenantId),
            } as MagicDoor.Api.HydratedLeaseDto)
        );
      }
    } catch (err: any) {
      setError(err);
    }
  };

  const editLease = async (leaseId: string, payload: MagicDoor.Api.UpdateLeaseDto) => {
    try {
      const updated = await repo.editLease(leaseId, payload);
      getLease(leaseId);
      refetchUnit(updated.unitId);
      return updated;
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };

  const deleteLease = async (leaseId: string) => {
    try {
      await repo.deleteLease(leaseId);
      refetchUnitsIf((u) => u.activeLeases?.some((i) => i.id === leaseId));
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };
  const eviction = async (leaseId: string, payload: MagicDoor.Api.UpdateLeaseEvictionStatusDto) => {
    try {
      await repo.eviction(leaseId, payload);
      refetchUnitsIf((u) => u.activeLeases?.some((i) => i.id === leaseId));
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };
  const endLease = async (leaseId: string) => {
    try {
      await repo.endLease(leaseId);
      refetchUnitsIf((u) => u.activeLeases?.some((i) => i.id === leaseId));
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };

  const reactivateLease = async (leaseId: string) => {
    try {
      await repo.reactivateLease(leaseId);
      refetchUnitsIf((u) => u.activeLeases?.some((i) => i.id === leaseId));
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };

  const suggestLeaseEndDate = (startDate: string): Promise<SuggestedEndDateResponse> => {
    return repo.suggestedLeaseEndDate(startDate);
  };

  const getLeaseOverlapping = (unitId: string, startTime: string, endTime?: string) => {
    return repo.getLeaseOverlapping(unitId, startTime, endTime);
  };

  const bindTenantToLease = async (leaseId: string, tenantId: string, data: MagicDoor.Api.MoveInDto) => {
    return repo.bindTenantToLease(leaseId, tenantId, data);
  };

  const activateDraft = createMutation(async (leaseId: string) => {
    await repo.activateDraft(leaseId);
    if (lease()?.id === leaseId) getLease(leaseId);
    refetchUnitsIf((u) => u.activeLeases?.some((i) => i.id === leaseId));
  });

  const getNextChargeDate = async (leaseStartDate: string) => {
    const res = await repo.getNextChargeDate(leaseStartDate);
    if (res.date) {
      return res.date;
    }
  };

  return {
    lease,
    loading,
    error,
    addLease,
    getLease,
    moveoutTenant,
    editLease,
    deleteLease,
    eviction,
    suggestLeaseEndDate,
    setLease,
    getLeaseOverlapping,
    endLease,
    reactivateLease,
    bindTenantToLease,
    activateDraft,
    getNextChargeDate,
  };
});

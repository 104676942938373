import { For, Show } from 'solid-js';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

type StepperProps = {
  class?: string;
  step: number;
  steps: string[];
};

export const Stepper: Component<StepperProps> = (props) => {
  return (
    <div class={cn('flex items-center gap-2 px-4 pb-6', props.class)}>
      <div />
      <For each={props.steps}>
        {(step, index) => (
          <>
            <div class={cn('relative z-10', props.step >= index() ? 'text-essential-colour' : 'text-auxiliary-text')}>
              <span
                class={cn(
                  'box-content flex size-11 items-center justify-center rounded-full border-2 bg-white text-xl font-semibold',
                  { 'bg-essential-colour text-white': props.step >= index() },
                  props.step >= index() ? 'border-essential-colour' : 'border-auxiliary-text'
                )}>
                <Show when={props.step > index()} fallback={index() + 1}>
                  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.49992 4.83333L0.333252 7L6.83325 13.5L17.6666 2.66667L15.4999 0.5L6.83325 9.16667L2.49992 4.83333Z"
                      fill="white"
                    />
                  </svg>
                </Show>
              </span>
              <span
                class={cn('absolute -bottom-2 left-1/2 -translate-x-1/2 translate-y-full whitespace-nowrap text-xs', {
                  'font-medium': props.step > index(),
                })}>
                {step}
              </span>
            </div>
            <Show when={index() !== props.steps.length - 1}>
              <div
                class={cn('w-10 border border-dashed border-auxiliary-text lg:w-20', {
                  'border-essential-colour border-solid': props.step - 1 >= index(),
                })}
              />
            </Show>
          </>
        )}
      </For>
    </div>
  );
};

import { useNavigate, useMatch } from '@solidjs/router';
import { createEffect, createMemo, createSignal, onMount, Show } from 'solid-js';
import { PageCloseGuard } from '~/components/common/PageCloseGuard';
import { MultiSteps } from '~/components/common/Panels/MultiStepsPanel';
import { Stepper } from '~/components/common/Stepper';
import { NEW_RENTAL_APPLICATION_REVIEW_STEP, useNewRentalApplication } from '~/contexts/local';
import { Employment } from '~/pages/rental-applications/add-rental-application/Employment';
import { Identity } from '~/pages/rental-applications/add-rental-application/Identity';
import { QuestionAndTerms } from '~/pages/rental-applications/add-rental-application/QuestionAndTerms';
import { RentalHistory } from '~/pages/rental-applications/add-rental-application/RentalHistory';
import { TenantProfile } from '~/pages/rental-applications/add-rental-application/TenantProfile';

export const StepsBeforePayment = () => {
  const navigate = useNavigate();
  const { store, onStepChange, setStore, checkPaymentSettings } = useNewRentalApplication();

  const isPaymentStep = useMatch(() => '/leasing/rental-applications/new/:applicationId/payment');

  const components = createMemo(() => {
    const base = [TenantProfile, RentalHistory, Employment, Identity, QuestionAndTerms];
    return base;
  });

  onMount(() => {
    checkPaymentSettings();
  });

  createEffect(() => {
    const _matched = isPaymentStep();
    if (_matched) {
      setStore({
        currentStep: 5,
      });
    }
  });

  const [isDirty, setIsDirty] = createSignal(false);

  const handleSubmit = async () => {
    try {
      onStepChange(NEW_RENTAL_APPLICATION_REVIEW_STEP);
    } catch (err) {
      console.error('Error creating a lease:', err);
    }
  };
  const _onStepChange = async (step: number) => {
    await onStepChange(step);
    setIsDirty(false);
  };

  return (
    <Show when={store.currentStep < NEW_RENTAL_APPLICATION_REVIEW_STEP}>
      <PageCloseGuard isDirty={isDirty()}>
        <MultiSteps
          stepper={
            <div class="flex items-center justify-center pb-10">
              <Stepper class="p-9" steps={store.steps} step={store.currentStep} />
            </div>
          }
          currentStep={store.currentStep}
          contentClass="[&>div]:mx-auto [&>div]:mb-10 [&>div]:max-w-4xl"
          onStepChange={_onStepChange}
          showSubmit={!isPaymentStep()}
          onClose={() => navigate(-1)}
          onSubmit={handleSubmit}
          disableNext={store.disableNext}
          showPrevious={!isPaymentStep()}
          steps={components()}
          loading={store.stepChangeLoading}
        />
      </PageCloseGuard>
    </Show>
  );
};

import { createMemo, Show, splitProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import fallbackPropertyImage from '~/assets/images/property/defaultProperty.jpg';
import { DeactivatedFlag } from '~/components/common';
import DisplayAddress from '~/components/common/DisplayAddress';
import { pickProps, TitleWithFeedbackIcon } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import { Image, Skeleton } from '~/components/ui';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import { getImageUrl } from '~/utils/file';
import { propertyTypes } from './PropertyType';
import type { Component } from 'solid-js';
import type { TitleWithFeedbackIconProps } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import type { PolymorphicComponent } from '~/utils/types';

export const PropertyImage: Component<{ class?: string; property?: MagicDoor.Api.PropertyDto }> = (props) => {
  const src = createMemo(() => {
    if (props.property == null || props.property.images.length === 0) return fallbackPropertyImage;
    const imageId = props.property.images.find((i) => i.default)?.fileId;
    if (imageId == null || imageId.length === 0) return fallbackPropertyImage;
    return getImageUrl('property', props.property.id, imageId);
  });
  return <Image class={cn('object-cover', props.class)} src={src()} />;
};

export const PropertyTitle: PolymorphicComponent<
  'div',
  { class?: string; property?: MagicDoor.Api.PropertyDto; showType?: boolean; typeClass?: string } & Partial<TitleWithFeedbackIconProps>
> = (props) => {
  const [, rest] = splitProps(props, ['class', 'property']);
  return (
    <TitleWithFeedbackIcon
      {...pickProps(rest)}
      type="properties"
      entityId={props.property?.id}
      initalSelectedIcons={props.property?.icons}
      class="items-start">
      <Dynamic
        component={props.as || 'div'}
        class={cn('flex max-w-full items-center gap-2 text-base text-title-gray', props.class)}
        {...rest}>
        <Show when={props.property} fallback={<Skeleton class="size-12 rounded-md" data-slot="image" />}>
          <PropertyImage class="size-12 rounded-md" property={props.property} data-slot="image" />
        </Show>
        <div class="line-clamp-2 space-y-0.5 text-left" data-slot="content">
          <Show
            when={props.property}
            fallback={
              <>
                <Skeleton class="h-7 w-28" />
                <Skeleton class="h-4 w-44" />
              </>
            }>
            <h4 data-slot="title">
              {props.property?.name || emptyPlaceholder}
              <DeactivatedFlag class="ml-2" active={props.property?.active} />
              <Show when={props.showType}>
                <div class="ml-1.5 inline-block">
                  <div
                    class={cn(
                      'flex items-center gap-1 rounded-3xl bg-black/20 px-1.5 py-1 text-xs font-normal capitalize',
                      props.typeClass
                    )}>
                    <Dynamic component={propertyTypes[props.property!.type].icon} class="inline-block size-4 text-white" />
                    {propertyTypes[props.property!.type].name}
                  </div>
                </div>
              </Show>
            </h4>
            <DisplayAddress
              class="text-[.75em] font-normal leading-[1.2em] text-current-alpha-8"
              address={props.property?.address}
              data-slot="address"
            />
          </Show>
        </div>
      </Dynamic>
    </TitleWithFeedbackIcon>
  );
};

import { Show } from 'solid-js';
import type { JSX } from 'solid-js';

type CheckboxToExpandProps = {
  label: string;
  content: string | JSX.Element;
  onChange?: (checked: boolean) => void;
  checked?: boolean;
};

const CheckboxToExpand = (props: CheckboxToExpandProps) => {
  const handleCheckboxChange = (checked: boolean) => {
    props.onChange?.(checked);
  };

  return (
    <div class="flex flex-col">
      <label class="flex items-center gap-2">
        <input
          type="checkbox"
          style={{
            appearance: 'none',
          }}
          class="size-4 rounded border border-text-level03"
          classList={{ 'bg-essential-colour': props.checked, transparent: !props.checked }}
          checked={props.checked}
          onChange={(e) => handleCheckboxChange(e.currentTarget.checked)}
        />
        <span class="text-sm text-text-level02">{props.label}</span>
      </label>

      <Show when={props.checked}>{props.content}</Show>
    </div>
  );
};

export default CheckboxToExpand;

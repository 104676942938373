import { BaseRestRepository } from '~/repositories/baseRestRepository';

const LATE_FEE_SETTING_URL = '/api/settings/late-fees';

export class lateFeesSettingsRepository extends BaseRestRepository {
  public async addLateFeesSettings(payload: MagicDoor.Api.LateFeesSettingsDto) {
    const url = LATE_FEE_SETTING_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async addLateFeesSettingsByLevel(level: string, levelId: string, payload: MagicDoor.Api.LateFeesSettingsDto) {
    const url = `${LATE_FEE_SETTING_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getLateFeesSettings(): Promise<MagicDoor.Api.LateFeesSettingsDto> {
    const url = LATE_FEE_SETTING_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getLateFeesSettingsByLevel(level: string, levelId: string) {
    const url = `${LATE_FEE_SETTING_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getAllLateFeesSettings(): Promise<MagicDoor.Api.LateFeesSettingsDtoSettingsWithOverridesDto> {
    const url = `${LATE_FEE_SETTING_URL}/all`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async deleteLateFeesSettingsByLevel(level: string, levelId: string) {
    const url = `${LATE_FEE_SETTING_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }
}

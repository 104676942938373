import { useNavigate, useSearchParams } from '@solidjs/router';
import dayjs from 'dayjs';
import { createEffect, Show, createMemo, createSignal, onMount, on, For, untrack } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { PrintButton } from '~/components/common/Buttons';
import DisplayAddress from '~/components/common/DisplayAddress';
import { Empty } from '~/components/common/Empty';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { OwnerSearch } from '~/components/owner/OwnerSearch';
import { DatePicker, IconLoader, Select } from '~/components/ui';
import { useLocalization, useProperties } from '~/contexts/global';
import { useOwners, useReports } from '~/contexts/local';
import { compareTwoDateRange } from '~/pages/reports/utils';
import { twc } from '~/utils/classnames';
import { commaNumber, currency } from '~/utils/number';
import { parseColumns, ReportTable } from './components/Report';
import type { LineItem } from './components/Report';
import type { Component } from 'solid-js';
import type { OwnerStatementReportPropertyDto } from '~/swagger/Api';

const TableTitle = twc.div`text-base font-semibold text-title-gray mb-4`;

const OwnerStatementReport: Component<{ class?: string; report?: MagicDoor.Api.OwnerStatementReportDto }> = (props) => {
  const { t } = useLocalization();

  function getPropertyCashSummaryLines(property: OwnerStatementReportPropertyDto) {
    const res: LineItem[] = [];

    res.push({ label: t('Beginning cash'), level: 0, values: property.beginningCash as never });
    res.push({ label: t('Cash in'), level: 1, values: property.cashIn as never });
    res.push({ label: t('Cash out'), level: 1, values: property.cashOut as never });
    res.push({ label: t('Owner contributions'), level: 1, values: property.ownerContributions as never });
    res.push({ label: t('Company credits'), level: 1, values: property.companyCredits as never })
    res.push({ label: t('Owner distributions'), level: 1, values: property.ownerDistributions as never });
    res.push({ label: t('Ending cash balance'), level: 0, values: property.endingCash as never });
    res.push({ label: t('Unpaid bills'), level: 1, values: property.unpaid as never });
    res.push({ label: t('Property reserve'), level: 1, values: property.propertyReserve as never });
    res.push({ label: t('Net owner funds'), level: 0, values: property.netOwnerFunds as never });

    return res;
  }

  function getTransactionLines(property: OwnerStatementReportPropertyDto) {
    const res: LineItem[] = [];

    res.push({
      label: '',
      level: 0,
      values: {
        payeeOrPayerName: ' ',
        description: t('Beginning cash balance as of {date}', { date: dayjs(props.report?.dateRange?.start).format('MM/DD/YYYY') }),
        cashIn: ' ',
        cashOut: ' ',
        balance: props.report?.properties?.reduce((acc, item) => acc + (item.beginningCash || 0), 0),
      },
    });

    property?.transactions?.forEach((transaction) => {
      res.push({
        label: dayjs(transaction.paymentDate).format('MM/DD/YYYY'),
        level: 0,
        values: transaction as Record<string, string | number | null | undefined>,
      });
    });

    res.push({
      label: '',
      level: 0,
      values: {
        payeeOrPayerName: ' ',
        description: t('Ending cash balance'),
        cashIn: ' ',
        cashOut: ' ',
        balance: props.report?.properties?.reduce((acc, item) => acc + (item.endingCash || 0), 0),
      },
    });

    res.push({
      label: t('Total'),
      type: 'total',
      level: 0,
      values: {
        ...property?.transactionsTotal,
        balance: '',
      },
    });

    return res;
  }

  function getDueDateLines(property: OwnerStatementReportPropertyDto) {
    const res: LineItem[] = [];

    property?.billsDue?.forEach((bill) => {
      res.push({
        label: dayjs(bill.dueDate).format('MM/DD/YYYY'),
        level: 0,
        values: bill as Record<string, string | number | null | undefined>,
      });
    });

    res.push({
      label: t('Total'),
      type: 'total',
      level: 0,
      values: property?.billsDueTotal as Record<string, string | number | null | undefined>,
    });

    return res;
  }

  return (
    <Show
      when={props.report?.properties && props.report?.properties.length}
      fallback={
        <div class="flex w-full items-center justify-center">
          <Empty />
        </div>
      }>
      <For each={props.report?.properties}>
        {(item) => (
          <>
            <div class="mb-4 text-lg font-semibold text-title-gray">{<DisplayAddress {...item.property} />}</div>
            <TableTitle>{t('Property cash summary')}</TableTitle>
            <ReportTable
              label={t('Cash summary')}
              lines={getPropertyCashSummaryLines(item)}
              class="mb-5"
              columns={[
                {
                  label: '',
                  type: 'number',
                  render(item) {
                    return commaNumber((item.values as never) ?? 0);
                  },
                },
              ]}
            />
            <Show when={item.netOwnerFunds && item.netOwnerFunds < 0}>
              <p class="mb-5 text-right text-lg">
                <span>{t('Please remit balance due')}:</span>
                <span class="px-4 font-semibold text-warning">{currency(Math.abs(item.netOwnerFunds ?? 0))}</span>
              </p>
            </Show>

            <TableTitle>{t('Transactions')}</TableTitle>

            <ReportTable
              label={t('Date')}
              lines={getTransactionLines(item)}
              class="mb-5"
              columns={parseColumns([
                {
                  name: 'Payee/Payer',
                  id: 'payeeOrPayerName',
                  type: 'string',
                },
                {
                  name: 'Description',
                  id: 'description',
                  type: 'string',
                },
                {
                  name: 'Cash in',
                  id: 'cashIn',
                },
                {
                  name: 'Cash out',
                  id: 'cashOut',
                },
                {
                  name: 'Balance',
                  id: 'balance',
                },
              ])}
            />

            <TableTitle>{t('Bills due')} </TableTitle>
            <ReportTable
              label={t('Due date')}
              lines={getDueDateLines(item)}
              class="mb-5"
              columns={parseColumns([
                {
                  name: 'Description',
                  id: 'description',
                  type: 'string',
                },
                {
                  name: 'Amount',
                  id: 'amount',
                },
              ])}
            />
          </>
        )}
      </For>
    </Show>
  );
};

export const OwnerStatementReportPage = () => {
  const navigate = useNavigate();

  const { t } = useLocalization();
  const { properties } = useProperties();
  const { filtered, setFilter } = useOwners();
  const { ownerStatementReport, getOwnerStatementReport, dealMultipleFilter, getFilterDisplay } = useReports();

  const [searchParams, setSearchParams] = useSearchParams<{
    propertyIds?: string;
    ownerId?: string;
    start: string;
    end: string;
    includeAllUnpaidBills?: string;
  }>();

  const [selectedOwner, setSelectedOwner] = createSignal<MagicDoor.Api.OwnerDto | undefined>(undefined);
  const [printContainer, setPrintContainer] = createSignal<HTMLDivElement>();
  const [initialLoad, setInitialLoad] = createSignal(true);

  const saveOwnerIdToLocalStorage = (ownerId: string) => {
    localStorage.setItem('ownerStatementLastSelectedOwnerId', ownerId);
  };

  const getOwnerIdFromLocalStorage = (): string | null => {
    return localStorage.getItem('ownerStatementLastSelectedOwnerId');
  };

  const breadcrumbItems = createMemo(() => [{ label: t('Reports'), link: '/reports' }, { label: t('Owner statement') }]);

  const filteredProperties = createMemo(() => {
    return (
      properties()
        ?.filter((item) => !searchParams.ownerId || item.owners?.find((owner) => owner.owner?.id === searchParams.ownerId))
        .map((item) => ({ label: item.name as string, value: item.id })) ?? []
    );
  });

  const handlePropertyChange = (value: string[], current: string) => {
    value = dealMultipleFilter(value, current);

    if (value.includes('All')) {
      setSearchParams({ propertyIds: '' });
    } else {
      setSearchParams({ propertyIds: value.toString() });
    }
  };

  const handleOwnerChange = (value: MagicDoor.Api.OwnerDto) => {
    setSearchParams({ ownerId: value.id, propertyIds: undefined });
    setSelectedOwner(value);
    saveOwnerIdToLocalStorage(value.id);
  };

  function shouldNotUpdate(params: any, preParams: any) {
    return (
      params?.ownerId === preParams?.ownerId &&
      params?.propertyIds === preParams?.propertyIds &&
      compareTwoDateRange([params, untrack(ownerStatementReport)?.dateRange]) &&
      params?.includeAllUnpaidBills === preParams?.includeAllUnpaidBills
    );
  }

  createEffect(
    on(
      () => ({ ...searchParams }),
      (params, preParams) => {
        if (shouldNotUpdate(params, preParams)) return;
        const { propertyIds, ownerId } = searchParams;

        if (ownerId) {
          getOwnerStatementReport({
            propertyIds: propertyIds?.split(','),
            ownerId,
            dateRange: { start: searchParams.start, end: searchParams.end },
            includeAllUnpaidBills: searchParams.includeAllUnpaidBills === 'true',
          });
        }
      }
    )
  );

  createEffect(() => {
    if (searchParams.includeAllUnpaidBills === undefined) {
      setSearchParams({ includeAllUnpaidBills: 'true' });
    }
  });

  createEffect(() => {
    const report = ownerStatementReport();
    if (report?.dateRange == null) return;
    setSearchParams({ start: report.dateRange.start, end: report.dateRange.end });
  });

  onMount(() => {
    setFilter({});
  });

  createEffect(() => {
    const currentOwnerId = searchParams.ownerId;
    if (currentOwnerId) {
      const currentOwner = filtered()?.items.find((item) => item.id === currentOwnerId);
      if (currentOwner) {
        setSelectedOwner(currentOwner);
      }
    }
  });

  createEffect(
    on(filtered, () => {
      const _items = filtered()?.items;
      if (_items && _items.length > 0) {
        let ownerToSelect;

        if (initialLoad()) {
          const lastSelectedOwnerId = searchParams.ownerId || getOwnerIdFromLocalStorage();
          if (lastSelectedOwnerId) {
            ownerToSelect = _items.find((item) => item.id === lastSelectedOwnerId);
          }
          if (!ownerToSelect) {
            ownerToSelect = _items[0];
          }
          setInitialLoad(false);
        } else {
          const currentOwnerId = searchParams.ownerId;
          if (currentOwnerId) {
            ownerToSelect = _items.find((item) => item.id === currentOwnerId);
          }
          if (!ownerToSelect) {
            ownerToSelect = _items[0];
          }
        }

        if (!searchParams.ownerId || searchParams.ownerId !== ownerToSelect.id) {
          setSearchParams({ ownerId: ownerToSelect.id });
          setSelectedOwner(ownerToSelect);
          saveOwnerIdToLocalStorage(ownerToSelect.id);
        } else {
          setSelectedOwner(ownerToSelect);
        }
      }
    })
  );
  const printHeader = createMemo(() => {
    const property = getFilterDisplay(searchParams.propertyIds, '', properties());
    const owner = `${selectedOwner()?.firstName} ${selectedOwner()?.lastName ?? ''}` || 'All';
    const start = searchParams.start;
    const end = searchParams.end;
    return (
      <div>
        <div class="flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Owner statement report')}</h1>
        </div>
        <div class="my-6 flex flex-wrap items-center gap-4">
          <div>
            {t('Owner')} : {owner}
          </div>
          <div>
            {t('Property')} : {property.propertiesStr}
          </div>
          <div>
            {t('Date range start')} : {start}
          </div>
          <div>
            {t('Date range end')} : {end}
          </div>
        </div>
      </div>
    );
  });

  const [printContainerWidth, setPrintContainerWidth] = createSignal<string>('1600px');

  createEffect(() => {
    if (!ownerStatementReport.loading) {
      const clientWidth = printContainer()?.children[0]?.children[1]?.clientWidth;
      clientWidth && setPrintContainerWidth(`${clientWidth + clientWidth * 0.2}px`);
    }
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="m-8 rounded-lg border border-partingline bg-white p-8">
        <div class="flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Owner statement report')}</h1>
          <PrintButton
            // disabled={ownerStatementReport.loading}
            printContainer={printContainer()!}
            printHeader={printHeader() as Element}
            isReport={true}
            printWrapClass="w-auto"
            extraStyle="width:1590px;zoom:0.6;"
          />
        </div>
        <div class="my-6 flex flex-wrap items-center gap-3">
          <OwnerSearch
            placeholder={t('Owner')}
            selected={selectedOwner()}
            onSelect={(owner) => {
              if (owner?.id) {
                handleOwnerChange(owner);
              }
            }}
          />
          <Select
            class="min-w-64 grow"
            placeholder={t('Property')}
            options={[{ label: t('All'), value: 'All' }, ...filteredProperties()]}
            value={searchParams.propertyIds ? searchParams.propertyIds.split(',') : ['All']}
            onChange={handlePropertyChange}
            multiple
          />
          <DatePicker
            class="min-w-64 grow"
            placeholder={t('Date range start')}
            value={searchParams.start}
            onChange={(value) => {
              if (value !== null && searchParams.end <= value) {
                const start = new Date(value);
                start.setDate(start.getDate() + 30);
                searchParams.end = start.toISOString().split('T')[0];
              }
              setSearchParams({ ...searchParams, start: value });
            }}
          />
          <DatePicker
            class="min-w-64 grow"
            placeholder={t('Date range end')}
            value={searchParams.end}
            onChange={(value) => {
              if (value !== null && searchParams.start >= value) {
                const end = new Date(value);
                end.setDate(end.getDate() - 30);
                searchParams.start = end.toISOString().split('T')[0];
              }
              setSearchParams({ ...searchParams, end: value });
            }}
          />
          <Checkbox
            showLabel
            label={<span class="text-sm text-title-gray">{t('Include all unpaid bills')}</span>}
            checked={searchParams.includeAllUnpaidBills === 'true'}
            onChange={(e) => {
              setSearchParams({
                ...searchParams,
                includeAllUnpaidBills: e.target.checked ? 'true' : 'false',
              });
            }}
          />
        </div>
        <div ref={(e) => setPrintContainer(e)}>
          <Show when={ownerStatementReport.loading} fallback={<OwnerStatementReport report={ownerStatementReport()} />}>
            <IconLoader class="mx-auto my-56 animate-spin" />
          </Show>
        </div>
      </div>
    </>
  );
};

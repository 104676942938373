import { useParams, useNavigate } from '@solidjs/router';
import { Show, createEffect } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Panel } from '~/components/common/Panels';
import { IconLoader } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBankAccount } from '~/contexts/local';
import EditBankAccount from '~/pages/bank-accounts/components/EditBankAccount';

const EditBankAccountPage = () => {
  const { bankAccountId } = useParams();
  const { bankAccount, setBankAccountId, isLoading } = useBankAccount();
  const navigate = useNavigate();

  const { t } = useLocalization();

  const breadcrumbItems = () => [
    { label: t('Bank accounts'), link: '/accounting/bank-accounts' },
    { label: bankAccount()?.name || t('Loading'), link: `/accounting/bank-accounts/${bankAccountId}` },
    { label: t('Edit bank account') },
  ];

  createEffect(() => {
    setBankAccountId(bankAccountId);
  });

  createEffect(() => {
    bankAccount();
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <Show
        when={!isLoading()}
        fallback={
          <Panel class="m-8 flex h-section1 flex-col items-center justify-center gap-2">
            <IconLoader class="animate-spin text-title-gray" />
            {t('Loading')}...
          </Panel>
        }>
        <EditBankAccount />
      </Show>
    </>
  );
};

export default EditBankAccountPage;

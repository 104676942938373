import { Route, Routes } from '@solidjs/router';
import { AiRunbookTabRoutes } from '~/pages/ai-runbooks/AiRunbookTabRoutes';
import { RunbookDetailsPage } from '~/pages/ai-runbooks/runbooks-details';
import { AddAndEditRunbookModal } from './forms/AddAndEditRunbookModal';

export const AiRunbooksRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/:runbookId/*" component={RunbookDetailsPage} matchFilters={{ runbookId: /^\d+$/ }} />
        <Route path="*" component={AiRunbookTabRoutes} />
      </Routes>
      <Routes>
        <Route path="/custom/add" component={AddAndEditRunbookModal} />
        <Route path="/:runbookId/edit" component={AddAndEditRunbookModal} />
        <Route path="/custom/:runbookId/edit" component={AddAndEditRunbookModal} />
      </Routes>
    </>
  );
};

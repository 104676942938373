import { useNavigate } from '@solidjs/router';
import { createEffect, createMemo } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { ListView } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useAnnouncements } from '~/contexts/local';
import { useLocalPagination } from '~/hooks';
import { dateFormat } from '~/utils/date';
import type { AnnouncementListDto } from '~/swagger/Api';

const AnnouncementsListView = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('AnnouncementsListView');
  const { isLoading, announcements, fetchAnnouncements } = useAnnouncements();

  const getAnnouncements = async () => {
    await fetchAnnouncements();
  };

  const announcementList = createMemo<AnnouncementListDto[]>(() => {
    const data = announcements();

    if (!data || typeof data !== 'object' || !('announcements' in data)) {
      return [];
    }

    return Array.isArray(data.announcements) ? data.announcements : [];
  });

  createEffect(() => {
    getAnnouncements();
  });

  return (
    <div class="relative">
      <ListView
        page={page()}
        pageSize={pageSize()}
        onChange={({ page, pageSize }) => {
          setPage(page);
          setPageSize(pageSize);
        }}
        title={t('Announcement')}
        titleActions={
          <div class="flex items-center gap-2">
            <Button
              class="ml-auto text-sm capitalize"
              onClick={() => {
                navigate('/communications/announcements/add');
              }}>
              <IconPlus class="text-white" />
              {t('Add announcement')}
            </Button>
          </div>
        }
        data={announcementList()}
        loading={isLoading()}
        skeletonSize={10}
        rowLink={(item) => `/communications/announcements/${item.id}`}
        columns={[
          {
            title: t('Subject'),
            render: (row: AnnouncementListDto) => (
              <div>
                <div class="mb-1 font-medium">{row.subject || t('No Subject')}</div>
                <div class="text-gray-400">{row.chatMessage || row.emailMessage || t('No Message')}</div>
              </div>
            ),
          },
          {
            title: t('Sent at'),
            render: (row: AnnouncementListDto) => <div class="font-medium">{dateFormat(undefined, row.sentAt)}</div>,
          },
          {
            title: t('Targets count'),
            render: (row: AnnouncementListDto) => <div class="font-medium">{row.targetsCount}</div>,
          },
        ]}
      />
    </div>
  );
};

export default AnnouncementsListView;

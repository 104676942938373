import { Route, Routes } from '@solidjs/router';
import { ViolationsProvider } from '~/contexts/local';
import { AddViolationView } from '~/pages/violations/add-violation/AddViolation';
import { EditViolation } from '~/pages/violations/edit-violation/EditViolation';
import { ViolationDetailsPage } from '~/pages/violations/violation-details';
import { ListViolations } from './list-violations';

export const ViolationsRoutes = () => {
  return (
    <ViolationsProvider>
      <Routes>
        <Route path="/add" component={AddViolationView} />
        <Route path="/:violationId/edit" component={EditViolation} />
        <Route path="/:violationId/:modalType?" component={ViolationDetailsPage} />
        <Route path="/:violationId/*" component={ViolationDetailsPage} />
        <Route path="*" component={ListViolations} />
      </Routes>
    </ViolationsProvider>
  );
};

import { Route, Routes } from '@solidjs/router';
import { CompanySettingsProvider, BankAccountsListProvider } from '~/contexts/local';
import { CompanyInformation } from './company-information';

const CompanyRoutes = () => {
  return (
    <CompanySettingsProvider>
      <BankAccountsListProvider>
        <Routes>
          <Route path="/information" element={<CompanyInformation />} />
        </Routes>
      </BankAccountsListProvider>
    </CompanySettingsProvider>
  );
};

export default CompanyRoutes;

import { Show } from 'solid-js';
import { cn } from '~/utils/classnames';
import ContentSection from './ContentSection';
import TopSection from './TopSection';
import type { JSX } from 'solid-js';

interface ThreePartBoxProps {
  topTitle: JSX.Element;
  topAction?: JSX.Element;
  middle: JSX.Element;
  bottom?: JSX.Element;
  class?: string;
  coloredClass?: string;
}

const ThreeSectionBox = (props: ThreePartBoxProps) => (
  <div class={cn('flex flex-col overflow-hidden rounded-lg border border-partingline bg-white', props.class)}>
    <TopSection title={props.topTitle} action={props.topAction} coloredClass={props.coloredClass} />
    <ContentSection content={props.middle} />
    <Show when={props.bottom}>
      <ContentSection content={props.bottom} />
    </Show>
  </div>
);

export default ThreeSectionBox;

import { UserWorkOrders } from './components/UserWorkOrders';
import { WorkOrderTable } from './components/WorkOrderTable';

export const ListWorkOrders = () => {
  return (
    <div class="flex flex-col gap-4">
      <UserWorkOrders />
      <WorkOrderTable title="Current work orders" />
    </div>
  );
};

import { createEffect, on } from 'solid-js';
import { Form } from '~/components/common/BetterForm';
import { useForm } from '~/components/common/BetterForm/context';
import { BaseModal } from '~/components/modals/BaseModal';
import { Footer } from '~/components/modals/Footer';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { RentalBasicInfo } from '~/pages/rental-applications/add-rental-application/RentalBasicInfo';
import { currentUpdateType } from '~/utils/constant';
import { pick } from '~/utils/tool';

export const RentalInfomationModal = () => {
  const { applicationStore, setApplicationStore, baseUpdate } = useRentalApplication();
  const { t } = useLocalization();
  const form = useForm();

  createEffect(
    on([() => applicationStore.currentUpdateType], () => {
      if (applicationStore.currentUpdateType !== currentUpdateType.rentalInfo || !applicationStore.application) return;
      form.setFieldsValue(pick(applicationStore.application, ['dateOfBirth', 'phone', 'email']));
    })
  );

  const onHide = () => {
    setApplicationStore('currentUpdateType', null);
    form.resetFields();
  };

  const onSubmit = () => {
    const validationResult = form.validateForm();

    if (!validationResult.validateStatus) {
      return;
    }

    baseUpdate(form.formStore).then(() => {
      onHide();
    });
  };

  return (
    <BaseModal
      size="md"
      title={t('Update rental information')}
      visible={applicationStore.currentUpdateType === currentUpdateType.rentalInfo}
      onClose={() => {
        setApplicationStore('currentUpdateType', null);
      }}>
      <div class="flex max-h-[50vh] flex-col gap-2 overflow-y-auto p-4">
        <Form defaultForm={form}>
          <RentalBasicInfo />
        </Form>
      </div>
      <Footer onOk={onSubmit} okProps={{ loading: applicationStore.updateLoading }} onCancel={onHide} />
    </BaseModal>
  );
};

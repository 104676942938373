import { useNavigate } from '@solidjs/router';
import IconTenantsAutoPayments from '~/assets/images/leases/tenantsAutoPayments.svg';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import { AutoPaymentsTable } from './AutoPaymentsTable';

interface TenantsAutoPaymentsPanelProps {
  autoPayments: MagicDoor.Api.HydratedTenantAutoPayDto[];
  isLoading: boolean;
}

export const TenantsAutoPaymentsPanel = (props: TenantsAutoPaymentsPanelProps) => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const onTableRowClick = (record: MagicDoor.Api.HydratedTenantAutoPayDto) => {
    navigate(`./auto-payment/${record.id}`);
  };

  return (
    <Panel
      header={
        <div
          style={{ 'background-image': `url(${IconTenantsAutoPayments})` }}
          class="flex items-center justify-between border-b border-partingline bg-[rgba(158,253,232,.06)] bg-right bg-no-repeat px-6 py-4">
          <h3 class="text-lg font-semibold">{t('Tenants Auto Payments')}</h3>
        </div>
      }>
      <AutoPaymentsTable records={props.autoPayments} isLoading={props.isLoading} onRowClick={onTableRowClick} />
    </Panel>
  );
};

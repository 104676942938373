import { Routes, Route } from '@solidjs/router';
import { AddOwnerDistributionsProvider } from '~/contexts/local';
import { AddDistributionModal } from '~/pages/owner-funds/distributions/components/AddDistributionModal';
import { AddDistribution } from './Add';
import { DistributionsList } from './List';

export const DistributionsPage = () => (
  <AddOwnerDistributionsProvider>
    <Routes>
      <Route path="/calculate" component={AddDistribution} />
      <Route path="*" component={DistributionsList} />
    </Routes>
    <Routes>
      <Route path="/add" component={AddDistributionModal} />
    </Routes>
  </AddOwnerDistributionsProvider>
);

import { useParams } from '@solidjs/router';
import { Show, onMount } from 'solid-js';
import { RingLoader } from '~/components/common/Loaders';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import DocumentUpload from '~/pdfsigner/ui/components/DocumentUpload';
import Toolbar from '~/pdfsigner/ui/components/toolbar/Toolbar';
import { ErrorHandler } from '~/pdfsigner/ui/context/ErrorContext';
import { LoadLeaseTemplateUseCase } from '~/pdfsigner/usecases/loadLeaseTemplateUseCase';
import type { JSX } from 'solid-js';

const Composer = (): JSX.Element => {
  const { execute: loadTemplate, isLoading } = useUseCase(LoadLeaseTemplateUseCase);
  const { templateId } = useParams();

  onMount(() => {
    loadTemplate(templateId);
  });

  return (
    <Show
      when={!isLoading()}
      fallback={
        <div class="flex size-full h-screen flex-wrap items-center justify-center">
          <RingLoader color="#a126ec" size={125} borderWidth={5} showIcon />
        </div>
      }>
      <div class="flex size-full min-w-[1000px] flex-col overflow-x-auto p-2">
        <ErrorHandler>
          <Toolbar />
          <DocumentUpload />
        </ErrorHandler>
      </div>
    </Show>
  );
};

export default Composer;

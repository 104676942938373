import { UseCase } from './useCase';

export class ChangeFontSizeUseCase extends UseCase {
  public async runLogic(strFontSize: string) {
    const fontSize = parseInt(strFontSize);
    if (!isNaN(fontSize) && fontSize >= 0 && fontSize < 999) {
      this.state.selectedFontSize = fontSize;
      if (this.state.selectedAnnotation) {
        this.state.selectedAnnotation.fontSize = fontSize;
      }
    }
  }
}

import { Route, Routes, useNavigate } from '@solidjs/router';
import { BankAccountsListProvider, PlaidProvider, StripeProvider } from '~/contexts/local';
import BankAccountDetailsPage from './bank-account-details';
import BankAccountEditPage from './bank-account-details/edit-components';
import { ListBankAccountsPage } from './list-bank-accounts';
import { AddBankAccountsModal } from './list-bank-accounts/AddBankAccountPage';

const BankAccountsRoutes = () => {
  const navigate = useNavigate();

  return (
    <BankAccountsListProvider>
      <PlaidProvider>
        <StripeProvider>
          <Routes>
            <Route path="/">
              <Route
                path="/add"
                element={
                  <>
                    <ListBankAccountsPage />
                    <AddBankAccountsModal onClose={() => navigate('/accounting/bank-accounts', { scroll: false })} />
                  </>
                }
              />
              <Route path="/:bankAccountId/edit" element={<BankAccountEditPage />} />
              <Route path="/:bankAccountId/*" element={<BankAccountDetailsPage />} />
              <Route path="*" element={<ListBankAccountsPage />} />
            </Route>
          </Routes>
        </StripeProvider>
      </PlaidProvider>
    </BankAccountsListProvider>
  );
};

export default BankAccountsRoutes;

import type { Icon } from '~/components/ui';

export const IconTenantsSummaryCard: Icon = (props) => (
  <svg width="90" height="120" viewBox="0 0 90 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clip-path="url(#clip0_25_35)">
      <mask id="mask0_25_35" style={{ 'mask-type': 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="90" height="120">
        <rect width="90" height="120" fill="white" />
      </mask>
      <g mask="url(#mask0_25_35)">
        <path
          opacity="0.3"
          d="M62 76.4934C86.7061 76.4934 107 56.1597 107 30.7467C107 5.33377 86.706 -15 62 -15C37.294 -15 17 5.33378 17 30.7467C17 56.1597 37.294 76.4934 62 76.4934Z"
          stroke="url(#paint0_linear_25_35)"
          stroke-width="24"
        />
        <path
          d="M10.0659 118C10.7362 112.515 13.4625 108.618 18 105.87C13.3947 103.177 10.8868 99.2399 10.0508 94C9.06425 99.1846 6.62791 103.24 2 105.945C6.81996 108.539 9.12826 112.748 10.0659 118Z"
          fill="white"
          fill-opacity="0.16"
        />
        <path
          d="M23.5288 98C23.8221 95.4862 25.0148 93.6999 27 92.4403C24.9852 91.206 23.888 89.4016 23.5222 87C23.0906 89.3763 22.0247 91.2349 20 92.4747C22.1087 93.6637 23.1186 95.593 23.5288 98Z"
          fill="white"
          fill-opacity="0.06"
        />
      </g>
    </g>
    <defs>
      <linearGradient id="paint0_linear_25_35" x1="62" y1="9.47161" x2="16.8032" y2="64.1092" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0" />
        <stop offset="1" stop-color="white" stop-opacity="0.4" />
      </linearGradient>
      <clipPath id="clip0_25_35">
        <rect width="90" height="120" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const IconActiveCount: Icon = (props) => (
  <svg width="9" height="10" viewBox="0 0 9 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.37168 5.24879C4.61098 5.24879 5.6044 4.24252 5.6044 2.98719C5.6044 1.73186 4.61098 0.725586 3.37168 0.725586C2.13239 0.725586 1.13897 1.73186 1.13897 2.98719C1.13897 4.24252 2.13239 5.24879 3.37168 5.24879ZM3.37429 6.35638C1.55407 6.35638 0 6.64741 0 7.81014C0 8.97242 1.54462 9.27393 3.37429 9.27393C5.19406 9.27393 6.74858 8.98291 6.74858 7.82018C6.74858 6.65744 5.20396 6.35638 3.37429 6.35638ZM8.05233 3.91427H8.59361C8.8164 3.91427 8.99806 4.09848 8.99806 4.32441C8.99806 4.55034 8.8164 4.73455 8.59361 4.73455H8.05233V5.26422C8.05233 5.49015 7.87114 5.67436 7.64788 5.67436C7.42508 5.67436 7.24343 5.49015 7.24343 5.26422V4.73455H6.70308C6.47982 4.73455 6.29863 4.55034 6.29863 4.32441C6.29863 4.09848 6.47982 3.91427 6.70308 3.91427H7.24343V3.38507C7.24343 3.15914 7.42508 2.97493 7.64788 2.97493C7.87114 2.97493 8.05233 3.15914 8.05233 3.38507V3.91427Z" />
  </svg>
);

export const IconGuestCount: Icon = (props) => (
  <svg width="9" height="10" viewBox="0 0 9 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.77979 6.58584C3.88787 6.32811 4.04631 6.09471 4.24578 5.8994C3.71743 5.91175 3.19724 5.76708 2.75066 5.4836C1.69339 5.76094 0.738321 6.33833 0 7.1465V8C0 8.15913 0.0630209 8.31174 0.175199 8.42426C0.287377 8.53679 0.439522 8.6 0.598166 8.6H3.63774C3.62399 8.5019 3.61651 8.402 3.61651 8.3V7.4C3.61619 7.1204 3.67171 6.84357 3.77979 6.58584Z" />
    <path d="M5.66754 1.11508C5.27492 0.721249 4.74241 0.5 4.18716 0.5C3.91223 0.5 3.63999 0.554318 3.38598 0.659853C3.13198 0.765388 2.90118 0.920073 2.70678 1.11508C2.51237 1.31008 2.35816 1.54158 2.25294 1.79636C2.14773 2.05115 2.09358 2.32422 2.09358 2.6V3.2C2.09358 3.75695 2.31415 4.2911 2.70678 4.68492C3.0994 5.07875 3.63191 5.3 4.18716 5.3C4.74241 5.3 5.27492 5.07875 5.66754 4.68492C6.06017 4.2911 6.28074 3.75695 6.28074 3.2V2.6C6.28074 2.04305 6.06017 1.5089 5.66754 1.11508Z" />
    <path d="M4.81314 6.0875C4.83916 5.8154 5.09936 5.6 5.4143 5.6H8.39615C8.72843 5.6 9 5.8397 9 6.1328V8.123C9 8.3315 8.86212 8.5127 8.66293 8.6C8.66473 8.585 8.66592 8.57 8.66592 8.5547V6.5642C8.66592 6.2714 8.39406 6.0317 8.06178 6.0317H5.07992C4.98422 6.0317 4.89389 6.0521 4.81314 6.0875Z" />
    <path d="M4.81822 6.5H7.79858C8.13027 6.5 8.40184 6.7352 8.40184 7.0229V8.9771C8.40184 9.2648 8.13057 9.5 7.79858 9.5H4.81822C4.48624 9.5 4.21468 9.2648 4.21468 8.9771V8.669H6.04566C6.19012 8.669 6.30825 8.5664 6.30825 8.441V8.036C6.30825 7.9106 6.19012 7.808 6.04566 7.808H4.21468V7.0229C4.21468 6.7352 4.48624 6.5 4.81822 6.5ZM7.25575 7.154C7.25575 7.3244 7.41546 7.463 7.61255 7.463C7.80935 7.463 7.96906 7.3247 7.96906 7.1537C7.96906 6.983 7.80935 6.8447 7.61255 6.8447C7.41546 6.8447 7.25575 6.983 7.25575 7.154Z" />
  </svg>
);

export const IconLeaseBackground: Icon = (props) => (
  <svg width="168" height="68" viewBox="0 0 168 68" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clip-path="url(#clip0_75_268)">
      <g opacity="0.4">
        <path
          d="M56.4563 43.8227H71.714C73.3155 43.8227 74.6131 42.5144 74.6131 40.9045C74.6131 39.2945 73.3155 37.9862 71.7188 37.9862H56.4563C54.8596 37.9862 53.562 39.2945 53.562 40.9045C53.562 42.5144 54.8596 43.8227 56.4563 43.8227Z"
          fill="url(#paint0_linear_75_268)"
        />
        <path
          d="M81.7427 78.1516C82.2395 78.638 82.9004 78.9104 83.5902 78.9104C83.6384 78.9104 83.6867 78.9104 83.7397 78.9055C84.1053 78.8859 84.4628 78.7902 84.7899 78.6245C85.117 78.4587 85.4065 78.2265 85.6403 77.9425L94.0337 67.7576C94.2608 67.4877 94.4326 67.175 94.5391 66.8377C94.6456 66.5004 94.6847 66.1451 94.6541 65.7925C94.6236 65.4398 94.5239 65.0968 94.361 64.7832C94.198 64.4697 93.975 64.1918 93.7049 63.9657C93.4347 63.7396 93.1228 63.5698 92.7871 63.4661C92.4514 63.3624 92.0987 63.3268 91.7493 63.3615C91.3999 63.3961 91.0608 63.5003 90.7516 63.668C90.4424 63.8357 90.1692 64.0635 89.9479 64.3384L83.3876 72.3005L80.2328 69.2071C79.983 68.9619 79.6877 68.7689 79.3639 68.6389C79.04 68.5089 78.6939 68.4446 78.3454 68.4495C77.9968 68.4545 77.6527 68.5287 77.3326 68.6678C77.0125 68.807 76.7227 69.0084 76.4799 69.2606C76.2368 69.5124 76.0453 69.8102 75.9164 70.1367C75.7875 70.4633 75.7237 70.8122 75.7286 71.1637C75.7335 71.5151 75.8071 71.8621 75.9451 72.1849C76.0831 72.5076 76.2829 72.7998 76.533 73.0446L81.7427 78.1516Z"
          fill="url(#paint1_linear_75_268)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M96.9105 50.7927V54.2314C101.802 57.8355 104.995 63.6575 105 70.214C105 81.1235 96.1966 90 85.3768 90C74.557 90 65.7536 81.1235 65.7536 70.214C65.7505 67.2133 66.4287 64.2518 67.7362 61.5564H60.2062C58.8314 61.5564 57.7171 62.6848 57.7171 64.0661V85.0729C57.7171 86.6829 56.4195 87.9912 54.8229 87.9912H38.2242C33.9551 87.9912 30.482 84.4893 30.482 80.1848V50.7927C27.8385 50.7489 25.499 49.1439 24.4956 46.673C23.9974 45.4547 23.8712 44.114 24.1333 42.8229C24.3955 41.5318 25.0339 40.3489 25.9669 39.4259L55.0978 10.4667C59.7335 5.86068 67.302 5.84123 71.9618 10.4278L101.401 39.4016C103.326 41.2985 103.915 44.1439 102.902 46.6585C102.417 47.8653 101.59 48.9012 100.524 49.6365C99.4587 50.3719 98.2017 50.7741 96.9105 50.7927ZM51.9238 82.1595V64.0709C51.9238 59.4698 55.6381 55.7295 60.211 55.7295H69.4245C70.1818 55.7295 70.9151 55.8754 71.6097 56.1478C75.1552 52.6215 80.0176 50.4376 85.3816 50.4376C87.3306 50.4367 89.2688 50.7302 91.1316 51.3083V47.8841C91.1316 46.2742 92.4292 44.9658 94.0259 44.9658H96.8092C97.253 44.9658 97.4556 44.6935 97.5472 44.4649C97.6389 44.2363 97.6823 43.8958 97.3639 43.5845L67.9243 14.6107C65.5028 12.2274 61.5714 12.2372 59.1643 14.6302L30.0285 43.5845C29.715 43.8958 29.7584 44.2314 29.8501 44.46C29.9417 44.6886 30.1443 44.961 30.5881 44.961H33.3714C34.9681 44.961 36.2657 46.2693 36.2657 47.8793V80.1897C36.2657 81.2743 37.1436 82.1595 38.2194 82.1595H51.9238ZM71.5373 70.2188C71.5373 77.9085 77.7455 84.1683 85.372 84.1683C92.9984 84.1683 99.2066 77.9134 99.2066 70.2188C99.2066 62.5243 93.0032 56.2694 85.372 56.2694C77.7407 56.2694 71.5373 62.5291 71.5373 70.2188Z"
          fill="url(#paint2_linear_75_268)"
        />
      </g>
    </g>
    <defs>
      <linearGradient id="paint0_linear_75_268" x1="64.5" y1="7" x2="64.5" y2="69" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint1_linear_75_268" x1="64.5" y1="7" x2="64.5" y2="69" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint2_linear_75_268" x1="64.5" y1="7" x2="64.5" y2="69" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <clipPath id="clip0_75_268">
        <rect width="168" height="68" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

import { splitProps } from 'solid-js';
import { Portal } from 'solid-js/web';
import type { Component, ComponentProps } from 'solid-js';

export const Modal: Component<ComponentProps<'div'>> = (props) => {
  const [params, rest] = splitProps(props, ['ref']);
  return (
    <Portal ref={params.ref}>
      <div class="fixed inset-0 z-50 flex w-screen animate-fade-in items-center justify-center bg-black/80 p-2 sm:p-4 md:p-10">
        <div {...rest} role="dialog" />
      </div>
    </Portal>
  );
};

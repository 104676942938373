import { createMemo, createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { EmailRepository } from '~/repositories/emailRepository';
import { createLazyResource, createTriggerResource, filterResource } from '~/utils/resource';
import type { EmailListFilter, EmailFilter, SendEmailQuest } from '~/repositories/emailRepository';

const repo = new EmailRepository();
const URL_PREFIX = '/api/emails/';

export const [EmailsProvider, useEmails] = createMagicDoorContext('Emails', () => {
  const [emails, { fetch, refetch, mutate }] = createLazyResource(async () => {
    const pageSize = 100;
    const pagination = await repo.getEmails({ pageSize });
    if (pagination.totalPages === 1) return pagination.items;
    const restPaginations = await Promise.all(
      Array.from({ length: pagination.totalPages ? pagination.totalPages - 1 : 0 }, (_, i) => i + 2).map((page) =>
        repo.getEmails({ pageSize, page })
      )
    );
    return [pagination, ...restPaginations].flatMap((p) => p.items);
  });

  const [filter, setFilter] = createSignal<EmailListFilter>({});

  const filtered = createMemo(() => {
    const { page = 1, pageSize = 10 } = filter() ?? {};

    return filterResource(emails, {
      page,
      pageSize,
    });
  });

  const [email, getEmail, emailActions] = createTriggerResource((emailId: string) => repo.getEmail(emailId));

  const getOwnerEmails = async (ownerId: string, filter?: EmailFilter) => {
    return await repo.getOwnerEmails(ownerId, filter);
  };

  const getTenantEmails = async (tenantId: string, filter?: EmailFilter) => {
    return await repo.getTenantEmails(tenantId, filter);
  };

  const getLeaseEmails = async (leaseId: string, filter?: EmailFilter) => {
    return await repo.getLeaseEmails(leaseId, filter);
  };

  const getVendorEmails = async (vendorId: string, filter?: EmailFilter) => {
    return await repo.getVendorEmails(vendorId, filter);
  };

  const sendVendorEmails = async (vendorId: string, payload: SendEmailQuest) => {
    const url = `${URL_PREFIX}vendors/${vendorId}`;
    return await repo.postVendorEmails(url, payload);
  };

  const sendOwnersEmails = async (ownerId: string, payload: SendEmailQuest) => {
    const url = `${URL_PREFIX}owners/${ownerId}`;
    return await repo.postVendorEmails(url, payload);
  };

  const sendLeasesEmails = async (leaseId: string, payload: SendEmailQuest) => {
    const url = `${URL_PREFIX}leases/${leaseId}`;
    return await repo.postVendorEmails(url, payload);
  };

  const sendTenantsEmails = async (tenantId: string, payload: SendEmailQuest) => {
    const url = `${URL_PREFIX}tenants/${tenantId}`;
    return await repo.postVendorEmails(url, payload);
  };

  return {
    get emails() {
      fetch();
      return emails;
    },
    get loading() {
      fetch();
      return () => emails.loading;
    },
    get filtered() {
      fetch();
      return filtered;
    },
    refetch,
    setFilter,
    email,
    getEmail,
    getOwnerEmails,
    getTenantEmails,
    emailActions,
    getLeaseEmails,
    getVendorEmails,
    sendVendorEmails,
    sendOwnersEmails,
    sendLeasesEmails,
    sendTenantsEmails,
  };
});

import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

export type DepositSlipsFilter = {
  page?: number;
  pageSize?: number;
  bankAccountIds?: string[];
};

export type TransactionsFilter = {
  bankAccountIds?: string[];
  'dateTimeRange.start'?: string;
  'dateTimeRange.end'?: string;
};

const BASE_URL = '/api/deposit-slips';
export class DepositSlipsRepository extends BaseRestRepository {
  public async getDepositSlips(filter?: DepositSlipsFilter): Promise<MagicDoor.Api.DepositSlipPaginatedDto> {
    const url = urlWithQuery(BASE_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getDepositSlip(depositSlipId: string): Promise<MagicDoor.Api.DepositSlipDto> {
    const url = `${BASE_URL}/${depositSlipId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async addDepositSlip(payload: MagicDoor.Api.CreateDepositSlipsRequestDto): Promise<MagicDoor.Api.DepositSlipsDto> {
    const url = `${BASE_URL}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }
  public async getTransactions(filter?: TransactionsFilter): Promise<MagicDoor.Api.TransactionsDto> {
    const url = urlWithQuery(`${BASE_URL}/transactions`, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
}

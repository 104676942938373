import { useNavigate } from '@solidjs/router';
import { createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import { LeaseProvider } from '~/contexts/local';
import { NewLease } from './components/NewLease';

export const NewLeaseView = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const breadcrumbItems = createMemo(() => [{ label: t('Lease'), link: '/leasing/leases' }, { label: t('Set up a new lease') }]);

  return (
    <LeaseProvider>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <Panel class="m-8 flex h-section1 flex-col" title={t('Set up a new lease')}>
        <NewLease />
      </Panel>
    </LeaseProvider>
  );
};

import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

const methodMap: Record<`${MagicDoor.Api.TransactionPaymentMethod}`, string> = {
  ach: 'Ach',
  credit: 'Credit',
  debitCard: 'Debit Card',
  wallet: 'Wallet',
  creditCard: 'Credit Card',
  check: 'Check',
  cashiersCheck: 'Cashiers Check',
  moneyOrder: 'Money Order',
  cash: 'Cash',
  other: 'Other',
};

export const getTransactionPaymentMethod = (method?: `${MagicDoor.Api.TransactionPaymentMethod}`): string =>
  method ? methodMap[method] || 'Unknown' : 'Unknown';

export const TransactionPaymentMethod: Component<{ method: `${MagicDoor.Api.TransactionPaymentMethod}` }> = (props) => {
  const { t } = useLocalization();
  return <>{t(methodMap[props.method])}</>;
};

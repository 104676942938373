import type { JSX } from 'solid-js';

interface IconBubbleProps {
  icon: JSX.Element;
  upperContent: JSX.Element | string;
  lowerContent: JSX.Element | string;
}

function IconBubble(props: IconBubbleProps) {
  return (
    <div class="flex gap-5">
      <div class="h-fit rounded-lg border border-[#BDD8FF] bg-[#EEF5FF] p-0.5">{props.icon}</div>
      <div class="relative w-full rounded-xl text-left">
        <div class="absolute -left-1 top-5 size-3 -translate-y-1/2 rotate-45 bg-light-faded" />
        <div class="rounded-t-lg  bg-light-faded p-3">{props.upperContent}</div>
        <div class="rounded-b-lg border ">{props.lowerContent}</div>
      </div>
    </div>
  );
}

export default IconBubble;

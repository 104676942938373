import { Route, Routes, useParams, useNavigate } from '@solidjs/router';
import { createEffect, createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { TabLinks } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { RunbookSummary } from './components/RunbookSummary';
import { RunbookOverviewTab } from './tabs/RunbookOverviewTab';
import { RunbookScriptTab } from './tabs/RunbookScriptTab';
import type { TabLinkItem } from '~/components/ui';

export const RunbookDetailsPage = () => {
  const { t } = useLocalization();
  const params = useParams<{ runbookId: string }>();
  const navigate = useNavigate();

  const { getRunBook } = useRunBooks();

  createEffect(() => {
    getRunBook(params.runbookId);
  });

  const breadcrumbItems = createMemo(() => [
    { label: t('AI runbooks'), link: '/maintenance/ai-runbooks' },
    { label: t('AI runbook detail') },
  ]);

  const tabs = createMemo<TabLinkItem[]>(() => {
    return [
      { label: t('Overview'), href: '' },
      { label: t('Script'), href: 'script' },
    ];
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="my-5 flex flex-col-reverse gap-4 px-8 lg:flex-row">
        <div class="flex flex-1 flex-col gap-4">
          <TabLinks boxClass="mb-4 rounded-lg border border-partingline bg-white" items={tabs()} replace />
          <Routes>
            <Route path="/*" component={RunbookOverviewTab} />
            <Route path="/script/*" component={RunbookScriptTab} />
          </Routes>
        </div>
        <RunbookSummary />
      </div>
    </>
  );
};

import { createSignal, mergeProps, Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import { BaseModal } from './BaseModal';
import ConfirmationModal from './ConfirmationModal';
import type { BaseModalProps } from './BaseModal';
import type { ConfirmationModalProps } from './ConfirmationModal';
import type { Component, JSX } from 'solid-js';

export type ModalProps = Omit<BaseModalProps, 'onClose'> & {
  submitId?: string;
  loading?: boolean;
  disabled?: boolean;
  doneText?: string | JSX.Element;
  cancelText?: string;
  confirmation?: false | Pick<ConfirmationModalProps, 'title' | 'message' | 'cancelText' | 'confirmText'>;
  onCancel?: () => void;
  onDone?: () => void;
  footer?: JSX.Element;
  doneColor?: 'primary' | 'link' | 'warning';
};

export const Modal: Component<ModalProps> = (originProps) => {
  const { t } = useLocalization();
  const props = mergeProps(
    {
      size: 'md' as const,
      doneText: t('OK'),
      cancelText: t('Cancel'),
      confirmation: {
        title: t('Confirmation'),
        message: t('Are you sure you want to cancel?'),
        cancelText: t('Go back'),
        confirmText: t('Yes'),
      },
    },
    originProps
  );

  const [confirmModal, showConfirmModal] = createSignal(false);

  const handleConfirm = () => {
    showConfirmModal(false);
    props.onCancel && props.onCancel();
  };

  const handleClose = () => {
    if (props.confirmation === false) {
      props.onCancel && props.onCancel();
    } else {
      showConfirmModal(true);
    }
  };

  return (
    <BaseModal {...props} onClose={() => handleClose()} class={props.class}>
      {props.children}
      <Show when={!props.footer} fallback={props.footer}>
        <div class="flex justify-end gap-2 border-t border-partingline p-6" data-slot="footer">
          <Button class="capitalize" variant="outlined" type="button" onClick={() => handleClose()}>
            {props.cancelText}
          </Button>
          <Button
            class="capitalize"
            loading={props.loading}
            disabled={props.disabled || props.loading}
            form={props.submitId}
            color={props.doneColor}
            type={props.submitId ? 'submit' : 'button'}
            onClick={() => props.onDone && props.onDone()}>
            {props.doneText}
          </Button>
        </div>
      </Show>
      <Show when={confirmModal() && props.confirmation !== false}>
        <ConfirmationModal
          {...(props.confirmation as ConfirmationModalProps)}
          onConfirm={handleConfirm}
          onCancel={() => showConfirmModal(false)}
        />
      </Show>
    </BaseModal>
  );
};

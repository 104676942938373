import { createMemo, For, Show } from 'solid-js';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { Section } from '~/components/common/FormSectionTitle';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Table } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions } from '~/contexts/local';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import type { JSX } from 'solid-js';
import type { EditingDistribution } from '~/contexts/local';

export interface DistributionRowProps {
  columns: {
    title: string;
    class?: string;
    render: (record: MagicDoor.Api.CalculatedDistributionsForPropertyDto) => JSX.Element | string;
  }[];
  record: MagicDoor.Api.CalculatedDistributionsForPropertyDto;
  isLoading: boolean;
  isExpanded: boolean;
  onToggleExpand: () => void;
}

export const DistributionRow = (props: DistributionRowProps) => {
  const { t } = useLocalization();
  const { store, bankAccountOptions, updateOwnerDistributionRecord, paymentMethodOptions } = useAddOwnerDistributions();
  const totalDistributedAmount = createMemo(() => {
    const recordsUnderCurrentProperty = store.editingDistributions.filter((record) => record.propertyId === props.record.property?.id);

    return recordsUnderCurrentProperty.reduce((acc, cur) => {
      return acc + cur.distributionAmount;
    }, 0);
  });

  const remainingAmount = createMemo(() => (props.record.availableForDistribution ?? 0) - totalDistributedAmount());

  const onChange = (ownerId: string | undefined, action: { key: keyof EditingDistribution; value: any }) => {
    const propertyId = props.record.property?.id;
    if (!propertyId || !ownerId) {
      return;
    }
    updateOwnerDistributionRecord(propertyId, ownerId, action);
  };

  const getEditingFieldData = (ownerId: string | undefined, key: keyof EditingDistribution): any => {
    if (!ownerId) {
      return undefined;
    }
    const data = store.editingDistributions.find((record) => record.propertyId === props.record.property?.id && record.ownerId === ownerId);

    return data?.[key];
  };

  const getOwnerColumns = (paymentType: string) => {
    const baseColumns = [
      {
        title: t('Owner'),
        headerClass: 'whitespace-nowrap',
        render: (item: MagicDoor.Api.DistributionOwnerDto) => (
          <span class="whitespace-nowrap">{`${item.owner?.firstName} ${item.owner?.lastName || ''}`}</span>
        ),
      },
      {
        title: t('Percentage'),
        headerClass: 'text-right whitespace-nowrap',
        render: (item: MagicDoor.Api.DistributionOwnerDto) => `${((item.ownershipPercentage ?? 0) * 100).toFixed(2)}%`,
      },
      {
        title: t('Payment type'),
        headerClass: 'whitespace-nowrap',
        render: (item: MagicDoor.Api.DistributionOwnerDto) => (
          <DropdownMenu
            value={(getEditingFieldData(item.owner?.id, 'paymentType') as string) || 'printCheck'}
            options={[
              { label: t('Manual'), value: 'manual' },
              { label: t('Print check'), value: 'printCheck' },
            ]}
            onChange={(value) => {
              onChange(item.owner?.id, { key: 'paymentType', value });
              const distributionsForProperty = store.editingDistributions.filter((d) => d.propertyId === props.record.property?.id);
              if (distributionsForProperty.length === 1) {
                distributionsForProperty.forEach((d) =>
                  updateOwnerDistributionRecord(d.propertyId, d.ownerId, { key: 'paymentType', value })
                );
              }
            }}
          />
        ),
      },
    ];

    const manualColumns = [
      {
        title: t('Payment method'),
        headerClass: 'whitespace-nowrap',
        render: (item: MagicDoor.Api.DistributionOwnerDto) => (
          <DropdownMenu
            value={getEditingFieldData(item.owner?.id, 'paymentMethod')}
            options={paymentMethodOptions}
            placeholder={t('Please select')}
            onChange={(value) => onChange(item.owner?.id, { key: 'paymentMethod', value })}
          />
        ),
      },
      {
        title: t('External transaction ID'),
        headerClass: 'whitespace-nowrap',
        render: (item: MagicDoor.Api.DistributionOwnerDto) => (
          <LabeledTextInput
            value={getEditingFieldData(item.owner?.id, 'externalTransactionId')}
            placeholder={t('Enter transaction ID')}
            onInput={(value) => onChange(item.owner?.id, { key: 'externalTransactionId', value })}
          />
        ),
      },
    ];

    const printCheckColumns = [
      {
        title: t('Memo'),
        headerClass: 'whitespace-nowrap',
        render: (item: MagicDoor.Api.DistributionOwnerDto) => (
          <LabeledTextInput
            value={getEditingFieldData(item.owner?.id, 'memo') || getEditingFieldData(item.owner?.id, 'defaultMemo')}
            placeholder={t('Enter memo')}
            onInput={(value) => onChange(item.owner?.id, { key: 'memo', value })}
          />
        ),
      },
    ];

    const commonColumns = [
      {
        title: t('Account'),
        headerClass: 'whitespace-nowrap',
        render: (item: MagicDoor.Api.DistributionOwnerDto) => (
          <DropdownMenu
            value={getEditingFieldData(item.owner?.id, 'bankAccountId')}
            options={bankAccountOptions() ?? []}
            placeholder={t('Please select')}
            onChange={(value) =>
              onChange(item.owner?.id, {
                key: 'bankAccountId',
                value,
              })
            }
          />
        ),
      },
      {
        title: t('Amount'),
        headerClass: 'whitespace-nowrap',
        render: (item: MagicDoor.Api.DistributionOwnerDto) => (
          //TODO: HAO - LabeledNumberInput side effect, each enter de-focus the input
          <LabeledTextInput
            // min={0}
            value={getEditingFieldData(item.owner?.id, 'distributionAmount') as number}
            placeholder={t('Please input amount')}
            onInput={(value) => onChange(item.owner?.id, { key: 'distributionAmount', value: Number(value) })}
          />
        ),
      },
    ];

    return [
      ...baseColumns,
      ...(paymentType === 'manual' ? manualColumns : []),
      ...(paymentType === 'printCheck' ? printCheckColumns : []),
      ...commonColumns,
    ];
  };

  return (
    <div class={cn({ 'rounded-lg border border-input-border': props.isExpanded })}>
      <div
        class={cn(
          'relative flex cursor-pointer items-center rounded-lg border border-input-border bg-input',
          props.isExpanded && '-left-px -top-px w-[calc(100%+2px)]'
        )}
        onClick={() => props.onToggleExpand}>
        <For each={props.columns}>{(column) => <div class={cn('p-4', column.class)}>{column.render(props.record)}</div>}</For>
      </div>
      <Show when={props.isExpanded}>
        <div class="px-6 pb-4 pt-2.5">
          <Section title={t('Owner distributions')} class="mb-0">
            <div class=" thinscroll overflow-auto rounded-lg border border-input-border">
              <Table
                theadClass="static"
                columns={getOwnerColumns(
                  getEditingFieldData(props.record.distributionOwners?.[0]?.owner?.id, 'paymentType') || 'printCheck'
                )}
                data={props.record.distributionOwners}
              />
            </div>
            <div class="flex flex-col items-end pt-2.5 text-right text-xs text-text-level02">
              <div class="mb-1 normal-case">{`${t('To be distributed')}: ${currency(props.record.availableForDistribution ?? 0)}`}</div>
              <div class="border-input-borer mb-2.5 w-[203px] border-b pb-2">
                {`${t('Distributed')}: ${currency(totalDistributedAmount())}`}
              </div>
              <div
                class={cn('text-sm', {
                  'text-danger': remainingAmount() < 0,
                  'text-warning': remainingAmount() > 0,
                  'text-green': remainingAmount() === 0,
                })}>
                {`${t('Remaining')}: ${currency(remainingAmount())}`}
              </div>
            </div>
          </Section>
        </div>
      </Show>
    </div>
  );
};

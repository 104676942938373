import IconTenantPortalAccess from '~/assets/images/settings/portal/portalTenantAccessBanner.png';
import IconTenantAccess from '~/assets/images/settings/portal/protalTenantAccess.svg';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { SettingToggleWithText } from '~/pages/settings/SettingToggleWithText';

export const PortalTenantAccessSettings = () => {
  const { t } = useLocalization();

  return (
    <ThreeSectionBox
      class="h-fit"
      topTitle={<SettingTitle title={t('Tenant portal')} icon={IconTenantAccess} />}
      middle={
        <div class="flex flex-col gap-5 p-4 ">
          <div class="flex w-full flex-col justify-start gap-4 rounded-md bg-[#FFF9E3] text-left ">
            <div
              class="size-full bg-right-top bg-no-repeat bg-origin-content px-4 py-6"
              style={{ 'background-image': `url(${IconTenantPortalAccess})` }}>
              <div class="text-xl	font-semibold "> {t('Access')}</div>
              <SettingToggleWithText
                class="w-fit gap-5"
                textClass="text-base font-medium text-text-level02"
                text={t('Enable tenant portal')}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

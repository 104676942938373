import DisplayAddress from '~/components/common/DisplayAddress';
import IconField from '~/components/common/IconField';
import { IconLink, IconMail, IconMapPin, IconPhone, IconWallet } from '~/components/ui';
import { useLocalization, useVendors } from '~/contexts/global';

const VendorContactInformation = () => {
  const { current } = useVendors();
  const { t } = useLocalization();

  return (
    <div class="flex w-full flex-col items-start px-4 py-2 text-xs text-text-level01">
      <div class="w-full border-b py-3 text-left text-sm font-medium leading-5">{t('Contact information')}</div>
      <div class="flex w-full flex-row flex-wrap gap-y-4 overflow-hidden py-4 xl:flex-col">
        <IconField class="basis-1/2" name={t('Phone')} value={current()?.contact?.phone} src={IconPhone} />
        <IconField class="basis-1/2" name={t('Email')} value={current()?.contact?.email} src={IconMail} />
        <IconField class="basis-1/2" name={t('Website')} value={current()?.website} src={IconLink} />
        <IconField class="basis-1/2" name={t('Expense account')} value="Repairs" src={IconWallet} />
        <IconField
          name={t('Address')}
          value={<DisplayAddress class="text-wrap" address={current()?.contact?.address} />}
          src={IconMapPin}
        />
      </div>
    </div>
  );
};

export default VendorContactInformation;

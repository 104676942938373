import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

const BASE_URL = '/api/management-fees';

export type ManagementFeeListFilter = {
  page?: number;
  pageSize?: number;
  unitIdOr?: string[];
  propertyIdOr?: string[];
  portfolioIdOr?: string[];
  vendorIdOr?: string[];
  'DueDate.Start'?: string;
  'DueDate.End'?: string;
  'Amount.From'?: number;
  'Amount.To'?: number;
};

export class ManagementFeesRepository extends BaseRestRepository {
  public async getManagementFees(filter?: ManagementFeeListFilter): Promise<MagicDoor.Api.HydratedBillDtoPaginationDto> {
    const url = urlWithQuery(BASE_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async calculateManagementFees(payload: MagicDoor.Api.CalculateManagementFeesDto): Promise<MagicDoor.Api.ManagementFeesResultDto> {
    const url = `${BASE_URL}/calculate`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async addManagementFee(payload: MagicDoor.Api.AddManagementFeesDto): Promise<MagicDoor.Api.HydratedBillDto> {
    const url = `${BASE_URL}/add`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }
  public async deleteManagementFee(id: string) {
    const url = `${BASE_URL}/${id}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }

  public async getManagementFee(id: string): Promise<MagicDoor.Api.HydratedBillDto> {
    const url = `${BASE_URL}/${id}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
}

import { createEffect, createSignal, Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import IconPic from '~/assets/images/common/pic.svg?component-solid';
import NoUnitImg from '~/assets/images/empty/noUnitImg.svg';
import IconBathroom from '~/assets/images/property/bathroom.svg';
import IconBedroom from '~/assets/images/property/bedroom.svg';
import IconDateAvaliable from '~/assets/images/property/date.svg';
import IconUnitSize from '~/assets/images/property/unitSize.svg';
import { LinkButton } from '~/components/common/Buttons';
import DisplayAddress from '~/components/common/DisplayAddress';
import { ImagePreview } from '~/components/common/ImagePreview';
import { Panel } from '~/components/common/Panels';
import { getDeposit } from '~/components/leases/utils';
import { propertyTypes } from '~/components/properties/PropertyType';
import { Image } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { UnitMetric } from '~/pages/units/components/UnitMetric';
import { emptyPlaceholder } from '~/utils/constant';
import { getImageUrl } from '~/utils/file';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';
import type { PreviewList } from '~/components/common/ImagePreview/type';

type ListInformationProps = {
  unit?: MagicDoor.Api.HydratedUnitDto;
};

export const ListInformation: Component<ListInformationProps> = (props) => {
  const { t } = useLocalization();
  // const { portfolioId, propertyId, unitId } = useParams();
  // const editUrl = createMemo(() => `/portfolios/${portfolioId}/properties/${propertyId}/units/${unitId}/edit`);

  const [previewVisible, setPreviewVisible] = createSignal<boolean>(false);
  const [previewIndex, setPreviewIndex] = createSignal<number>(0);
  const [previewList, setPreviewList] = createSignal<PreviewList[]>([]);

  const showImagePreview = (index: number) => {
    setPreviewIndex(index);
    setPreviewVisible(true);
  };

  createEffect(() => {
    const list: PreviewList[] = [];
    if (props.unit?.images.length) {
      for (const item of props.unit.images) {
        const url = getImageUrl('unit', props.unit?.id, item.fileId);
        list.push({
          url,
          type: 'img',
        });
      }
    }
    if (props.unit?.property.images.length) {
      for (const item of props.unit.property.images) {
        const url = getImageUrl('property', props.unit?.property.id, item.fileId);
        list.push({
          url,
          type: 'img',
        });
      }
    }
    setPreviewList(list);
  });
  return (
    <>
      <Panel
        title={t('Unit information')}
        actions={
          <LinkButton size="xs" href={'/listing'} variant="outlined" class="border-link py-2 font-medium text-link hover:bg-link/10">
            {t('View listing')}
          </LinkButton>
        }>
        <div class="flex flex-col-reverse gap-6 px-6 py-3 lg:flex-row">
          <div class="relative flex w-full flex-col gap-3 lg:w-80">
            <Show
              when={previewList()[0]}
              fallback={
                <div class="flex size-full  items-center justify-center rounded-lg bg-light-gray lg:size-80">
                  <Image class="w-[205px] object-cover" src={NoUnitImg} />
                </div>
              }>
              <Image
                class="size-full cursor-pointer rounded-lg object-cover lg:size-80"
                src={previewList()[0].url}
                onClick={() => showImagePreview(0)}
              />
            </Show>
            <div class="flex w-full gap-2 lg:w-80">
              <Show when={previewList()[1]}>
                <Image
                  class="h-16 w-1/3 cursor-pointer rounded-lg object-cover"
                  src={previewList()[1].url}
                  onClick={() => showImagePreview(1)}
                />
              </Show>

              <Show when={previewList()[2]}>
                <Image
                  class="h-16 w-1/3 cursor-pointer rounded-lg object-cover"
                  src={previewList()[2].url}
                  onClick={() => showImagePreview(2)}
                />
              </Show>

              <Show when={previewList()[3]}>
                <div class="relative w-1/3 overflow-hidden rounded-lg">
                  <Image class="h-16 w-full cursor-pointer object-cover" src={previewList()[3].url} onClick={() => showImagePreview(3)} />
                  <Show when={previewList().length - 4 > 0}>
                    <div class="absolute inset-0 flex items-center justify-center bg-black/50">
                      <IconPic class="size-4" />
                      <span class="ml-1 text-sm text-white">{previewList().length - 4}</span>
                    </div>
                  </Show>
                </div>
              </Show>
            </div>
          </div>
          <div class="w-full divide-y divide-partingline">
            <div>
              <div class="mb-8">
                <div class="flex items-center gap-2 text-2xl font-semibold text-text-level01">
                  {props.unit?.name}
                  <Show when={props.unit?.property.type && propertyTypes[props.unit?.property.type]}>
                    {(type) => (
                      <div class="flex items-center gap-1 rounded-full bg-[#8712CF] px-3 py-1 text-xs text-white">
                        <Dynamic component={type().icon} class="size-4 text-white" />
                        {t(type().name)}
                      </div>
                    )}
                  </Show>
                </div>
                <DisplayAddress class="text-sm font-medium text-text-level03" address={props.unit?.property.address} />
              </div>
              <Show when={props.unit?.activeLeases?.length}>
                <div class="mb-6 flex gap-14">
                  <div>
                    <div class="text-xs text-text-level03">{t('Rent')}</div>
                    <div class="text-xl font-semibold text-essential-colour">
                      {currency(props.unit?.activeLeases?.length ? props.unit?.activeLeases[0]?.currentRent : emptyPlaceholder)}
                    </div>
                  </div>
                  <div>
                    <div class="text-xs text-text-level03">{t('Security deposit')}</div>
                    <div class="text-xl font-semibold text-text-level01">
                      {getDeposit(
                        props.unit?.activeLeases?.length
                          ? (props.unit?.activeLeases[0] as unknown as MagicDoor.Api.HydratedLeaseDto)
                          : undefined
                      )}
                    </div>
                  </div>
                </div>
              </Show>
            </div>
            <div>
              <div class="flex flex-wrap gap-10 py-6">
                <UnitMetric icon={IconBedroom} title={t('Bedroom')} value={props.unit?.beds ?? '-'} />
                <UnitMetric icon={IconBathroom} title={t('Bathroom')} value={props.unit?.baths ?? '-'} />
                <UnitMetric icon={IconUnitSize} title={t('Unit size')} value={props.unit?.unitSizeSqft ?? '-'} suffix={t('Sqft')} />
                <UnitMetric icon={IconDateAvaliable} title={t('Date avaliable')} value={'-'} />
              </div>
              <div class="my-2 text-base font-semibold text-text-level01">{t('Unit description')}</div>
              <div class="text-xs text-text-level02">
                {t('Built in')} : {emptyPlaceholder}
              </div>
              <div class="mt-1 w-96 text-xs text-text-level03" />
            </div>
          </div>
        </div>
      </Panel>
      <ImagePreview previewList={previewList()} initIndex={previewIndex()} visible={[previewVisible, setPreviewVisible]} />
    </>
  );
};

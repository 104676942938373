import { createContext, useContext } from 'solid-js';
import type { ParentProps } from 'solid-js';

export const contextCreator = <T, U = unknown>(name: string, factory: (props: ParentProps<U>) => T) => {
  const Context = createContext<T>();

  const Provider = (props: ParentProps<U>) => {
    const value = factory(props);
    return Context.Provider({
      value,
      get children() {
        return props.children;
      },
    });
  };

  const use = () => {
    const value = useContext(Context);
    if (value != null) return value;
    throw new Error(`${name} Context must be used within ${name} Provider`);
  };

  return [Provider, use] as const;
};

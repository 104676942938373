import { emptyPlaceholder } from '~/utils/constant';
import { upperFirst } from '~/utils/tool';
type OwnerLike = { firstName?: string | null; lastName?: string | null };

export const getOwnerName = (owner?: MagicDoor.Api.OwnerDto) =>
  owner && owner.lastName ? `${owner.firstName} ${owner.lastName}` : owner?.firstName || emptyPlaceholder;

export function getOwnerFullName<T extends OwnerLike>(owner?: T | null) {
  if (!owner || (!owner.firstName && !owner.lastName)) return '';
  return `${upperFirst(owner.firstName || '')} ${upperFirst(owner.lastName || '')}`.trim();
}

import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { managementFeesSettingsRepository } from '~/repositories/settings/managementFeesSettingsRepository';
import type { Accessor } from 'solid-js';

interface ManagementFeesSettingsContextValue {
  deleteManagementFeesSettingsByLevel: (level: string, levelId: string) => Promise<void>;
  updateManagementFeesSettings: (payload: MagicDoor.Api.ManagementFeeSettingsDto) => Promise<void>;
  updateManagementFeesSettingsByLevel: (level: string, levelId: string, payload: MagicDoor.Api.ManagementFeeSettingsDto) => Promise<void>;
  getManagementFeesSettingsByLevel: (level: string, levelId: string) => Promise<MagicDoor.Api.ManagementFeeSettingsDto | undefined>;
  getAllManagementFeesSettings: () => Promise<MagicDoor.Api.ManagementFeeSettingsDtoSettingsWithOverridesDto | undefined>;
  isLoading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
  managementFeesSettings: Accessor<MagicDoor.Api.ManagementFeeSettingsDto | undefined>;
  allManagementFeesSettings: Accessor<MagicDoor.Api.ManagementFeeSettingsDtoSettingsWithOverridesDto | undefined>;
}

export const [ManagementFeesSettingsProvider, useManagementFeesSettings] = createMagicDoorContext<ManagementFeesSettingsContextValue>(
  'ManagementFeesSettings',
  () => {
    const repo = new managementFeesSettingsRepository();
    const [managementFeesSettings, setManagementFeesSettings] = createSignal<MagicDoor.Api.ManagementFeeSettingsDto | undefined>(undefined);
    const [allManagementFeesSettings, setAllManagementFeesSettings] = createSignal<
      MagicDoor.Api.ManagementFeeSettingsDtoSettingsWithOverridesDto | undefined
    >(undefined);
    const [isLoading, setIsLoading] = createSignal<boolean>(false);
    const [error, setError] = createSignal<Error | undefined>(undefined);

    const updateManagementFeesSettings = async (payload: MagicDoor.Api.ManagementFeeSettingsDto) => {
      setIsLoading(true);

      try {
        await repo.updateManagementFeesSettings(payload);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const updateManagementFeesSettingsByLevel = async (level: string, levelId: string, payload: MagicDoor.Api.ManagementFeeSettingsDto) => {
      setIsLoading(true);
      try {
        await repo.updateManagementFeesSettingsByLevel(level, levelId, payload);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const deleteManagementFeesSettingsByLevel = async (level: string, levelId: string) => {
      setIsLoading(true);
      try {
        await repo.deleteManagementFeesSettingsByLevel(level, levelId);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const getAllManagementFeesSettings = async () => {
      setIsLoading(true);
      try {
        const data = await repo.getAllManagementFeesSettings();
        setAllManagementFeesSettings(data);
        return data;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const getManagementFeesSettingsByLevel = async (level: string, levelId: string) => {
      setIsLoading(true);
      try {
        const data = await repo.getManagementFeesSettingsByLevel(level, levelId);
        setManagementFeesSettings(data);
        return data;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      }
    };

    return {
      deleteManagementFeesSettingsByLevel,
      updateManagementFeesSettings,
      updateManagementFeesSettingsByLevel,
      getManagementFeesSettingsByLevel,
      getAllManagementFeesSettings,
      isLoading,
      error,
      managementFeesSettings,
      allManagementFeesSettings,
    };
  }
);

import { createEffect, createSignal, onCleanup } from 'solid-js';
import ImgSideBar from '~/assets/images/sidebar/sidebarBg.png';
import { useOutsideClick } from '~/utils/hooks';
import MainMenuItems from './MainMenuItems';
import { MenuProvider, useMenu } from './MenuContext';
import SubMenuItems from './SubMenuItems';

const Sidebar = () => {
  const menu = useMenu();

  const [isSidebarOpen, setIsSidebarOpen] = createSignal<boolean>(window.innerWidth > 1023);

  const toggleSidebar = (): void => {
    setIsSidebarOpen((prevState) => !prevState);
  };
  let refMenuContainer!: HTMLDivElement;

  createEffect(() => {
    const handleResize = () => setIsSidebarOpen(window.innerWidth > 1023);
    window.addEventListener('resize', handleResize);
    onCleanup(() => window.removeEventListener('resize', handleResize));
  });

  useOutsideClick(
    () => refMenuContainer,
    () => menu?.isCollapsed() == true,
    () => menu?.setActiveMenuItem(undefined)
  );

  return (
    <>
      <div class="fixed left-4 top-4 z-50 cursor-pointer rounded-lg bg-gray-100 p-2 md:hidden" onClick={toggleSidebar}>
        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 18L20 18" stroke="#000000" stroke-width="2" stroke-linecap="round" />
          <path d="M4 12L20 12" stroke="#000000" stroke-width="2" stroke-linecap="round" />
          <path d="M4 6L20 6" stroke="#000000" stroke-width="2" stroke-linecap="round" />
        </svg>
      </div>
      <div
        class={` fixed left-0 top-0 z-40 flex h-dvh w-64 -translate-x-full flex-col  text-sm transition-transform md:translate-x-0`}
        classList={{ 'translate-x-0 md:w-64 w-screen': isSidebarOpen() }}
        ref={refMenuContainer}
        style={{
          background: 'linear-gradient(177deg, #510FA6 0%, #74139D 100%), linear-gradient(177deg, #7C2EC6 0%, #6222B9 100%), #5B18AC',
        }}>
        <img src="/logo.png" class="sticky top-0 z-20 mx-auto w-44 pb-6 pt-4" alt="logo" />
        <img class="absolute bottom-0 left-0 w-full" src={ImgSideBar} alt="sidebar background" />
        <div class="thinscroll flex overflow-x-hidden" style={{ '--track-color': 'transparent' }}>
          <MainMenuItems setIsSidebarOpen={setIsSidebarOpen} isSidebarOpen={isSidebarOpen()} />
          <SubMenuItems setIsSidebarOpen={setIsSidebarOpen} />
        </div>
      </div>
    </>
  );
};

export default () => {
  return (
    <MenuProvider>
      <Sidebar />
    </MenuProvider>
  );
};

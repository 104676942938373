import { UseCase } from './useCase';
import type { AnnotationTypeChangeRequest } from './types/annotationTypeChangeRequest';

export class SetAnnotationTypesUseCase extends UseCase {
  public async runLogic(request: AnnotationTypeChangeRequest) {
    if (request.type === undefined || !this.isPdfLoaded()) {
      return;
    }
    if (this.state.selectedAnnotationType === request.type) {
      this.state.selectedAnnotation = undefined;
      this.state.selectedAnnotationType = undefined;
      return;
    }
    this.state.selectedAnnotationType = request.type;
  }
}

import { createMemo, For } from 'solid-js';
import { cn } from '~/utils/classnames';
import type { JSX } from 'solid-js';

export const Header = function <T>(props: { items: { title: JSX.Element; key: T }[]; activeKey: string; onChange: (key: T) => void }) {
  const currentIndex = createMemo(() =>
    Math.max(
      props.items.findIndex((item) => item.key === props.activeKey),
      0
    )
  );
  return (
    <div class="bg-[#f8f8f8] px-2">
      <div class="flex h-11 items-center gap-3">
        <For each={props.items}>
          {(tab, index) => (
            <div
              onClick={() => {
                props.onChange(tab.key);
              }}
              class={cn('flex size-8 cursor-pointer items-center justify-center rounded-lg', {
                'bg-[#DFE4EF]': index() === currentIndex(),
              })}>
              <div class="size-6">{tab.title}</div>
            </div>
          )}
        </For>
      </div>
    </div>
  );
};

import { LeaseDepositTransactionDetailsModal } from '~/pages/leases/lease-details/components/lease-deposit-transaction/LeaseDepositTransactionDetailsModal';
import type { Component } from 'solid-js';
import type { ModalProps } from '~/components/modals/Modal';

type LeaseTransactionDetailModalProps = Omit<ModalProps, 'title'> & {
  currentLease: MagicDoor.Api.HydratedLeaseDto;
  transactionDetail: MagicDoor.Api.HydratedLeaseTransactionDto;
  showUpload?: boolean;
  onUpload?: (transaction: MagicDoor.Api.HydratedLeaseTransactionDto) => void;
  originalTransactionId?: string;
  originalOpen?: (refundTransactionId: string | undefined) => void;
};

export const LeaseTransactionDetailModal: Component<LeaseTransactionDetailModalProps> = (props) => {
  return <LeaseDepositTransactionDetailsModal {...props} />;
};

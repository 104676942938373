import { useNavigate } from '@solidjs/router';
import { createEffect, createSignal, on, Show } from 'solid-js';
import IconApprove from '~/assets/images/rental-application/approve.svg?component-solid';
import ConvertToLeaseBg from '~/assets/images/rental-application/convert-to-lease.png';
import IconConvertToLease from '~/assets/images/rental-application/convert-to-lease.svg?component-solid';
import ConvertToTenantBg from '~/assets/images/rental-application/convert-to-tenant.png';
import IconConvertToTenant from '~/assets/images/rental-application/convert-to-tenant.svg?component-solid';
import IconIgnore from '~/assets/images/rental-application/quick-ignore.svg?component-solid';
import IconReject from '~/assets/images/rental-application/quick-reject.svg?component-solid';
import IconRefundPayment from '~/assets/images/rental-application/refund-payment.svg?component-solid';
import { Avatar } from '~/components/common/Avatar';
import { LabeledInputSearch } from '~/components/common/Inputs/LabeledInputSearch';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { toast, confirm, Tabs } from '~/components/ui';
import { UnitSearch } from '~/components/units';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import UnitItem from '~/pages/bills/components/UnitItem';
import { ReceivePaymentModal } from '~/pages/properties/property-details/rental-application-details/edit-components/ReceivePaymentModal';
import { ApplicationDecision } from '~/swagger/Api';
import { cn } from '~/utils/classnames';
import { urlWithQuery } from '~/utils/url';
import type { Component } from 'solid-js';

const Action: Component<{
  icon: Component;
  label: string;
  class: string;
  textClass?: string;
  dividerClass?: string;
  onClick?: () => void;
}> = (props) => {
  return (
    <div
      class={cn('flex h-10 cursor-pointer  select-none items-center rounded-lg', props.class)}
      onClick={() => {
        props.onClick?.();
      }}>
      <div class="flex min-w-10 items-center justify-center">
        <props.icon />
      </div>
      <div class={cn('h-4 min-w-px', props.dividerClass || 'bg-white/20')} />
      <div class={cn('min-w-36 pr-2 text-center text-sm font-medium', props.textClass || 'text-white')}>{props.label}</div>
    </div>
  );
};

const [refundAmount, setRefundAmount] = createSignal<string>('');
const [refundReason, setRefundReason] = createSignal<string>('');
const [isRefundDisabled, setRefundDisabled] = createSignal<boolean>(false);

createEffect(() => {
  setRefundDisabled(refundAmount().length === 0 || refundReason().length === 0 || refundReason().length > 150);
});
export const QuickAction = () => {
  const navigate = useNavigate();
  const { t } = useLocalization();
  const { baseUpdate, applicationStore, tenantConvert, paymentRefund } = useRentalApplication();

  const onUpdateDecision = async (decision: MagicDoor.Api.ApplicationDecision, showToast = true) => {
    await baseUpdate({
      decision,
    });
    showToast && toast.success(t('Operation successful'));
  };

  const formatAmount = (inputValue: string) => {
    inputValue = inputValue || '';
    inputValue = inputValue.replace(/[^\d.]/g, '');
    const parts = inputValue.split('.');

    if (parts.length > 2 || (parts.length === 2 && parts[1].length > 2)) {
      inputValue = parts[0] + '.' + parts[1].slice(0, 2);
    } else if (parts.length === 2 && parts[1].length < 2) {
      inputValue = parts[1].length === 0 ? parts[0] + '.00' : parts[0] + '.' + parts[1] + '0';
    }

    if (!inputValue.includes('.')) {
      inputValue += '.00';
    }
    return inputValue;
  };

  const handleInputRefundAmount = (_: string, event: Event) => {
    const { application } = applicationStore;
    const input = event.target as HTMLInputElement;
    // let inputValue = input.value || '';
    let inputValue = formatAmount(input.value);

    const paymentAmount = application?.paymentAmount || 0;
    if (+inputValue > paymentAmount) {
      inputValue = formatAmount('' + paymentAmount);
      toast.error(t('The refund amount cannot exceed the paid amount.'));
    } else if (+inputValue < 1) {
      inputValue = '1.00';
      toast.error(t('The minimum refund amount is 1.'));
    }
    setRefundAmount(inputValue);
    input.value = inputValue;
  };

  const refundPayment = () => {
    const { application } = applicationStore;

    return confirm({
      title: t('Refund payment'),
      class: 'px-0',
      footerClass: 'px-6',
      titleClass: 'px-5',
      doneText: t('Confirm'),
      content: () => (
        <div class="w-96 border-t">
          <div class="px-6 pt-4">
            <div class="font-size-12 pb-3 text-label">{t('Refund the payment')}</div>
            <div class="mb-2 flex w-full items-center rounded-md bg-input">
              <div class="ml-4">{t('Amount')}</div>
              <div class="ml-auto mr-1 text-primary">$</div>
              <LabeledTextInput
                inputClass="align-item input-no-focus-border w-28 bg-input pl-0 text-primary"
                inputContainerClass="border-none focus-within:ring-0"
                placeholder="Enter amount"
                value={refundAmount()}
                onInput={handleInputRefundAmount}
              />
            </div>
            <LabeledTextInput
              label="Refund reason"
              labelClass="normal-case"
              inputClass="input-no-focus-border bg-input"
              inputContainerClass="border-none focus-within:ring-0"
              placeholder="Please enter refund reason"
              value={refundReason()}
              onInput={(value: string, event: Event) => {
                const input = event.target as HTMLInputElement;
                const inputValue = input.value;
                if (inputValue.length > 150) {
                  input.value = value.slice(0, 150);
                  toast.error(t('Refund reason must be less than 150 characters.'));
                }
                setRefundReason(value.slice(0, 150));
              }}
            />
          </div>
        </div>
      ),
      confirmDisabled: () => isRefundDisabled(),
      async onResolve(confirmed) {
        if (!confirmed) {
          setRefundAmount('');
          setRefundReason('');
          return;
        }
        const applicationId = application?.id;
        if (!applicationId) return;
        try {
          await paymentRefund(applicationId, Number(refundAmount()), refundReason());
          navigate(`/leasing/rental-applications/${applicationId}`);
        } catch (e) {
          toast.error(t('Operation failed, please try again later'));
        }
      },
    });
  };

  const convertToLease = () => {
    const { application } = applicationStore;
    const params: any = { rentalApplicationId: application?.id, startDate: application?.desiredMoveInDate, convertTenant: 1 };
    const preferredUnitOptions: MagicDoor.Api.UnitDto[] = application?.interests?.map((item) => item.unit) || [];
    const [unitType, setUnitType] = createSignal<'preferred' | 'other'>('other');
    const [unitId, setUnitId] = createSignal<string>();
    if (preferredUnitOptions.length) {
      setUnitType('preferred');
    }
    createEffect(
      on(unitType, () => {
        setUnitId('');
      })
    );

    const navigateToNewLease = async () => {
      await onUpdateDecision(ApplicationDecision.Approved, false);
      const link = urlWithQuery(`/leasing/leases/new`, params);
      navigate(link);
    };

    return confirm({
      title: t('Convert to lease'),
      content: () => (
        <>
          <img class="-mx-5 block h-32 max-w-none bg-light-pink object-cover" src={ConvertToLeaseBg} alt="ConvertToLeaseBg" />
          <Tabs defaultSelected={unitType()} onChange={setUnitType}>
            <Tabs.List class="my-3 flex gap-4">
              <Tabs.Trigger
                class="flex-1 overflow-hidden rounded-lg border px-3 py-2 text-center transition-colors aria-selected:border-primary aria-selected:bg-primary/10 aria-selected:font-medium aria-selected:text-primary"
                value="preferred">
                {t('Preferred unit')}
                <p class="mt-1 truncate text-xs font-normal text-text-level03">{t("Select the applicant's preferred unit")}</p>
              </Tabs.Trigger>
              <Tabs.Trigger
                class="flex-1 overflow-hidden rounded-lg border px-3 py-2 text-center transition-colors aria-selected:border-primary aria-selected:bg-primary/10 aria-selected:font-medium aria-selected:text-primary"
                value="other">
                {t('Other unit')}
                <p class="mt-1 truncate text-xs font-normal text-text-level03">{t('Select other unit')}</p>
              </Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content value="preferred">
              <Show
                when={preferredUnitOptions.length}
                fallback={
                  <div class="rounded-lg border border-input-border bg-light-gray p-2 px-3 text-text-level03">
                    {t('The applicant has not added units, Please select other unit.')}
                  </div>
                }>
                <LabeledInputSearch
                  value={unitId()}
                  options={preferredUnitOptions}
                  renderItem={(item) => <UnitItem unit={item} />}
                  placeholder={t('Select unit')}
                  onSelect={(_, item) => {
                    params.portfolioId = item?.portfolioId;
                    params.propertyId = item?.propertyId;
                    params.unitId = item?.id;
                    setUnitId(item?.id);
                  }}
                  valuePropName="id"
                />
              </Show>
            </Tabs.Content>
            <Tabs.Content value="other">
              <UnitSearch
                onSelect={(item) => {
                  params.portfolioId = item?.portfolioId;
                  params.propertyId = item?.propertyId;
                  params.unitId = item?.id;
                  setUnitId(item?.id);
                }}
                placeholder={t('Select unit')}
              />
            </Tabs.Content>
          </Tabs>
        </>
      ),
      async onResolve(confirmed) {
        if (!confirmed) return;
        if (!unitId()) return toast.error(t('Please select unit'));
        await navigateToNewLease();
      },
      confirmDisabled: () => !unitId(),
    });
  };

  const convertToTenant = () => {
    const { application } = applicationStore;
    const name = `${application?.firstName} ${application?.lastName}`;

    return confirm({
      title: t('Convert to tenant'),
      class: 'px-0',
      footerClass: 'px-5',
      titleClass: 'px-5',
      doneText: t('Confirm'),
      content: (
        <div class="w-96">
          <div class="w-full bg-light-pink">
            <img src={ConvertToTenantBg} class="h-24 w-full" alt="ConvertToLeaseBg" />
          </div>
          <div class="p-5">
            <div class="mb-2 flex h-14 w-full items-center gap-2 rounded-md bg-input px-3">
              <Avatar name={name} />
              <span class="text-sm font-medium text-text-level01">{name}</span>
            </div>
            {t("Do you want to convert the applicant '{name}' into a tenant?", {
              name: `${name}`,
            })}
          </div>
        </div>
      ),
      async onResolve(confirmed) {
        if (!confirmed) return;
        const applicationId = application?.id;
        if (!applicationId) return;
        const tenant = await tenantConvert(applicationId);
        navigate(`/users/tenants/${tenant.id}`);
      },
    });
  };

  return (
    <div class="h-fit grow divide-y rounded-lg border border-partingline capitalize sm:mt-6 md:w-full lg:w-auto">
      <div class="flex h-11 items-center p-3 text-sm font-medium capitalize text-text-level01">{t('Quick action')}</div>

      <div class="flex flex-wrap items-center gap-4 p-5">
        <Show when={['noDecision', 'ignored'].includes(applicationStore.application?.applicationDecision as string)}>
          <Action
            class="bg-[#D9F0EB]"
            textClass="text-light-green"
            dividerClass="bg-light-green/20"
            label={t('Approve')}
            icon={IconApprove}
            onClick={() => {
              onUpdateDecision(ApplicationDecision.Approved);
            }}
          />
          <Action
            class="bg-[#DFE8FF]"
            textClass="text-[#3651BE]"
            dividerClass="bg-[#3651BE20]"
            label={t('Ignore')}
            icon={IconIgnore}
            onClick={() => {
              onUpdateDecision(ApplicationDecision.Ignored);
            }}
          />
          <Action
            class="bg-[#F5DBE1]"
            textClass="text-danger"
            dividerClass="bg-danger/20"
            label={t('Reject')}
            icon={IconReject}
            onClick={() => {
              onUpdateDecision(ApplicationDecision.Rejected);
            }}
          />
        </Show>

        <Show when={applicationStore.application?.applicationDecision === 'approved'}>
          <Show when={!applicationStore.application?.tenant}>
            <Action class="bg-[#5C63F4]" label={t('Convert to tenant')} icon={IconConvertToTenant} onClick={() => convertToTenant()} />
          </Show>
          <Action class="bg-primary" label={t('Convert to lease')} icon={IconConvertToLease} onClick={() => convertToLease()} />
        </Show>
        <Show
          when={applicationStore.application?.paymentStatus === 'paid' && applicationStore.application?.applicationDecision === 'rejected'}>
          <Action class="bg-danger" label={t('Refund payment')} icon={IconRefundPayment} onClick={() => refundPayment()} />
        </Show>
        {/* TODO restore when ReceivePaymentModal is implemented */}
        {/* <Show
          when={
            applicationStore.application?.paymentStatus === 'unpaid' && applicationStore.application?.applicationDecision !== 'rejected'
          }>
          <Action
            class="bg-light-green "
            label={t('Receive payments')}
            icon={IconPayment}
            onClick={() => {
              setApplicationStore('currentUpdateType', currentUpdateType.receivePayment);
            }}
          />
        </Show> */}
      </div>

      <ReceivePaymentModal />
    </div>
  );
};

import { useNavigate } from '@solidjs/router';
import { loadStripe } from '@stripe/stripe-js';
import { createStore, produce } from 'solid-js/store';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { RentalApplicationRepository } from '~/repositories/rentalApplicationRepository';
import { ScreeningStatus } from '~/swagger/Api';
import { rentalApplicationPaymentStatus } from '~/utils/constant';
import { getStripeKey } from '~/utils/stripeKey';
import type { Stripe, StripeElements } from '@stripe/stripe-js';

const applicationRepo = new RentalApplicationRepository();

export const [RentalApplicationPaymentProvider, useRentalApplicationPayment] = createMagicDoorContext('RentalApplicationPayment', () => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const [store, setStore] = createStore<{
    intent: Partial<MagicDoor.Api.RentalApplicationPaymentIntentDto>;
    application: Partial<MagicDoor.Api.RentalApplicationDto>;
    stripe: Stripe | null;
    stripLoaded: boolean;
    isSubmitting: boolean;
    isScreening: boolean;
    isCheckingScreeningStatus: boolean;
    isLoadingQuestions: boolean;
    isAnsweringQuestions: boolean;
    paymentStatus: Partial<MagicDoor.Api.RentalApplicationPaymentStatusDto>;
    transUnionScreeningStatus: Partial<MagicDoor.Api.CheckScreeningStatusResultDto>;
    transUnionScreeningQuestions: Partial<MagicDoor.Api.TransunionExamQuestionsDto>;
    selectedAnswers: Record<string, string>;
    skipPaymentLoading: boolean;
  }>({
    intent: {},
    application: {},
    stripe: null,
    stripLoaded: false,
    isSubmitting: false,
    paymentStatus: {},
    isScreening: false,
    transUnionScreeningStatus: {},
    transUnionScreeningQuestions: {},
    selectedAnswers: {},
    isCheckingScreeningStatus: false,
    isLoadingQuestions: false,
    isAnsweringQuestions: false,
    skipPaymentLoading: false,
  });

  const gotoStatusPage = (status: string) => {
    navigate(`/leasing/rental-applications/new/${store.application.id}/status/${status}`, { resolve: false });
  };

  async function getApplicationIntent() {
    const applicationId = store.application.id;
    if (!applicationId) return;

    if (!store.paymentStatus.paid) {
      const res = await applicationRepo.getRentalApplicationPayment(applicationId);
      if (res) {
        setStore(
          produce((draft) => {
            draft.intent = res;
          })
        );
      } else {
        navigate('/leasing/rental-applications', { replace: true });
      }
      return;
    }
  }

  const getPaymentStatus = async (applicationId = store.application.id) => {
    if (!applicationId) return;

    const status = await applicationRepo.getRentalApplicationPaymentStatus(applicationId);

    setStore(
      produce((draft) => {
        draft.paymentStatus = status;
      })
    );
  };

  const _loadStripe = async () => {
    const stripKey = getStripeKey();
    if (!stripKey || !store.intent.stripeAccountId) return;
    const stripeInstance = await loadStripe(stripKey, {
      stripeAccount: store.intent.stripeAccountId,
    });
    setStore(
      produce((draft) => {
        draft.stripe = stripeInstance;
        draft.stripLoaded = true;
      })
    );
  };

  const setRentalApplicationId = async (applicationId: string) => {
    if (!applicationId) return;
    setStore(
      produce((draft) => {
        draft.application.id = applicationId;
      })
    );
  };

  const initPaymentByApplicationId = async (applicationId: string) => {
    await setRentalApplicationId(applicationId);
    await getPaymentStatus();
    await getApplicationIntent();
    await _loadStripe();

    if (store.paymentStatus.paid) {
      gotoStatusPage(`processing`);
    }
  };

  const handleSubmit = async (elements: StripeElements | null) => {
    if (!elements || !store.stripe) return;
    try {
      setStore('isSubmitting', true);
      await store.stripe.confirmPayment({
        elements: elements,
        confirmParams: {
          return_url: `${window.location.origin}/leasing/rental-applications/new/${store.application.id}/status/${rentalApplicationPaymentStatus.processing}`,
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      setStore('isSubmitting', false);
    }
  };

  const handleScreening = async () => {
    if (!store.application.id) return;
    setStore('isScreening', true);
    try {
      await applicationRepo.startScreening(store.application.id);
      gotoStatusPage(rentalApplicationPaymentStatus.questionnaire);
    } finally {
      setStore('isScreening', false);
    }
  };

  const getScreeningStatus = async () => {
    if (!store.application.id) return;
    try {
      setStore('isCheckingScreeningStatus', true);
      const status = await applicationRepo.getScreeningStatus(store.application.id);
      setStore(produce((draft) => (draft.transUnionScreeningStatus = status)));
    } finally {
      setStore('isCheckingScreeningStatus', false);
    }
  };

  const getScreeningQuestions = async () => {
    if (!store.application.id) return;
    try {
      setStore('isLoadingQuestions', true);
      const questions = await applicationRepo.getScreeningQuestions(store.application.id);
      setStore(produce((draft) => (draft.transUnionScreeningQuestions = questions)));
    } finally {
      setStore('isLoadingQuestions', false);
    }
  };

  const checkScreeningStatus = async () => {
    await getScreeningStatus();

    switch (store.transUnionScreeningStatus.status) {
      case ScreeningStatus.Verified:
        gotoStatusPage(rentalApplicationPaymentStatus.submitted);
        break;

      case ScreeningStatus.ManualVerificationRequired:
        gotoStatusPage(rentalApplicationPaymentStatus.confirmIdentity);
        break;

      default:
        getScreeningQuestions();
        break;
    }
  };

  const handleSubmitAnswers = async () => {
    if (!store.application.id) return;

    try {
      setStore('isAnsweringQuestions', true);
      await applicationRepo.postScreeningAnswers(store.application.id, {
        examId: store.transUnionScreeningQuestions?.examId ?? 0,
        answers: Object.entries(store.selectedAnswers).map(([questionKeyName, selectedChoiceKeyName]) => ({
          questionKeyName,
          selectedChoiceKeyName,
        })),
      });
      checkScreeningStatus();
    } catch (error) {
      toast(t('Failed to submit answers'), 'error');
    } finally {
      setStore('isAnsweringQuestions', false);
    }
  };

  const updateAnswer = (questionKey: string, choiceKey?: string) => {
    if (questionKey && choiceKey) {
      setStore(
        produce((draft) => {
          draft.selectedAnswers[questionKey] = choiceKey;
        })
      );
    }
  };

  async function skipPayment() {
    if (!store.application.id) return;

    setStore({
      skipPaymentLoading: true,
    });
    try {
      await applicationRepo.skipPayment(store.application.id);
      navigate('/leasing/rental-applications', { replace: true });
    } finally {
      setStore({
        skipPaymentLoading: false,
      });
    }
  }

  return {
    store,
    setStore,
    initPaymentByApplicationId: initPaymentByApplicationId,
    handleSubmit,
    getPaymentStatus: getPaymentStatus,
    handleScreening: handleScreening,
    handleSubmitAnswers: handleSubmitAnswers,
    updateAnswer: updateAnswer,
    getScreeningQuestions: getScreeningQuestions,
    checkScreeningStatus: checkScreeningStatus,
    setRentalApplicationId: setRentalApplicationId,
    getScreeningStatus: getScreeningStatus,
    skipPayment: skipPayment,
  };
});

import IconTenantRequest from '~/assets/images/settings/portal/portalTenantRequest.svg';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { SettingToggleWithText } from '~/pages/settings/SettingToggleWithText';

export const PortalTenantRequestsSettings = () => {
  const { t } = useLocalization();

  return (
    <ThreeSectionBox
      class="h-fit"
      topTitle={<SettingTitle title={t('Tenant requests')} icon={IconTenantRequest} />}
      middle={
        <div class="flex flex-col gap-5 p-4">
          <div class="flex flex-col gap-4 p-2 text-left">
            <SettingToggleWithText text={t('Enable tenants to submit online maintenance requests')} />
            <SettingToggleWithText text={t('Allow tenants to view open maintenance requests')} />
            <SettingToggleWithText text={t('Allow tenants to view closed maintenance requests')} />
            <div class="flex flex-col gap-4 pt-3">
              <div>
                <div class="text-sm text-text-level02">{t('Tenant maintenance Request Instructions')}</div>
                <div class="text-xs text-text-level03">
                  {t(
                    'You can add additional instructions for your tenants that will be displayed when new requests are created from the tenant portal'
                  )}
                </div>
              </div>
              <textarea
                class="border- w-full rounded-md border bg-inputbox-bg px-3 py-2 text-sm text-black placeholder:text-auxiliary-text focus:outline-none focus:ring-1 focus:ring-essential-colour "
                rows={6}
                placeholder={t('Type instructions here')}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { RentalApplicationPaymentSettingRepository } from '~/repositories/settings/rentalApplicationPaymentAccountRepository';
import type { Accessor } from 'solid-js';

interface RentalApplicationPaymentAccountContextValue {
  isLoading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
  updateRentalApplicationPaymentAccount: (
    payload: MagicDoor.Api.RentalApplicationPaymentAccountSettingsDto
  ) => Promise<MagicDoor.Api.RentalApplicationPaymentAccountSettingsDto | undefined>;
  getRentalApplicationPaymentAccount: () => Promise<MagicDoor.Api.RentalApplicationPaymentAccountSettingsDto | undefined>;
  deleteRentalApplicationPaymentAccount: () => Promise<void>;
  rentalApplicationPaymentAccount: Accessor<MagicDoor.Api.RentalApplicationPaymentAccountSettingsDto | undefined>;
}

export const [RentalApplicationPaymentAccountProvider, useRentalApplicationPaymentAccount] =
  createMagicDoorContext<RentalApplicationPaymentAccountContextValue>('RentalApplicationPaymentAccount', () => {
    const repo = new RentalApplicationPaymentSettingRepository();
    const [rentalApplicationPaymentAccount, setRentalApplicationPaymentAccount] = createSignal<
      MagicDoor.Api.RentalApplicationPaymentAccountSettingsDto | undefined
    >(undefined);
    const [isLoading, setIsLoading] = createSignal<boolean>(false);
    const [error, setError] = createSignal<Error | undefined>(undefined);

    const getRentalApplicationPaymentAccount = async (): Promise<MagicDoor.Api.RentalApplicationPaymentAccountSettingsDto | undefined> => {
      setIsLoading(true);

      try {
        const result = await repo.getRentalApplicationPaymentAccountSettings();
        setRentalApplicationPaymentAccount(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    const updateRentalApplicationPaymentAccount = async (
      payload: MagicDoor.Api.RentalApplicationPaymentAccountSettingsDto
    ): Promise<MagicDoor.Api.RentalApplicationPaymentAccountSettingsDto | undefined> => {
      setIsLoading(true);
      setError(undefined);
      try {
        const result = await repo.updateRentalApplicationAccountPaymentSettings(payload);
        setRentalApplicationPaymentAccount(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    const deleteRentalApplicationPaymentAccount = async (): Promise<void> => {
      setIsLoading(true);

      try {
        await repo.deleteRentalApplicationPaymentAccountSettings();
        setRentalApplicationPaymentAccount(undefined);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    return {
      isLoading,
      error,
      updateRentalApplicationPaymentAccount,
      getRentalApplicationPaymentAccount,
      deleteRentalApplicationPaymentAccount,
      rentalApplicationPaymentAccount,
    };
  });

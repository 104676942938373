import { createEffect, createMemo, createSignal } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import LabeledAddressInput from '~/components/common/Inputs/form-inputs/LabeledAddressInput';
import CheckboxToExpand from '~/components/common/expandables/CheckboxToExpand';
import { useLocalization } from '~/contexts/global';
import { isEmptyData } from '~/utils/tool';
import type { Component } from 'solid-js';
import type { LabeledGroupProps } from '~/components/common/Inputs';

interface LabeledPaymentInputProps extends LabeledGroupProps {
  value: MagicDoor.VendorPaymentModel;
  onInput: (value: MagicDoor.VendorPaymentModel) => void;
  hasError?: boolean;
  onClick?: (isMouseDown: boolean) => void;
  isSubmit?: boolean;
  fieldName: string;
}

const LabeledPaymentInput: Component<LabeledPaymentInputProps> = (props) => {
  const [nameCheckboxChecked, setNameCheckboxChecked] = createSignal<boolean>(false);
  const [addressCheckboxChecked, setAddressCheckboxChecked] = createSignal<boolean>(false);

  const { t } = useLocalization();
  const taxIdTypes = [
    { label: 'SSN', value: 'ssn' },
    { label: 'EIN', value: 'ein' },
  ];

  const handleChange = (name: string, value: string | undefined | number) => {
    const updatedValue = { ...props.value, [name]: value };
    props.onInput(updatedValue);
  };

  const handleNameCheckboxChange = (checked: boolean) => {
    setNameCheckboxChecked(checked);
    if (!checked) {
      handleChange('overwriteName', '');
    }
  };

  const handleAddressCheckboxChange = (checked: boolean) => {
    setAddressCheckboxChecked(checked);
    if (!checked) {
      handleChange('overwriteAddress', '');
    }
  };

  createEffect(() => {
    const addressChecked = !!props.value?.overwriteAddress;
    const nameChecked = !!props.value?.overwriteName;

    if (addressChecked === addressCheckboxChecked()) {
      setAddressCheckboxChecked(addressChecked);
    }
    if (nameChecked === nameCheckboxChecked()) {
      setNameCheckboxChecked(nameChecked);
    }
  });

  createEffect(() => {
    setNameCheckboxChecked(!!props.value?.overwriteName);
    setAddressCheckboxChecked(!isEmptyData(props.value?.overwriteAddress));
  });

  const form = useFormContext();
  const shouldThisFieldBeRequired = createMemo(() => {
    return !isEmptyData(form.formStore[props.fieldName]);
  });

  return (
    <div class="flex flex-col gap-5 py-6">
      <div class="flex gap-5 pb-4">
        <div class="w-1/2 ">
          <FormItem
            label={t('Tax identity type')}
            options={taxIdTypes}
            onClick={props.onClick}
            placeholder={t('Select tax identity type')}
            onChangeMethodName="onChange"
            formFieldName={[props.fieldName, 'taxIdType']}
            component={DropdownMenu}
            rules={[{ required: shouldThisFieldBeRequired(), message: t('Please select a tax identity type') }]}
          />
        </div>
        <div class="w-1/2">
          <FormItem
            label={t('Tax id')}
            placeholder={t('Enter taxId')}
            onClick={props.onClick}
            component={LabeledTextInput}
            rules={[
              { required: shouldThisFieldBeRequired(), message: t('Please enter a tax id') },
              { length: 200, message: t('{name} must be {length} characters or less', { name: t('Tax id'), length: '200' }) },
            ]}
            formFieldName={[props.fieldName, 'taxId']}
          />
        </div>
      </div>

      {/* TODO COMMENT THIS BACK IN WHEN READY */}
      {/* <div class="flex gap-8">
        <div class="w-1/2">
          <LabeledTextInput
            label="Expense account number"
            placeholder="Enter account number"
            value={props.value.expenseAccount}
            onInput={(value: string | undefined) => handleChange('expenseAccount', value)}
            onClick={props.onClick}
          />
        </div>

        <div class="w-1/2">
          <LabeledTextInput
            label="Payment method"
            placeholder="Enter routing number"
            value={props.value.paymentMethod}
            onInput={(value: string | undefined) => handleChange('paymentMethod', value)}
            onClick={props.onClick}
          />
        </div>
      </div> */}

      <CheckboxToExpand
        label={t('Use a different name')}
        onChange={handleNameCheckboxChange}
        checked={nameCheckboxChecked()}
        content={
          <div class="flex w-input flex-col gap-y-5 py-4">
            <LabeledTextInput
              label={t('Name')}
              placeholder={t('Enter new name')}
              value={props.value?.overwriteName}
              onInput={(value: string | undefined) => handleChange('overwriteName', value)}
              onClick={props.onClick}
            />
          </div>
        }
      />
      <CheckboxToExpand
        label={t('Use a different address')}
        onChange={handleAddressCheckboxChange}
        checked={addressCheckboxChecked()}
        content={
          <div class="flex flex-col gap-y-5 py-6">
            <FormItem onClick={props.onClick} component={LabeledAddressInput} formFieldName={[props.fieldName, 'overwriteAddress']} />
          </div>
        }
      />
    </div>
  );
};
export default LabeledPaymentInput;

import { ValidationError } from '~/errors';
import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

const VENDOR_URL = '/api/vendors';

export function validateVendor(vendor: MagicDoor.Api.HydratedVendorDto | MagicDoor.CreateOrUpdateVendor): void {
  if (!vendor) throw new ValidationError('Vendor object must be provided');
  if (!vendor.name) throw new ValidationError('Vendor name must be provided');

  // You can add more validations based on your business rules.
}

export function validateId(id: string, entityName = 'Entity'): void {
  if (!id) throw new ValidationError(`${entityName} ID must be provided`);
}

export type VendorFilter = MagicDoor.Filter<{
  categoryId?: string;
  phoneNumber?: string;
}>;

export class VendorRepository extends BaseRestRepository {
  public async getVendors(filter?: VendorFilter): Promise<MagicDoor.Api.HydratedVendorDtoPaginationDto> {
    const url = urlWithQuery(VENDOR_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getVendor(vendorId: string): Promise<MagicDoor.Api.HydratedVendorDto> {
    validateId(vendorId);
    const url = `${VENDOR_URL}/${vendorId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async createVendor(vendor: MagicDoor.CreateOrUpdateVendor): Promise<MagicDoor.Api.HydratedVendorDto> {
    validateVendor(vendor);
    const url = VENDOR_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(vendor),
    });
    return this.getJsonResponse(response);
  }

  public async updateVendor(vendorId: string, vendor: MagicDoor.CreateOrUpdateVendor): Promise<MagicDoor.Api.HydratedVendorDto> {
    validateId(vendorId);
    validateVendor(vendor);
    const url = `${VENDOR_URL}/${vendorId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(vendor),
    });
    return this.getJsonResponse(response);
  }

  public async deleteVendor(vendorId: string): Promise<void> {
    validateId(vendorId);
    if (!vendorId) throw new ValidationError('MagicDoor.Api.HydratedVendorDto ID must be provided');
    const url = `${VENDOR_URL}/${vendorId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    if (!response.ok) {
      console.error(await response.json());
    }
  }

  public async getAllVendors(): Promise<MagicDoor.Api.VendorDto[]> {
    const url = `${VENDOR_URL}/all`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getVendorOverview(vendorId: string, useUpdatedAt?: boolean): Promise<MagicDoor.Api.VendorOverviewDto> {
    let url = `${VENDOR_URL}/${vendorId}/overview`;
    if (useUpdatedAt) {
      url += `?updatedAt=` + new Date().getTime();
    }
    const response = await this.fetchWithAuth(url, { method: 'GET' });
    return this.getJsonResponse(response);
  }
}

import { UseCase } from './useCase';
import type { AnnotatedPdfPage } from './types/annotatedPdfPage';
import type { DeleteRequest } from './types/deleteRequest';

export class DeleteAnnotationUseCase extends UseCase {
  public async runLogic(request: DeleteRequest) {
    if (request.id === this.state.selectedAnnotation?.id && this.state.isEditing) {
      return;
    }
    const pdfPages: AnnotatedPdfPage[] = [...this.state.pdfs.pdfPages];
    for (const page of pdfPages) {
      if (page.annotations.some((annotation) => annotation.id === request.id)) {
        page.annotations = page.annotations.filter((annotation) => annotation.id !== request.id);
        break;
      }
    }
    if (request.id === this.state.selectedAnnotation?.id) {
      this.state.selectedAnnotation = undefined;
    }
  }
}

import type { Icon } from '~/components/ui';

export const IconLeasesOverviewHeader: Icon = (props) => (
  <svg viewBox="0 0 257 108" fill="white" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.08">
      <path d="M166.275 18.9037H199.336V106.416H166.275V18.9037Z" />
      <path d="M193.96 34.6558H220.102V108H193.96V34.6558Z" />
      <path d="M233.932 58.2854H245.465V107.459H233.932V58.2854Z" />
      <path d="M245.467 26.7798H257V107.625H245.467V26.7798Z" />
      <path d="M71.9979 0H103.497V107.119H71.9979V0Z" />
      <path d="M48.3735 43.6241L72.2083 26.955V107.119L48.3735 106.967V43.6241Z" />
      <path d="M0 37.9425L21.1437 15.856L42.2874 37.9425V107.119H0V37.9425Z" />
    </g>
    <g opacity="0.2">
      <path d="M166.223 63.4921L195.402 82.822C195.962 83.1925 196.298 83.8186 196.298 84.4894V107.393H163.118V65.1595C163.118 63.5647 164.894 62.6114 166.223 63.4921Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M150.266 17.503L94.9856 38.0251C92.6341 38.8981 91.0737 41.1417 91.0737 43.65V106.915H158.354V23.1279C158.354 18.9471 154.185 16.048 150.266 17.503ZM111.345 49.2362C111.345 47.0291 113.134 45.2399 115.342 45.2399H140.528C142.735 45.2399 144.525 47.0291 144.525 49.2362C144.525 51.4433 142.735 53.2325 140.528 53.2325H115.342C113.134 53.2325 111.345 51.4433 111.345 49.2362ZM115.342 65.2247C113.134 65.2247 111.345 67.0139 111.345 69.221C111.345 71.4281 113.134 73.2173 115.342 73.2173H140.528C142.735 73.2173 144.525 71.4281 144.525 69.221C144.525 67.0139 142.735 65.2247 140.528 65.2247H115.342Z"
      />
    </g>
  </svg>
);

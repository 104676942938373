import { Show } from 'solid-js';
import IconTriangleAlert from '~/assets/images/leases/prorate-charges.png';
import { Button } from '~/components/common/Buttons';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { useLocalization } from '~/contexts/global';
import { useProrateCharge } from '~/contexts/local';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';
import type { CreateLeaseModel } from '~/pages/leases/new-lease/components/NewLease/types';

export const Alert: Component<{ class?: string; onClick?: () => void; content: string; noAction?: boolean }> = (props) => {
  const { t } = useLocalization();
  return (
    <div class={cn('flex items-center rounded-2xl bg-[#faf1ff] p-4', props.class)}>
      <div class="flex items-center gap-4 text-text-level02">
        <img src={IconTriangleAlert} class="size-16" alt="Alert" />
        <div class="text-base normal-case text-text-level02">{props.content}</div>
      </div>
      <Show when={!props.noAction}>
        <Button variant="outlined" onClick={props.onClick} class="shrink-0">
          {t(`Prorate Charges`)}
        </Button>
      </Show>
    </div>
  );
};

export const ProrateChargeForm = (props: {
  proratedAmount: number | undefined | null;
  onUpdate: (model: Partial<CreateLeaseModel>) => void;
}) => {
  const { t } = useLocalization();

  const { onUserCheckedChange, store, shouldHideProratedAmountAlert } = useProrateCharge();

  return (
    <div class="py-6">
      <div class="flex items-start gap-3">
        <Checkbox checked={store.userChecked} onInput={onUserCheckedChange} />
        <h2 class="text-sm font-medium text-text-level01">{t('Prorate Charges')}</h2>
      </div>
      <Show when={store.userChecked}>
        <Show when={!shouldHideProratedAmountAlert()}>
          <Alert class="mt-6" noAction content={t(`Prorated amount will be automatically calculated after the rent amount is entered.`)} />
        </Show>
        <LabeledNumberInput
          class="col-span-2 my-6 lg:col-span-1"
          prepend="$"
          label={t('Prorated amount')}
          required
          value={props.proratedAmount ?? ''}
          onInput={(value) => props.onUpdate({ proratedAmount: Number(value) })}
          placeholder={t('Please enter')}
        />
      </Show>
    </div>
  );
};

import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

const LEASES_URL = '/api/leases';

export interface SuggestedEndDateResponse {
  suggestedEndDate: string;
}
export type LeaseListFilter = MagicDoor.Filter<{
  unitId?: string;
  monthToMonth?: boolean;
  eviction?: boolean;
  withBalance?: boolean;
  tenantIds?: string[];
  propertyId?: string;
  portfolioId?: string;
  ended?: boolean;
  draft?: boolean;
  balanceLessThan?: number;
  balanceMoreThan?: number;
  'LeaseStart.Start'?: string;
  'LeaseStart.End'?: string;
  'LeaseEnd.Start'?: string;
  'LeaseEnd.End'?: string;
}>;

export type ExpiringLeaseListFilter = MagicDoor.Filter<{
  unitId?: string;
  portfolioIds?: string[];
  propertyIds?: string[];
  unitIds?: string[];
}>;

export class LeaseRepository extends BaseRestRepository {
  public async addLease(payload: MagicDoor.Api.CreateLeaseDto): Promise<MagicDoor.Api.LeaseDto> {
    const url = LEASES_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getLeases(filter?: LeaseListFilter): Promise<MagicDoor.Api.HydratedLeaseDtoPaginationDto> {
    const url = urlWithQuery(LEASES_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getLease(unitId: string): Promise<MagicDoor.Api.HydratedLeaseDto> {
    const url = `${LEASES_URL}/${unitId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async bindTenantToLease(leaseId: string, tenantId: string, data: MagicDoor.Api.MoveInDto): Promise<boolean> {
    const url = `${LEASES_URL}/${leaseId}/tenants/${tenantId}`;
    const response = await this.fetchWithAuth(url, { method: 'POST', body: JSON.stringify(data) });
    return response.ok;
  }

  public async editLease(leaseId: string, payload: MagicDoor.Api.UpdateLeaseDto): Promise<MagicDoor.Api.LeaseDto> {
    const url = `${LEASES_URL}/${leaseId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async deleteLease(leaseId: string) {
    const url = `${LEASES_URL}/${leaseId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }

  public async eviction(leaseId: string, payload: MagicDoor.Api.UpdateLeaseEvictionStatusDto) {
    const url = `${LEASES_URL}/${leaseId}/eviction`;
    await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  }

  public async suggestedLeaseEndDate(startDate: string): Promise<SuggestedEndDateResponse> {
    const url = `${LEASES_URL}/suggested-end-date?startDate=${startDate}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getLeaseOverlapping(unitId: string, startTime: string, endTime?: string): Promise<MagicDoor.Api.HydratedLeaseDto[]> {
    const url = urlWithQuery(`${LEASES_URL}/overlapping`, { unitId, startTime, endTime });

    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
  public async endLease(leaseId: string) {
    const url = `${LEASES_URL}/${leaseId}/end`;
    await this.fetchWithAuth(url, { method: 'POST' });
  }
  public async reactivateLease(leaseId: string) {
    const url = `${LEASES_URL}/${leaseId}/reactivate`;
    await this.fetchWithAuth(url, { method: 'POST' });
  }

  public async sendNotifyBalanceDue(leaseId: string) {
    const url = `${LEASES_URL}/${leaseId}/notify-balance-due`;
    const response = await this.fetchWithAuth(url, { method: 'POST' });
    return this.getJsonResponse(response);
  }

  public async getExpiringLeases(filter?: ExpiringLeaseListFilter): Promise<MagicDoor.Api.HydratedLeaseDtoPaginationDto> {
    const url = urlWithQuery(`${LEASES_URL}/expiring`, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getProratedAmount(opts: { leaseStartDate?: string; rentAmount: number; firstChargeDate: string }): Promise<{
    proratedAmount: number;
  }> {
    const url = urlWithQuery(`${LEASES_URL}/prorated-amount`, opts);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async activateDraft(leaseId: string): Promise<void> {
    const url = `${LEASES_URL}/${leaseId}/activate-draft`;
    await this.fetchWithAuth(url, { method: 'POST' });
  }

  public async getNextChargeDate(leaseStartDate: string): Promise<{ date: string }> {
    const url = urlWithQuery(`${LEASES_URL}/next-charge-date`, { leaseStartDate });
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
}

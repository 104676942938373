import { createMemo } from 'solid-js';
import IconBalanceBg from '~/assets/images/units/balancesBg.svg?component-solid';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';

type UnitBalancesProps = {
  class?: string;
  balance?: number;
};

export const UnitBalances: Component<UnitBalancesProps> = (props) => {
  const { t } = useLocalization();

  const isDangerColor = createMemo(() => !!props.balance && props.balance > 0);
  const isSuccessColor = createMemo(() => !!props.balance && props.balance < 0);
  const isInfoColor = createMemo(() => !props.balance || props.balance == 0);

  return (
    <Panel title={t('Balances')} class={cn('flex flex-col', props.class)}>
      <div class="flex-1 px-6 pb-4 pt-3">
        <div
          class="relative flex h-full items-center justify-center rounded-lg"
          classList={{
            'bg-danger-light': isDangerColor(),
            'bg-success-light': isSuccessColor(),
            'bg-[#F3F2F9]': isInfoColor(),
          }}>
          <span
            class="text-xl font-bold"
            classList={{
              'text-red-500': isDangerColor(),
              'text-[#1FC09F]': isSuccessColor(),
              'text-[#8D9BBD]': isInfoColor(),
            }}>
            {currency(props.balance)}
          </span>
          <IconBalanceBg
            class="absolute right-0 top-0"
            classList={{
              'text-success': isSuccessColor(),
              'text-danger': isDangerColor(),
            }}
          />
        </div>
      </div>
    </Panel>
  );
};

import { For } from 'solid-js';
import { IconChevronLeft } from '~/components/ui/Icons';
import { cn } from '~/utils/classnames';
import type { Component, ComponentProps, JSX } from 'solid-js';
import type { Merge } from '~/utils/types';

type PageItem = {
  label: JSX.Element;
  value?: number;
  selected?: boolean;
  disabled?: boolean;
};

const PageItem: Component<{ page: PageItem; onSelect: (value?: number) => void }> = (props) => (
  <li
    aria-selected={props.page.selected || undefined}
    aria-disabled={props.page.disabled || undefined}
    class={cn(
      'flex size-10 select-none items-center justify-center rounded-md p-2 transition-colors aria-disabled:cursor-not-allowed aria-disabled:opacity-50',
      props.page.value != null &&
        !props.page.disabled &&
        'cursor-pointer hover:bg-essential-colour/5 hover:text-essential-colour aria-selected:bg-essential-colour/10 aria-selected:text-essential-colour'
    )}
    onClick={() => props.onSelect(props.page.value)}>
    {props.page.label}
  </li>
);

export type PaginationProps = Merge<ComponentProps<'ul'>, { value: number; total: number; onChange?: (value: number) => void }>;

export const Pagination: Component<PaginationProps> = (props) => {
  const pages = (): PageItem[] => {
    const current = props.value;
    const showCount = 5;
    const start = Math.max(1, Math.min(current - Math.floor(showCount / 2), props.total - showCount + 1));
    const end = Math.min(props.total, start + showCount - 1);
    return [
      { value: current - 1, label: <IconChevronLeft class="size-5" />, disabled: current === 1 },
      ...(start > 1 ? [{ value: 1, label: 1, selected: current === 1 }] : []),
      ...(start > 2 ? [{ label: '...' }] : []),
      ...Array.from({ length: end - start + 1 }, (_, i) => ({
        value: start + i,
        label: start + i,
        selected: start + i === current,
      })),
      ...(end < props.total - 1 ? [{ label: '...' }] : []),
      ...(end < props.total ? [{ value: props.total, label: props.total, selected: current === props.total }] : []),
      { value: current + 1, label: <IconChevronLeft class="size-5 rotate-180" />, disabled: current === props.total },
    ];
  };

  const handlePageChange = (page?: number) => {
    if (page == null || page < 1 || page > props.total) return;
    props.onChange?.(page);
  };

  return (
    <ul class={cn('flex items-center gap-2 text-sm', props.class)}>
      <For each={pages()}>{(page) => <PageItem page={page} onSelect={handlePageChange} />}</For>
    </ul>
  );
};

import { SelectAnnotationUseCase } from './selectAnnotationUseCase';
import { UseCase } from './useCase';
import type { TransformRequest } from './types/transformRequest';

export class ResizeAnnotationUseCase extends UseCase {
  public async runLogic(request: TransformRequest) {
    if (!request.relativePosition) {
      return;
    }
    const selectAnnotationUseCase = new SelectAnnotationUseCase();
    await selectAnnotationUseCase.execute({ id: request.id, page: request.startPage });
    if (!this.isTransformationStarted()) {
      this.initializeTransformation(request);
    }
    if (!this.state.selectedAnnotation || !this.state.clickPosition || !this.state.initialBounds) {
      return;
    }
    const selectedAnnotation = this.state.selectedAnnotation;
    const position = request.relativePosition.position;
    const boundingBox = request.relativePosition.boundingBox;
    const x = (position.x - boundingBox.x) / boundingBox.width;
    const y = (position.y - boundingBox.y) / boundingBox.height;
    const deltaX = x - this.state.clickPosition.x;
    const deltaY = y - this.state.clickPosition.y;
    selectedAnnotation.width = this.state.initialBounds.width + deltaX;
    selectedAnnotation.height = this.state.initialBounds.height + deltaY;
  }
}

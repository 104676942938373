import { Route, Routes, useNavigate } from '@solidjs/router';
import { FormWrapper } from '~/components/common/BetterForm/Form';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import { NewRentalApplicationProvider } from '~/contexts/local';
import { Payment } from '~/pages/rental-applications/add-rental-application/Payment';
import { PaymentStatus } from '~/pages/rental-applications/add-rental-application/PaymentStatus';
import { StepsBeforePayment } from '~/pages/rental-applications/add-rental-application/StepsBeforePayment';

export const NewRentalApplication = () => {
  return (
    <FormWrapper
      initialValues={{
        unit: [{}],
        residentialHistory: [
          {
            address: {
              city: '',
            },
          },
        ],
        employment: [
          {
            position: '',
          },
        ],
      }}>
      <NewRentalApplicationProvider>
        <NewRentalApplicationView />
      </NewRentalApplicationProvider>
    </FormWrapper>
  );
};

const NewRentalApplicationView = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  return (
    <>
      <Breadcrumb
        backLink={() => navigate(-1)}
        items={[{ label: t('Rental applications'), link: '/leasing/rental-applications' }, { label: t('New rental application') }]}
      />
      <Panel class="m-8 flex h-section1 flex-col" title={t('New rental application')}>
        <Routes>
          <Route path="/:applicationId/status/:paymentStatus" component={PaymentStatus} />
          <Route path="/:applicationId/payment" component={Payment} />
          <Route path="*" component={StepsBeforePayment} />
        </Routes>
      </Panel>
    </>
  );
};

import { createSignal } from 'solid-js';
import { useForm } from '~/components/common/BetterForm';
import { FormItem, FormWrapper } from '~/components/common/BetterForm/Form';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { Modal } from '~/components/modals/Modal';
import { LabeledVendorSearch } from '~/components/vendors';
import { useLocalization } from '~/contexts/global';
import { permissionToEnterTypes } from '~/utils/constant';
import type { Component } from 'solid-js';

export const CreateWorkOrderModal: Component<{
  visible?: boolean;
  onClose?: () => void;
  onSubmit?: (data: any) => Promise<void>;
}> = (props) => {
  const { t } = useLocalization();
  const form = useForm();

  const [loading, setLoading] = createSignal(false);

  const onDone = async () => {
    try {
      setLoading(true);
      const { validateStatus } = form.validateForm();
      if (validateStatus) {
        await props.onSubmit?.(form.formStore);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal title={t('Create work order')} visible={props.visible ?? false} onCancel={props.onClose} onDone={onDone} loading={loading()}>
      <FormWrapper defaultForm={form} class="p-6">
        <FormItem<any>
          class="mb-6 w-full"
          label={t(`Vendor`)}
          formFieldName="vendorId"
          component={LabeledVendorSearch}
          placeholder={t('Please select vendor')}
          rules={[
            {
              required: true,
              message: t('Please select vendor'),
            },
          ]}
        />
        <FormItem
          class="col-span-full lg:col-span-1"
          label={t(`Permission to enter`)}
          options={permissionToEnterTypes(t)}
          formFieldName="permissionToEnter"
          onChangeMethodName="onChange"
          component={DropdownMenu}
          placeholder={t('Please select')}
        />
      </FormWrapper>
    </Modal>
  );
};

import { BaseRestRepository } from '~/repositories/baseRestRepository';

export class contributionsSettingsRepository extends BaseRestRepository {
  public async changeContributionsSettings(payload: MagicDoor.Api.ContributionSettingsDto) {
    const url = '/api/settings/contributions';
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getAllContributionsSettings() {
    const url = '/api/settings/contributions/all';
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async updateContributionsSettingsByLevel(level: string, levelId: string, payload: MagicDoor.Api.ContributionSettingsDto) {
    const url = `/api/settings/contributions/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async deleteContributionsSettingsByLevel(level: string, levelId: string) {
    const url = `/api/settings/contributions/${level}/${levelId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }
}

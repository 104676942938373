import { Show, createEffect } from 'solid-js';
import { Section } from '~/components/common/FormSectionTitle';
import { RingLoader } from '~/components/common/Loaders';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions } from '~/contexts/local';
import { OwnerDistributionsEditListView } from './OwnerDistributionsEditListView';

export const OwnerDistributionsEdit = () => {
  const { t } = useLocalization();
  const { ownerDistributions, ownerDistributionsActions } = useAddOwnerDistributions();

  createEffect(() => {
    ownerDistributionsActions.refetch();
  });

  return (
    <div class="px-6">
      <p class="mb-2.5 text-xs font-thin normal-case text-text-level02">
        {t('Owner distribution fees will not be sent until you click confirm.')}
      </p>
      <Section title={t('Owner distributions')} border class="mb-0 [&>*]:mb-0 [&>*]:border-b-0">
        <Show
          when={!ownerDistributions.loading}
          fallback={
            <div class="flex items-center justify-center pt-6">
              <RingLoader color="#A126EC" text={`${t('Loading')}...`} />
            </div>
          }>
          <OwnerDistributionsEditListView />
        </Show>
      </Section>
    </div>
  );
};

import { createSignal } from 'solid-js';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { AnnouncementRepository } from '~/repositories/announcementRepository';
import { createTriggerResource } from '~/utils/resource';
import type { AnnouncementListQuery } from '~/repositories/announcementRepository';

const repo = new AnnouncementRepository();
export const [AnnouncementProvider, useAnnouncements] = createMagicDoorContext('Announcements', () => {
  const { t } = useLocalization();
  const [announcements, setAnnouncements] = createSignal<MagicDoor.Api.AnnouncementsDto[]>([]);
  const [isLoading, setIsLoading] = createSignal(false);
  const [error, setError] = createSignal<Error | null>(null);

  const [announcement, getAnnouncement] = createTriggerResource(repo.getAnnouncementDetails.bind(repo));

  const fetchAnnouncementTargets = async (filter?: MagicDoor.Api.GetAnnouncementTargetsRequestDto) => {
    setIsLoading(true);
    try {
      const response = await repo.getAnnouncementTargets(filter);
      return response;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      toast(t('Failed to fetch announcement targets'), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAnnouncements = async (filter?: AnnouncementListQuery) => {
    setIsLoading(true);
    try {
      const response = await repo.getAnnouncements(filter);
      setAnnouncements(response);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      toast(t('Failed to fetch announcements'), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const addAnnouncement = async (announcement: FormData) => {
    setIsLoading(true);
    try {
      const response = await repo.addAnnouncement(announcement);
      await fetchAnnouncements();
      return response;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    announcements,
    isLoading,
    error,
    fetchAnnouncementTargets,
    fetchAnnouncements,
    getAnnouncement,
    announcement,
    addAnnouncement,
  };
});

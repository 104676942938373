import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { LeaseRecurringChargeRepository } from '~/repositories/leaseRecurringChargeRepository';
import type { Accessor } from 'solid-js';

interface LeaseRentChargesValue {
  charges: Accessor<MagicDoor.Api.HydratedRecurringLeaseChargeDto[]>;
  loading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
  getRecurringCharges: (leaseId: string) => Promise<void>;
  deleteRecurringCharge: (leaseId: string, id: string) => Promise<void>;
  updateRecurringCharge: (leaseId: string, recurringId: string, payload: MagicDoor.Api.UpdateRecurringLeaseChargeDto) => Promise<void>;
  addRecurringCharge: (
    leaseId: string,
    payload: MagicDoor.Api.CreateRecurringLeaseChargeDto
  ) => Promise<MagicDoor.Api.RecurringLeaseChargeDto | undefined>;
}

export const [LeaseRentChargesProvider, useLeaseRentCharges] = createMagicDoorContext<LeaseRentChargesValue>('LeaseRentCharges', () => {
  const repo = new LeaseRecurringChargeRepository();

  const [charges, setCharges] = createSignal<MagicDoor.Api.HydratedRecurringLeaseChargeDto[]>([]);
  const [loading, setLoading] = createSignal<boolean>(false);
  const [error, setError] = createSignal<Error | undefined>(undefined);

  const getRecurringCharges = async (id: string) => {
    setLoading(true);
    try {
      const response = await repo.getRecurringCharges(id);
      setCharges(response);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const addRecurringCharge = async (
    id: string,
    payload: MagicDoor.Api.CreateRecurringLeaseChargeDto
  ): Promise<MagicDoor.Api.RecurringLeaseChargeDto | undefined> => {
    try {
      const response = await repo.addRecurringCharge(id, payload);
      getRecurringCharges(id);
      return response;
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };

  const updateRecurringCharge = async (
    leaseId: string,
    recurringId: string,
    payload: MagicDoor.Api.UpdateRecurringLeaseChargeDto
  ): Promise<void> => {
    try {
      await repo.updateRecurringCharge(leaseId, recurringId, payload);
      getRecurringCharges(leaseId);
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };

  const deleteRecurringCharge = async (leaseId: string, recurringId: string) => {
    try {
      const response = await repo.deleteRecurringCharge(leaseId, recurringId);
      setCharges((prev: MagicDoor.Api.HydratedRecurringLeaseChargeDto[]) => prev.filter((recurring) => recurring.id !== recurringId));
      return response;
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };

  return {
    charges,
    loading,
    error,
    getRecurringCharges,
    addRecurringCharge,
    updateRecurringCharge,
    deleteRecurringCharge,
  };
});

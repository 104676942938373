import { BaseRestRepository } from './baseRestRepository';

const BILLS_URL = '/api/service-bills';

export class ServiceBillRepository extends BaseRestRepository {
  public async addServiceBill(data: MagicDoor.Api.AddServiceBillDto): Promise<MagicDoor.Api.ServiceBillResultDto> {
    const url = `${BILLS_URL}`;
    const response = await this.fetchWithAuth(url, { method: 'POST', body: JSON.stringify(data) });
    return await this.getJsonResponse(response);
  }
}

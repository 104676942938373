import { BaseRestRepository } from '~/repositories/baseRestRepository';

const MANAGEMENT_FEES_SETTINGS_URL = '/api/settings/management-fees';

export class managementFeesSettingsRepository extends BaseRestRepository {
  public async getAllManagementFeesSettings(): Promise<MagicDoor.Api.ManagementFeeSettingsDtoSettingsWithOverridesDto> {
    const url = `${MANAGEMENT_FEES_SETTINGS_URL}/all`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async updateManagementFeesSettings(data: MagicDoor.Api.ManagementFeeSettingsDto): Promise<MagicDoor.Api.ManagementFeeSettingsDto> {
    const url = `${MANAGEMENT_FEES_SETTINGS_URL}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    return this.getJsonResponse(response);
  }

  public async deleteManagementFeesSettings(): Promise<void> {
    const url = `${MANAGEMENT_FEES_SETTINGS_URL}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }

  public async updateManagementFeesSettingsByLevel(
    level: string,
    levelId: string,
    data: MagicDoor.Api.ManagementFeeSettingsDto
  ): Promise<MagicDoor.Api.ManagementFeeSettingsDto> {
    const url = `${MANAGEMENT_FEES_SETTINGS_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    return this.getJsonResponse(response);
  }

  public async getManagementFeesSettingsByLevel(level: string, levelId: string): Promise<MagicDoor.Api.ManagementFeeSettingsDto> {
    const url = `${MANAGEMENT_FEES_SETTINGS_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async deleteManagementFeesSettingsByLevel(level: string, levelId: string): Promise<void> {
    const url = `${MANAGEMENT_FEES_SETTINGS_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }
}

import { createEffect, createSignal, onCleanup } from 'solid-js';
import type { Accessor } from 'solid-js';

const lock = () => {
  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
  document.documentElement.style.paddingRight = `${scrollbarWidth}px`;
  document.documentElement.style.overflow = 'hidden';
};

const unlock = () => {
  document.documentElement.style.paddingRight = '';
  document.documentElement.style.overflow = '';
};

export const useLockBodyScroll = (locked: Accessor<boolean> = () => true) => {
  createEffect(() => {
    if (locked()) {
      lock();
      onCleanup(unlock);
    }
  });
};

export const resizeTextArea = (textAreaRef: HTMLTextAreaElement) => {
  setTimeout(() => {
    if (textAreaRef) {
      textAreaRef.style.height = 'auto';
      textAreaRef.style.height = textAreaRef.scrollHeight + 'px';
    }
  }, 30);
};

export const useEnterKey = (onEnter: () => void) => {
  return (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onEnter();
    }
  };
};

export const useEscKey = (onEsc: () => void) => {
  return (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      onEsc();
    }
  };
};

export const useScrollSpy = (ids: () => string[], options: IntersectionObserverInit = {}) => {
  const [active, setActive] = createSignal<string | undefined>(undefined);

  createEffect(() => {
    const map = new Map<string, number>();
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => map.set(entry.target.id, entry.intersectionRatio));
        const max = Math.max(...map.values(), 0.1);
        const item = ids().find((i) => map.get(i) === max);
        if (item == null) return;
        setActive(item);
      },
      { rootMargin: '0px', threshold: [0, 0.25, 0.5, 0.75, 1], ...options }
    );
    const elements = ids()
      .map((i) => document.getElementById(i))
      .filter((i) => i) as HTMLElement[];
    elements.forEach((element) => observer.observe(element));
    onCleanup(() => observer.disconnect());
  });

  return [active, setActive] as const;
};

type Container = HTMLElement | undefined | null;

export const useOutsideClick = (
  containers: Accessor<Container | Container[]>,
  enabled: Accessor<boolean>,
  callback: (e: MouseEvent, originalTarget: Element) => void
) => {
  let original: EventTarget | null = null;

  createEffect(() => {
    if (!enabled()) return;

    const handleMouseDown = (e: MouseEvent) => {
      original = e.target;
    };

    const handleClick = (e: MouseEvent) => {
      const target = original as Element | null;
      if (target == null) return;

      const cts = containers();
      const elements = Array.isArray(cts) ? cts : [cts];

      if (elements.some((i) => i?.contains(target))) return;

      const dialogId = target.closest('[role="dialog"]')?.id;
      if (dialogId != null) {
        const dc = document.querySelector(`[aria-controls="${dialogId}"]`);
        if (dc && elements.some((i) => i?.contains(dc))) return;
      }

      callback(e, target);

      original = null;
    };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('click', handleClick);

    onCleanup(() => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('click', handleClick);
    });
  });
};

export const usePortal = (id: string, className: string) => {
  function createRootElement(id: string) {
    const rootContainer = document.createElement('div');
    rootContainer.setAttribute('id', id);
    return rootContainer;
  }

  function addRootElement(rootElem: any) {
    document.body.appendChild(rootElem);
  }

  const existingParent = document.querySelector(`#${id}`);

  const parentElem = existingParent || createRootElement(id);

  if (!existingParent) {
    addRootElement(parentElem);
  }
  parentElem.classList.add(className);
  return parentElem;
};

export function useCreateModel<T>(props: any, field: string, defaultValue: T) {
  let value;
  let setValue;
  if (props[field] && props[field].length === 2 && typeof props[field][0] === 'function') {
    value = props[field][0];
    setValue = props[field][1];
  } else {
    [value, setValue] = createSignal<T>(props[field] || defaultValue);
  }

  return [value, setValue];
}

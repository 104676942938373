import { For } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';

export interface TabItem {
  key: string;
  title: string;
}

interface TabProps {
  class?: string;
  tabs: TabItem[];
  activeTab: string;
  onTabChange: (tab: string) => void;
  noTranslate?: boolean;
}

const Tab = (props: TabProps) => {
  const isActive = (tab: string) => props.activeTab === tab;
  const { t } = useLocalization();

  return (
    <div
      class={cn('flex h-15 w-full items-center gap-2 overflow-x-hidden rounded-md border bg-white text-base font-semibold', props.class)}>
      <div class="thinscroll flex flex-1 overflow-x-auto whitespace-nowrap text-sm font-medium capitalize [--thumb-color:#0003] [--track-color:#0000] *:text-text-level03">
        <For each={props.tabs} fallback={<span>{t('Loading')}</span>}>
          {(tab) => (
            <button
              onClick={() => props.onTabChange(tab.key)}
              class="flex items-center justify-center px-5 py-2 transition-all duration-300"
              classList={{ 'text-link relative': isActive(tab.key), 'text-text-level03 hover:text-text-level02': !isActive(tab.key) }}>
              {props.noTranslate ? tab.title : t(tab.title)}
              <span
                class="absolute bottom-0 h-1 w-14 rounded-t-lg bg-link"
                classList={{ block: isActive(tab.key), hidden: !isActive(tab.key) }}
              />
            </button>
          )}
        </For>
      </div>
    </div>
  );
};

export default Tab;

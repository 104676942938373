import { For, Show } from 'solid-js';
import { Dynamic, Portal } from 'solid-js/web';
import { IconAlert, IconCircleCheck, IconVolume, IconX } from '~/components/ui';
import { remove, toasts } from './store';
import type { ToastType, ToastItem } from './store';
import type { Component } from 'solid-js';

const configMap: Record<ToastType, { icon: Component<{ class?: string }>; color: string }> = {
  normal: { icon: IconVolume, color: '#4F79E7' },
  success: { icon: IconCircleCheck, color: '#1E967D' },
  error: { icon: IconAlert, color: '#F94874' },
};

const Toast: Component<ToastItem> = (props) => (
  <li
    class="relative flex animate-toast-in items-center gap-2 overflow-hidden rounded-xl border border-[color-mix(in_srgb,var(--color)_15%,#fff)] bg-[color-mix(in_srgb,var(--color)_3%,#fff)] bg-white p-4 text-sm text-[--color] opacity-60 shadow-2xl transition last:opacity-100"
    style={{ '--color': props.color ?? configMap[props.type].color }}>
    <Dynamic component={configMap[props.type].icon} class="size-5 opacity-90" />
    <div class="flex-1">
      <Show when={props.title != null}>
        <h4 class="mb-1 font-medium">{props.title}</h4>
      </Show>
      <p class="text-sm">{props.message}</p>
    </div>
    <button type="button" class="p-0.5 text-gray-500 transition-colors hover:text-gray-800" onClick={() => remove(props.id)}>
      <IconX class="size-4" />
    </button>
    <Show when={props.showProgress}>
      <i class="absolute bottom-0 left-0 h-0.5 animate-progress bg-current" style={{ 'animation-duration': `${props.timeout + 100}ms` }} />
    </Show>
  </li>
);

export const Toaster: Component = () => (
  <Show when={toasts.length}>
    <Portal>
      <ol class="fixed inset-x-0 top-0 z-50 m-10 mx-auto flex w-96 flex-col-reverse gap-4">
        <For each={toasts}>{Toast}</For>
      </ol>
    </Portal>
  </Show>
);

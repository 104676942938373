import { useNavigate } from '@solidjs/router';
import { For, createMemo } from 'solid-js';
import PlusIcon from '~/assets/images/common/plus.svg';
import acticateLeaseIcon from '~/assets/images/units/acticateLease_quickAction.svg';
import CancelEvictionIcon from '~/assets/images/units/cancelEviction.png';
import CopyNewLeaseIcon from '~/assets/images/units/copyNewLease.svg';
import deleteLeaseIcon from '~/assets/images/units/deleteLease.svg';
import EndLeaseIcon from '~/assets/images/units/endLease.svg';
import ExtendleaseIcon from '~/assets/images/units/extendLease.svg';
import ReactivateLeaseIcon from '~/assets/images/units/reactivateLease.svg';
import StartEvictionIcon from '~/assets/images/units/startEviction.svg';
import StartNewLeaseIcon from '~/assets/images/units/startNewLease.svg';
import { LinkButton } from '~/components/common/Buttons';
import { Panel } from '~/components/common/Panels';
import { isDraftLease, isEndedLease, isEvictionLease } from '~/components/leases/utils';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLease } from '~/contexts/local';
import type { Component } from 'solid-js';

type BalancesProps = {
  class?: string;
  lease?: MagicDoor.Api.HydratedLeaseDto;
};

export const QuickAction: Component<BalancesProps> = (props) => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const { lease, getLease, activateDraft } = useLease();

  const quickActions = createMemo(() => {
    if (isDraftLease(props.lease)) {
      return [
        {
          title: t('Activate lease'),
          icon: acticateLeaseIcon,
          bgColor: '#A126EC',
          code: 'ActicateLease',
        },
        {
          title: t('Delete'),
          icon: deleteLeaseIcon,
          bgColor: '#FFE9EE',
          code: 'DeleteLease',
        },
      ];
    } else if (isEvictionLease(props.lease)) {
      return [
        {
          title: t('Cancel eviction'),
          icon: CancelEvictionIcon,
          bgColor: '#FDF5FF',
          code: 'CancelEviction',
        },
        {
          title: t('End lease'),
          icon: EndLeaseIcon,
          bgColor: '#FFF1F6',
          code: 'EndLease',
        },
        {
          title: t('Add service bill'),
          icon: PlusIcon,
          code: 'AddServiceBill',
          bgColor: '#FFF6F6',
        },
      ];
    } else if (isEndedLease(props.lease)) {
      return [
        {
          title: t('Reactivate lease'),
          icon: ReactivateLeaseIcon,
          bgColor: '#A126EC1A',
          code: 'ReactivateLease',
        },
        {
          title: t('Copy and start a new lease'),
          icon: CopyNewLeaseIcon,
          bgColor: '#E2F0FF',
          code: '',
          class: 'w-[220px]',
          isComingSoon: true,
        },
        {
          title: t('Add service bill'),
          icon: PlusIcon,
          code: 'AddServiceBill',
          bgColor: '#FFF6F6',
        },
      ];
    } else {
      return [
        {
          title: t('Start new lease'),
          icon: StartNewLeaseIcon,
          bgColor: '#FFF6F6',
          code: 'StartNewLease',
        },
        {
          title: t('Extend lease'),
          icon: ExtendleaseIcon,
          bgColor: '#FFF6F6',
          code: 'ExtendLease',
        },
        {
          title: t('Start eviction'),
          icon: StartEvictionIcon,
          bgColor: '#F4FFFD',
          code: 'StartEviction',
        },
        {
          title: t('End lease'),
          icon: EndLeaseIcon,
          bgColor: '#FFFDF0',
          code: 'EndLease',
        },
        {
          title: t('Add service bill'),
          icon: PlusIcon,
          code: 'AddServiceBill',
          bgColor: '#FFF6F6',
        },
      ];
    }
  });

  const quickAction = (code: string) => {
    switch (code) {
      case 'EndLease':
        navigate(`/leasing/leases/${props.lease?.id}/end-lease`, { scroll: false });
        break;
      case 'EditLease':
        navigate(`/leasing/leases/${props.lease?.id}/edit-lease`);
        break;
      case 'StartEviction':
        navigate(`/leasing/leases/${props.lease?.id}/start-eviction`, { scroll: false });
        break;
      case 'ExtendLease':
        navigate(`/leasing/leases/${props.lease?.id}/edit-lease`);
        break;
      case 'StartNewLease':
        navigate(
          `/leasing/leases/new?portfolioId=${props.lease?.portfolioId}&propertyId=${props.lease?.propertyId}&unitId=${props.lease?.unitId}`
        );
        break;
      case 'CancelEviction':
        navigate(`/leasing/leases/${props.lease?.id}/cancel-eviction`, { scroll: false });
        break;
      case 'ActicateLease':
        activateDraft(props.lease?.id as string).then(() => {
          getLease(lease()?.id as string);
          toast.success(t('Operation successful'));
        });
        break;
      case 'DeleteLease':
        navigate(`delete-lease`);
        break;
      case 'ReactivateLease':
        navigate(`reactivate-lease`);
        break;

      case 'AddServiceBill':
        navigate(`/leasing/leases/${props.lease?.id}/add-service-bill`);
        break;
    }
  };

  return (
    <Panel
      class={props.class}
      title={t('Quick action')}
      actions={
        <LinkButton href={`/leasing/leases/${props.lease?.id}/edit-lease`} variant="outlined" class="px-4 py-2 text-xs" rounded="md">
          {t('Edit lease')}
        </LinkButton>
      }>
      <div class="flex flex-wrap gap-4 px-9 py-4 font-mono text-sm font-bold leading-6 text-white">
        <For each={quickActions()}>
          {(item) => (
            <div class={'grow-1 relative flex basis-32 flex-col items-center justify-center'}>
              <div
                onClick={() => quickAction(item.code)}
                class=" flex cursor-pointer flex-col items-center justify-center rounded-md p-2 hover:bg-input/70">
                <div
                  class="flex size-9 items-center justify-center rounded-full"
                  style={{
                    'background-color': item.bgColor,
                  }}>
                  <img src={item.icon.toString()} class="size-5" alt="icon" />
                </div>
                <span class="mt-1 text-nowrap text-sm font-medium text-black">{item.title}</span>
              </div>
            </div>
          )}
        </For>
      </div>
    </Panel>
  );
};

import { createEffect, createSignal, splitProps } from 'solid-js';
import { Panel } from '~/components/common/Panels';
import { cn } from '~/utils/classnames';
import { isUndefined } from '~/utils/tool';
import { MultiSteps } from './MultiSteps';
import type { MultiStepsProps } from './MultiSteps';
import type { Component } from 'solid-js';

type MultiStepsPanelProps = Omit<MultiStepsProps, 'currentStep'> & {
  class?: string;
  title: string;
  currentStep?: number;
};

export const MultiStepsPanel: Component<MultiStepsPanelProps> = (props) => {
  const [currentStep, setCurrentStep] = createSignal<number>(0);
  const [, msProps] = splitProps(props, ['title', 'class']);
  const [loading, setLoading] = createSignal<boolean>(false);
  const onStepChange = async (step: number, isBack?: boolean) => {
    setLoading(true);
    const flag = props.onStepChange && (await props.onStepChange(step, isBack));
    if (flag || isUndefined(flag)) {
      setCurrentStep(step);
    }
    setLoading(false);
  };

  createEffect(() => {
    if (!isUndefined(props.currentStep)) {
      setCurrentStep(props.currentStep);
    }
  });

  return (
    <Panel class={cn('m-8 flex h-section1 flex-col', props.class)} title={props.title}>
      <MultiSteps {...msProps} currentStep={currentStep()} onStepChange={onStepChange} loading={loading()} />
    </Panel>
  );
};

import { Route, Routes } from '@solidjs/router';
import { ListListingPage } from './list-listing';
import ListingDetailsPage from './listing-detail';

const ListingRoutes = () => {
  return (
    <Routes>
      <Route path="/:listingId/*" component={ListingDetailsPage} />
      <Route path="*" component={ListListingPage} />
    </Routes>
  );
};

export default ListingRoutes;

import { Show } from 'solid-js';
import type { JSX } from 'solid-js';

interface ContentSectionProps {
  content?: JSX.Element;
}

const ContentSection = (props: ContentSectionProps) => <Show when={props.content}>{props.content}</Show>;

export default ContentSection;

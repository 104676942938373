import { createSignal, onMount } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { rentPaymentNotificationsSettingsRepository } from '~/repositories/settings/rentPaymentNotificationsSettingsRepository';
import { createLazyResource } from '~/utils/resource';
import type { RentPaymentNotificationSettingsDtoSettingsWithOverridesDto } from '~/swagger/Api';

export const [RentPaymentNotificationsSettingsProvider, useRentPaymentNotificationsSettings] = createMagicDoorContext(
  'RentPaymentNotificationsSettings',
  () => {
    const repo = new rentPaymentNotificationsSettingsRepository();
    const [isLoading, setIsLoading] = createSignal<boolean>(false);
    const [error, setError] = createSignal<Error | undefined>(undefined);

    const [allRentPaymentNotificationsSettings, allRentPaymentNotificationsSettingsActions] =
      createLazyResource<RentPaymentNotificationSettingsDtoSettingsWithOverridesDto>(() => repo.getAllRentPaymentNotificationsSettings());

    // change update delete text setting like chats
    const updateRentPaymentNotificationsSettingsByLevel = async (
      level: string,
      levelId: string,
      payload: MagicDoor.Api.RentPaymentNotificationSettingsDto
    ): Promise<void> => {
      setIsLoading(true);
      try {
        await repo.updateRentPaymentNotificationsSettingsByLevel(level, levelId, payload);
        setTimeout(() => {
          allRentPaymentNotificationsSettingsActions.refetch();
        }, 1000);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    const deleteRentPaymentNotificationsSettingsByLevel = async (level: string, levelId: string): Promise<void> => {
      setIsLoading(true);
      try {
        await repo.deleteRentPaymentNotificationsSettingsByLevel(level, levelId);
        setTimeout(() => {
          allRentPaymentNotificationsSettingsActions.refetch();
        }, 1000);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    const updateRentPaymentNotificationsSettings = async (payload: MagicDoor.Api.RentPaymentNotificationSettingsDto): Promise<void> => {
      setIsLoading(true);
      try {
        await repo.changeRentPaymentNotificationsSettings(payload);
        setTimeout(() => {
          allRentPaymentNotificationsSettingsActions.refetch();
        }, 1000);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    onMount(() => {
      allRentPaymentNotificationsSettingsActions.fetch();
    });

    return {
      isLoading,
      error,

      updateRentPaymentNotificationsSettings,
      allRentPaymentNotificationsSettings,
      updateRentPaymentNotificationsSettingsByLevel,
      deleteRentPaymentNotificationsSettingsByLevel,
    };
  }
);

import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { PropertiesRepository } from '~/repositories/propertiesRepository';
import { createLazyResource, createMutation, createTriggerResource } from '~/utils/resource';
import type { PropertyImagePayload, PropertiesFilter } from '~/repositories/propertiesRepository';

const repo = new PropertiesRepository();

export type PropertyImage = {
  status: 'new' | 'deleted' | 'normal';
  file?: File;
  fileId?: string;
};

export const [PropertiesProvider, useProperties] = createMagicDoorContext('Properties', () => {
  const [filtered, setFilter, { refetch: refetchFiltered }] = createTriggerResource(
    async (filter: PropertiesFilter) => await repo.getProperties({ active: true, ...filter })
  );

  const [properties, { fetch: fetchAllProperties }] = createLazyResource(() => repo.getAllProperties());

  const [propertyOverview, setPropertyOverview] = createSignal<MagicDoor.Api.PropertyOverviewDto>();

  const [current, setCurrentId, { refetch: refetchProperty, mutate: mutateProperty }] = createTriggerResource(async (id?: string) => {
    if (!id) return undefined;
    return await repo.getProperty(id);
  });

  const addProperty = createMutation(async (payload: MagicDoor.Api.CreatePropertyDto, images?: PropertyImage[]) => {
    const result = await repo.createProperty(payload);

    if (images != null && images.length > 0) {
      await Promise.all(
        images.map((image) => {
          if (image.status !== 'new') return;
          return repo.addPropertyImage(result.id, { file: image.file as File });
        })
      );
    }

    refetchFiltered();

    return result;
  });

  /** Not preferred. use deactivateProperty instead in most cases */
  const deleteProperty = createMutation(async (id: string) => {
    await repo.deleteProperty(id);
    refetchProperty();
  });

  const deactivateProperty = createMutation(async (id: string, forceDeactivateChildren?: boolean) => {
    await repo.deactivateProperty(id, forceDeactivateChildren);
    refetchProperty();
  });

  const updateProperty = createMutation(async (id: string, payload: MagicDoor.Api.UpdatePropertyDto, images?: PropertyImage[]) => {
    const result = await repo.updateProperty(id, payload);

    if (images != null && images.length > 0) {
      await Promise.all(
        images.map((image) => {
          if (image.status === 'deleted') return repo.deletePropertyImage(id, image.fileId as string);
          if (image.status === 'new') return repo.addPropertyImage(id, { file: image.file as File });
        })
      );
    }

    refetchProperty();
    return result;
  });

  const addPropertyImage = createMutation(async (id: string, image: PropertyImagePayload | PropertyImagePayload[]) => {
    const images = Array.isArray(image) ? image : [image];
    await Promise.all(images.map((image) => repo.addPropertyImage(id, image)));
    refetchProperty();
  });

  const deletePropertyImage = createMutation(async (id: string, imageId: string) => {
    await repo.deletePropertyImage(id, imageId);
    refetchProperty();
  });

  const removePropertyOwner = createMutation(async (propertyId: string, ownerId: string) => {
    await repo.removePropertyOwner(propertyId, ownerId);
    refetchProperty();
  });

  const addPropertyOwner = createMutation(async (propertyId: string, ownerId: string, ownershipPercentage: number) => {
    await repo.addPropertyOwner(propertyId, ownerId, { ownershipPercentage });
    refetchProperty();
  });

  const updatePropertyOwner = createMutation(async (propertyId: string, ownerId: string, ownershipPercentage: number) => {
    await repo.updatePropertyOwner(propertyId, ownerId, { ownershipPercentage });
    refetchProperty();
  });

  const getPropertyOverview = createMutation(async (propertyId: string) => {
    const data = await repo.getPropertyOverview(propertyId, true);
    setPropertyOverview(data);
  });

  return {
    get properties() {
      fetchAllProperties();
      return properties;
    },
    get loading() {
      return () => filtered.loading || current.loading;
    },
    get filtered() {
      return filtered;
    },
    setFilter,
    setCurrentId: setCurrentId,
    current,
    propertyOverview,
    getPropertyOverview,
    addProperty: addProperty,
    deleteProperty,
    deactivateProperty,
    updateProperty: updateProperty,
    mutateProperty: mutateProperty,
    addPropertyImage: addPropertyImage,
    deletePropertyImage: deletePropertyImage,
    removePropertyOwner,
    addPropertyOwner,
    updatePropertyOwner,
  };
});

import { A } from '@solidjs/router';
import { Show, createEffect, createSignal } from 'solid-js';
import IconAnnouncement from '~/assets/images/chat/annoucementMessage.svg';
import IconBot from '~/assets/images/chat/botMessage.png';
import IconTempPfp from '~/assets/images/chat/tempPfp.svg';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useChat } from '~/contexts/local';
import { MessageBody } from './chat-message/MessageBody';
import { MessageStatus } from './chat-message/MessageStatus';
import { ParticipantInfo } from './chat-message/ParticipantInfo';

interface ChatMessageProps {
  message: MagicDoor.Api.ChatMessageDto;
  allMessage: MagicDoor.Api.ChatMessageDto[];
  chatDetails: MagicDoor.Api.HydratedMagicChatDto | undefined;
}

export const ChatContent = (props: ChatMessageProps) => {
  const { t } = useLocalization();
  const { getParticipantName } = useChat();

  const [participantName, setParticipantName] = createSignal<string | null | undefined>('');
  const [showOriginal, setShowOriginal] = createSignal<boolean>(true);

  const toggleMessageView = () => {
    if (props.message.translatedMessage) {
      setShowOriginal((prev) => !prev);
    }
  };

  const updateParticipantName = () => {
    switch (props.message.messageType) {
      case 'bot':
        return 'AI';
      case 'announcement':
        return 'Announcement';
      default:
        return props.chatDetails ? getParticipantName(props.message, props.chatDetails) : 'Unknown';
    }
  };

  const messageIcon = () => {
    switch (props.message.messageType) {
      case 'bot':
        return IconBot;
      case 'announcement':
        return IconAnnouncement;
      case 'participant':
        return `https://i.pravatar.cc/150?u=${props.message.participantId}`;
      default:
        return IconTempPfp;
    }
  };

  const messageStyle = () => {
    switch (props.message.messageType) {
      case 'bot':
        return 'rounded-full';
      case 'announcement':
        return 'border border-warning bg-[#FFCB97]';
      default:
        return 'border border-[#7F0DC5] bg-essential-colour';
    }
  };

  const messageAlignmentClass = () => {
    const isRightAligned = props.message.messageType !== 'participant';
    return isRightAligned ? 'items-start justify-end' : 'flex-row-reverse items-start justify-end';
  };

  const participantInSystem = () => {
    return props.chatDetails?.type !== 'unknown' && props.message.messageType === 'participant';
  };

  const findParticipant = () => {
    return props.chatDetails?.participants?.find((p) => p.id === props.message.participantId);
  };

  const linkToDetails = () => {
    if (!participantInSystem()) {
      return;
    }

    const participant = findParticipant();
    if (!participant) {
      return;
    }

    let path = '';
    switch (participant.participantType) {
      case 'tenant':
        path = `/users/tenants/${participant.accountId}`;
        break;
      case 'vendor':
        path = `/maintenance/vendors/${participant.accountId}`;
        break;
      default:
        console.error(`Unsupported participant type: ${participant}`);
        return;
    }

    if (path) {
      return path;
    } else {
      toast(t('No valid navigation path found'), 'error');
    }
  };

  createEffect(() => {
    const isPropertyManager = props.message.messageType !== 'participant';
    setShowOriginal(isPropertyManager);
  });

  createEffect(() => {
    setParticipantName(updateParticipantName());
  });

  return (
    <div class="w-full py-4">
      <div class={`flex w-full gap-3.5 ${messageAlignmentClass()}`}>
        <div class={`flex flex-col gap-1.5 text-xs ${props.message.messageType !== 'participant' ? 'items-end' : 'items-start'}`}>
          <ParticipantInfo
            href={linkToDetails() || ''}
            participantName={participantName()}
            participantInSystem={participantInSystem()}
            message={props.message}
          />
          <MessageBody
            toggleMessageView={toggleMessageView}
            message={props.message}
            showOriginal={showOriginal()}
            allMessage={props.allMessage}
            chatId={props.chatDetails?.id ?? ''}
          />
          <MessageStatus textStatus={props.message.textStatus} />
        </div>

        <A
          href={linkToDetails() ?? ''}
          classList={{
            'cursor-default': !participantInSystem(),
          }}
          class={`flex size-10 min-h-10 min-w-10 items-center justify-center overflow-hidden rounded-full ${messageStyle()}`}>
          <Show when={props.chatDetails?.type !== 'unknown'} fallback={<img src={IconTempPfp} alt="Message Icon" class="size-5" />}>
            <img
              src={messageIcon()}
              alt="Message Icon"
              class="size-fit"
              classList={{
                'rounded-full': props.message.messageType === 'bot',
              }}
            />
          </Show>
        </A>
      </div>
    </div>
  );
};

import { Routes, Route } from '@solidjs/router';
import PlusIcon from '~/assets/images/common/plus.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import { TabLinks } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { LeasesListProvider } from '~/contexts/local';
import { LeasesDraft } from './LeaseDraft';
import { LeaseRenewal } from './LeaseRenewal';
import { LeasesList } from './LeasesList';
import { LeasesOverview } from './LeasesOverview';
import { LeasesPast } from './LeasesPast';

export const ListLeases = () => {
  const { t } = useLocalization();
  return (
    <div class="flex flex-col gap-y-4 p-8">
      <div class="flex items-center justify-between overflow-hidden rounded-lg border border-partingline bg-white">
        <TabLinks
          items={[
            { label: t('Overview'), href: '' },
            { label: t('Leases'), href: 'list' },
            { label: t('Expiring leases'), href: 'expiring-leases' },
            { label: t('Draft leases'), href: 'draft' },
            { label: t('Past leases'), href: 'past-leases' },
          ]}
        />
        <LinkButton href="/leasing/leases/new" class="mr-3">
          <PlusIcon />
          <div class="hidden truncate md:flex">{t('Set up a new lease')}</div>
        </LinkButton>
      </div>
      <LeasesListProvider>
        <Routes>
          <Route path="/" component={LeasesOverview} />
          <Route path="/list" component={LeasesList} />
          <Route path="/draft" component={LeasesDraft} />
          <Route path="/past-leases" component={LeasesPast} />
          <Route path="/expiring-leases" component={LeaseRenewal} />
        </Routes>
      </LeasesListProvider>
    </div>
  );
};

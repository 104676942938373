import { For } from 'solid-js';
import IconLock from '~/assets/images/settings/lock.svg?component-solid';
import imgSecurityBg from '~/assets/images/settings/security-bg.svg';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { LabeledPasswordInput } from '~/components/common/Inputs/LabeledPasswordInput';
import { useLocalization } from '~/contexts/global';
import { SaveBtn } from '~/pages/settings/components/edit-form/SaveBtn';
import type { Component } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm';
import type { Promisable } from '~/utils/types';

const PasswordFormItem: Component<{ field: string; label: string; placeholder: string; rules?: BetterForm.Rule[] }> = (props) => {
  const { t } = useLocalization();
  return (
    <FormItem
      class="w-full text-left [&_div]:border-0"
      formFieldName={props.field}
      component={LabeledPasswordInput}
      type="password"
      placeholder={t(props.placeholder)}
      rules={[
        { required: true, message: t(`${props.label} is required`) },
        // { validator: (value) => value.length >= 5, message: t(`${props.label} must be at least 5 characters`) },
        ...(props.rules ?? []),
      ]}
      label={t(props.label)}
      labelClass="mb-1 text-xs text-text-level03"
    />
  );
};

type Model = { currentPassword: string; newPassword: string };

export const UpdatePassword: Component<{ errors: string; onClose: () => void; onSubmit: (model: Model) => Promisable<void> }> = (props) => {
  const { t } = useLocalization();
  const form = useForm();

  const isDirty = () => Object.keys(form.formStore).length > 0;

  const sections = [
    { field: 'currentPassword', label: 'Current password', placeholder: 'Enter password' },
    { field: 'newPassword', label: 'New password', placeholder: 'Enter new password' },
    {
      field: 'confirmPassword',
      label: 'Confirm new password',
      placeholder: 'Confirm new password',
      rules: [{ validator: (value: string) => value === form.formStore.newPassword, message: t('Passwords do not match') }],
    },
  ];

  const handleSave = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;
    if (form.formStore.newPassword !== form.formStore.confirmPassword) {
      form.setFormError('confirmPassword', t('Passwords do not match'));
      return;
    }
    try {
      await props.onSubmit(form.formStore as Model);
      props.onClose();
    } catch (error) {
      form.setFormError('currentPassword', t('Wrong password'));
    }
  };

  return (
    <>
      <div class="relative rounded-lg bg-dark-navy px-6 py-5 text-white">
        <img src={imgSecurityBg} alt={t('Login background')} class="absolute inset-x-6 top-6" />
        <Form class="mx-auto flex max-w-sm flex-col items-center py-6" defaultForm={form}>
          <IconLock />
          <h3 class="mt-3 text-2xl">{t('Update password')}</h3>
          <p class="mt-2 text-xs text-text-level03">{t('Enter the current password and new password')}</p>
          <div class="mb-4 mt-8 w-full space-y-7">
            <For each={sections}>{(section) => <PasswordFormItem {...section} />}</For>
          </div>
        </Form>
      </div>
      <SaveBtn dirty={isDirty()} onCancel={props.onClose} onSave={handleSave} />
    </>
  );
};

import { BaseRestRepository } from '~/repositories/baseRestRepository';

const TENANT_PORTAL_INVITE_SETTINGS_URL = '/api/settings/tenant-portal-invite-notifications-texts';

export class TenantPortalInviteSettingsRepository extends BaseRestRepository {
  public getTenantPortalInviteSettings = async (): Promise<MagicDoor.Api.TenantPortalInviteTextNotificationSettingsDto> => {
    const url = TENANT_PORTAL_INVITE_SETTINGS_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  };

  public deleteTenantPortalInviteSettings = async () => {
    const url = TENANT_PORTAL_INVITE_SETTINGS_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  };

  public updateTenantPortalInviteSettings = async (
    payload: MagicDoor.Api.TenantPortalInviteTextNotificationSettingsDto
  ): Promise<MagicDoor.Api.TenantPortalInviteTextNotificationSettingsDto> => {
    const url = TENANT_PORTAL_INVITE_SETTINGS_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  };
}

import { PortalAppUrlSettings } from './PortalAppUrlSettings';
// import { PortalTenantInviteSettings } from './PortalTenantInviteSettings';
// import { PortalUrlSettings } from './PortalUrlSettings';

export const PortalInviteUrl = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <PortalAppUrlSettings />
      {/* <PortalUrlSettings /> */}
      {/* <PortalTenantInviteSettings /> */}
    </div>
  );
};

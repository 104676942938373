import type { NotificationModalProps, SubjectOption } from './GenericNotificationModal';

export const prefilledContent: Record<
  NotificationModalProps['notificationType'],
  Partial<Record<SubjectOption, { chatMessage: string; emailMessage: string }>>
> = {
  lease: {
    'tenant-platinum-group': {
      chatMessage: '',
      emailMessage: `Please see the attached HOA Notice of Violation we have received.

It is your obligation to correct this non-compliance as per your lease agreement. You must remedy this problem by and provide our office with before and after photos of the correction(s). Photos must be emailed—please reply directly to this email with photos attached.

If we do not receive a response with proof of the violation cured by the above date, we will take immediate action to correct the violation. You will be responsible for all costs associated with curing the violation and any additional fines imposed by the HOA.

**IT IS YOUR RESPONSIBILITY TO SUBMIT PHOTOS AND TO CONTACT THE HOA DIRECTLY REGARDING THE CUREMENT OF THE HOA VIOLATION.**

$125.00 HOA Notice/Violation charge has been applied to your account as a result of this notice.

Thank you,
Centurion Management Services
702-435-7368

Samuel A Culotta Jr
President - Broker/Owner
Platinum Group Companies LLC
LIC #0143916.LLC`,
    },
    'centurion-hoa-violation': {
      chatMessage: '',
      emailMessage: `REF:HOA VIOLATION
The vendor below will be contacting you and/or visiting the property without a scheduled appointment (if they do not need access inside) to correct an HOA violation.  Please schedule with the vendor accordingly should they call.  You may contact them directly as well.
`,
    },
    'ameropan-hoa-violation': {
      chatMessage: '',
      emailMessage: `The vendor below will be contacting you and/or visiting the property without a scheduled appointment (if they do not need access inside) to correct an HOA violation. Please schedule with the vendor accordingly should they call. You may contact them directly as well.`,
    },
  },
  owner: {
    'centurion-hoa-violation': {
      chatMessage: '',
      emailMessage: `Attached work order submitted by our HOA compliance department. Vendor will be assigned, dispatched and paid from reserve funds and/or rents on hand (under $500). Anything over $500, we will contact you for approval.

If this cost falls under tenant responsibility, tenant(s) will be billed back for reimbursement costs once completed.

Please contact our HOA compliance department directly with any questions- shari@centurionmgmt.com.

**Owner Responsibility**`,
    },
    'ameropan-hoa-violation': {
      chatMessage: '',
      emailMessage: `Attached work order submitted by our HOA compliance department.  Vendor will be assigned, dispatched and paid from reserve funds and/or rents on hand (under $500).  Anything over $500, we will contact you for approval.
If this cost falls under tenant responsibility, tenant(s) will be billed back for reimbursement costs once completed.
Please contact our HOA compliance department directly with any questions- shari@ameropanrealty.com.

**Owner Responsibility**`,
    },
  },
  vendor: {
    'centurion-hoa-violation': {
      chatMessage: '',
      emailMessage: `THIS IS AN HOA VIOLATION AND/OR COURTESY NOTICE

*please send completion photos to shari@centurionmgmt.com*

Please contact tenants directly for scheduling. A scheduled appointment is not required for any work needed outside the property (front yard only). Tenant(s) have been notified of this work order.

Please advise scheduled date/time.

**Vendor may proceed with all work under $500. If over, please send an estimate to repairs@centurionmgmt.com for approval.**

***All work orders marked EMERGENCY/URGENT must begin to cure within 48 hours***

To receive final payment, **PHOTOS MUST BE TAKEN AND SUBMITTED** with every invoice. Invoices will not be paid otherwise. All invoices must be received within 90 days from completed work. Please send all invoices directly to accounting - donna@centurionmgmt.com.`,
    },
    'ameropan-hoa-violation': {
      chatMessage: '',
      emailMessage: `THIS IS AN HOA VIOLATION AND/OR COURTESY NOTICE
*please send completion photos to shari@ameropanrealty.com

Please contact tenants directly for scheduling.  A scheduled appointment is not required for any work needed outside the property (front yard only).  Tenant(s) have been notified of this work order.

Please advise scheduled date/time.

Vendor may proceed with all work under $500.  If over, please send an estimate to repairs@ameropanrealty.com for approval.

*All work orders marked EMERGENCY/URGENT must begin to cure within 48 hours*

To receive final payment, PHOTOS MUST BE TAKEN AND SUBMITTED with every invoice.  Invoices will not be paid otherwise.  All invoices must be received within 90 days from completed work.  Please send all invoices directly to accounting- donna@ameropanrealty.com.`,
    },
  },
};

import { LatestUpdatedAt } from '~/components/ui';
import { useVendors } from '~/contexts/global';
import { BillsProvider } from '~/contexts/local';
import VendorSummary from './VendorSummary';
import VendorBill from './bills/VendorBills';
import VendorTransactions from './transactions/VendorTransactions';
import VendorWorkOrder from './work-order/VendorWorkOrder';

const VendorGeneralTab = () => {
  const { vendorOverview } = useVendors();
  return (
    <div class="relative flex flex-col gap-y-4">
      <LatestUpdatedAt time={vendorOverview()?.cacheDate ?? ''} />
      <BillsProvider>
        <VendorSummary />
        <VendorBill />
      </BillsProvider>
      <div class="flex gap-x-4">
        <div class="w-3/5">
          <VendorWorkOrder />
        </div>
        <div class="w-2/5">
          <VendorTransactions />
        </div>
      </div>
    </div>
  );
};

export default VendorGeneralTab;

import { signerColorsTranslucent } from '~/pdfsigner/ui/types/signerColors';
import { AnnotationType } from '~/pdfsigner/usecases/types/annotation';
import { Presenter } from './Presenter';
import type { PresentableAnnotatedPdfPage } from '~/pdfsigner/ui/types/presentableAnnotatedPdfPage';
import type { DataPathManager } from '~/pdfsigner/usecases/state/dataPathManager';
import type { PdfState } from '~/pdfsigner/usecases/state/pdfAppState';
import type { Annotation } from '~/pdfsigner/usecases/types/annotation';
import type { Signer } from '~/pdfsigner/usecases/types/signer';

export interface PresentableAnnotation {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  text: string;
  borderColor: string;
  fontType: string;
  fontSize: number;
  backgroundColor: string;
  isSelected: boolean;
  isReadOnly: boolean;
}

interface AnnotationDescriptor {
  annotation: Annotation;
  selectedAnnotationId: string | undefined;
  isEditing: boolean;
  pageBounds: DOMRect;
  signers: Signer[];
  dataPathManager?: DataPathManager;
}

export class PdfPresenter extends Presenter<PresentableAnnotatedPdfPage[]> {
  createModel(state: PdfState): PresentableAnnotatedPdfPage[] {
    const result: PresentableAnnotatedPdfPage[] = [];
    const pdfPages = state.pdfs.pdfPages;
    if (pdfPages.length === 0) {
      return result;
    }
    let pageNumber = 0;
    for (let i = 0; i < pdfPages.length; i++) {
      const element = document.getElementById('pdfPage' + pageNumber);
      const rect: DOMRect | undefined = element?.getBoundingClientRect();
      const page = pdfPages[i];
      const annotations = this.createPresentableAnnotations(state, page.annotations, rect);
      result.push({
        page: page.page,
        annotations,
        pageNumber,
      });
      pageNumber++;
    }
    return result;
  }

  private createPresentableAnnotations(state: PdfState, annotations: Annotation[], pageBounds?: DOMRect): PresentableAnnotation[] {
    return annotations.map((annotation: Annotation): PresentableAnnotation => {
      const dataSource: AnnotationDescriptor = {
        annotation,
        isEditing: state.isEditing,
        selectedAnnotationId: state.selectedAnnotation?.id,
        pageBounds: pageBounds || new DOMRect(),
        signers: state.pdfs.signers,
        dataPathManager: state.dataPathManager,
      };
      return this.createPresentableAnnotation(dataSource);
    });
  }

  createPresentableAnnotation(descriptor: AnnotationDescriptor): PresentableAnnotation {
    const signerIndex = descriptor.signers.findIndex((signer) => signer.id === descriptor.annotation.signerId);
    const isSelected = descriptor.annotation.id === descriptor.selectedAnnotationId;
    const isEditing = isSelected && !this.isClientInput(descriptor.annotation.type) && descriptor.isEditing;
    let isReadOnly = isSelected && !isEditing;
    let borderColor = 'lightgray';
    let backgroundColor = 'white';
    let text = this.getAnnotationText(descriptor.annotation, descriptor.dataPathManager);
    if (this.isClientInput(descriptor.annotation.type)) {
      text = `${descriptor.signers[signerIndex].name}: ${text}`;
    }
    switch (descriptor.annotation.type) {
      case AnnotationType.SIGNATURE:
      case AnnotationType.INITIALS:
      case AnnotationType.DATE:
        borderColor = 'purple';
        backgroundColor = descriptor.annotation.signerId !== undefined ? signerColorsTranslucent[signerIndex] : 'white';
        isReadOnly = true;
        break;
      case AnnotationType.AUTOFILL:
        borderColor = 'blue';
        backgroundColor = 'rgba(48, 168, 255, 0.2)';
        isReadOnly = true;
        break;
    }

    const presentableAnnotation: PresentableAnnotation = {
      id: descriptor.annotation.id,
      x: descriptor.annotation.x * descriptor.pageBounds.width,
      y: descriptor.annotation.y * descriptor.pageBounds.height,
      width: descriptor.annotation.width * descriptor.pageBounds.width,
      height: descriptor.annotation.height * descriptor.pageBounds.height,
      text,
      borderColor,
      backgroundColor,
      fontType: 'Helvetica',
      fontSize: descriptor.annotation.fontSize,
      isSelected,
      isReadOnly,
    };
    return presentableAnnotation;
  }

  private isClientInput(annotationType: AnnotationType): boolean {
    return (
      annotationType === AnnotationType.SIGNATURE || annotationType === AnnotationType.INITIALS || annotationType === AnnotationType.DATE
    );
  }

  private getAnnotationText(annotation: Annotation, dataPathManager?: DataPathManager) {
    switch (annotation.type) {
      case AnnotationType.DATE:
        return 'MM/DD/YYY';
      case AnnotationType.INITIALS:
        return 'XX';
      case AnnotationType.SIGNATURE:
        return 'Signature';
      case AnnotationType.AUTOFILL:
        return dataPathManager?.getNameOfDataPathWithKey(annotation.dataPathKey) || '';
      default:
        return annotation.text;
    }
  }
}

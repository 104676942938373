import { createSignal } from 'solid-js';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { LeaseRepository } from '~/repositories/leaseRepository';
import { sendRequestsInPool } from '~/utils/requestLoop';
import type { Accessor } from 'solid-js';
import type { ExpiringLeaseListFilter, LeaseListFilter } from '~/repositories/leaseRepository';

interface Pagination {
  current: number;
  pageSize: number;
  total: number;
  totalPages: number;
}

interface LeasesListContextProps {
  filter: Accessor<LeaseListFilter | undefined>;
  leases: Accessor<MagicDoor.Api.HydratedLeaseDto[]>;
  loading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
  getLeases: (params: LeaseListFilter) => Promise<void>;
  refetchLeases: () => Promise<void>;
  getExpiringLeases: (params: ExpiringLeaseListFilter) => Promise<void>;
  onLeaseAdded: (lease: MagicDoor.Api.HydratedLeaseDto) => void;
  pagination: Accessor<Pagination>;
  sendNotifyBalanceDue: (leaseIds: string[]) => Promise<void>;
  sendNotifyBalanceDueList: Accessor<string[]>;
}

export const [LeasesListProvider, useLeasesList] = createMagicDoorContext<LeasesListContextProps>('LeasesList', () => {
  const repo = new LeaseRepository();
  const { t } = useLocalization();
  const [filter, setFilter] = createSignal<LeaseListFilter>();
  const [leases, setLeases] = createSignal<MagicDoor.Api.HydratedLeaseDto[]>([]);
  const [loading, setLoading] = createSignal<boolean>(false);
  const [error, setError] = createSignal<Error | undefined>(undefined);
  const [pagination, setPagination] = createSignal<Pagination>({
    current: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
  });
  const [sendNotifyBalanceDueList, setSendNotifyBalanceDueList] = createSignal<string[]>([]);

  const getLeases = async (params: LeaseListFilter) => {
    setLoading(true);
    try {
      setFilter(params);
      if (params.search && params.search.length < 3) {
        toast(t('Search must be at least 3 characters'));
        return;
      }
      const response = await repo.getLeases(params);
      setLeases(response.items);
      setPagination({
        current: response.currentPage,
        pageSize: response.pageSize,
        total: response.totalCount,
        totalPages: response.totalPages,
      });
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const refetchLeases = async () => {
    if (filter() == null) return;
    await getLeases({ ...filter() });
  };

  const onLeaseAdded = (lease: MagicDoor.Api.HydratedLeaseDto) => {
    setLeases((prev) => [lease, ...prev]);
  };

  const sendNotifyBalanceDue = async (leaseIds: string[]) => {
    try {
      const requests = leaseIds.map((leaseId) => () => repo.sendNotifyBalanceDue(leaseId));
      await sendRequestsInPool(requests, requests?.length < 6 ? requests?.length : 6);
    } catch (error: any) {
      setError(error);
    } finally {
      setSendNotifyBalanceDueList((prev) => [...prev, ...leaseIds]);
    }
  };

  const getExpiringLeases = async (params: ExpiringLeaseListFilter) => {
    setLoading(true);
    try {
      setFilter(params);
      const response = await repo.getExpiringLeases(params);
      setLeases(response.items);
      setPagination({
        current: response.currentPage,
        pageSize: response.pageSize,
        total: response.totalCount,
        totalPages: response.totalPages,
      });
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    sendNotifyBalanceDueList,
    filter,
    leases,
    getExpiringLeases,
    loading,
    error,
    getLeases,
    refetchLeases,
    onLeaseAdded,
    pagination,
    sendNotifyBalanceDue,
  };
});

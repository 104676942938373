import { useLocalization, useWorkOrders } from '~/contexts/global';
import { GenericNotificationModal } from './GenericNotificationModal';
import type { CreateNotificationModalProps } from './GenericNotificationModal';
import type { Component } from 'solid-js';

export const CreateVendorNotificationModal: Component<CreateNotificationModalProps & { workOrderId: string }> = (props) => {
  const { t } = useLocalization();
  const { createVendorNotification } = useWorkOrders();

  const onSubmit = async (formData: FormData) => {
    if (!props.workOrderId || props.workOrderId.trim() === '') {
      return;
    }
    formData.append('workOrderId', props.workOrderId);
    await createVendorNotification(props.workOrderId, formData);
    props.onClose();
  };

  return (
    <GenericNotificationModal notificationType="vendor" title={t('Send vendor notification')} onSubmit={onSubmit} onClose={props.onClose} />
  );
};

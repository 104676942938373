import { BaseRestRepository } from './baseRestRepository';

const CHART_OF_ACCOUNTS_URL = '/api/chart-of-accounts';

export class ChartOfAccountsRepository extends BaseRestRepository {
  public async getChartOfAccounts(): Promise<MagicDoor.Api.ChartOfAccountDto[]> {
    const url = CHART_OF_ACCOUNTS_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async createChartOfAccount(data: MagicDoor.Api.CreateChartOfAccountDto) {
    const url = CHART_OF_ACCOUNTS_URL;
    const response = await this.fetchWithAuth(
      url,
      {
        method: 'POST',
        body: JSON.stringify(data),
      },
      false
    );
    return this.getJsonResponse(response);
  }

  public async updateChartOfAccount(id: string, data: MagicDoor.Api.UpdateChartOfAccountDto): Promise<MagicDoor.Api.ChartOfAccountDto> {
    const url = `${CHART_OF_ACCOUNTS_URL}/${id}`;
    const response = await this.fetchWithAuth(
      url,
      {
        method: 'PUT',
        body: JSON.stringify(data),
      },
      false
    );
    return this.getJsonResponse(response);
  }

  public async deleteChartOfAccount(id: string, replacementId: string): Promise<void> {
    const url = `${CHART_OF_ACCOUNTS_URL}/${id}`;
    await this.fetchWithAuth(
      url,
      {
        method: 'DELETE',
        body: JSON.stringify({ replacementId }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
      false
    );
  }
}

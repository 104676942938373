import { useNavigate, useParams } from '@solidjs/router';
import { createEffect, For } from 'solid-js';
import { useForm } from '~/components/common/BetterForm/context';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import { toast } from '~/components/ui';
import { useLocalization, useTenants } from '~/contexts/global';
import { HttpError } from '~/errors';
import { AddTenantForm } from '~/pages/leases/add-tenant/AddTenantForm';
import { omit } from '~/utils/tool';

function EditTenant() {
  const params = useParams<{ tenantId: `${number}` }>();
  const navigate = useNavigate();
  const { t } = useLocalization();

  const { updateTenant, current } = useTenants();

  const form = useForm();

  let tenantFormRef: any;

  createEffect(() => {
    const _tenant = current();
    if (!_tenant) return;
    form.setFieldsValue(omit(_tenant, ['id', 'created', 'updated']));
  });

  const handleEditTenant = async () => {
    const { validateStatus, errorFields } = form.validateForm();
    if (!validateStatus) {
      const name = errorFields[0].name;
      if (name[0] !== 'firstName') {
        tenantFormRef.expandableToggleOpen(true);
      }
      setTimeout(() => {
        form.scrollToField(name);
      }, 300);
      throw new Error('Invalid form');
    }
    try {
      await updateTenant(params.tenantId, form.formStore as MagicDoor.Api.UpdateTenantDto);
      navigate(`/users/tenants/${params.tenantId}`, { replace: true });
      toast.success(t('{name} has been edited successfully', { name: 'Tenant' }));
    } catch (e) {
      const errors = e instanceof HttpError && e.getErrors();
      const message = errors ? (
        <For each={Object.values(errors).flat()}>{(error) => <p>{error as string}</p>}</For>
      ) : (
        t('Operation failed, please try again later')
      );
      toast.error(message);
      throw e;
    }
  };

  return (
    <MultiStepsPanel
      title={t('Edit tenant')}
      onClose={() => navigate(`/users/tenants/${params.tenantId}`, { replace: true })}
      onSubmit={handleEditTenant}>
      <AddTenantForm ref={tenantFormRef} form={form} class="mx-auto my-10 w-full max-w-[1200px]" />
    </MultiStepsPanel>
  );
}

export default EditTenant;

import { createMemo } from 'solid-js';
import { template, Dynamic } from 'solid-js/web';
import type { Component, JSX } from 'solid-js';

export const Html: Component<{ as?: keyof JSX.IntrinsicElements; class?: string; children: string }> = (props) => {
  return <>{template(`<${props.as || 'span'} class="${props.class}">${props.children}</${props.as || 'span'}>`)}</>;
};

interface HtmlProps {
  as?: keyof JSX.IntrinsicElements;
  class?: string;
  children: string | JSX.Element;
  [key: string]: any;
}

export const DynamicHtml: Component<HtmlProps> = (props) => {
  const content = createMemo(() => {
    if (typeof props.children === 'string') {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = props.children;
      return Array.from(tempDiv.childNodes);
    }
    return props.children;
  });

  return (
    <Dynamic component={props.as || 'span'} class={props.class} {...props}>
      {content()}
    </Dynamic>
  );
};

import { useNavigate } from '@solidjs/router';
import { Show, createSignal } from 'solid-js';
import { CancelWithConfirmation } from '~/components/modals';
import MiddleModal from '~/components/modals/MiddleModal';
import { useLocalization } from '~/contexts/global';
import AddNewVendor from '~/pages/vendors/components/AddNewVendor';

export const AddVendorModal = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = createSignal<boolean>(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);

  const handleClose = () => navigate('maintenance/vendors', { scroll: false });

  return (
    <MiddleModal
      bodyClass="overflow-hidden"
      confirmation={showCloseConfirmation()}
      onClose={handleClose}
      confirmationTitle={t('Are you sure you want to cancel?')}
      confirmationMessage={t('If you cancel the addition, the information already filled in cannot be saved')}>
      <AddNewVendor
        onClose={handleClose}
        setShowCloseConfirmation={setShowCloseConfirmation}
        showCloseConfirmation={showCloseConfirmation()}
      />
      <Show when={showConfirmationModal()}>
        <CancelWithConfirmation
          onConfirm={handleClose}
          onCancel={() => setShowConfirmationModal(false)}
          message={t('Are you sure you want to cancel?')}
        />
      </Show>
    </MiddleModal>
  );
};

import { createMemo, createSignal } from 'solid-js';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { ChartOfAccountsRepository } from '~/repositories/chartOfAccountsRepository';
import { createLazyResource, createMutation } from '~/utils/resource';
import { chartOfAccountTypeMap } from './map';

const repo = new ChartOfAccountsRepository();

type ChartOfAccountTreeItem = MagicDoor.Api.ChartOfAccountDto & { children: ChartOfAccountTreeItem[] };

export type ChartOfAccountGroup = {
  type: `${MagicDoor.Api.ChartOfAccountType}`;
  title: string;
  items: ChartOfAccountTreeItem[];
};

export const [ChartOfAccountsProvider, useChartOfAccounts] = createMagicDoorContext('ChartOfAccounts', () => {
  const { t } = useLocalization();
  const [chartOfAccounts, { fetch, refetch, mutate }] = createLazyResource(() => repo.getChartOfAccounts());
  const [currentId, setCurrentId] = createSignal<string>();

  const current = createMemo(() => (currentId() ? chartOfAccounts()?.find((p) => p.id === currentId()) : undefined));

  const chartOfAccountTree = createMemo(() => {
    const list = chartOfAccounts();
    if (list == null || list.length === 0) return [];
    const map = new Map<string, ChartOfAccountTreeItem>();
    list.forEach((item) => map.set(item.id, { ...item, children: [] }));
    return list.reduce((prev, curr) => {
      const node = map.get(curr.id) as ChartOfAccountTreeItem;
      if (node.parentId == null) {
        prev.push(node);
      } else {
        const parent = map.get(node.parentId);
        parent?.children.push(node);
      }
      return prev;
    }, [] as ChartOfAccountTreeItem[]);
  });

  const chartOfAccountGroups = createMemo(() => {
    return chartOfAccountTree()?.reduce((prev, curr) => {
      const group = prev.find((group) => group.type === curr.type);
      if (group) {
        group.items.push(curr);
      } else {
        prev.push({ type: curr.type, title: t(chartOfAccountTypeMap[curr.type]), items: [curr] });
      }
      return prev;
    }, [] as ChartOfAccountGroup[]);
  });

  const addChartOfAccount = createMutation(async (model: MagicDoor.Api.CreateChartOfAccountDto) => {
    const created = await repo.createChartOfAccount(model);
    mutate((prev) => [...(prev ?? []), created]);
    return created;
  });

  const editChartOfAccount = createMutation(async (id: string, model: MagicDoor.Api.UpdateChartOfAccountDto) => {
    const updated = await repo.updateChartOfAccount(id, model);
    mutate((prev) => prev?.map((t) => (t.id === updated.id ? updated : t)));
    return updated;
  });

  const deleteChartOfAccount = createMutation(async (accountId: string, replacementId: string) => {
    try {
      await repo.deleteChartOfAccount(accountId, replacementId);
      mutate((prev) => prev?.filter((t) => t.id !== accountId));
      return null;
    } catch (error) {
      if (error instanceof Error) {
        return error.message;
      }
      return 'An error occurred';
    }
  });

  return {
    get chartOfAccounts() {
      fetch();
      return chartOfAccounts;
    },
    get chartOfAccountTree() {
      fetch();
      return chartOfAccountTree;
    },
    get chartOfAccountGroups() {
      fetch();
      return chartOfAccountGroups;
    },
    refetch,
    current,
    addChartOfAccount,
    editChartOfAccount,
    deleteChartOfAccount,
    setCurrentId,
  };
});

import { useNavigate } from '@solidjs/router';
import { HeadLayout } from '~/components/head-layout';
import { PropertyTitle } from '~/components/properties';
import { useProperties } from '~/contexts/global';

export const PropertySummary = () => {
  const { current, loading } = useProperties();
  const navigate = useNavigate();

  return (
    <HeadLayout type="property" class="bg-essential-colour" backLink={() => navigate(-1)}>
      <PropertyTitle showType={true} property={loading() ? undefined : current()} class="w-full text-lg font-semibold text-white" />
    </HeadLayout>
  );
};

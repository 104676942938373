import { IconLink, IconMail, IconMapPin, IconPhone, IconWallet } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import IconFieldSkeleton from './IconFieldSkeleton';

export const VendorContactInformationSkeleton = () => {
  const { t } = useLocalization();

  return (
    <div class="flex w-full flex-col items-start px-4 py-2 text-xs text-text-level01">
      <div class="w-full border-b py-3 text-left text-sm font-medium leading-5">{t('Contact information')}</div>
      <div class="flex w-full flex-col gap-y-4 py-4">
        <IconFieldSkeleton name={t('Phone')} src={() => <IconPhone class="size-5 text-text-level03" />} />
        <IconFieldSkeleton name={t('Email')} src={() => <IconMail class="size-5 text-text-level03" />} />
        <IconFieldSkeleton name={t('Website')} src={() => <IconLink class="size-5 text-text-level03" />} />
        <IconFieldSkeleton name={t('Expense account')} src={() => <IconWallet class="size-5 text-text-level03" />} />
        <IconFieldSkeleton name={t('Address')} src={() => <IconMapPin class="size-5 text-text-level03" />} />
      </div>
    </div>
  );
};

import { Route, Routes } from '@solidjs/router';
import { useUnits } from '~/contexts/global';
import { AddUnit } from '~/pages/units/add-unit';
import { ListInformation } from './ListInformation';
import type { Component } from 'solid-js';

export const UnitInformation: Component = () => {
  const { unit } = useUnits();

  return (
    <>
      <div class="grid gap-5 text-left">
        <ListInformation unit={unit()} />
      </div>
      <Routes>
        <Route path="/edit-unit" component={AddUnit} />
      </Routes>
    </>
  );
};

import { For, Show, createSignal } from 'solid-js';
import { DragAndDrop, IconImage, IconLoader, IconX, Image, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
import type { Promisable } from '~/utils/types';

type Photo = { id: string; url: string };

export const PhotosEditor: Component<{
  photos?: Photo[];
  onUpload: (files: File[]) => Promisable<void>;
  onDelete: (id: string) => Promisable<void>;
}> = (props) => {
  const { t } = useLocalization();
  const [uploading, setUploading] = createSignal<boolean>(false);
  const [deleting, setDeleting] = createSignal<string>();

  const handleDropFiles = async (files: FileList) => {
    const photos = [] as File[];

    for (const file of files) {
      if (!file.type.startsWith('image/')) {
        toast.error(t('Only image files are allowed'));
        continue;
      }
      if (file.size > 10 * 1024 * 1024) {
        toast.error(t('Image size should be less than 10MB'));
        continue;
      }
      photos.push(file);
    }

    if (photos.length > 0) {
      setUploading(true);
      await props.onUpload(photos);
      toast.success(t('{name} has been added successfully', { name: t('Photo') }));
      setUploading(false);
    }
  };

  const handleDeleteFile = async (fileId: string) => {
    setDeleting(fileId);
    await props.onDelete(fileId);
    toast.success(t('{name} has been deleted successfully', { name: t('Photo') }));
    setDeleting(undefined);
  };

  return (
    <Show
      when={props.photos?.length}
      fallback={
        <DragAndDrop
          class="m-8 flex cursor-pointer flex-col items-center justify-center rounded-lg bg-light-gray p-20 text-text-level03 transition-colors hover:bg-light-pink data-[active]:bg-light-pink data-[active]:shadow-inner data-[active]:ring-1 data-[active]:ring-inset data-[active]:ring-primary"
          accept="image/*"
          multiple
          onDropFiles={handleDropFiles}
          data-slot="dnd">
          <Show when={uploading()} fallback={<IconImage class="mt-2 size-10" />}>
            <IconLoader class="mt-2 size-10 animate-spin" />
          </Show>
          <p class="mb-4 mt-2 text-xs">{t('Drag and drop photos here')}</p>
          <span class="rounded-lg border border-primary px-5 py-2 text-sm text-primary">{t('Select from computer')}</span>
        </DragAndDrop>
      }>
      <div class="grid grid-cols-2 gap-4 p-8 md:grid-cols-4 xl:grid-cols-6">
        <DragAndDrop
          class="flex aspect-square cursor-pointer flex-col items-center justify-center rounded-lg bg-light-gray p-4 text-text-level03 transition-colors hover:bg-light-pink data-[active]:bg-light-pink data-[active]:shadow-inner data-[active]:ring-1 data-[active]:ring-inset data-[active]:ring-primary"
          accept="image/*"
          multiple
          onDropFiles={handleDropFiles}
          data-slot="dnd">
          <Show when={uploading()} fallback={<IconImage class="mt-2 size-6" />}>
            <IconLoader class="mt-2 size-6 animate-spin" />
          </Show>
          <p class="mt-1 text-xs">{t('Add photo')}</p>
        </DragAndDrop>
        <For each={props.photos}>
          {(item) => (
            <div class="relative overflow-hidden rounded-lg" data-slot="photo">
              <Image src={item.url} class="aspect-square w-full object-cover" />
              <button
                type="button"
                class="absolute right-1 top-1 rounded bg-black/30 p-1 text-white transition-colors hover:bg-black/60"
                onClick={() => handleDeleteFile(item.id)}>
                <Show when={deleting() === item.id} fallback={<IconX class="size-4" />}>
                  <IconLoader class="size-4 animate-spin" />
                </Show>
              </button>
            </div>
          )}
        </For>
      </div>
    </Show>
  );
};

import { createMemo } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { TranslationsRepository } from '~/repositories/translationsRepository';
import { createLazyResource } from '~/utils/resource';

const repo = new TranslationsRepository();

export const [TranslationsProvider, useTranslations] = createMagicDoorContext('Translations', () => {
  const [languages, { fetch, refetch }] = createLazyResource(() => repo.getLanguages());

  const userDefaultLanguage = createMemo(() => {
    return languages()?.find((l) => l.code === 'en');
  });

  return {
    get languages() {
      fetch();
      return languages;
    },
    refetch,
    userDefaultLanguage,
  };
});

import { useParams, useNavigate } from '@solidjs/router';
import { Show, createEffect } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Panel } from '~/components/common/Panels';
import { getTenantFullName } from '~/components/tenants/utils';
import { IconLoader } from '~/components/ui';
import { useLocalization, useTenants } from '~/contexts/global';
import EditTenant from '~/pages/tenants/components/EditTenant';

export const EditTenantPage = () => {
  const params = useParams<{ tenantId: `${number}` }>();
  const navigate = useNavigate();

  const { t } = useLocalization();

  const { setCurrentId, current } = useTenants();
  createEffect(() => setCurrentId(params.tenantId));

  const breadcrumbItems = () => [
    { label: t('Tenants'), link: '/users/tenants' },
    { label: getTenantFullName(current()) || t('Loading'), link: `/users/tenants/${params.tenantId}` },
    { label: t('Edit tenant') },
  ];

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <Show
        when={!current.loading}
        fallback={
          <Panel class="m-8 flex h-section1 flex-col items-center justify-center gap-2">
            <IconLoader class="animate-spin text-title-gray" />
            {t('Loading')}...
          </Panel>
        }>
        <EditTenant />
      </Show>
    </>
  );
};

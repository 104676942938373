export class HttpError extends Error {
  private httpStatusCode: number;
  private json?: any;

  constructor(httpStatusCode: number, json?: any) {
    super('HTTP Status Code: ' + httpStatusCode);
    this.httpStatusCode = httpStatusCode;
    this.json = json;
    Object.setPrototypeOf(this, HttpError.prototype);
  }

  getStatusCode(): number {
    return this.httpStatusCode;
  }

  getErrors(): any | undefined {
    if (typeof this.json === 'string') {
      return { unknown: this.json };
    }

    return this.json?.detail ? { unknown: this.json?.detail } : this.json?.errors;
  }
}

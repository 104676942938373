import dayjs from 'dayjs';
import { createEffect, createMemo, on, untrack } from 'solid-js';
import { createStore } from 'solid-js/store';
import { createMagicDoorContext } from '~/contexts/utils';
import { LeaseRepository } from '~/repositories/leaseRepository';
import { debounce } from '~/utils/tool';
import type { SetStoreFunction } from 'solid-js/store';
import type { CreateLeaseModel } from '~/pages/leases/new-lease/components/NewLease/types';

const lease = new LeaseRepository();

type ProrateChargeStore = {
  isUserSubmit: boolean;
  userChecked?: boolean;
  prorateAmount: null | number;
};

export const [ProrateChargeProvider, useProrateCharge] = createMagicDoorContext(
  'LeaseProrateCharge',
  (props: { model: Partial<CreateLeaseModel>; setModel: SetStoreFunction<CreateLeaseModel> }) => {
    const [store, setStore] = createStore<ProrateChargeStore>({
      isUserSubmit: false,
      userChecked: true,
      prorateAmount: null,
    });

    const canProrateCharge = createMemo(
      () =>
        // shouldShowProrate(props.model.start ?? '', props.model.rent?.chargeFrequency ?? ChargeFrequency.Monthly)
        !dayjs(props.model.rent?.startFrom).isSame(props.model.start, 'date')
    );

    const showAlert = createMemo(() => {
      return !store.isUserSubmit && canProrateCharge();
    });

    const showProrateChargeForm = createMemo(() => store.isUserSubmit && canProrateCharge());

    const amount = createMemo(() => props.model.rent?.amount);

    createEffect(
      on([showProrateChargeForm, () => store.userChecked, () => store.prorateAmount], (_showProrateChargeForm) => {
        props.setModel?.({
          proratedAmount: _showProrateChargeForm.every(Boolean) ? store.prorateAmount : null,
        });
      })
    );

    createEffect(
      on([amount, () => props.model.rent?.startFrom], () => {
        getProratedAmount();
      })
    );

    const getProratedAmount = debounce(async function () {
      const start = untrack(() => props.model.start);
      const amount = untrack(() => props.model.rent?.amount);
      const firstChargeDate = untrack(() => props.model.rent?.startFrom);

      if (!start || !amount) return;

      const res = await lease.getProratedAmount({
        leaseStartDate: start,
        rentAmount: amount,
        firstChargeDate: firstChargeDate ?? '',
      });

      if (res.proratedAmount) {
        setStore({
          prorateAmount: res.proratedAmount,
        });
      }
    }, 300);

    const onUserCheckedChange = async (checked: boolean) => {
      setStore({
        userChecked: checked,
      });
    };

    const submitProrateCharges = () => {
      setStore({
        isUserSubmit: true,
        userChecked: true,
      });
    };

    const shouldHideProratedAmountAlert = createMemo(() => !!props.model.rent?.amount && store.prorateAmount);

    return {
      store,
      setStore,
      showAlert,
      submitProrateCharges,
      onUserCheckedChange,
      showProrateChargeForm,
      shouldHideProratedAmountAlert,
    };
  }
);

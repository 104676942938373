import { onMount } from 'solid-js';
import { ListView } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useChatsList } from '~/contexts/local';
import { useLocalPagination } from '~/hooks';
import { dateFormat } from '~/utils/date';

export const UnreadMessageView = () => {
  const { t } = useLocalization();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('UnreadMessageView');
  const { filtered, setFilter } = useChatsList();

  onMount(() => {
    setFilter({ page: page(), pageSize: pageSize() });
  });

  return (
    <div class="flex w-full gap-5 p-8 text-sm">
      <div class="flex w-full flex-col gap-5">
        <div class="relative">
          <ListView
            title={t('Unread messages')}
            page={filtered()?.currentPage}
            totalPages={filtered()?.totalPages}
            pageSize={filtered()?.pageSize}
            data={filtered()?.items}
            loading={filtered.loading}
            onChange={({ page, pageSize }) => {
              setPage(page);
              setPageSize(pageSize);
            }}
            skeletonSize={10}
            rowLink={(item) => `/communications/chats/${item.chatId}`}
            columns={[
              {
                title: t('Message'),
                render: (row) => row.message,
              },
              {
                title: t('Sent at'),
                render: (row) => dateFormat('MM/DD/YYYY', row.textSentAt),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

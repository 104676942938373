import { cn } from '~/utils/classnames';
import { scrollToSection } from '~/utils/scrollToSection';
import type { Component } from 'solid-js';

type SectionAnchorProps = {
  id: string;
  title: string;
  isActive: boolean;
  onClick?: () => void;
};

export const SectionAnchor: Component<SectionAnchorProps> = (props) => {
  const handleClick = () => {
    const section = document.getElementById(props.id);
    scrollToSection(section);
  };

  return (
    <div
      class={cn('flex h-14 w-52 cursor-pointer items-center rounded-lg border border-partingline p-3 text-text-level03', {
        'border-essential-colour text-essential-colour': props.isActive,
      })}
      onClick={() => handleClick()}>
      <div class="size-5 rounded-full border-[3px] border-partingline" />
      <span class="ml-2 font-medium">{props.title}</span>
    </div>
  );
};

import { Panel } from '~/components/common/Panels';
import TableSkeleton from '~/components/skeletons/TableSkeleton';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
type LatestPaymentsTableProps = {
  class?: string;
};

export const LatestPaymentsTableSkeleton: Component<LatestPaymentsTableProps> = (props) => {
  const { t } = useLocalization();

  const columns = [
    {
      title: t('Date'),
    },
    {
      title: t('Receipt no'),
    },
    {
      title: t('Description'),
    },
    {
      title: t('From'),
    },
    {
      title: t('To'),
    },
    {
      title: t('Amount'),
    },
  ];

  return (
    <Panel title={t('Latest payments')} class={props.class}>
      <TableSkeleton columns={columns} rowCount={2} />
    </Panel>
  );
};

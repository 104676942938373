import type { Icon } from '~/components/ui';

export const PortfolioIcon: Icon = (props) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.7602 20.6362H15.7964V4.37119C15.7964 3.16619 14.8202 2.18994 13.6152 2.18994H6.47019C5.26519 2.18994 4.28895 3.16619 4.28895 4.37119V20.6362H3.16895C2.91202 20.6362 2.66561 20.7383 2.48394 20.9199C2.30226 21.1016 2.2002 21.348 2.2002 21.6049C2.2002 21.8619 2.30226 22.1083 2.48394 22.2899C2.66561 22.4716 2.91202 22.5737 3.16895 22.5737H21.7602C22.0171 22.5737 22.2635 22.4716 22.4452 22.2899C22.6269 22.1083 22.7289 21.8619 22.7289 21.6049C22.7289 21.348 22.6269 21.1016 22.4452 20.9199C22.2635 20.7383 22.0171 20.6362 21.7602 20.6362Z"
      fill="#B140F5"
    />
    <path
      d="M6.72363 6.41895H8.69113V8.38645H6.72363V6.41895ZM6.72363 10.8602H8.69113V12.8277H6.72363V10.8602ZM6.72363 15.3302H8.69113V17.2977H6.72363V15.3302ZM9.89488 6.41895H11.8624V8.38645H9.89488V6.41895Z"
      fill="white"
    />
    <path
      d="M22.9174 12.3138C23.0787 9.07626 20.5337 6.29001 17.2937 6.33751C14.2662 6.38251 11.8262 8.85001 11.8262 11.8875C11.8262 15.185 14.3174 18.8588 16.7762 20.4675C16.9507 20.5817 17.155 20.6418 17.3636 20.6405C17.5721 20.6392 17.7756 20.5764 17.9487 20.46C20.3512 18.84 22.7387 15.8838 22.9174 12.3138Z"
      fill="#A126EC"
    />
    <path
      d="M15.7964 19.7186V6.56738C13.5002 7.24738 11.8252 9.37113 11.8252 11.8886C11.8252 14.7461 13.6977 17.8849 15.7964 19.7186ZM17.2814 20.6361C17.3314 20.6399 17.3802 20.6399 17.4302 20.6361H17.2814Z"
      fill="#A126EC"
    />
    <path
      d="M17.3751 15.7375C15.2526 15.7375 13.5264 14.0113 13.5264 11.8888C13.5264 9.76629 15.2526 8.04004 17.3751 8.04004C19.4976 8.04004 21.2239 9.76629 21.2239 11.8888C21.2239 14.01 19.4976 15.7375 17.3751 15.7375ZM17.3751 10.04C16.3551 10.04 15.5264 10.8688 15.5264 11.8888C15.5264 12.9088 16.3551 13.7375 17.3751 13.7375C18.3951 13.7375 19.2239 12.9088 19.2239 11.8888C19.2239 10.8688 18.3939 10.04 17.3751 10.04Z"
      fill="white"
    />
  </svg>
);

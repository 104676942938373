import { Show } from 'solid-js';
import { Empty } from '~/components/common/Empty';
import { ChatProvider, ChatsListProvider, ChatsSettingsProvider } from '~/contexts/local';
import { ChatWindow as BaseChatWindow } from '~/pages/chats/chat-details/ChatWindow';
import type { ChatWindowProps } from '~/pages/chats/chat-details/ChatWindow';

export const ChatWindow = (props: Partial<ChatWindowProps>) => {
  return (
    <div class="overflow-hidden rounded-lg border border-partingline bg-white">
      <ChatsListProvider>
        <ChatsSettingsProvider>
          <ChatProvider>
            <Show when={props.participantId} fallback={<Empty />}>
              <BaseChatWindow
                participantId={props.participantId}
                chatId={props.chatId}
                participantType={props.participantType}
                class={props.class}
              />
            </Show>
          </ChatProvider>
        </ChatsSettingsProvider>
      </ChatsListProvider>
    </div>
  );
};

import { createMagicDoorContext } from '~/contexts/utils';
import { UnitRepository } from '~/repositories/unitRepository';
import { createMutation, createTriggerResource } from '~/utils/resource';
import type { UnitFilter, UnitImagePayload } from '~/repositories/unitRepository';

const repo = new UnitRepository();

export const [UnitsProvider, useUnits] = createMagicDoorContext('Units', () => {
  const [units, getUnits, unitsActions] = createTriggerResource((filter: UnitFilter) => repo.getUnits({ active: true, ...filter }));

  const [unit, getUnit, unitActions] = createTriggerResource((unitId: string) => repo.getUnit(unitId));

  const refetchUnit = createMutation(async (unitId: string) => {
    const isCurrent = unit()?.id === unitId;
    const inList = units()?.items.some((u) => u.id === unitId);
    if (!isCurrent && !inList) return;

    const hydrated = await repo.getUnit(unitId);
    isCurrent && unitActions.mutate(hydrated);
    inList && unitsActions.mutate((prev) => prev && { ...prev, items: prev.items.map((i) => (i.id === unitId ? hydrated : i)) });
  });

  const refetchUnitsIf = createMutation(async (condition?: (unit: MagicDoor.Api.HydratedUnitDto) => boolean | undefined) => {
    if (typeof condition !== 'function') return;
    const currentUnit = unit();
    if (currentUnit && condition(currentUnit)) {
      unitActions.refetch();
    }
    const currentPagination = units();
    if (currentPagination && currentPagination.items.some(condition)) {
      unitsActions.refetch();
    }
  });

  const addUnit = createMutation(async (payload: MagicDoor.Api.CreateUnitDto) => {
    const added = await repo.addUnit(payload);
    unitsActions.refetch();
    return added;
  });

  const updateUnit = createMutation(async (unitId: string, payload: MagicDoor.Api.UpdateUnitDto) => {
    const updated = await repo.updateUnit(unitId, payload);
    refetchUnit(unitId);
    return updated;
  });

  /** Not preferred. use deactivateUnit instead in most cases */
  const deleteUnit = createMutation(async (unitId: string) => {
    await repo.deleteUnit(unitId);
    if (unitId === unit()?.id) unitActions.mutate(undefined);
    unitsActions.refetch();
  });

  const deactivateUnit = createMutation(async (unitId: string, forceEndLease?: boolean) => {
    await repo.deactivateUnit(unitId, forceEndLease);
    if (unitId === unit()?.id) unitActions.mutate((prev) => prev && { ...prev, active: false });
    unitsActions.refetch();
  });

  const addUnitImage = createMutation(async (unitId: string, payload: UnitImagePayload | UnitImagePayload[]) => {
    const images = Array.isArray(payload) ? payload : [payload];
    await Promise.all(images.map((image) => repo.addUnitImage(unitId, image)));
    return refetchUnit(unitId);
  });

  const deleteUnitImage = createMutation(async (unitId: string, imageId: string) => {
    await repo.deleteUnitImage(unitId, imageId);
    return refetchUnit(unitId);
  });

  const clearUnit = createMutation(async () => {
    unitActions.mutate(undefined);
  });

  return {
    units,
    getUnits,
    unit,
    getUnit,
    refetchUnit,
    refetchUnitsIf,
    addUnit,
    updateUnit,
    deleteUnit,
    deactivateUnit,
    addUnitImage,
    deleteUnitImage,
    clearUnit,
    unitActions,
  };
});

import dayjs from 'dayjs';
import { isEmptyData } from '~/utils/tool';
import type { BetterForm } from '~/components/common/BetterForm/type';

export const isRequired = (rules: BetterForm.Rule[] = []) => {
  const ruleIsRequired = rules.find((rule) => rule.required);
  if (ruleIsRequired) return ruleIsRequired;
  return false;
};

export const isControlledByYourself = (rules: BetterForm.Rule[] = []) => {
  return rules.some((rule) => rule.type === 'controlledByYourself');
};

export const isFalsy = (value: any) => {
  return value === undefined || value === null || value === '';
};

export const getErrorMessage = (value: any, rules: BetterForm.Rule[] = [], specificFieldPath?: string) => {
  const required = isRequired(rules);
  function getResponse(message: string, isError: boolean) {
    return {
      isError,
      message,
    };
  }
  if (required && isFalsy(value)) {
    return getResponse(required.message || '', true);
  }

  for (const rule of rules) {
    if (rule.validator) {
      const res = rule.validator(value, specificFieldPath);
      if (res === true || res === '') {
        continue;
      }
      return getResponse(typeof res === 'string' ? res : (rule.message as string), true);
    }
    if (!isFalsy(value)) {
      if (rule.regex && !rule.regex.test(value)) {
        return getResponse(rule.message as string, true);
      }
      if (rule.type === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        return getResponse(rule.message as string, true);
      }
      if (
        rule.type === 'phone' &&
        !/^\+1\s*(?:\(?\d{3}\)?[\s-]?)?\d{3}[\s-]?\d{4}$|^\d{10}$|^\d{3}[\s-]\d{3}[\s-]\d{4}$|^\(\d{3}\)\s?\d{3}[\s-]\d{4}$/.test(value)
      ) {
        return getResponse(rule.message as string, true);
      }
      if (rule.type === 'number') {
        if (isNaN(value)) {
          return getResponse(rule.message as string, true);
        }
      }
      if (rule.type === 'int') {
        if (!Number.isInteger(value)) {
          return getResponse(rule.message as string, true);
        }
      }
      if (rule.type === 'dateOfBirth') {
        const isDateOfBirthInvalid = dayjs().isBefore(dayjs(value)) || !/^\d{4}-\d{2}-\d{2}$/.test(value);
        if (isDateOfBirthInvalid) {
          return getResponse(rule.message as string, true);
        }
      }

      if (rule.type === 'ssn' && !/^(\d{3}-{0,1}\d{2}-{0,1}\d{4}|\d{9}|\d{2}-{0,1}\d{7})$/.test(value)) {
        return getResponse(rule.message as string, true);
      }

      if (rule.length && typeof rule.length === 'number' && value.length > rule.length) {
        return getResponse(rule.message as string, true);
      }
      if (rule.length && Array.isArray(rule.length) && (value.length < rule.length[0] || value.length > rule.length[1])) {
        return getResponse(rule.message as string, true);
      }
      if (rule.range && (value < rule.range[0] || value > rule.range[1])) {
        return getResponse(rule.message as string, true);
      }
    }
  }

  return getResponse('', false);
};

export const transFormNamePath = (name: BetterForm.NamePath = []) => {
  return Array.isArray(name) ? name : stringToNamePath(name);
};

export const namePathToString = (namePath: BetterForm.NamePath) => {
  return transFormNamePath(namePath).join('.');
};

export const stringToNamePath = (namePathStr: string) => {
  return namePathStr.split('.').map((key) => (/^\d+$/.test(key) ? Number(key) : key));
};

export const compareTwoNamePath = (namePath1: BetterForm.NamePath, namePath2: BetterForm.NamePath) => {
  const namePath1Arr = transFormNamePath(namePath1);
  const namePath2Arr = transFormNamePath(namePath2);
  if (namePath1Arr.length !== namePath2Arr.length) return false;
  for (let i = 0; i < namePath1Arr.length; i++) {
    if (namePath1Arr[i] !== namePath2Arr[i]) return false;
  }
  return true;
};

export const combineNamePaths = (...namePaths: BetterForm.NamePath[]): BetterForm.NamePath => {
  return namePaths.reduce((acc, cur) => {
    return (acc as (string | number)[]).concat(transFormNamePath(cur));
  }, []);
};

export const isFormStoreChanged = function <T extends Record<string, any>>(initData: T | undefined, store: BetterForm.FormStore) {
  if (!initData) return !isEmptyData(store);
  return Object.keys(store).some((key) => initData[key] !== store[key]);
};

import { useNavigate } from '@solidjs/router';
import { createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { LeasesListProvider, MaintenanceRequestsProvider, useChat } from '~/contexts/local';
import { setNewChatAdded } from '~/pages/chats/list-chats/list-components/chatUtils';
import { validatePhone } from '~/utils/validations';
import { AddNewCommunication } from './AddCommunication';
import type { ChatCreationResponse } from '~/repositories/chatRepository';

export const AddCommunicationModal = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const { createChat, createTextChat } = useChat();
  const [type, setType] = createSignal<`${MagicDoor.Api.EntityType}` | 'textMessage'>();
  const [targetId, setTarget] = createSignal<string>();
  const [textMessage, setTextMessage] = createStore<{ phone?: string; name?: string; subject?: string }>({});
  const [pending, setPending] = createSignal<boolean>(false);

  const handleClose = () => navigate('/communications/chats', { scroll: false, replace: true });

  const handleDone = async () => {
    const typeValue = type();
    if (typeValue == null) return;

    let chat: ChatCreationResponse | undefined;

    if (typeValue !== 'textMessage') {
      const entryId = targetId();
      if (entryId == null) return toast.error(t('Please select a {type}', { type: t(typeValue) }));
      setPending(true);
      chat = await createChat(typeValue, entryId);
      setPending(false);
    } else {
      if (textMessage.phone == null || textMessage.phone.length === 0) return toast.error(t('Please enter a phone number'));
      if (!validatePhone(textMessage.phone)) return toast.error(t('Phone must be a number and 10 characters'));
      setPending(true);
      chat = await createTextChat({
        participantName: textMessage.name,
        subject: textMessage.subject ?? textMessage.phone,
        phoneNumber: textMessage.phone as string,
      });
      setPending(false);
    }

    if (chat == null) return toast.error(t('Operation failed, please try again later'));
    setNewChatAdded(typeValue === 'textMessage' ? 'unknown' : typeValue);
    navigate(`/communications/chats/${chat.chatId}`, { replace: true });
  };

  return (
    <Modal
      visible
      loading={pending()}
      title={t('New communication')}
      doneText={t('Start conversation')}
      onCancel={handleClose}
      onDone={handleDone}
      confirmation={type() != null ? undefined : false}>
      <LeasesListProvider>
        <MaintenanceRequestsProvider>
          <AddNewCommunication onTypeChange={setType} onTargetChange={setTarget} onTextMessageChange={setTextMessage} />
        </MaintenanceRequestsProvider>
      </LeasesListProvider>
    </Modal>
  );
};

import { FormItem } from '~/components/common/BetterForm';
import { Section } from '~/components/common/FormSectionTitle';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
import { useNewRentalApplication } from '~/contexts/local';
import { RentalApplicationDocuments } from '~/pages/rental-applications/add-rental-application/Documents';

export const Identity = () => {
  const { t } = useLocalization();
  const { store, validFormItem } = useNewRentalApplication();
  return (
    <>
      <Section required border title={t('Income verification')}>
        <div class="flex flex-col gap-y-8">
          <FormItem
            placeholder={t('Social security number')}
            label={t('Social security number (ssn)')}
            formFieldName={['ssn']}
            component={LabeledTextInput}
            rules={[
              { required: true, message: t('Please input social security number') },
              { type: 'ssn', message: t('SSN is not valid') },
            ]}
            onInput={() => {
              validFormItem('ssn');
            }}
          />
          <RentalApplicationDocuments appId={store.applicationId} files={store.rentalApplication?.files} />
        </div>
      </Section>
    </>
  );
};

import { createSignal, mergeProps, createEffect, Show } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { Button } from '~/components/common/Buttons';
import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization, useEmails, useCompanies } from '~/contexts/global';
import { MessageInput } from '~/pages/announcements/add-announcement/components/MesssageInput';
import { cn } from '~/utils/classnames';
import { prefilledContent } from './DonnaViolation';
import { IconSend } from './Icons';
import type { Component, JSX } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm';
import type { SendEmailQuest } from '~/repositories/emailRepository.ts';

// TODO: TEMP SOLUTION WAITING BACKEND
// DONNA'S COMPANY ID: '1248155916383899648' (OURS IS 1192372134522703872)
const COMPANY_ID = '1248155916383899648';

export type SendEmailProps = {
  text?: string;
  type: 'owner' | 'lease' | 'tenant' | 'vendor';
  id: string | undefined | null;
  showModal?: boolean;
  modalTitle?: string | undefined;
  body?: string | undefined;
  subject?: string | undefined;
  class?: string;
  icon?: JSX.Element;
  onSendDone?: () => void;
  disabled?: boolean;
  isAssociationViolation?: boolean;
}

export const SendEmail: Component<SendEmailProps> = (props) => {
  const newProps = mergeProps({ showModal: false, isAssociationViolation: false }, props);
  const { t } = useLocalization();
  const { companies } = useCompanies();
  const [modalShow, setModalShow] = createSignal<boolean>(false);
  const [loading, setLoading] = createSignal<boolean>(false);
  const [params, setParams] = createSignal<SendEmailQuest>({
    subject: newProps.subject || '',
    body: newProps.body || '',
    attachments: undefined,
  });
  const form = useForm();
  const { sendVendorEmails, sendOwnersEmails, sendTenantsEmails, sendLeasesEmails } = useEmails();

  const [currentSubjectOptions, setCurrentSubjectOptions] = createSignal<Array<{ value: string; label: string }>>([]);
  const [selectedSubject, setSelectedSubject] = createSignal<string>('');

  const btnClass = () => cn('ml-auto flex w-full justify-center text-sm capitalize', newProps.class);

  const resetForm = () => {
    form.resetFields(['subject', 'body', 'SubjectSelection']);
    setSelectedSubject('');
    setParams({
      subject: '',
      body: '',
      attachments: undefined,
    });
  };

  const onHide = () => {
    resetForm();
    setModalShow(false);
  };

  const validations = {
    subject: [
      { required: true, message: t('Please enter') },
      { length: 150, message: t('{name} must be {length} characters or less', { name: t('Subject'), length: '150' }) },
    ],
    body: [
      { required: true, message: t('Please enter') },
      { length: 10000, message: t('{name} must be {length} characters or less', { name: t('Body'), length: '10000' }) },
      {
        validator: (value: string | number) => {
          const stringValue = String(value).trim();
          return stringValue.length >= 5 || t('Body must be at least 5 characters long');
        },
      },
    ],
  };

  const handleValidate = () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;
    const formStore = form.formStore;
    setParams({ ...params(), ...formStore });
    handleSubmit();
  };

  const handleClick = () => {
    if (newProps.showModal) {
      setModalShow(true);
    } else {
      handleSubmit();
    }
  };

  const handleFilesChange = (newFiles: File[]) => {
    if (newFiles.length) {
      setParams({ ...params(), attachments: newFiles });
    }
  };

  const handleSubmit = async () => {
    if (loading()) return;
    setLoading(true);
    let sendEmailApi;
    if (newProps.id) {
      switch (newProps.type) {
        case 'owner':
          sendEmailApi = sendOwnersEmails(newProps.id, params());
          break;
        case 'lease':
          sendEmailApi = sendLeasesEmails(newProps.id, params());
          break;
        case 'tenant':
          sendEmailApi = sendTenantsEmails(newProps.id, params());
          break;
        case 'vendor':
          sendEmailApi = sendVendorEmails(newProps.id, params());
          break;
        default:
          break;
      }
    }
    try {
      if (sendEmailApi) {
        await sendEmailApi;
        toast.success(t('Email has been sent successfully'));
        if (newProps.showModal) onHide();
        props?.onSendDone?.();
      } else {
        console.error(t('No email API selected'));
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      toast.error(`${t('Operation failed')}: ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

  const updateMessagesForSubject = (value: string) => {
    if (companies()?.id === COMPANY_ID && newProps.isAssociationViolation) {
      let newSubject = '';
      let newBody = '';

      if (value !== '') {
        const content = prefilledContent[newProps.type]?.[value];
        if (content) {
          newSubject = content.subject;
          newBody = content.body;
        }
      }

      form.setFieldsValue({
        subject: newSubject,
        body: newBody,
      });

      setParams((prev) => ({
        ...prev,
        subject: newSubject,
        body: newBody,
      }));
    }
  };

  createEffect(() => {
    if (companies()?.id === COMPANY_ID && newProps.isAssociationViolation) {
      const options = Object.entries(prefilledContent[newProps.type] || {}).map(([key, value]) => ({
        value: key,
        label: value?.subject || key,
      }));
      const emptyOption = { value: '', label: t('Select a custom script') };
      setCurrentSubjectOptions([emptyOption, ...options]);
      setSelectedSubject(emptyOption.value);
      updateMessagesForSubject(emptyOption.value);
    } else {
      form.setFieldsValue({ subject: newProps.subject, body: newProps.body });
      setParams((prev) => ({
        ...prev,
        subject: newProps.subject || '',
        body: newProps.body || '',
      }));
    }
  });

  return (
    <>
      <Button onClick={handleClick} class={btnClass()} disabled={newProps.disabled}>
        <Show when={!newProps.icon}>
          <IconSend class="size-5 text-white" />
        </Show>
        <Show when={newProps.icon}>{newProps.icon}</Show>
        <span class="capitalize">{newProps.text}</span>
      </Button>

      <Show when={newProps.id}>
        <Modal
          size="lg"
          loading={loading()}
          title={newProps.modalTitle || t('Send email')}
          onCancel={onHide}
          onDone={handleValidate}
          visible={modalShow()}>
          <Form defaultForm={form} class="mb-6 flex flex-col gap-2 p-6" onFormSubmit={handleValidate}>
            <Show
              when={companies()?.id === COMPANY_ID && newProps.isAssociationViolation}
              fallback={
                <FormItem
                  component={LabeledTextInput}
                  formFieldName="subject"
                  rules={validations.subject}
                  label={t('Subject')}
                  placeholder={t('Please enter')}
                />
              }>
              <div class="flex flex-col gap-2">
                <FormItem
                  label={t('Custom scripts')}
                  formFieldName="SubjectSelection"
                  component={LabeledSelect}
                  options={currentSubjectOptions()}
                  value={selectedSubject()}
                  customSyncValue={(formContext: BetterForm.form, realValue: any) => {
                    const value = realValue ?? '';
                    setSelectedSubject(value);
                    updateMessagesForSubject(value);
                  }}
                />
                <FormItem
                  component={LabeledTextInput}
                  formFieldName="subject"
                  rules={validations.subject}
                  label={t('Subject')}
                  placeholder={t('Please enter')}
                />
              </div>
            </Show>
            <FormItem
              component={MessageInput}
              formFieldName="body"
              label={t('Body')}
              placeholder={t('Please enter')}
              rows={4}
              type="email"
              minlength={5}
              maxlength={10000}
              onFilesChange={handleFilesChange}
              rules={validations.body}
            />
          </Form>
        </Modal>
      </Show>
    </>
  );
};
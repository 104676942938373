import { createMagicDoorContext } from '~/contexts/utils';
import { OwnersRepository } from '~/repositories/ownersRepository';
import { createTriggerResource, createMutation } from '~/utils/resource';
import type { OwnersTransfersFilter } from '~/repositories/ownersRepository';

export const [OwnerTransfersContextProvider, useOwnerTransfers] = createMagicDoorContext('OwnerTransfers', () => {
  const repo = new OwnersRepository();
  const [ownerTransfers, setFilter, actions] = createTriggerResource((filter: OwnersTransfersFilter) => repo.getOwnersTransfers(filter));
  const deleteOwnerTransfer = createMutation(async (id: string) => {
    await repo.deleteOwnerTransfers(id);
  });

  const addOwnerTransfer = createMutation(async (payload: MagicDoor.Api.TransferOwnerFundsDto) => {
    await repo.postOwnerTransfers(payload);
  });

  return {
    ownerTransfers,
    setFilter,
    refetch: actions.refetch,
    deleteOwnerTransfer,
    addOwnerTransfer,
  };
});

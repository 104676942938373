import { createSignal, splitProps, For, createEffect } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { IconClock } from '~/components/ui/Icons';
import { Popover } from '~/components/ui/Popover';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { debounce } from '~/utils/tool';
import type { Component, ComponentProps, Setter } from 'solid-js';
import type { Merge } from '~/utils/types';

export type TimePickerProps = Merge<
  ComponentProps<'input'>,
  {
    value?: string | undefined;
    onChange?: (value: string) => void;
  }
>;
type TimeScrollProps = {
  setOpen: Setter<boolean>;
};
const formatTimeStr = (str?: string) => {
  if (!str) return '';
  return str.replace(/[^0-9:]/g, '');
};

const TimePicker: Component<TimePickerProps> = (props) => {
  const { t } = useLocalization();
  const [time, setTime] = createSignal<string>('');
  const [params, rest] = splitProps(props, ['value', 'onChange', 'class']);
  const [hour, setHour] = createSignal<string>('00');
  const [minute, setMinute] = createSignal<string>('00');
  const [second, setSecond] = createSignal<string>('00');
  createEffect(() => {
    const timeVal = formatTimeStr(params.value);
    setTime(timeVal);
    const timeArr = timeVal && timeVal.split(':');
    if (timeArr?.length) {
      setHour(timeArr[0] + '' || '00');
      setMinute(timeArr[1] + '' || '00');
      setSecond(timeArr[2] + '' || '00');
    }
  });

  const formatTime = (time: number) => {
    return time < 10 ? '0' + time : time + '';
  };

  const TIME_REGEX = /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
  const HOURS = Array.from({ length: 24 }, (_, i) => formatTime(i));
  const MINUTE_SECOND = Array.from({ length: 60 }, (_, i) => formatTime(i));

  // eslint-disable-next-line solid/reactivity
  const handleChange = debounce((e: { target: HTMLInputElement }) => {
    e.target.value = e.target.value.replace(/[^0-9:]/g, '');
    const input = e.target.value;
    if (!input) {
      props.onChange?.('');
      return setTime('');
    }
    if (!TIME_REGEX.test(input)) return;
    setTime(input);
    props.onChange?.(input);
  }, 500);

  const handleNow = () => {
    const now = new Date();
    const hours = formatTime(now.getHours());
    const minutes = formatTime(now.getMinutes());
    const seconds = formatTime(now.getSeconds());
    const nowStr = `${hours}:${minutes}:${seconds}`;
    setTime(nowStr);
    props.onChange?.(nowStr);
  };

  const handleSave = () => {
    const time = `${hour()}:${minute()}:${second()}`;
    setTime(time);
    props.onChange?.(time);
  };

  const TimeScroll = (props: TimeScrollProps) => {
    return (
      <div class={cn('origin-[--transform-origin] animate-zoom-in rounded-md border bg-white', params.class)}>
        <div class="mt-1 flex justify-between overflow-hidden p-1">
          <ul class="max-h-64 overflow-scroll">
            <For each={HOURS}>
              {(hourItem) => (
                <li
                  class="cursor-pointer px-4 hover:bg-essential-colour/70 hover:text-white"
                  classList={{ 'bg-essential-colour text-white': hour() === hourItem }}
                  onClick={() => {
                    setHour(hourItem);
                  }}>
                  {hourItem}
                </li>
              )}
            </For>
          </ul>
          <ul class="max-h-64 overflow-scroll">
            <For each={MINUTE_SECOND}>
              {(minuteItem) => (
                <li
                  class="cursor-pointer px-4 hover:bg-essential-colour/70 hover:text-white"
                  classList={{ 'bg-essential-colour text-white': minute() === minuteItem }}
                  onClick={() => setMinute(minuteItem)}>
                  {minuteItem}
                </li>
              )}
            </For>
          </ul>
          <ul class="max-h-64 overflow-scroll">
            <For each={MINUTE_SECOND}>
              {(secondItem) => (
                <li
                  class="cursor-pointer px-4 hover:bg-essential-colour/70 hover:text-white"
                  classList={{ 'bg-essential-colour text-white': second() === secondItem }}
                  onClick={() => setSecond(secondItem)}>
                  {secondItem}
                </li>
              )}
            </For>
          </ul>
        </div>
        <div class="mt-1 flex justify-between border-t p-2">
          <Button
            size="xs"
            class="rounded-lg border border-essential-colour bg-white text-base leading-5 text-essential-colour hover:bg-essential-colour/10"
            onClick={() => {
              handleNow();
              props.setOpen?.(false);
            }}>
            {t('Now')}
          </Button>
          <Button
            size="xs"
            onClick={() => {
              handleSave();
              props.setOpen?.(false);
            }}>
            {t('Save')}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Popover
      class="relative"
      onOutsideClick={(e: MouseEvent) => (e.currentTarget as Node).contains(e.relatedTarget as Node) && e.preventDefault()}>
      {({ setOpen }) => (
        <>
          <input
            type="tel"
            placeholder={t('HH:MM:SS')}
            value={time()}
            {...rest}
            onFocus={() => setOpen(true)}
            onBlur={(e) => {
              e.target.value = time();
            }}
            onInput={handleChange}
            class={cn(
              'w-full rounded-md border bg-inputbox-bg px-3 py-2 text-sm text-black outline-none placeholder:text-auxiliary-text focus:ring-1 focus:ring-essential-colour',
              params.class
            )}
          />
          <IconClock
            class="absolute inset-y-0 right-0 mx-3 my-2 size-5 cursor-pointer text-text-level02"
            onClick={() => {
              !props.disabled && setOpen((prev) => !prev);
            }}
          />
          <Popover.Content align="start" class="my-1 rounded-md border bg-white">
            <TimeScroll setOpen={setOpen} />
          </Popover.Content>
        </>
      )}
    </Popover>
  );
};

export { TimePicker };

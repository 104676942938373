import { UseCase } from './useCase';
import type { AnnotatedPdfPage } from './types/annotatedPdfPage';
import type { Annotation } from './types/annotation';
import type { AnnotationSelectionRequest } from './types/annotationSelectionRequest';

export class SelectAnnotationUseCase extends UseCase {
  public async runLogic(request: AnnotationSelectionRequest) {
    if (this.isPageWithingRange(request.page) || !this.isPdfLoaded()) {
      return;
    }
    const annotation = this.findAnnotationWithIdOnPage(request.id, this.state.pdfs.pdfPages[request.page]);
    this.state.selectedAnnotation = annotation;
  }

  private findAnnotationWithIdOnPage(id: string, page: AnnotatedPdfPage): Annotation | undefined {
    const annotations = page.annotations.filter((annotation) => {
      return annotation.id === id;
    });
    if (annotations && annotations.length > 0) {
      return annotations[0];
    }
    return undefined;
  }
}

import * as Sentry from '@sentry/browser';
import { Router } from '@solidjs/router';
import { DEV } from 'solid-js';
import { render } from 'solid-js/web';
import './index.css';
import App from './App';
import { GlobalProviders } from './contexts/global';

if (!DEV) {
  Sentry.init({
    dsn: 'https://73479263eeab7c9ca5e6e51768cbf916@sentry.magicdoor.io/2',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = document.getElementById('root');

if (import.meta.env.DEV && !(root instanceof HTMLElement)) {
  throw new Error('Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got mispelled?');
}

render(
  () => (
    <Router>
      <GlobalProviders>
        <App />
      </GlobalProviders>
    </Router>
  ),
  root!
);

import { useParams } from '@solidjs/router';
import { createSignal } from 'solid-js';
import { LeaseSearch } from '~/components/leases';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization, useWorkOrders } from '~/contexts/global';
import { useCreateModel } from '~/utils/hooks';
import type { Component, Setter } from 'solid-js';

type BindingLeaseModalProps = {
  visible?: Setter<boolean>[];
  workOrderId?: string;
  onSuccess?: () => void;
};

export const BindingLeaseModal: Component<BindingLeaseModalProps> = (props) => {
  const { t } = useLocalization();
  const params = useParams();
  const [visible, setVisible] = useCreateModel(props, 'visible', false);
  const [newLeaseId, setNewLeaseId] = createSignal<string>('');
  const [loading, setLoading] = createSignal<boolean>(false);
  const { updateLease } = useWorkOrders();

  const handleClose = () => {
    setVisible(false);
  };
  const handleDone = async () => {
    setLoading(true);
    const workOrderId = params?.workOrderId || props.workOrderId;
    try {
      await updateLease(workOrderId as string, {
        newLeaseId: newLeaseId(),
      });
      toast.success(t('Operation successful'));
      props.onSuccess && props.onSuccess();
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={t('Binding lease')}
      loading={loading()}
      visible={visible()}
      confirmation={false}
      onCancel={handleClose}
      onDone={handleDone}>
      <div class="p-6">
        <div class="mb-2 text-xs uppercase text-text-level02">{t('Select lease')}</div>
        <LeaseSearch onSelect={(v) => setNewLeaseId(v.id)} />
      </div>
    </Modal>
  );
};

import { createMagicDoorContext } from '~/contexts/utils';
import { DepositSlipsRepository } from '~/repositories/depositSlipsRepository';
import { createTriggerResource } from '~/utils/resource';
import type { DepositSlipsFilter, TransactionsFilter } from '~/repositories/depositSlipsRepository';

const repo = new DepositSlipsRepository();
export const [DepositSlipsProvider, useDepositSlips] = createMagicDoorContext('DepositSlips', () => {
  const [filtered, setFilter, actions] = createTriggerResource((filter?: DepositSlipsFilter) => repo.getDepositSlips(filter));

  const addDepositSlip = async (payload: MagicDoor.Api.CreateDepositSlipsRequestDto) => {
    await repo.addDepositSlip(payload);
  };

  const getTransactions = async (filter?: TransactionsFilter) => {
    return await repo.getTransactions(filter);
  };

  const getDepositSlip = async (depositSlipId: string) => {
    return await repo.getDepositSlip(depositSlipId);
  };

  return {
    filtered: filtered,
    setFilter: (filter: DepositSlipsFilter) => setFilter(filter),
    refetch: actions.refetch,
    addDepositSlip,
    getTransactions,
    getDepositSlip,
  };
});

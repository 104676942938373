import { onMount } from 'solid-js';
import { createStore } from 'solid-js/store';
import { ManagementFeesRepository } from '~/repositories/managementFeesRepository';
import { PropertiesRepository } from '~/repositories/propertiesRepository';
import type { Option } from '~/components/common/DropdownMenu';

export interface ManagementFeeStore {
  submitLoading?: boolean;
  properties?: MagicDoor.Api.PropertyDto[];
  propertiesLoading?: boolean;
  managementFees?: MagicDoor.Api.ManagementFeesResultDto['properties'];
  managementFeesLoading?: boolean;
}

export const dateTimeRangeOptions: Option[] = [
  { label: 'End Of Last Month', value: 'endOfLastMonth' },
  { label: 'Now', value: 'now' },
];

const propertiesRepo = new PropertiesRepository();
const managementFeesRepo = new ManagementFeesRepository();

const [store, setStore] = createStore<ManagementFeeStore>({});

const getProperties = async () => {
  setStore('propertiesLoading', true);
  const response = await propertiesRepo.getAllProperties();
  setStore('properties', response);
  setStore('propertiesLoading', false);
};

const getManagementFees = async (payload: MagicDoor.Api.CalculateManagementFeesDto) => {
  setStore('managementFeesLoading', true);
  const response = await managementFeesRepo.calculateManagementFees(payload);
  setStore('managementFees', response.properties);
  setStore('managementFeesLoading', false);
  return response.calculateFeesUntil;
};

onMount(() => {
  getProperties();
});

export default {
  store,
  getManagementFees,
};

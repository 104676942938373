import { A, useSearchParams } from '@solidjs/router';
import dayjs from 'dayjs';
import { For, Show } from 'solid-js';
import NoSearchData from '~/assets/images/empty/noSearchData.svg';
import { Empty } from '~/components/common/Empty';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { commaNumber } from '~/utils/number';
import reportStyles from './report.css?inline';
import type { JSX } from 'solid-js';
import type { ReportGroupHeader } from '~/swagger/Api';
export enum DataType {
  Number = 'number',
  String = 'string',
  Date = 'date',
}

export type Column<T = LineItem> = {
  label: string;
  type: 'number' | 'string' | 'date';
  render: (item: T) => JSX.Element;
  style?: string;
};

export type LineItem =
  | {
      type?: 'item' | 'total';
      label: JSX.Element;
      level: number;
      values?: Record<string, string | number | null | undefined>;
      metadata?: Record<string, unknown>;
    }
  | { type: 'empty'; label?: never; level?: never; values?: never; metadata?: never };

export const ReportTable = <T extends LineItem>(props: { label: JSX.Element; columns: Column<T>[]; lines: T[]; class?: string }) => {
  const { t } = useLocalization();
  return (
    <div class={cn('thinscroll overflow-auto pb-2 text-sm text-title-gray', props.class)}>
      <style>{reportStyles}</style>
      <Show when={props.lines && props.lines.length}>
        <table data-slot="report">
          <thead data-slot="head">
            <tr>
              <th data-slot="label">{props.label}</th>
              <For each={props.columns}>
                {(col) => (
                  <th data-slot="column" data-type={col.type} style={col.style}>
                    {col.label}
                  </th>
                )}
              </For>
            </tr>
          </thead>
          <Show when={props.lines && props.lines.length}>
            <tbody data-slot="body">
              <For each={props.lines}>
                {(line) =>
                  line.type === 'empty' ? (
                    <tr data-slot="line" data-type={line.type}>
                      <td data-slot="label">&nbsp;</td>
                      <For each={props.columns}>{(col) => <td style={col.style} />}</For>
                    </tr>
                  ) : (
                    <tr data-slot="line" data-type={line.type} data-level={line.level} style={{ '--level': line.level }}>
                      <td data-slot="label">{line.label}</td>
                      <For each={props.columns}>
                        {(col) => (
                          <td data-slot="value" data-type={col.type} style={col.style}>
                            {col.render(line)}
                          </td>
                        )}
                      </For>
                    </tr>
                  )
                }
              </For>
            </tbody>
          </Show>
        </table>
      </Show>

      <Show when={!props.lines || !props.lines.length}>
        <div class="flex w-full items-center justify-center">
          <Empty imgSrc={NoSearchData} description={t('No data yet')} />
        </div>
      </Show>
    </div>
  );
};

const isNullOrEmpty = (input: Record<string, number | null> | undefined) => input == null || Object.keys(input).length === 0;

export const parseLines = (lines?: MagicDoor.Api.ChartOfAccountReportLineDto[], level = 1): LineItem[] => {
  const { t } = useLocalization();
  if (lines == null || lines.length == 0) return [];

  return lines.flatMap<LineItem>((line) => {
    const label = line.chartOfAccount?.name as string;
    const base = {
      level,
      values: isNullOrEmpty(line.groups) ? undefined : line.groups,
      metadata: { chartOfAccountId: line.chartOfAccount?.id },
    };
    if (line.groupTotal == null) return { label: t(label), ...base };
    const upperLabel = t(label).toUpperCase();
    return [
      { label: upperLabel, ...base },
      ...parseLines(line.children, level + 1),
      {
        label: t(`Total {type}`, { type: upperLabel }),
        type: 'total',
        level,
        values: isNullOrEmpty(line.groupTotal) ? undefined : line.groupTotal,
      },
    ];
  });
};

function formatValueByType(_text: string | undefined | null | number, columnType: Column['type'], lineItemType: LineItem['type']) {
  if (columnType === 'date') return dayjs(_text).format('MM/DD/YYYY');
  if (columnType === 'number') return _text ? commaNumber(_text) : _text;

  return _text;
}

export const parseColumns = (headers: Array<ReportGroupHeader & Partial<Pick<Column, 'type'>>> | undefined): Column[] => {
  if (headers == null) return [];
  const { t } = useLocalization();
  const [searchParams] = useSearchParams();

  return headers.map((h) => {
    const type = h.type ?? 'number';
    return {
      label: t(h.name as string),
      type,
      render: (item: LineItem) => (
        <Show when={item.values}>
          {(values) => {
            const href = () => {
              if (typeof item.metadata?.chartOfAccountId !== 'string') return;
              const query = new URLSearchParams(searchParams);
              query.set('chartOfAccountIds', item.metadata.chartOfAccountId);
              return `/reports/general-ledger?${query}`;
            };

            const _text = formatValueByType(values()[h.id as string], type, item.type);

            return (
              <Show when={href()} fallback={_text}>
                {(href) => (
                  <A class="text-link hover:underline" href={href()}>
                    {_text}
                  </A>
                )}
              </Show>
            );
          }}
        </Show>
      ),
    };
  });
};

import { useSearchParams } from '@solidjs/router';
import { createMemo } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { MaintenanceRequestListView } from './list-components/MaintenanceRequestListView';
import { MaintenanceRequestStatusFilterTabs } from './list-components/MaintenanceRequestStatusFilterTabs';
import { statusFilters } from './list-components/statusFilters';

export const ListMaintenanceRequestPage = () => {
  const { t } = useLocalization();
  const [searchParams] = useSearchParams();

  const currentStatusKey = createMemo(() => {
    const valid: Record<keyof MagicDoor.Api.MaintenanceRequestsStatsDto, true> = {
      newRequests: true,
      inProgress: true,
      pending: true,
      closed: true,
      aiProcessing: true,
      cacheDate: true,
      waitingForWorkOrder: true,
    };
    const status = searchParams.status as keyof MagicDoor.Api.MaintenanceRequestsStatsDto;
    return valid[status] ? status : 'newRequests';
  });

  const currentStatus = createMemo(() => statusFilters.find((s) => s.key === currentStatusKey()) ?? statusFilters[0]);

  return (
    <div class="flex flex-col gap-5 p-8">
      <MaintenanceRequestStatusFilterTabs current={currentStatusKey()} />
      <MaintenanceRequestListView filterStatus={currentStatus().key} title={t(currentStatus().label)} filter={currentStatus().filter} />
    </div>
  );
};

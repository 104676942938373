import { createEffect, createMemo, createSignal, Show } from 'solid-js';
import { useForm } from '~/components/common/BetterForm';
import { FormWrapper } from '~/components/common/BetterForm/Form';
import CallToActionButton from '~/components/common/Buttons/CallToActionButton';
import {
  CategoryComponentSection,
  CategoryComponentWrapperProvider,
  CategoryContentSection,
  getErrorAndProgressProps,
} from '~/components/common/Category';
import { RingLoader } from '~/components/common/Loaders';
import CancelWithConfirmation from '~/components/modals/CancelWithConfirmation';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { isEmptyData } from '~/utils/tool';
import RunbookInformationSection from './RunbookInformationSection';
import type { JSX, Setter } from 'solid-js';
import type { Section } from '~/components/common/Category';

interface Props {
  onClose: () => void;
  setShowCloseConfirmation?: Setter<boolean>;
  showCloseConfirmation?: boolean;
  isEdit: boolean;
}

type AddSection = {
  key: string;
  component: (props: any) => JSX.Element;
  name: string;
  ref?: HTMLElement | null;
  required?: boolean;
} & Omit<Section, 'component'>;

export const AddAndEditRunbook = (props: Props) => {
  const { t } = useLocalization();
  const { addRunBook, runBooksActions, runBook, updateRunBook } = useRunBooks();

  const form = useForm();

  const [activeCategory, setActiveCategory] = createSignal<string>('runbookInformation');
  const [expandedAdditional, setExpandedAdditional] = createSignal<boolean>(false);
  const [isLoading, setIsLoading] = createSignal<boolean>(false);

  const sections = createMemo<AddSection[]>(() => [
    {
      key: 'runbookInformation',
      component: RunbookInformationSection,
      name: t('Runbook information'),
      required: true,
      fields: props.isEdit ? ['title', 'description', 'script'] : ['title', 'description', 'script', 'vendorId'],
    },
  ]);

  let scrollContainer!: HTMLDivElement;

  const submit = async () => {
    setIsLoading(true);
    try {
      if (props.isEdit) {
        await updateRunBook(runBook()?.id as string, form.formStore as MagicDoor.Api.UpdateRunBookDto);
        toast.success(t('{name} has been edited successfully', { name: t('AI runbooks') }));
      } else {
        await addRunBook(form.formStore as MagicDoor.Api.CreateRunBookDto);
        toast.success(t('{name} has been added successfully', { name: t('AI runbooks') }));
      }

      runBooksActions.refetch();
      props.onClose();
    } finally {
      setIsLoading(false);
    }
  };

  createEffect(() => {
    props.setShowCloseConfirmation?.(!isEmptyData(form.formStore));
  });

  createEffect(() => {
    if (props.isEdit && runBook()) {
      form.setFieldsValue({
        title: runBook()?.title,
        description: runBook()?.description,
        script: runBook()?.script,
      });
    }
  });

  return (
    <FormWrapper class="relative flex flex-col text-sm" defaultForm={form} onFormSubmit={submit}>
      <CategoryComponentWrapperProvider
        onActiveCategoryChange={(key, evt) => {
          if (key !== 'runbookInformation' && evt === 'click') setExpandedAdditional(true);
          if (evt === 'scroll' && !expandedAdditional()) return setActiveCategory('runbookInformation');
          setActiveCategory(key);
        }}
        sections={sections()}
        getScrollElement={() => scrollContainer}
        activeCategory={activeCategory()}>
        <div class="relative flex flex-1 items-start">
          <div class="sticky top-0 hidden h-full w-1/3 bg-inputbox-bg text-text-level03 xs:block">
            <div class="flex flex-col gap-2">
              <CategoryComponentSection
                headerTitle={
                  <div class="py-3 pl-5 text-lg font-semibold text-text-level01">
                    {props.isEdit ? t('Update custom runbook') : t('Create custom runbook')}
                  </div>
                }
                class="min-w-0 bg-transparent"
                {...getErrorAndProgressProps(form)}
              />
            </div>
          </div>

          <CategoryContentSection
            renderMethod={({ setContentRef }) => {
              return (
                <div ref={scrollContainer} class="thinscroll flex h-[480px] flex-1 flex-col overflow-auto px-8">
                  <div
                    ref={(ref) => {
                      setContentRef('runbookInformation', ref);
                    }}>
                    <Show when={props.isEdit && runBook.loading} fallback={<RunbookInformationSection isEdit={props.isEdit} />}>
                      <RingLoader color="#a126ec" class="mt-48" />
                    </Show>
                  </div>
                </div>
              );
            }}
          />
        </div>

        <div class="z-20 flex w-full items-center justify-between border-t bg-white p-6">
          <div class="w-2/4 text-left text-xs text-text-level03">{t('You may modify these information later')}</div>
          <div class="flex w-2/4 justify-end gap-2">
            <CancelWithConfirmation
              enabled={props.showCloseConfirmation}
              onConfirm={props.onClose}
              buttonsDisabled={isLoading()}
              message={t('Are you sure you want to cancel? The changes will not be saved.')}
            />
            <CallToActionButton
              type="submit"
              loading={isLoading()}
              tooltipMessage={t('Please fill out all the required fields that are in red')}>
              {t('Submit')}
            </CallToActionButton>
          </div>
        </div>
      </CategoryComponentWrapperProvider>
    </FormWrapper>
  );
};

import { BaseRestRepository } from './baseRestRepository';

const ME_URL = '/api/me';

export class MeRepository extends BaseRestRepository {
  public async getProfile(): Promise<MagicDoor.Api.PropertyManagerDto> {
    const response = await this.fetchWithAuth(ME_URL);
    return await this.getJsonResponse(response);
  }

  public async updateProfile(model: MagicDoor.Api.UpdatePropertyManagerDto): Promise<MagicDoor.Api.PropertyManagerDto> {
    const response = await this.fetchWithAuth(ME_URL, {
      method: 'PUT',
      body: JSON.stringify(model),
    });
    return await this.getJsonResponse(response);
  }

  public async updateProfileImage(image: File): Promise<MagicDoor.Api.PropertyManagerDto> {
    const formData = new FormData();
    formData.append('ImageFile', image);
    const response = await this.fetchWithAuth(ME_URL + '/image', {
      method: 'POST',
      headers: { 'content-type': 'multipart/form-data' },
      body: formData,
    });
    return await this.getJsonResponse(response);
  }

  public async changePassword(model: MagicDoor.Api.UpdatePropertyManagerPasswordDto): Promise<MagicDoor.Api.PropertyManagerDto> {
    const response = await this.fetchWithAuth(ME_URL + '/password', {
      method: 'PUT',
      body: JSON.stringify(model),
    });
    return await this.getJsonResponse(response);
  }
}

import { getOwner } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm';
import { useCategoryComponentWrapper } from '~/components/common/Category';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import LabeledAddressInput from '~/components/common/Inputs/form-inputs/LabeledAddressInput';
import { useLocalization } from '~/contexts/global';
import { addressFormat } from '~/utils/formRules';

const ContactsSection = () => {
  const { onClickSection } = useCategoryComponentWrapper();

  const { t } = useLocalization();
  const owner = getOwner();

  return (
    <div id="contacts">
      <div class="sticky top-0 z-10 border-b bg-white py-3 text-lg font-semibold text-text-level01">{t('Contacts')}</div>
      <div class="flex flex-col gap-y-5 py-6">
        <div class="flex gap-5">
          <div class="w-1/2">
            <FormItem
              label={t('Phone')}
              placeholder={t('Enter phone number')}
              onClick={() => onClickSection('contacts')}
              component={LabeledTextInput}
              rules={[{ type: 'phone', message: t(`Phone is invalid`) }]}
              formFieldName={['contact', 'phone']}
            />
          </div>
          <div class="w-1/2">
            <FormItem
              label={t('Email')}
              placeholder={t('Enter email')}
              onClick={() => onClickSection('contacts')}
              component={LabeledTextInput}
              rules={[{ type: 'email', message: t(`Email is not valid`) }]}
              formFieldName={['contact', 'email']}
            />
          </div>
        </div>

        <FormItem
          rules={[addressFormat(owner, t)]}
          onClick={() => onClickSection('contacts')}
          component={LabeledAddressInput}
          formFieldName={['address']}
        />

        <FormItem
          label={t('Website')}
          placeholder="Enter website"
          onClick={() => onClickSection('contacts')}
          component={LabeledTextInput}
          formFieldName={['website']}
        />
      </div>
    </div>
  );
};

export default ContactsSection;

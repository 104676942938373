import { createSignal, onMount, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconTextMessages from '~/assets/images/settings/property/propertyTextMessages.svg';
import { Button } from '~/components/common/Buttons';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { confirm, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequestSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import Overrides from '~/pages/settings/components/Overrides';
import { AddOverridesModal } from '~/pages/settings/components/add-overrides-modal';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';

export const MaintenanceRequestSetting = () => {
  const { t } = useLocalization();

  const {
    isLoading,
    addMaintenanceRequestSettings,
    addMaintenanceRequestSettingsByLevel,
    getAllMaintenanceRequestSettings,
    allMaintenanceRequestSettings,
    deleteMaintenanceRequestSettingsByLevel,
  } = useMaintenanceRequestSettings();

  const [showModal, setShowModal] = createSignal<boolean>(false);

  onMount(async () => {
    await getAllMaintenanceRequestSettings();
  });

  const onDone = async (level: string, levelId: string) => {
    await addMaintenanceRequestSettingsByLevel(level, levelId, {
      ...(allMaintenanceRequestSettings()?.settings as MagicDoor.Api.MaintenanceRequestSettingsDto),
    });
    setShowModal(false);
    getAllMaintenanceRequestSettings();
    toast(t('Maintenance request settings saved successfully'), 'success');
  };

  const handleSave = async (data: MagicDoor.Api.MaintenanceRequestSettingsDto, level?: string, levelId?: string) => {
    if (!level || !levelId) {
      await addMaintenanceRequestSettings(data);
      toast(t('Maintenance request settings saved successfully'), 'success');
      getAllMaintenanceRequestSettings();
    } else {
      await addMaintenanceRequestSettingsByLevel(level, levelId, data);
      toast(t('Maintenance request settings saved successfully'), 'success');
      getAllMaintenanceRequestSettings();
    }
  };

  const onDelete = async (level: string, levelId: string) => {
    confirm({
      content: t('Are you sure you want to delete this override?'),
      title: t(`Delete override`),
      async onResolve(confirmed) {
        if (!confirmed) return;
        await deleteMaintenanceRequestSettingsByLevel(level, levelId);
        toast(t('Maintenance request settings deleted successfully'), 'success');
        getAllMaintenanceRequestSettings();
      },
    });
  };

  const onClose = () => {
    setShowModal(false);
  };

  const sections = [
    {
      field: 'onlyAllowRequesterToEdit',
      label: 'Only allow requester to edit',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass: 'flex-row justify-between',
      description: 'Allow tenants to update the maintenance request description after it has been submitted.',
    },
  ];

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topAction={
          <Button variant="white" class="rounded-3xl px-3 py-1 text-sm" onClick={() => setShowModal(true)}>
            <IconPlus />
            <div class="hidden font-medium md:block">{t('Add new overrides')}</div>
          </Button>
        }
        topTitle={<SettingTitle title={t('Maintenance request')} icon={IconTextMessages} />}
        middle={
          <div class="flex flex-col gap-5 p-4">
            <div class="flex flex-col gap-4 p-2 text-left">
              <EditForm<MagicDoor.Api.MaintenanceRequestSettingsDto>
                sections={sections}
                onOk={(data) => handleSave(data)}
                model={allMaintenanceRequestSettings()?.settings || ({} as MagicDoor.Api.MaintenanceRequestSettingsDto)}
              />
            </div>

            <Overrides<MagicDoor.Api.MaintenanceRequestSettingsDtoSettingsWithOverridesDto>
              overrides={allMaintenanceRequestSettings()?.overrides || []}
              onDelete={onDelete}
              title={t('Maintenance request overwrites')}>
              {(override) => {
                return (
                  <div class="flex flex-col gap-4 p-2 text-left">
                    <EditForm<MagicDoor.Api.MaintenanceRequestSettingsDto>
                      sections={sections}
                      onOk={(data) => handleSave(data, override.level, override.levelId)}
                      model={override.settings || ({} as MagicDoor.Api.MaintenanceRequestSettingsDto)}
                    />
                  </div>
                );
              }}
            </Overrides>
          </div>
        }
      />
      <Show when={showModal()}>
        <AddOverridesModal
          overrides={allMaintenanceRequestSettings()?.overrides || []}
          onClose={onClose}
          onDone={onDone}
          loading={isLoading()}
        />
      </Show>
    </>
  );
};

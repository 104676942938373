import { splitProps } from 'solid-js';
import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import { ImageUploader } from '~/components/common/Upload/ImageUploader';
import type { TextAreaProps } from '~/components/common/Inputs/LabeledTextArea';
import type { ImageUploaderProps } from '~/components/common/Upload/ImageUploader';

export const Description = (props: Omit<TextAreaProps, 'extraItem'> & ImageUploaderProps) => {
  const [uploadProps, restProps] = splitProps(props, ['defaultFileList', 'entityFilePrefixURL', 'onFilesListChange', 'uploaderRef']);
  return (
    <div class="flex flex-col gap-2 divide-y">
      <LabeledTextArea
        rows={5}
        {...restProps}
        extraItem={() => (
          <div class="border-t border-t-[#e4e4e4] pt-2">
            <ImageUploader {...uploadProps} />
          </div>
        )}
      />
    </div>
  );
};

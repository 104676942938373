import { For, createSignal, createMemo, Show, onMount } from 'solid-js';
import IconTempUnit from '~/assets/images/common/tempUnit.png';
import { TitleWithFeedbackIcon } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import { LabeledInputSearch } from '~/components/common/Inputs/LabeledInputSearch';
import { LeasePeriod } from '~/components/leases/LeasePeriod';
import { Modal } from '~/components/modals/Modal';
import { PortfolioTitle } from '~/components/portfolios';
import { PropertyTitle } from '~/components/properties';
import { formatTenantNames } from '~/components/tenants/utils';
import { Image } from '~/components/ui';
import { UnitTitle } from '~/components/units';
import { useLocalization, useTenants, useProperties } from '~/contexts/global';
import { useOverrideContext } from '~/pages/settings/Context';
import { IconLeaseColored, IconPortfolioColored, IconPropertyColored, IconUnitColored } from '~/pages/settings/components/Icons';
import ImgAddOverride from './add-override-modal.svg';
import type { JSX } from 'solid-js';
import type { AddOverridePortfolio, LeaseWithName } from '~/pages/settings/Context';

export interface AddOverridesProps {
  onClose: () => void;
  onDone: (level: string, levelId: string) => void;
  loading?: boolean;
  overrides: any[];
}

export const AddOverridesModal = (props: AddOverridesProps) => {
  const { t } = useLocalization();
  const [selectLevel, setLevel] = createSignal('');
  const { store } = useOverrideContext();
  const [selectLevelId, setSelectLevelId] = createSignal<string>();
  const { filtered, setFilter } = useTenants();
  const { properties } = useProperties();

  onMount(() => setFilter({}));

  const levels = [
    { icon: IconPortfolioColored, label: 'Portfolios', value: 'portfolio' },
    { icon: IconPropertyColored, label: 'Property', value: 'property' },
    { icon: IconUnitColored, label: 'Unit', value: 'unit' },
    { icon: IconLeaseColored, label: 'Lease', value: 'lease' },
  ];

  const handleClickLevel = (level: string) => {
    setLevel(level);
    setSelectLevelId('');
  };

  const getFilterData = (data: any[]) => {
    return data.filter((item) => !props.overrides?.find((override) => override.levelId === item.id));
  };

  const options = createMemo(() => {
    switch (selectLevel()) {
      case 'portfolio':
        return getFilterData(store.portfolios);
      case 'property':
        return getFilterData(store.properties);
      case 'unit':
        return getFilterData(store.units);
      case 'lease':
        return getFilterData(store.leases);
      default:
        return [];
    }
  });

  const renderItem = createMemo(() => {
    switch (selectLevel()) {
      case 'portfolio':
        return (props: AddOverridePortfolio) => <PortfolioTitle feedbackIconReadonly portfolio={props} />;
      case 'property':
        return (props: MagicDoor.Api.PropertyDto) => <PropertyTitle feedbackIconReadonly property={props} />;
      case 'unit':
        return (props: MagicDoor.Api.HydratedUnitDto) => (
          <UnitTitle feedbackIconReadonly unit={props} property={properties()?.find((p) => p.id === props.propertyId)} />
        );
      case 'lease':
        return (props: LeaseWithName) => {
          const currentTenant = createMemo(() =>
            props.tenants.map((tenant: any) => {
              return {
                tenant: filtered()?.items?.find((t) => tenant?.tenantId === t.id) || {},
              };
            })
          );
          return (
            <TitleWithFeedbackIcon feedbackIconReadonly type="leases" initalSelectedIcons={props.icons}>
              <div class="flex items-center gap-2">
                <Image src={IconTempUnit} class="size-11 rounded-lg" />
                <div>
                  <div class="text-sm font-medium text-text-level01">{props?.name}</div>
                  <div class="flex items-center">
                    <LeasePeriod lease={props} class="text-xs" />
                    <Show when={currentTenant()}>
                      <div class="ml-1 whitespace-nowrap text-xs text-text-level03">
                        | {t('Tenants')}: {formatTenantNames(currentTenant())}
                      </div>
                    </Show>
                  </div>
                </div>
              </div>
            </TitleWithFeedbackIcon>
          );
        };
      default:
        return undefined;
    }
  });

  const onSelect = (id: string) => {
    setSelectLevelId(id);
  };

  return (
    <Modal
      class="min-w-[766px]"
      visible
      title={t('Add new override')}
      doneText={t('Save')}
      onCancel={props.onClose}
      onDone={() => props.onDone(selectLevel(), selectLevelId() || '')}
      loading={props.loading}>
      <img src={ImgAddOverride} />
      <div class="mt-6 px-8 pb-8">
        <div class="grid grid-cols-4 gap-2">
          <For each={levels}>
            {(level) => (
              <div
                class="mb-4 flex cursor-pointer items-center rounded-lg border border-auxiliary-text p-3 transition-all hover:border-essential-colour hover:bg-light-pink"
                classList={{
                  'bg-[#FBF1FF]': level.value === selectLevel(),
                  'border-essential-colour': level.value === selectLevel(),
                  'text-essential-colour': level.value === selectLevel(),
                }}
                onClick={() => handleClickLevel(level.value)}>
                <div class="relative">
                  <level.icon />
                  <i class="absolute inset-y-1 -right-2 block w-px bg-input-border" />
                </div>
                <span class="ml-6">{t(level.label)}</span>
              </div>
            )}
          </For>
        </div>
        <Show when={selectLevel()}>
          <div class="mt-5 uppercase text-text-level03">Select {selectLevel()}</div>
          <LabeledInputSearch
            tiggerAs="div"
            value={selectLevelId()}
            onSelect={(v) => onSelect(v as string)}
            options={options()}
            renderItem={
              renderItem() as (
                props: AddOverridePortfolio | MagicDoor.Api.PropertyDto | MagicDoor.Api.HydratedUnitDto | LeaseWithName
              ) => JSX.Element
            }
            label={t('Search')}
            class="w-full"
            placeholder={t('Search by name')}
          />
        </Show>
      </div>
    </Modal>
  );
};

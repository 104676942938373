import { useNavigate } from '@solidjs/router';
import { createEffect, createMemo, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconSort from '~/assets/images/common/sort.svg?component-solid';
import IconSwitch from '~/assets/images/common/switch.svg?component-solid';
import IconDeleteWarning from '~/assets/images/confirm-modal/delete.svg?component-solid';
import IconEdit from '~/assets/images/maintenance/edit.svg?component-solid';
import IconTrash from '~/assets/images/maintenance/trash.svg?component-solid';
import { AIScoreCell } from '~/components/common/AiScoreCell';
import { Avatar } from '~/components/common/Avatar';
import { LinkButton } from '~/components/common/Buttons';
import DisplayAddress from '~/components/common/DisplayAddress';
import { Radio } from '~/components/common/Inputs/Radio';
import { MaintenanceRequestTitle, MaintenanceRequestUrgency } from '~/components/maintenance-requests';
import { Html, ListView, toast, confirm } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequests } from '~/contexts/local';
import { useMaintenanceRequestFilter } from '~/hooks';
import { getStatusColumn } from '~/pages/maintenance-request/list-maintenance-request/list-components/StatusColumn';
import { maintenanceStatusTypes } from '~/utils/constant';
import { formatAgo, dateFormat } from '~/utils/date';
import type { Component } from 'solid-js';
import type { FilterKey } from '~/pages/maintenance-request/list-maintenance-request/list-components/statusFilters';
import type { MaintenanceRequestFilter } from '~/repositories/maintenanceRequestRepository';

export const MaintenanceRequestListView: Component<{
  title: string;
  filter?: MaintenanceRequestFilter;
  filterStatus?: FilterKey;
}> = (props) => {
  const navigate = useNavigate();
  const { t } = useLocalization();
  const {
    maintenanceRequests,
    maintenanceRequestCategory,
    getMaintenanceRequests,
    updateMaintenanceRequestUrgency,
    updateMaintenanceRequestStatus,
    deleteMaintenanceRequest,
  } = useMaintenanceRequests();
  const { filterValue, filterItems, parseFilter, setFilterValue, handleFilterReset } = useMaintenanceRequestFilter();

  const maintenanceUrgencyOptions = createMemo(() => {
    const urgencies: `${MagicDoor.Api.Urgency}`[] = ['none', 'low', 'medium', 'high', 'urgent'];
    return urgencies.map((urgency) => ({ label: <MaintenanceRequestUrgency urgency={urgency} />, value: urgency }));
  });

  const handleStatusChange = async (maintenanceId: string | undefined, value: MagicDoor.Api.Status) => {
    if (!maintenanceId) return;

    try {
      await updateMaintenanceRequestStatus(maintenanceId, value);
      toast.success(t('Modify status success'));
    } catch (error) {
      toast.error(t('Modify status failed, please try again later'));
    }
  };

  const handleUrgencyChange = async (maintenanceId: string | undefined, value: MagicDoor.Api.Urgency) => {
    if (!maintenanceId) return;

    try {
      await updateMaintenanceRequestUrgency(maintenanceId, value);
      toast.success(t('Modify priority success'));
    } catch (error) {
      toast.error(t('Modify priority failed, please try again later'));
    }
  };

  createEffect(() => {
    const page = Number(localStorage.getItem(`MaintenanceRequestListView${props.title}LocalPage`)) || 1;
    const pageSize = Number(localStorage.getItem(`MaintenanceRequestListView${props.title}LocalPageSize`)) || 50;

    getMaintenanceRequests({ page, pageSize, ...props.filter });
  });

  function onDelete(row: MagicDoor.Api.MaintenanceRequestListDto) {
    confirm({
      title: t(`Delete maintenance request`),
      content: (
        <>
          <IconDeleteWarning class="-mx-5 block bg-light-pink" />
          <Html as="p" class="mx-auto w-80 text-text-level03 [&>b]:font-bold [&>b]:text-primary">
            {t('Confirm to delete “<b>{title}</b>” permanently, or “Cancel” if you want to go back. This action can not be undo.', {
              title: row.title,
            })}
          </Html>
        </>
      ),
      async onResolve(confirmed) {
        if (!confirmed) return;
        await deleteMaintenanceRequest(row.id);
        toast.success(t('{name} has been deleted successfully', { name: row.title }));
      },
    });
  }

  const listData = createMemo(() =>
    maintenanceRequests()?.items.map((item) => ({
      ...item,
      unit: maintenanceRequests()?.units?.find((unit) => unit.id === item.unitId),
      property: maintenanceRequests()?.properties?.find((property) => property.id === item.propertyId),
      propertyManager: maintenanceRequests()?.propertyManagers?.find(
        (propertyManager) => propertyManager.id === item.assignedPropertyManagerId
      ),
    }))
  );
  return (
    <ListView
      onChange={({ page, pageSize, filter }) => {
        localStorage.setItem(`MaintenanceRequestListView${props.title}LocalPage`, `${page}`);
        localStorage.setItem(`MaintenanceRequestListView${props.title}LocalPageSize`, `${pageSize}`);
        setFilterValue(filter);
        getMaintenanceRequests({ ...props.filter, page, pageSize, ...parseFilter(filter) });
      }}
      title={props.title}
      searchPlaceholder={t('Search')}
      titleActions={
        <LinkButton class="ml-auto text-sm capitalize" href="/maintenance/maintenance-request/add" noScroll>
          <IconPlus class="text-white" />
          {t('Add maintenance request')}
        </LinkButton>
      }
      totalPages={maintenanceRequests()?.totalPages}
      page={maintenanceRequests()?.currentPage}
      pageSize={maintenanceRequests()?.pageSize}
      filterValue={filterValue()}
      filterItems={filterItems()}
      onFilterReset={handleFilterReset}
      rowLink={(item) => `/maintenance/maintenance-request/${item.id}`}
      skeletonSize={10}
      loading={maintenanceRequests.loading}
      data={listData()}
      columns={[
        {
          title: t('Priority'),
          render: (row) => <MaintenanceRequestUrgency urgency={row.urgency} />,
        },
        {
          title: t('Type'),
          render: (row) => (
            <Show when={row.categoryId} fallback={<div class="text-gray-400">--</div>}>
              <div class="text-sm capitalize">{maintenanceRequestCategory()?.find((item) => item.id === row.categoryId)?.name || ''}</div>
            </Show>
          ),
        },
        {
          title: t('Title'),
          render: (row) => <MaintenanceRequestTitle id={row.id} aiProcessed={row.aiProcessed} title={row.title} />,
        },
        {
          title: t('Unit'),
          render: (row) => (
            <div>
              <div class="font-medium">{row.unit?.name}</div>
              <DisplayAddress class="text-xs text-text-level03" address={row.property?.address} />
            </div>
          ),
        },
        {
          title: t('Assigned to'),
          render: (row) => (
            <Show when={row?.propertyManager} fallback={<div class="text-gray-400">--</div>}>
              <div class="flex">
                <Avatar name={row?.propertyManager?.name || ''} />
                <div class="ml-2">
                  <div class="text-nowrap"> {row?.propertyManager?.name}</div>
                  <div class="text-gray-400">{t('Manager')}</div>
                </div>
              </div>
            </Show>
          ),
        },
        getStatusColumn(props.filterStatus),
        {
          title: t('AI score'),
          render: (row) => <AIScoreCell score={row.aiUrgencyScore} />,
        },
        {
          title: t('Last updated'),
          render: (row) => (
            <div>
              <div class="mb-1 font-medium">{dateFormat(t('MM/DD/YYYY hh:mm A'), row.lastUpdated)}</div>
              <div class="text-gray-400">{formatAgo(row.lastUpdated, 2)}</div>
            </div>
          ),
        },
      ].filter(Boolean)}
      actions={(row) => [
        {
          label: t('Modify Priority'),
          icon: IconSort,
          children: (
            <Radio
              direction="column"
              items={maintenanceUrgencyOptions()}
              value={row?.urgency}
              onInput={(value) => handleUrgencyChange(row?.id, value as MagicDoor.Api.Urgency)}
            />
          ),
        },
        {
          label: t('Modify Status'),
          icon: IconSwitch,
          children: (
            <Radio
              direction="column"
              class="w-28"
              items={maintenanceStatusTypes.map((item) => ({ ...item, label: t(item.label) }))}
              value={row?.status}
              onInput={(value) => handleStatusChange(row?.id, value as MagicDoor.Api.Status)}
            />
          ),
        },
        {
          label: t('Edit'),
          icon: IconEdit,
          onClick: () => {
            navigate(`${row.id}/edit`);
          },
        },
        {
          label: t('Delete'),
          icon: IconTrash,
          onClick: () => onDelete(row),
        },
      ]}
    />
  );
};

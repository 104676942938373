import { For, createEffect, createSignal, onCleanup } from 'solid-js';
import type { JSX } from 'solid-js';

export const ScrollSpy = <T extends { href: string; label: string }>(
  props: {
    items: T[];
    class?: string;
    children?: (item: T, active: boolean) => JSX.Element;
    onChange?: (item: T) => void;
  } & IntersectionObserverInit
) => {
  const [active, setActive] = createSignal<T | undefined>(undefined);

  createEffect(() => {
    const map = new Map<string, number>();
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => map.set(entry.target.id, entry.intersectionRatio));
        const max = Math.max(...map.values(), 0.1);
        const item = props.items.find((i) => map.get(i.href.slice(1)) === max);
        if (item == null) return;
        setActive(() => item);
        props.onChange?.(item);
      },
      { rootMargin: '0px', threshold: [0, 0.25, 0.5, 0.75, 1], ...props }
    );
    const elements = props.items.map((i) => document.getElementById(i.href.slice(1))).filter((i) => i) as HTMLElement[];
    elements.forEach((element) => observer.observe(element));
    onCleanup(() => observer.disconnect());
  });

  return (
    <For each={props.items}>
      {(item) => (
        <a
          href={item.href}
          class={props.class}
          data-active={item.href === active()?.href ? '' : undefined}
          onClick={(e) => {
            e.preventDefault();
            const element = document.getElementById(item.href.slice(1));
            element?.scrollIntoView({ behavior: 'smooth' });
            history.pushState({}, '', item.href);
          }}>
          {props.children ? props.children(item, item.href === active()?.href) : item.label}
        </a>
      )}
    </For>
  );
};

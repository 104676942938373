import dayjs from 'dayjs';
import { Show } from 'solid-js';
import PlusIcon from '~/assets/images/common/plus.svg?component-solid';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { Form, FormItem, FormList } from '~/components/common/BetterForm';
import { FormListRemoverWrapper } from '~/components/common/BetterForm/FormListRemover';
import { Button } from '~/components/common/Buttons';
import { Section } from '~/components/common/FormSectionTitle';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledInputSearch } from '~/components/common/Inputs/LabeledInputSearch';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { BillFileListView } from '~/components/file-attachments/BillFileListView';
import { LeaseItem } from '~/components/leases/LeaseItem';
import { getLeaseDateName } from '~/components/leases/utils';
import { PropertyTitle } from '~/components/properties';
import { useLocalization } from '~/contexts/global';
import UnitItem from '~/pages/bills/components/UnitItem';
import VendorItem from '~/pages/bills/components/VendorItem';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm/type';
import type { LabeledInputSearchProps } from '~/components/common/Inputs/LabeledInputSearch';
import type { TBillStore } from '~/pages/bills/store';

const AddBillForm: Component<{ form?: BetterForm.ContextValue; store: TBillStore; class?: string }> = (props) => {
  const { t } = useLocalization();

  return (
    <div class="mx-auto flex w-10/12 flex-col gap-5">
      <Form
        initialValues={{
          billDate: dayjs().format('YYYY-MM-DD'),
          dueDate: dayjs().format('YYYY-MM-DD'),
        }}
        defaultForm={props.form}
        class={cn('text-left', props.class)}>
        <Section title={t('Vendor information')} border>
          <div class="grid grid-cols-3 gap-5">
            <div class="col-span-full lg:col-span-2">
              <FormItem<LabeledInputSearchProps<MagicDoor.Api.HydratedVendorDto>>
                required
                class="w-full"
                label={t(`Vendor`)}
                formFieldName="vendorId"
                options={props.store.vendors}
                onChangeMethodName="onSelect"
                renderItem={(item) => <VendorItem vendor={item} />}
                component={LabeledInputSearch}
                rules={[{ required: true, message: t(`Please select vendor`) }]}
                loading={props.store.vendorLoading}
                removeValueOnClean
                placeholder={t('Please select vendor')}
              />
            </div>
          </div>
        </Section>

        <Section title={t('Bill dates')} border>
          <div class="grid grid-cols-3 gap-5">
            <FormItem
              required
              class="col-span-full lg:col-span-1"
              formFieldName="billDate"
              label={t('Bill date')}
              rules={[{ required: true, message: t(`Please enter the {name}`, { name: t('Bill date') }) }]}
              component={DueDateInputField}
            />
            <FormItem
              required
              class="col-span-full lg:col-span-1"
              formFieldName="dueDate"
              label={t('Due date')}
              rules={[{ required: true, message: t(`Please enter the {name}`, { name: t('Due date') }) }]}
              component={DueDateInputField}
            />
          </div>
        </Section>

        <Section title={t('Bill detail')} border>
          <div class="grid grid-cols-3 gap-5">
            <div class="col-span-full lg:col-span-2">
              <FormItem<LabeledInputSearchProps<MagicDoor.Api.PropertyDto>>
                required
                label={t(`Property`)}
                formFieldName="propertyId"
                options={props.store.properties}
                onChangeMethodName="onSelect"
                renderItem={(item) => <PropertyTitle feedbackIconReadonly property={item} />}
                component={LabeledInputSearch}
                rules={[{ required: true, message: t(`Select property`) }]}
                loading={props.store.propertiesLoading}
                removeValueOnClean
                placeholder={t('Select property')}
              />
            </div>
            <Show when={props.form?.formStore.propertyId}>
              <div class="col-span-full lg:col-span-1 lg:col-start-1">
                <FormItem<LabeledInputSearchProps<MagicDoor.Api.UnitDto>>
                  label={`${t(`Unit`)} (${t('Optional')})`}
                  formFieldName="unitId"
                  options={props.store.units}
                  onChangeMethodName="onSelect"
                  renderItem={(item) => <UnitItem unit={item} />}
                  component={LabeledInputSearch}
                  loading={props.store.unitLoading}
                  removeValueOnClean
                  placeholder={t('Select unit')}
                />
              </div>
            </Show>

            <Show when={props.form?.formStore.unitId}>
              <div class="col-span-full lg:col-span-1">
                <FormItem<LabeledInputSearchProps<MagicDoor.Api.HydratedLeaseDto>>
                  label={`${t(`Lease`)} (${t('Optional')})`}
                  formFieldName="leaseId"
                  options={props.store.leases}
                  onChangeMethodName="onSelect"
                  renderItem={(item) => <LeaseItem lease={item} />}
                  component={LabeledInputSearch}
                  loading={props.store.leaseLoading}
                  filter={(item, keyword) => getLeaseDateName(item)?.toLowerCase().includes(keyword.toLowerCase())}
                  removeValueOnClean
                  placeholder={t('Select lease')}
                />
              </div>
            </Show>

            <FormItem
              class="col-span-full lg:col-span-2 lg:col-start-1"
              formFieldName="memo"
              label={t('Memo')}
              component={LabeledTextInput}
              placeholder={t('Please enter')}
            />
            <FormItem
              class="col-span-full lg:col-span-1"
              formFieldName="reference"
              label={t('Reference')}
              component={LabeledTextInput}
              placeholder={t('Please enter')}
            />
          </div>
        </Section>
        <Section title={t('Bill line items')} border>
          <FormList
            class="rounded-md border p-3"
            formFieldName={'lineItems'}
            renderItemsGroup={({ index, remove }) => {
              return (
                <FormListRemoverWrapper class="grid grid-cols-3 gap-5 " index={index} remove={remove} showRemove={true}>
                  <FormItem
                    class="col-span-full lg:col-span-1"
                    placeholder={t('Please select chart of account')}
                    label={t(`Chart of account`)}
                    formFieldName={['chartOfAccountId']}
                    component={LabeledChartOfAccountSelect}
                    types={['expense'] as MagicDoor.Api.ChartOfAccountType[]}
                    rules={[{ message: t(`Please select chart of account`), required: true }]}
                  />
                  <FormItem
                    class="col-span-full lg:col-span-1"
                    placeholder={t('Please input the amount')}
                    label={t(`Amount`)}
                    formFieldName={['amount']}
                    prepend="$"
                    component={LabeledNumberInput}
                    rules={[{ message: t(`Please input the amount`), required: true }]}
                  />
                  <FormItem
                    class="col-span-full lg:col-span-1"
                    placeholder={t('Please input description')}
                    label={t(`Description`)}
                    formFieldName={['description']}
                    component={LabeledTextInput}
                  />
                </FormListRemoverWrapper>
              );
            }}
            autoCombineFieldName={false}>
            {({ add }) => {
              return (
                <Button variant="text" color="link" onClick={add}>
                  <PlusIcon /> {t('Add line item')}
                </Button>
              );
            }}
          </FormList>
        </Section>
        <Section title={t('Bill invoice')}>
          <BillFileListView onlyUpload={true} autoUpload={false} />
        </Section>
      </Form>
    </div>
  );
};

export default AddBillForm;

import { useParams } from '@solidjs/router';
import { Show, createEffect, createSignal } from 'solid-js';
import IconDeleteWarning from '~/assets/images/confirm-modal/delete.svg?component-solid';
import { AutoPaymentAmount, AutoPaymentStatus, AutoPaymentType } from '~/components/auto-payments';
import { PrintButton } from '~/components/common/Buttons';
import { TransactionLeaseName, TransactionPaymentMethod, TransactionStatus } from '~/components/transactions';
import { IconPencilLine, IconTrash, ListView, confirm, toast } from '~/components/ui';
import { useLocalization, useTenants } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import { AutoTenantPaymentDetailsModal } from '~/pages/tenants/tenant-details/components/AutoTenantPaymentDetailsModal';
import { IconAutoPayments } from '~/pages/tenants/tenant-details/components/Icons';
import { TenantPaymentDetailsModal } from '~/pages/tenants/tenant-details/components/TenantPaymentDetailsModal';
import { TenantPaymentsMethod } from '~/pages/tenants/tenant-details/components/TenantPaymentsMethod';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';

const AutoPaymentInfo = () => {
  const { t } = useLocalization();
  const { current, autoPayments, getAutoPayments } = useTenants();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('TenantAutoPaymentsInfoListView');

  createEffect(() => {
    const tenant = current();
    tenant && getAutoPayments(tenant.id);
  });

  return (
    <ListView
      class="mb-5"
      headerClass="bg-gradient-to-br from-[#33a7ff] to-[#1b5cff] px-6 py-4 text-white"
      title={
        <div class="flex items-center gap-2">
          <IconAutoPayments />
          <div class="text-lg font-semibold">{t('Auto payments')}</div>
        </div>
      }
      page={page()}
      pageSize={pageSize()}
      onChange={({ page, pageSize }) => {
        setPage(page);
        setPageSize(pageSize);
      }}
      loading={current.loading || autoPayments.loading}
      data={autoPayments()}
      skeletonSize={3}
      columns={[
        {
          title: t('Payment type'),
          render: (item) => <AutoPaymentType entry={item} />,
        },
        {
          title: t('Status'),
          headerClass: 'w-24',
          render: (item) => <AutoPaymentStatus entry={item} />,
        },
        {
          title: t('Amount'),
          headerClass: 'text-right w-44',
          class: 'text-right font-medium',
          render: (item) => <AutoPaymentAmount entry={item} />,
        },
        {
          title: t('Start and expire date'),
          headerClass: 'w-64',
          render: (item) => (
            <>
              <p>{`${t('Next payment')}: ${dateFormat('M/D/YY', item.nextPayment)}`}</p>
              <Show when={item.end}>
                <p class="text-xs text-text-level03">{`${t('Expire date')}: ${dateFormat('M/D/YY', item.end)}`}</p>
              </Show>
            </>
          ),
        },
      ]}
      actions={(item) => [
        {
          label: t('Details'),
          href: `./auto-payment-details/${item.id}`,
        },
      ]}
    />
  );
};

export const TenantPaymentsTab = () => {
  const params = useParams<{ paymentId?: string; autoPaymentId?: string }>();
  const { t } = useLocalization();
  const { localPage, setPage, setPageSize } = useLocalPagination('TenantPaymentsListView');
  const { current, payments, getPayments, deletePayment } = useTenants();
  const [printContainer, setPrintContainer] = createSignal<HTMLDivElement>();

  const currentPayment = () => {
    if (params.paymentId == null) return;
    return payments()?.items.find((p) => p.id === params.paymentId);
  };

  createEffect(() => {
    const tenant = current();
    tenant && getPayments({ tenantId: tenant.id, page: localPage, pageSize: 100 });
  });

  const handleDelete = (id: string) => {
    confirm({
      title: t('Delete transaction'),
      content: (
        <>
          <IconDeleteWarning class="-mx-5 block bg-light-pink" />
          <p class="w-[356px] text-text-level03">
            {t('Confirm to delete this payment, or “Cancel” if you want to go back. This action can not be undo.')}
          </p>
        </>
      ),
      async onResolve(confirmed) {
        if (!confirmed) return;
        await deletePayment(id);
        toast.success('Payment successfully deleted.');
      },
    });
  };

  return (
    <>
      <AutoPaymentInfo />
      <TenantPaymentsMethod />
      <ListView
        class="mt-5"
        title={t('Payments')}
        titleActions={
          <div class="flex gap-2">
            <PrintButton printContainer={printContainer() as HTMLDivElement} />
          </div>
        }
        loading={current.loading || payments.loading}
        data={payments()?.items}
        page={payments()?.currentPage}
        totalPages={payments()?.totalPages}
        pageSize={payments()?.pageSize}
        onChange={({ page, pageSize }) => {
          const tenant = current();

          if (!tenant) {
            return;
          }

          setPage(page);
          setPageSize(pageSize);
          getPayments({ tenantId: tenant.id, pageSize, page });
        }}
        skeletonSize={10}
        columns={[
          {
            title: t('Date'),
            headerClass: 'w-32',
            render: (item) => dateFormat('', item.transactionDate),
          },
          {
            title: t('Transaction type'),
            headerClass: 'w-40',
            render: (item) => <TransactionPaymentMethod method={item.transactionPaymentMethod} />,
          },
          {
            title: t('Lease'),
            render: (item) => <TransactionLeaseName property={item.property} unit={item.unit} />,
          },
          {
            title: t('Amount'),
            headerClass: 'w-32 text-right',
            class: 'text-right font-medium',
            render: (item) => currency(item.amount),
          },
          {
            title: t('Status'),
            headerClass: 'w-24',
            render: (item) => <TransactionStatus status={item.status} />,
          },
        ]}
        actions={(item) => [
          { icon: IconPencilLine, label: t('Details'), href: item.id, noScroll: true },
          { icon: IconTrash, label: t('Delete'), onClick: () => handleDelete(item.id) },
        ]}
        getPrintContainer={(pc) => setPrintContainer(pc)}
      />
      <TenantPaymentDetailsModal visible={params.paymentId != null} payment={currentPayment()} />
      <AutoTenantPaymentDetailsModal visible={params.autoPaymentId != null} />
    </>
  );
};

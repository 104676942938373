interface IconFieldSkeletonProps {
  name: string;
}

const IconFieldSkeleton = (props: IconFieldSkeletonProps) => {
  return (
    <div class="flex h-9 flex-row items-center gap-2">
      <div class="w-full text-left">
        <div class="uppercase text-text-level03">{props.name}</div>
        <div class="skeleton-shimmer h-6" />
      </div>
    </div>
  );
};

export default IconFieldSkeleton;

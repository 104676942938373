import { Show } from 'solid-js';
import IconMoney from '~/assets/images/common/money.svg?component-solid';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';

interface ComponentProps {
  class?: string;
  money?: number;
  showIcon?: boolean;
  zeroHolder?: string;
  color?: string | 'normal';
}

const CnMoney: Component<ComponentProps> = (props) => {
  function getColor() {
    if (props.color == 'normal') return 'text-title-gray';
    if (props.money == 0 || !props.money) return 'text-text-level03';
    if (props.money > 0) return 'text-danger';
    return 'text-[#1FC09F]';
  }
  return (
    <div class={cn('flex items-center justify-end text-xs font-semibold md:text-sm', props.class, getColor())}>
      <Show when={props.showIcon}>
        <IconMoney class="mr-0.5" />
      </Show>
      {props.zeroHolder && !props.money ? props.zeroHolder : currency(props.money)}
    </div>
  );
};

export default CnMoney;

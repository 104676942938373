import { onMount, createMemo } from 'solid-js';
import IconChargeLateFees from '~/assets/images/settings/property/propertyChargeLateFee.svg';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBankAccountsList, useRentalApplicationPaymentAccount } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';
import type { ItemProps } from '~/pages/settings/components/edit-form/EditForm';

export const PaymentAccount = () => {
  const { t } = useLocalization();

  const { rentalApplicationPaymentAccount, updateRentalApplicationPaymentAccount, getRentalApplicationPaymentAccount } =
    useRentalApplicationPaymentAccount();

  const { bankAccounts } = useBankAccountsList();

  onMount(async () => {
    await getRentalApplicationPaymentAccount();
  });

  const handleSave = async (data: MagicDoor.Api.RentalApplicationPaymentAccountSettingsDto) => {
    try {
      await updateRentalApplicationPaymentAccount(data);
      toast(t('Rental application PaymentAccount settings saved successfully'), 'success');
    } catch (error) {
      toast(t('Failed to save Rental application PaymentAccount settings'), 'error');
    }
  };

  const sections = createMemo<ItemProps[]>(() => {
    return [
      {
        field: 'rentalApplicationBankAccountId',
        label: 'Rental application bank account',
        type: 'select',
        options: bankAccounts().map((account) => ({ label: account.name, value: account.id })),
        description: 'What bank account to send rental application fee to',
        class: 'col-span-2',
        labelClass: 'w-1/2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
      },
    ];
  });

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topTitle={<SettingTitle title={t('Payment account')} icon={IconChargeLateFees} />}
        middle={
          <div class="flex flex-col gap-5 p-4">
            <div class="flex flex-col gap-4 p-2 text-left">
              <EditForm<MagicDoor.Api.RentalApplicationPaymentAccountSettingsDto>
                sections={sections()}
                onOk={(data) => handleSave(data)}
                model={rentalApplicationPaymentAccount() || ({} as MagicDoor.Api.RentalApplicationPaymentAccountSettingsDto)}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

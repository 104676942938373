import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

const EMAIL_URL = '/api/emails';

export type EmailListFilter = {
  ownerId?: string;
  ownerIds?: string[];
  tenantIds?: string[];
  leaseIds?: string[];
  vendorIds?: string[];
  subjectLike?: string;
  bodyLike?: string;
  disable?: boolean;
  page?: number;
  pageSize?: number;
  skip?: number;
};

export type EmailFilter = {
  page?: number;
  pageSize?: number;
  skip?: number;
  disable?: boolean;
};

export type SendEmailQuest = {
  subject: string;
  body: string;
  attachments?: File[] | undefined;
};

export class EmailRepository extends BaseRestRepository {
  public async getEmails(filter?: EmailListFilter): Promise<MagicDoor.Api.EmailsDto> {
    const url = urlWithQuery(EMAIL_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getEmail(emailId: string): Promise<MagicDoor.Api.EmailDto> {
    const url = `${EMAIL_URL}/${emailId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getOwnerEmails(ownerId: string, filter?: EmailFilter): Promise<MagicDoor.Api.EmailsDto> {
    const url = urlWithQuery(`${EMAIL_URL}/owners/${ownerId}`, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getTenantEmails(tenantId: string, filter?: EmailFilter): Promise<MagicDoor.Api.EmailsDto> {
    const url = urlWithQuery(`${EMAIL_URL}/tenants/${tenantId}`, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getLeaseEmails(leaseId: string, filter?: EmailFilter): Promise<MagicDoor.Api.EmailsDto> {
    const url = urlWithQuery(`${EMAIL_URL}/leases/${leaseId}`, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getVendorEmails(vendorId: string, filter?: EmailFilter): Promise<MagicDoor.Api.EmailsDto> {
    const url = urlWithQuery(`${EMAIL_URL}/vendors/${vendorId}`, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async postVendorEmails(url: string, payload: SendEmailQuest) {
    const formData = new FormData();
    formData.append('subject', payload.subject);
    formData.append('body', payload.body);
    if (payload.attachments) {
      payload.attachments.forEach((file) => formData.append('attachments', file));
    }
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return this.getJsonResponse(response);
  }
}
import { untrack } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { useLocalization } from '~/contexts/global';
import { MaintenanceRequestStatus } from '~/swagger/Api';
import { PendingIcon } from './Icons';
import IconAiProcessing from './aiProcessing.svg?component-solid';
import IconWorkerOrder from './workOrder.svg?component-solid';
import type { JSX } from 'solid-js';
import type { MaintenanceRequestListDto } from '~/swagger/Api';

export const AIProcessing = () => {
  const { t } = useLocalization();
  return (
    <div
      class="relative flex h-7 w-[121px] items-center justify-center gap-1 rounded-3xl px-2.5 text-xs text-white"
      style={{
        background: 'linear-gradient(300deg, #587DFF 25.19%, #7F11ED 100%), #D9D9D9',
      }}>
      <IconAiProcessing />
      <span>{t('AI processing')}</span>
    </div>
  );
};

export const AIProcessed = () => {
  const { t } = useLocalization();
  return (
    <div class="relative flex h-7 w-[121px] items-center justify-center rounded-3xl bg-gray-level01 text-xs text-text-level03">
      <span>{t('AI processed')}</span>
    </div>
  );
};

const WaitingForWorkOrder = () => {
  const { t } = useLocalization();
  return (
    <div class="relative flex h-7 w-[180px] items-center justify-center gap-1 rounded-3xl bg-green text-xs text-white">
      <IconWorkerOrder />
      <span>{t('Waiting for work order')}</span>
    </div>
  );
};

const InProgress = () => {
  const { t } = useLocalization();
  return (
    <div class="relative flex h-7 w-[121px] items-center justify-center gap-1 rounded-3xl bg-essential-colour text-xs text-white">
      <span>{t('In progress')}</span>
    </div>
  );
};

const Pending = () => {
  const { t } = useLocalization();
  return (
    <div class="relative flex h-7 w-[121px] items-center justify-center gap-1 rounded-3xl bg-light-purple text-xs text-white">
      <PendingIcon />
      <span>{t('Pending')}</span>
    </div>
  );
};

const statusToComponent: {
  [key in MaintenanceRequestStatus | 'aiProcessed']?: () => JSX.Element;
} = {
  [MaintenanceRequestStatus.AiProcessing]: AIProcessing,
  [MaintenanceRequestStatus.WaitingForWorkOrder]: WaitingForWorkOrder,
  [MaintenanceRequestStatus.InProgress]: InProgress,
  aiProcessed: AIProcessed,
  pending: Pending,
};

export const getStatusColumn = (status?: MaintenanceRequestStatus) => {
  const { t } = useLocalization();

  // Only newRequests status is displayed in the Status column. No other status is displayed
  if (status !== MaintenanceRequestStatus.NewRequests) return undefined;

  return {
    title: t('Status'),
    render(row: MaintenanceRequestListDto) {
      const component = untrack(() => row.status);
      return <Dynamic component={statusToComponent[component]} />;
    },
  };
};

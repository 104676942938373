import { Show } from 'solid-js';

interface SettingTitleProps {
  title: string;
  icon?: string;
  description?: string;
}

export const SettingTitle = (props: SettingTitleProps) => {
  return (
    <div class="flex w-full items-center justify-start gap-3">
      <Show when={props.icon}>
        <img class="size-9" src={props.icon} />
      </Show>
      <div>
        <div class="capitalize text-title-gray">{props.title}</div>
        <div class="text-xs font-normal text-text-level03">{props.description}</div>
      </div>
    </div>
  );
};

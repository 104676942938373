import { Route, Routes } from '@solidjs/router';
import { AddVendorModal } from './add-vendor';
import { VendorEditPage } from './edit-vendor';
import { ListVendorsPage } from './list-vendors';
import { VendorDetailsPage } from './vendor-details';

const VendorRoutes = () => (
  <>
    <Routes>
      <Route path="/:vendorId/edit" component={VendorEditPage} matchFilters={{ vendorId: /\d+/ }} />
      <Route path="/:vendorId/*" component={VendorDetailsPage} matchFilters={{ vendorId: /\d+/ }} />
      <Route path="*" component={ListVendorsPage} />
    </Routes>
    <Routes>
      <Route path="/add" component={AddVendorModal} />
    </Routes>
  </>
);

export default VendorRoutes;

import { createResource, createSignal, createMemo } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { LeaseDepositRepository } from '~/repositories/leaseDepositRepository';
import { createMutation } from '~/utils/resource';

const repo = new LeaseDepositRepository();

export const [LeaseDepositsProvider, useLeaseDeposits] = createMagicDoorContext('LeaseDeposits', () => {
  const [currentId, setCurrentId] = createSignal<string | undefined>(undefined);
  const [deposit, depositActions] = createResource(currentId, (leaseId) => repo.getLeaseDeposit(leaseId));

  const addLeaseDeposit = createMutation(async (leaseId: string, model: MagicDoor.Api.AddLeaseDepositRequestDto) => {
    const response = await repo.addLeaseDeposit(leaseId, model);
    depositActions.refetch();
    return response;
  });

  const addLeaseDepositOfflinePayment = createMutation(async (leaseId: string, model: MagicDoor.Api.OfflinePaymentDto) => {
    const response = await repo.addLeaseDepositOfflinePayment(leaseId, model);

    depositActions.refetch();
    return response;
  });

  const leaseDesposit = createMemo(() => deposit()?.transactions ?? []);

  const releaseDeposit = createMutation(async (leaseId: string, request: MagicDoor.Api.ReleaseDepositRequestDto) => {
    const response = await repo.releaseDeposit(leaseId, request);
    depositActions.refetch();
    return response;
  });

  return {
    deposit,
    setCurrentId,
    addLeaseDepositOfflinePayment,
    addLeaseDeposit,
    releaseDeposit,
    leaseDesposit,
    depositActions,
  };
});

import { onMount } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import { ListView } from '~/components/ui';
import { useDepositSlips, useLocalization } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import { getBankAccountName } from '~/pages/deposit-slips/utils';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';

export const ListDepositSlips = () => {
  const { t } = useLocalization();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('ListDepositSlips');
  const { filtered, setFilter } = useDepositSlips();

  onMount(() => {
    setFilter({ page: page(), pageSize: pageSize() });
  });

  return (
    <div class="space-y-5 p-8">
      <ListView
        skeletonSize={10}
        title={t('Deposit slips')}
        titleActions={
          <LinkButton class="ml-auto text-sm capitalize" href="add" noScroll>
            <IconPlus class="text-white" />
            {t('Create new deposit slip')}
          </LinkButton>
        }
        page={filtered()?.currentPage}
        totalPages={filtered()?.totalPages}
        pageSize={filtered()?.pageSize}
        data={filtered()?.items}
        loading={filtered.loading}
        onChange={({ page, pageSize }) => {
          setPage(page);
          setPageSize(pageSize);
          setFilter({ page, pageSize });
        }}
        rowLink={(item) => `/accounting/deposit-slips/${item.id}`}
        columns={[
          {
            title: t('Reference'),
            render: (item) => <div>{item.reference || emptyPlaceholder}</div>,
          },
          {
            title: t('Bank account name'),
            render: (item) => <div>{getBankAccountName(filtered()?.bankAccounts, item.bankAccountId)}</div>,
          },
          {
            title: t('Date'),
            render: (item) => <div>{dateFormat(t('MM/DD/YYYY'), item.date)}</div>,
          },
          {
            title: t('Number of transactions'),
            render: (item) => <div>{item.transactionIds?.length || emptyPlaceholder}</div>,
          },
        ]}
      />
    </div>
  );
};

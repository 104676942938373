import { useParams } from '@solidjs/router';
import { createEffect, createSignal } from 'solid-js';
import { useForm } from '~/components/common/BetterForm';
import { FormWrapper } from '~/components/common/BetterForm/Form';
import CallToActionButton from '~/components/common/Buttons/CallToActionButton';
import {
  CategoryComponentSection,
  CategoryComponentWrapperProvider,
  CategoryContentSection,
  getErrorAndProgressProps,
} from '~/components/common/Category';
import CancelWithConfirmation from '~/components/modals/CancelWithConfirmation';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { usePropertyManagers } from '~/contexts/local';
import { isEmptyData } from '~/utils/tool';
import PropertyManagerInformationSection from './PropertyMangerInfoSection';
import type { JSX, Setter } from 'solid-js';
import type { Section } from '~/components/common/Category';

interface Props {
  onClose: () => void;
  setShowCloseConfirmation?: Setter<boolean>;
  showCloseConfirmation?: boolean;
}

type AddSection = {
  key: string;
  component: (props: any) => JSX.Element;
  name: string;
  ref?: HTMLElement | null;
  required?: boolean;
} & Omit<Section, 'component'>;

export const AddOrEditPropertyManager = (props: Props) => {
  const { t } = useLocalization();
  const { addPropertyManager, updatePropertyManager, refetch, getPropertyManagerInfo } = usePropertyManagers();

  const form = useForm();
  const { managerId } = useParams<{ managerId?: string }>();

  const [activeCategory, setActiveCategory] = createSignal<string>('propertyManagerInformation');
  const [expandedAdditional, setExpandedAdditional] = createSignal<boolean>(false);
  const [isLoading, setIsLoading] = createSignal<boolean>(false);

  const sections: AddSection[] = [
    {
      key: 'propertyManagerInformation',
      component: PropertyManagerInformationSection,
      name: t('Property manager information'),
      required: true,
      fields: ['firstName', 'lastName', 'email', 'clearTextPassword', 'isBusinessAgent'],
    },
  ];

  let scrollContainer!: HTMLDivElement;

  const fetchManagerData = async () => {
    if (managerId) {
      setIsLoading(true);
      try {
        const data = await getPropertyManagerInfo(managerId);
        form.setFieldsValue(data);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const performSave = async () => {
    setIsLoading(true);
    try {
      if (managerId) {
        await updatePropertyManager(managerId, form.formStore as MagicDoor.Api.PropertyManagerDto);
        toast.success(t('{name} has been edited successfully', { name: t('Property manager') }));
      } else {
        await addPropertyManager(form.formStore as MagicDoor.Api.PropertyManagerDto);
        toast.success(t('{name} has been added successfully', { name: t('Property manager') }));
      }
      refetch();
      props.onClose();
    } finally {
      setIsLoading(false);
    }
  };

  createEffect(() => {
    props.setShowCloseConfirmation?.(!isEmptyData(form.formStore));
  });

  createEffect(() => {
    if (managerId) {
      fetchManagerData();
    }
  });

  return (
    <FormWrapper class="relative flex flex-col text-sm" defaultForm={form} onFormSubmit={performSave}>
      <CategoryComponentWrapperProvider
        onActiveCategoryChange={(key, evt) => {
          if (key !== 'propertyManagerInformation' && evt === 'click') setExpandedAdditional(true);
          if (evt === 'scroll' && !expandedAdditional()) return setActiveCategory('propertyManagerInformation');
          setActiveCategory(key);
        }}
        sections={sections}
        getScrollElement={() => scrollContainer}
        activeCategory={activeCategory()}>
        <div class="relative flex flex-1 items-start">
          <div class="sticky top-0 hidden h-full w-1/3 bg-inputbox-bg text-text-level03 xs:block">
            <div class="flex flex-col gap-2">
              <CategoryComponentSection
                headerTitle={
                  <div class="py-3 pl-5 text-lg font-semibold text-text-level01">
                    {managerId ? t('Edit property manager') : t('Add property manager')}
                  </div>
                }
                class="min-w-0 bg-transparent"
                {...getErrorAndProgressProps(form)}
              />
            </div>
          </div>

          <CategoryContentSection
            renderMethod={({ setContentRef }) => {
              return (
                <div ref={scrollContainer} class="thinscroll flex h-[610px] flex-1 flex-col overflow-auto px-8">
                  <div
                    ref={(ref) => {
                      setContentRef('propertyManagerInformation', ref);
                    }}>
                    <PropertyManagerInformationSection />
                  </div>
                </div>
              );
            }}
          />
        </div>

        <div class="z-20 flex w-full items-center justify-between border-t bg-white p-6">
          <div class="w-2/4 text-left text-xs text-text-level03">{t('You may modify these information later')}</div>
          <div class="flex w-2/4 justify-end gap-2">
            <CancelWithConfirmation
              enabled={props.showCloseConfirmation}
              onConfirm={props.onClose}
              title={t('Are you sure you want to cancel?')}
              buttonsDisabled={isLoading()}
              message={t('If you cancel, the information already filled in cannot be saved')}
            />
            <CallToActionButton
              type="submit"
              loading={isLoading()}
              tooltipMessage={t('Please fill out all the required fields that are in red')}>
              {managerId ? t('Update property manager') : t('Create property manager')}
            </CallToActionButton>
          </div>
        </div>
      </CategoryComponentWrapperProvider>
    </FormWrapper>
  );
};

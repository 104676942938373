import InputSwitch from './InputSwitch';
import { LabeledGroup } from './LabeledGroup';
import type { LabeledGroupProps } from './LabeledGroup';
import type { Component } from 'solid-js';

export interface InputSwitchProps extends LabeledGroupProps {
  placeholder?: string;
  value?: boolean | undefined;
  onInput?: (e: boolean) => void;
  onClick?: (isMouseDown: boolean) => void;
  validationFunction?: (value: string | number) => string | undefined;
  labelClass?: string;
  suffix?: string;
  inputClass?: string;
  prepend?: string;
  disabled?: boolean;
  name?: string;
  labelContainerClass?: string;
}

export const LabeledInputSwitch: Component<InputSwitchProps> = (props) => {
  const handleChange = (value: boolean) => {
    props.onInput && props.onInput(value);
  };
  return (
    <LabeledGroup {...props} labelClass={props.labelClass}>
      <InputSwitch onChange={handleChange} checked={props.value} name={props.name} disabled={props.disabled} />
    </LabeledGroup>
  );
};

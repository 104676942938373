import { useNavigate } from '@solidjs/router';
import { createSignal } from 'solid-js';
import MiddleModal from '~/components/modals/MiddleModal';
import { useLocalization, useOwners } from '~/contexts/global';
import { AddOwner } from './AddOwner';
import type { Component } from 'solid-js';

export const AddOwnerModal: Component = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const { refetch } = useOwners();
  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);

  const handleClose = () => {
    refetch();
    navigate('/users/owners');
  };

  return (
    <MiddleModal
      confirmation={showCloseConfirmation()}
      onClose={handleClose}
      confirmationTitle={t('Are you sure you want to cancel?')}
      confirmationMessage={t('If you cancel the addition, the information already filled in cannot be saved')}>
      <AddOwner onClose={handleClose} showCloseConfirmation={showCloseConfirmation()} setShowCloseConfirmation={setShowCloseConfirmation} />
    </MiddleModal>
  );
};

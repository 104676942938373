import { BaseRestRepository } from '~/repositories/baseRestRepository';

const baseUrl = '/api/settings/property';

export class PropertySettingsRepository extends BaseRestRepository {
  public async getAllPropertySettings(): Promise<MagicDoor.Api.PropertySettingsDtoSettingsWithOverridesDto> {
    const url = baseUrl + '/all';
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getPropertySettingsByLevel(level: string, levelId: string): Promise<MagicDoor.Api.PropertySettingsDto> {
    const url = `${baseUrl}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async updatePropertySettings(payload: MagicDoor.Api.PropertySettingsDto): Promise<MagicDoor.Api.PropertySettingsDto> {
    const url = baseUrl;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async updatePropertySettingsByLevel(
    level: string,
    levelId: string,
    payload: MagicDoor.Api.PropertySettingsDto
  ): Promise<MagicDoor.Api.PropertySettingsDto> {
    const url = `${baseUrl}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async deletePropertySettings(): Promise<void> {
    const url = baseUrl;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }

  public async deletePropertySettingsByLevel(level: string, levelId: string): Promise<void> {
    const url = `${baseUrl}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }
}

import { createEffect, createMemo, createSignal, mergeProps, Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { ListView, IconDownload, IconTrash, confirm } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useAttachments } from '~/contexts/local';
import { dateFormat } from '~/utils/date';
import { downloadFile } from '~/utils/file';
import { FilePreviewModal } from './FilePreviewModal';
import { FileTitle } from './FileTitle';
import { FileUploadModal } from './FileUploadModal';
import type { Component } from 'solid-js';

export const FileListView: Component<{
  entryType?: `${MagicDoor.Api.EntityType}`;
  entryId?: string;
  class?: string;
  onlyUpload?: boolean;
  autoUpload?: boolean;
  ref?: any;
}> = (originProps) => {
  const props = mergeProps(
    {
      autoUpload: true,
    },
    originProps
  );

  const { t } = useLocalization();
  const { attachments, getAttachments, deleteAttachment } = useAttachments();

  const [preview, setPreview] = createSignal<MagicDoor.Api.HydratedFileAttachmentDto>();
  const [showUploadModal, setShowUploadModal] = createSignal<boolean>(false);
  const [selected, setSelected] = createSignal<MagicDoor.Api.HydratedFileAttachmentDto[]>([]);
  const [list, setList] = createSignal<MagicDoor.Api.HydratedFileAttachmentDto[]>([]);

  createEffect(() => props.entryType && props.entryId && getAttachments(props.entryType, props.entryId));

  const loading = () => props.entryType == null || props.entryId == null || attachments.loading;

  const data = createMemo(() => (props.autoUpload ? attachments() : list()));

  const handleDownloadSelect = async () => {
    const files = selected();
    if (files.length === 0) return;
    files.forEach((file) => downloadFile(file.fileUrl, file.fileName));
  };

  const onUploadConfirm = async (categoryId: string, files: File[], name: string, description?: string) => {
    const newFiles = files.map((file) => ({
      type: file.type,
      fileName: file.name,
      description,
      file: file,
    })) as unknown as MagicDoor.Api.HydratedFileAttachmentDto[];
    setList((prev) => [...prev, ...newFiles]);
  };

  const deleteFile = async (item: MagicDoor.Api.HydratedFileAttachmentDto, index: number) => {
    if (props.autoUpload) {
      confirm({
        title: t('Delete file'),
        content: t('Are you sure you want to delete this file?'),
        async onResolve(confirmed) {
          if (!confirmed || props.entryType == null || props.entryId == null) return;
          await deleteAttachment(props.entryType, props.entryId, item.id);
        },
      });
      return;
    }
    setList((prev) => prev.toSpliced(index, 1));
  };

  const tableActions = (item: MagicDoor.Api.HydratedFileAttachmentDto, index: number) => {
    const actions = [
      {
        label: t('Download'),
        icon: IconDownload,
        onClick: () => downloadFile(item.fileUrl, item.fileName),
      },
      {
        label: t('Delete'),
        icon: IconTrash,
        onClick: () => deleteFile(item, index),
      },
    ];
    return props.autoUpload ? actions : actions.toSpliced(0, 1);
  };

  props.ref &&
    props.ref({
      getFileList() {
        return data();
      },
    });

  return (
    <>
      <ListView
        class={props.class}
        title={t('Files')}
        titleActions={
          <>
            <Show when={!props.onlyUpload}>
              <Button variant="outlined" class="p-2.5" disabled={selected().length === 0} onClick={handleDownloadSelect}>
                <IconDownload class="size-5" />
              </Button>
            </Show>

            <Button onClick={() => setShowUploadModal(true)}>{t('Upload file')}</Button>
          </>
        }
        selected={props.onlyUpload ? undefined : selected()}
        onSelect={setSelected}
        onRowClick={setPreview}
        loading={loading()}
        data={data()}
        columns={[
          {
            title: t('File'),
            render: (item) => <FileTitle file={item} />,
          },
          // {
          //   title: t('Category'),
          //   headerClass: 'w-40',
          //   key: 'uploaderType',
          // },
          {
            title: t('From'),
            headerClass: 'w-40',
            class: 'space-y-1 text-xs',
            render: (item) => (
              <>
                <p class="text-sm text-title-gray">{dateFormat('', item.created)}</p>
                <Show when={item.uploaderName}>
                  <p class="text-text-level03">
                    {t('by')} {item.uploaderName}
                  </p>
                </Show>
              </>
            ),
          },
        ]}
        actions={(item, index) => tableActions(item, index)}
      />

      <FileUploadModal
        entryType={props.autoUpload ? props.entryType : undefined}
        entryId={props.autoUpload ? props.entryId : undefined}
        open={showUploadModal()}
        onClose={() => setShowUploadModal(false)}
        onConfirm={props.autoUpload ? undefined : onUploadConfirm}
      />

      <FilePreviewModal file={preview()} onClose={setPreview} />
    </>
  );
};

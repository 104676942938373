import { Show } from 'solid-js';
import usePresenter from '~/pdfsigner/hooks/usePresenter';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { PdfPresenter } from '~/pdfsigner/presenters/PdfPresenter';
import { AddPdfUseCase } from '~/pdfsigner/usecases/addPdfUseCase';
import { PdfDocument } from './PdfDocument';
import { SignerList } from './SignerList';
import type { JSX } from 'solid-js';

const DocumentUpload = (): JSX.Element => {
  const { execute: addPdf } = useUseCase(AddPdfUseCase);
  const { model: presentablePdf } = usePresenter(PdfPresenter);

  const handleDrop = async (event: DragEvent) => {
    event.preventDefault();
    await addPdf(event.dataTransfer?.files);
  };

  const preventDefaultBehavior = (event: DragEvent) => {
    event.preventDefault();
  };

  const isPdfLoaded = () => {
    const pdf = presentablePdf();
    return pdf !== undefined && pdf?.length > 0;
  };

  return (
    <div
      id="pdf-viewer"
      class="flex h-screen w-full"
      onDrop={handleDrop}
      onDragOver={preventDefaultBehavior}
      onDragLeave={preventDefaultBehavior}>
      <Show
        when={isPdfLoaded()}
        fallback={<div class="flex h-screen w-full items-center justify-center bg-gray-100">Drag and drop a PDF file here</div>}>
        <div class="z-20 flex size-full">
          <div class="max-h-full grow overflow-y-auto pr-[10px]">
            <PdfDocument />
          </div>
          <div class="z-30 h-full shrink-0 pt-4">
            <div class="mb-4">
              <SignerList />
            </div>
          </div>
        </div>
      </Show>
    </div>
  );
};

export default DocumentUpload;

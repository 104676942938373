import IconWand from '~/assets/images/common/wand.png';
import IconBubble from '~/components/common/IconBubble';
import UnderConstruction from '~/components/common/UnderConstruction';
import UserActivity from '~/components/common/UserActivity';

//TODO - Fix with real data
function VendorActivityLog() {
  return (
    <div class="relative rounded-lg border bg-white p-6 text-sm">
      <IconBubble
        icon={<img class="size-9" src={IconWand} />}
        upperContent={<UserActivity name="Kelly Shao" />}
        lowerContent={
          <div class="flex justify-between px-4 py-3">
            <div class="flex flex-col gap-1">
              <div class="text-sm font-medium text-link">Carpet cleaning at 3 Industrial Road</div>
              <div class="text-text-level03 ">150 Main Ave - Unit 4</div>
            </div>
            <div class="flex items-end font-medium">$750.00</div>
          </div>
        }
      />
      <UnderConstruction />
    </div>
  );
}

export default VendorActivityLog;

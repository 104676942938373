import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { LeaseSearch } from '~/components/leases';
import { MaintenanceRequestSearch } from '~/components/maintenance-requests';
import { OwnerSearch } from '~/components/owner/OwnerSearch';
import { TenantSearch } from '~/components/tenants';
import { Tabs } from '~/components/ui';
import { VendorSearch } from '~/components/vendors';
import { useLocalization } from '~/contexts/global';
import { twc } from '~/utils/classnames';
import {
  IconAddCommunicationLease,
  IconAddCommunicationMaintenance,
  IconAddCommunicationTenant,
  IconAddCommunicationTextMessage,
  IconAddCommunicationVendor,
  IconAddCommunicationOwner,
} from './assets';
import AddCommunicationBanner from './assets/add-communication-banner.svg?component-solid';
import type { SetStoreFunction } from 'solid-js/store';

const OptionButton = twc.button`flex flex-1 flex-col items-center justify-center rounded-lg border p-2 capitalize outline-none aria-selected:border-current aria-selected:font-medium aria-selected:text-primary`;

export const AddNewCommunication = (props: {
  onTypeChange: (value: string) => void;
  onTargetChange: (value: string) => void;
  onTextMessageChange: SetStoreFunction<{ phone?: string | undefined; name?: string | undefined; subject?: string | undefined }>;
}) => {
  const { t } = useLocalization();

  const handleSelect = (entry: { id: string }) => {
    props.onTargetChange(entry.id);
  };

  return (
    <div class="thinscroll flex-1 overflow-auto">
      <AddCommunicationBanner class="w-full bg-safe-green" />
      <div class="px-6 py-8">
        <p class="mb-3 text-center font-medium text-text-level02">{t('I want to chat with')}:</p>
        <Tabs onChange={props.onTypeChange}>
          <Tabs.List class="mb-5 grid grid-cols-3 gap-1 text-xs text-title-gray">
            <Tabs.Trigger value="textMessage" as={OptionButton}>
              <IconAddCommunicationTextMessage />
              <span>{t('Text message')}</span>
            </Tabs.Trigger>
            <Tabs.Trigger value="lease" as={OptionButton}>
              <IconAddCommunicationLease />
              <span>{t('Lease')}</span>
            </Tabs.Trigger>
            <Tabs.Trigger value="vendor" as={OptionButton}>
              <IconAddCommunicationVendor />
              <span>{t('Vendor')}</span>
            </Tabs.Trigger>
            <Tabs.Trigger value="maintenanceRequest" as={OptionButton}>
              <IconAddCommunicationMaintenance />
              <span>{t('Maintenance')}</span>
            </Tabs.Trigger>
            <Tabs.Trigger value="tenant" as={OptionButton}>
              <IconAddCommunicationTenant />
              <span>{t('Tenant')}</span>
            </Tabs.Trigger>
            <Tabs.Trigger value="owner" as={OptionButton}>
              <IconAddCommunicationOwner />
              <span>{t('Owner')}</span>
            </Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="textMessage">
            <div class="flex w-full flex-wrap gap-3">
              <LabeledTextInput
                class="min-w-56 flex-1"
                label={t('Phone')}
                placeholder={t('Please enter')}
                onInput={(value) => props.onTextMessageChange('phone', value)}
                required
              />
              <LabeledTextInput
                class="min-w-56 flex-1"
                label={t('To')}
                placeholder={t('Please enter')}
                onInput={(value) => props.onTextMessageChange('name', value)}
              />
              <LabeledTextInput
                class="min-w-80 flex-1"
                label={t('Subject')}
                placeholder={t('Please enter')}
                onInput={(value) => props.onTextMessageChange('subject', value)}
              />
            </div>
          </Tabs.Content>
          <Tabs.Content value="lease">
            <LeaseSearch placeholder="Select a lease to start a conversation" onSelect={handleSelect} />
          </Tabs.Content>
          <Tabs.Content value="vendor">
            <VendorSearch placeholder="Select a vendor to start a conversation" onSelect={handleSelect} />
          </Tabs.Content>
          <Tabs.Content value="maintenanceRequest">
            <MaintenanceRequestSearch placeholder="Select a maintenance request to start a conversation" onSelect={handleSelect} />
          </Tabs.Content>
          <Tabs.Content value="tenant">
            <TenantSearch placeholder="Select a tenant to start a conversation" onSelect={handleSelect} />
          </Tabs.Content>
          <Tabs.Content value="owner">
            <OwnerSearch placeholder="Select an owner to start a conversation" onSelect={handleSelect} />
          </Tabs.Content>
        </Tabs>
      </div>
    </div>
  );
};

import { Show, splitProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { PolymorphicComponent } from '~/utils/types';

export const DeactivatedFlag: PolymorphicComponent<'span', { active?: boolean; class?: string; children?: never }> = (props) => {
  const { t } = useLocalization();
  const [, rest] = splitProps(props, ['as', 'class', 'active']);
  return (
    <Show when={props.active === false}>
      <Dynamic component={props.as || 'span'} class={cn('text-[.9em] text-current-alpha-8', props.class)} data-slot="status" {...rest}>
        ({t('Deactivated')})
      </Dynamic>
    </Show>
  );
};

import { useLocation, useNavigate } from '@solidjs/router';
import { createContext, useContext, createEffect, createSignal, createMemo, on } from 'solid-js';
import { getMenuItems } from './menuConfig';
import type { MenuItem } from './types/menuItem';
import type { JSX, Accessor, Setter } from 'solid-js';

export interface MenuContextType {
  menuItems: Accessor<MenuItem[]>;
  subMenuItems: Accessor<MenuItem[]>;
  isCollapsed: Accessor<boolean>;
  isActive: (item: MenuItem) => boolean;
  isSelected: (item: MenuItem) => boolean;
  setActiveMenuItem: (item?: MenuItem) => void;
  activeParentMenuTitle: Accessor<string>;
  setActiveMenuItemId: Setter<string | undefined>;
}

const MenuContext = createContext<MenuContextType>();

interface ProviderProps {
  children: JSX.Element;
}

export function MenuProvider(props: ProviderProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeMenuItemId, setActiveMenuItemId] = createSignal<string | undefined>(undefined);
  const [selectedMenuItems, setSelectedMenuItems] = createSignal<string[]>([]);

  const isActive = (item: MenuItem): boolean => {
    return activeMenuItemId() === item.id;
  };

  const isSelected = (item: MenuItem): boolean => {
    return selectedMenuItems().includes(item.id);
  };

  const menuItems = createMemo(getMenuItems);

  const subMenuItems = createMemo((): MenuItem[] => {
    const menuItem = menuItems().find((item) => item.id === activeMenuItemId());
    return menuItem?.subMenuItems ?? [];
  });

  const activeParentMenuTitle = createMemo((): string => {
    const menuItem = menuItems().find((item) => item.id === activeMenuItemId());
    return menuItem?.text ?? '';
  });

  const isCollapsed = createMemo(() => subMenuItems() && subMenuItems().length > 0);

  createEffect(
    on(
      () => location.pathname,
      (path) => {
        const items = getSelectedMenuItems(path, menuItems());
        setSelectedMenuItems(items);
        setActiveMenuItemId(items[0]);
      }
    )
  );

  const response: MenuContextType = {
    menuItems,
    subMenuItems,
    isCollapsed,
    isActive,
    isSelected,
    setActiveMenuItem: (item?: MenuItem) => {
      if (item) {
        setActiveMenuItemId(item.id);
        if (item.canNaviate) {
          navigate(item.replace || item.path);
        }
      } else {
        setActiveMenuItemId(selectedMenuItems()[0]);
      }
    },
    activeParentMenuTitle,
    setActiveMenuItemId,
  };

  return <MenuContext.Provider value={response}>{props.children}</MenuContext.Provider>;
}

export const getSelectedMenuItems = (path: string, menuItems: MenuItem[]): string[] => {
  const normalizedPath = normalizePath(path);
  return retrieveElementsInPath(menuItems, normalizedPath);
};

const retrieveElementsInPath = (menuItems: MenuItem[], path: string): string[] => {
  const result: string[] = [];
  let elementsToSearch: MenuItem[] | undefined = menuItems;
  while (elementsToSearch) {
    let didRefreshSearchLists = false;
    for (const menuItem of elementsToSearch) {
      if ((path.includes(menuItem.path) && menuItem.path !== '/') || path === menuItem.path) {
        result.push(menuItem.id);
        elementsToSearch = menuItem.subMenuItems;
        didRefreshSearchLists = true;
        break;
      }
    }
    if (!didRefreshSearchLists) break;
  }
  return result;
};

const normalizePath = (path: string): string => {
  let result = path.replace(/\/+/g, '/');
  if (!result.startsWith('/')) {
    result = '/' + result;
  }
  return result;
};

export function useMenu() {
  return useContext(MenuContext);
}

import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

type UnitBalancesProps = {
  class?: string;
};

export const UnitBalancesSkeleton: Component<UnitBalancesProps> = (props) => {
  const { t } = useLocalization();
  return (
    <Panel title={t('Balances')} class={cn('flex flex-col', props.class)}>
      <div class="flex-1 px-6 pb-4 pt-3">
        <div class="skeleton-shimmer h-12 rounded-lg " />
      </div>
    </Panel>
  );
};

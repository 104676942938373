import { UseCase } from './useCase';
import type { PMSelectionRequest } from './types/pmSelectionChangeRequest';

export class PMSelectionChangedUseCase extends UseCase {
  public async runLogic(request: PMSelectionRequest) {
    if (!request) {
      return;
    }
    this.state.pdfs.signers[request.index].isPropertyManager = request.isPropertyManager;
  }
}

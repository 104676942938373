import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { lateFeesSettingsRepository } from '~/repositories/settings/lateFeesSettingsRepository';
import type { Accessor } from 'solid-js';

interface LateFeesSettingsContextValue {
  addLateFeesSettings: (payload: MagicDoor.Api.LateFeesSettingsDto) => Promise<void>;
  addLateFeesSettingsByLevel: (level: string, levelId: string, payload: MagicDoor.Api.LateFeesSettingsDto) => Promise<void>;
  getLateFeesSettings: () => Promise<MagicDoor.Api.LateFeesSettingsDto | undefined>;
  getLateFeesSettingsByLevel: (level: string, levelId: string) => Promise<MagicDoor.Api.LateFeesSettingsDto | undefined>;
  getAllLateFeesSettings: () => Promise<MagicDoor.Api.LateFeesSettingsDtoSettingsWithOverridesDto | undefined>;
  isLoading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
  lateFeesSettingsWithNoOverrides: Accessor<MagicDoor.Api.LateFeesSettingsDto | undefined>;
  allLateFeesSettings: Accessor<MagicDoor.Api.LateFeesSettingsDtoSettingsWithOverridesDto | undefined>;
  deleteLateFeesSettingsByLevel: (level: string, levelId: string) => Promise<void>;
}

export const [LateFeesSettingsProvider, useLateFeesSettings] = createMagicDoorContext<LateFeesSettingsContextValue>(
  'LateFeesSettings',
  () => {
    const repo = new lateFeesSettingsRepository();
    const [lateFeesSettingsWithNoOverrides, setLateFeesSettingsWithNoOverrides] = createSignal<
      MagicDoor.Api.LateFeesSettingsDto | undefined
    >(undefined);
    const [allLateFeesSettings, setAllLateFeesSettings] = createSignal<
      MagicDoor.Api.LateFeesSettingsDtoSettingsWithOverridesDto | undefined
    >(undefined);
    const [isLoading, setIsLoading] = createSignal<boolean>(false);
    const [error, setError] = createSignal<Error | undefined>(undefined);

    const addLateFeesSettings = async (payload: MagicDoor.Api.LateFeesSettingsDto) => {
      setIsLoading(true);
      try {
        await repo.addLateFeesSettings(payload);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    const addLateFeesSettingsByLevel = async (level: string, levelId: string, payload: MagicDoor.Api.LateFeesSettingsDto) => {
      setIsLoading(true);
      try {
        await repo.addLateFeesSettingsByLevel(level, levelId, payload);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    const getLateFeesSettings = async (): Promise<MagicDoor.Api.LateFeesSettingsDto | undefined> => {
      setIsLoading(true);
      try {
        const result = await repo.getLateFeesSettings();
        setLateFeesSettingsWithNoOverrides(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const getLateFeesSettingsByLevel = async (level: string, levelId: string): Promise<MagicDoor.Api.LateFeesSettingsDto | undefined> => {
      setIsLoading(true);
      try {
        const result = await repo.getLateFeesSettingsByLevel(level, levelId);
        setLateFeesSettingsWithNoOverrides(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const getAllLateFeesSettings = async (): Promise<MagicDoor.Api.LateFeesSettingsDtoSettingsWithOverridesDto | undefined> => {
      setIsLoading(true);
      try {
        const result = await repo.getAllLateFeesSettings();
        setAllLateFeesSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const deleteLateFeesSettingsByLevel = async (level: string, levelId: string) => {
      setIsLoading(true);
      try {
        await repo.deleteLateFeesSettingsByLevel(level, levelId);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    return {
      deleteLateFeesSettingsByLevel,
      addLateFeesSettings,
      addLateFeesSettingsByLevel,
      getLateFeesSettings,
      getLateFeesSettingsByLevel,
      getAllLateFeesSettings,
      allLateFeesSettings,
      isLoading,
      lateFeesSettingsWithNoOverrides,
      error,
    };
  }
);

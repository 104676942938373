import { For, Show } from 'solid-js';
import { Empty } from '~/components/common/Empty';
import { SearchListInput } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { AIRunbookItem } from '~/pages/ai-runbooks/list-runbooks/components/AiRunbookItem';
import type { ListRunBookDto } from '~/swagger/Api';

export const AIRunbookTable = (props: { title: string; runbooks?: ListRunBookDto[] }) => {
  const { t } = useLocalization();

  const { getRunBooks, changeRunbookStatus, runBooks } = useRunBooks();

  const handleChange = (value?: string) => {
    getRunBooks({ title: value });
  };

  return (
    <div>
      <div class="flex items-center justify-between gap-4 rounded-lg bg-white px-6 py-4 text-lg font-semibold text-text-level01">
        <div class="flex gap-4">
          <span>{props.title}</span>
          <span class="text-purple">{props.runbooks?.length ?? 0}</span>
        </div>

        <SearchListInput placeholder={t('Search')} onChange={handleChange} />
      </div>

      <div class="mt-4 grid grid-cols-2 items-stretch justify-between gap-5 lg:grid-cols-3 xl:grid-cols-4">
        <Show
          when={!runBooks.loading}
          fallback={[<AIRunbookItem loading />, <AIRunbookItem loading />, <AIRunbookItem loading />, <AIRunbookItem loading />]}>
          <For
            each={props.runbooks}
            fallback={
              <div class="col-span-full flex justify-center pt-10">
                <Empty />
              </div>
            }>
            {(runbook) => (
              <AIRunbookItem
                runbook={runbook}
                onChangeStatus={(checked) => {
                  changeRunbookStatus(runbook.id, checked);
                }}
              />
            )}
          </For>
        </Show>
      </div>
    </div>
  );
};

import { Show } from 'solid-js';
import IconTitle from '~/assets/images/aiRunBooks/title.svg?component-solid';
import { Panel } from '~/components/common/Panels';
import { Skeleton } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
export const RunbookTitle = () => {
  const { t } = useLocalization();
  const { runBook } = useRunBooks();

  return (
    <Panel
      title={
        <Show when={!runBook.loading} fallback={<Skeleton class="h-7 w-64" />}>
          <div class="mb-1 flex items-center gap-1">
            <IconTitle />
            <span class="text-sm font-normal text-text-level03">{t('Title')}: </span>
            {runBook()?.title}
          </div>
        </Show>
      }
      description={
        <Show when={!runBook.loading} fallback={<Skeleton class="h-4 w-72 border-t-2 border-white" />}>
          <div class="inline-flex items-center rounded-full bg-light-essential/20 px-2 py-1 text-xs text-essential-colour">
            {runBook()?.publicRunBook ? t(`MagicDoor runbook`) : t(`Custom runbook`)}
          </div>
        </Show>
      }
    />
  );
};

import { Show } from 'solid-js';
import { AutoPaymentAmount, AutoPaymentStatus, AutoPaymentType } from '~/components/auto-payments';
import { Table } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { dateFormat } from '~/utils/date';

interface AutoPaymentTableProps {
  records: MagicDoor.Api.HydratedTenantAutoPayDto[];
  isLoading: boolean;
  onRowClick: (record: MagicDoor.Api.HydratedTenantAutoPayDto) => void;
}

export const AutoPaymentsTable = (props: AutoPaymentTableProps) => {
  const { t } = useLocalization();

  return (
    <div class="thinscroll overflow-x-auto">
      <Table
        data={props.records}
        loading={props.isLoading}
        onRowClick={props.onRowClick}
        columns={[
          {
            title: t('Payment type'),
            headerClass: 'w-[30%]',
            render: (record) => <AutoPaymentType entry={record} />,
          },
          {
            title: t('Status'),
            headerClass: 'w-[20%]',
            render: (record) => <AutoPaymentStatus entry={record} />,
          },
          {
            title: t('Amount'),
            headerClass: 'w-[20%]',
            render: (record) => <AutoPaymentAmount entry={record} />,
          },
          {
            title: t('Start and expire date'),
            headerClass: 'w-[30%]',
            render: (record) => (
              <div>
                <p>{`${t('Next payment')}: ${dateFormat('M/D/YY', record.nextPayment)}`}</p>
                <Show when={record.end}>
                  <p class="text-xs text-text-level03">{`${t('Expire date')}: ${dateFormat('M/D/YY', record.end)}`}</p>
                </Show>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

import { useNavigate, useParams } from '@solidjs/router';
import { createMemo } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconActicateLease from '~/assets/images/units/acticateLease.svg?component-solid';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import IconEndLease from '~/assets/images/units/endLease.svg?component-solid';
import IconEditLease from '~/assets/images/units/lease/editLease.svg?component-solid';
import IconStartEviction from '~/assets/images/units/lease/startEviction.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import { Panel } from '~/components/common/Panels';
import { LeasePeriod, LeaseStatus } from '~/components/leases';
import { getDeposit } from '~/components/leases/utils';
import { formatTenantNames } from '~/components/tenants';
import { Table, TableActions } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLease } from '~/contexts/local';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type CurrentLeaseProps = {
  class?: string;
  currentLeases: MagicDoor.Api.HydratedLeaseDto[];
  isLoading: boolean;
};

export const CurrentLease: Component<CurrentLeaseProps> = (props) => {
  const { t } = useLocalization();

  const navigate = useNavigate();
  const params = useParams();

  const { setLease } = useLease();

  const query = () => new URLSearchParams(params);

  const columns = createMemo<TableColumns<MagicDoor.Api.HydratedLeaseDto>>(() => [
    {
      title: t('Lease term'),
      render: (item) => (
        <>
          <LeaseStatus lease={item} />
          <LeasePeriod lease={item} />
        </>
      ),
    },
    {
      title: t('Tenants'),
      render: (item) => {
        return <div>{formatTenantNames(item.tenants)}</div>;
      },
    },
    {
      title: t('Rent'),
      headerClass: 'text-right',
      class: 'text-right',
      render: (item) => <span class="text-sm">{currency(item.currentRent)}</span>,
    },
    {
      title: t('Deposit'),
      headerClass: 'text-right',
      class: 'text-right',
      render: (item) => <span class="text-sm">{getDeposit(item)}</span>,
    },
    {
      title: t('Last payment'),
      render: (item) => <span class="text-sm">{item.lastPaymentDate ? dateFormat('', item.lastPaymentDate) : '--'}</span>,
    },
    {
      title: t('Balances'),
      headerClass: 'text-right',
      class: 'text-right',
      render: (item) => <CnMoney money={item.balance} />,
    },
    {
      title: '',
      class: 'relative',
      render: (item) => (
        <TableActions
          actions={[
            {
              icon: IconActicateLease,
              label: t('Extend lease'),
              href: `/leasing/leases/${item.id}/edit-lease`,
            },
            {
              icon: IconEditLease,
              label: t('Edit lease'),
              href: `/leasing/leases/${item.id}/edit-lease`,
            },
            {
              icon: IconStartEviction,
              label: t('Start eviction'),
              onClick: () => {
                setLease(item);
                navigate('start-eviction', { scroll: false });
              },
            },
            {
              icon: () => <IconEndLease class="text-text-level03" />,
              label: t('End lease'),
              onClick: () => {
                setLease(item);
                navigate('end-lease', { scroll: false });
              },
            },
            {
              icon: IconEditLease,
              label: t('Add service bill'),
              onClick: () => {
                setLease(item);
                navigate('add-service-bill', { scroll: false });
              },
            },
            {
              icon: IconDelete,
              label: t('Delete lease'),
              onClick: () => {
                setLease(item);
                navigate('delete-lease', { scroll: false });
              },
            },
          ]}
        />
      ),
    },
  ]);

  return (
    <Panel
      title={t('Current lease')}
      class={props.class}
      actions={
        <div class="flex gap-2">
          <LinkButton href={`/leasing/leases/new?${query()}`} class="ml-auto text-sm">
            <IconPlus /> {t('Add new lease')}
          </LinkButton>
        </div>
      }>
      <div class="thinscroll overflow-x-auto">
        <Table columns={columns()} loading={props.isLoading} data={props.currentLeases} rowLink={(item) => `/leasing/leases/${item.id}`} />
      </div>
    </Panel>
  );
};

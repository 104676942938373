import { ValidationError } from '~/errors';
import { BaseRestRepository } from './baseRestRepository';

export function validateId(id: string, entityName = 'Entity'): void {
  if (!id) throw new ValidationError(`${entityName} ID must be provided`);
}

const LEASES_URL = '/api/leases';

export class LeaseRecurringChargeRepository extends BaseRestRepository {
  public async getRecurringCharges(leaseId: string): Promise<MagicDoor.Api.HydratedRecurringLeaseChargeDto[]> {
    const url = `${LEASES_URL}/${leaseId}/charges/recurring`;
    const response = await this.fetchWithAuth(url, {
      method: 'GET',
    });
    return this.getJsonResponse(response);
  }

  public async addRecurringCharge(
    leaseId: string,
    payload: MagicDoor.Api.CreateRecurringLeaseChargeDto
  ): Promise<MagicDoor.Api.RecurringLeaseChargeDto> {
    const url = `${LEASES_URL}/${leaseId}/charges/recurring`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async updateRecurringCharge(
    leaseId: string,
    recurringId: string,
    payload: MagicDoor.Api.UpdateRecurringLeaseChargeDto
  ): Promise<void> {
    validateId(recurringId);
    if (!recurringId) throw new ValidationError('Recurring ID must be provided');
    const url = `${LEASES_URL}/${leaseId}/charges/recurring/${recurringId}`;
    await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
  }

  public async deleteRecurringCharge(leaseId: string, recurringId: string): Promise<void> {
    validateId(recurringId);
    if (!recurringId) throw new ValidationError('Recurring ID must be provided');
    const url = `${LEASES_URL}/${leaseId}/charges/recurring/${recurringId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    if (!response.ok) {
      console.error(await response.json());
    }
  }
}

import { createEffect, createSignal, Match, on, Switch } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import { Description } from '~/components/common/Description';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Subsection } from '~/components/common/Subsection';
import { LabeledLeaseSearch } from '~/components/leases/LeaseSearch';
import { LabeledPropertySearch } from '~/components/properties/PropertySearch';
import { LabeledUnitSearch } from '~/components/units/UnitSearch';
import { LabeledVendorSearch } from '~/components/vendors/VendorSearch';
import { useLocalization } from '~/contexts/global';
import { useViolations } from '~/contexts/local';
import { AssociationViolationStatus } from '~/swagger/Api';
import { enumToOptions } from '~/utils/constant';
import type { SubsectionListItem } from '~/components/common/Subsection';
import type { UploaderFile } from '~/components/common/Upload/Interface';

export const EssentialInfomation = () => {
  const { t } = useLocalization();

  const { setNewFiles } = useViolations();

  const [current, setCurrent] = createSignal<string>('property');
  const { resetFields } = useFormContext();

  const subsectionList: SubsectionListItem[] = [
    {
      label: 'Property',
      key: 'property',
    },
    {
      label: 'Unit',
      key: 'unit',
    },
    {
      label: 'Lease',
      key: 'lease',
    },
  ];

  createEffect(
    on(
      current,
      (v) => {
        if (v === 'property') {
          resetFields(['unitId', 'leaseId']);
        } else if (v === 'unit') {
          resetFields(['propertyId', 'leaseId']);
        } else {
          resetFields(['propertyId', 'unitId']);
        }
      },
      { defer: true }
    )
  );

  return (
    <div class="flex flex-col gap-8">
      <FormItem
        class="w-full"
        label={t(`Description`)}
        rules={[{ required: true, message: t(`Please input description`) }]}
        formFieldName="description"
        component={Description}
        placeholder={t('Please enter')}
        onFilesListChange={(files: UploaderFile[]) => {
          setNewFiles(files);
        }}
      />

      <div class="grid grid-cols-2 gap-8">
        <div class="flex flex-col">
          <div class="mb-2 flex text-sm uppercase text-label">{t('Option')}</div>
          <Subsection list={subsectionList} current={[current, setCurrent]} />
        </div>
        <Switch>
          <Match when={current() === 'property'}>
            <FormItem<any>
              label={t(`Property`)}
              formFieldName="propertyId"
              component={LabeledPropertySearch}
              rules={[{ required: true, message: t(`Select property`) }]}
              placeholder={t('Select property')}
            />
          </Match>
          <Match when={current() === 'unit'}>
            <FormItem<any>
              label={t(`Unit`)}
              formFieldName="unitId"
              component={LabeledUnitSearch}
              rules={[{ required: true, message: t(`Select unit`) }]}
              placeholder={t('Select unit')}
            />
          </Match>
          <Match when={current() === 'lease'}>
            <FormItem<any>
              label={t(`Select lease`)}
              formFieldName="leaseId"
              component={LabeledLeaseSearch}
              rules={[{ required: true, message: t(`Please select lease`) }]}
              placeholder={t('Please select lease')}
              filter={{
                ended: false,
              }}
            />
          </Match>
        </Switch>
      </div>

      <FormItem<any>
        class="w-full"
        label={t(`HOA`)}
        formFieldName="hoaVendorId"
        component={LabeledVendorSearch}
        placeholder={t('Please select vendor')}
        rules={[{ required: true, message: t('Please select HOA vendor') }]}
      />

      <div class="grid grid-cols-2 gap-8">
        <FormItem
          class="col-span-full lg:col-span-1"
          label={t('Violation date')}
          placeholder={t('Please select')}
          formFieldName="violationDate"
          component={DueDateInputField}
          rules={[{ required: true, message: t('Please select violation date') }]}
        />
        <FormItem
          label={t('Violation receive date')}
          placeholder={t('Please select')}
          component={DueDateInputField}
          formFieldName="violationReceivedDate"
          class="col-span-full lg:col-span-1"
          rules={[{ required: true, message: t('Please select violation receive date') }]}
        />
        <FormItem
          label={t('Fix by date')}
          placeholder={t('Please select')}
          component={DueDateInputField}
          formFieldName="fixByDate"
          class="col-span-full lg:col-span-1"
        />
        <FormItem
          label={t('Status')}
          options={enumToOptions(AssociationViolationStatus)}
          placeholder={t('Please select')}
          component={LabeledSelect}
          formFieldName="status"
          rules={[{ required: true, message: t('Please select status') }]}
        />
        {/* TODO: BACKEND SAID REMOVE FOR NOW
        <FormItem
          label={t('Association fine')}
          placeholder={t('Please enter')}
          component={LabeledTextInput}
          prepend="$"
          formFieldName="associationFine"
          getValueFromEvent={(e) => +e}
        /> */}
        <FormItem
          class="col-span-full"
          label={t('HOA violation reference #')}
          placeholder={t('Please enter')}
          component={LabeledTextInput}
          formFieldName="reference"
        />
      </div>
    </div>
  );
};

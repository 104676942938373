import { Show } from 'solid-js';
import { AIProcessing, AIProcessed } from '~/pages/maintenance-request/list-maintenance-request/list-components/StatusColumn';
import type { Component } from 'solid-js';

export const MaintenanceRequestTitle: Component<{ class?: string; id: string; title: string; aiProcessed?: boolean }> = (props) => (
  <div class={props.class}>
    <div class="mb-1 flex items-center">
      <div class="mr-2 cursor-pointer font-medium text-link">{props.title}</div>
      <Show when={props.aiProcessed} fallback={<AIProcessing />}>
        <AIProcessed />
      </Show>
    </div>
    <div class="text-gray-400">#{props.id}</div>
  </div>
);

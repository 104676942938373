import { useNavigate } from '@solidjs/router';
import { onMount } from 'solid-js';
import { usePortfolios, useProperties } from '~/contexts/global';
import { AddProperty } from '~/pages/properties/components/AddNewProperty';
import type { Component } from 'solid-js';

export const AddPropertyModal: Component = () => {
  const navigate = useNavigate();

  const { current, refetch } = usePortfolios();
  const { mutateProperty } = useProperties();

  onMount(() => {
    mutateProperty(undefined);
  });

  const handleClose = () => {
    refetch();
    const currentId = current()?.id;
    navigate(currentId ? `/portfolios/${currentId}` : '/portfolios', { scroll: false });
  };

  return <AddProperty onClose={handleClose} />;
};

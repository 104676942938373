import { createEffect, createMemo, on } from 'solid-js';
import { Avatar } from '~/components/common/Avatar';
import { Form, FormItem } from '~/components/common/BetterForm';
import { useForm } from '~/components/common/BetterForm/context';
import DisplayAddress from '~/components/common/DisplayAddress';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { PaymentAmount } from '~/components/common/PaymentAmount';
import { BaseModal } from '~/components/modals/BaseModal';
import { Footer } from '~/components/modals/Footer';
import { getTenantFullName } from '~/components/tenants/utils';
import { toast } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { currentUpdateType } from '~/utils/constant';
import { validArr } from '~/utils/tool';

function getFirstUnitFromInterests(interests?: MagicDoor.Api.RentalApplicationInterestDto[] | null) {
  if (!validArr(interests) || !interests?.length) return;
  return {
    address: interests[0].property?.address,
    unit: interests[0].unit,
  };
}

export const ReceivePaymentModal = () => {
  const { applicationStore, setApplicationStore } = useRentalApplication();
  const { t } = useLocalization();
  const form = useForm();

  createEffect(
    on([() => applicationStore.currentUpdateType], () => {
      if (applicationStore.currentUpdateType !== currentUpdateType.rental) return;
      form.setFieldsValue({
        residentialHistory: applicationStore.application?.residentialHistory,
      });
    })
  );

  function onHide() {
    setApplicationStore('currentUpdateType', null);
    form.resetFields();
  }

  const onOk = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;
    toast('Not implemented yet');
    onHide();
  };

  const unitInfo = createMemo(() => getFirstUnitFromInterests(applicationStore.application?.interests));

  return (
    <BaseModal
      class="w-modalSm"
      title={t('Receive payment')}
      visible={applicationStore.currentUpdateType === currentUpdateType.receivePayment}
      onClose={() => {
        setApplicationStore('currentUpdateType', null);
      }}>
      <div class="flex flex-col gap-2 overflow-y-auto">
        <div class="p-8 pb-0">
          <div class="mb-8 flex gap-2 rounded bg-input p-2">
            <Avatar class="size-9" name={getTenantFullName(applicationStore.application)} />
            <div class="flex flex-col">
              <span class="text-sm font-medium text-text-level01">{getTenantFullName(applicationStore.application)}</span>
              <div class="flex gap-1 text-xs text-green">
                <DisplayAddress class="w-auto" address={unitInfo()?.address} />, <span>{unitInfo()?.unit.name}</span>
              </div>
            </div>
          </div>
          <Form defaultForm={form} class="grid grid-cols-2 gap-8">
            <FormItem
              formFieldName="paymentMethod"
              class="col-span-1"
              placeholder={t('Please select')}
              label={t('Payment method')}
              component={DropdownMenu}
            />
            <FormItem
              formFieldName="paymentNumber"
              placeholder={t('Please select')}
              label={t('Payment Number')}
              component={LabeledTextInput}
              class="col-span-1"
            />
            <FormItem
              formFieldName="paymentMethod"
              placeholder={t('Please select')}
              label={t('Amount received')}
              component={DropdownMenu}
              class="col-span-1"
            />
          </Form>
        </div>
        <PaymentAmount label={t('Amount received')} mount={12} />
      </div>
      <Footer onOk={onOk} okProps={{ loading: applicationStore.updateLoading }} onCancel={onHide} />
    </BaseModal>
  );
};

import { Route, Routes } from '@solidjs/router';
import { CompanyRevenueTabRoutes } from './CompanyRevenueTabRoutes';
import { AddManagementFee } from './management-fee-tab/add-management-fee/AddManagementFee';
import { AddManualManagementFeesModal } from './management-fee-tab/add-manual-management-fee/AddManualManagementFeesModal';
import { ManagementFeeDetail } from './management-fee-tab/management-fee-detail/ManagementFeeDetail';
import { AddPayOutModal } from './pay-outs-tab/add-pay-outs';
import { PayoutsCalculate } from './pay-outs-tab/pay-outs-calculate/PayoutsCalculate';

export const CompanyRevenueRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/management-fees/add-manual" element={<AddManualManagementFeesModal />} />
        <Route path="/management-fees/add" element={<AddManagementFee />} />
        <Route path="/management-fees/:managementFeeId" element={<ManagementFeeDetail />} />

        <Route path="/pay-outs/calculate" element={<PayoutsCalculate />} />

        <Route path="*" component={CompanyRevenueTabRoutes} />
      </Routes>

      <Routes>
        <Route path="/pay-outs/add" element={<AddPayOutModal />} />
      </Routes>
    </>
  );
};

import { useNavigate, useParams, A } from '@solidjs/router';
import { Show, createSignal } from 'solid-js';
import IconDelete from '~/assets/images/common/deleteLines.svg';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import ConfirmationModal from '~/components/modals/ConfirmationModal';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBankAccount } from '~/contexts/local';

const BankSummaryActions = () => {
  const navigate = useNavigate();
  const { bankAccountId } = useParams();
  const { t } = useLocalization();

  const { deleteBankAccount } = useBankAccount();
  const [showConfirmationModal, setShowConfirmationModal] = createSignal<boolean>(false);

  const onDeleteBankAccount = async () => {
    await deleteBankAccount(bankAccountId);
    navigate('/accounting/bank-accounts');
    toast.success(t('{name} has been deleted successfully', { name: 'BankAccount' }));
  };

  const handleDeleteClick = (): void => {
    setShowConfirmationModal(true);
  };

  return (
    <div class="flex items-center justify-center gap-2">
      <button type="button" onClick={handleDeleteClick}>
        <img src={IconDelete} />
      </button>
      <A href={`/accounting/bank-accounts/${bankAccountId}/edit`}>
        <IconEdit class="text-link" />
      </A>
      <Show when={showConfirmationModal()}>
        <ConfirmationModal
          onConfirm={onDeleteBankAccount}
          onCancel={() => setShowConfirmationModal(false)}
          message={t('Are you sure you want to delete this bankAccount?')}
        />
      </Show>
    </div>
  );
};

export default BankSummaryActions;

import { Panel } from '~/components/common/Panels';
import TableSkeleton from '~/components/skeletons/TableSkeleton';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
type OngoingLeasesTableSkeletonProps = {
  class?: string;
};

export const OngoingLeasesTableSkeleton: Component<OngoingLeasesTableSkeletonProps> = (props) => {
  const { t } = useLocalization();

  const columns = [
    {
      title: t('Lease term'),
    },
    {
      title: t('Tenants'),
    },
    {
      title: t('Rent'),
    },
    {
      title: t('Deposit'),
    },
    {
      title: t('Last payment'),
    },
    {
      title: t('Balances'),
    },
    {
      title: '',
    },
  ];

  return (
    <Panel title={t('Leases')} class={props.class}>
      <TableSkeleton columns={columns} rowCount={2} />
    </Panel>
  );
};

import { useNavigate, useParams, useSearchParams } from '@solidjs/router';
import { Show, createEffect, createMemo, createSignal } from 'solid-js';
import { CancelWithConfirmation } from '~/components/modals';
import MiddleModal from '~/components/modals/MiddleModal';
import { toast } from '~/components/ui';
import { useLocalization, useChartOfAccounts } from '~/contexts/global';
import { AddOrEditChartOfAccount } from './add-components';
import type { AccountType } from './list-components/ChartOfAccountsTable';

export const AddOrEditChartOfAccountModal = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const { setCurrentId, current, addChartOfAccount, editChartOfAccount, chartOfAccounts } = useChartOfAccounts();

  const [showConfirmationModal, setShowConfirmationModal] = createSignal<boolean>(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);

  const isEditing = createMemo(() => !!params.chartOfAccountId);
  const accountType = createMemo<AccountType | undefined>(() => params.type as AccountType);

  const handleClose = () => {
    navigate('/accounting/chart-of-accounts', { scroll: false, replace: true });
    setCurrentId(undefined);
  };

  const handleSave = async (data: MagicDoor.Api.CreateChartOfAccountDto | MagicDoor.Api.UpdateChartOfAccountDto) => {
    if (current()?.id && current()?.isDefault) {
      toast.error(t('Default chart of accounts cannot be edited'));
      return;
    }

    try {
      const result = await (current()?.id
        ? editChartOfAccount(current()?.id || '', data as MagicDoor.Api.UpdateChartOfAccountDto)
        : addChartOfAccount({ ...data, type: accountType() } as MagicDoor.Api.CreateChartOfAccountDto));

      if (result) {
        toast.success(t(current()?.id ? 'Chart of account updated successfully' : 'Chart of account added successfully'));
        handleClose();
      } else {
        throw new Error(`Failed to ${current()?.id ? 'update' : 'add'} chart of account`);
      }
    } catch (err) {
      toast.error(t('An error occurred while saving the chart of account'));
      setShowConfirmationModal(true);
    }
  };

  const filteredParentOptions = createMemo(() => chartOfAccounts()?.filter((account) => account.type === accountType()));

  createEffect(() => {
    if (isEditing()) {
      setCurrentId(params.chartOfAccountId);
    } else {
      setCurrentId(undefined);
    }
  });

  return (
    <MiddleModal
      confirmation={showCloseConfirmation()}
      onClose={handleClose}
      confirmationTitle={t('Are you sure you want to cancel?')}
      confirmationMessage={t('If you cancel the addition, the information already filled in cannot be saved')}>
      <Show when={accountType()} fallback={<div>{t('Invalid account type')}</div>}>
        <AddOrEditChartOfAccount
          onClose={handleClose}
          setShowCloseConfirmation={setShowCloseConfirmation}
          showCloseConfirmation={showCloseConfirmation()}
          onSave={handleSave}
          parentOptions={filteredParentOptions()}
          type={accountType()}
          defaultParentId={isEditing() ? undefined : searchParams.parentId}
        />
      </Show>

      <Show when={showConfirmationModal()}>
        <CancelWithConfirmation
          onConfirm={handleClose}
          onCancel={() => setShowConfirmationModal(false)}
          message={t('Are you sure you want to cancel?')}
        />
      </Show>
    </MiddleModal>
  );
};

import { For } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { useCreateModel } from '~/utils/hooks';
import type { Component, Setter } from 'solid-js';

export type SubsectionListItem = {
  label: string;
  key: string;
};
type SubsectionProps = {
  list: SubsectionListItem[];
  class?: string;
  itemClass?: string;
  current: Setter<string>[];
};
export const Subsection: Component<SubsectionProps> = (props) => {
  const { t } = useLocalization();

  let subsectionRef!: HTMLDivElement;
  const [current, setCurrent] = useCreateModel(props, 'current', '');

  return (
    <div ref={subsectionRef} class={cn('relative flex h-[38px] items-center overflow-hidden rounded-lg bg-inputbox-bg p-1.5', props.class)}>
      <For each={props.list}>
        {(item) => (
          <div
            class={cn('flex h-full flex-1 cursor-pointer items-center justify-center rounded-lg text-sm transition-all', props.itemClass)}
            classList={{
              'font-normal text-auxiliary-text': current() !== item.key,
              'font-medium text-title-gray bg-white': current() === item.key,
            }}
            data-key={item.key}
            onClick={() => setCurrent(item.key)}>
            {t(item.label)}
          </div>
        )}
      </For>
    </div>
  );
};

import { DeactivateModal } from '~/components/modals';
import { getTenantFullName } from '~/components/tenants';
import { toast } from '~/components/ui';
import { useLocalization, useTenants } from '~/contexts/global';
import type { Component } from 'solid-js';

export const DeactivateTenant: Component<{
  tenant?: { id: string; firstName?: string | null; lastName?: string | null };
  onClose?: () => void;
}> = (props) => {
  const { t } = useLocalization();
  const { deactivateTenant } = useTenants();

  const handleConfirm = async () => {
    if (props.tenant == null) return;
    await deactivateTenant(props.tenant.id, true);
    toast.success(t('{name} has been deactivated successfully', { name: t('Tenant') }));
    props.onClose?.();
  };

  return (
    <DeactivateModal visible={props.tenant != null} onConfirm={handleConfirm} onCancel={props.onClose}>
      {t(
        'Deactivating <b>“{name}”</b> Tenant will also <b>move they out of all Leases</b>, and the data will not be displayed in the report.',
        { name: getTenantFullName(props.tenant) }
      )}
    </DeactivateModal>
  );
};

import { For, Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import IconField from '~/components/common/IconField';
import { Panel } from '~/components/common/Panels/Panel';
import { ListFileThumbnailWithPreview } from '~/components/file-attachments/ListFileThumbnailWithPreview';
import { IconCalendar, IconCircleDollar, IconMessageSquareText, IconSquarePen, Skeleton } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useViolations } from '~/contexts/local';
import { ViolationStatus } from '~/pages/violations/list-violations';
import { AssociationViolationStatus } from '~/swagger/Api';
import { twc } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';

const Title = twc.div`mb-3 mt-6 flex items-center gap-1 text-sm text-text-level03`;

const IconFieldWrapper = twc.div`flex w-60 gap-2 rounded-lg border border-partingline px-4 py-3`;

export const ViolationOverviewTab: Component = () => {
  const { t } = useLocalization();

  const { violation } = useViolations();

  const dateFields = () => [
    {
      title: 'Violation date',
      value: violation()?.violationDate,
    },
    {
      title: 'Violation receive date',
      value: violation()?.violationReceivedDate,
    },
    ...(violation()?.fixByDate
      ? [
          {
            title: 'Fix by date',
            value: violation()?.fixByDate,
          },
        ]
      : []),
  ];

  return (
    <div class="flex flex-col gap-5">
      <Panel
        title={
          <Show when={!violation.loading} fallback={<Skeleton class="h-7 w-64" />}>
            <div class="flex items-center gap-2">
              <div>{violation()?.title}</div>
              <ViolationStatus status={violation()?.status ?? AssociationViolationStatus.InProgress} />
            </div>
          </Show>
        }
        actions={
          <>
            <Button href={`/associations/violations/${violation()?.id}/edit`} variant="outlined" color="primary">
              <IconSquarePen class="size-5" />
              {t('Edit violation')}
            </Button>
          </>
        }
        description={
          <Show when={!violation.loading} fallback={<Skeleton class="h-4 w-72 border-t-2 border-white" />}>
            {`${violation()?.property?.name} - ${violation()?.unit?.name || ''}`}
          </Show>
        }
        actionClass="flex gap-2">
        <div class="flex flex-col px-5  py-6">
          <div class="rounded-lg bg-light-gray p-5">
            <Show when={violation.loading} fallback={<div class="font-medium normal-case text-title-gray">{violation()?.description}</div>}>
              <Skeleton />
              <Skeleton class="mt-1 w-2/3" />
            </Show>
            <ListFileThumbnailWithPreview loading={violation.loading} files={violation()?.files} />
          </div>

          <Title>
            <IconMessageSquareText class="size-4" />
            {t('Date')}
          </Title>

          <div class="flex flex-wrap gap-4">
            <For each={dateFields()}>
              {(date) => (
                <IconFieldWrapper>
                  <IconField
                    iconRounded={true}
                    name={t(date.title)}
                    loading={violation.loading}
                    value={dateFormat(t('MM/DD/YYYY'), date.value ?? emptyPlaceholder)}
                    src={IconCalendar}
                  />
                </IconFieldWrapper>
              )}
            </For>
          </div>

          <Title>
            <IconCircleDollar class="size-4" />
            {t('Bill')}
          </Title>

          <IconFieldWrapper>
            <IconField
              iconRounded={true}
              name={t('Association fine')}
              loading={violation.loading}
              value={currency(violation()?.associationFine ?? emptyPlaceholder)}
              src={IconCircleDollar}
            />
          </IconFieldWrapper>
        </div>
      </Panel>
    </div>
  );
};

import { Route, Routes } from '@solidjs/router';
import { createEffect } from 'solid-js';
import { TabLinks } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBills } from '~/contexts/local';
import { BillsListView } from './BillsListView';

export const ListBillsPage = () => {
  const { t } = useLocalization();
  const { filtered, refetch } = useBills();

  createEffect(() => {
    if (filtered.error) throw filtered.error;
  });

  createEffect(() => {
    refetch();
  });

  //TODO FUTURE ADD SUMMARY
  return (
    <div class="min-h-svh space-y-5 p-8" classList={{ 'cursor-wait': filtered.loading }}>
      <div class="flex w-full flex-col">
        <TabLinks
          class="mb-4 rounded-lg border border-partingline bg-white"
          items={[
            { label: t('Bills'), href: '' },
            // { label: t('Recurring bills'), href: '/' },
            // { label: t('Unpaid balances'), href: '/' },
          ]}
        />

        <Routes>
          <Route path="/" element={<BillsListView />} />
        </Routes>
      </div>
    </div>
  );
};

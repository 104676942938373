import { splitProps } from 'solid-js';
import { LabeledGroup } from '~/components/common/Inputs';
import { SearchSelect } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { PropertiesRepository } from '~/repositories/propertiesRepository';
import { PropertyTitle } from './PropertyTitle';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { EntrySearchProps } from '~/components/ui';

const repo = new PropertiesRepository();

export const PropertySearch = <T extends boolean | undefined = undefined>(
  props: EntrySearchProps<MagicDoor.Api.HydratedPropertyDto, T>
) => {
  const { t } = useLocalization();
  return (
    <SearchSelect
      placeholder={t('Search properties')}
      fetcher={(query: string | undefined, page: number) =>
        repo.getProperties({ active: true, page, pageSize: 20, search: query || undefined })
      }
      renderSelected="displayName"
      renderItem={(item) => <PropertyTitle feedbackIconReadonly property={item} />}
      {...props}
    />
  );
};

export const LabeledPropertySearch = <T extends boolean | undefined = undefined>(
  props: Omit<EntrySearchProps<MagicDoor.Api.HydratedPropertyDto, T>, 'onSelect'> &
    LabeledGroupProps & {
      value?: T extends true ? string[] : string;
      onInput?: (
        value: T extends true ? string[] : string,
        selected: T extends true ? MagicDoor.Api.HydratedPropertyDto[] : MagicDoor.Api.HydratedPropertyDto
      ) => void;
    }
) => {
  const [searchProps, labeledProps] = splitProps(props, ['multiple', 'selected', 'placeholder', 'onInput', 'exclude']);

  // TODO: get entries by ids???

  return (
    <LabeledGroup {...labeledProps}>
      <PropertySearch
        {...searchProps}
        onSelect={(selected) => {
          props.onInput?.(
            (Array.isArray(selected) ? selected.map((t) => t.id) : selected.id) as T extends true ? string[] : string,
            selected
          );
        }}
      />
    </LabeledGroup>
  );
};

import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { AIRunbookTable } from './components/AiRunbookTable';

export const ListAiRunbooks = () => {
  const { t } = useLocalization();
  const { aiRunbooks } = useRunBooks();
  return (
    <div class="flex flex-col gap-4">
      <AIRunbookTable title={t('AI runbooks')} runbooks={aiRunbooks()} />
    </div>
  );
};

import { createStore } from 'solid-js/store';
import { BankAccountRepository } from '~/repositories/bankAccountRepository';
import { BillRepository } from '~/repositories/billRepository';
import { ChartOfAccountsRepository } from '~/repositories/chartOfAccountsRepository';
import { LeaseRepository } from '~/repositories/leaseRepository';
import { PropertiesRepository } from '~/repositories/propertiesRepository';
import { UnitRepository } from '~/repositories/unitRepository';
import { VendorRepository } from '~/repositories/vendorRepository';
import type { Option } from '~/components/common/DropdownMenu';
import type { VendorBillsPayListFilter } from '~/repositories/billRepository';

export interface TBillStore {
  submitLoading?: boolean;
  vendors?: MagicDoor.Api.VendorDto[];
  vendorLoading?: boolean;
  properties?: MagicDoor.Api.PropertyDto[];
  propertiesLoading?: boolean;
  units?: MagicDoor.Api.UnitDto[];
  unitLoading?: boolean;
  leases?: MagicDoor.Api.HydratedLeaseDto[];
  leaseLoading?: boolean;
  chartOfAccountOptions?: Option[];
  chartOfAccountMap?: Map<string, string>;
  chartOfAccountLoading?: boolean;
  bills?: MagicDoor.Api.HydratedBillDto[];
  billsLoading?: boolean;
  bankAccountOptions?: Option[];
  bankAccountsLoading?: boolean;
}

const vendorRepo = new VendorRepository();
const propertiesRepo = new PropertiesRepository();
const leaseRepo = new LeaseRepository();
const unitRepo = new UnitRepository();
const ChartOfAccountsRepo = new ChartOfAccountsRepository();
const BillRepo = new BillRepository();
const BankAccountRepo = new BankAccountRepository();

const [store, setStore] = createStore<TBillStore>({});

const perfectLeases = () => {
  if (store.leases && store.properties && store.units) {
    setStore('leaseLoading', true);
    const leases = store.leases?.map((lease) => ({
      ...lease,
      property: store.properties?.find((property) => property.id === lease.propertyId) as MagicDoor.Api.PropertyDto,
      unit: store.units?.find((unit) => unit.id === lease.unitId) as MagicDoor.Api.UnitDto,
    }));
    setStore('leases', leases);
    setStore('leaseLoading', false);
  }
};

const getVendors = async () => {
  setStore('vendorLoading', true);
  const response = await vendorRepo.getAllVendors();
  setStore('vendors', response);
  setStore('vendorLoading', false);
};

const getProperties = async () => {
  setStore('propertiesLoading', true);
  const response = await propertiesRepo.getAllProperties();
  setStore('properties', response);
  setStore('propertiesLoading', false);
  perfectLeases();
};

const getLeases = async (unitId: string, search?: string, page = 1, pageSize = 20) => {
  setStore('leaseLoading', true);
  try {
    const response = await leaseRepo.getLeases({
      unitId,
      search,
      page,
      pageSize,
    });
    setStore('leases', response.items);
    return response;
  } catch (error) {
    console.error('Error fetching leases:', error);
    setStore('leases', []);
    return { items: [], totalPages: 0, totalCount: 0 };
  } finally {
    setStore('leaseLoading', false);
  }
};

const getLeasesForUnit = async (unitId: string) => {
  if (!unitId) {
    setStore('leases', []);
    return;
  }
  await getLeases(unitId);
};

const getUnits = async (propertyId: string) => {
  setStore('unitLoading', true);
  const response = await unitRepo.getAllUnits({
    propertyId,
  });
  setStore('units', response);
  setStore('unitLoading', false);
  perfectLeases();
};

const getChartOfAccounts = async () => {
  setStore('chartOfAccountLoading', true);
  const response = await ChartOfAccountsRepo.getChartOfAccounts();
  const options = (response ?? []).map((account) => ({
    label: account.name,
    value: account.id,
  }));
  const accountMap = new Map(response.map((account) => [account.id, account.name]));
  setStore('chartOfAccountOptions', options);
  setStore('chartOfAccountMap', accountMap);
  setStore('chartOfAccountLoading', false);
};

interface ProcessedBill extends MagicDoor.Api.BillDto {
  property: MagicDoor.Api.PropertyDto & { availableBalance?: number };
  vendor: MagicDoor.Api.VendorDto;
}

const getVendorBillsPay = async (filter: VendorBillsPayListFilter): Promise<void> => {
  setStore('billsLoading', true);
  try {
    const response = await BillRepo.getVendorBillsPay(filter);
    console.log('Raw API Response:', JSON.stringify(response, null, 2));

    if (!response || !response.properties || !Array.isArray(response.properties) || !response.vendors || !Array.isArray(response.vendors)) {
      console.error("Invalid response structure. Expected 'properties' and 'vendors' arrays.");
      setStore('bills', []);
      return;
    }

    const vendorMap = new Map(response.vendors.map((vendor) => [vendor.id, vendor]));

    const processedBills: ProcessedBill[] = response.properties
      .flatMap(
        (property) =>
          property.bills
            ?.map((bill) => {
              const vendor = vendorMap.get(bill.vendorId);
              if (!vendor) {
                console.warn(`No vendor found for vendorId: ${bill.vendorId}`);
                return null;
              }
              return {
                ...bill,
                property: {
                  ...property.property,
                  availableBalance: property.availableBalance,
                },
                vendor: vendor,
              };
            })
            .filter((bill): bill is ProcessedBill => bill !== null) ?? []
      )
      .filter((bill) => bill.due > 0);

    console.log('Processed Bills:', JSON.stringify(processedBills, null, 2));
    setStore('bills', processedBills);
  } catch (error) {
    console.error('Error fetching bills:', error);
    setStore('bills', []);
  } finally {
    setStore('billsLoading', false);
  }
};

const getBankAccounts = async () => {
  setStore('bankAccountsLoading', true);
  const response = await BankAccountRepo.getBankAccounts();
  const options = (response ?? []).map((account: any) => ({
    label: account.name,
    value: account.id,
  }));
  setStore('bankAccountOptions', options);
  setStore('bankAccountsLoading', false);
};

const paymentMethodOptions: Option[] = [
  {
    label: 'ach',
    value: 'ach',
  },
  {
    label: 'credit',
    value: 'credit',
  },
  {
    label: 'Debit card',
    value: 'debitCard',
  },
  {
    label: 'wallet',
    value: 'wallet',
  },
  {
    label: 'Credit card',
    value: 'creditCard',
  },
  {
    label: 'check',
    value: 'check',
  },
  {
    label: 'Cashiers check',
    value: 'cashiersCheck',
  },
  {
    label: 'Money order',
    value: 'moneyOrder',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export default {
  paymentMethodOptions,
  store,
  getVendorBillsPay,
  getUnits,
  getLeases,
  setStore,
  getLeasesForUnit,
  getVendors,
  getProperties,
  getChartOfAccounts,
  getBankAccounts,
};

import { createMagicDoorContext } from '~/contexts/utils';
import { ChecksRepository } from '~/repositories/checks';
import { emptyPlaceholder } from '~/utils/constant';
import { createLazyResource, createMutation, createTriggerResource } from '~/utils/resource';
import type { ChecksFilter } from '~/repositories/checks';

const repo = new ChecksRepository();

export const [ChecksProvider, useChecks] = createMagicDoorContext('Checks', () => {
  const [checks, getChecks, checksActions] = createTriggerResource(async (filter?: ChecksFilter) => {
    const res = await repo.getChecks(filter);
    const items = res.items.map((item) => {
      const owner = res.owners?.find((owner) => owner.id === item.ownerId);
      const ownerName = owner ? `${owner.firstName} ${owner.lastName}`.trim() : emptyPlaceholder;
      return {
        ...item,
        vendorName: res.vendors?.find((vendor) => vendor.id === item.vendorId)?.name || emptyPlaceholder,
        ownerName: ownerName,
        bankAccountName: res.bankAccounts?.find((bankAccount) => bankAccount.id === item.bankAccountId)?.name || emptyPlaceholder,
      };
    });

    return {
      ...res,
      items,
    };
  });

  const [nextCheckNumber, nextCheckNumberActions] = createLazyResource(repo.getNextCheckNumber.bind(repo));

  const deleteCheck = createMutation(async (checkId: string) => {
    await repo.deleteCheck(checkId);
  });

  const updateNextCheckNumber = createMutation(async (payload: MagicDoor.Api.NextCheckNumberDto) => {
    await repo.updateNextCheckNumber(payload);
  });

  const getCheck = createMutation(async (id: string) => {
    return await repo.getCheck(id);
  });

  return {
    checks,
    getChecks,
    checksActions,

    get nextCheckNumber() {
      nextCheckNumberActions.fetch();
      return nextCheckNumber;
    },
    nextCheckNumberActions,

    deleteCheck,
    updateNextCheckNumber,
    getCheck,
  };
});

import { Show, For } from 'solid-js';
import { SimpleFileListView } from '~/components/file-attachments/FileListViewWithNoEntity';

export const renderField = (label: string, value: string | null | undefined, t: (key: string) => string) => (
  <Show when={value != null}>
    <div class="mb-4">
      <h5 class="font-semibold">{t(label)}</h5>
      <span>{value}</span>
    </div>
  </Show>
);

export const renderAttachments = (attachments: any[] | null | undefined, title: string, t: (key: string) => string) => {
  return (
    <Show when={attachments && attachments.length > 0}>
      <div class="mb-4">
        <h5 class="font-semibold">{t(title)}</h5>
        <ul class="list-inside list-disc">
          <For each={attachments}>
            {(attachment) => (
              <li>
                <a href={attachment.url} target="_blank" rel="noopener noreferrer" class="hover:underline">
                  {attachment.name}
                </a>
              </li>
            )}
          </For>
        </ul>
      </div>
    </Show>
  );
};

export const renderAttachmentLists = (attachments: any[] | null | undefined, title: string, t: (key: string) => string) => {
  return (
    <Show when={attachments && attachments.length > 0}>
      <div class="mb-4">
        <h5 class="font-semibold">{t(title)}</h5>
        <SimpleFileListView attachments={attachments ?? []} title={title} />
      </div>
    </Show>
  );
};

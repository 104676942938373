import { Show } from 'solid-js';
import IconField from '~/components/common/IconField';
import RelativeDate from '~/components/common/RelativeDate';
import ExpandableSection from '~/components/common/expandables/ExpandableSection';
import { IconCalendar, IconHash, IconUmbrella } from '~/components/ui';
import { useLocalization, useVendors } from '~/contexts/global';

const VendorInsurance = () => {
  const { t } = useLocalization();
  const { current } = useVendors();

  return (
    <ExpandableSection title={t('Insurance')}>
      <div>
        <div class="flex w-full flex-row flex-wrap gap-y-4 border-t py-4 xl:flex-col">
          <IconField class="basis-1/2" name={t('Provider')} value={current()?.insurancePolicy?.provider} src={IconUmbrella} />
          <IconField class="basis-1/2" name={t('Policy number')} value={current()?.insurancePolicy?.policyNumber} src={IconHash} />
          <IconField
            class="basis-1/2"
            name={t('Days until expiration')}
            value={
              <Show when={current()?.insurancePolicy?.expirationDate} fallback="--">
                <RelativeDate timestamp={current()?.insurancePolicy?.expirationDate} />
              </Show>
            }
            src={IconCalendar}
          />
        </div>
      </div>
    </ExpandableSection>
  );
};

export default VendorInsurance;

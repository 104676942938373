import { Show, createMemo, createSignal, onMount } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconRentPayment from '~/assets/images/settings/property/propertyRentPayment.svg';
import { Button } from '~/components/common/Buttons';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { toast, confirm, IconLoader } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBankAccountsList, useDepositPaymentSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import Overrides from '~/pages/settings/components/Overrides';
import { AddOverridesModal } from '~/pages/settings/components/add-overrides-modal';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';
import type { ItemProps } from '~/pages/settings/components/edit-form/EditForm';

export const DepositPaymentSettings = () => {
  const { t } = useLocalization();
  const {
    allDepositSettings,
    getAllDepositSettings,
    isLoading,
    updateDepositPaymentSettings,
    addOverrideSettings,
    updateOverrideDepositPaymentSettings,
    removeOverrideSettings,
  } = useDepositPaymentSettings();
  const { bankAccounts } = useBankAccountsList();
  const sections = createMemo<ItemProps[]>(() => [
    {
      field: 'allowOnlinePayments',
      label: 'Allow online payments',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass: 'flex-row justify-between',
      description: 'This will allow rent payment to be made via the tenant portal.',
    },
    {
      field: 'allowAchPayments',
      label: 'Allow ACH payments',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass: 'flex-row justify-between',
    },
    {
      field: 'allowCardPayments',
      label: 'Allow card payments',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass: 'flex-row justify-between',
    },
    {
      field: 'allowPartialPayments',
      label: 'Allow partial payments',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass: 'flex-row justify-between',
    },
    {
      field: 'bankAccountId',
      label: 'Bank account to recive payments',
      type: 'select',
      options: bankAccounts().map((account) => ({ label: account.name, value: account.id })),
      labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
      labelClass: 'w-1/2',
      class: 'col-span-2',
      description:
        'The bank account that rent payment will be sent to, changing this value will only affect future payments. Bank account has to be linked with Plaid and/or Stripe',
    },
  ]);
  const [isShowModal, setIsShowModal] = createSignal(false);

  const onSave = async (data: MagicDoor.Api.DepositPaymentSettingsDto, level?: string, levelId?: string) => {
    if (!level && !levelId) {
      await updateDepositPaymentSettings(data);
      toast(t('Deposit payment settings saved successfully'), 'success');
      getAllDepositSettings();
      return;
    }

    await updateOverrideDepositPaymentSettings(level as string, levelId as string, data);
    toast(t('Deposit payment settings saved successfully'), 'success');
    getAllDepositSettings();
  };

  const onIsShowModalChange = () => {
    setIsShowModal((prev) => !prev);
  };

  const onAddOverrideSettingsConfirm = async (level: string, levelId: string) => {
    await addOverrideSettings(level, levelId, { ...((allDepositSettings()?.settings ?? {}) as MagicDoor.Api.DepositPaymentSettingsDto) });
    onIsShowModalChange();
    toast(t('Add deposit payment settings successfully'), 'success');
    getAllDepositSettings();
  };

  const onRemoveOverrideSettings = (level: string, levelId: string) => {
    confirm({
      content: t('Are you sure you want to delete this override?'),
      title: t('Delete override'),
      onResolve: async (confirmed) => {
        if (!confirmed) {
          return;
        }

        await removeOverrideSettings(level, levelId);
        toast(t('Deposit payment settings deleted successfully'), 'success');
        getAllDepositSettings();
      },
    });
  };

  onMount(() => getAllDepositSettings());

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topTitle={<SettingTitle title={t('Deposit payment')} icon={IconRentPayment} />}
        topAction={
          <Button variant="outlined" class="rounded-3xl px-3 py-1 text-sm" onClick={onIsShowModalChange}>
            <IconPlus />
            <span class="hidden font-medium md:block">{t('Add new overrides')}</span>
          </Button>
        }
        middle={
          <div class="flex flex-col gap-5 p-4">
            <Show when={!isLoading()} fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
              <div class="flex flex-col gap-4 p-2 text-left">
                <EditForm
                  sections={sections()}
                  onOk={(data) => onSave(data)}
                  model={allDepositSettings()?.settings || ({} as MagicDoor.Api.RentPaymentSettingsDto)}
                />
              </div>
            </Show>
            <Overrides
              overrides={allDepositSettings()?.overrides ?? []}
              onDelete={onRemoveOverrideSettings}
              title={t('Deposit payment overrides')}>
              {(override) => (
                <div class="flex flex-col gap-4 p-2 text-left">
                  <EditForm
                    sections={sections()}
                    model={(override.settings ?? {}) as MagicDoor.Api.DepositPaymentSettingsDto}
                    onOk={(data) => onSave(data, override.level, override.levelId)}
                  />
                </div>
              )}
            </Overrides>
          </div>
        }
      />
      <Show when={isShowModal()}>
        <AddOverridesModal
          overrides={allDepositSettings()?.overrides || []}
          loading={isLoading()}
          onClose={onIsShowModalChange}
          onDone={onAddOverrideSettingsConfirm}
        />
      </Show>
    </>
  );
};

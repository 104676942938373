import UnderConstructionImg from '~/assets/images/common/underConstruction.svg';
import ExpiringLeasesImg from '~/assets/images/custom-layout/home/expiringLeases.png';
import IncomeStatisticsImg from '~/assets/images/custom-layout/home/incomeStatistics.png';
import LearnImg from '~/assets/images/custom-layout/home/learn.png';
import LeasesWithBalanceDueImg from '~/assets/images/custom-layout/home/leasesWithBalanceDue.png';
import MaintenaceRequestsImg from '~/assets/images/custom-layout/home/maintenaceRequests.png';
import RentApplicationsImg from '~/assets/images/custom-layout/home/rentApplications.png';
import ExpiringLeases from './list-items/ExpiringLeases';
import IncomeStatistics from './list-items/IncomeStatistics';
import Learn from './list-items/Learn';
import LeasesWithBalanceDue from './list-items/LeasesWithBalanceDue';
import MaintenanceRequests from './list-items/MaintenanceRequests';
import RentalApplications from './list-items/RentalApplications';
import ThingsToDo from './list-items/ThingsToDo';
import type { HomeLayout } from './type';
export const getHomeAllComponent = (key?: string) => {
  const homeAllComponent: HomeLayout[] = [
    {
      key: 'RentalApplications',
      width: 'xl:col-span-4',
      component: RentalApplications,
      thumbnail: RentApplicationsImg,
      title: 'Rental applications',
      description: 'Preview recent applications on the home page',
    },
    {
      key: 'LeasesWithBalanceDue',
      width: 'xl:col-span-8',
      component: LeasesWithBalanceDue,
      thumbnail: LeasesWithBalanceDueImg,
      title: 'Leases with balance due',
      description: 'Display the most recent  balance payable on the home page',
    },
    {
      key: 'MaintenanceRequests',
      width: 'xl:col-span-6',
      component: MaintenanceRequests,
      thumbnail: MaintenaceRequestsImg,
      title: 'Maintenance requests',
      description: 'Displays the latest maintenance request',
    },
    {
      key: 'ThingsToDo',
      width: 'xl:col-span-6',
      component: ThingsToDo,
      thumbnail: UnderConstructionImg,
      title: 'Things to do',
      description: 'To-do list, including maintenance requests and Guest Cards...',
    },
    {
      key: 'ExpiringLeases',
      width: 'xl:col-span-4',
      component: ExpiringLeases,
      thumbnail: ExpiringLeasesImg,
      title: 'Expiring leases',
      description: 'Display the chart in proportion to lease expiration time',
    },
    {
      key: 'IncomeStatistics',
      width: 'xl:col-span-4',
      component: IncomeStatistics,
      thumbnail: IncomeStatisticsImg,
      title: 'Income statistics',
      description: 'Recipts and bills',
    },
    {
      key: 'Learn',
      width: 'xl:col-span-4',
      component: Learn,
      thumbnail: LearnImg,
      title: 'Learn to master magicdoor quickly',
      description: 'Magicdoor video instructions',
    },
  ];

  return key ? homeAllComponent.filter((item) => item.key === key) : homeAllComponent;
};

import { Show, createSignal } from 'solid-js';
import IconHide from '~/assets/images/common/hide.svg';
import IconShow from '~/assets/images/common/show.svg';

interface TinToggleProps {
  number: string;
  type: 'ssn' | 'ein' | 'others';
}

export function TinToggle(props: TinToggleProps) {
  const [isHidden, setIsHidden] = createSignal(true);

  const formatNumber = () => {
    const formatSSN = (ssn: string) => {
      if (isHidden()) {
        return `***-**-${ssn.substring(5)}`;
      } else {
        return `${ssn.substring(0, 3)}-${ssn.substring(3, 5)}-${ssn.substring(5)}`;
      }
    };

    const formatEIN = (ein: string) => {
      if (isHidden()) {
        return `**-***${ein.substring(ein.length - 4)}`;
      } else {
        return `${ein.substring(0, 2)}-${ein.substring(2)}`;
      }
    };

    const formatOthers = (number: string) => {
      if (isHidden()) {
        return `***${number.substring(number.length - 4)}`;
      } else {
        return number;
      }
    };

    if (props.type === 'ssn') {
      return formatSSN(props.number);
    }
    if (props.type === 'ein') {
      return formatEIN(props.number);
    }

    if (props.type === 'others') {
      return formatOthers(props.number);
    }

    return props.number;
  };

  return (
    <div class="flex items-center space-x-2">
      <span class="font-mono">{formatNumber()}</span>
      <button class="px-1 " onClick={() => setIsHidden(!isHidden())}>
        <Show when={isHidden()} fallback={<img src={IconShow} />}>
          <img src={IconHide} />
        </Show>
      </button>
    </div>
  );
}

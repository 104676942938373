import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import ThingsToDoBg from '~/assets/images/dashboard/thingsToDoBg.svg';
import { LinkButton } from '~/components/common/Buttons';
import UnderConstruction from '~/components/common/UnderConstruction';
import { useLocalization } from '~/contexts/global';
import Card from '~/pages/dashboard/components/Card';
import type { Component } from 'solid-js';

type ThingsToDoProps = {
  class?: string;
};

const ThingsToDo: Component<ThingsToDoProps> = (props) => {
  const { t } = useLocalization();

  return (
    <Card
      class={props.class}
      title={t('Things to do')}
      bgClass="bg-[#F9FAFF]"
      bgImgSrc={ThingsToDoBg}
      actions={
        <LinkButton disabled href="/" class="px-3 py-1 text-xs" rounded="full" variant="outlined">
          {t('Detail')}
          <IconViewArrow class="size-4 text-essential-colour" />
        </LinkButton>
      }>
      <div class="relative size-full">
        <UnderConstruction />
      </div>
    </Card>
  );
};

export default ThingsToDo;

import { Show } from 'solid-js';
import IconInformation from '~/assets/images/common/information.svg?component-solid';
import FixedTermIcon from '~/assets/images/leases/fixedTerm.svg?component-solid';
import MonthToMonthIcon from '~/assets/images/leases/monthToMonth.svg?component-solid';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import InputSwitch from '~/components/common/Inputs/InputSwitch';
import { OptionButton } from '~/components/common/OptionButton';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

export type EditLeaseFormprops = {
  formData: {
    type: string;
    start: string;
    end?: string;
    eviction: boolean;
    monthToMonth: boolean;
    icons: string[];
  };
  formError: {
    start: string | undefined;
  };
  isDraft?: boolean;
  onUpdate: (field: string, value: any) => void;
};
export const EditLeaseForm: Component<EditLeaseFormprops> = (props) => {
  const { t } = useLocalization();

  return (
    <div class="grid grid-cols-2 gap-6 py-4">
      <OptionButton
        class="h-20"
        Icon={FixedTermIcon}
        label={t('Fixed term')}
        isActive={props.formData.type === 'fixedTerm'}
        onClick={() => props.onUpdate('type', 'fixedTerm')}
      />
      <OptionButton
        class="h-20"
        Icon={MonthToMonthIcon}
        label={t('Month to month')}
        isActive={props.formData.type === 'monthToMonth'}
        onClick={() => props.onUpdate('type', 'monthToMonth')}
      />
      <DueDateInputField
        class="col-span-2 text-left lg:col-span-1"
        label={t('Start date')}
        value={props.formData.start}
        onInput={(v) => props.onUpdate('start', v)}
        error={props.formError.start}
      />
      <Show when={props.formData.type === 'fixedTerm'}>
        <DueDateInputField
          label={t('End date')}
          class="col-span-2 text-left lg:col-span-1"
          value={props.formData.end}
          onInput={(v) => props.onUpdate('end', v)}
        />
        <span class="col-span-full mt-4 flex items-center gap-2 text-sm text-text-level02">
          <Checkbox checked={props.formData.monthToMonth} onInput={(monthToMonth) => props.onUpdate('monthToMonth', monthToMonth)} />
          {t('Transfer to month-to-month after the term is over')}
        </span>

        <div class="col-span-full flex items-center rounded-lg bg-gray-level01 p-4 text-left text-sm text-text-level02">
          <IconInformation />
          <span class="ml-2 flex-1">{t('The end date is just for bookkeeping, you still need to manually end the lease')}</span>
        </div>
      </Show>

      <Show when={!props.isDraft}>
        <div class="col-span-full border-t py-8">
          <Panel
            title={<div class="text-[#DF7B18]">{t('Start eviction')}</div>}
            class="w-full bg-[#FFF3E4]"
            actions={<InputSwitch checked={props.formData.eviction} onChange={(eviction) => props.onUpdate('eviction', eviction)} />}>
            <div class="mx-6 my-3 text-left text-sm text-[#DF7B18]">
              {props.formData.eviction
                ? t('If click off and save, it wil allow payment immediately')
                : t('Please note, when eviction process starts, all online payments to this lease will be blocked effective immediately')}
            </div>
          </Panel>
        </div>
      </Show>
    </div>
  );
};

import { useNavigate } from '@solidjs/router';
import { createSignal, onMount } from 'solid-js';
import MiddleModal from '~/components/modals/MiddleModal';
import { useLocalization, usePortfolios, useProperties } from '~/contexts/global';
import { AddOrEditPropertyManager } from './AddOrEditPropertyManager';
import type { Component } from 'solid-js';

export const AddOrEditPropertyManagerModal: Component = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const { refetch } = usePortfolios();
  const { mutateProperty } = useProperties();

  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);
  onMount(() => mutateProperty(undefined));

  const handleClose = () => {
    refetch();
    navigate(-1);
  };

  return (
    <MiddleModal
      confirmation={showCloseConfirmation()}
      onClose={handleClose}
      confirmationTitle={t('Are you sure you want to cancel?')}
      confirmationMessage={t('If you cancel the addition, the information already filled in cannot be saved')}>
      <AddOrEditPropertyManager
        onClose={handleClose}
        showCloseConfirmation={showCloseConfirmation()}
        setShowCloseConfirmation={setShowCloseConfirmation}
      />
    </MiddleModal>
  );
};

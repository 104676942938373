import AnnouncementsListView from './list-announcements/AnnouncementsListView';

export const ListAnnouncements = () => {
  return (
    <div class="flex w-full gap-5 p-8 text-sm">
      <div class="flex w-full flex-col gap-5">
        <AnnouncementsListView />
      </div>
    </div>
  );
};

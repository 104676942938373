import { Route, Routes } from '@solidjs/router';
import { AddOwnerModal } from './forms/AddOwnerModal';
import { EditOwner } from './forms/EditOwner';
import { ListOwner } from './list-owner/ListOwner';
import { OwnerDetailsPage } from './owner-details';

const matchFilters = {
  ownerId: /\d+/,
};

export const OwnerRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/add" component={AddOwnerModal} />
      </Routes>
      <Routes>
        <Route path="/:ownerId/*" matchFilters={matchFilters} component={OwnerDetailsPage} />
        <Route path="/:ownerId/edit" component={EditOwner} />
        <Route path="*" component={ListOwner} />
      </Routes>
    </>
  );
};

import { createMemo, createSignal } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import type { FilterItems } from '~/components/ui';

type FilterValue = {
  priority?: string;
};

type MaintenanceRequestFilter = {
  priority?: string;
};

export const useMaintenanceRequestFilter = () => {
  const { t } = useLocalization();
  const [filterValue, setFilterValue] = createSignal<FilterValue>({});

  const filterItems = createMemo(
    () =>
      [
        {
          type: 'select',
          key: 'priority',
          label: t('Priority'),
          options: [
            { label: t('All'), value: undefined },
            { label: t('None'), value: 'none' },
            { label: t('Low'), value: 'low' },
            { label: t('Medium'), value: 'medium' },
            { label: t('High'), value: 'high' },
            { label: t('Urgent'), value: 'urgent' },
          ],
        },
      ].filter(Boolean) as FilterItems<FilterValue>
  );

  const parseFilter = (value: FilterValue): MaintenanceRequestFilter => {
    const params: MaintenanceRequestFilter = {};
    params.priority = value.priority;
    return params;
  };

  const handleFilterReset = () => {
    setFilterValue({});
  };

  return { filterValue, filterItems, parseFilter, handleFilterReset, setFilterValue };
};

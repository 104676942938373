import { BaseRestRepository } from '~/repositories/baseRestRepository';

export class rentPaymentNotificationsSettingsRepository extends BaseRestRepository {
  public async changeRentPaymentNotificationsSettings(payload: MagicDoor.Api.RentPaymentNotificationSettingsDto) {
    const url = '/api/settings/rent-payment/notifications';
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getAllRentPaymentNotificationsSettings() {
    const url = '/api/settings/rent-payment/notifications/all';
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async updateRentPaymentNotificationsSettingsByLevel(
    level: string,
    levelId: string,
    payload: MagicDoor.Api.RentPaymentNotificationSettingsDto
  ) {
    const url = `/api/settings/rent-payment/notifications/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async deleteRentPaymentNotificationsSettingsByLevel(level: string, levelId: string) {
    const url = `/api/settings/rent-payment/notifications/${level}/${levelId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }
}

import { createMemo } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { dateFormat, dateDiffInDays } from '~/utils/date';

const DAYS_IN_YEAR = 365;
const DAYS_IN_MONTH = 30;

const getRelativeDateString = (
  diffDays: number,
  t: { (_key: string, _params?: Record<string, string> | undefined): string; (arg0: string, arg1: { count: any } | undefined): string }
) => {
  const isPast = diffDays < 0;
  const absDiffDays = Math.abs(diffDays);
  let dateString = '';

  if (isPast) {
    if (absDiffDays > 1) {
      if (absDiffDays < DAYS_IN_MONTH) {
        dateString = t('DaysAgo', { count: absDiffDays });
      } else if (absDiffDays < DAYS_IN_YEAR) {
        const months = Math.round(absDiffDays / DAYS_IN_MONTH);
        dateString = t(months === 1 ? 'OneMonthAgo' : 'MonthsAgo', { count: months });
      } else {
        const years = Math.round(absDiffDays / DAYS_IN_YEAR);
        dateString = t(years === 1 ? 'OneYearAgo' : 'YearsAgo', { count: years });
      }
    } else {
      dateString = t('OneDayAgo');
    }
  } else {
    if (diffDays > 1) {
      if (diffDays < DAYS_IN_MONTH) {
        dateString = t('Days', { count: diffDays });
      } else if (diffDays < DAYS_IN_YEAR) {
        const months = Math.round(diffDays / DAYS_IN_MONTH);
        dateString = t(months === 1 ? 'OneMonth' : 'Months', { count: months });
      } else {
        const years = Math.round(diffDays / DAYS_IN_YEAR);
        dateString = t(years === 1 ? 'OneYear' : 'Years', { count: years });
      }
    } else {
      dateString = diffDays === 1 ? t('OneDay') : t('Today');
    }
  }

  return dateString;
};

const RelativeDate = (props: { timestamp: any }) => {
  const { t } = useLocalization();
  const isValidTimestamp = (timestamp: number) => typeof timestamp === 'number' && !isNaN(timestamp);
  const relativeDate = createMemo((): [string, boolean] => {
    const timestamp = isValidTimestamp(props.timestamp) ? props.timestamp : Date.now();
    const diffDays = dateDiffInDays(timestamp);
    const dateString = getRelativeDateString(diffDays, t);
    const isPast = diffDays < 0;
    return [dateString, isPast];
  });

  const displayDate = createMemo(() => {
    return props.timestamp ? dateFormat('', props.timestamp) : '';
  });
  return (
    <div class="flex w-full items-end gap-2">
      <div classList={{ 'text-danger': relativeDate()[1] }}>{relativeDate()[0]}</div>
      <div class="text-xs text-text-level02">{displayDate()}</div>
    </div>
  );
};

export default RelativeDate;

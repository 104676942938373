import { BaseRestRepository } from '~/repositories/baseRestRepository';

export class chatsSettingsRepository extends BaseRestRepository {
  public async changeTextSettings(payload: MagicDoor.Api.TextSettingsDto) {
    const url = '/api/settings/text';
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async changeChatSettings(payload: MagicDoor.Api.ChatSettingsDto) {
    const url = '/api/settings/chat';
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getAllTextSettings() {
    const url = '/api/settings/text/all';
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getChatsSettings() {
    const url = '/api/settings/chat';
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async updateTextSettingsByLevel(level: string, levelId: string, payload: MagicDoor.Api.TextSettingsDto) {
    const url = `/api/settings/text/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async updateChatsSettingsByLevel(level: string, levelId: string, payload: MagicDoor.Api.ChatSettingsDto) {
    const url = `/api/settings/chat/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async deleteTextSettingsByLevel(level: string, levelId: string) {
    const url = `/api/settings/text/${level}/${levelId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }

  public async deleteChatsSettingsByLevel(level: string, levelId: string) {
    const url = `/api/settings/chat/${level}/${levelId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }
}

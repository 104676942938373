import { BaseRestRepository } from '~/repositories/baseRestRepository';

type UploadParams = {
  fileName?: string;
  params?: Record<string, string>;
};

export class FileRepository extends BaseRestRepository {
  async deleteFile(url: string) {
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  async uploadFile(url: string, file: File, opts?: UploadParams) {
    const formData = new FormData();
    formData.append(opts?.fileName || 'file', file);

    if (opts?.params) {
      for (const [key, value] of Object.entries(opts.params)) {
        formData.append(key, value);
      }
    }

    return this.fetchWithAuth(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

import { createMemo, createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { PortfolioRepository } from '~/repositories/portfolioRepository';
import { createLazyResource, createMutation } from '~/utils/resource';

const repo = new PortfolioRepository();

const LAST_USED_PORTFOLIO_KEY = 'last-used-portfolio';

export const [PortfoliosProvider, usePortfolios] = createMagicDoorContext('Portfolios', () => {
  const [portfolios, { fetch, refetch, mutate }] = createLazyResource(() => repo.getPortfolios({ active: true }));
  const [currentId, setCurrentId] = createSignal<string>(localStorage.getItem(LAST_USED_PORTFOLIO_KEY) ?? '');
  const current = createMemo(() => (currentId() ? portfolios()?.find((p) => p.id === currentId()) : undefined));
  const [portfolioOverview, setPortfolioOverview] = createSignal<MagicDoor.Api.PortfolioOverviewDto>();

  const addPortfolio = createMutation(async (name: string) => {
    const result = await repo.createPortfolio({ name });
    const hydrated = { ...result, properties: [], recentRentalApplications: 0 };
    mutate((prev) => [...(prev ?? []), hydrated].sort((a, b) => a.name.localeCompare(b.name)));
    return result;
  });

  /** Not preferred. use deactivatePortfolio instead in most cases */
  const deletePortfolio = createMutation(async (id: string) => {
    await repo.deletePortfolio(id);
    mutate((prev) => prev?.filter((p) => p.id !== id));
  });

  const deactivatePortfolio = createMutation(async (id: string, forceDeactivateChildren?: boolean) => {
    await repo.deactivatePortfolio(id, forceDeactivateChildren);
    mutate((prev) => prev?.filter((p) => p.id !== id));
    // TODO: maybe in the future we can use the deactivated portfolio in context
    // mutate((prev) => prev?.map((p) => (p.id === id ? { ...p, active: false } : p)));
  });

  const updatePortfolio = createMutation(async (id: string, name: string) => {
    const result = await repo.updatePortfolio(id, { name });
    mutate((prev) => prev?.map((p) => (p.id === id ? { ...p, ...result } : p)));
    return result;
  });

  const getPortfolioOverview = createMutation(async (portfolioId: string) => {
    const data = await repo.getPortfolioOverview(portfolioId, true);
    setPortfolioOverview(data);
  });

  return {
    get portfolios() {
      fetch();
      return portfolios;
    },
    refetch,
    current,
    setCurrentId: (id?: string) => {
      id && localStorage.setItem(LAST_USED_PORTFOLIO_KEY, id);
      setCurrentId(id ?? '');
    },
    addPortfolio,
    deletePortfolio,
    deactivatePortfolio,
    updatePortfolio,
    getPortfolioOverview,
    portfolioOverview,
  };
});

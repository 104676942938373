import { useNavigate } from '@solidjs/router';
import { createSignal, For, createEffect, Show } from 'solid-js';
import { createStore } from 'solid-js/store';
import IconFile from '~/assets/images/chat/chatFile.png';
import IconDrag from '~/assets/images/common/dragDots.svg?component-solid';
import IconRemove from '~/assets/images/common/remove.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import { useLeaseTemplate, useLeaseTemplateCategory } from '~/contexts/local';
import type { Component } from 'solid-js';

type Template = {
  id: string;
  leaseTemplateCategoryId: string;
  name: string;
};

type SelectedTemplate = Template & { order: number };

export const LeaseTemplateForm: Component = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const { leasetemplatecategories } = useLeaseTemplateCategory();
  const { leasetemplates } = useLeaseTemplate();

  const [title, setTitle] = createSignal('');
  const [selectedTemplates, setSelectedTemplates] = createSignal<SelectedTemplate[]>([]);
  const [checkedTemplates, setCheckedTemplates] = createStore<Record<string, boolean>>({});
  const [dragOverIndex, setDragOverIndex] = createSignal<number | null>(null);
  const [draggedId, setDraggedId] = createSignal<string | null>(null);
  const [isDragging, setIsDragging] = createSignal(false);

  createEffect(() => {
    const newSelectedTemplates =
      leasetemplates()
        ?.filter((t) => checkedTemplates[t.id])
        .map((t, index) => ({ ...t, order: index + 1 })) || [];
    setSelectedTemplates(newSelectedTemplates);
  });

  const handleDragStart = (e: DragEvent, template: SelectedTemplate) => {
    setIsDragging(true);
    setDraggedId(template.id);
    e.dataTransfer?.setData('text/plain', template.id);
    if (e.target instanceof HTMLElement) {
      setTimeout(() => (e.target.style.opacity = '0.4'), 0);
    }
  };

  const handleDragEnd = (e: DragEvent) => {
    if (e.target instanceof HTMLElement) {
      e.target.style.opacity = '1';
    }
    setDragOverIndex(null);
    setDraggedId(null);
    setIsDragging(false);
  };

  const updateDragPosition = (e: DragEvent) => {
    if (!isDragging()) return;

    const rightColumn = document.querySelector('.right-column');
    if (!rightColumn) return;

    const rightColumnRect = rightColumn.getBoundingClientRect();
    const topDropZone = rightColumnRect.top + 20;
    const bottomDropZone = rightColumnRect.bottom - 30;

    if (e.clientY < topDropZone) {
      setDragOverIndex(0);
    } else if (e.clientY > bottomDropZone) {
      setDragOverIndex(selectedTemplates().length);
    } else {
      const dragOverItem = (e.target as HTMLElement).closest('li');
      if (dragOverItem) {
        const dragOverTemplateId = dragOverItem.getAttribute('data-id');
        const dragOverTemplate = selectedTemplates().find((t) => t.id === dragOverTemplateId);
        const draggedTemplate = selectedTemplates().find((t) => t.id === draggedId());

        if (dragOverTemplate && draggedTemplate && dragOverTemplate.id !== draggedTemplate.id) {
          const rect = dragOverItem.getBoundingClientRect();
          const midY = rect.top + rect.height / 2;

          let newIndex: number | null = null;

          if (e.clientY < midY) {
            newIndex = dragOverTemplate.order - 1;
          } else {
            newIndex = dragOverTemplate.order;
          }

          setDragOverIndex(newIndex !== draggedTemplate.order - 1 ? newIndex : null);
        }
      }
    }
  };

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    if (e.dataTransfer) {
      e.dataTransfer.dropEffect = 'move';
    }
    updateDragPosition(e);
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    const droppedId = e.dataTransfer?.getData('text/plain');
    const draggedTemplate = selectedTemplates().find((t) => t.id === droppedId);

    if (draggedTemplate) {
      const newIndex = dragOverIndex();
      if (newIndex !== null && newIndex !== draggedTemplate.order - 1 && newIndex !== draggedTemplate.order) {
        const newTemplates = selectedTemplates()
          .filter((t) => t.id !== draggedTemplate.id)
          .sort((a, b) => a.order - b.order);

        newTemplates.splice(newIndex, 0, draggedTemplate);

        const reorderedTemplates = newTemplates.map((t, index) => ({
          ...t,
          order: index + 1,
        }));

        setSelectedTemplates(reorderedTemplates);
      }
    }
    setDragOverIndex(null);
    setDraggedId(null);
    setIsDragging(false);
  };

  const toggleTemplate = (template: Template) => {
    setCheckedTemplates(template.id, !checkedTemplates[template.id]);
  };

  const handleRemoveTemplate = (templateId: string) => {
    setCheckedTemplates(templateId, false);
  };

  return (
    <div class="p-8 text-left">
      <Panel title={t('Customize lease templates')} class="container h-section1">
        <div class="h-section2 p-6">
          <LabeledTextInput
            type="text"
            value={title()}
            onInput={(e) => setTitle(e)}
            placeholder="Enter title"
            class="min-w-56 flex-1 border-b pb-8"
            label={t('Title')}
          />

          <div class="flex h-[calc(100%-130px)] flex-1 py-6">
            <div class="thinscroll w-1/2 flex-1 overflow-auto pr-4">
              <For each={leasetemplatecategories()}>
                {(category) => (
                  <div class="mb-4">
                    <h3 class="mb-2 text-text-level03">{category.name}</h3>
                    <For each={leasetemplates()?.filter((t) => t.leaseTemplateCategoryId === category.id)}>
                      {(template) => (
                        <div
                          class={`mb-2 flex cursor-pointer items-center rounded-md border px-4 py-3 transition-colors duration-200 ${
                            checkedTemplates[template.id] ? 'border-primary bg-primary/10' : 'bg-transparent'
                          }`}
                          onClick={() => toggleTemplate(template)}>
                          <Checkbox
                            checkBoxClass="text-sm font-medium text-text-level02"
                            label={template.name}
                            class="size-4 rounded border-text-level03"
                            showLabel
                            onChange={(e) => e.stopPropagation()}
                            checked={checkedTemplates[template.id] || false}
                          />
                        </div>
                      )}
                    </For>
                  </div>
                )}
              </For>
            </div>
            <div class="right-column relative w-1/2 border-l p-4 pl-6 " onDragOver={handleDragOver} onDrop={handleDrop}>
              <div class="inset-0 flex h-[calc(100%-10px)] flex-1  flex-col rounded-md border-2 border-dashed border-primary">
                <Show
                  when={selectedTemplates().length}
                  fallback={
                    <div class="flex h-full flex-col items-center justify-center gap-4">
                      <img src={IconFile} class="h-20" />
                      <p class="font-medium normal-case text-text-level03">{t('Selected templates will appear here')}</p>
                    </div>
                  }>
                  <h2 class="mb-3 mt-4 flex justify-center normal-case text-text-level03	">{t('Order the lease template')}</h2>
                  <div class="thinscroll  flex-1 overflow-auto">
                    <ul class="space-y-2 p-4">
                      <Show when={dragOverIndex() === 0}>
                        <div class="drag-indicator my-1 ml-6 rounded-md border-2 border-dashed border-link" style={{ height: '46px' }} />
                      </Show>

                      <For each={selectedTemplates().sort((a, b) => a.order - b.order)}>
                        {(template, index) => (
                          <>
                            <Show when={index() !== 0 && index() === dragOverIndex()}>
                              <div
                                class="drag-indicator my-1 ml-6 rounded-md border-2 border-dashed border-link"
                                style={{ height: '46px' }}
                              />
                            </Show>

                            <div class="flex w-full gap-2">
                              <button onClick={() => handleRemoveTemplate(template.id)}>
                                <IconRemove class="text-text-level02" />
                              </button>
                              <li
                                data-id={template.id}
                                class="flex w-full cursor-grab items-center space-x-2 rounded-md border p-4 active:cursor-grabbing"
                                style={{ height: '46px' }}
                                draggable="true"
                                onDragStart={(e) => handleDragStart(e, template)}
                                onDragEnd={handleDragEnd}>
                                <span class="grow text-sm">{template.name}</span>
                                <IconDrag class="text-text-level02" />
                              </li>
                            </div>
                          </>
                        )}
                      </For>

                      <Show when={dragOverIndex() === selectedTemplates().length}>
                        <div class="drag-indicator my-1 ml-6 rounded border-2 border-dashed border-link" style={{ height: '46px' }} />
                      </Show>
                    </ul>
                  </div>
                </Show>
              </div>
            </div>
          </div>
        </div>
        <div class="sticky bottom-0 mt-auto flex justify-end gap-2 border-t border-partingline bg-white px-3 py-5 lg:px-6">
          <Button class="mr-auto" variant="outlined" onClick={() => navigate(-1)}>
            {t('Previous')}
          </Button>

          <Button variant="outlined" onClick={() => navigate(-1)}>
            {t('Cancel')}
          </Button>

          <Button onClick={() => console.log('Clicked next')}>{t('Next')}</Button>
        </div>
      </Panel>
    </div>
  );
};

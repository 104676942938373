import { splitProps } from 'solid-js';
import { LabeledGroup } from '~/components/common/Inputs';
import { SearchSelect } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { UnitRepository } from '~/repositories/unitRepository';
import { UnitTitle } from './UnitTitle';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { EntrySearchProps } from '~/components/ui';
import type { UnitFilter } from '~/repositories/unitRepository';

const repo = new UnitRepository();

export const UnitSearch = <T extends boolean | undefined = undefined>(
  props: EntrySearchProps<MagicDoor.Api.HydratedUnitDto, T> & { filter?: UnitFilter }
) => {
  const { t } = useLocalization();
  return (
    <SearchSelect
      fetcher={(query: string | undefined, page: number) =>
        repo.getUnits({ active: true, ...props.filter, page, pageSize: 20, search: query || undefined })
      }
      placeholder={t('Search units')}
      renderSelected="name"
      renderItem={(item) => <UnitTitle feedbackIconReadonly unit={item} />}
      {...props}
    />
  );
};

export const LabeledUnitSearch = <T extends boolean | undefined = undefined>(
  props: Omit<EntrySearchProps<MagicDoor.Api.HydratedUnitDto, T>, 'onSelect'> &
    LabeledGroupProps & {
      value?: T extends true ? string[] : string;
      onInput?: (value: T extends true ? string[] : string) => void;
      filter?: UnitFilter;
    }
) => {
  const [searchProps, labeledProps] = splitProps(props, ['multiple', 'selected', 'placeholder', 'onInput', 'exclude', 'filter']);

  // TODO: get entries by ids???

  return (
    <LabeledGroup {...labeledProps}>
      <UnitSearch
        {...searchProps}
        onSelect={(selected) => {
          props.onInput?.((Array.isArray(selected) ? selected.map((t) => t.id) : selected.id) as T extends true ? string[] : string);
        }}
      />
    </LabeledGroup>
  );
};

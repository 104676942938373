import { Route, Routes, useParams } from '@solidjs/router';
import { createEffect } from 'solid-js';
import { useUnits } from '~/contexts/global';
import {
  DebugProvider,
  LeaseDepositsProvider,
  LeaseProvider,
  LeaseRentChargesProvider,
  LeaseRentTransactionsProvider,
  LeasesListProvider,
  MaintenanceRequestsProvider,
  useLease,
  LeaseAutoPaymentsProvider,
  ServiceBillProvider,
} from '~/contexts/local';
import { EditLeaseView } from '~/pages/leases/edit-lease';
import { LeaseDetails } from './LeaseDetails';
import { useUpdates } from './UpdatePagesContext';

const LeaseDetailsWrapper = () => {
  const { lease, getLease } = useLease();
  const params = useParams();
  const { getUnit } = useUnits();
  const { updateSignal } = useUpdates();

  createEffect(() => {
    updateSignal();
    getLease(params.leaseId);
  });

  createEffect(() => {
    updateSignal();
    const unitId = lease()?.unitId;
    if (unitId) getUnit(unitId); // for navbar
  });

  return (
    <Routes>
      <Route path="edit-lease" component={EditLeaseView} />
      <Route path="*" component={LeaseDetails} />
    </Routes>
  );
};

export const LeaseDetailsView = () => {
  return (
    <DebugProvider>
      <MaintenanceRequestsProvider>
        <ServiceBillProvider>
          <LeasesListProvider>
            <LeaseProvider>
              <LeaseRentChargesProvider>
                <LeaseRentTransactionsProvider>
                  <LeaseDepositsProvider>
                    <LeaseAutoPaymentsProvider>
                      <LeaseDetailsWrapper />
                    </LeaseAutoPaymentsProvider>
                  </LeaseDepositsProvider>
                </LeaseRentTransactionsProvider>
              </LeaseRentChargesProvider>
            </LeaseProvider>
          </LeasesListProvider>
        </ServiceBillProvider>
      </MaintenanceRequestsProvider>
    </DebugProvider>
  );
};

import { createSignal, createEffect, Show } from 'solid-js';
import IconEmailMsg from '~/assets/images/announcement/emailMsg.svg?component-solid';
import IconTextMsg from '~/assets/images/announcement/textMsg.svg?component-solid';
import IconMessageBanner from '~/assets/images/chat/messageBanner.png';
import { useForm, Form, FormItem } from '~/components/common/BetterForm';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useCompanies, useLocalization } from '~/contexts/global';
import { prefilledContent } from './DonnaProperty';
import { MessageInput } from './MesssageInput';
import { NotificationModalProvider, useNotificationModal } from './NotificationModalContext';
import type { Component } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm';

export type NotificationFormData = {
  Subject: string;
  ChatMessage?: string;
  EmailMessage?: string;
  ChatAttachments?: File[];
  EmailAttachments?: File[];
};

export interface CreateNotificationModalProps {
  onClose: () => void;
}

export type NotificationModalProps = {
  title: string;
  onSubmit: (formData: FormData) => Promise<void>;
  onClose: () => void;
  notificationType: 'lease' | 'owner' | 'vendor';
};

// TODO: TEMP SOLUTION WAITING BACKEND
// DONNA'S COMPANY ID: '1248155916383899648' (OURS IS 1192372134522703872)
const COMPANY_ID = '1248155916383899648';

const subjectOptions = {
  lease: [
    { value: 'tenant-platinum-group', label: 'Tenant Platinum Group - HOA Notice of Violation' },
    { value: 'centurion-hoa-violation', label: 'Centurion: Hoa Violation' },
    { value: 'ameropan-hoa-violation', label: 'Ameropan: HOA Violation' },
  ],
  owner: [
    { value: 'centurion-hoa-violation', label: 'CENTURION: HOA VIOLATION' },
    { value: 'ameropan-hoa-violation', label: 'AMEROPAN: HOA VIOLATION' },
  ],
  vendor: [
    { value: 'centurion-hoa-violation', label: 'CENTURION: HOA VIOLATION' },
    { value: 'ameropan-hoa-violation', label: 'AMEROPAN: HOA VIOLATION' },
  ],
} as const;

export type SubjectOption = (typeof subjectOptions)[keyof typeof subjectOptions][number]['value'];

const NotificationModalContent: Component<NotificationModalProps> = (props) => {
  const { t } = useLocalization();
  const form = useForm();
  const { companies } = useCompanies();
  const { getChatFiles, getEmailFiles } = useNotificationModal();

  const [loading, setLoading] = createSignal<boolean>(false);
  const [sendChatMessage, setSendChatMessage] = createSignal<boolean>(false);
  const [sendEmailMessage, setSendEmailMessage] = createSignal<boolean>(false);
  const [formErrors, setFormErrors] = createSignal<Partial<NotificationFormData>>({});
  const [currentSubjectOptions, setCurrentSubjectOptions] = createSignal<Array<{ value: string; label: string }>>([]);
  const [selectedSubject, setSelectedSubject] = createSignal<string>('');

  const onDone = async () => {
    if (!validator()) return;

    try {
      setLoading(true);
      const formData = new FormData();

      formData.append('Subject', form.getFieldValue('Subject')?.toString() ?? '');

      const chatAttachments = getChatFiles();
      if (sendChatMessage() && Array.isArray(chatAttachments)) {
        formData.append('ChatMessage', form.getFieldValue('ChatMessage')?.toString() ?? '');
        chatAttachments.forEach((file) => formData.append('ChatAttachments', file));
      }

      const emailAttachments = getEmailFiles();
      if (sendEmailMessage() && Array.isArray(emailAttachments)) {
        formData.append('EmailMessage', form.getFieldValue('EmailMessage')?.toString() ?? '');
        emailAttachments.forEach((file) => formData.append('EmailAttachments', file));
      }
      await props.onSubmit(formData);
      toast(t('Create notification success'), 'success');
      props.onClose();
    } finally {
      setLoading(false);
    }
  };

  const updateMessagesForSubject = (value: string) => {
    if (companies()?.id === COMPANY_ID) {
      if (value === '') {
        form.setValueToStore('ChatMessage', '');
        form.setValueToStore('EmailMessage', '');
      } else {
        const content = prefilledContent[props.notificationType]?.[value as SubjectOption];
        if (content) {
          form.setValueToStore('ChatMessage', content.chatMessage);
          form.setValueToStore('EmailMessage', content.emailMessage);
        } else {
          form.setValueToStore('ChatMessage', '');
          form.setValueToStore('EmailMessage', '');
        }
      }
    }
  };

  createEffect(() => {
    const options = subjectOptions[props.notificationType];
    if (options && options.length > 0 && companies()?.id === COMPANY_ID) {
      const emptyOption = { value: '', label: t('Select a custom script') };
      setCurrentSubjectOptions([emptyOption, ...options]);
      setSelectedSubject(emptyOption.value);
      form.setValueToStore('Subject', '');
      updateMessagesForSubject(emptyOption.value);
    }
  });

  const validator = (): boolean => {
    const errors: Partial<NotificationFormData> = {};
    const subject = form.getFieldValue('Subject')?.toString() ?? '';

    if (!subject) {
      errors.Subject = t('Subject is required');
    }

    if (sendChatMessage() && !form.getFieldValue('ChatMessage')) {
      errors.ChatMessage = t('Chat message is required when selected');
    }

    if (sendEmailMessage() && !form.getFieldValue('EmailMessage')) {
      errors.EmailMessage = t('Email message is required when selected');
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <Modal size="lg" title={props.title} visible={true} onCancel={props.onClose} onDone={onDone} loading={loading()}>
      <img src={IconMessageBanner} />
      <Form defaultForm={form} class="flex flex-col gap-x-7 gap-y-6 px-8 pb-5 pt-6">
        <Show
          when={companies()?.id === COMPANY_ID}
          fallback={
            <FormItem
              label={t('Subject')}
              formFieldName="Subject"
              component={LabeledTextInput}
              placeholder={t('Please enter')}
              rules={[{ required: true, message: t(`Please input the subject`) }]}
            />
          }>
          <div class="flex flex-col gap-2">
            <FormItem
              label={t('Custom scripts')}
              formFieldName="SubjectSelection"
              component={LabeledSelect}
              options={currentSubjectOptions()}
              value={selectedSubject()}
              customSyncValue={(formContext: BetterForm.form, realValue: any) => {
                const value = realValue ?? '';
                const selectedOption = currentSubjectOptions().find((option) => option.value === value);
                if (selectedOption) {
                  setSelectedSubject(value);
                  formContext.setValueToStore('Subject', selectedOption.value === '' ? '' : selectedOption.label);
                  updateMessagesForSubject(value);
                }
              }}
            />
            <FormItem
              label={t('Subject')}
              formFieldName="Subject"
              component={LabeledTextInput}
              placeholder={t('Enter subject')}
              rules={[{ required: true, message: t(`Please input the subject`) }]}
            />
          </div>
        </Show>
        {formErrors().Subject && <span class="text-sm text-red-500">{formErrors().Subject}</span>}

        <div class="grid grid-cols-2 gap-x-7 gap-y-6">
          <Checkbox
            class="col-span-full flex space-x-4 lg:col-span-2"
            label={
              <div class="flex items-center gap-1">
                {t('Send chat message')}
                <IconTextMsg class="text-gray-400" />
              </div>
            }
            checked={sendChatMessage()}
            onInput={setSendChatMessage}
            showLabel={true}
          />
          <Checkbox
            class="col-span-full flex space-x-4 lg:col-span-2"
            label={
              <div class="flex items-center gap-1">
                {t('Send email message')}
                <IconEmailMsg class="text-gray-400" />
              </div>
            }
            checked={sendEmailMessage()}
            onInput={setSendEmailMessage}
            showLabel={true}
          />
        </div>

        <Show when={sendChatMessage()}>
          <FormItem
            placeholder={t('Please enter')}
            label={t('Chat message')}
            formFieldName="ChatMessage"
            component={MessageInput}
            rows={4}
            type="chat"
          />
          {formErrors().ChatMessage && <span class="text-sm text-red-500">{formErrors().ChatMessage}</span>}
        </Show>

        <Show when={sendEmailMessage()}>
          <FormItem
            placeholder={t('Please enter')}
            label={t('Email message')}
            formFieldName="EmailMessage"
            component={MessageInput}
            rows={10}
            type="email"
          />
          {formErrors().EmailMessage && <span class="text-sm text-red-500">{formErrors().EmailMessage}</span>}
        </Show>
      </Form>
    </Modal>
  );
};

export const GenericNotificationModal: Component<NotificationModalProps> = (props) => {
  return (
    <NotificationModalProvider>
      <NotificationModalContent {...props} />
    </NotificationModalProvider>
  );
};

import { BankAccountsListProvider, DepositPaymentSettingsProvider } from '~/contexts/local';
import { DepositPaymentSettings } from './Settings';

export const PropertyDepositPaymentSettingsWrapper = () => {
  return (
    <DepositPaymentSettingsProvider>
      <BankAccountsListProvider>
        <DepositPaymentSettings />
      </BankAccountsListProvider>
    </DepositPaymentSettingsProvider>
  );
};

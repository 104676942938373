import { A } from '@solidjs/router';
import { createEffect, createSignal } from 'solid-js';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import { AIScoreCell } from '~/components/common/AiScoreCell';
import { Section } from '~/components/common/Section';
import { FilePreviewModal } from '~/components/file-attachments/FilePreviewModal';
import { FileTitle } from '~/components/file-attachments/FileTitle';
import StatusCol from '~/components/rental-application/StatusCol';
import { TransactionLeaseName, TransactionPaymentMethod, TransactionStatus } from '~/components/transactions';
import { IconCircleChevronRight, IconMail, IconPhone, Table } from '~/components/ui';
import { useLocalization, useRentalApplication, useTenants } from '~/contexts/global';
import { useAttachments } from '~/contexts/local';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';

// const SocialSecurity: Component<{ class?: string; loading?: boolean; tenant?: MagicDoor.Api.HydratedTenantDto }> = (props) => {
//   const { t } = useLocalization();

//   return (
//     <Section
//       title={t('Social security')}
//       class={props.class}
//       contentClass="m-6 flex justify-center rounded-lg bg-current-alpha py-5 font-bold text-success">
//       <Show when={!props.loading} fallback={<Skeleton class="h-6 w-32 bg-success/20" />}>
//         {props.tenant?.ssn}
//       </Show>
//     </Section>
//   );
// };

// const TenantPortal: Component<{ class?: string; loading?: boolean; tenant?: MagicDoor.Api.HydratedTenantDto }> = (props) => {
//   const { t } = useLocalization();

//   return (
//     <Section title={t('Tenant portal')} class={props.class} contentClass="space-y-2 px-7 py-4 text-xs">
//       <div class="flex items-center gap-2">
//         <span class="uppercase text-text-level03">{t('Status')}:</span>
//         <Show when={!props.loading} fallback={<Skeleton class="w-20 rounded-full" />}>
//           <TenantPortalStatus status={props.tenant?.portalStatus} />
//         </Show>
//       </div>
//       <div class="flex items-center gap-2">
//         <span class="uppercase text-text-level03">{t('Last seen')}:</span>
//         <Show when={!props.loading} fallback={<Skeleton class="h-4 w-24" />}>
//           <span class="font-medium text-text-level02">{dateFormat('MM/DD/YY', props.tenant?.lastSeen)}</span>
//         </Show>
//       </div>
//       <div class="flex items-center gap-2">
//         <span class="uppercase text-text-level03">{t('Login email')}:</span>
//         <Show when={!props.loading} fallback={<Skeleton class="h-4 w-40" />}>
//           <span class="font-medium text-text-level02">{props.tenant?.email}</span>
//         </Show>
//       </div>
//     </Section>
//   );
// };

const LatestPayments: Component<{ class?: string; loading?: boolean; tenant?: MagicDoor.Api.HydratedTenantDto }> = (props) => {
  const { t } = useLocalization();
  const { payments, getPayments } = useTenants();

  createEffect(() => props.tenant && getPayments({ tenantId: props.tenant.id, pageSize: 5 }));

  return (
    <Section
      title={t('Latest payments')}
      class={props.class}
      titleClass="border-0"
      contentClass="thinscroll overflow-x-auto whitespace-nowrap text-text-level01">
      <Table
        loading={props.loading || payments.loading}
        data={payments()?.items}
        columns={[
          {
            title: t('Date'),
            headerClass: 'w-32',
            render: (item) => dateFormat('', item.transactionDate),
          },
          {
            title: t('Transaction type'),
            headerClass: 'w-40',
            render: (item) => <TransactionPaymentMethod method={item.transactionPaymentMethod} />,
          },
          {
            title: t('Lease'),
            render: (item) => <TransactionLeaseName property={item.property} unit={item.unit} />,
          },
          {
            title: t('Status'),
            headerClass: 'w-24',
            render: (item) => <TransactionStatus status={item.status} />,
          },
          {
            title: t('Amount'),
            headerClass: 'w-32 text-right',
            class: 'text-right font-medium',
            render: (item) => currency(item.amount),
          },
        ]}
      />
    </Section>
  );
};

const Files: Component<{ class?: string; loading?: boolean; tenant?: MagicDoor.Api.HydratedTenantDto }> = (props) => {
  const { t } = useLocalization();
  const { attachments, getAttachments } = useAttachments();
  const [preview, setPreview] = createSignal<MagicDoor.Api.HydratedFileAttachmentDto>();

  createEffect(() => props.tenant && getAttachments('tenant', props.tenant.id));

  return (
    <Section
      title={
        <>
          {t('Files')}
          <A href="files" class="flex items-center gap-1 text-xs font-normal text-link">
            {t('View all')}
            <IconCircleChevronRight class="size-3.5 text-link" />
          </A>
        </>
      }
      class={props.class}
      titleClass="flex justify-between border-0"
      contentClass="thinscroll overflow-x-auto whitespace-nowrap text-text-level01">
      <Table
        loading={props.loading || attachments.loading}
        data={attachments()?.slice(0, 5)}
        onRowClick={setPreview}
        columns={[
          {
            title: t('Type'),
            render: (item) => <FileTitle file={item} />,
          },
          {
            title: t('From'),
            class: 'space-y-1 text-xs',
            render: (item) => (
              <>
                <p class="text-title-gray">{dateFormat('M/D/YY', item.created)}</p>
                <p class="text-text-level03">
                  {t('by')} {props.tenant?.firstName}
                </p>
              </>
            ),
          },
        ]}
      />
      <FilePreviewModal file={preview()} onClose={setPreview} />
    </Section>
  );
};

const EmergencyContacts: Component<{ class?: string; loading?: boolean; tenant?: MagicDoor.Api.HydratedTenantDto }> = (props) => {
  const { t } = useLocalization();

  return (
    <Section
      title={t('Emergency contacts')}
      class={props.class}
      titleClass="border-0"
      contentClass="thinscroll overflow-x-auto whitespace-nowrap text-text-level01">
      <Table
        loading={props.loading}
        data={props.tenant?.emergencyContacts || []}
        columns={[
          {
            key: 'name',
            title: t('Name'),
          },
          {
            title: t('Contact'),
            class: 'space-y-1 text-xs',
            render: (item) => (
              <>
                <p class="flex items-center gap-1">
                  <IconPhone class="size-3.5 text-text-level03" /> {item.phone}
                </p>
                <p class="flex items-center gap-1">
                  <IconMail class="size-3.5 text-text-level03" /> {item.email}
                </p>
              </>
            ),
          },
        ]}
      />
    </Section>
  );
};

const RentalApplicationHistory: Component<{ class?: string; loading?: boolean; tenant?: MagicDoor.Api.HydratedTenantDto }> = (props) => {
  const { t } = useLocalization();

  const { isLoading, rentalApplications, getRentalApplications } = useRentalApplication();

  createEffect(() => props.tenant && getRentalApplications({ tenantId: props.tenant.id }));

  return (
    <Section
      title={t('Rental application history')}
      class={props.class}
      titleClass="border-0"
      contentClass="thinscroll overflow-x-auto whitespace-nowrap text-text-level01">
      <Table
        loading={props.loading || isLoading()}
        data={rentalApplications()}
        columns={[
          {
            title: t('Date'),
            render: (item) => dateFormat('', item.createdAt),
          },
          {
            title: t('Status'),
            render: (item) => <StatusCol status={item.applicationDecision} />,
          },
          {
            title: t('Applied unit'),
            render: (item) => (item.interests?.length ? item.interests[0].unit.name : emptyPlaceholder),
          },
          {
            title: t('AI score'),
            render: (item) => <AIScoreCell score={item.aiApplicationScore} />,
          },
          {
            render: (item) => (
              <div>
                <A href={`/leasing/rental-applications/${item.id}`} class="flex items-center gap-1 text-xs text-link ">
                  {t('View application')}
                  <IconViewArrow class="size-4 text-link" />
                </A>
              </div>
            ),
          },
        ]}
      />
    </Section>
  );
};

export const TenantOverviewTab = () => {
  const { current } = useTenants();
  return (
    <div class="grid gap-5 lg:grid-cols-2">
      {/* <SocialSecurity loading={current.loading} tenant={current()} />
      <TenantPortal loading={current.loading} tenant={current()} /> */}
      <LatestPayments class="col-span-full" loading={current.loading} tenant={current()} />
      <Files loading={current.loading} tenant={current()} />
      <EmergencyContacts loading={current.loading} tenant={current()} />
      <RentalApplicationHistory class="col-span-full" loading={current.loading} tenant={current()} />
    </div>
  );
};

import { TenantPortalInviteSettingsProvider } from '~/contexts/local';
import { InviteNotification } from './InviteNotification';

export const PortalInviteText = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <TenantPortalInviteSettingsProvider>
        <InviteNotification />
      </TenantPortalInviteSettingsProvider>
    </div>
  );
};

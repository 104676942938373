import { createSignal } from 'solid-js';
import { Form } from '~/components/common/BetterForm';
import { useForm } from '~/components/common/BetterForm/context';
import { BaseModal } from '~/components/modals/BaseModal';
import { Footer } from '~/components/modals/Footer';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { RentalApplicationDocuments } from '~/pages/rental-applications/add-rental-application/Documents';
import { currentUpdateType } from '~/utils/constant';

export const DocumentsModal = () => {
  const { applicationStore, setApplicationStore, fetchApplication } = useRentalApplication();
  const { t } = useLocalization();
  const form = useForm();

  const [loading, setLoading] = createSignal<boolean>(false);

  let rentalApplicationDocumentsRef: any;

  function onClose() {
    setApplicationStore('currentUpdateType', null);
    fetchApplication(applicationStore.application?.id, true);
  }

  const submit = async () => {
    try {
      setLoading(true);
      await rentalApplicationDocumentsRef.submit();
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal
      class="w-[820px!important]"
      title={t('Update documents')}
      visible={applicationStore.currentUpdateType === currentUpdateType.document}
      onClose={() => {
        setApplicationStore('currentUpdateType', null);
      }}>
      <div class="thinscroll flex max-h-[50vh] flex-col gap-2 overflow-y-auto p-4">
        <Form defaultForm={form}>
          <RentalApplicationDocuments
            ref={rentalApplicationDocumentsRef}
            autoUpload={false}
            appId={applicationStore.application?.id}
            files={applicationStore.application?.files}
          />
        </Form>
      </div>
      <Footer
        okProps={{
          loading: loading(),
        }}
        onOk={submit}
        onCancel={onClose}
      />
    </BaseModal>
  );
};

import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
import type { SectionProps } from '~/components/common/Panels/MultiSectionPanel';

type ListingTermsProps = SectionProps<MagicDoor.Api.HydratedUnitDto>;

export const ListingTerms: Component<ListingTermsProps> = (props) => {
  const { t } = useLocalization();
  return (
    <div class="grid grid-cols-2 gap-8">
      <LabeledTextInput label={t('Rent amount')} value={undefined} onInput={(e) => props.onChange && props.onChange('', e)} />
      <LabeledTextInput label={t('Security deposit($)')} value={undefined} onInput={(e) => props.onChange && props.onChange('', e)} />
      <LabeledTextInput label={t('Date available')} value={undefined} onInput={(e) => props.onChange && props.onChange('', e)} />
      <LabeledTextInput label={t('Lease duration(year)')} value={undefined} onInput={(e) => props.onChange && props.onChange('', e)} />
    </div>
  );
};

import { createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { useLocalization } from '~/contexts/global';
import { useBankAccount } from '~/contexts/local';
import BankDashboards from './BankDashboards';
import BankTransactionsTable from './BankTransactionsTable';

const BankAccountDetailsView = () => {
  const { t } = useLocalization();
  const { bankAccount, isLoading } = useBankAccount();

  const breadcrumbItems = createMemo(() => [
    { label: t('Bank accounts'), link: '/accounting/bank-accounts' },
    { label: `${t('Bank account details')}: ${bankAccount()?.name || t('Loading')}` },
  ]);

  return (
    <div class="flex w-full flex-col gap-y-4 text-xs" classList={{ 'cursor-wait': isLoading() }}>
      <Breadcrumb backLink={'/accounting/bank-accounts'} items={breadcrumbItems()} />
      <div class="flex flex-col gap-4 px-8">
        <BankDashboards />
        <BankTransactionsTable />
      </div>
    </div>
  );
};

export default BankAccountDetailsView;

import { useNavigate, useParams } from '@solidjs/router';
import dayjs from 'dayjs';
import { createSignal } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { LabeledVendorSearch } from '~/components/vendors';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequests } from '~/contexts/local';
import { priorityTypes } from '~/utils/constant';
import { useCreateModel } from '~/utils/hooks';
import type { Setter } from 'solid-js';

export const ConvertToWorkOrderModal = (props: { visible: Setter<boolean>[] }) => {
  const { t } = useLocalization();
  const { convertToWorkOrder } = useMaintenanceRequests();

  const formId = 'convert_to_work_order';
  const [loading, setLoading] = createSignal<boolean>(false);
  const form = useForm();
  const [visible, setVisible] = useCreateModel(props, 'visible', false);
  const params = useParams<{ maintenanceRequestId: string }>();
  const navigate = useNavigate();

  const handleClose = () => {
    setVisible(false);
  };
  const handleDone = async () => {
    setLoading(true);
    try {
      const res = await convertToWorkOrder(params.maintenanceRequestId, form.formStore);
      toast.success(t('Operation successful'));
      handleClose();
      navigate(`/maintenance/work-orders/${res.id}`);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      visible={visible()}
      title={t('Convert to work order')}
      submitId={formId}
      loading={loading()}
      onDone={handleDone}
      onCancel={handleClose}>
      <Form
        class="grid grid-cols-2 gap-4 p-4"
        defaultForm={form}
        initialValues={{
          status: 'pending',
          urgency: 'low',
          dueDate: dayjs().format('YYYY-MM-DD'),
        }}>
        <FormItem<any>
          class="col-span-full"
          label={t(`Vendor`)}
          formFieldName="vendorId"
          component={LabeledVendorSearch}
          placeholder={t('Please select vendor')}
        />
        <FormItem class="col-span-full lg:col-span-1" formFieldName="dueDate" label={t('Due date')} component={DueDateInputField} />
        <FormItem
          class="col-span-full lg:col-span-1"
          label={t(`Priority`)}
          options={priorityTypes}
          formFieldName="urgency"
          onChangeMethodName="onChange"
          component={DropdownMenu}
          placeholder={t('Please select priority')}
        />
      </Form>
    </Modal>
  );
};

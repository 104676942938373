import { ValidationError } from '~/errors';
import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

const PROPERTIES_URL = '/api/properties';

export function validateProperties(properties: MagicDoor.Api.CreatePropertyDto | MagicDoor.Api.UpdatePropertyDto): void {
  if (!properties) throw new ValidationError('Properties object must be provided');
}

export function validateId(id: string, entityName = 'Entity'): void {
  if (!id) throw new ValidationError(`${entityName} ID must be provided`);
}

export type PropertiesFilter = MagicDoor.Filter<{
  portfolioId?: string;
}>;

export type PropertyImagePayload = {
  file: File;
  isDefault?: boolean;
  description?: string;
};

export class PropertiesRepository extends BaseRestRepository {
  public async getProperties(filter?: PropertiesFilter): Promise<MagicDoor.Api.HydratedPropertyDtoPaginationDto> {
    const url = urlWithQuery(PROPERTIES_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getAllProperties(filter?: Omit<PropertiesFilter, 'page' | 'pageSize'>): Promise<MagicDoor.Api.PropertyDto[]> {
    const url = urlWithQuery(`${PROPERTIES_URL}/all`, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getProperty(propertyId: string): Promise<MagicDoor.Api.HydratedPropertyDto> {
    validateId(propertyId);
    const url = `${PROPERTIES_URL}/${propertyId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async createProperty(property: MagicDoor.Api.CreatePropertyDto): Promise<MagicDoor.Api.PropertyDto> {
    validateProperties(property);
    const url = PROPERTIES_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(property),
    });
    return this.getJsonResponse(response);
  }

  public async updateProperty(propertyId: string, property: MagicDoor.Api.UpdatePropertyDto): Promise<MagicDoor.Api.PropertyDto> {
    validateId(propertyId);
    validateProperties(property);
    const url = `${PROPERTIES_URL}/${propertyId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(property),
    });
    return this.getJsonResponse(response);
  }

  /** Not preferred. use deactivateProperty instead in most cases */
  public async deleteProperty(propertyId: string): Promise<void> {
    validateId(propertyId);
    const url = `${PROPERTIES_URL}/${propertyId}`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async deactivateProperty(propertyId: string, forceDeactivateChildren?: boolean): Promise<void> {
    validateId(propertyId);
    const url = urlWithQuery(`${PROPERTIES_URL}/${propertyId}/deactivate`, { forceDeactivateChildren });
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async addPropertyImage(propertyId: string, image: PropertyImagePayload): Promise<MagicDoor.Api.PropertyDto> {
    validateId(propertyId);
    const formData = new FormData();
    formData.append('imageFile', image.file, image.file.name);
    formData.append('isDefault', String(image.isDefault ?? false));
    image.description && formData.append('description', image.description);
    const url = `${PROPERTIES_URL}/${propertyId}/images`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      body: formData,
    });
    return this.getJsonResponse(response);
  }

  public async deletePropertyImage(propertyId: string, imageId: string): Promise<MagicDoor.Api.PropertyDto> {
    validateId(propertyId);
    validateId(imageId);
    const url = `${PROPERTIES_URL}/${propertyId}/images/${imageId}`;
    const response = await this.fetchWithAuth(url, { method: 'DELETE' });
    return this.getJsonResponse(response);
  }

  public async removePropertyOwner(propertyId: string, ownerId: string): Promise<void> {
    const url = `${PROPERTIES_URL}/${propertyId}/owners/${ownerId}`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async addPropertyOwner(
    propertyId: string,
    ownerId: string,
    payload: MagicDoor.Api.PropertyOwnershipDto
  ): Promise<MagicDoor.Api.HydrateOwnerPropertyDto> {
    const url = `${PROPERTIES_URL}/${propertyId}/owners/${ownerId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async updatePropertyOwner(
    propertyId: string,
    ownerId: string,
    payload: MagicDoor.Api.PropertyOwnershipDto
  ): Promise<MagicDoor.Api.HydrateOwnerPropertyDto> {
    const url = `${PROPERTIES_URL}/${propertyId}/owners/${ownerId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getPropertyOverview(propertyId: string, useUpdatedAt?: boolean): Promise<MagicDoor.Api.PropertyOverviewDto> {
    let url = `${PROPERTIES_URL}/${propertyId}/overview`;
    if (useUpdatedAt) {
      url += `?updatedAt=` + new Date().getTime();
    }
    const response = await this.fetchWithAuth(url, { method: 'GET' });
    return this.getJsonResponse(response);
  }
}

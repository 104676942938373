import { dateFormat } from '~/utils/date';
import type { JSX } from 'solid-js';
interface UserActivityProps {
  name: string;
  time?: Date;
  action?: string;
}

const UserActivity = (props: UserActivityProps): JSX.Element => {
  const getInitials = (): string => {
    const parts = props.name.split(' ');
    const initials = parts.map((part) => part.charAt(0).toUpperCase()).join('');
    return initials.slice(0, 2);
  };

  return (
    <div class="flex px-2">
      <div class="flex size-8 shrink-0 items-center justify-center rounded-full bg-gray-500 text-white">{getInitials()}</div>
      <div class="ml-4 flex flex-col gap-1">
        <div class="text-sm font-medium text-text-level01">
          {props.name}
          {props.action && ` ${props.action}`}
        </div>
        <div class="text-xs text-text-level03">{dateFormat('', props.time)}</div>
      </div>
    </div>
  );
};

export default UserActivity;

import { Route, Routes } from '@solidjs/router';
import { ChatsListProvider } from '~/contexts/local';
import { UnreadMessageView } from './unread-messages';

const UnreadMessagesRoutes = () => {
  return (
    <ChatsListProvider>
      <Routes>
        <Route path="/*" component={UnreadMessageView} />
      </Routes>
    </ChatsListProvider>
  );
};

export default UnreadMessagesRoutes;

import { createMemo, onMount, Show } from 'solid-js';
import IconRentPayment from '~/assets/images/settings/property/propertyRentPayment.svg';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { toast, IconLoader } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRentPaymentFailureFeeSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';
import type { ItemProps } from '~/pages/settings/components/edit-form/EditForm';

export const RentPaymentFailureFeeSettings = () => {
  const { t } = useLocalization();
  const { isLoading, rentPaymentFailureFeeSettings, getRentPaymentFailureFeeSettings, updateRentPaymentFailureFeeSettings } =
    useRentPaymentFailureFeeSettings();

  const sections = createMemo<ItemProps[]>(() => {
    return [
      {
        field: 'chargeFeeForFailedTransfers',
        label: 'Charge fee for failed transfers',
        type: 'boolean',
        class: 'col-span-2',
        labelContainerClass: 'flex-row justify-between',
      },
      {
        field: 'noSufficientFundsFeeChargeChartOfAccountId',
        label: 'No sufficient funds fee charge chart of account',
        type: 'chartOfAccountSelect',
        class: 'col-span-2',
        labelClass: 'w-1/2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
      },
      {
        field: 'achReturnFeeChartOfAccountId',
        label: 'ACH return fee chart of account',
        type: 'chartOfAccountSelect',
        class: 'col-span-2',
        labelClass: 'w-1/2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        description:
          'When a ACH payment is returned, we charge the tenant a return fee, select what charge of account should be used on the charge here.',
      },
      {
        field: 'achFailedProcessingFeeChartOfAccountId',
        label: 'Failed ACH fee chart of account',
        type: 'chartOfAccountSelect',
        class: 'col-span-2',
        labelClass: 'w-1/2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        description:
          'Charges are posted to the tenants lease in adance, indicate here how many days in advance you want us to post the bill for. This may take up to 24 hours to appy.',
      },
      {
        field: 'disputedFeeChartOfAccountId',
        label: 'Disputed fee chart of account id',
        type: 'chartOfAccountSelect',
        class: 'col-span-2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
      },
      {
        field: 'noSufficientFundsFee',
        label: 'No sufficient funds fee',
        type: 'number',
        class: 'col-span-2',
        labelClass: 'w-1/2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        rules: [
          {
            validator: (value: number) => value <= 1000 && value >= 0,
            message: t('The value of amount is between 0 and 1000'),
          },
        ],
      },
      {
        field: 'achFailedFee',
        label: 'ACH failed fee',
        type: 'number',
        class: 'col-span-2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        rules: [
          {
            validator: (value: number) => value <= 1000 && value >= 0,
            message: t('The value of amount is between 0 and 1000'),
          },
        ],
      },
      {
        field: 'achReturnFee',
        label: 'ACH return fee',
        type: 'number',
        class: 'col-span-2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        rules: [
          {
            validator: (value: number) => value <= 1000 && value >= 0,
            message: t('The value of amount is between 0 and 1000'),
          },
        ],
      },
      {
        field: 'disputedFee',
        label: 'Disputed fee',
        type: 'number',
        class: 'col-span-2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        rules: [],
      },
    ];
  });

  onMount(async () => {
    await getRentPaymentFailureFeeSettings();
  });

  const handleSave = async (data: MagicDoor.Api.RentPaymentFailureFeeSettingsDto) => {
    await updateRentPaymentFailureFeeSettings(data);
    toast.success(t('{name} has been edited successfully', { name: t('Rent payment failure fee') }));
    getRentPaymentFailureFeeSettings();
  };

  return (
    <>
      <ThreeSectionBox
        class="mt-5 h-fit"
        topTitle={<SettingTitle title={t('Rent payment failure fee')} icon={IconRentPayment} />}
        middle={
          <div class="flex flex-col gap-5 p-4">
            <Show when={!isLoading()} fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
              <div class="flex flex-col gap-4 p-2 text-left">
                <EditForm
                  sections={sections()}
                  onOk={(data) => handleSave(data)}
                  model={rentPaymentFailureFeeSettings() || ({} as MagicDoor.Api.RentPaymentFailureFeeSettingsDto)}
                />
              </div>
            </Show>
          </div>
        }
      />
    </>
  );
};

import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { Panel } from '~/components/common/Panels';
import UnderConstruction from '~/components/common/UnderConstruction';
import { useLocalization } from '~/contexts/global';
export const ListingSyndications = () => {
  const { t } = useLocalization();

  return (
    <div class="relative">
      <Panel
        title={t('Listing syndications')}
        actions={
          <Button color="link" variant="outlined" class="px-4 py-2 text-base font-medium">
            <IconEdit class="size-5 text-link" />
            {t('Edit listing')}
          </Button>
        }>
        <div class="flex items-center px-6 py-5 text-xs text-text-level03">
          <Checkbox />
          <span class="ml-2">{t('I accept the magicDoor')}</span>
          <a class="ml-1 cursor-pointer text-link underline">{t('Terms and conditions')}</a>
        </div>
      </Panel>
      <UnderConstruction imgClass="size-24" />
    </div>
  );
};

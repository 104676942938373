import { useNavigate, useParams } from '@solidjs/router';
import { createMemo, createSignal, onMount, Show } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { PrintButton } from '~/components/common/Buttons/PrintButton';
import IconField from '~/components/common/IconField';
import { PrintPageHeader } from '~/components/print';
import { TenantTitle } from '~/components/tenants';
import { IconClock, IconHash, ListView } from '~/components/ui';
import { useCompanies, useDepositSlips, useLocalization } from '~/contexts/global';
import { getBankAccountName, getTenant } from '~/pages/deposit-slips/utils';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { JSX } from 'solid-js';
export const DepositSlipDetail = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const breadcrumbItems = createMemo(() => [
    { label: t('Deposit slips'), link: '/accounting/deposit-slips' },
    { label: t('Deposit slip detail') },
  ]);

  let depositSlipDetailRef!: HTMLDivElement;

  const { companies } = useCompanies();
  const { getDepositSlip } = useDepositSlips();
  const [depositSlip, setDepositSlip] = createSignal<MagicDoor.Api.DepositSlipDto>();
  const params = useParams<{ depositSlipId?: string }>();

  const totalAmount = createMemo<number>(
    () => depositSlip()?.transaction?.reduce((previous, current) => previous + current.amount, 0) || 0
  );

  const printHeader = createMemo<JSX.Element>(() => (
    <div>
      <PrintPageHeader companies={companies()} />
      <div class="my-5 flex flex-wrap text-left text-[12px] text-[#6A6D85]">
        <div class="w-1/2">
          {t('Bank account name')}:{' '}
          <span class="text-[#2C3045]">{getBankAccountName(depositSlip()?.bankAccounts, depositSlip()?.bankAccountId)}</span>
        </div>
        <div class="w-1/2">
          {t('Date')}: <span class="text-[#2C3045]">{dateFormat(t('MM/DD/YYYY'), depositSlip()?.date)}</span>
        </div>
        <div class="mt-1 w-1/2">
          {t('Total amount')}: <span class="text-[#2C3045]">{currency(totalAmount())}</span>
        </div>
        <div class="mt-1 w-1/2">
          {t('Reference')}: <span class="text-[#2C3045]">{depositSlip()?.reference}</span>
        </div>
      </div>
    </div>
  ));

  onMount(() => {
    params?.depositSlipId && getDepositSlip(params.depositSlipId).then((res) => setDepositSlip(res));
  });

  return (
    <div class=" relative">
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />

      <div class="relative flex flex-col-reverse flex-wrap  gap-3  p-8 lg:flex-row">
        <div class="w-full lg:flex-1" ref={depositSlipDetailRef}>
          <ListView
            title={t('Transactions')}
            data={depositSlip()?.transaction}
            skeletonSize={10}
            loading={!depositSlip()}
            columns={[
              {
                title: t('Date finalized'),
                render: (row) => <div>{dateFormat(t('MM/DD/YYYY'), row.transactionFinalizedDate)}</div>,
              },

              {
                title: t('Payment method'),
                render: (row) => <div>{row.transactionPaymentMethod || emptyPlaceholder}</div>,
              },
              {
                title: t('Reference'),
                render: (row) => <div>{row.reference || emptyPlaceholder}</div>,
              },

              {
                title: `${t('Memo')}/${t('Description')}`,
                render: (row) => <div>{row.memo || emptyPlaceholder}</div>,
              },

              {
                title: t('Payee owner or tenant'),
                render: (row) => (
                  <Show when={getTenant(depositSlip()?.tenants, row.tenantId || undefined)} fallback={emptyPlaceholder}>
                    <TenantTitle feedbackIconReadonly tenant={getTenant(depositSlip()?.tenants, row.tenantId || undefined)} />
                  </Show>
                ),
              },
              {
                title: t('Amount'),
                render: (row) => <div>{currency(row.amount)}</div>,
              },
            ]}
            footerSummary={
              <div class="w-full text-right text-text-level01">
                {t('Total amount')}: {currency(totalAmount())}
              </div>
            }
          />
        </div>

        <div class="mb-4 h-fit w-full divide-y divide-partingline overflow-hidden rounded-lg border border-partingline bg-white lg:w-72">
          <div class="px-5 py-4">
            <div class="flex items-center justify-between gap-1 ">
              <span class="text-lg font-semibold text-title-gray">{t('Deposit slip detail')} </span>
            </div>
          </div>
          <div class="flex w-full flex-row flex-wrap gap-5 p-4 lg:flex-col">
            <IconField name={t('Date')} loading={!depositSlip()} value={dateFormat(t('MM/DD/YYYY'), depositSlip()?.date)} src={IconClock} />
            <IconField
              name={t('Bank account name')}
              loading={!depositSlip()}
              value={getBankAccountName(depositSlip()?.bankAccounts, depositSlip()?.bankAccountId)}
              src={IconHash}
            />
            <IconField name={t('Reference')} loading={!depositSlip()} value={depositSlip()?.reference} src={IconHash} />
            <Show when={params?.depositSlipId}>
              <PrintButton printHeader={printHeader() as Element} printContainer={depositSlipDetailRef} />
            </Show>
          </div>
        </div>
      </div>
    </div>
  );
};

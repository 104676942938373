import { Switch, Match } from 'solid-js';
import { isMonthToMonth, isDraftLease, isEndedLease, isEvictionLease, isExpiredLease, isFutureLease } from '~/components/leases/utils';
import { DraftIcon, ComingUpIcon, EvictionIcon, MonthToMonthIcon, EndedIcon, ExpiredIcon, NotLeasedIcon, LeasedIcon } from './Icons';
import type { Component } from 'solid-js';

export const Avatar: Component<{
  lease: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto;
}> = (props) => {
  return (
    <span>
      <Switch fallback={<LeasedIcon />}>
        <Match when={isEvictionLease(props.lease)}>
          <EvictionIcon />
        </Match>
        <Match when={isMonthToMonth(props.lease)}>
          <MonthToMonthIcon />
        </Match>
        <Match when={isExpiredLease(props.lease)}>
          <ExpiredIcon />
        </Match>

        <Match when={isEndedLease(props.lease)}>
          <EndedIcon />
        </Match>
        <Match when={isFutureLease(props.lease)}>
          <ComingUpIcon />
        </Match>
        <Match when={isDraftLease(props.lease)}>
          <DraftIcon />
        </Match>
        <Match when={!props.lease}>
          <NotLeasedIcon />
        </Match>
      </Switch>
    </span>
  );
};

export class NotFoundError extends Error {
  private httpStatusCode?: number;
  private json?: any;

  constructor(httpStatusCode?: number, json?: any) {
    super();
    this.httpStatusCode = httpStatusCode;
    this.json = json;
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }

  getStatusCode() {
    return this.httpStatusCode;
  }

  getErrors(): Record<string, string> | undefined {
    return this.json?.detail ? { unknown: this.json?.detail } : this.json?.errors;
  }
}

import { For, Show, createEffect, createMemo, createSignal } from 'solid-js';
import { Avatar } from '~/components/common/Avatar';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { IconX, ListView } from '~/components/ui';
import { useLocalization, useTenants } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { dateFormat } from '~/utils/date';
import { TenantTitle } from './TenantTitle';
import { getTenantFullName } from './utils';
import type { Component, JSX } from 'solid-js';
import type { TenantFilter } from '~/repositories/tenantRepository';

export const TenantSelect: Component<{
  class?: string;
  size?: number;
  filter?: TenantFilter;
  value?: MagicDoor.Api.HydratedTenantDto[];
  onChange?: (tenants: MagicDoor.Api.HydratedTenantDto[]) => void;
  titleActions?: JSX.Element;
}> = (props) => {
  const { t } = useLocalization();
  const [selected, setSelected] = createSignal<MagicDoor.Api.HydratedTenantDto[]>([]);
  const { filtered, setFilter } = useTenants();

  const selectedIds = createMemo(() => selected().map((item) => item.id));

  createEffect(() => setSelected(props.value ?? []));
  createEffect(() => setFilter({ ...props.filter, pageSize: props.size ?? 10 }));

  const handleToggle = (tenant: MagicDoor.Api.HydratedTenantDto) => {
    if (selectedIds().includes(tenant.id)) {
      setSelected((prev) => prev.filter((item) => item.id !== tenant.id));
    } else {
      setSelected((prev) => [...prev, tenant]);
    }
    props.onChange?.(selected());
  };

  const handleSelectAll = (checked: boolean) => {
    const items = filtered()?.items;
    if (items == null) return;
    if (checked) {
      setSelected((prev) => [...prev, ...items.filter((item) => !selectedIds().includes(item.id))]);
    } else {
      setSelected((prev) => prev.filter((item) => !items.map((item) => item.id).includes(item.id)));
    }
    props.onChange?.(selected());
  };

  const getFirstLeaseCreatedAt = (tenant: MagicDoor.Api.HydratedTenantDto) => {
    const lease = tenant.currentLeases?.[0];
    return lease ? `${t('Created on')}: ${dateFormat(undefined, lease?.start)}` : t('No lease');
  };

  return (
    <ListView
      class={cn('border-0', props.class)}
      headerClass="p-3 [&_form]:flex-1 [&_input:focus]:w-full [&_input]:w-full"
      subHeader={
        <Show when={selected().length > 0}>
          <div class="flex flex-wrap items-center gap-2 px-6 py-4">
            <For each={selected()}>
              {(tenant) => (
                <div class="group relative flex select-none items-center gap-2 overflow-hidden rounded-md border border-primary/50 p-2 text-xs text-text-level01">
                  <Avatar class="size-8 text-xs" name={getTenantFullName(tenant)} />
                  <span>{getTenantFullName(tenant)}</span>
                  <button
                    type="button"
                    class="absolute inset-0 flex items-center justify-center bg-black/50 opacity-0 transition-opacity group-hover:opacity-100"
                    onClick={() => handleToggle(tenant)}
                    title={t('Remove from selection')}>
                    <IconX class="size-6 text-primary" />
                  </button>
                </div>
              )}
            </For>
          </div>
        </Show>
      }
      page={filtered()?.currentPage}
      totalPages={filtered()?.totalPages}
      pageSize={filtered()?.pageSize}
      onRowClick={handleToggle}
      searchPlaceholder={t('Search tenants')}
      onChange={({ page, pageSize, search }) => setFilter({ ...props.filter, page, pageSize, search })}
      loading={filtered.loading}
      skeletonSize={10}
      data={filtered()?.items}
      titleActions={props.titleActions}
      columns={[
        {
          title: (
            <Checkbox
              checked={!filtered.loading && filtered()?.items.every((item) => selectedIds().includes(item.id))}
              onInput={handleSelectAll}
            />
          ),
          headerClass: 'w-15',
          render: (item) => <Checkbox checked={selectedIds().includes(item.id)} />,
        },
        {
          title: t('Tenant'),
          class: 'flex items-center gap-2 text-text-level01 select-none',
          render: (item) => <TenantTitle feedbackIconReadonly tenant={item} />,
        },
        {
          title: t('Lease'),
          headerClass: 'text-right w-56',
          class: 'text-right text-text-level03 select-none',
          render: (item) => getFirstLeaseCreatedAt(item),
        },
      ]}
    />
  );
};

import { Avatar } from '~/components/common/Avatar';
import { getOwnerName } from '~/components/owner/utils';

export const OwnerTitle = (props: { owner: MagicDoor.Api.OwnerDto }) => {
  return (
    <div>
      <div class="flex items-center justify-start gap-2">
        <Avatar class="size-9 shrink-0" name={getOwnerName(props.owner)} />
        <div class="flex flex-col text-sm text-text-level01">{getOwnerName(props.owner)}</div>
      </div>
    </div>
  );
};

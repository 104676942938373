import { DeactivatedFlag } from '~/components/common';
import { PropertyImage } from '~/components/properties';
import { useLocalization, useProperties } from '~/contexts/global';
import Card from '~/pages/dashboard/components/Card';
import type { Component } from 'solid-js';

type PropertyDistributionProps = {
  class?: string;
};

const PropertyDistribution: Component<PropertyDistributionProps> = (props) => {
  const { t } = useLocalization();
  const { propertyOverview } = useProperties();

  return (
    <Card
      class={props.class}
      title={t('Rent receivable')}
      contentClass="h-[174px]"
      header={
        <div class="flex items-center gap-3">
          <PropertyImage property={propertyOverview()?.property} class="size-10 shrink-0 rounded-md" />
          <div class="flex-1">
            <div class="text-left text-base font-semibold leading-6">
              {propertyOverview()?.property?.name}
              <DeactivatedFlag class="ml-2" active={propertyOverview()?.property?.active} />
            </div>
            <div class="text-left text-sm font-normal leading-tight">
              {propertyOverview()?.summary?.activeUnits} {t('Active units')}
            </div>
          </div>
        </div>
      }
      bgClass="h-auto">
      <div class="relative flex size-full items-center justify-center">
        <div class="flex w-full flex-col items-center justify-between px-8">
          <div class="m-5 flex w-full divide-x rounded-lg bg-light-gray py-6">
            <div class="flex-1 px-2">
              <h3 class="text-center text-xl font-semibold text-danger">{propertyOverview()?.summary?.vacantUnits ?? 0}</h3>
              <p class="mt-0.5 flex justify-center gap-1 text-[11px]">{t('Vacant')}</p>
            </div>
            <div class="flex-1 px-2">
              <h3 class="text-center text-xl font-semibold text-title-gray">{propertyOverview()?.summary?.occupiedUnits}</h3>
              <p class="mt-0.5 flex justify-center gap-1 text-[11px]">{t('Occupied')}</p>
            </div>
            <div class="flex-1 px-2">
              <h3 class="text-center text-xl font-semibold text-title-gray">{propertyOverview()?.summary?.unlistedUnits}</h3>
              <p class="mt-0.5 flex justify-center gap-1 text-[11px]">{t('Unlisted')}</p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PropertyDistribution;

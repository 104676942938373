import { BaseRestRepository } from '~/repositories/baseRestRepository';

const TenantAutoPayFailedNotificationUrl = '/api/settings/tenant-auto-pay-failed-notifications';

export class tenantAutoPayFailedNotificationRepository extends BaseRestRepository {
  public async deleteTenantAutoPayFailedNotificationByLevel(level: string, levelId: string) {
    const url = `${TenantAutoPayFailedNotificationUrl}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }

  public async getTenantAutoPayFailedNotificationAll() {
    const url = TenantAutoPayFailedNotificationUrl + '/all';
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getTenantAutoPayFailedNotification() {
    const url = TenantAutoPayFailedNotificationUrl;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async updateTenantAutoPayFailedNotification(payload: MagicDoor.Api.TenantAutoPayFailedNotificationSettingsDto) {
    const url = TenantAutoPayFailedNotificationUrl;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getTenantAutoPayFailedNotificationByLevel(level: string, levelId: string) {
    const url = `${TenantAutoPayFailedNotificationUrl}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async updateTenantAutoPayFailedNotificationByLevel(
    level: string,
    levelId: string,
    payload: MagicDoor.Api.TenantAutoPayFailedNotificationSettingsDto
  ) {
    const url = `${TenantAutoPayFailedNotificationUrl}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }
}

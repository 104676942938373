import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { PropertySettingsRepository } from '~/repositories/settings/propertySettingsRepository';

export const [PropertySettingsProvider, usePropertySettings] = createMagicDoorContext('PropertySettings', () => {
  const repo = new PropertySettingsRepository();
  const [isLoading, setIsLoading] = createSignal(false);
  const [error, setError] = createSignal<Error | undefined>(undefined);
  const [allPropertySettings, setAllPropertySettings] = createSignal<
    MagicDoor.Api.PropertySettingsDtoSettingsWithOverridesDto | undefined
  >();

  const getAllPropertySettings = async () => {
    try {
      setIsLoading(true);
      const json = await repo.getAllPropertySettings();
      setAllPropertySettings(json);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const updatePropertySettings = async (payload: MagicDoor.Api.PropertySettingsDto) => {
    try {
      setIsLoading(true);
      await repo.updatePropertySettings(payload);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const updateOverridePropertySettings = async (level: string, levelId: string, payload: MagicDoor.Api.PropertySettingsDto) => {
    try {
      setIsLoading(true);
      await repo.updatePropertySettingsByLevel(level, levelId, payload);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const addOverrideSettings = async (level: string, levelId: string, payload: MagicDoor.Api.PropertySettingsDto) => {
    try {
      setIsLoading(true);
      await repo.updatePropertySettingsByLevel(level, levelId, payload);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const removeOverrideSettings = async (level: string, levelId: string) => {
    try {
      setIsLoading(true);
      await repo.deletePropertySettingsByLevel(level, levelId);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error,
    isLoading,
    allPropertySettings,
    getAllPropertySettings,
    updatePropertySettings,
    addOverrideSettings,
    updateOverridePropertySettings,
    removeOverrideSettings,
  };
});

import { Show, createMemo, createSignal } from 'solid-js';
import IconAppMsg from '~/assets/images/announcement/appMsg.svg?component-solid';
import IconEmailMsg from '~/assets/images/announcement/emailMsg.svg?component-solid';
import IconPortfolio from '~/assets/images/announcement/portfolio.svg?component-solid';
import IconTextMsg from '~/assets/images/announcement/textMsg.svg?component-solid';
import IconUncategorized from '~/assets/images/announcement/uncategorized.svg?component-solid';
import IconUser from '~/assets/images/announcement/user.svg?component-solid';
import { Panel } from '~/components/common/Panels';
import UnderConstruction from '~/components/common/UnderConstruction';
import { Table } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { dateFormat } from '~/utils/date';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type AnnouncementListProps = {
  class?: string;
};

type ReceiverPerson = {
  name: string;
  email: string;
};

type tempAnnouncement = {
  title: string;
  lastMessageMs: number;
  emailMsg: boolean;
  textMsg: boolean;
  appMsg: boolean;
  receiverPeople: ReceiverPerson[];
  receiverGroup: string;
  category: string;
};

export const AnnouncementList: Component<AnnouncementListProps> = (props) => {
  const { t } = useLocalization();
  const [annoucements] = createSignal([
    {
      title: 'Community Gathering This Saturday!',
      receiverGroup: 'Wewelo',
      emailMsg: true,
      textMsg: false,
      appMsg: false,
      lastMessageMs: 1698315182456,
      receiverPeople: [
        {
          name: 'Kelly Shao',
          email: '',
        },
        {
          name: 'Selly Khao',
          email: '',
        },
      ],
      category: 'uncategorized',
    },
    {
      title: 'Keep warm as the weather gets cool',
      receiverGroup: 'Wewelo',
      emailMsg: true,
      textMsg: false,
      appMsg: false,
      lastMessageMs: 1698315182456,
      receiverPeople: [
        {
          name: 'Kelly Shao',
          email: '',
        },
        {
          name: 'Selly Khao',
          email: '',
        },
      ],
      category: 'uncategorized',
    },
    {
      title: 'Community Gathering This Saturday!',
      receiverGroup: 'Wewelo',
      emailMsg: true,
      textMsg: false,
      appMsg: false,
      lastMessageMs: 1698315182456,
      receiverPeople: [
        {
          name: 'Kelly Shao',
          email: '',
        },
        {
          name: 'Selly Khao',
          email: '',
        },
      ],
      category: 'uncategorized',
    },
    {
      title: 'Keep warm as the weather gets cool',
      receiverGroup: 'Wewelo',
      emailMsg: true,
      textMsg: false,
      appMsg: false,
      lastMessageMs: 1698315182456,
      receiverPeople: [
        {
          name: 'Kelly Shao',
          email: '',
        },
        {
          name: 'Selly Khao',
          email: '',
        },
      ],
      category: 'uncategorized',
    },
  ] as tempAnnouncement[]);

  const columns = createMemo<TableColumns<tempAnnouncement>>(() => [
    {
      title: t('Subject'),
      render: (item) => (
        <div class="flex items-center  text-sm">
          <div class="flex size-10 items-center justify-center rounded-full bg-light-pink text-essential-colour">
            <IconUncategorized />
          </div>

          <div class="flex flex-col">
            <span class="font-medium">{item.title}</span>
            <span class="text-xs text-text-level03"> {item.category}</span>
          </div>
        </div>
      ),
    },

    {
      title: t('To'),
      render: (item) => (
        <div class="flex flex-col">
          <span class="flex items-center gap-1 text-sm font-medium">
            <IconPortfolio class="text-text-level03" />
            {item.receiverGroup}
          </span>
          <span class="flex items-center gap-1 text-xs text-text-level03">
            <IconUser />
            {item.receiverPeople.length} People
          </span>
        </div>
      ),
    },
    {
      title: t('Through'),
      render: (item) => (
        <div class="flex gap-2">
          <Show when={item.appMsg} fallback={<IconAppMsg class="text-auxiliary-text" />}>
            <IconAppMsg class="text-green" />
          </Show>
          <Show when={item.emailMsg} fallback={<IconEmailMsg class="text-auxiliary-text" />}>
            <IconEmailMsg class="text-green" />
          </Show>
          <Show when={item.textMsg} fallback={<IconTextMsg class="text-auxiliary-text" />}>
            <IconTextMsg class="text-green" />
          </Show>
        </div>
      ),
    },
    {
      title: t('Last message'),
      render: (item) => <span class="text-xs">{dateFormat('', item.lastMessageMs)}</span>,
    },
  ]);

  return (
    <div class="text-left">
      <Panel title={t('Announcement')} class={cn('relative', props.class)}>
        <Table columns={columns()} data={annoucements()} />
        <UnderConstruction />
      </Panel>
    </div>
  );
};

import { useParams } from '@solidjs/router';
import { createEffect, createSignal } from 'solid-js';
import { LinkButton } from '~/components/common/Buttons';
import { getLeaseDateName, getUnitFullname } from '~/components/leases/utils';
import { PrintTablePageButton } from '~/components/print/PrintTablePageButton';
import { useLocalization, useUnits } from '~/contexts/global';
import { useLease, useLeaseDeposits } from '~/contexts/local';
import { useUpdates } from '~/pages/leases/lease-details//UpdatePagesContext';
import { LeaseDepositTransactionTable } from '~/pages/leases/lease-details/components/lease-deposit-transaction/LeaseDepositTransactionTable';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import { LeaseDepositDetailModal } from './LeaseDepositDetailModal';

interface LeaseDepositsTableProps {
  lease: MagicDoor.Api.HydratedLeaseDto;
}

export const LeaseDepositsTable = (props: LeaseDepositsTableProps) => {
  const { t } = useLocalization();
  const { updateSignal } = useUpdates();

  const params = useParams();
  const { lease } = useLease();
  const { unit } = useUnits();
  const { leaseDesposit, deposit, depositActions } = useLeaseDeposits();
  const [status, setStatus] = createSignal<string>('');
  const [showModal, setShowModal] = createSignal<string>('');
  const [depositData, setDepositData] = createSignal<MagicDoor.Api.HydratedLeaseTransactionDto | undefined>(undefined);

  const handleDetailClick = (trans: MagicDoor.Api.HydratedLeaseTransactionDto) => {
    setDepositData(trans);
    setShowModal('detail');
  };

  let leaseDepositRef: any;

  const getTotal = () => {
    const data: MagicDoor.Api.HydratedLeaseTransactionDto[] = leaseDepositRef.getData();
    const total = currency(data.reduce((previous, current) => previous + current.amount, 0));
    return total;
  };

  const PanelActions = () => (
    <>
      <LinkButton
        class="ml-auto whitespace-nowrap text-sm"
        disabled={deposit.loading}
        href={`/leasing/leases/${params.leaseId}/deposits/release-deposit`}
        noScroll>
        {t('Release deposit')}
      </LinkButton>
      <LinkButton
        class="ml-auto whitespace-nowrap"
        disabled={deposit.loading}
        variant="outlined"
        href={`/leasing/leases/${params.leaseId}/deposits/receive-payment`}
        noScroll>
        {t('Receive payment')}
      </LinkButton>
      <LinkButton
        class="ml-auto whitespace-nowrap text-sm"
        disabled={deposit.loading}
        href={`/leasing/leases/${params.leaseId}/deposits/add-deposit`}
        noScroll>
        {t('Add deposit')}
      </LinkButton>
      <PrintTablePageButton
        table={{
          columns: leaseDepositRef.getColumns(),
          data: leaseDepositRef.getData(),
        }}
        caption={{
          title: 'Deposits',
          extraData: [
            {
              label: 'Lease',
              value: getLeaseDateName(lease()!),
            },
            {
              label: 'Unit',
              value: getUnitFullname(unit()),
            },
            {
              label: 'Total',
              value: getTotal(),
            },
            {
              label: 'Date',
              value: dateFormat(),
            },
          ],
        }}
      />
    </>
  );

  createEffect(() => {
    updateSignal();
    depositActions.refetch();
  });

  return (
    <LeaseDepositTransactionTable
      ref={leaseDepositRef}
      data={leaseDesposit()}
      actions={depositActions.refetch()}
      loading={deposit.loading}
      status={status()}
      setStatus={setStatus}
      onDetailClick={handleDetailClick}
      onDeleteClick={(trans) => {
        setDepositData(trans);
        setShowModal('deleteTransaction');
      }}
      PanelActions={<PanelActions />}
      showModal={showModal()}
      setShowModal={setShowModal}
      transactionData={depositData()}
      tableTitle={t('Deposits')}
      detailModal={
        <LeaseDepositDetailModal
          transactionDetail={depositData() as MagicDoor.Api.HydratedLeaseTransactionDto}
          currentLease={props.lease}
          visible={showModal() === 'detail'}
          onCancel={() => setShowModal('')}
        />
      }
    />
  );
};

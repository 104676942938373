import { Show } from 'solid-js';
import IconWarning from '~/assets/images/common/warning.svg?component-solid';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

type BalancesProps = {
  class?: string;
  lease?: MagicDoor.Api.HydratedLeaseDto;
};

export const LeaseNotice: Component<BalancesProps> = (props) => {
  const { t } = useLocalization();

  return (
    <Show when={props.lease?.eviction && !props.lease?.ended}>
      <div
        class={cn('flex items-center gap-6 rounded-xl bg-danger-light p-4 text-sm text-danger', props.class)}
        style={{ 'box-shadow': '0px 6px 24px 0px rgba(0, 0, 0, 0.12)' }}>
        <div class="size-5">
          <IconWarning />
        </div>
        <div class="text-wrap">
          <span class="font-medium">{`${props.lease?.unit.name}`}&nbsp</span>
          {t('Is under the process of eviction, all online payments to this lease is blocked.')}
        </div>
      </div>
    </Show>
  );
};

import { createMemo } from 'solid-js';
import { PieChart } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import Card from '~/pages/dashboard/components/Card';
import type { Component } from 'solid-js';

type ExpiringLeasesProps = {
  class?: string;
};

const ListingPlans: Component<ExpiringLeasesProps> = (props) => {
  const { t } = useLocalization();

  const expiredLeases = createMemo(() => [
    {
      name: 'free plan listing ',
      value: 3,
      color: '#FF545D',
    },
    {
      name: 'paid plan listing ',
      value: 4,
      color: '#FA8511',
    },
    {
      name: 'premium listing',
      value: 3,
      color: '#36CBAC',
    },
  ]);

  return (
    <Card class={props.class} title={t('Expiring leases')} contentClass="h-[174px]">
      <div class="relative flex size-full items-center justify-center">
        <div class="flex w-full items-center justify-between px-4 xs:px-9">
          <div class="w-[99px] xs:w-[156px]">
            <PieChart data={expiredLeases()} />
          </div>

          <div class="ml-8 w-48">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <span class="size-3 rounded bg-[#FF545D]" />
                <span class="ml-2 text-xs text-text-level03">{expiredLeases()[0].name}</span>
              </div>
              <span class="text-xs font-semibold text-text-level01">
                {expiredLeases()[0].value} {t('Units')}
              </span>
            </div>
            <div class="my-5 flex items-center justify-between">
              <div class="flex items-center">
                <span class="size-3 rounded bg-warning" />
                <span class="ml-2 text-xs text-text-level03">{expiredLeases()[1].name}</span>
              </div>
              <span class="text-xs font-semibold text-text-level01">
                {expiredLeases()[1].value} {t('Units')}
              </span>
            </div>
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <span class="size-3 rounded bg-success" />
                <span class="ml-2 text-xs text-text-level03">{expiredLeases()[2].name}</span>
              </div>
              <span class="text-xs font-semibold text-text-level01">
                {expiredLeases()[2].value} {t('Units')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ListingPlans;

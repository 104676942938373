import dayjs from 'dayjs';
import { Dynamic } from 'solid-js/web';
import { Button } from '~/components/common/Buttons';
import { propertyTypes } from '~/components/properties/PropertyType';
import { Image } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

type PropertyDistributionProps = {
  class?: string;
  listing?: any;
};

const ListingCard: Component<PropertyDistributionProps> = (props) => {
  const { t } = useLocalization();

  return (
    <div class={cn('overflow-hidden rounded-lg bg-[#F6F6F6] shadow-lg', props.class)}>
      <div class="bg-white p-2">
        <Image class="h-[143px] w-full rounded-lg" src={props.listing.image} />
        <div class="mt-4 flex items-center justify-between gap-2 text-lg font-semibold text-text-level01">
          <span>{props.listing.name}</span>
          <div class="flex items-center gap-1 rounded-full bg-[#8712CF] px-3 py-1 text-xs text-white">
            <Dynamic component={propertyTypes[props.listing.type as MagicDoor.Api.PropertyType].icon} class="size-4" />
            {propertyTypes[props.listing.type as MagicDoor.Api.PropertyType].name}
          </div>
        </div>
        <div class="text-xs font-medium text-text-level03">{props.listing.address}</div>
        <div class="my-3 h-[0.5px] w-full bg-[#BABABA]" />
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <div class="rounded-xl bg-green-level01 px-2 py-1 text-xs text-light-green">{t(props.listing.status)}</div>
            <div class="text-xs text-text-level03">
              {t('Updated')} {dayjs(props.listing.updateDate).format('MMM DD')}
            </div>
          </div>
          <Button href="./111" variant="text" class="text-xs">
            {t('View listing')}
          </Button>
        </div>
      </div>
      <div class="my-3 w-full text-center text-xs text-text-level03">Past 30 days</div>
      <div class="gird mb-4 flex w-full grid-cols-3 px-2 text-center">
        <div class="w-full border-r border-[#BABABA]">
          <div class="text-xs font-medium text-[#6A6A6B]">{t('Views')}</div>
          <div class="mt-1 text-sm font-medium text-black">{props.listing.views}</div>
        </div>
        <div class="w-full border-r border-[#BABABA]">
          <div class="text-xs font-medium text-[#6A6A6B]">{t('Guest cards')}</div>
          <div class="mt-1 text-sm font-medium text-black">{props.listing.guestCards}</div>
        </div>
        <div class="w-full">
          <div class="text-xs font-medium text-[#6A6A6B]">{t('Applications')}</div>
          <div class="mt-1 text-sm font-medium text-black">{props.listing.applications}</div>
        </div>
      </div>
    </div>
  );
};

export default ListingCard;

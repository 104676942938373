import { For } from 'solid-js';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization } from '~/contexts/global';

const VendorWorkOrderSkeleton = () => {
  const { t } = useLocalization();

  const numberOfSkeletons = 3;

  return (
    <ThreeSectionBox
      topTitle={<div>{t('Work order')}</div>}
      topAction={
        <button class="flex items-center gap-1">
          <div>{t('View all')}</div>
          <IconViewArrow class="size-4 text-link" />
        </button>
      }
      middle={
        <div class="flex h-64 flex-col gap-2 px-6 py-4">
          <For each={Array.from({ length: numberOfSkeletons })}>
            {() => <div class="skeleton-shimmer flex h-15 items-center gap-3 rounded-xl" />}
          </For>
        </div>
      }
    />
  );
};

export default VendorWorkOrderSkeleton;

import { For, createSignal } from 'solid-js';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

const MaintenanceRequest: Component = () => {
  return (
    <div class="skeleton-shimmer rounded-lg px-3 py-1.5">
      <div class="h-8" style={{ width: '311px' }} />
    </div>
  );
};

type MaintenanceRequestsTableProps = {
  class?: string;
};

export const MaintenanceRequestsTableSkeleton: Component<MaintenanceRequestsTableProps> = (props) => {
  const { t } = useLocalization();
  const [tab, setTab] = createSignal<'incoming' | 'mine'>('incoming');

  const requests = [1, 2, 3, 4];

  return (
    <Panel title={t('Maintenance requests')} class={props.class}>
      <div class="relative flex border-b border-partingline py-2 text-center text-sm text-text-level03">
        <div
          class="flex-1 cursor-pointer hover:text-text-level02"
          classList={{ 'font-medium text-text-level01': tab() === 'incoming' }}
          onClick={() => setTab('incoming')}>
          {t('Incoming requests')}
        </div>
        <div
          class="flex-1 cursor-pointer hover:text-text-level02"
          classList={{ 'font-medium text-text-level01': tab() === 'mine' }}
          onClick={() => setTab('mine')}>
          {t('Assigned to me')}
        </div>
        <div
          class="absolute bottom-0 h-0.5 w-20 -translate-x-1/2 rounded-t-sm bg-link"
          classList={{ 'left-1/4': tab() === 'incoming', 'left-3/4': tab() === 'mine' }}
        />
      </div>
      <div class="flex flex-col space-y-2 px-6 py-4">
        <For each={requests}>{() => <MaintenanceRequest />}</For>
      </div>
    </Panel>
  );
};

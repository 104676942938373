import { BaseRestRepository } from '~/repositories/baseRestRepository';

const BASE_URL = '/api/settings/rent-payment-failure-fee';

export class rentPaymentFailureFeeSettingsRepository extends BaseRestRepository {
  public getRentPaymentFailureFeeSettings = async (): Promise<MagicDoor.Api.RentPaymentFailureFeeSettingsDto> => {
    const url = BASE_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  };

  public deleteRentPaymentFailureFeeSettings = async () => {
    const url = BASE_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  };

  public updateRentPaymentFailureFeeSettings = async (
    payload: MagicDoor.Api.RentPaymentFailureFeeSettingsDto
  ): Promise<MagicDoor.Api.RentPaymentFailureFeeSettingsDto> => {
    const url = BASE_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  };

  public async addRentPaymentFailureFeeSettingsByLevel(
    level: string,
    levelId: string,
    payload: MagicDoor.Api.RentPaymentFailureFeeSettingsDto
  ) {
    const url = `${BASE_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async deleteRentPaymentFailureFeeSettingsByLevel(level: string, levelId: string) {
    const url = `${BASE_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }

  public async getAllRentPaymentFailureFeeSettings(): Promise<MagicDoor.Api.RentPaymentFailureFeeSettingsDtoSettingsWithOverridesDto> {
    const url = `${BASE_URL}/all`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
}

import { Route, Routes, useParams } from '@solidjs/router';
import { EmailDetailsModal } from '~/components/emails/EmailModal';
import { EmailsListWithType } from '~/components/emails/EmailsListWithType';

export const LeaseEmailsTab = () => {
  const params = useParams<{ leaseId: string }>();

  return (
    <>
      <EmailsListWithType id={params.leaseId} type="lease" />
      <Routes>
        <Route path="/:emailId" element={<EmailDetailsModal />} />
      </Routes>
    </>
  );
};

import { useNavigate, useParams } from '@solidjs/router';
import IconDeleteWarning from '~/assets/images/confirm-modal/delete.svg?component-solid';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLeaseTemplateCategory } from '~/contexts/local';
import type { Component } from 'solid-js';

export const DeleteLeaseTemplateCategoryModal: Component = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { categoryId } = useParams();

  const { deleteLeaseTemplateCategory } = useLeaseTemplateCategory();

  const handleClose = () => {
    navigate(`/leasing/lease-templates`, { scroll: false, replace: true });
  };

  const handleSubmit = async () => {
    await deleteLeaseTemplateCategory(categoryId);
    toast.success(t('The lease template has been deleted successfully'));
    navigate('/leasing/lease-templates', { replace: true });
  };

  return (
    <Modal
      visible
      class="animate-zoom-in"
      loading={deleteLeaseTemplateCategory.loading}
      title={'Confirm delete'}
      doneText={t('Confirm delete')}
      doneColor="warning"
      confirmation={false}
      onDone={handleSubmit}
      onCancel={handleClose}>
      <div class="bg-danger-light">
        <IconDeleteWarning class="mx-auto" />
      </div>
      <div class="px-6 py-5 text-sm text-text-level02 [&>b]:mx-1 [&>b]:font-bold [&>b]:text-essential-colour">
        {t('Please confirm that you would like to delete this template')}
      </div>
    </Modal>
  );
};

import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { FileRepository } from '~/repositories/fileRepository';
import type { Accessor } from 'solid-js';

interface FilesListContextValue {
  attachments: Accessor<MagicDoor.Api.HydratedFileAttachmentDto[]>;
  loading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
  addAttachment: (file: File, description?: string) => Promise<MagicDoor.Api.HydratedFileAttachmentDto | undefined>;
  getAttachments: () => Promise<void>;
  getFile: (fileId: string, fileName: string | null, ext: string | null) => string | undefined;
  deleteAttachment: (fileAttachmentId: string) => Promise<void>;
}

type FilesListProviderProps = {
  entityType: MagicDoor.Api.EntityType;
  entityId: string;
};

export const [FilesListProvider, useFilesList] = createMagicDoorContext<FilesListContextValue, FilesListProviderProps>(
  'FilesList',
  (props) => {
    const repo = new FileRepository();
    const [attachments, setAttachments] = createSignal<MagicDoor.Api.HydratedFileAttachmentDto[]>([]);
    const [loading, setLoading] = createSignal<boolean>(false);
    const [error, setError] = createSignal<Error | undefined>(undefined);

    const getFile = (fileId: string, fileName: string | null, ext: string | null) => {
      try {
        return repo.getFile(fileId, fileName || 'File', ext || 'txt');
      } catch (err: any) {
        setError(err);
        return undefined;
      }
    };

    const getAttachments = async () => {
      setLoading(true);
      try {
        const response = await repo.getAttachments(props.entityType, props.entityId);
        setAttachments(response);
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    const addAttachment = async (file: File, description?: string) => {
      try {
        const response = await repo.addAttachment(props.entityType, props.entityId, file, description);
        setAttachments((prev) => [response, ...prev]);
        return response;
      } catch (err: any) {
        setError(err);
        throw new Error(err);
      } finally {
        setLoading(false);
      }
    };

    const deleteAttachment = async (fileAttachmentId: string) => {
      try {
        await repo.deleteAttachment(props.entityType, props.entityId, fileAttachmentId);
        setAttachments((prev) => prev.filter((file) => file.id !== fileAttachmentId));
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    // onMount(() => {
    //   getAttachments();
    // });

    return {
      attachments,
      loading,
      error,
      addAttachment,
      getAttachments,
      getFile,
      deleteAttachment,
    };
  }
);

import { createMemo } from 'solid-js';
import deleteLeaseBg from '~/assets/images/units/deleteLeaseBg.png';
import { Button } from '~/components/common/Buttons';
import { Modal } from '~/components/modals/Modal';
import { Html } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import type { JSX } from 'solid-js';

interface ArchiveChatModalProps {
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  chatSubject?: string;
  modalLoading: boolean;
}

export const ArchiveChatModal = (props: ArchiveChatModalProps) => {
  const { t } = useLocalization();

  const footer = createMemo<JSX.Element>(() => (
    <div class="flex justify-end gap-2 border-t border-partingline p-6">
      <Button class="border-danger text-danger" variant="outlined" type="button" onClick={props.onCancel}>
        {t('Cancel')}
      </Button>
      <Button class="border-danger bg-danger hover:bg-danger/90" loading={props.modalLoading} type="button" onClick={props.onConfirm}>
        {t('Archive')}
      </Button>
    </div>
  ));

  return (
    <Modal
      size="sm"
      onCancel={props.onCancel}
      footer={footer()}
      visible={props.visible}
      title={<div class="text-error">{t('Archive chat')}</div>}
      confirmation={false}>
      <div>
        <img class="size-full h-24" src={deleteLeaseBg} alt="ArchiveChat" />
        <Html as="div" class="px-6 py-5 text-sm text-text-level02 [&>b]:mx-1 [&>b]:font-bold [&>b]:text-essential-colour">
          {t('By archiving <b>“{name}”</b>, you will no longer see it in your active chats. Are you sure you want to archive it?', {
            name: props.chatSubject || '',
          })}
        </Html>
      </div>
    </Modal>
  );
};

import { Show } from 'solid-js';
import { Select } from '~/components/ui';
import { useTranslations } from '~/contexts/global';
import type { Component } from 'solid-js';

export const LanguageSelect: Component<{
  label?: string;
  class?: string;
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  labelClass?: string;
}> = (props) => {
  const { languages, userDefaultLanguage } = useTranslations();

  const options = async () => {
    if (languages.loading) return new Promise<any>(() => void 0);
    return languages()?.map((item) => ({ label: item.name || '', value: item.code || '' }));
  };

  return (
    <div>
      <Show when={props.label}>
        <label class={`mb-2 block text-sm uppercase text-label ${props.labelClass}`}>{props.label}</label>
      </Show>
      <Select
        class={props.class}
        placeholder={props.placeholder}
        options={options}
        value={props.value || userDefaultLanguage()?.code}
        onChange={props.onChange}
        filterable
      />
    </div>
  );
};

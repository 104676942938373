import { BaseRestRepository } from '~/repositories/baseRestRepository';

const MAINTENANCE_REQUEST_SETTINGS_URL = '/api/settings/maintenance-request';

export class maintenanceRequestSettingsRepository extends BaseRestRepository {
  public async addMaintenanceRequestSettings(payload: MagicDoor.Api.MaintenanceRequestSettingsDto) {
    const url = MAINTENANCE_REQUEST_SETTINGS_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async addMaintenanceRequestSettingsByLevel(level: string, levelId: string, payload: MagicDoor.Api.MaintenanceRequestSettingsDto) {
    const url = `${MAINTENANCE_REQUEST_SETTINGS_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getMaintenanceRequestSettings(): Promise<MagicDoor.Api.MaintenanceRequestSettingsDto> {
    const url = MAINTENANCE_REQUEST_SETTINGS_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getMaintenanceRequestSettingsByLevel(level: string, levelId: string) {
    const url = `${MAINTENANCE_REQUEST_SETTINGS_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getAllMaintenanceRequestSettings(): Promise<MagicDoor.Api.MaintenanceRequestSettingsDtoSettingsWithOverridesDto> {
    const url = `${MAINTENANCE_REQUEST_SETTINGS_URL}/all`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async deleteMaintenanceRequestSettingsByLevel(level: string, levelId: string) {
    const url = `${MAINTENANCE_REQUEST_SETTINGS_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }
}

import { useNavigate } from '@solidjs/router';
import { createMemo, createSignal, createEffect, Show } from 'solid-js';
import DetailIcon from '~/assets/images/common/detail.svg?component-solid';
import PlusIcon from '~/assets/images/common/plus.svg?component-solid';
import IconCall from '~/assets/images/contact/call.svg?component-solid';
import IconEmail from '~/assets/images/contact/email.svg?component-solid';
import PencilIcon from '~/assets/images/units/lease/editLease.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import { Panel } from '~/components/common/Panels';
import { TenantTitle } from '~/components/tenants';
import { Table, TableActions } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLease } from '~/contexts/local';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type TenantsTableProps = {
  class?: string;
  tenants: MagicDoor.Api.HydratedLeaseTenantDto[];
  moveoutTenant: (tenant: any) => Promise<void>;
  leaseId: string | undefined;
};

export const TenantsTable: Component<TenantsTableProps> = (props: TenantsTableProps) => {
  const { t } = useLocalization();
  const { lease } = useLease();

  const [currentTenantForMoveOut, setCurrentTenantForMoveOut] = createSignal<MagicDoor.Api.HydratedLeaseTenantDto[]>([]);
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = createSignal();

  createEffect(() => {
    if (lease()?.ended === true) {
      setFilteredData([]);
    } else {
      setFilteredData(props.tenants.filter((item: MagicDoor.Api.HydratedLeaseTenantDto) => item.isMovedOut === false));
    }
    setCurrentTenantForMoveOut(filteredData() as MagicDoor.Api.HydratedLeaseTenantDto[]);
  });

  const columns = createMemo<TableColumns<MagicDoor.Api.HydratedLeaseTenantDto>>(() => [
    {
      title: t('Name'),
      render: (item) => <TenantTitle tenant={item.tenant} />,
    },
    {
      title: t('Contact'),
      render: (item) => (
        <div class="space-y-1 text-xs">
          <Show when={item.tenant?.phone}>
            <div class="flex items-center space-x-1">
              <IconCall class="text-text-level03" />
              <span>{item.tenant?.phone}</span>
            </div>
          </Show>
          <Show when={item.tenant?.email}>
            <div class="flex items-center space-x-1">
              <IconEmail class="text-text-level03" />
              <span class="normal-case">{item.tenant?.email}</span>
            </div>
          </Show>
        </div>
      ),
    },
    {
      title: '',
      class: 'relative',
      render: (item) => (
        <div class="flex justify-end">
          <TableActions
            actions={[
              {
                label: t('Detail'),
                icon: DetailIcon,
                href: `/users/tenants/${item.tenant?.id}`,
              },
              {
                label: t('Move out'),
                icon: PencilIcon,
                onClick: () => {
                  props.moveoutTenant(item.tenant);
                  navigate(`/leasing/leases/${props.leaseId}/remove-tenant`);
                },
              },
            ]}
          />
        </div>
      ),
    },
  ]);

  return (
    <Panel
      title={t('Tenants')}
      class={props.class}
      actions={
        <LinkButton noScroll href={`/leasing/leases/${lease()?.id}/add-tenant`} class="ml-auto text-sm">
          <PlusIcon /> {t('Add a tenant')}
        </LinkButton>
      }>
      <Table columns={columns()} data={currentTenantForMoveOut()} rowLink={(item) => `/users/tenants/${item.tenant.id}`} />
    </Panel>
  );
};

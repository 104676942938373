import { createSignal, For, Show, createMemo, createEffect } from 'solid-js';
import { createStore } from 'solid-js/store';

interface BarChartProps {
  data: any[];
  xKey: string;
  yKeys: string[];
  colors: string[];
  width: number;
  height: number;
  visibleBars: number;
  maxValue: number;
  formatYAxis: (value: number) => string;
  formatTooltip: (value: number) => string;
}

export const BarChart = (props: BarChartProps) => {
  const { width = 600, height = 400, visibleBars = 6 } = props;

  const [tooltip, setTooltip] = createStore({
    visible: false,
    x: 0,
    y: 0,
    data: null,
    key: '',
  });

  const [startIndex, setStartIndex] = createSignal(0);

  createEffect(() => {
    const lastValidIndex = props.data.findLastIndex((d) => props.yKeys.some((key) => d[key] !== null && d[key] !== undefined));
    setStartIndex(Math.max(0, Math.min(lastValidIndex, props.data.length - visibleBars)));
  });

  const chartDimensions = createMemo(() => ({
    margin: { top: 10, right: 40, bottom: 30, left: 60 },
    chartWidth: width - 100,
    chartHeight: height - 40,
  }));

  const scales = createMemo(() => ({
    yScale: (value: number) => chartDimensions().chartHeight - (value / props.maxValue) * chartDimensions().chartHeight,
    barWidth: (chartDimensions().chartWidth / visibleBars) * 0.35,
    barSpacing:
      (chartDimensions().chartWidth - (chartDimensions().chartWidth / visibleBars) * 0.35 * props.yKeys.length * visibleBars) / visibleBars,
  }));

  const tooltipWidth = 180;
  const tooltipHeight = 40 + props.yKeys.length * 25;

  const showTooltip = (d: any, key: string, e: any) => {
    const svgRect = e.currentTarget.ownerSVGElement.getBoundingClientRect();
    const barRect = e.currentTarget.getBoundingClientRect();

    let x = barRect.left + barRect.width / 2 - svgRect.left;
    let y = barRect.top - svgRect.top - 10; // 10px above the bar

    // Check if tooltip exceeds right boundary
    if (x + tooltipWidth / 2 > width - 60) {
      x = x - tooltipWidth; // Move tooltip to the left
    }

    // Ensure the tooltip stays within the chart boundaries
    x = Math.max(tooltipWidth / 2, Math.min(x, width - tooltipWidth / 2));
    y = Math.max(tooltipHeight, y);

    setTooltip({
      visible: true,
      x,
      y,
      data: d,
      key,
    });
  };

  const hideTooltip = () => {
    setTooltip('visible', false);
  };

  const canScrollLeft = () => startIndex() > 0;
  const canScrollRight = () => startIndex() + visibleBars < props.data.length;

  const scrollLeft = () => {
    if (canScrollLeft()) {
      setStartIndex(Math.max(0, startIndex() - 1));
    }
  };

  const scrollRight = () => {
    if (canScrollRight()) {
      setStartIndex(Math.min(props.data.length - visibleBars, startIndex() + 1));
    }
  };

  const renderBars = () => (
    <For each={props.data.slice(startIndex(), startIndex() + visibleBars)}>
      {(d, i) => (
        <>
          <For each={props.yKeys}>
            {(key, keyIndex) => (
              <rect
                x={i() * (scales().barWidth * props.yKeys.length + scales().barSpacing) + keyIndex() * scales().barWidth}
                y={scales().yScale(d[key])}
                width={scales().barWidth}
                height={chartDimensions().chartHeight - scales().yScale(d[key])}
                fill={`url(#gradient-${key})`}
                rx="5"
                ry="5"
                onMouseEnter={(e) => showTooltip(d, key, e)}
                onMouseLeave={hideTooltip}
              />
            )}
          </For>
          <text
            x={i() * (scales().barWidth * props.yKeys.length + scales().barSpacing) + (scales().barWidth * props.yKeys.length) / 2}
            y={chartDimensions().chartHeight + 20}
            text-anchor="middle"
            font-size="12"
            fill="#888">
            {d[props.xKey]}
          </text>
        </>
      )}
    </For>
  );

  return (
    <div class={`w-[${width}px] relative mx-auto max-w-full overflow-hidden rounded-3xl bg-white`}>
      <svg width={width} height={height}>
        <defs>
          <For each={props.yKeys}>
            {(key, index) => (
              <linearGradient id={`gradient-${key}`} x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stop-color={props.colors[index()]} />
                <stop offset="100%" stop-color={`${props.colors[index()]}99`} />
              </linearGradient>
            )}
          </For>

          <filter id="dropShadow" height="130%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
            <feOffset dx="2" dy="2" result="offsetblur" />
            <feComponentTransfer>
              <feFuncA type="linear" slope="0.2" />
            </feComponentTransfer>
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>

        <rect
          x={chartDimensions().margin.left}
          y={chartDimensions().margin.top}
          width={chartDimensions().chartWidth}
          height={chartDimensions().chartHeight}
          fill="#FAFAFA"
        />

        <g transform={`translate(${chartDimensions().margin.left}, ${chartDimensions().margin.top})`}>
          <g filter="url(#dropShadow)">
            <line x1="0" y1="0" x2="0" y2={chartDimensions().chartHeight} stroke="#E0E0E0" stroke-width="2" />
            <For each={[0, props.maxValue * 0.25, props.maxValue * 0.5, props.maxValue * 0.75, props.maxValue]}>
              {(tick) => (
                <g transform={`translate(0, ${scales().yScale(tick)})`}>
                  <line x1="-5" y1="0" x2={chartDimensions().chartWidth} y2="0" stroke="#E0E0E0" stroke-dasharray="5,5" />
                  <text x="-10" y="5" text-anchor="end" font-size="12" fill="#888">
                    {props.formatYAxis(tick)}
                  </text>
                </g>
              )}
            </For>
          </g>

          {renderBars()}

          <Show when={tooltip.visible}>
            <g
              transform={`translate(${tooltip.x}, ${tooltip.y})`}
              style={{
                transition: 'opacity 0.2s ease-in-out',
                opacity: tooltip.visible ? 1 : 0,
                'pointer-events': 'none',
              }}>
              <rect
                x={-tooltipWidth / 2}
                y={-tooltipHeight}
                width={tooltipWidth}
                height={tooltipHeight}
                fill="white"
                stroke="#E0E0E0"
                rx="12"
                ry="12"
                filter="url(#dropShadow)"
              />
              <text x="0" y={-tooltipHeight + 20} text-anchor="middle" font-size="14" fill="#333" font-weight="600">
                {tooltip.data?.[props.xKey]}
              </text>
              <For each={props.yKeys}>
                {(key, index) => (
                  <g transform={`translate(0, ${-tooltipHeight + 45 + index() * 25})`}>
                    <circle cx="-75" cy="-5" r="4" fill={props.colors[index()]} />
                    <text x="-65" y="0" text-anchor="start" font-size="12" fill="#666" font-weight="500">
                      {key}:
                    </text>
                    <text x="75" y="0" text-anchor="end" font-size="12" fill="#333" font-weight="600">
                      {props.formatTooltip(tooltip?.data?.[key])}
                    </text>
                  </g>
                )}
              </For>
            </g>
          </Show>
        </g>
      </svg>

      <div class="mt-2.5 flex justify-between">
        <div
          onClick={scrollLeft}
          class={`flex size-8 items-center justify-center rounded-full border ${
            canScrollLeft()
              ? 'cursor-pointer border-gray-200 bg-white text-gray-600 hover:bg-gray-100'
              : 'cursor-default border-gray-100 bg-gray-50 text-gray-300'
          } transition-colors duration-200`}>
          <svg xmlns="http://www.w3.org/2000/svg" class="size-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </div>

        <div class="flex items-center justify-center gap-2.5">
          <For each={props.yKeys}>
            {(key, index) => (
              <div class="flex items-center text-xs">
                <div class="mr-1.5 size-3 rounded" style={{ 'background-color': props.colors[index()] }} />
                {key}
              </div>
            )}
          </For>
        </div>

        <div
          onClick={scrollRight}
          class={`flex size-8 items-center justify-center rounded-full border ${
            canScrollRight()
              ? 'cursor-pointer border-gray-200 bg-white text-gray-600 hover:bg-gray-100'
              : 'cursor-default border-gray-100 bg-gray-50 text-gray-300'
          } transition-colors duration-200`}>
          <svg xmlns="http://www.w3.org/2000/svg" class="size-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </div>
      </div>
    </div>
  );
};

import { createMemo, createSignal } from 'solid-js';
import { usePlaid } from '~/contexts/local';

export const usePlaidUI = (bankAccount?: MagicDoor.Api.HydratedBankAccountDto) => {
  const { linkPlaidWithAccount, plaidUrl, getPlaidQuestionnaireUrl, isPlaidLoading } = usePlaid();
  const [isPlaidLinking, setIsPlaidLinking] = createSignal<boolean>(false);

  const isPlaidConnected = createMemo(() => !!bankAccount?.plaid?.hasOriginator);
  const shouldPerformQuestionnaire = createMemo(() => !!bankAccount?.plaid?.shouldPerformQuestionnaire);
  const notSubmittedOriginator = createMemo(() => bankAccount?.plaid?.originatorStatus === 'notSubmitted');
  const pendingOriginator = createMemo(
    () => bankAccount?.plaid?.originatorStatus === 'submitted' || bankAccount?.plaid?.originatorStatus === 'underReview'
  );
  const approvedOriginator = createMemo(() => bankAccount?.plaid?.originatorStatus === 'approved');

  const getPlaidStatusBgColor = (status: MagicDoor.Api.DiligenceStatus | undefined) => {
    switch (status) {
      case 'notSubmitted':
        return 'bg-essential-colour/20';
      case 'submitted':
        return 'bg-[#FF8618]/20';
      case 'underReview':
        return 'bg-[#FF8618]/20';
      case 'approved':
        return 'bg-[#28D9A5]/20';
      default:
        return 'bg-gray-200';
    }
  };

  const openPlaidLink = async (bankAccountId: string | undefined) => {
    if (bankAccountId === undefined) return;
    setIsPlaidLinking(true);

    await getPlaidQuestionnaireUrl(bankAccountId).finally(() => {
      setIsPlaidLinking(false);
    });

    const url = plaidUrl();
    if (url) {
      window.open(url, '_blank');
    }
  };

  const linkPlaidWithAccountAndRefresh = async (bankAccountId: string | undefined, reloadFunction: () => Promise<void>) => {
    if (bankAccountId === undefined) return;
    setIsPlaidLinking(true);
    await linkPlaidWithAccount(bankAccountId)
      .then(async () => {
        await reloadFunction();
      })
      .finally(() => setIsPlaidLinking(false));
    setIsPlaidLinking(false);
  };

  return {
    isPlaidConnected,
    shouldPerformQuestionnaire,
    notSubmittedOriginator,
    pendingOriginator,
    approvedOriginator,
    getPlaidStatusBgColor,
    openPlaidLink,
    linkPlaidWithAccountAndRefresh,
    isPlaidLoading,
    isPlaidLinking,
    linkPlaidWithAccount,
  };
};

import { useNavigate } from '@solidjs/router';
import { createSignal, Show } from 'solid-js';
import { handlePrint } from '~/components/checks/PrintCheck';
import { PrintCheckModal } from '~/components/checks/PrintResultModal';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Modal } from '~/components/modals/Modal';
import { LabeledOwnerSearch } from '~/components/owner';
import { LabeledPropertySearch } from '~/components/properties';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions } from '~/contexts/local';
import { createMutation } from '~/utils/resource';
import type { HydratedCheckDto } from '~/swagger/Api';

export const AddDistributionModal = () => {
  const { t } = useLocalization();
  const form = useForm();
  const navigate = useNavigate();
  const { paymentMethodOptions, paymentTypeOptions, onSubmitOwnerDistribution } = useAddOwnerDistributions();
  const [check, setCheck] = createSignal<HydratedCheckDto>();

  const submit = createMutation(async () => {
    const { validateStatus } = form.validateForm();
    if (validateStatus) {
      const res = await onSubmitOwnerDistribution(form.formStore as unknown as MagicDoor.Api.CreateOwnerDistributionDto);
      const check = res.printCheckResults?.at(0)?.check;
      if (check) {
        setCheck(check);
      } else {
        navigate(-1);
      }
    }
  });

  function onDone() {
    handlePrint(check() as HydratedCheckDto);
    navigate(-1);
  }

  return (
    <Show when={!check()} fallback={<PrintCheckModal check={check()} onDone={onDone} />}>
      <Modal
        visible
        onDone={submit}
        doneText={t('Confirm')}
        class="lg:w-modalSm"
        title={t('Add distribution')}
        loading={submit.loading}
        onCancel={() => {
          navigate(-1);
        }}>
        <Form class="grid grid-cols-1 gap-6 p-5 lg:grid-cols-2" defaultForm={form}>
          <FormItem
            component={LabeledPropertySearch}
            label={t('Property')}
            placeholder={t('Please select')}
            formFieldName="propertyId"
            rules={[{ required: true, message: t('Property is required') }]}
            class="col-span-1"
          />

          <FormItem
            filter={{
              propertyIds: [form?.formStore.propertyId],
            }}
            component={LabeledOwnerSearch}
            label={t('Select owner')}
            placeholder={t('Please select')}
            formFieldName="ownerId"
            rules={[{ required: true, message: t('Owner is required') }]}
            class="col-span-1"
          />

          <FormItem
            placeholder={t('Please enter')}
            label={t(`Distribution amount`)}
            formFieldName="distributionAmount"
            prepend="$"
            component={LabeledNumberInput}
            rules={[{ message: t(`Please enter`), required: true }]}
            class="col-span-1"
          />

          <FormItem
            formFieldName="paymentType"
            label={t('Payment type')}
            options={paymentTypeOptions}
            onChangeMethodName="onChange"
            component={DropdownMenu}
            placeholder={t('Please select')}
            class="col-span-1"
          />

          <Show when={form.formStore.paymentType === 'manual'}>
            <FormItem
              formFieldName="paymentMethod"
              label={t('Payment method')}
              options={paymentMethodOptions}
              onChangeMethodName="onChange"
              component={DropdownMenu}
              placeholder={t('Please select')}
              class="col-span-1"
            />

            <FormItem
              label={t('External transaction ID')}
              placeholder={t('Enter transaction ID')}
              component={LabeledTextInput}
              rules={[{ required: true, message: t('Please enter') }]}
              formFieldName="externalTransactionId"
              class="col-span-1"
            />
          </Show>
          <FormItem
            class="col-span-1 lg:col-span-2"
            formFieldName="memo"
            label={t('Memo')}
            component={LabeledTextInput}
            placeholder={t('Please enter')}
          />
        </Form>
      </Modal>
    </Show>
  );
};

import { createSignal, onMount } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { chatsSettingsRepository } from '~/repositories/settings/chatsSettingsRepository';
import { createLazyResource } from '~/utils/resource';
import type { ChatSettingsDto, TextSettingsDtoSettingsWithOverridesDto } from '~/swagger/Api';

export const [ChatsSettingsProvider, useChatsSettings] = createMagicDoorContext('ChatsSettings', () => {
  const repo = new chatsSettingsRepository();
  const [isLoading, setIsLoading] = createSignal<boolean>(false);
  const [error, setError] = createSignal<Error | undefined>(undefined);

  const [chatsSettings, chatsSettingActions] = createLazyResource<ChatSettingsDto>(() => repo.getChatsSettings());

  const [allTextSettings, allTextSettingsActions] = createLazyResource<TextSettingsDtoSettingsWithOverridesDto>(() =>
    repo.getAllTextSettings()
  );

  const updateChatSettings = async (payload: MagicDoor.Api.ChatSettingsDto) => {
    setIsLoading(true);
    try {
      await repo.changeChatSettings(payload);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const updateChatsSettingsByLevel = async (level: string, levelId: string, payload: MagicDoor.Api.ChatSettingsDto): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.updateChatsSettingsByLevel(level, levelId, payload);
      setTimeout(() => {
        chatsSettingActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteChatsSettingsByLevel = async (level: string, levelId: string): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.deleteChatsSettingsByLevel(level, levelId);
      setTimeout(() => {
        chatsSettingActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  // change update delete text setting like chats
  const updateTextSettingsByLevel = async (level: string, levelId: string, payload: MagicDoor.Api.TextSettingsDto): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.updateTextSettingsByLevel(level, levelId, payload);
      setTimeout(() => {
        allTextSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteTextSettingsByLevel = async (level: string, levelId: string): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.deleteTextSettingsByLevel(level, levelId);
      setTimeout(() => {
        allTextSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const updateTextSettings = async (payload: MagicDoor.Api.TextSettingsDto): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.changeTextSettings(payload);
      setTimeout(() => {
        allTextSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  onMount(() => {
    chatsSettingActions.fetch();
    allTextSettingsActions.fetch();
  });

  return {
    isLoading,
    error,
    updateChatSettings,
    chatsSettings,
    updateChatsSettingsByLevel,
    deleteChatsSettingsByLevel,

    updateTextSettings,
    allTextSettings,
    updateTextSettingsByLevel,
    deleteTextSettingsByLevel,
  };
});

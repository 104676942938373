import { Show } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

export const MaintenanceRequestStatus: Component<{
  status: `${MagicDoor.Api.MaintenanceRequestDto['status']}` | `${MagicDoor.Api.HydratedWorkOrderDto['status']}`;
}> = (props) => {
  const { t } = useLocalization();
  const statusConfig = {
    closed: {
      text: 'Closed',
      class: 'bg-gray-level01 text-text-level03',
    },
    pending: {
      text: 'Pending',
      class: 'bg-danger-light text-danger',
    },
    inProgress: {
      text: 'In progress',
      class: 'bg-warning-light text-warning',
    },
  };
  return (
    <Show when={statusConfig[props.status as keyof typeof statusConfig]}>
      <div
        class={cn(
          'max-wc text-nowrap rounded-xl px-2 py-1 text-xs capitalize',
          statusConfig[props.status as keyof typeof statusConfig].class
        )}>
        {t(statusConfig[props.status as keyof typeof statusConfig].text)}
      </div>
    </Show>
  );
};

import { For } from 'solid-js';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import IconSend from '~/assets/images/common/send.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import InputSwitch from '~/components/common/Inputs/InputSwitch';
import { Panel } from '~/components/common/Panels';
import UnderConstruction from '~/components/common/UnderConstruction';
import { useLocalization } from '~/contexts/global';
export const FreeListingSites = () => {
  const { t } = useLocalization();
  const listingSites = [
    {
      title: 'Facebook Marketplace',
      icon: 'https://www.zillow.com/apple-touch-icon.png',
    },
    {
      title: 'Craigslist',
      icon: 'https://www.zillow.com/apple-touch-icon.png',
    },
    {
      title: 'MagicDoor.com',
      icon: 'https://www.zillow.com/apple-touch-icon.png',
    },
  ];

  return (
    <div class=" relative">
      <Panel title={t('Free listing sites')}>
        <div class="px-6">
          <For each={listingSites}>
            {(item) => (
              <div class="flex items-center justify-between border-b py-4">
                <div class="flex items-center gap-4">
                  <div class="relative flex h-12 w-64 items-center justify-between rounded-lg bg-input px-4">
                    <div class="flex items-center gap-3">
                      <img class="size-6" src={item.icon} alt="listing" />
                      <span>{item.title}</span>
                    </div>
                    <IconSend class="size-5 text-essential-colour" />
                  </div>
                </div>
                <div class="flex items-center gap-2">
                  <Button color="link" variant="outlined" class="px-3 py-1 text-sm">
                    <IconEdit class="size-4 text-link" />
                    {t('Edit')}
                  </Button>
                  <InputSwitch checked />
                </div>
              </div>
            )}
          </For>
        </div>
      </Panel>
      <UnderConstruction />
    </div>
  );
};

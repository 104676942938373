import { For, Show } from 'solid-js';
import IconDelete from '~/assets/images/common/deleteRegular.svg?component-solid';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
interface QuestionsProps {
  value: string[];
  onInput: (value: string[]) => void;
  label?: string;
}

export const QuestionList = (props: QuestionsProps) => {
  const { t } = useLocalization();

  const handleChange = (e: string, index: number) => {
    const newValue = [...props.value];
    newValue[index] = e;
    props.onInput(newValue);
  };

  const handleAdd = () => {
    props.onInput([...props.value, ' ']);
  };

  const handleDelete = (index: number) => {
    const newValue = [...props.value];
    newValue.splice(index, 1);
    props.onInput(newValue);
  };

  return (
    <div>
      <Show when={props.label}>
        <div class="mb-2 text-sm uppercase text-label">{props.label}</div>
      </Show>

      <For each={props.value}>
        {(item, index) => {
          return (
            <div class="mb-6 flex   gap-2">
              <LabeledTextInput class=" flex-1" value={item} onInput={(e) => handleChange(e, index())} />{' '}
              <Button variant="outlined" color="warning" class="rounded-lg p-2.5" onClick={() => handleDelete(index())}>
                <IconDelete />
              </Button>
            </div>
          );
        }}
      </For>
      <div class="flex gap-2 border-t border-input-border pt-6">
        <div class="flex cursor-pointer select-none items-center gap-1 text-link hover:opacity-80" onClick={handleAdd}>
          <IconPlus />
          {t('Add addtional questions')}
        </div>
      </div>
    </div>
  );
};

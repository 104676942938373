import { Route, Routes } from '@solidjs/router';
import { PropertyManagersProvider } from '~/contexts/local';
import { AddOrEditPropertyManagerModal } from './forms/AddOrEditPropertyManagerModal';
import { ListPropertyManagers } from './list-property-managers/ListPropertyManagers';

export const PropertyManagerRoutes = () => {
  return (
    <PropertyManagersProvider>
      <Routes>
        <Route path="*" component={ListPropertyManagers} />
      </Routes>
      <Routes>
        <Route path="/add" component={AddOrEditPropertyManagerModal} />
        <Route path="/:managerId/edit" component={AddOrEditPropertyManagerModal} />
      </Routes>
    </PropertyManagersProvider>
  );
};

import dayjs from 'dayjs';
import { createMemo } from 'solid-js';
import DisplayAddress from '~/components/common/DisplayAddress';
import { Table } from '~/components/ui';
import { useLocalization, useProperties } from '~/contexts/global';
import Card from '~/pages/dashboard/components/Card';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type PropertyRenewalProps = {
  class?: string;
};

export const PropertyRenewal: Component<PropertyRenewalProps> = (props) => {
  const { t } = useLocalization();
  const { propertyOverview } = useProperties();

  const columns = createMemo<TableColumns<MagicDoor.Api.LeaseDto>>(() => [
    {
      title: t('Unit'),
      class: 'text-nowrap',
      render: (row) => {
        const unit = createMemo(() => {
          return propertyOverview()?.units?.find((unit) => unit.id === row.unitId);
        });

        return (
          <div>
            <div class="font-medium">{unit?.name}</div>
            <DisplayAddress class="text-xs text-text-level03" address={propertyOverview()?.property?.address} />
          </div>
        );
      },
    },
    {
      title: t('Tenants'),
      headerClass: 'w-64',
      render: (row) => <span class="text-sm font-medium text-text-level01">{row.tenants.length}</span>,
    },
    {
      title: t('Renewal date'),
      class: 'text-nowrap',
      render: (item) => <span class="text-sm">{dayjs(item?.end).format('MM/DD')}</span>,
    },
    {
      title: t('Term'),
      class: 'text-nowrap',
      render: (item) => (
        <span class="text-sm font-medium text-text-level01">{`${dayjs(item.start).format('MM/DD/YYYY')} - ${dayjs(item.end).format(
          'MM/DD/YYYY'
        )}`}</span>
      ),
    },
  ]);

  return (
    <Card
      class={props.class}
      contentClass="h-[288px]"
      title={t('Leases with upcoming renewal')}
      description={t('The lease ends in less than 30 days')}>
      <Table columns={columns()} data={propertyOverview()?.upcomingRenewalLeases} />
    </Card>
  );
};

/* eslint-disable solid/reactivity */
import { useNavigate } from '@solidjs/router';
import { createMemo, createSignal, For, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconDeleteWarning from '~/assets/images/confirm-modal/delete.svg?component-solid';
import IconTrash from '~/assets/images/maintenance/trash.svg?component-solid';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { LinkButton } from '~/components/common/Buttons';
import { Panel } from '~/components/common/Panels';
import { Html, Table, TableActions, toast, confirm } from '~/components/ui';
import { useChartOfAccounts, useLocalization } from '~/contexts/global';
import type { TableColumns, TableRowData } from '~/components/ui';

export type AccountType = 'asset' | 'liability' | 'equity' | 'revenue' | 'expense';

type ExtendedChartOfAccountDto = MagicDoor.Api.ChartOfAccountDto & {
  children?: MagicDoor.Api.ChartOfAccountDto[];
};

const organizeChartOfAccountsByType = (
  data: MagicDoor.Api.ChartOfAccountDto[] = []
): { type: AccountType; accounts: TableRowData<ExtendedChartOfAccountDto>[] }[] => {
  const types: AccountType[] = ['asset', 'liability', 'equity', 'revenue', 'expense'];
  const grouped: Record<AccountType, TableRowData<ExtendedChartOfAccountDto>[]> = {
    asset: [],
    liability: [],
    equity: [],
    revenue: [],
    expense: [],
  };

  const accountMap = new Map<string, TableRowData<ExtendedChartOfAccountDto>>();

  data.forEach((account) => {
    const extendedAccount = { ...account, children: [] } as TableRowData<ExtendedChartOfAccountDto>;
    accountMap.set(extendedAccount.id, extendedAccount);
  });

  data.forEach((account) => {
    const extendedAccount = accountMap.get(account.id);
    if (account.parentId) {
      const parent = accountMap.get(account.parentId);
      if (parent && extendedAccount) {
        parent.children?.push(extendedAccount);
      }
    } else if (grouped[account.type as AccountType]) {
      grouped[account.type as AccountType].push(
        extendedAccount ?? {
          ...account,
          children: [],
        }
      );
    }
  });

  return types.map((type) => ({
    type,
    accounts: grouped[type],
  }));
};

export const ChartOfAccountsTable = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const { setCurrentId, chartOfAccounts, deleteChartOfAccount } = useChartOfAccounts();
  const [selectedReplacementId, setSelectedReplacementId] = createSignal<string>('');

  const typeLabels = createMemo(() => ({
    asset: t('Asset'),
    liability: t('Liability'),
    equity: t('Equity'),
    revenue: t('Revenue'),
    expense: t('Expense'),
  }));

  const columns = createMemo<TableColumns<MagicDoor.Api.ChartOfAccountDto>>(() => [
    {
      title: t('Chart of account'),
      headerClass: 'w-[40%]',
      render: (account) => <div class="text-sm text-text-level01">{account.name}</div>,
    },
    {
      title: t('Default account'),
      headerClass: 'w-[40%]',
      render: (account) => (
        <Show when={account.isDefault} fallback="--">
          <div class="truncate text-sm text-text-level01">{t('Default')}</div>
        </Show>
      ),
    },
    {
      title: '',
      class: 'relative flex justify-end',
      render: (row) => (
        <TableActions
          actions={[
            {
              label: t('Add child account'),
              jsxIcon: (
                <div>
                  <IconPlus class="text-text-level03" />
                </div>
              ),
              onClick: () => onAddChild(row),
            },
            {
              label: t('Delete'),
              icon: IconTrash,
              onClick: () => onDelete(row),
            },
          ]}
        />
      ),
    },
  ]);

  function onAddChild(row: MagicDoor.Api.ChartOfAccountDto) {
    navigate(`/accounting/chart-of-accounts/add/${row.type}?parentId=${row.id}`);
  }
  const organizedByType = createMemo(() => organizeChartOfAccountsByType(chartOfAccounts() || []));

  function onDelete(row: MagicDoor.Api.ChartOfAccountDto) {
    if (row.isDefault) {
      toast.error(t('You cannot delete a default chart of account'));
      return;
    }

    const [error, setError] = createSignal<string | undefined>();

    confirm({
      title: <span>{t(`Delete chart of account`)}</span>,
      content: () => (
        <div class="flex flex-col items-center gap-4 py-4">
          <IconDeleteWarning class="-mx-5 block bg-light-pink" />
          <Html as="p" class="mx-auto mt-3 w-80 text-text-level03 [&>b]:font-bold [&>b]:text-primary">
            {t(
              "In order to delete '<b>{name}</b>' permanently, you must select a replacement chart of account. This action cannot be undone",
              {
                name: row.name,
              }
            )}
          </Html>

          <div class="w-80">
            <LabeledChartOfAccountSelect
              label={t('Replacement chart of account')}
              value={selectedReplacementId()}
              onInput={(value) => {
                setSelectedReplacementId(value);
                setError(undefined);
              }}
              error={error()}
            />
          </div>
        </div>
      ),
      async onResolve(confirmed): Promise<void> {
        return new Promise<void>((resolve, reject) => {
          if (confirmed) {
            const replacementId = selectedReplacementId();

            if (!replacementId) {
              setError(t('Please select a replacement chart of account'));
              reject();
              return;
            }

            deleteChartOfAccount(row.id, replacementId)
              .then((errorData) => {
                if (errorData) {
                  toast.error(t('Failed to delete chart of account'));
                  reject();
                } else {
                  toast.success(t('{name} is successfully deleted', { name: row.name }));
                  resolve();
                }
              })
              .catch(() => {
                toast.error(t('Failed to delete chart of account'));
                reject();
              })
              .finally(() => {
                setSelectedReplacementId('');
                setError(undefined);
              });
          } else {
            resolve();
          }
        });
      },
    });
  }

  return (
    <div class="flex flex-col gap-4">
      <For each={organizedByType()}>
        {({ type, accounts }) => (
          <Show when={accounts.length > 0}>
            <Panel
              title={typeLabels()[type]}
              actions={
                <LinkButton href={`/accounting/chart-of-accounts/add/${type}`}>
                  <IconPlus class="text-white" />
                  <div class="hidden normal-case lg:block">{t(`Add account`)}</div>
                </LinkButton>
              }>
              <Table
                onRowClick={(item) => setCurrentId(item.id)}
                columns={columns()}
                data={accounts}
                rowLink={(item) => `/accounting/chart-of-accounts/edit/${item.id}/${item.type}`}
              />
            </Panel>
          </Show>
        )}
      </For>
    </div>
  );
};

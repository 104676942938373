import { Route, Routes } from '@solidjs/router';
import { MaintenanceRequestsProvider } from '~/contexts/local';
import { WorkOrderTabRoutes } from './WorkOrderTabRoutes';
import { AddWorkOrderView } from './add-work-order/AddWorkOrder';
import { EditWorkOrder } from './edit-work-order/EditWorkOrder';
import { WorkOrderDetailsPage } from './work-orders-details';

export const WorkOrderRoutes = () => {
  return (
    <MaintenanceRequestsProvider>
      <Routes>
        <Route path="/add" element={<AddWorkOrderView />} />
        <Route path="/:workOrderId/edit" component={EditWorkOrder} matchFilters={{ workOrderId: /^\d+$/ }} />
        <Route path="/:workOrderId/*" component={WorkOrderDetailsPage} matchFilters={{ workOrderId: /^\d+$/ }} />
        <Route path="*" component={WorkOrderTabRoutes} />
      </Routes>
    </MaintenanceRequestsProvider>
  );
};

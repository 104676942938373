import { Route, Routes, useNavigate } from '@solidjs/router';
import { createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import UnderConstruction from '~/components/common/UnderConstruction';
import { TabLinks } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { ListingSyndicationsTab } from './tabs/ListingSyndicationsTab';
import { UnitInformationTab } from './tabs/UnitInformationTab';

const ListingDetailsPage = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const breadcrumbItems = createMemo(() => [{ label: t('Listing'), link: '/listing' }, { label: t('Listing detail') }]);

  return (
    <div class="relative h-section overflow-hidden">
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="my-5 flex  flex-col-reverse gap-4  px-8 lg:flex-row">
        <div class="flex w-full flex-col gap-4">
          <TabLinks
            class="mb-4 rounded-lg border border-partingline bg-white"
            items={[
              { label: t('Unit information'), href: '' },
              { label: t('Listing syndications'), href: 'listing-syndications' },
            ]}
            replace
          />
          <Routes>
            <Route path="/" component={UnitInformationTab} />
            <Route path="/listing-syndications" component={ListingSyndicationsTab} />
          </Routes>
        </div>
      </div>
      <UnderConstruction />
    </div>
  );
};

export default ListingDetailsPage;

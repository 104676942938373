import { CustomLayoutButton } from './CustomLayoutButton/CustomLayoutButton';
import LocalizationButton from './LocalizationButton/LocalizationButton';
import ProfileButton from './ProfileButton/ProfileButton';

const Navigation = () => {
  return (
    <div class="fixed top-0 z-30 flex h-16 w-full bg-white px-8 md:w-layout-right">
      <div class="ml-10 flex w-full items-center justify-between md:ml-0">
        <LocalizationButton />

        <div class="flex items-center gap-2">
          <CustomLayoutButton />
          <ProfileButton />
        </div>
      </div>
    </div>
  );
};

export default Navigation;

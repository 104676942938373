import { A, useNavigate } from '@solidjs/router';
import { For, Show } from 'solid-js';
import IconBackArrow from '~/assets/images/common/backArrow.svg?component-solid';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';

export interface BreadcrumbItem {
  label: string;
  link?: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
  backLink?: string | (() => void);
  loading?: boolean;
}

const Breadcrumb = (props: BreadcrumbProps) => {
  const navigate = useNavigate();
  const { t } = useLocalization();

  function onClickBack() {
    if (typeof props.backLink === 'function') {
      props.backLink();
      return;
    }
    props.backLink && navigate(props.backLink);
  }

  return (
    <div class="flex items-center border-t bg-white py-1 text-sm">
      <Show when={props.backLink}>
        <button type="button" onClick={() => onClickBack()} class="flex h-fit gap-3 text-nowrap border-r px-9 py-2 font-medium">
          <IconBackArrow class="text-title-gray" />
          {t('Back')}
        </button>
      </Show>
      <nav
        aria-label="breadcrumb"
        class="w-full overflow-hidden p-4 text-sm"
        classList={{
          'pointer-events-none': props.loading,
        }}>
        <ol class="flex space-x-2 overflow-hidden">
          <For each={props.items}>
            {(item, index) => (
              <>
                <Show when={item.link} fallback={<li class="font-medium text-text-level01">{item.label}</li>}>
                  <li class={cn({ hidden: index() < props.items.length - 1 }, 'xs:block')}>
                    <A href={item.link as string} class="text-text-level03 ">
                      {item.label}
                    </A>
                  </li>
                </Show>
                <Show when={index() < props.items.length - 1}>
                  <li class="hidden text-gray-400 xs:block"> &gt; </li>
                </Show>
              </>
            )}
          </For>
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;

import { DeleteAnnotationUseCase } from './deleteAnnotationUseCase';
import { SelectAnnotationUseCase } from './selectAnnotationUseCase';
import { UseCase } from './useCase';
import type { TransformRequest } from './types/transformRequest';

export class DragAnnotationUseCase extends UseCase {
  public async runLogic(request: TransformRequest) {
    const selectAnnotationUseCase = new SelectAnnotationUseCase();
    const deleteAnnotationUseCase = new DeleteAnnotationUseCase();
    await selectAnnotationUseCase.execute({ id: request.id, page: request.startPage });
    if (!this.state.selectedAnnotation || !request.relativePosition) {
      return;
    }
    if (!this.isTransformationStarted()) {
      await this.initializeTransformation(request);
    }
    if (request.startPage !== request.endPage) {
      const annotation = this.state.selectedAnnotation;
      await deleteAnnotationUseCase.execute({ id: request.id });
      this.state.pdfs.pdfPages[request.endPage].annotations.push(annotation);
      await selectAnnotationUseCase.execute({
        id: request.id,
        page: request.endPage,
      });
    }
    if (!this.state.clickPosition || !this.state.initialBounds) {
      return;
    }
    let x = this.state.initialBounds?.x;
    let y = this.state.initialBounds?.y;
    if (request.relativePosition != undefined) {
      const position = request.relativePosition.position;
      const boundingBox = request.relativePosition.boundingBox;
      const newX = (position.x - boundingBox.x) / boundingBox.width;
      const newY = (position.y - boundingBox.y) / boundingBox.height;
      const deltaX = this.state.clickPosition.x - x;
      const deltaY = this.state.clickPosition.y - y;
      x = newX - deltaX;
      y = newY - deltaY;
    }
    const selectedAnnotation = this.state.selectedAnnotation;
    if (selectedAnnotation) {
      selectedAnnotation.x = x;
      selectedAnnotation.y = y;
    }
  }
}

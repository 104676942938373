import { ValidationError } from '~/errors';
import { BaseRestRepository } from './baseRestRepository';

const PLAID_URL = '/api/plaid';

export function validateId(id: string, entityName = 'Entity'): void {
  if (!id) throw new ValidationError(`${entityName} ID must be provided`);
}

export class PlaidRepository extends BaseRestRepository {
  async addPlaidToBankAccount(bankAccountId: string) {
    validateId(bankAccountId, 'BankAccount');
    const url = `${PLAID_URL}/bank-accounts/${bankAccountId}`;
    await this.fetchWithAuth(url, { method: 'POST' });
  }

  async getPlaidQuestionnaire(bankAccountId: string): Promise<MagicDoor.Api.GetPlaidQuestionnaireUrlDto> {
    validateId(bankAccountId, 'BankAccount');
    const url = `${PLAID_URL}/bank-accounts/${bankAccountId}/questionnaire-url`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  async getPlaidTransfers(): Promise<MagicDoor.Api.HydratedPlaidTransferDtoPaginationDto> {
    const url = `${PLAID_URL}/transfers`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
}

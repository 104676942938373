import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { getHomeAllComponent } from '~/pages/dashboard/constant';
import type { HomeLayout } from '~/pages/dashboard/type';

export const [CustomLayoutProvider, useCustomLayout] = createMagicDoorContext('CustomLayout', () => {
  const [enable, setEnable] = createSignal<boolean>(false);
  let localLayout: HomeLayout[] | undefined;
  if (localStorage.getItem('home-layout')) {
    localLayout = JSON.parse(localStorage.getItem('home-layout') as string);
    localLayout = localLayout?.map((item) => ({
      ...item,
      component: getHomeAllComponent(item.key)[0].component,
    }));
  }
  const [homeLayout, setHomeLayout] = createSignal<HomeLayout[]>(localLayout || getHomeAllComponent());

  return {
    enable,
    setEnable,
    homeLayout,
    setHomeLayout,
    localLayout,
  };
});

import { Route, Routes, useParams, useNavigate } from '@solidjs/router';
import { createEffect, createMemo, onCleanup } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { TabLinks } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequests, useRunBooks } from '~/contexts/local';
import { MaintenanceRequestSummary } from './components/MaintenanceRequestSummary';
import { GroupChatTab } from './tabs/GroupChatTab';
import { MaintenanceAiRunbookTab } from './tabs/MaintenanceAiRunbookTab';
import { MaintenanceCommunicationsTab } from './tabs/MaintenanceCommunicationsTab';
import { MaintenanceOverviewTab } from './tabs/MaintenanceOverviewTab';
import type { TabLinkItem } from '~/components/ui';

export const MaintenanceRequestDetailsPage = () => {
  const { t } = useLocalization();
  const params = useParams<{ maintenanceRequestId: string }>();
  const { getRunBook } = useRunBooks();

  const navigate = useNavigate();

  const { maintenanceRequest, getMaintenanceRequest, maintenanceRequestActions } = useMaintenanceRequests();

  createEffect(() => {
    if (!maintenanceRequest() || maintenanceRequest()?.id !== params.maintenanceRequestId) {
      getMaintenanceRequest(params.maintenanceRequestId);
      setTimeout(() => {
        if (!maintenanceRequest.loading) maintenanceRequestActions.refetch();
      }, 30);
    }
  });

  const breadcrumbItems = createMemo(() => [
    { label: t('Maintenance request'), link: '/maintenance/maintenance-request' },
    { label: t('Maintenance request details') },
  ]);

  const tabs = createMemo<TabLinkItem[]>(() => {
    const allTabs = [
      { label: t('General'), href: '' },
      // { label: t('Updates'), href: 'updates' },
      maintenanceRequest()?.runBookId && { label: t('AI runbook'), href: 'ai-runbook' },
      { label: t('Communications'), href: 'communications' },
    ].filter(Boolean) as TabLinkItem[];
    const groupChatTab = { label: t('Group chat'), href: 'group-chat' };
    if (maintenanceRequest()?.workOrder) allTabs.push(groupChatTab);
    return allTabs;
  });

  createEffect(() => {
    const _runBookId = maintenanceRequest()?.runBookId;
    if (_runBookId) {
      getRunBook(_runBookId);
    }
  });

  onCleanup(() => {
    maintenanceRequestActions.mutate(undefined);
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="my-5 flex flex-col-reverse gap-4 px-8 lg:flex-row">
        <div class="flex w-full flex-col gap-4 lg:w-3/4">
          <TabLinks class="rounded-lg border border-partingline bg-white" items={tabs()} replace />
          <Routes>
            <Route path="/" component={MaintenanceOverviewTab} />
            {/* <Route path="/updates" component={MaintenanceUpdateTab} /> */}
            <Route path="/ai-runbook" component={MaintenanceAiRunbookTab} />
            <Route path="/communications" component={MaintenanceCommunicationsTab} />
            <Route path="/group-chat" component={GroupChatTab} />
          </Routes>
        </div>
        <MaintenanceRequestSummary
          class="w-full min-w-80 shrink-0 lg:w-1/4"
          loading={maintenanceRequest.loading}
          maintenanceRequest={maintenanceRequest()}
        />
      </div>
    </>
  );
};

import dayjs from 'dayjs';
import { createEffect, getOwner, onCleanup } from 'solid-js';
import { useFormContext } from '~/components/common/BetterForm';
import { FormItem, FormList } from '~/components/common/BetterForm/Form';
import { FormListRemoverWrapper } from '~/components/common/BetterForm/FormListRemover';
import { Button } from '~/components/common/Buttons';
import { Section } from '~/components/common/FormSectionTitle';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import LabeledAddressInput from '~/components/common/Inputs/form-inputs/LabeledAddressInput';
import { useLocalization } from '~/contexts/global';
import { useNewRentalApplication } from '~/contexts/local';
import { addressRequired } from '~/utils/formRules';

const WrappedLabeledAddressInput = (props: any) => <LabeledAddressInput {...props} required />;

export const RentalHistoryFormList = () => {
  const owner = getOwner();
  const { t } = useLocalization();

  const { form, validFormItem } = useNewRentalApplication();
  const formContext = useFormContext();

  createEffect(() => {
    if (!form.formStore.residentialHistory || !form.formStore.residentialHistory.length) {
      form.setFormStore('residentialHistory', [{}]);
    }
  });

  onCleanup(() => {
    if (form.formStore.residentialHistory.length) {
      const residentialHistory = form.formStore.residentialHistory.filter((item: any) => item.address);
      form.setFormStore('residentialHistory', residentialHistory);
    }
  });

  return (
    <FormList
      formFieldName={'residentialHistory'}
      renderItemsGroup={({ index, remove }) => {
        return (
          <FormListRemoverWrapper index={index} remove={remove} removeAll showRemove={index() > 0}>
            <div class="mb-8 flex flex-col gap-y-8">
              <FormItem<any>
                placeholder="Address"
                label={t('Street address')}
                formFieldName={['address']}
                component={WrappedLabeledAddressInput}
                required
                rules={[...addressRequired(t)]}
                onChangeMethodName="onChange"
                onChange={(value: string, name: string) => {
                  validFormItem(`residentialHistory.${index()}.address`, true, name);
                }}
              />
            </div>
            <div class="grid grid-cols-3 gap-8">
              <FormItem
                formFieldName={'landlordName'}
                label={t(`Previous property manager`)}
                component={LabeledTextInput}
                rules={[
                  {
                    validator: (value: string) => !value || /[a-zA-Z\s]{2,150}$/.test(value),
                    message: t("Previous property manager's name must consist of 2 to 150 letters.."),
                  },
                ]}
                onInput={() => validFormItem(`residentialHistory.${index()}.landlordName`)}
              />
              <FormItem
                formFieldName={'landlordPhone'}
                label={t(`Cell phone(property manager)`)}
                component={LabeledTextInput}
                rules={[
                  {
                    type: 'phone',
                    message: t('Cell phone(property manager) is not valid', { name: t('Cell phone(property manager)') }),
                  },
                ]}
                onInput={() => validFormItem(`residentialHistory.${index()}.landlordPhone`)}
              />
              <FormItem
                formFieldName={'reasonForLeaving'}
                label={t(`Reason for leaving`)}
                component={LabeledTextInput}
                rules={[
                  {
                    validator: (value: string) => !value || value.length <= 500,
                    message: t('{name} must be {length} characters or less', { name: t(`Reason for leaving`), length: '500' }),
                  },
                ]}
                onInput={() => validFormItem(`residentialHistory.${index()}.reasonForLeaving`)}
              />
              <FormItem
                formFieldName={'rent'}
                label={t(`Rent`)}
                prepend="$"
                component={LabeledNumberInput}
                max={2147483647}
                min={0}
                onInput={() => validFormItem(`residentialHistory.${index()}.rent`)}
              />
              <FormItem
                formFieldName={'moveInDate'}
                label={t(`Move in date`)}
                component={DueDateInputField}
                onInput={() => {
                  validFormItem(`residentialHistory.${index()}.moveOutDate`);
                }}
              />
              <FormItem
                formFieldName={'moveOutDate'}
                label={t(`Move out date`)}
                component={DueDateInputField}
                showUntil
                rules={[
                  {
                    validator: (value: string | undefined) => {
                      const currentFormData = formContext.formStore.residentialHistory[index()];
                      if (currentFormData && currentFormData.moveInDate && value) {
                        return dayjs(currentFormData.moveInDate).isSameOrBefore(dayjs(value));
                      }
                      return true;
                    },
                    message: t('{date1} cannot be earlier than {date2}', { date1: t('Move out date'), date2: t('Move in date') }),
                  },
                ]}
                onInput={() => {
                  validFormItem(`residentialHistory.${index()}.moveOutDate`);
                }}
              />
            </div>
          </FormListRemoverWrapper>
        );
      }}>
      {({ add }) => {
        return (
          <Button variant="outlined" onClick={() => add()}>
            {t('Add more')}
          </Button>
        );
      }}
    </FormList>
  );
};

export const RentalHistory = () => {
  const { t } = useLocalization();
  return (
    <>
      <Section required border title={t('Residential History')}>
        <RentalHistoryFormList />
      </Section>
    </>
  );
};

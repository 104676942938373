import { useNavigate } from '@solidjs/router';
import dayjs from 'dayjs';
import { createMemo, createSignal, Show } from 'solid-js';
import { LabeledBankAccountSearch } from '~/components/bank-accounts/BankAccountSearch';
import { handlePrint } from '~/components/checks/PrintCheck';
import { PrintCheckModal } from '~/components/checks/PrintResultModal';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledInputSwitch } from '~/components/common/Inputs/LabeledInputSwitch';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Modal } from '~/components/modals/Modal';
import { useLocalization, usePayOuts } from '~/contexts/global';
import { paymentMethodOptions, paymentTypeOptions } from '~/utils/constant';
import type { HydratedCheckDto } from '~/swagger/Api';

export const AddPayOutModal = () => {
  const { t } = useLocalization();
  const form = useForm();
  const navigate = useNavigate();
  const { addPayOut, setFilter } = usePayOuts();
  const [payout, setPayout] = createSignal<MagicDoor.Api.HydratedCheckDto>();

  const submitPayout = async () => {
    const { validateStatus } = form.validateForm();
    if (validateStatus) {
      const res = await addPayOut({
        payOuts: [
          {
            bankAccountId: form.formStore.bankAccountId,
            amount: form.formStore.amount,
            paymentType: form.formStore.paymentType,
            paymentMethod: form.formStore.paymentMethod,
            externalTransactionId: form.formStore.externalTransactionId,
            memo: form.formStore.memo,
          },
        ],
        paymentDate: form.formStore.paymentDate,
        setPayOutDate: form.formStore.setPayOutDate,
      });

      setFilter({});

      const check = res.payOuts?.[0].check;
      if (check) {
        setPayout(check);
      } else {
        navigate(-1);
      }
    }
  };

  const printCheck = async () => {
    handlePrint(payout() as HydratedCheckDto);
    navigate(-1);
  };

  const doneAction = createMemo(() => {
    if (payout()) {
      return printCheck;
    }
    return submitPayout;
  });

  return (
    <Show
      when={!payout()}
      fallback={
        <PrintCheckModal
          check={payout() as HydratedCheckDto}
          onDone={() => {
            printCheck();
          }}
        />
      }>
      <Modal
        visible
        onDone={doneAction()}
        doneText={t('Confirm')}
        class="lg:w-modalSm"
        title={t('Add payout')}
        loading={addPayOut.loading}
        onCancel={() => {
          navigate(-1);
        }}>
        <Form
          class="grid grid-cols-1 gap-2 p-5 lg:grid-cols-2"
          defaultForm={form}
          initialValues={{
            paymentDate: dayjs().format('YYYY-MM-DD'),
          }}>
          <FormItem
            class="col-span-1 lg:col-span-2"
            placeholder={t('Please select bank account')}
            label={t(`Bank account`)}
            formFieldName="bankAccountId"
            component={LabeledBankAccountSearch}
            rules={[{ message: t(`Please select bank account`), required: true }]}
          />

          <FormItem class="col-span-1" formFieldName="setPayOutDate" label={t('Set payment date')} component={LabeledInputSwitch} />

          <Show when={form.formStore.setPayOutDate}>
            <FormItem
              formFieldName="paymentDate"
              label={t('Payment date')}
              component={DueDateInputField}
              placeholder={t('Please select')}
              class="col-span-1"
              rules={[{ message: t(`Please select`), required: true }]}
              removeValueOnClean
            />
          </Show>
          <FormItem
            placeholder={t('Please enter')}
            label={t(`Payout amount`)}
            formFieldName="amount"
            prepend="$"
            component={LabeledNumberInput}
            rules={[{ message: t(`Please enter`), required: true }]}
            class="col-span-1"
          />

          <FormItem
            formFieldName="paymentType"
            label={t('Payment type')}
            options={paymentTypeOptions(t)}
            onChangeMethodName="onChange"
            component={DropdownMenu}
            placeholder={t('Please select')}
            class="col-span-1"
            rules={[{ message: t(`Please select`), required: true }]}
          />

          <Show when={form.formStore.paymentType === 'manual'}>
            <FormItem
              formFieldName="paymentMethod"
              label={t('Payment method')}
              options={paymentMethodOptions(t)}
              onChangeMethodName="onChange"
              component={DropdownMenu}
              placeholder={t('Please select')}
              class="col-span-1"
            />

            <FormItem
              label={t('External transaction ID')}
              placeholder={t('Enter transaction ID')}
              component={LabeledTextInput}
              rules={[{ required: true, message: t('Please enter') }]}
              formFieldName="externalTransactionId"
              class="col-span-1"
              maxlength={50}
            />
          </Show>

          <FormItem
            class="col-span-1 "
            formFieldName="memo"
            label={t('Memo')}
            component={LabeledTextInput}
            placeholder={t('Please enter')}
            maxlength={200}
          />

          <Show when={form.formStore.setPayOutDate}>
            <div class="col-span-1 rounded-2xl bg-[#faf1ff] p-4 text-text-level02 lg:col-span-2">
              <div class="text-base normal-case text-text-level02">
                {t('Setting pay out date, will tell the systems that all payouts before this date has been done')}
              </div>
            </div>
          </Show>
        </Form>
      </Modal>
    </Show>
  );
};

import { PortalPaymentMethodSettings } from './PortalPaymentMethodSettings';
import { PortalTenantAccessSettings } from './PortalTenantAccessSettings';
import { PortalTenantRequestsSettings } from './PortalTenantRequestsSettings';

export const TenantPortal = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <PortalTenantAccessSettings />
      <PortalPaymentMethodSettings />
      <PortalTenantRequestsSettings />
    </div>
  );
};

import dayjs from 'dayjs';
import { createMemo, createSignal, For, Show, untrack } from 'solid-js';
import CircleArrowIcon from '~/assets/images/common/circleArrow.svg?component-solid';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledInputSwitch } from '~/components/common/Inputs/LabeledInputSwitch';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization, usePayOuts } from '~/contexts/global';
import { CreditDebitDirection } from '~/swagger/Api';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';
import type { Option } from '~/components/common/DropdownMenu';
import type { MixedPayOutBankAccountDto } from '~/contexts/global';

const paymentTypeOptions: Option[] = [
  { label: 'Print check', value: 'printCheck' },
  { label: 'Manual', value: 'manual' },
];

const paymentMethodOptions: Option[] = [
  { label: 'ACH', value: 'ach' },
  { label: 'Credit', value: 'credit' },
  { label: 'Debit card', value: 'debitCard' },
  { label: 'Wallet', value: 'wallet' },
  { label: 'Credit card', value: 'creditCard' },
  { label: 'Check', value: 'check' },
  { label: 'Cashiers check', value: 'cashiersCheck' },
  { label: 'Money order', value: 'moneyOrder' },
  { label: 'Other', value: 'other' },
];

const BillItem: Component<{ bankAccount: MixedPayOutBankAccountDto }> = (props) => {
  const { t } = useLocalization();
  const [activeIndex, setActiveIndex] = createSignal<number[]>([0]);

  const changeActive = (index: number) => {
    setActiveIndex((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
  };

  const isOpen = (index: number) => activeIndex().includes(index);

  const { setPayoutForm } = usePayOuts();

  const onChangeAll = (key: 'paymentType' | 'paymentMethod' | 'externalTransactionId' | 'memo', val: string) => {
    setPayoutForm('bankAccountPayOuts', function (bankAccountPayOuts) {
      const id = untrack(() => props.bankAccount.bankAccount?.id);
      return bankAccountPayOuts?.map((payment) => {
        if (payment.bankAccount?.id === id) {
          const updatedPayment = { ...payment, [key]: val };
          if (key === 'paymentType') {
            if (val === 'manual') {
              updatedPayment.paymentMethod = paymentMethodOptions[0].value;
              updatedPayment.externalTransactionId = '';
            } else if (val === 'printCheck') {
              updatedPayment.paymentMethod = undefined;
              updatedPayment.externalTransactionId = undefined;
            }
          }

          return updatedPayment;
        }
        return payment;
      });
    });
  };

  return (
    <div class="mb-4 w-full rounded-lg border">
      <div class="flex cursor-pointer items-center justify-between border-b bg-input px-3 py-4">
        <div class="w-1/6">{props.bankAccount.bankAccount?.name}</div>
        <div class="w-1/6">
          <DropdownMenu
            buttonClass="bg-white"
            value={props.bankAccount.paymentType || 'printCheck'}
            options={paymentTypeOptions}
            placeholder={t('Select payment type')}
            onChange={(selected) => onChangeAll('paymentType', selected)}
          />
        </div>
        <Show when={props.bankAccount.paymentType === 'manual'}>
          <div class="w-1/6">
            <DropdownMenu
              buttonClass="bg-white"
              value={props.bankAccount.paymentMethod || paymentMethodOptions[0].value}
              options={paymentMethodOptions}
              placeholder={t('Select payment method')}
              onChange={(val) => onChangeAll('paymentMethod', val)}
            />
          </div>
          <div class="w-1/6">
            <LabeledTextInput
              inputContainerClass="bg-white"
              value={props.bankAccount.externalTransactionId || ''}
              placeholder={t('Enter transaction ID')}
              onInput={(val) => onChangeAll('externalTransactionId', val)}
            />
          </div>
        </Show>
        <Show when={props.bankAccount.paymentType === 'printCheck'}>
          <div class="w-1/6">
            <LabeledTextInput
              maxlength={150}
              inputContainerClass="bg-white"
              value={props.bankAccount.memo || ''}
              placeholder={t('Enter memo')}
              onInput={(e) => onChangeAll('memo', e)}
              validationFunction={(value) => {
                const str = value.toString();
                return str.length > 150 ? t('{name} must be {length} characters or less', { name: t('Memo'), length: '150' }) : undefined;
              }}
            />
          </div>
        </Show>
        <div class="flex w-1/6 items-center justify-end" onClick={() => changeActive(0)}>
          <span class="ml-1 text-xs font-semibold text-text-level01">{currency(props.bankAccount.amount)}</span>
          <CircleArrowIcon
            class="ml-5 mr-1"
            classList={{
              'text-text-level02': !isOpen(0),
              'text-primary rotate-180': isOpen(0),
            }}
          />
        </div>
      </div>
      <Show when={isOpen(0)}>
        <div class="w-full px-5 transition-all">
          <div class="py-3 text-xs font-semibold text-text-level01">{t('Pay outs detail')}</div>
          <div class="my-4">
            <div class="not-prose relative rounded-lg bg-white">
              <div
                class="bg-grid-slate-100 absolute inset-0 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))]"
                style={{ 'background-position': '10px 10px' }}
              />
              <div class="relative rounded-lg">
                <div class="my-8 shadow-sm">
                  <table class="w-full table-auto border-collapse text-xs text-text-level03">
                    <thead>
                      <tr>
                        <th class="border-b  p-3.5 text-left">{t('Cash in')}</th>
                        <th class="border-b  p-3.5 text-left">{t('Cash out')}</th>
                        <th class="border-b p-3.5 text-left">{t('Memo')}</th>
                      </tr>
                    </thead>
                    <tbody class="bg-input text-text-level01">
                      <For each={props.bankAccount.lineItems}>
                        {(bill) => (
                          <tr>
                            <td class="border-b border-slate-100 p-3">
                              {bill.bill?.direction === CreditDebitDirection.Debit && currency(bill.amount)}
                            </td>
                            <td class="border-b border-slate-100 p-3">
                              {bill.bill?.direction === CreditDebitDirection.Credit && currency(bill.amount)}
                            </td>
                            <td class="border-b border-slate-100 p-3 align-top">{bill.bill?.memo}</td>
                          </tr>
                        )}
                      </For>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="pointer-events-none absolute inset-0 rounded-lg border border-black/5 dark:border-white/5" />
            </div>
          </div>
        </div>
      </Show>
    </div>
  );
};

const PaymentForm: Component<any> = () => {
  const { t } = useLocalization();

  const { payoutForm, setPayoutForm } = usePayOuts();

  const paymentTotal = createMemo(() => {
    return payoutForm?.bankAccountPayOuts?.reduce((total, payment) => total + (payment.amount || 0), 0);
  });

  const handleDateChange = (date: string) => {
    setPayoutForm('paymentDate', date);
  };

  const handleSetPayOutDate = (checked: boolean) => {
    setPayoutForm('setPayOutDate', checked);
    setPayoutForm('paymentDate', dayjs().format('YYYY-MM-DD'));
    if (!checked) {
      setPayoutForm('paymentDate', '');
    }
  };

  return (
    <div class="thinscroll mx-auto flex w-10/12 min-w-[710px] flex-col gap-5 overflow-x-auto">
      <div>
        <div class="border-b border-partingline py-3">
          <h4 class="text-base font-semibold">{t('Payment')}</h4>
          <div class="my-4 ml-1 flex flex-col gap-2">
            <LabeledInputSwitch label={t('Set payment date')} onInput={handleSetPayOutDate} />
            <Show when={payoutForm.setPayOutDate}>
              <DueDateInputField
                label={t('Payment date')}
                value={payoutForm.paymentDate}
                class="w-1/2"
                onInput={handleDateChange}
                placeholder={t('Please select')}
              />
            </Show>
          </div>
        </div>
        <div class="my-4 flex w-full justify-between px-3 text-sm">
          <span class="w-1/6 font-medium text-slate-400">{t('Bank account')}</span>
          <span class="w-1/6 font-medium text-slate-400">{t('Payment type')}</span>

          <Show when={payoutForm.bankAccountPayOuts?.some((payment) => payment.paymentType === 'manual')}>
            <span class="w-1/6 font-medium text-slate-400">{t('Payment method')}</span>
            <span class="w-1/6 font-medium text-slate-400">{t('Transaction id')}</span>
          </Show>
          <Show when={payoutForm.bankAccountPayOuts?.some((payment) => payment.paymentType === 'printCheck')}>
            <span class="w-1/6 font-medium text-slate-400">{t('Memo')}</span>
          </Show>
          <span class="w-1/6 text-right font-medium text-slate-400">{t('Payment amount')}</span>
        </div>
        <For each={payoutForm?.bankAccountPayOuts}>{(propertyData) => <BillItem bankAccount={propertyData} />}</For>

        <div class="my-8 flex items-center justify-end text-sm text-text-level02">
          <div class="text-sm uppercase text-text-level02">
            {t('Payment total')}: <span class="text-base font-semibold text-essential-colour">{currency(paymentTotal())}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;

const STORAGE_KEY = 'token';
const PERSISTED_LIFETIME = 1000 * 60 * 60 * 24 * 7; // 7 days
const SESSION_LIFETIME = 1000 * 60 * 60 * 2; // 2 hours (LRU)

type TokenStore = { type: 'session' | 'persisted'; value: string; expires: number };

const tryParseToken = (str: string | null): TokenStore | null => {
  if (!str) return null;
  try {
    const token = JSON.parse(str);
    if (token == null) return null;
    if (typeof token.type !== 'string' || (token.type !== 'session' && token.type !== 'persisted')) return null;
    if (typeof token.value !== 'string' || token.value.length === 0) return null;
    if (typeof token.expires !== 'number' || token.expires < Date.now()) return null;
    return token;
  } catch {
    return null;
  }
};

export const tokenStorage = {
  get: () => {
    const s = localStorage.getItem(STORAGE_KEY);
    const token = tryParseToken(s);
    if (token == null) {
      tokenStorage.clear();
      return null;
    }
    if (token.type === 'session') {
      tokenStorage.set(token.value, false);
    }
    return token.value;
  },
  set: (value: string, persisted?: boolean) => {
    const expires = Date.now() + (persisted ? PERSISTED_LIFETIME : SESSION_LIFETIME);
    const token = { type: persisted ? 'persisted' : 'session', value, expires };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(token));
    // for bff image url auth
    document.cookie = `auth.token=${encodeURIComponent(value)}; expires=${new Date(expires).toUTCString()}; path=/`;
  },
  clear: () => {
    localStorage.removeItem(STORAGE_KEY);
    document.cookie = 'auth.token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
  },
};

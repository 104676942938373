import { leaseTemplateRepository } from '~/pdfsigner/repositories/leaseTemplateRepository';
import { ChangeNumberOfSignersUseCase } from './changeNumberOfSignersUseCase';
import { DataPathManager } from './state/dataPathManager';
import { UseCase } from './useCase';

export class LoadLeaseTemplateUseCase extends UseCase {
  public async runLogic(templateId?: string) {
    const [leaseTemplateCategories, dataPaths] = await Promise.all([
      leaseTemplateRepository.getLeaseTemplateCategories(),
      leaseTemplateRepository.getDataPaths(),
    ]);
    this.state.leaseTemplateCategories = leaseTemplateCategories;
    this.state.dataPathManager = new DataPathManager(dataPaths);
    const defaultCategory = this.state.leaseTemplateCategories.find((category) => category.isDefault);
    const selectedCategory = this.state.leaseTemplateCategories.find((category) => category.id === this.state.pdfs.category?.id);
    if (!selectedCategory) {
      this.state.pdfs.category = defaultCategory ? defaultCategory : this.state.leaseTemplateCategories[0];
    }
    if (!templateId) {
      return;
    }
    const pdfs = await leaseTemplateRepository.getLeaseTemplate(templateId);
    pdfs.category = this.state.leaseTemplateCategories.find((category) => category.id === pdfs.category?.id);
    this.state.pdfs = pdfs;
    if (pdfs.signers.length === 0) {
      new ChangeNumberOfSignersUseCase().execute(2);
    }
  }
}

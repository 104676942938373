import { For, Show } from 'solid-js';
import type { JSX } from 'solid-js';

interface RoundedTableColumn {
  title: string;
  key: string;
  img?: string;
  imgStyle?: string;
  render?: (data: RoundedTableRow) => JSX.Element;
}

export type RoundedTableRow = {
  data: { [key: string]: string | JSX.Element };
  descriptions?: { [key: string]: string };
};

interface RoundedTableProps {
  columns: RoundedTableColumn[];
  data: RoundedTableRow[];
}

const RoundedTableCell = (props: { column: RoundedTableColumn; row: RoundedTableRow }) => {
  return (
    <div class="flex flex-1 items-center px-4 py-2">
      <Show when={props.column.img}>
        <div class="relative mr-2">
          <img src={props.column.img} alt={`${props.column.title} icon`} class={props.column.imgStyle || ''} />
          <Show when={props.column.render}>
            <div class="absolute bottom-0 left-1 rounded-t-lg bg-light-essential px-1.5 text-xxs text-white">
              {props.column.render?.(props.row)}
            </div>
          </Show>
        </div>
      </Show>
      <div class="flex flex-col gap-1 text-left">
        <span class="font-medium text-text-level01">{props.row.data[props.column.key]}</span>
        <Show when={props.row.descriptions && props.row.descriptions[props.column.key]}>
          <span class="normal-case text-text-level03">{props.row.descriptions?.[props.column.key]}</span>
        </Show>
      </div>
    </div>
  );
};

const RoundedTable = (props: RoundedTableProps) => {
  return (
    <div class="overflow-hidden rounded-md border">
      <div class="flex border-b bg-light-faded text-left text-text-level03">
        <For each={props.columns}>{(column) => <div class="flex-1 px-4 py-2 text-xs uppercase">{column.title}</div>}</For>
      </div>
      <For each={props.data}>
        {(row) => (
          <div class="flex border-b py-1 transition-colors hover:bg-gray-100">
            <For each={props.columns}>{(column) => <RoundedTableCell column={column} row={row} />}</For>
          </div>
        )}
      </For>
    </div>
  );
};

export default RoundedTable;

import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { TenantPortalInviteSettingsRepository } from '~/repositories/settings/tenantPortalInviteSettingsRepository';
import type { Accessor } from 'solid-js';

interface TenantPortalInviteSettingsContextValue {
  isLoading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
  getTenantPortalInviteSettings: () => Promise<MagicDoor.Api.TenantPortalInviteTextNotificationSettingsDto | undefined>;
  updateTenantPortalInviteSettings: (
    payload: MagicDoor.Api.TenantPortalInviteTextNotificationSettingsDto
  ) => Promise<MagicDoor.Api.TenantPortalInviteTextNotificationSettingsDto | undefined>;
  deleteTenantPortalInviteSettings: () => Promise<void>;
  tenantPortalInviteSettings: Accessor<MagicDoor.Api.TenantPortalInviteTextNotificationSettingsDto | undefined>;
}

export const [TenantPortalInviteSettingsProvider, useTenantPortalInviteSettings] =
  createMagicDoorContext<TenantPortalInviteSettingsContextValue>('TenantPortalInviteSettings', () => {
    const repo = new TenantPortalInviteSettingsRepository();
    const [tenantPortalInviteSettings, setTenantPortalInviteSettings] = createSignal<
      MagicDoor.Api.TenantPortalInviteTextNotificationSettingsDto | undefined
    >(undefined);
    const [isLoading, setIsLoading] = createSignal<boolean>(false);
    const [error, setError] = createSignal<Error | undefined>(undefined);

    const getTenantPortalInviteSettings = async (): Promise<MagicDoor.Api.TenantPortalInviteTextNotificationSettingsDto | undefined> => {
      setIsLoading(true);

      try {
        const result = await repo.getTenantPortalInviteSettings();
        setTenantPortalInviteSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const deleteTenantPortalInviteSettings = async (): Promise<void> => {
      setIsLoading(true);

      try {
        await repo.deleteTenantPortalInviteSettings();
        setTenantPortalInviteSettings(undefined);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    const updateTenantPortalInviteSettings = async (
      payload: MagicDoor.Api.TenantPortalInviteTextNotificationSettingsDto
    ): Promise<MagicDoor.Api.TenantPortalInviteTextNotificationSettingsDto | undefined> => {
      setIsLoading(true);
      setError(undefined);
      try {
        const result = await repo.updateTenantPortalInviteSettings(payload);
        setTenantPortalInviteSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    return {
      isLoading,
      error,
      getTenantPortalInviteSettings,
      updateTenantPortalInviteSettings,
      deleteTenantPortalInviteSettings,
      tenantPortalInviteSettings,
    };
  });

import IconLogo from '~/assets/images/common/logo.svg?component-solid';
export const PrintPageHeader = (props: { companies?: MagicDoor.Api.CompanyDto }) => {
  return (
    <div>
      <div class="flex w-full flex-row justify-between border-b py-3">
        <div class="flex flex-col gap-1 text-[12px] text-[#5E6077]">
          <span class="text-sm text-[#862888]">{props.companies?.name}</span>
          <span>{props.companies?.address?.streetAddress1}</span>
          <span>
            {props.companies?.address?.city},{props.companies?.address?.state} {props.companies?.address?.zipCode}
          </span>
          <span>{props.companies?.phoneNumber}</span>
          <span>{props.companies?.publicUrl}</span>
        </div>
        <div class="flex  flex-col gap-1 text-xxs text-[#74789A]">
          <IconLogo />
          <span>The AI-Enabled All-In-One </span>
          <span>Property Management Solution</span>
        </div>
      </div>
    </div>
  );
};

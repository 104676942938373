import { useNavigate } from '@solidjs/router';
import { createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { useLocalization } from '~/contexts/global';
import { LeaseTemplateForm } from './LeaseTemplateForm';

export const CustomizeLeaseTemplates = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const breadcrumbItems = createMemo(() => [
    { label: t('Lease templates'), link: '/leasing/lease-templates' },
    { label: t('Customize lease templates') },
  ]);

  return (
    <div>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <LeaseTemplateForm />
    </div>
  );
};

import { Show } from 'solid-js';
import { leasePeriodString } from '~/components/leases/utils';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

type LeasePeriodProps = {
  class?: string;
  lease?: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto;
};

export const LeasePeriod: Component<LeasePeriodProps> = (props) => {
  return (
    <Show when={props.lease}>
      <div class={cn('whitespace-nowrap text-xs text-text-level03', props.class)}>
        {leasePeriodString(props.lease as MagicDoor.Api.HydratedLeaseDto)}
      </div>
    </Show>
  );
};

import { useLocalization } from '~/contexts/global';
import { TenantAutoPayType } from '~/swagger/Api';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';
import type { TFunction } from '~/contexts/global';

export const getAutoPaymentAmount = (t: TFunction, entry: MagicDoor.Api.HydratedTenantAutoPayDto) => {
  if (entry.type === TenantAutoPayType.FixedAmount && entry.fixedAmount) {
    return currency(entry.fixedAmount);
  }

  if (entry.type === TenantAutoPayType.CurrentBalance) {
    return t('Current balance');
  }

  return t('Posted balance');
};

export const AutoPaymentAmount: Component<{ entry: MagicDoor.Api.HydratedTenantAutoPayDto }> = (props) => {
  const { t } = useLocalization();
  return <>{getAutoPaymentAmount(t, props.entry)}</>;
};

import type { Component } from 'solid-js';
import type { SectionProps } from '~/components/common/Panels/MultiSectionPanel';

type UnitPhotosProps = SectionProps<MagicDoor.Api.HydratedUnitDto>;

export const UnitPhotos: Component<UnitPhotosProps> = () => {
  return (
    <div class="">
      <div class="h-96 w-full bg-inputbox-bg" />
    </div>
  );
};

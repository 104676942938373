import { Route, Routes } from '@solidjs/router';
import { PortalInviteText } from './portal-invite-text';
import { PortalInviteUrl } from './portal-invite-url';
import { TenantPortal } from './portal-tenant-portal';

const PortalRoutes = () => {
  return (
    <div class="flex w-full gap-6">
      <Routes>
        <Route path="/tenant-portal" element={<TenantPortal />} />
        <Route path="/invite-url" element={<PortalInviteUrl />} />
        <Route path="/invite-text" element={<PortalInviteText />} />
      </Routes>
    </div>
  );
};

export default PortalRoutes;

import { ValidationError } from '~/errors';
import { BaseRestRepository } from './baseRestRepository';

export function validateId(id: string, entityName = 'Entity'): void {
  if (!id) throw new ValidationError(`${entityName} ID must be provided`);
}

const LEASES_URL = '/api/leases';

export class LeaseDepositRepository extends BaseRestRepository {
  public async getLeaseDeposit(leaseId: string): Promise<MagicDoor.Api.HydratedGetLeaseTransactionsDto> {
    validateId(leaseId);
    const url = `${LEASES_URL}/${leaseId}/deposits/transactions`;
    const response = await this.fetchWithAuth(url, {
      method: 'GET',
    });
    return this.getJsonResponse(response);
  }
  public async addLeaseDeposit(leaseId: string, model: MagicDoor.Api.AddLeaseDepositRequestDto): Promise<MagicDoor.Api.HydratedBillDto> {
    validateId(leaseId);

    const url = `${LEASES_URL}/${leaseId}/deposits`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(model),
    });
    return this.getJsonResponse(response);
  }

  public async addLeaseDepositOfflinePayment(
    leaseId: string,
    model: MagicDoor.Api.OfflinePaymentDto
  ): Promise<MagicDoor.Api.HydratedBillDto> {
    validateId(leaseId);

    const url = `${LEASES_URL}/${leaseId}/deposits/offline-payment`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(model),
    });
    return this.getJsonResponse(response);
  }

  public async releaseDeposit(leaseId: string, request: MagicDoor.Api.ReleaseDepositRequestDto): Promise<MagicDoor.Api.HydratedBillDto> {
    validateId(leaseId);

    const url = `${LEASES_URL}/${leaseId}/deposits/release`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(request),
    });
    return this.getJsonResponse(response);
  }
}

import { LinkButton } from '~/components/common/Buttons';
import { TabLinks } from '~/components/ui';
import { useLocalization } from '~/contexts/global';

const baseUrl = '/accounting/owner-funds';

export const FundsNavbar = () => {
  const { t } = useLocalization();
  return (
    <TabLinks
      boxClass="rounded-lg border border-partingline bg-white  py-2 pr-6"
      items={[
        { label: t('Owner distributions'), href: `${baseUrl}/distributions` },
        { label: t('Owner contribution'), href: `${baseUrl}/contributions` },
      ]}
      actions={
        <LinkButton variant="outlined" href="transfer">
          {t('Transfer funds')}
        </LinkButton>
      }
    />
  );
};

import { useNavigate } from '@solidjs/router';
import { DeactivateModal } from '~/components/modals';
import { toast } from '~/components/ui';
import { useLocalization, usePortfolios } from '~/contexts/global';
import type { Component } from 'solid-js';

export const DeactivatePortfolioModal: Component = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const { current, deactivatePortfolio } = usePortfolios();

  const handleClose = () => {
    const currentId = current()?.id;
    navigate(currentId ? `/portfolios/${currentId}` : '/portfolios', { scroll: false, replace: true });
  };

  const handleConfirm = async () => {
    const portfolio = current();
    if (portfolio == null) return;
    await deactivatePortfolio(portfolio.id, true);
    navigate('/portfolios', { replace: true });
    toast.success(t('{name} has been deactivated successfully', { name: t('Portfolio') }));
  };

  return (
    <DeactivateModal onConfirm={handleConfirm} onCancel={handleClose}>
      {t(
        'Deactivating <b>“{name}”</b> Portfolio will also <b>deactivate all properties under it</b>, and the data will not be displayed in the report.',
        { name: current()?.name ?? '-' }
      )}
    </DeactivateModal>
  );
};

import { createMemo } from 'solid-js';
import { Panel } from '~/components/common/Panels';
import UnderConstruction from '~/components/common/UnderConstruction';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type LatestPaymentsTableProps = {
  class?: string;
};

export const LatestPaymentsTable: Component<LatestPaymentsTableProps> = (props) => {
  const { t } = useLocalization();

  const columns = createMemo<TableColumns<MagicDoor.LeaseTenant>>(() => [
    {
      title: t('Date'),
      render: () => <div />,
    },
    {
      title: t('Receipt no'),
      render: () => <div />,
    },
    {
      title: t('Description'),
      render: () => <div />,
    },
    {
      title: t('From'),
      render: () => <div />,
    },
    {
      title: t('To'),
      render: () => <div />,
    },
    {
      title: t('Amount'),
      render: () => <div />,
    },
  ]);

  return (
    <Panel title={t('Latest payments')} class={props.class}>
      <UnderConstruction />
      {/* <Show when={[].length} fallback={<Empty description={t('There are no latest payments under this unit')} />}>
        <Table columns={columns()} data={[]} />
      </Show> */}
    </Panel>
  );
};

import { useCategoryComponentWrapper } from '~/components/common/Category';
import LabeledBankingInput from '~/components/common/Inputs/form-inputs/LabeledBankingInput';
import { useLocalization } from '~/contexts/global';

const BankingInformationSection = () => {
  const { onClickSection } = useCategoryComponentWrapper();

  const { t } = useLocalization();

  return (
    <div id="bank-information">
      <div class="sticky top-0 z-10 border-b bg-white py-3 text-lg font-semibold text-text-level01">{t('Banking information')}</div>
      <LabeledBankingInput fieldName="bankRouting" onClick={() => onClickSection('bankingInformation')} />
    </div>
  );
};

export default BankingInformationSection;

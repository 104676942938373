import { useParams, useNavigate } from '@solidjs/router';
import { Switch, Match, Show, For, createSignal, createEffect } from 'solid-js';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import { LinkButton, Button } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import { LeasePeriod, LeaseStatus } from '~/components/leases';
import { getTenantFullName } from '~/components/tenants/utils';
import { IconBan, IconCirclePlus, ListView, Popover, TableActions } from '~/components/ui';
import { UnitTitle } from '~/components/units';
import { useLocalization, useUnits } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import { DeactivateUnit } from '~/pages/units/components/DeactivateUnit';
import { emptyPlaceholder } from '~/utils/constant';
import { currency } from '~/utils/number';
import { Avatar } from './Avatar';
import type { Component } from 'solid-js';

const UnitLeasesList: Component<{
  leases?: (MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto)[];
}> = (props) => {
  const navigate = useNavigate();
  function getTenant(lease: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto): lease is MagicDoor.Api.HydratedLeaseDto {
    return 'tenant' in lease.tenants[0];
  }
  return (
    <ul class="flex w-96 max-w-full cursor-pointer flex-col gap-1 divide-y divide-partingline rounded-lg border border-partingline bg-white p-2 shadow-lg">
      <For each={props.leases}>
        {(item) => (
          <li class="flex cursor-pointer items-center gap-2 p-3" onClick={() => navigate(`/leasing/leases/${item?.id}`)}>
            <Avatar lease={item} />
            <div class="flex-1">
              <LeasePeriod class="whitespace-nowrap text-sm font-medium text-title-gray" lease={item} />
              <div class="text-xs text-text-level03">{getTenant(item) ? getTenantFullName(item.tenants[0].tenant) : emptyPlaceholder}</div>
            </div>
            <LeaseStatus class="text-xs font-medium" lease={item} />
          </li>
        )}
      </For>
    </ul>
  );
};

export const UnitsListView = () => {
  const { t } = useLocalization();
  const { units, getUnits } = useUnits();
  const params = useParams<{ propertyId: string; portfolioId: string }>();
  const { localPageSize, setPage, setPageSize } = useLocalPagination('UnitsListView');
  const [deactivating, setDeactivating] = createSignal<MagicDoor.Api.HydratedUnitDto>();

  const getRentAndDepositAndBalanceTotal = (
    activeLeases: (MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto)[] | undefined,
    key: 'currentRent' | 'securityDepositRequested' | 'balance' | 'securityDepositPaid'
  ) => {
    if (!activeLeases || !activeLeases.length) {
      return undefined;
    }
    return activeLeases?.reduce((total, item) => {
      return total + item[key];
    }, 0);
  };

  const depositContent = (activeLeases: (MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto)[]) => {
    const paid = getRentAndDepositAndBalanceTotal(activeLeases, 'securityDepositPaid');
    const requested = getRentAndDepositAndBalanceTotal(activeLeases, 'securityDepositRequested');

    return (
      <>
        <Show when={paid !== requested} fallback={<span class="text-sm font-medium text-text-level01">{currency(requested)}</span>}>
          <span class="text-sm font-medium text-text-level01">{currency(paid)}</span>
          <span class="text-xs font-light text-text-level04">/{currency(requested)}</span>
        </Show>
      </>
    );
  };

  createEffect(() => getUnits({ pageSize: localPageSize, propertyId: params.propertyId, portfolioId: params.portfolioId }));

  return (
    <>
      <ListView
        class="mx-8"
        title={t('Units')}
        data={units()?.items}
        page={units()?.currentPage}
        pageSize={units()?.pageSize}
        totalPages={units()?.totalPages}
        loading={units.loading}
        searchPlaceholder={t('Search units')}
        onChange={({ page, pageSize, search }) => {
          setPage(page);
          setPageSize(pageSize);
          getUnits({ ...params, page, pageSize, search });
        }}
        rowLink={(item) => item.id}
        titleActions={
          <LinkButton href="add" noScroll>
            <IconCirclePlus class="size-5" />
            <span class="hidden lg:block">{t('Add unit')}</span>
          </LinkButton>
        }
        columns={[
          {
            title: t('Units'),
            render: (unit: MagicDoor.Api.HydratedUnitDto) => <UnitTitle class="min-w-40" unit={unit} />,
          },
          {
            title: t('Lease'),
            render: (unit) => (
              <div>
                <Switch fallback={<div class="text-xs text-danger">{t('Not leased')}</div>}>
                  <Match when={unit.activeLeases?.length && unit.activeLeases?.length > 1}>
                    <div class="flex flex-col text-xs">
                      <span class="text-link">{`${unit.activeLeases?.length} ${t('Ongoing leases')}`}</span>
                      <Popover class="relative">
                        {({ setOpen }) => (
                          <>
                            <Button onClick={() => setOpen(true)} class="mt-1 rounded-full px-2 py-0.5 text-xs" variant="outlined">
                              {t('View all')}
                            </Button>
                            <Popover.Content as={UnitLeasesList} leases={unit.activeLeases} align="start" />
                          </>
                        )}
                      </Popover>
                    </div>
                  </Match>
                  <Match when={unit.activeLeases?.length === 1}>
                    <div>
                      <Show when={unit.activeLeases?.length} fallback={<div class="text-xs text-danger">{t('Not leased')}</div>}>
                        <LeaseStatus lease={unit.activeLeases?.length ? unit.activeLeases[0] : undefined} />
                        <LeasePeriod lease={unit.activeLeases?.length ? unit.activeLeases[0] : undefined} />
                      </Show>
                    </div>
                  </Match>
                </Switch>
              </div>
            ),
          },
          {
            title: t('Rent'),
            headerClass: 'text-right',
            class: 'text-right',
            render: (unit) => (
              <span class="text-sm">
                {unit.activeLeases?.length ? `${currency(getRentAndDepositAndBalanceTotal(unit.activeLeases, 'currentRent'))}` : '-'}
              </span>
            ),
          },
          {
            title: t('Deposit'),
            headerClass: 'text-right',
            class: 'text-right',
            render: (unit) => <span class="text-sm">{unit.activeLeases?.length ? depositContent(unit.activeLeases) : '-'}</span>,
          },
          {
            title: t('Balances'),
            headerClass: 'text-right',
            render: (unit) => <CnMoney money={getRentAndDepositAndBalanceTotal(unit.activeLeases, 'balance')} showIcon />,
          },
          {
            title: t('Operation'),
            headerClass: 'text-right',
            class: 'relative',
            render: (unit) => (
              <div class="flex justify-end">
                <TableActions
                  actions={[
                    {
                      label: t('Edit'),
                      icon: IconPencil,
                      href: `edit/${unit.id}`,
                      noScroll: true,
                    },
                    {
                      label: t('Deactivate'),
                      icon: IconBan,
                      onClick: () => setDeactivating(unit),
                    },
                    {
                      label: t('Add service bill'),
                      icon: IconCirclePlus,
                      href: `addServiceBill/${unit.id}`,
                      noScroll: true,
                    },
                  ]}
                />
              </div>
            ),
          },
        ]}
      />
      <DeactivateUnit unit={deactivating()} onClose={setDeactivating} />
    </>
  );
};

import {
  BankAccountsListProvider,
  RentalApplicationPaymentAccountProvider,
  RentalApplicationScreeningSettingsProvider,
} from '~/contexts/local';
import { PaymentAccount } from './PaymentAccount';
import { Screen } from './Screen';

export const RentalApplicationInformationWarp = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <RentalApplicationScreeningSettingsProvider>
        <Screen />
      </RentalApplicationScreeningSettingsProvider>
      <RentalApplicationPaymentAccountProvider>
        <BankAccountsListProvider>
          <PaymentAccount />
        </BankAccountsListProvider>
      </RentalApplicationPaymentAccountProvider>
    </div>
  );
};

import { useNavigate } from '@solidjs/router';
import { onMount, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconSort from '~/assets/images/common/sort.svg?component-solid';
import IconEdit from '~/assets/images/maintenance/edit.svg?component-solid';
import IconTrash from '~/assets/images/maintenance/trash.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import { Radio } from '~/components/common/Inputs/Radio';
import { ListView } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useViolations } from '~/contexts/local';
import { useLocalPagination } from '~/hooks';
import { AssociationViolationStatus } from '~/swagger/Api';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder, enumToOptions } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';

export const ViolationStatus: Component<{ status: AssociationViolationStatus }> = (props) => {
  const { t } = useLocalization();
  const statusConfig = {
    [AssociationViolationStatus.Received]: {
      text: 'Received',
      class: 'bg-essential-colour/80 text-white',
    },
    [AssociationViolationStatus.Resolved]: {
      text: 'In progress',
      class: 'bg-danger/80 text-white',
    },
    [AssociationViolationStatus.InProgress]: {
      text: 'Resolved',
      class: 'bg-light-green/80 text-white',
    },
  };

  return (
    <Show when={statusConfig[props.status]}>
      <div class={cn('inline-block h-fit text-nowrap rounded-xl px-2 py-1 text-xs capitalize', statusConfig[props.status].class)}>
        {t(statusConfig[props.status].text)}
      </div>
    </Show>
  );
};

export const ListViolations = () => {
  const { t } = useLocalization();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('AssociationsViolationsListView');
  const { filtered, setFilter, deleteViolation, handleStatusChange } = useViolations();
  const navigate = useNavigate();

  onMount(() => {
    setFilter({ page: page(), pageSize: pageSize() });
  });

  return (
    <div class="space-y-5 p-8">
      <ListView
        skeletonSize={10}
        title={t('Violations overview')}
        titleActions={
          <LinkButton class="ml-auto text-sm capitalize" href="/associations/violations/add" noScroll>
            <IconPlus class="text-white" />
            {t('Add violation')}
          </LinkButton>
        }
        page={filtered()?.currentPage}
        totalPages={filtered()?.totalPages}
        pageSize={filtered()?.pageSize}
        data={filtered()?.items}
        loading={filtered.loading}
        onChange={({ page, pageSize }) => {
          setPage(page);
          setPageSize(pageSize);
          setFilter({ page, pageSize });
        }}
        rowLink={(item) => `/associations/violations/${item.id}`}
        columns={[
          {
            title: t('Title'),
            key: 'title',
          },
          {
            title: t('Property'),
            render: (item) => <div>{filtered()?.properties?.find((it) => it.id === item.propertyId)?.displayName || emptyPlaceholder}</div>,
          },
          {
            title: t('Status'),
            render: (item) => <ViolationStatus status={item.status ?? AssociationViolationStatus.InProgress} />,
          },
          {
            title: t('Violation date'),
            render: (item) => <div>{dateFormat('', item.violationDate)}</div>,
          },
          {
            title: t('Fix by date'),
            render: (item) => <div>{dateFormat('', item.fixByDate) ?? emptyPlaceholder}</div>,
          },
          {
            title: t('Association fine'),
            render: (item) => <div>{currency(item.associationFine ?? emptyPlaceholder)}</div>,
          },
        ]}
        actions={(row) => [
          {
            label: t('Modify Status'),
            icon: IconSort,
            children: (
              <Radio
                direction="column"
                items={enumToOptions(AssociationViolationStatus, (key: AssociationViolationStatus) => (
                  <ViolationStatus status={key} />
                ))}
                value={row?.status}
                onInput={(value) => handleStatusChange(row?.id, value as MagicDoor.Api.AssociationViolationStatus)}
              />
            ),
          },
          {
            label: t('Edit'),
            icon: IconEdit,
            onClick: () => {
              navigate(`${row.id}/edit`);
            },
          },
          {
            label: t('Delete'),
            icon: IconTrash,
            onClick: () => {
              deleteViolation(row.id);
            },
          },
        ]}
      />
    </div>
  );
};

import { Route, Routes } from '@solidjs/router';
import { RentalApplicationFeeWrap } from './application-fee';
import { ChartOfAccountWrap } from './chart-of-account';
import { RentalApplicationInformationWarp } from './information';
import { StandardQuestionsWrap } from './standard-questions';

const RentalApplicationRoutes = () => {
  return (
    <Routes>
      <Route path="/applicationfee" element={<RentalApplicationFeeWrap />} />
      <Route path="/chart-of-account" element={<ChartOfAccountWrap />} />
      <Route path="/information" element={<RentalApplicationInformationWarp />} />
      <Route path="/standard-questions" element={<StandardQuestionsWrap />} />
    </Routes>
  );
};

export default RentalApplicationRoutes;

import type { Component } from 'solid-js';

export const CircularProgress: Component<{ class?: string; value: number }> = (props) => (
  <svg
    class={props.class}
    viewBox="0 0 24 24"
    fill="none"
    stroke-width={3}
    stroke-linecap="round"
    stroke-linejoin="round"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-opacity={0.1} />
    <circle
      cx="12"
      cy="12"
      r="10"
      fill={props.value >= 1 ? 'currentColor' : 'transparent'}
      stroke="currentColor"
      stroke-dashoffset={(Math.PI * 20 * (1 - Math.min(Math.max(props.value, 0), 1))).toString()}
      stroke-dasharray={(Math.PI * 20).toString()}
      style={{ transition: 'stroke-dashoffset 0.3s, fill 0.3s' }}
    />
    <path d="M18.5 8L9.5 17L5 12" stroke={props.value >= 1 ? '#fff' : 'none'} stroke-width={2} />
  </svg>
);

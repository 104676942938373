import { createSignal, createEffect, For } from 'solid-js';

interface Item {
  title: string;
  value: number;
  color: string;
  path?: string;
}
interface Props {
  data: Item[];
  width?: string;
  height?: string;
}

export const FullPieChart = (props: Props) => {
  const [slices, setSlices] = createSignal<Item[]>([]);

  createEffect(() => {
    const total = props.data.reduce((sum, item) => sum + item.value, 0);
    let startAngle = 0;

    if (props.data.length === 1) {
      const item = props.data[0];
      setSlices([
        {
          path: describeFullCircle(100, 100, 80),
          title: item.title,
          value: item.value,
          color: item.color,
        },
      ]);
    } else {
      const newSlices = props.data.map((item) => {
        const percentage = item.value / total;
        const endAngle = startAngle + percentage * 360;

        const slice = {
          path: describeArc(100, 100, 80, startAngle, endAngle),
          title: item.title,
          value: item.value,
          color: item.color,
        };

        startAngle = endAngle;
        return slice;
      });

      setSlices(newSlices);
    }
  });

  function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }

  function describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
    return ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y, 'L', x, y, 'Z'].join(' ');
  }

  function describeFullCircle(x: number, y: number, radius: number) {
    return ['M', x - radius, y, 'A', radius, radius, 0, 1, 0, x + radius, y, 'A', radius, radius, 0, 1, 0, x - radius, y, 'Z'].join(' ');
  }

  return (
    <svg width={props.width || '200'} height={props.height || '200'} viewBox="0 0 200 200">
      <For each={slices()}>
        {(slice) => (
          <path d={slice.path} fill={slice.color} stroke="white" stroke-width="1">
            <title>{`${slice.title}: ${slice.value}`}</title>
          </path>
        )}
      </For>
    </svg>
  );
};

import {
  TenantAutoPayFailedNotificationProvider,
  TenantBalanceDueNotificationProvider,
  PropertyManagerNotificationProvider,
} from '~/contexts/local';
import { PropertyManagerNotification } from './PropertyManagerNotification';
import { PropertyRentPayFailNotificationSettings } from './PropertyRentPayfailNotification';
import { TenantBalanceDueNotification } from './TenantBalanceDueNotification';

export const PropertyRentNotification = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <TenantAutoPayFailedNotificationProvider>
        <PropertyRentPayFailNotificationSettings />
      </TenantAutoPayFailedNotificationProvider>
      <TenantBalanceDueNotificationProvider>
        <TenantBalanceDueNotification />
      </TenantBalanceDueNotificationProvider>
      <PropertyManagerNotificationProvider>
        <PropertyManagerNotification />
      </PropertyManagerNotificationProvider>
    </div>
  );
};

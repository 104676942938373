import { pdfAppState } from './state/pdfAppState';
import { AnnotationType } from './types/annotation';
import type { EventEmitter } from './state/eventEmitter';
import type { TransformRequest } from './types/transformRequest';

export abstract class UseCase {
  protected state = pdfAppState;
  protected eventEmitter?: EventEmitter;

  constructor(eventEmitter?: EventEmitter) {
    this.eventEmitter = eventEmitter;
  }

  public async execute(params?: any): Promise<void> {
    try {
      await this.runLogic(params);
      this.eventEmitter?.emitStateChange(this.state);
    } catch (error) {
      this.eventEmitter?.emitError(error as Error);
    }
  }

  public abstract runLogic(params: any): Promise<void>;

  protected isPdfLoaded(): boolean {
    return this.state.pdfs.pdfPages.length > 0;
  }

  protected isTransformationStarted() {
    return this.state.initialBounds && this.state.clickPosition;
  }

  protected isClientInput(type?: AnnotationType): boolean {
    if (type === undefined) {
      return false;
    }
    return type === AnnotationType.INITIALS || type === AnnotationType.SIGNATURE || type === AnnotationType.DATE;
  }

  protected async initializeTransformation(request: TransformRequest) {
    this.setClickPosition(request);
    this.state.isEditing = false;
    this.setInitialBounds();
  }

  private setInitialBounds() {
    if (!this.state.selectedAnnotation) {
      return;
    }
    this.state.initialBounds = {
      x: this.state.selectedAnnotation.x,
      y: this.state.selectedAnnotation.y,
      width: this.state.selectedAnnotation.width,
      height: this.state.selectedAnnotation.height,
    };
  }

  protected isPageWithingRange(page: number): boolean {
    return page < 0 || page > this.state.pdfs.pdfPages.length;
  }

  private setClickPosition(request: TransformRequest) {
    if (!request.relativePosition) {
      return;
    }
    const position = request.relativePosition.position;
    const boundingBox = request.relativePosition.boundingBox;
    this.state.clickPosition = {
      x: (position.x - boundingBox.x) / boundingBox.width,
      y: (position.y - boundingBox.y) / boundingBox.height,
    };
  }
}

import { createSignal, onMount } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { runBooksSettingsRepository } from '~/repositories/settings/runBooksSettingsRepository';
import { createLazyResource } from '~/utils/resource';
import type { MaintenanceRequestRunBookSettingsDtoSettingsWithOverridesDto } from '~/swagger/Api';

export const [RunBooksSettingsProvider, useRunBooksSettings] = createMagicDoorContext('RunBooksSettings', () => {
  const repo = new runBooksSettingsRepository();
  const [isLoading, setIsLoading] = createSignal<boolean>(false);
  const [error, setError] = createSignal<Error | undefined>(undefined);

  const [allRunBooksSettings, allRunBooksSettingsActions] =
    createLazyResource<MaintenanceRequestRunBookSettingsDtoSettingsWithOverridesDto>(() => repo.getAllRunBooksSettings());

  // change update delete text setting like chats
  const updateRunbooksSettingsByLevel = async (
    level: string,
    levelId: string,
    payload: MagicDoor.Api.MaintenanceRequestRunBookSettingsDto
  ): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.updateRunBooksSettingsByLevel(level, levelId, payload);
      setTimeout(() => {
        allRunBooksSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteRunBooksSettingsByLevel = async (level: string, levelId: string): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.deleteRunBooksSettingsByLevel(level, levelId);
      setTimeout(() => {
        allRunBooksSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const updateRunBooksSettings = async (payload: MagicDoor.Api.MaintenanceRequestRunBookSettingsDto): Promise<void> => {
    setIsLoading(true);
    try {
      await repo.changeRunBooksSettings(payload);
      setTimeout(() => {
        allRunBooksSettingsActions.refetch();
      }, 1000);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  onMount(() => {
    allRunBooksSettingsActions.fetch();
  });

  return {
    isLoading,
    error,

    updateRunBooksSettings,
    allRunBooksSettings,
    updateContributionSettingsByLevel: updateRunbooksSettingsByLevel,
    deleteRunBooksSettingsByLevel,
  };
});

import { splitProps } from 'solid-js';
import { LabeledGroup } from '~/components/common/Inputs';
import { SearchSelect } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { VendorRepository } from '~/repositories/vendorRepository';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { EntrySearchProps } from '~/components/ui';

const repo = new VendorRepository();

export const VendorSearch = <T extends boolean | undefined = undefined>(props: EntrySearchProps<MagicDoor.Api.HydratedVendorDto, T>) => {
  const { t } = useLocalization();
  return (
    <SearchSelect
      fetcher={(query: string | undefined, page: number) => repo.getVendors({ page, pageSize: 20, search: query })}
      placeholder={t('Search vendors')}
      renderSelected="name"
      renderItem={(item) => item.name}
      {...props}
    />
  );
};

export const LabeledVendorSearch = <T extends boolean | undefined = undefined>(
  props: Omit<EntrySearchProps<MagicDoor.Api.HydratedVendorDto, T>, 'onSelect'> &
    LabeledGroupProps & {
      value?: T extends true ? string[] : string;
      onInput?: (value: T extends true ? string[] : string) => void;
    }
) => {
  const [searchProps, labeledProps] = splitProps(props, ['multiple', 'selected', 'placeholder', 'onInput', 'exclude']);

  // TODO: get entries by ids???

  return (
    <LabeledGroup {...labeledProps}>
      <VendorSearch
        {...searchProps}
        onSelect={(selected) => {
          props.onInput?.((Array.isArray(selected) ? selected.map((t) => t.id) : selected.id) as T extends true ? string[] : string);
        }}
      />
    </LabeledGroup>
  );
};

import { Button } from '~/components/common/Buttons';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import TableSkeleton from '~/components/skeletons/TableSkeleton';
import { useLocalization } from '~/contexts/global';

const VendorBill = () => {
  const { t } = useLocalization();

  const columns = [
    {
      title: (
        <input
          type="checkbox"
          class="size-4 appearance-none rounded border border-gray-400 checked:border-transparent checked:bg-essential-colour"
        />
      ),
      key: 'checkbox',
      skeletonRender: () => (
        <input
          type="checkbox"
          disabled
          class="size-4 appearance-none rounded border border-gray-400 checked:border-transparent checked:bg-essential-colour"
        />
      ),
    },

    { title: t('Due'), key: 'due' },
    { title: t('Memo'), key: 'memo' },
    { title: t('Property'), key: 'property' },
    { title: t('Amount'), key: 'amount' },
  ];

  return (
    <ThreeSectionBox
      topTitle={<div>{t('Bills')}</div>}
      topAction={
        <div class="flex gap-2">
          <Button type="button" disabled variant="outlined">
            {t('Add a bill')}
          </Button>
        </div>
      }
      middle={<TableSkeleton columns={columns} rowCount={3} />}
      bottom={
        <div class="flex justify-between p-4 ">
          <div class="flex items-center py-3 text-left text-text-level03">{t('Bill not selected yet')}</div>
        </div>
      }
    />
  );
};

export default VendorBill;

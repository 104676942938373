import { RentalApplicationSettingsProvider } from '~/contexts/local';
import { StandardQuestions } from './StandardQuestions';

export const StandardQuestionsWrap = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <RentalApplicationSettingsProvider>
        <StandardQuestions />
      </RentalApplicationSettingsProvider>
    </div>
  );
};

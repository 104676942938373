import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { RentalApplicationScreeningRepository } from '~/repositories/settings/rentalApplicationScreeningRepository';
import type { Accessor } from 'solid-js';

interface RentalApplicationScreeningSettingsContextValue {
  isLoading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
  updateRentalApplicationScreeningSettings: (
    payload: MagicDoor.Api.RentalApplicationScreeningSettingsDto
  ) => Promise<MagicDoor.Api.RentalApplicationScreeningSettingsDto | undefined>;
  getRentalApplicationScreeningSettings: () => Promise<MagicDoor.Api.RentalApplicationScreeningSettingsDto | undefined>;
  deleteRentalApplicationScreeningSettings: () => Promise<void>;
  rentalApplicationScreeningSetting: Accessor<MagicDoor.Api.RentalApplicationScreeningSettingsDto | undefined>;
}

export const [RentalApplicationScreeningSettingsProvider, useRentalApplicationScreeningSettings] =
  createMagicDoorContext<RentalApplicationScreeningSettingsContextValue>('RentalApplicationScreeningSettings', () => {
    const repo = new RentalApplicationScreeningRepository();
    const [rentalApplicationScreeningSetting, setRentalApplicationScreeningSettings] = createSignal<
      MagicDoor.Api.RentalApplicationScreeningSettingsDto | undefined
    >(undefined);
    const [isLoading, setIsLoading] = createSignal<boolean>(false);
    const [error, setError] = createSignal<Error | undefined>(undefined);

    const getRentalApplicationScreeningSettings = async (): Promise<MagicDoor.Api.RentalApplicationScreeningSettingsDto | undefined> => {
      setIsLoading(true);

      try {
        const result = await repo.getRentalApplicationScreeningSettings();
        setRentalApplicationScreeningSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const updateRentalApplicationScreeningSettings = async (
      payload: MagicDoor.Api.RentalApplicationScreeningSettingsDto
    ): Promise<MagicDoor.Api.RentalApplicationScreeningSettingsDto | undefined> => {
      setIsLoading(true);

      try {
        const result = await repo.updateRentalApplicationScreeningSettings(payload);
        setRentalApplicationScreeningSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const deleteRentalApplicationScreeningSettings = async (): Promise<void> => {
      setIsLoading(true);

      try {
        await repo.deleteRentalApplicationScreeningSettings();
        setRentalApplicationScreeningSettings(undefined);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    return {
      isLoading,
      error,
      updateRentalApplicationScreeningSettings,
      getRentalApplicationScreeningSettings,
      deleteRentalApplicationScreeningSettings,
      rentalApplicationScreeningSetting,
    };
  });

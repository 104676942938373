import { useNavigate, useParams } from '@solidjs/router';
import { createMemo, Show, createEffect, onMount } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import Breadcrumb from '~/components/common/Breadcrumb';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { RingLoader } from '~/components/common/Loaders';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import { toast } from '~/components/ui';
import { useLocalization, useWorkOrders } from '~/contexts/global';
import { permissionToEnterTypes } from '~/utils/constant';

export const EditWorkOrder = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const breadcrumbItems = createMemo(() => [
    { label: t('Work orders'), link: '/maintenance/work-orders' },
    { label: t('Edit work order') },
  ]);

  const form = useForm();
  const params = useParams<{ workOrderId: string }>();
  const { getWorkOrder, workOrder, updateWorkOrder, workOrderActions } = useWorkOrders();

  const handleSubmit = async () => {
    const { validateStatus } = form.validateForm();
    const id = params.workOrderId || workOrder()?.id;
    if (!validateStatus || !id) throw new Error();
    try {
      await updateWorkOrder(id, form.formStore as MagicDoor.Api.UpdateWorkOrderDto);
      navigate(-1);
      toast.success(t('{name} has been edited successfully', { name: t('Work order') }));
    } catch (err) {
      console.error(err);

      throw err;
    }
  };

  onMount(() => {
    if (params.workOrderId) {
      if (workOrder()?.id === params.workOrderId) workOrderActions.refetch();
      else getWorkOrder(params.workOrderId);
    }
  });

  createEffect(() => {
    form.setFieldsValue({
      title: workOrder()?.title,
      dueDate: workOrder()?.dueDate,
      urgency: workOrder()?.urgency,
      status: workOrder()?.status,
      permissionToEnter: workOrder()?.permissionToEnter,
      description: workOrder()?.description,
      internalDescription: workOrder()?.internalDescription,
    });
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <MultiStepsPanel
        onClose={() => navigate(-1)}
        title={t('Edit work order')}
        disableNext={!form.isValidate}
        onSubmit={handleSubmit}
        loading={true}>
        <div class="relative">
          <div class="mx-auto  w-10/12 ">
            <Form defaultForm={form} class="mx-auto my-10 flex w-full max-w-[1200px] flex-col gap-5 text-left">
              <div class="grid grid-cols-3 gap-5">
                <FormItem
                  class="col-span-full lg:col-span-1"
                  formFieldName="title"
                  label={t('Title')}
                  component={LabeledTextInput}
                  placeholder={t('Please enter')}
                  rules={[{ required: true, message: t(`Please enter`) }]}
                />
                <FormItem class="col-span-full lg:col-span-1" formFieldName="dueDate" label={t('Due date')} component={DueDateInputField} />
              </div>
              <div class="grid grid-cols-3 gap-5">
                <FormItem
                  class="col-span-full lg:col-span-1"
                  label={t(`Priority`)}
                  rules={[{ required: true, message: t(`Please select priority`) }]}
                  formFieldName="urgency"
                  options={[
                    { label: t('Urgent'), value: 'urgent' },
                    { label: t('High'), value: 'high' },
                    { label: t('Medium'), value: 'medium' },
                    { label: t('Low'), value: 'low' },
                    { label: t('None'), value: 'none' },
                  ]}
                  onChangeMethodName="onChange"
                  component={DropdownMenu}
                  placeholder={t('Please select')}
                />
                <FormItem
                  class="col-span-full lg:col-span-1"
                  label={t(`Status`)}
                  rules={[{ required: true, message: t(`Please select status`) }]}
                  formFieldName="status"
                  options={[
                    { label: t('Pending'), value: 'pending' },
                    { label: t('In progress'), value: 'inProgress' },
                    { label: t('Closed'), value: 'closed' },
                  ]}
                  onChangeMethodName="onChange"
                  component={DropdownMenu}
                  placeholder={t('Please select')}
                />
                <FormItem
                  class="col-span-full lg:col-span-1"
                  label={t(`Permission to enter`)}
                  options={permissionToEnterTypes(t)}
                  formFieldName="permissionToEnter"
                  onChangeMethodName="onChange"
                  component={DropdownMenu}
                  placeholder={t('Please select')}
                />
              </div>
              <div class="grid grid-cols-3 gap-5">
                <FormItem
                  class="col-span-full"
                  formFieldName="description"
                  label={t('Description')}
                  component={LabeledTextInput}
                  placeholder={t('Please enter')}
                  rules={[{ required: true, message: t(`Please enter`) }]}
                />
                <FormItem
                  class="col-span-full"
                  formFieldName="internalDescription"
                  label={t('Internal description')}
                  component={LabeledTextInput}
                  placeholder={t('Please enter')}
                />
              </div>
            </Form>
          </div>

          <Show when={workOrder.loading}>
            <div class="absolute inset-0 z-50 mx-8 flex flex-col items-center justify-center rounded-lg bg-white">
              <RingLoader color="#a126ec" />
            </div>
          </Show>
        </div>
      </MultiStepsPanel>
    </>
  );
};

import { createSignal } from 'solid-js';

export const useLocalPagination = (name: string, data?: { page: number; pageSize: number }) => {
  const getPage = (defaultValue?: number) => {
    return Number(localStorage.getItem(`${name}LocalPage`)) || defaultValue || 1;
  };

  const getPageSize = (defaultValue?: number) => {
    return Number(localStorage.getItem(`${name}LocalPageSize`)) || defaultValue || 50;
  };

  const [page, setPage] = createSignal(getPage(data?.page));
  const [pageSize, setPageSize] = createSignal(getPageSize(data?.pageSize));

  const updatePage = (page: number) => {
    localStorage.setItem(`${name}LocalPage`, `${page}`);
    setPage(page);
  };

  const updatePageSize = (newPageSize: number) => {
    if (newPageSize !== pageSize()) {
      updatePage(1);
    }

    localStorage.setItem(`${name}LocalPageSize`, `${newPageSize}`);
    setPageSize(newPageSize);
  };

  return {
    page,
    setPage: updatePage,
    pageSize,
    setPageSize: updatePageSize,
    get localPage() {
      return getPage();
    },
    get localPageSize() {
      return getPageSize();
    },
  };
};

import { BaseRestRepository } from './baseRestRepository';

const LEASE_URL = '/api/leases';

export class LeaseAutoPaymentsRepository extends BaseRestRepository {
  public async getAutoPayments(leaseId: string): Promise<MagicDoor.Api.HydratedTenantAutoPayDto[]> {
    const url = `${LEASE_URL}/${leaseId}/auto-pays`;
    const response = await this.fetchWithAuth(url, { method: 'GET' });

    return this.getJsonResponse(response);
  }
}

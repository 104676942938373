import rentImg from '~/assets/images/units/listing/rent.png';
import truliaImg from '~/assets/images/units/listing/trulia.png';
import { Panel } from '~/components/common/Panels';
import UnderConstruction from '~/components/common/UnderConstruction';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';
type ListingSyndicationsProps = {
  class?: string;
};

export const ListingSyndications: Component<ListingSyndicationsProps> = (props) => {
  const { t } = useLocalization();
  return (
    <div class={cn('relative', props.class)}>
      <Panel title={t('Listing syndications')} actions={<div class="rounded-xl bg-success px-5 py-1 text-white">{t('Listing')}</div>}>
        <div class="mx-6 border-b py-4">
          <div>
            <span class="text-base font-medium text-text-level01">{t('Paid listing sites')}</span>

            <span class="ml-6 text-sm text-text-level03">5 {t('Sites')}</span>
          </div>
          <div class="mt-3">
            <img class="size-6" src={truliaImg} />
          </div>
        </div>
        <div class="mx-6 py-4">
          <div>
            <span class="text-base font-medium text-text-level01">{t('Free listing sites')}</span>

            <span class="ml-6 text-sm text-text-level03">2 {t('Sites')}</span>
          </div>
          <div class="mt-3">
            <img class="size-6" src={rentImg} />
          </div>
        </div>
      </Panel>
      <UnderConstruction />
    </div>
  );
};

import { createMemo, Show } from 'solid-js';
import { cn } from '~/utils/classnames';
import type { Component, JSX } from 'solid-js';

type DataItem = {
  value: number;
  color: string;
  name?: string;
};

type DataItemWithCount = DataItem & {
  count?: number;
  startValue?: number;
  endValue?: number;
  startPercent?: number;
  endPercent?: number;
  startDegrees?: number;
  endDegrees?: number;
};

type PieChartProps = {
  data: DataItemWithCount[];
  class?: string;
  innerDom?: JSX.Element;
};

const getCssString = (data: DataItemWithCount[]) => {
  const totalValue = data.sort((a, b) => b.value - a.value).reduce((a, b) => a + b.value, 0);
  if (!totalValue) {
    return `#E2E9F2 0deg 360deg`;
  } else {
    const convertToPercent = (num: number) => Math.round((num / totalValue) * 100);
    const convertToDegrees = (num: number) => Math.round((num / 100) * 360);
    return data
      .reduce<DataItemWithCount[]>((items, item, index, array) => {
        items.push(item);
        item.count = item.count || 0;
        item.count += array[index - 1]?.count || item.count;
        item.startValue = array[index - 1]?.count ? array[index - 1].count : 0;
        item.endValue = item.count += item.value;
        item.startPercent = convertToPercent(item.startValue as number, index);
        item.endPercent = convertToPercent(item.endValue, index + 1);
        item.startDegrees = convertToDegrees(item.startPercent);
        item.endDegrees = convertToDegrees(item.endPercent);
        return items;
      }, [])
      .map((chart, index) => {
        const { startDegrees, endDegrees } = chart;
        return ` ${data[index].color} ${startDegrees}deg ${endDegrees}deg`;
      })
      .join();
  }
};

export const PieChart: Component<PieChartProps> = (props: PieChartProps) => {
  const cssString = createMemo(() => getCssString(props.data));

  return (
    <div class={cn('flex grow flex-col gap-8', props.class)}>
      <div class="relative">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="size-full rounded-full">
          <clipPath id="hole">
            <path d="M 50 0 a 50 50 0 0 1 0 100 50 50 0 0 1 0 -100 v 18 a 2 2 0 0 0 0 64 2 2 0 0 0 0 -64" />
          </clipPath>

          <foreignObject x="0" y="0" width="100" height="100" clip-path="url(#hole)">
            <div
              x-mlns="http://www.w3.org/2000/svg"
              class="size-full"
              style={{
                background: `conic-gradient(${cssString()})`,
              }}
            />
          </foreignObject>
        </svg>

        <Show when={!props.innerDom} fallback={props.innerDom}>
          <svg
            class="absolute inset-1/2 -translate-x-1/2 -translate-y-1/2"
            width="27"
            height="23"
            viewBox="0 0 27 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.1903 11.7535V14.3989C15.2028 14.3986 15.215 14.3969 15.2276 14.3969C16.1897 14.3969 16.9768 15.2072 16.9768 16.1975V16.5807H24.0451V16.1975C24.0451 15.2072 24.8323 14.3969 25.7944 14.3969V11.7535C25.7944 11.2477 25.3925 10.834 24.9012 10.834H16.0836C15.5923 10.834 15.1903 11.2478 15.1903 11.7535ZM25.7944 15.1248C25.2417 15.1248 24.7895 15.5903 24.7895 16.1592V18.4196H16.2325V16.1592C16.2325 15.5903 15.7803 15.1248 15.2276 15.1248C14.6749 15.1248 14.2227 15.5903 14.2227 16.1592V18.4196V19.2241V19.9521V21.9442V22.7488H15.5253V21.9442H25.4967V22.7488H26.7994V21.9442V19.9521V19.2241V18.4196V16.1592C26.7993 15.5903 26.3471 15.1248 25.7944 15.1248Z"
              fill="#EEC9FF"
            />
            <path
              d="M16.8975 9.94594H19.5175V0.977039C19.5175 0.572307 19.1832 0.241211 18.7747 0.241211H5.73263C5.32407 0.241211 4.98985 0.572337 4.98985 0.977039V19.2876C4.80452 19.3196 4.62456 19.3532 4.44911 19.3879C4.1826 18.9703 3.7127 18.6928 3.17711 18.6928C2.35304 18.6928 1.68414 19.3493 1.6734 20.1631C1.65326 20.1708 1.63261 20.1784 1.61271 20.186C0.591417 20.5791 0.884258 22.085 1.97992 22.085H5.73269H13.248V10.6818C13.248 10.2771 13.5823 9.94597 13.9908 9.94597H15.4666L16.8975 9.94594ZM11.2594 15.4261C11.2594 15.8308 10.9251 16.1619 10.5166 16.1619H7.6098C7.20127 16.1619 6.86701 15.8308 6.86701 15.4261V10.6818C6.86701 10.2771 7.20127 9.94597 7.6098 9.94597H10.5166C10.9251 9.94597 11.2594 10.2771 11.2594 10.6818V15.4261ZM11.2594 7.58893C11.2594 7.99367 10.9251 8.32476 10.5166 8.32476H7.6098C7.20127 8.32476 6.86701 7.99364 6.86701 7.58893V2.84468C6.86701 2.43995 7.20127 2.10885 7.6098 2.10885H10.5166C10.9251 2.10885 11.2594 2.43998 11.2594 2.84468V7.58893ZM13.2479 7.58893V2.84468C13.2479 2.43995 13.5822 2.10885 13.9907 2.10885H16.8975C17.3061 2.10885 17.6403 2.43998 17.6403 2.84468V7.58893C17.6403 7.99367 17.306 8.32476 16.8975 8.32476H13.9907C13.5822 8.32479 13.2479 7.99367 13.2479 7.58893Z"
              fill="#A126EC"
            />
          </svg>
        </Show>
      </div>
    </div>
  );
};

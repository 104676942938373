import { useParams } from '@solidjs/router';
import { For, Show, createMemo, createSignal } from 'solid-js';
import { template } from 'solid-js/web';
import IconCircleNotSelected from '~/assets/images/common/circleNotSelected.svg?component-solid';
import IconCircleSelected from '~/assets/images/common/circleSelected.svg?component-solid';
import IconPolygon from '~/assets/images/common/polygon.svg';
import IconClock from '~/assets/images/common/timeThin.svg?component-solid';
import aiScoreBackground from '~/assets/images/rental-application/aiScoreBg.png';
import aiScoreLogo from '~/assets/images/rental-application/aiScoreLogo.png';
import IconApprove from '~/assets/images/rental-application/approve.svg';
import IconIgnore from '~/assets/images/rental-application/quick-ignore.svg';
import IconReject from '~/assets/images/rental-application/quick-reject.svg';
import { Button } from '~/components/common/Buttons';
import { Dropdown } from '~/components/common/Dropdown';
import { RingLoader } from '~/components/common/Loaders/RingLoader';
import { Modal } from '~/components/modals/Modal';
import { getTenantFullName } from '~/components/tenants/utils';
import { IconChevronDown, toast } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { NotFoundError, HttpError } from '~/errors';
import { QuickAction } from '~/pages/properties/property-details/rental-application-details/report/QuickAction';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import { clamp } from '~/utils/tool';
import type { Accessor, Component } from 'solid-js';

interface StepInfo {
  current: number;
  delta: number;
}

interface PaymentStatusIndicatorProps {
  label: string | undefined;
  color: string;
}
interface StatusIndicatorProps {
  label: string;
  color: string;
}

interface ProgressIndicatorProps {
  index: number;
  step: StepInfo;
}

export const paymentStatusIndicatorsArray: Array<PaymentStatusIndicatorProps> = [
  {
    label: 'paid',
    color: '#36CBAC',
  },
  {
    label: 'unpaid',
    color: '#BEC6DB',
  },
  {
    label: 'processing',
    color: '#00C0FF',
  },
  {
    label: 'failed',
    color: '#FF0000',
  },
  {
    label: 'refunded',
    color: '#999999',
  },
];
export const statusIndicatorsArray: Array<StatusIndicatorProps> = [
  {
    label: 'available',
    color: '#36CBAC',
  },
  {
    label: 'notRequested',
    color: '#BEC6DB',
  },
  {
    label: 'requested',
    color: '#3C35F2',
  },
];

export const PaymentStatusIndicator: Component<PaymentStatusIndicatorProps> = (props) => {
  return (
    <div class="relative flex h-4 items-center justify-center overflow-hidden rounded-2xl px-2" style={{ color: props.color }}>
      <div class="capitalize">{props.label}</div>
      <div class="absolute inset-0" style={{ background: props.color, opacity: 0.2 }} />
    </div>
  );
};
export const StatusIndicator: Component<StatusIndicatorProps> = (props) => (
  <div class="relative flex h-4 items-center justify-center overflow-hidden rounded-2xl px-2" style={{ color: props.color }}>
    <div class="mr-1 size-2 rounded-full" style={{ background: props.color }} />
    <div class="capitalize">{props.label}</div>
    <div class="absolute inset-0" style={{ background: props.color, opacity: 0.2 }} />
  </div>
);

const ProgressIndicator: Component<ProgressIndicatorProps> = (props) => {
  const { t } = useLocalization();
  const labels = createMemo(() => ['Very bad', 'Bad', 'Good', 'Very good', 'Excellent'].map((label) => t(label)));

  return (
    <Show when={props.step.current === props.index + 1}>
      <img src={IconPolygon} alt="Indicator" class="absolute -top-3 -translate-x-1/2" style={{ left: `${props.step.delta}%` }} />
      <Show when={labels()[props.step.current - 1]}>
        <div class="absolute left-1/2 -translate-x-1/2 translate-y-1 whitespace-nowrap text-xxs font-medium opacity-90">
          {labels()[props.step.current - 1]}
        </div>
      </Show>
    </Show>
  );
};

const StepBar: Component<{ score: number }> = (props) => {
  const stepInfo: Accessor<StepInfo> = createMemo(() => {
    const currentStep = Math.ceil(props.score / 20);
    return {
      current: currentStep,
      delta: clamp((props.score - (currentStep - 1) * 20) / 20, 0.2, 0.8) * 100,
    };
  });

  return (
    <div class="flex items-center gap-1">
      <For each={['#FF5C5C', '#FFAA6C', '#FFE769', '#87FF93', '#00D28D']}>
        {(color, index) => (
          <div class="relative">
            <div
              style={{ 'background-color': color }}
              class={cn('h-1 w-9 rounded-sm', index() + 1 === stepInfo().current && 'h-2 rounded-md')}
            />
            <ProgressIndicator index={index()} step={stepInfo()} />
          </div>
        )}
      </For>
      <span class="text-xxs">100</span>
    </div>
  );
};

export const AIScore = () => {
  const { t } = useLocalization();
  const { baseUpdate, applicationStore, getReport, setApplicationStore, requestReport, fetchApplication } = useRentalApplication();
  const aiApplicationScore = createMemo(() => applicationStore.application?.aiApplicationScore ?? 0);
  const reportStatus = createMemo(() => applicationStore.application?.reportStatus ?? 'notRequested');
  const paymentStatus = createMemo(() => applicationStore.application?.paymentStatus ?? 'unpaid');
  const [showContent, setShowContent] = createSignal<boolean>(false);
  const { applicationId } = useParams();

  const reports: { title: string; type: `${MagicDoor.Api.TransunionReportType}` }[] = [
    {
      title: t('Credit report'),
      type: 'credit',
    },
    {
      title: t('Eviction relation proceedings'),
      type: 'eviction',
    },
    {
      title: t('Criminal background report'),
      type: 'criminal',
    },
  ];

  const html = createMemo(() => {
    if (applicationStore.currentPreviewReportType) {
      const str = applicationStore.reports[applicationStore.currentPreviewReportType];
      if (str) {
        setTimeout(() => {
          const links = document.querySelector('#report_container')?.querySelectorAll('link') as any;
          Promise.all(
            Array.from(links).map((link: any) => {
              return new Promise((resolve, reject) => {
                link.addEventListener('load', resolve);
                link.addEventListener('error', reject);
              });
            })
          ).finally(() => {
            setShowContent(true);
          });
        }, 100);
        return template(`<div>${str}</div>`);
      }
    }

    return () => <span>{t('loading')}</span>;
  });

  const getDecisionClass = (decision?: string) => {
    switch (decision) {
      case 'rejected':
        return 'bg-[#F9487433] text-danger';
      case 'ignored':
        return 'bg-[#DFE8FF] text-[#4B64C6]';
      case 'approved':
        return 'bg-[#E1FFF9] text-light-green';
      default:
        return 'bg-gray-200';
    }
  };
  const getDecisionIcon = (decision?: string) => {
    const allIcon = {
      rejected: IconReject,
      ignored: IconIgnore,
      approved: IconApprove,
    };
    const icon = allIcon[decision as keyof typeof allIcon];
    return (
      <Show when={icon}>
        <img src={icon} class="size-3" alt="DecisionIcon" />
      </Show>
    );
  };

  const getDecisionTagHtml = (decision?: string) => {
    return (
      <div class={`${getDecisionClass(decision)} flex w-fit items-center gap-1 rounded-full px-4 py-1 text-xs capitalize`}>
        {getDecisionIcon(decision)}
        {t(decision || '')}
      </div>
    );
  };

  const onUpdateDecision = async (decision: MagicDoor.Api.ApplicationDecision, showToast = true) => {
    await baseUpdate({
      decision,
    });
    showToast && toast.success(t('Operation successful'));
  };

  const onRequestReport = async () => {
    try {
      await requestReport();
      toast.success(t('Operation successful, please waiting for report.'));
      const interval = setInterval(() => {
        fetchApplication(applicationId);
        if (applicationStore.application?.reportStatus === 'available') {
          clearInterval(interval);
        }
      }, 5000);
    } catch (errors) {
      let message: any = t('Operation failed, please try again later');

      if (errors instanceof NotFoundError || errors instanceof HttpError) {
        message = errors ? <For each={Object.values(errors.getErrors()).flat()}>{(error) => <p>{error as string}</p>}</For> : message;
      }

      toast.error(message);
    }
  };

  return (
    <div class="text-xs text-text-level03">
      <div class="flex flex-wrap items-center justify-between gap-10">
        <div class="items-left flex w-full flex-col gap-2 pl-4 text-left xl:w-2/5">
          <div>
            <h2 class="text-xl font-medium text-black">{getTenantFullName(applicationStore.application)}</h2>
            <span class="w-56 break-words">
              {applicationStore.application?.email},{applicationStore.application?.phone}
            </span>
          </div>
          <Show
            when={['rejected', 'approved'].includes(applicationStore.application?.applicationDecision || '')}
            fallback={getDecisionTagHtml(applicationStore.application?.applicationDecision)}>
            <Dropdown
              class="w-full cursor-pointer"
              contentPosition={['bottom']}
              renderLabel={(isOpen, open) => (
                <div
                  onClick={() => open(!isOpen)}
                  class={`${getDecisionClass(
                    applicationStore.application?.applicationDecision
                  )} flex w-fit items-center gap-1 rounded-full px-4 py-1 text-xs capitalize`}>
                  {getDecisionIcon(applicationStore.application?.applicationDecision)}
                  {t(applicationStore.application?.applicationDecision || '')}
                  <IconChevronDown
                    class="size-3.5 transition-transform"
                    classList={{
                      'rotate-180': isOpen,
                    }}
                  />
                </div>
              )}>
              {(_, open) => (
                <div class="flex flex-col gap-2">
                  <For each={['approved', 'rejected', 'ignored']}>
                    {(action) => (
                      <div
                        class="flex cursor-pointer items-center justify-between gap-2"
                        onClick={() => {
                          onUpdateDecision(action as MagicDoor.Api.ApplicationDecision);
                          open(false);
                        }}>
                        {getDecisionTagHtml(action)}
                        <Show when={action === applicationStore.application?.applicationDecision} fallback={<IconCircleNotSelected />}>
                          <IconCircleSelected />
                        </Show>
                      </div>
                    )}
                  </For>
                </div>
              )}
            </Dropdown>
          </Show>

          <Show when={!applicationStore.application?.hasBeenSubmitted}>
            <div class="flex h-6 w-fit items-center gap-1 rounded-xl bg-text-level03 px-3 text-xs capitalize text-white">
              <IconClock class="stroke-white" />
              {t('Information not completed')}
            </div>
          </Show>
        </div>
        <Show when={aiApplicationScore()}>
          <div
            style={{ 'background-image': `url(${aiScoreBackground})` }}
            class="flex h-fit w-full items-center gap-3	rounded-3xl bg-cover bg-center bg-no-repeat px-6 py-5 text-white xl:w-2/3">
            <img src={aiScoreLogo} alt="AI score" class="w-28" />
            <div class="flex flex-col items-center justify-start gap-6">
              <div class="flex flex-wrap justify-between gap-4 self-stretch">
                <div class="grow-1 flex basis-36 items-end justify-center gap-1.5">
                  <h2 class="text-3xl font-semibold leading-none	">{aiApplicationScore()}</h2>
                  <span class="text-sm">{t('Overall rating')}</span>
                </div>
                <StepBar score={aiApplicationScore()} />
              </div>
              <div class="text-xs opacity-90">
                <span class="font-medium"> {t('Reason for ai score')}: </span> {applicationStore.application?.aiApplicationDescription}
              </div>
            </div>
          </div>
        </Show>
      </div>
      <div class="mb-4 flex w-full flex-wrap gap-4">
        <Show when={applicationStore.application?.paymentStatus !== 'refunded'}>
          <QuickAction />
        </Show>

        <div class="relative h-fit grow rounded-lg border border-partingline capitalize sm:w-full lg:my-6 lg:w-auto ">
          <div class="flex h-11 items-center border-b p-3 text-sm font-medium capitalize text-text-level01">
            {t('Payment status')}
            <div class="pl-6">
              <PaymentStatusIndicator
                {...(paymentStatusIndicatorsArray.find(
                  (item) => item.label === applicationStore.application?.paymentStatus
                ) as (typeof paymentStatusIndicatorsArray)[0])}
              />
            </div>
          </div>

          <div class="min-h-20 divide-y">
            <div class="space-y-2 p-4">
              {/* <div class="flex items-center justify-between">
                <span class="text-sm text-gray-600">{t('Payment status')}</span>
                <span class="text-sm font-medium text-gray-900">{applicationStore.application?.paymentStatus}</span>
              </div> */}

              <div class="flex items-center justify-between">
                <span class="text-sm text-gray-600">{t('Payment amount')}</span>
                <span class="text-sm font-medium text-gray-900">
                  {!applicationStore.application?.paymentAmount ? emptyPlaceholder : applicationStore.application?.paymentAmount}
                </span>
              </div>
              <Show when={applicationStore.application?.paymentStatus === 'refunded'}>
                <div class="flex items-center justify-between">
                  <span class="text-sm text-gray-600">{t('Refund amount')}</span>
                  <span class="text-sm font-medium text-red-500">
                    {!applicationStore.application?.paymentAmount ? emptyPlaceholder : applicationStore.application?.refundAmount}
                  </span>
                </div>
              </Show>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap justify-between gap-4">
        <Show
          when={reportStatus() !== 'notRequested'}
          fallback={
            <div class="flex h-[132px] w-full grow flex-col items-stretch justify-between gap-5 rounded-xl border border-partingline bg-white p-4">
              <div class="flex flex-col items-start justify-between gap-1.5">
                <h2 class="flex-1 text-sm font-medium capitalize text-text-level01">{t('Request report')}</h2>
                <StatusIndicator label="notRequested" color="#BEC6DB" />
              </div>
              <Button
                loading={applicationStore.isReportLoading}
                onClick={onRequestReport}
                variant="outlined"
                class="flex w-full justify-center text-sm capitalize">
                {t('Request report')}
              </Button>
            </div>
          }>
          <For each={reports}>
            {(section) => (
              <div class="lex h-[132px] w-full flex-1 flex-col  items-stretch justify-between gap-5 rounded-xl border border-partingline bg-white p-4">
                <div class="flex flex-col items-start justify-between gap-1.5">
                  <h2 class="flex-1 text-sm font-medium capitalize text-text-level01">{section.title}</h2>
                  <StatusIndicator
                    {...(statusIndicatorsArray.find((item) => item.label === reportStatus()) as (typeof statusIndicatorsArray)[0])}
                  />
                </div>
                <Button
                  onClick={() => {
                    setShowContent(false);
                    getReport(section.type);
                  }}
                  disabled={reportStatus() !== 'available'}
                  variant="outlined"
                  class="mt-3 flex w-full justify-center text-sm capitalize">
                  {t('View report')}
                </Button>
              </div>
            )}
          </For>
        </Show>
      </div>
      <Modal
        class="!w-4/5"
        visible={!!applicationStore.currentPreviewReportType}
        onCancel={() => {
          setApplicationStore('currentPreviewReportType', undefined);
        }}
        confirmation={false}
        onDone={() => {
          setApplicationStore('currentPreviewReportType', undefined);
        }}
        title={reports.find((item) => item.type === applicationStore.currentPreviewReportType)?.title}>
        <div class="thinscroll flex h-[calc(100vh-400px)] w-full items-center justify-center overflow-auto">
          <Show when={applicationStore.isReportLoading && !showContent()}>
            <RingLoader color="#A126EC" />
          </Show>
          <div
            class={cn('h-full grow', {
              'invisible w-0 grow-0': applicationStore.isReportLoading,
            })}>
            <div id="report_container" class={`${showContent() ? 'block' : 'hidden'}`}>
              {html() as any}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

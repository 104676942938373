import dayjs from 'dayjs';
import { For } from 'solid-js';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';

export const lastMonthRange = {
  start: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
  end: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
};

export const ListReports = () => {
  const { t } = useLocalization();

  const navigateToReport = (path: string, filters: any) => {
    const url = new URL(window.location.origin + path);
    Object.keys(filters).forEach((key) => {
      url.searchParams.set(key, filters[key]);
    });
    window.location.href = url.toString();
  };

  const reports = [
    {
      title: t('Balance sheet'),
      path: '/reports/balance-sheet',
      buttons: [
        { label: t('Balance sheet'), filters: {} },
        { label: t('Balance sheet report as of'), filters: { reportAsOff: 'true' } },
        { label: t('Balance sheet include no property bills'), filters: { includeNoPropertyBills: 'true' } },
        { label: t('Balance sheet company bills'), filters: { complanyBills: 'true' } },
      ],
    },
    {
      title: t('Cash flow'),
      path: '/reports/cash-flow',
      buttons: [
        { label: t('Cash flow'), filters: {} },
        { label: t('Cash flow by month'), filters: { groupBy: 'month' } },
        { label: t('Cash flow by property'), filters: { groupBy: 'property' } },
        { label: t('Cash flow by quarter'), filters: { groupBy: 'quarter' } },
        { label: t('Cash flow by year'), filters: { groupBy: 'year' } },
      ],
    },
    {
      title: t('Income statement'),
      path: '/reports/income-statement',
      buttons: [
        { label: t('Income statement'), filters: { ...lastMonthRange } },
        {
          label: t('Income statement for today'),
          filters: { start: new Date().toISOString().split('T')[0], end: new Date().toISOString().split('T')[0] },
        },
        { label: t('Income statement include no property bills'), filters: { includeNoPropertyBills: 'true', ...lastMonthRange } },
        { label: t('Income statement company bills'), filters: { complanyBills: 'true', ...lastMonthRange } },
      ],
    },
    {
      title: t('General ledger'),
      path: '/reports/general-ledger',
      buttons: [
        { label: t('General ledger'), filters: {} },
        {
          label: t('General ledger for today'),
          filters: { start: new Date().toISOString().split('T')[0], end: new Date().toISOString().split('T')[0] },
        },
        { label: t('General ledger include no property bills'), filters: { includeNoPropertyBills: 'true' } },
        { label: t('General ledger company bills'), filters: { complanyBills: 'true' } },
      ],
    },
    {
      title: t('Owner statement'),
      path: '/reports/owner-statement',
      buttons: [{ label: t('Owner statement'), filters: {} }],
    },
    {
      title: t('Rent roll'),
      path: '/reports/rent-roll',
      buttons: [{ label: t('Rent roll'), filters: {} }],
    },
    {
      title: t('Owner directory'),
      path: '/reports/owner-directory',
      buttons: [{ label: t('Owner directory'), filters: {} }],
    },
    {
      title: t('Property directory'),
      path: '/reports/property-directory',
      buttons: [
        { label: t('Property directory'), filters: {} },
        { label: t('Property directory for active'), filters: { active: true } },
        { label: t('Property directory for inactive'), filters: { active: false } },
      ],
    },
    {
      title: t('Tenant directory'),
      path: '/reports/tenant-directory',
      buttons: [
        { label: t('Tenant directory'), filters: {} },
        {
          label: t('Tenant directory includes no active lease'),
          filters: { includeNoActiveLease: true },
        },
        {
          label: t('Tenant directory without no active lease'),
          filters: { includeNoActiveLease: false },
        },
      ],
    },
    {
      title: t('Bank account activity'),
      path: '/reports/bank-account-activity',
      buttons: [{ label: t('Bank account activity'), filters: {} }],
    },
    {
      title: t('Lease unpaid bills'),
      path: '/reports/lease-unpaid-bills',
      buttons: [{ label: t('Lease unpaid bills'), filters: {} }],
    },
  ];

  return (
    <div class="p-8 text-sm">
      <div class="mb-5 flex w-full items-center justify-between rounded-lg border border-partingline bg-white px-6 py-3">
        <div class="text-lg font-semibold">{t('Reports')}</div>
      </div>
      <div class="flex flex-col gap-4">
        <For each={reports}>
          {(report) => (
            <Panel title={report.title}>
              <div class="grid grid-cols-2 flex-col gap-x-4 rounded-lg bg-white px-6 pb-6 pt-3 text-start">
                <For each={report.buttons}>
                  {(button) => (
                    <button
                      class=" flex cursor-pointer items-center border-b p-2 text-base hover:bg-input/50"
                      onClick={() => navigateToReport(report.path, button.filters)}>
                      {button.label}
                    </button>
                  )}
                </For>
              </div>
            </Panel>
          )}
        </For>
      </div>
    </div>
  );
};

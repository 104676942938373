import { useNavigate } from '@solidjs/router';
import { createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { useLocalization } from '~/contexts/global';
import AddTenant from '~/pages/tenants/components/AddTenant';

export const AddTenantPage = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const breadcrumbItems = createMemo(() => [{ label: t('Tenants'), link: '/users/tenants' }, { label: `${t('Add a new tenant')}` }]);

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <AddTenant />
    </>
  );
};

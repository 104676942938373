import { Show, createMemo, mergeProps } from 'solid-js';
import { Image, Skeleton } from '~/components/ui';
import { cn } from '~/utils/classnames';
import { stringToColor } from '~/utils/strings';
import type { Component } from 'solid-js';

type AvatarProps = {
  name: string;
  imgUrl?: string;
  class?: string;
  href?: string;
  size?: 'large' | 'default' | 'small';
  loading?: boolean;
};

export const Avatar: Component<AvatarProps> = (originProps) => {
  const props = mergeProps({ size: 'default' as const }, originProps);

  const sizeClass = createMemo<string | undefined>(() => {
    switch (props.size) {
      case 'small':
        return 'size-6 min-w-6 text-xs';
      case 'default':
        return 'size-8 min-w-8 text-sm';
      case 'large':
        return 'size-10 min-w-10 text-base';
    }
  });
  return (
    <Show when={!props.loading} fallback={<Skeleton class={cn('rounded-full', sizeClass(), props.class)} />}>
      <Show
        when={!props.imgUrl}
        fallback={<Image class={cn('rounded-full object-cover', sizeClass(), props.class)} src={props.imgUrl} alt={props.name} />}>
        <Show
          when={props.href}
          fallback={
            <div
              class={cn(
                'flex select-none items-center justify-center rounded-full border border-current-alpha-2 bg-current-alpha font-medium uppercase text-[--c]',
                sizeClass(),
                props.class
              )}
              style={{ '--c': stringToColor(props.name) }}>
              {props.name
                ?.split(' ')
                .slice(0, 2)
                .map((e) => e[0])
                .join('')}
            </div>
          }>
          <a href={props.href}>
            <div
              class={cn(
                'flex select-none items-center justify-center rounded-full border border-current-alpha-2 bg-current-alpha font-medium uppercase text-[--c]',
                sizeClass(),
                props.class
              )}
              style={{ '--c': stringToColor(props.name) }}>
              {props.name
                ?.split(' ')
                .slice(0, 2)
                .map((e) => e[0])
                .join('')}
            </div>
          </a>
        </Show>
      </Show>
    </Show>
  );
};

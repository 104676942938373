import { Show } from 'solid-js';
import IconRentCharges from '~/assets/images/leases/rent-charges.svg?component-solid';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import { Table, TableActions } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Accessor, Component } from 'solid-js';

type ChargesTableProps = {
  data: Accessor<MagicDoor.Api.HydratedRecurringLeaseChargeDto[]>;
  loading: Accessor<boolean>;
  showStatus?: boolean;
  onEdit?: (chargeId: string) => void;
  onDelete?: (chargeId: string) => void;
};

export const EnumLeaseStatus = {
  active: 'Active',
  ended: 'Ended',
  dueSoon: 'Due soon',
};

export const ChargesTable: Component<ChargesTableProps> = (props) => {
  const { t } = useLocalization();
  return (
    <div class="thinscroll overflow-x-auto">
      <Table
        class="text-left"
        data={props.data()}
        loading={props.loading()}
        columns={[
          {
            title: t('Description/memo'),
            headerClass: props.showStatus ? 'w-[26%]' : 'w-[46%]',
            class: '',
            render: (item) => (
              <div class="flex items-center gap-2">
                <IconRentCharges />
                <div class="flex-1">
                  <h4 class="font-medium normal-case text-text-level01">{item.description}</h4>
                  <p class="text-text-level03">{item.chargeFrequency}</p>
                </div>
              </div>
            ),
          },
          {
            title: t('Status'),
            headerClass: props.showStatus ? 'w-[20%]' : 'hidden',
            class: props.showStatus ? undefined : 'hidden',
            render: (item) =>
              props.showStatus && (
                <div class="flex flex-col items-start gap-1 text-xs">
                  <span
                    class="rounded-full px-2 py-1 capitalize"
                    classList={{
                      'bg-green/20 text-green': item.status === 'active',
                      'bg-error/20 text-error': item.status === 'dueSoon',
                      'bg-disabled/20 text-disabled': item.status === 'ended',
                    }}>
                    {t(EnumLeaseStatus[item.status])}
                  </span>
                  <span class="text-text-level03">
                    {t('Next payment')}: {dateFormat('', item.nextCharge)}
                  </span>
                </div>
              ),
          },
          {
            title: t('Chart of account'),
            headerClass: 'w-[20%]',
            class: 'text-sm',
            render: (item) => (
              <div>
                {item.chartOfAccount?.name || ''}
                <Show when={item?.companyCharge}>
                  <div class="text-xs text-link">{t('Company bill')}</div>
                </Show>
              </div>
            ),
          },
          {
            title: t('Amount'),
            headerClass: 'w-[10%] text-right',
            class: 'text-sm text-right',
            render: (item) => `${currency(item.amount)}`,
          },
          {
            title: t('Start and expire date'),
            headerClass: 'w-[18%]',
            class: 'text-sm',
            render: (item) => `${dateFormat('', item.startFrom)}${item.end ? ` - ${dateFormat('', item.end)}` : ''}`,
          },
          {
            headerClass: 'w-[6%]',
            class: 'text-sm',
            render: (item) => (
              <TableActions
                actions={[
                  {
                    label: t('Edit'),
                    icon: IconPencil,
                    href: item.id,
                    noScroll: true,
                  },
                  {
                    label: t('Delete'),
                    icon: IconDelete,
                    onClick: () => props.onDelete?.(item.id),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

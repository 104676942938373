import { IconCircleCheck, IconCircleX, IconClock } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

type StatusColProps = {
  status: MagicDoor.Api.ApplicationDecision;
};

const StatusCol: Component<StatusColProps> = (props) => {
  const { t } = useLocalization();
  const statusConfig = {
    noDecision: {
      icon: <IconClock class="size-3.5" />,
      bgColor: '#BEC6DB',
      label: t('No decision'),
    },
    ignored: {
      icon: <IconClock class="size-3.5" />,
      bgColor: '#BEC6DB',
      label: t('Ignored'),
    },
    approved: {
      icon: <IconCircleCheck class="size-3.5" />,
      bgColor: '#36CBAC',
      label: t('Approved'),
    },
    rejected: {
      icon: <IconCircleX class="size-3.5" />,
      bgColor: '#FF6188',
      label: t('Rejected'),
    },
  };
  return (
    <div
      class="flex w-24 items-center justify-center gap-0.5 rounded-full p-1 text-xs text-white"
      style={{
        'background-color': statusConfig[props.status].bgColor,
      }}>
      {statusConfig[props.status].icon}
      {statusConfig[props.status].label}
    </div>
  );
};

export default StatusCol;

import { createSignal } from 'solid-js';
import { cn } from '~/utils/classnames';

interface ToggleButtonProps {
  initialState?: boolean;
  onToggle?: (state: boolean) => void;
  isOn?: boolean;
}

function ToggleButton(props: ToggleButtonProps) {
  const [isOn, setIsOn] = createSignal<boolean>(props.initialState || false);

  const toggle: () => void = () => {
    setIsOn(!isOn());
    props.onToggle && props.onToggle(isOn());
  };

  return (
    <div
      onClick={toggle}
      class={cn(
        'h-6 w-11 cursor-pointer rounded-full p-0.5 transition-colors duration-300',
        isOn() ? 'bg-essential-colour' : 'bg-auxiliary-text'
      )}>
      <div
        class={cn('size-5 rounded-full bg-white shadow-md transition-transform duration-300', isOn() ? 'translate-x-5' : 'translate-x-0')}
      />
    </div>
  );
}

export default ToggleButton;

import { Show, For, Switch, Match } from 'solid-js';
import PlusIcon from '~/assets/images/common/plus.svg?component-solid';
import IconRemove from '~/assets/images/common/remove.svg?component-solid';
import IconAllProperties from '~/assets/images/owner-funds/allProperties.svg';
import IconSomeProperties from '~/assets/images/owner-funds/someProperties.svg';
import { Button } from '~/components/common/Buttons';
import { Empty } from '~/components/common/Empty';
import { Section } from '~/components/common/FormSectionTitle';
import { LabeledInputSearch } from '~/components/common/Inputs/LabeledInputSearch';
import { RingLoader } from '~/components/common/Loaders';
import { PropertyTitle } from '~/components/properties';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions, PropertiesCategory } from '~/contexts/local';
import { cn } from '~/utils/classnames';

const PropertiesCategoryOption = (props: { title: string; icon: string; isSelected: boolean; onClick: () => void }) => (
  <div
    onClick={() => props.onClick()}
    class={cn(
      'inline-flex grow cursor-pointer items-center rounded-lg border border-auxiliary-text p-3 transition-all hover:border-primary',
      props.isSelected && 'border-primary bg-[rgba(161,38,236,0.06)] text-primary'
    )}>
    <div class="relative mr-4 pr-3">
      <img src={props.icon} class="w-16" />
      <div class="absolute inset-y-[9px] left-full w-px bg-input-border" />
    </div>
    <span>{props.title}</span>
  </div>
);

export const ChoseProperty = () => {
  const { t } = useLocalization();
  const { store, onChangeSelectedProperties, allProperties, onChangePropertiesCategory } = useAddOwnerDistributions();

  const onSelectAllProperties = () => {
    onChangePropertiesCategory(PropertiesCategory.ALL);
    onChangeSelectedProperties(allProperties() ?? []);
  };

  const onSelectSomeProperties = () => {
    onChangePropertiesCategory(PropertiesCategory.SOME);
    onChangeSelectedProperties([null]);
  };

  const onSelectProperty = (index: number, id: string) => {
    const newProperties = [...store.selectedProperties];

    newProperties[index] = allProperties()?.find((property) => property.id === id) as MagicDoor.Api.PropertyDto;
    onChangeSelectedProperties(newProperties);
  };

  const onRemoveProperty = (index: number) => {
    onChangeSelectedProperties(store.selectedProperties.toSpliced(index, 1));
  };

  const onAddProperty = () => {
    onChangeSelectedProperties([...store.selectedProperties, null]);
  };

  return (
    <Section required title={t('Properties')} border class="px-[96px]">
      <Switch>
        <Match when={allProperties.loading}>
          <div class="flex items-center justify-center">
            <RingLoader color="#A126EC" text={`${t('Loading')}...`} />
          </div>
        </Match>
        <Match when={!allProperties.loading && !allProperties()?.length}>
          <Empty description={t('There are currently no properties')} />
        </Match>
        <Match when={!allProperties.loading && allProperties()?.length}>
          <div class="grid grid-cols-2 gap-4">
            <PropertiesCategoryOption
              title={t('All properties')}
              icon={IconAllProperties}
              isSelected={store.selectedCategory === PropertiesCategory.ALL}
              onClick={onSelectAllProperties}
            />
            <PropertiesCategoryOption
              title={t('Some properties')}
              icon={IconSomeProperties}
              isSelected={store.selectedCategory === PropertiesCategory.SOME}
              onClick={onSelectSomeProperties}
            />
            <Show when={store.selectedCategory === PropertiesCategory.SOME}>
              <For each={store.selectedProperties}>
                {(item, index) => (
                  <>
                    <LabeledInputSearch
                      allowClear={false}
                      required
                      class="min-h-[42px]"
                      label={t(`Property`)}
                      options={allProperties()}
                      renderItem={(item) => <PropertyTitle feedbackIconReadonly property={item} />}
                      placeholder={t('Select property')}
                      value={item?.id}
                      onSelect={(val) => onSelectProperty(index(), val as string)}
                    />
                    <Show
                      when={index() === store.selectedProperties.length - 1}
                      fallback={
                        <div class="ml-5 flex items-center">
                          <IconRemove class="cursor-pointer text-link" onClick={() => onRemoveProperty(index())} />
                        </div>
                      }>
                      <div class="flex items-center">
                        <Button color="link" variant="outlined" onClick={onAddProperty}>
                          <PlusIcon class="hidden sm:block" /> {t('Add a new property')}
                        </Button>
                      </div>
                    </Show>
                  </>
                )}
              </For>
            </Show>
          </div>
        </Match>
      </Switch>
    </Section>
  );
};

import { BaseRestRepository } from './baseRestRepository';

const LEASE_TEMPLATE_CATEGORY_URL = '/api/lease-template/categories';

export class LeaseTemplateCategoryRepository extends BaseRestRepository {
  public async getLeaseTemplateCategories(): Promise<MagicDoor.Api.LeaseTemplateCategoryDto[]> {
    const response = await this.fetchWithAuth(LEASE_TEMPLATE_CATEGORY_URL);
    return this.getJsonResponse(response);
  }

  public async createLeaseTemplateCategory(
    payload: MagicDoor.Api.CreateLeaseTemplateCategoryDto
  ): Promise<MagicDoor.Api.LeaseTemplateCategoryDto> {
    const response = await this.fetchWithAuth(LEASE_TEMPLATE_CATEGORY_URL, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async updateLeaseTemplateCategory(
    id: string,
    payload: MagicDoor.Api.CreateLeaseTemplateCategoryDto
  ): Promise<MagicDoor.Api.LeaseTemplateCategoryDto> {
    const url = `${LEASE_TEMPLATE_CATEGORY_URL}/${id}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async deleteLeaseTemplateCategory(id: string): Promise<void> {
    const url = `${LEASE_TEMPLATE_CATEGORY_URL}/${id}`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }
}

import { MarkNewPending, MarkAll, MarkApproved, MarkRejected } from './assets';
import type { Component } from 'solid-js';
import type { RentalApplicationFilter } from '~/repositories/rentalApplicationRepository';

type Filter = {
  label: string;
  mark: Component<{ class?: string }>;
  key: keyof MagicDoor.Api.RentalApplicationCountsDto;
  filter: RentalApplicationFilter;
};

export const statusFilters = [
  {
    label: 'New pending applications',
    mark: MarkNewPending,
    key: 'newRentalApplications',
    filter: { new: true, draft: false, hasBeenSubmitted: true },
  },
  {
    label: 'All applications',
    mark: MarkAll,
    key: 'allApplications',
    filter: { hasBeenSubmitted: true },
  },
  {
    label: 'Approved',
    mark: MarkApproved,
    key: 'approvedApplications',
    filter: { decisions: ['approved'], hasBeenSubmitted: true },
  },
  {
    label: 'Rejected',
    mark: MarkRejected,
    key: 'deniedApplications',
    filter: { decisions: ['rejected'], hasBeenSubmitted: true },
  },
] as Filter[];

import { createMemo } from 'solid-js';
import { Panel } from '~/components/common/Panels';
import { PhotosEditor } from '~/components/photos';
import { useLocalization, useProperties } from '~/contexts/global';
import { getImageUrl } from '~/utils/file';
import type { Component } from 'solid-js';

export const PropertyPhotos: Component = () => {
  const { t } = useLocalization();
  const { current, addPropertyImage, deletePropertyImage } = useProperties();

  const images = createMemo(() => {
    const property = current();
    if (!property?.images) return [];
    return property.images.map((i) => ({ id: i.fileId, url: getImageUrl('property', property.id, i.fileId) })) || [];
  });

  const handleUpload = async (files: File[]) => {
    const propertyId = current()?.id;
    if (propertyId == null) return;

    for (const file of files) {
      await addPropertyImage(propertyId, { file });
    }
  };

  const handleDelete = async (fileId: string) => {
    const propertyId = current()?.id;
    if (propertyId == null) return;
    await deletePropertyImage(propertyId, fileId);
  };

  return (
    <Panel title={t('Property photos')} class="mx-8">
      <PhotosEditor photos={images()} onUpload={handleUpload} onDelete={handleDelete} />
    </Panel>
  );
};

import { createSignal, createMemo, Show } from 'solid-js';
import ImgMoveoutBg from '~/assets/images/tenant/moveoutBg.png';
import { ChartOfAccountSelect } from '~/components/chart-of-accounts';
import { Panel } from '~/components/common/Panels';
import { MessageModal } from '~/components/modals/MessageModal';
import { SearchListInput } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { ChargesTable } from './ChargesTable';
import type { Component, Accessor, JSX } from 'solid-js';

type ChargesPanelProps = {
  title?: string;
  actions?: JSX.Element;
  loading: Accessor<boolean>;
  charges: MagicDoor.Api.HydratedRecurringLeaseChargeDto[];
  showStatus?: boolean;
  onDelete: (chargeId: string) => Promise<void> | void;
  getPrintContainer?: (container: HTMLDivElement) => void;
  ref?: any;
};

export const ChargesPanel: Component<ChargesPanelProps> = (props) => {
  const { t } = useLocalization();
  const [selectedAccountId, setSelectedAccountId] = createSignal<string>('');
  const [searchText, setSearchText] = createSignal('');
  const [deletingChargeId, setDeletingChargeId] = createSignal<string | null>(null);
  const [deleting, setDeleting] = createSignal<boolean>(false);

  const filterCharges = createMemo(
    () =>
      props.charges.filter((charge) => {
        const isSelectedAccountIdPassed = !selectedAccountId() ? true : charge.chartOfAccountId === selectedAccountId();
        const isSearchTextPassed = !searchText() ? true : charge.description?.toUpperCase().includes(searchText().toUpperCase());

        return isSelectedAccountIdPassed && isSearchTextPassed;
      }) ?? []
  );

  const deletingCharge = createMemo(() => props.charges.find((charge) => charge.id === deletingChargeId()));

  const handleDelete = async () => {
    const chargeId = deletingChargeId();
    if (chargeId == null) return;
    setDeleting(true);
    await props.onDelete(chargeId);
    setDeletingChargeId(null);
    setDeleting(false);
  };

  props.ref &&
    props.ref({
      getData() {
        return filterCharges();
      },
    });

  return (
    <Panel>
      <div class="flex items-center justify-between px-6 py-4">
        <Show when={props.title}>
          <h3 class="mr-auto text-lg font-semibold">{props.title}</h3>
        </Show>
        <div class="inline-flex items-center gap-2">
          <SearchListInput isAlwaysExpanded={false} onChange={setSearchText} />
          <ChartOfAccountSelect class="py-1.5" value={selectedAccountId()} onChange={setSelectedAccountId} withAll />
        </div>
        <Show when={props.actions}>{props.actions}</Show>
      </div>
      <div ref={(e) => props?.getPrintContainer?.(e)}>
        <ChargesTable
          data={filterCharges}
          loading={props.loading}
          onDelete={(chargeId) => setDeletingChargeId(chargeId)}
          showStatus={props.showStatus}
        />
      </div>
      <MessageModal
        title={t('Delete rent charges')}
        visible={deletingChargeId() != null}
        loading={deleting()}
        onClose={() => setDeletingChargeId(null)}
        onDone={handleDelete}>
        <img class="h-24 w-full" src={ImgMoveoutBg} alt={t('Move out')} />
        <div class="p-6 text-sm leading-5 text-text-level02">
          {t('Please confirm whether to delete')}
          <span class="ml-1 font-semibold normal-case">“{deletingCharge()?.description}”</span>
        </div>
      </MessageModal>
    </Panel>
  );
};

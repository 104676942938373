import { Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { FeedbackIcon } from '~/components/common/FeedbackIcon';
import { FeedbackGroup } from '~/components/common/FeedbackIcon/Group';
import { Skeleton } from '~/components/ui';
import { cn } from '~/utils/classnames';
import { pick } from '~/utils/tool';
import type { ParentProps } from 'solid-js';
import type { FeedbackIconProps } from '~/components/common/FeedbackIcon';

export type TitleWithFeedbackIconProps = {
  feedbackIconReadonly?: boolean;
  feedbackIconMode?: 'row' | 'col';
  loading?: boolean;
} & FeedbackIconProps;

export const pickProps = (props: Partial<TitleWithFeedbackIconProps>) =>
  pick(props, ['feedbackIconReadonly', 'feedbackIconMode', 'loading']);

export const TitleWithFeedbackIcon = (props: ParentProps<TitleWithFeedbackIconProps>) => {
  return (
    <Show when={!props.loading} fallback={<Skeleton class="h-8 w-32" />}>
      <Dynamic
        component={props.feedbackIconMode === 'col' ? 'div' : FeedbackGroup}
        class={cn(
          'flex min-w-48 flex-row items-center gap-2',
          {
            'flex-col items-start': props.feedbackIconMode === 'col',
          },
          props.class
        )}>
        {props.children}

        <FeedbackIcon readOnly={props.feedbackIconReadonly} {...props} />
      </Dynamic>
    </Show>
  );
};

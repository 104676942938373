import { Show, createSignal } from 'solid-js';
import Tab from '~/components/layouts/Tabs';
import VendorBillSkeleton from './VendorBillSkeleton';
import VendorInformationSkeleton from './VendorInformationSkeleton';
import VendorMemoSkeleton from './VendorMemoSkeleton';
import VendorSummarySkeleton from './VendorSummarySkeleton';
import VendorTransactionsSkeleton from './VendorTransactionsSkeleton';
import VendorWorkOrderSkeleton from './WorkOrderSkeleton';

const VendorDetailsViewSkeleton = () => {
  const [activeTab, setActiveTab] = createSignal('general');

  const renderActiveTab = () => {
    return (
      <>
        <Show when={activeTab() === 'general'}>
          <div class="flex flex-col gap-y-4">
            <VendorSummarySkeleton />
            <VendorBillSkeleton />
            <div class="flex gap-x-4">
              <div class="w-3/5">
                <VendorWorkOrderSkeleton />
              </div>
              <div class="w-2/5">
                <VendorTransactionsSkeleton />
              </div>
            </div>
          </div>
        </Show>
      </>
    );
  };

  return (
    <div class="flex gap-4 p-8">
      <div class="hidden w-1/4 flex-col gap-y-4 xs:flex">
        <div class="flex flex-col gap-y-4">
          <VendorInformationSkeleton />
          <VendorMemoSkeleton />
        </div>
      </div>

      <div class="flex w-full flex-col gap-4 xs:w-3/4">
        <Tab
          tabs={[
            { key: 'general', title: 'General' },
            { key: 'bills', title: 'Bills' },
            { key: 'activityLogs', title: 'Activity logs' },
            { key: 'communications', title: 'Communications' },
            { key: 'files', title: 'Files' },
            { key: 'notes', title: 'Notes' },
          ]}
          activeTab={activeTab()}
          onTabChange={setActiveTab}
        />
        <div>{renderActiveTab()}</div>
      </div>
    </div>
  );
};

export default VendorDetailsViewSkeleton;

import { createSignal, createMemo, Show, createEffect } from 'solid-js';
import DetailIcon from '~/assets/images/common/detail.svg?component-solid';
import IconCall from '~/assets/images/contact/call.svg?component-solid';
import IconEmail from '~/assets/images/contact/email.svg?component-solid';
import { Panel } from '~/components/common/Panels';
import { TenantTitle } from '~/components/tenants';
import { Table, TableActions } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLease } from '~/contexts/local';
import { dateFormat } from '~/utils/date';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type MovedOutTenantsTableProps = {
  tenants: MagicDoor.Api.HydratedLeaseTenantDto[];
};

export const MovedOutTenantsTable: Component<MovedOutTenantsTableProps> = (props) => {
  const { t } = useLocalization();
  const { lease } = useLease();

  const [currentMovedOutTenants, setCurrentMovedOutTenants] = createSignal<MagicDoor.Api.HydratedLeaseTenantDto[]>([]);
  const columns = createMemo<TableColumns<MagicDoor.Api.HydratedLeaseTenantDto>>(() => [
    {
      title: t('Name'),
      render: (item) => <TenantTitle tenant={item.tenant} />,
    },
    {
      title: t('Contact'),
      render: (item) => (
        <div class="space-y-1 text-xs">
          <Show when={item.tenant?.phone}>
            <div class="flex items-center space-x-1">
              <IconCall class="text-text-level03" />
              <span>{item.tenant?.phone}</span>
            </div>
          </Show>
          <Show when={item.tenant?.email}>
            <div class="flex items-center space-x-1">
              <IconEmail class="text-text-level03" />
              <span>{item.tenant?.email}</span>
            </div>
          </Show>
        </div>
      ),
    },
    {
      title: t('Start/End date'),
      render: (item) => (
        <span>
          {dateFormat('MM/DD/YYYY', item.moveIn)} - {dateFormat('MM/DD/YYYY', item.moveOut)}
        </span>
      ),
    },
    {
      title: '',
      class: 'relative',
      render: (item) => (
        <div class="flex justify-end">
          <TableActions
            actions={[
              {
                label: t('Detail'),
                icon: DetailIcon,
                href: `/users/tenants/${item.tenant?.id}`,
              },
            ]}
          />
        </div>
      ),
    },
  ]);

  createEffect(() => {
    if (lease()?.ended === true) {
      setCurrentMovedOutTenants(props.tenants);
    } else {
      setCurrentMovedOutTenants(props.tenants.filter((item: MagicDoor.Api.HydratedLeaseTenantDto) => item.isMovedOut === true));
    }
  });

  return (
    <Panel title={t('Past tenants')}>
      <Table columns={columns()} data={currentMovedOutTenants()} rowLink={(item) => `/users/tenants/${item.tenant.id}`} />
    </Panel>
  );
};

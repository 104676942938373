import { UseCase } from './useCase';
import type { SetAnnotationTextRequest } from './types/setAnnotationTextRequest';

export class SetAnnotationTextUseCase extends UseCase {
  public async runLogic(request: SetAnnotationTextRequest) {
    if (request.id != this.state.selectedAnnotation?.id) {
      return;
    }
    if (this.state.selectedAnnotation) {
      const selectedAnnotation = this.state.selectedAnnotation;
      selectedAnnotation.text = request.value;
    }
  }
}

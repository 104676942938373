import UnderConstruction from '~/components/common/UnderConstruction';
import { CompanyRevenueOverview } from './CompanyRevenueOverview';
import { NetOperatingIncome } from './NetOperatingIncome';
import { ReceivablePayableListView } from './ReceivablePayableListView';

export const CompanyRevenueTab = () => {
  return (
    <div class="relative flex flex-col gap-4">
      <div class="flex gap-4">
        <div class="w-2/3">
          <CompanyRevenueOverview />
        </div>
        <div class="w-1/3">
          <NetOperatingIncome />
        </div>
      </div>
      <ReceivablePayableListView />
      <UnderConstruction />
    </div>
  );
};

import { Show, createMemo } from 'solid-js';
import CashDepositIcon from '~/assets/images/leases/cashDeposit.svg?component-solid';
import InsuranceIcon from '~/assets/images/leases/insurance.svg?component-solid';
import NoDepositIcon from '~/assets/images/leases/noDeposit.svg?component-solid';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { OptionButton } from '~/components/common/OptionButton';
import { useLocalization } from '~/contexts/global';
import { minNumberValidator } from '~/utils/validations';
import type { NewLeaseStepProps } from './types';
import type { Component } from 'solid-js';

export const Deposits: Component<NewLeaseStepProps> = (props) => {
  const { t } = useLocalization();
  const hasSubmit = createMemo(() => props.isSubmit);
  const amountError = createMemo(() => minNumberValidator(props.model.deposit as number, 'Amount', 1, true));
  // const [depositChecked, setDepositChecked] = createSignal(false);

  return (
    <div>
      <h4 class="border-b border-partingline py-3 text-base font-semibold">{t('Deposits')}</h4>
      <div class="grid grid-cols-3  gap-x-4 gap-y-6 py-4">
        <OptionButton
          class="h-20"
          Icon={NoDepositIcon}
          label={t('No deposit')}
          isActive={props.model.deposit === undefined}
          onClick={() => props.onUpdate({ deposit: undefined })}
        />
        <OptionButton
          class="h-20"
          Icon={CashDepositIcon}
          label={t('Cash deposit')}
          isActive={props.model.deposit !== undefined}
          onClick={() => props.onUpdate({ deposit: props.model.rent?.amount })}
        />
        <OptionButton class="h-20" Icon={InsuranceIcon} label={t('Lighthouse insurance')} disabled />
        <Show when={props.model.deposit !== undefined}>
          <div class="col-span-3 flex flex-col gap-4">
            {/* <div class="flex items-center rounded-2xl bg-essential-colour/5 px-4 py-2 text-base normal-case text-text-level02">
              <Checkbox
                label={t('set the deposit amount equals to one month of rent')}
                showLabel
                onInput={(checked) => {
                  if (checked) {
                    props.onUpdate({ deposit: Number(props.model.rent?.amount) });
                  }
                  setDepositChecked(checked);
                }}
                checked={depositChecked()}
              />
            </div> */}
            <LabeledNumberInput
              class="col-start-2"
              prepend="$"
              label={t('Deposit amount')}
              value={props.model.deposit}
              onInput={(value) => {
                // setDepositChecked(Number(props.model.rent?.amount) === Number(value));
                props.onUpdate({ deposit: Number(value) });
              }}
              error={hasSubmit() ? amountError() : undefined}
              required
              placeholder={t('Please enter')}
            />
          </div>
        </Show>
      </div>
    </div>
  );
};

import { useNavigate, useParams } from '@solidjs/router';
import { Show, createEffect, createSignal } from 'solid-js';
import { OwnerDropdownSummary } from '~/components/owner/OwnerDropdownSummary';
import { VendorSummary } from '~/components/vendors/VendorSummary';
import { useLocalization, useVendors } from '~/contexts/global';
import { useViolations } from '~/contexts/local';
import { CreateLeaseNotificationModal } from '~/pages/violations/create-notification-modal/CreateLeaseNotificationModal';
import { CreateOwnerNotificationModal } from '~/pages/violations/create-notification-modal/CreateOwnerNotificationModal';
import { CreateVendorNotificationModal } from '~/pages/violations/create-notification-modal/CreateVendorNotificationModal';
import { CreateWorkOrderModal } from '~/pages/violations/create-work-order-modal';
import { cn } from '~/utils/classnames';
import { RelationDetails } from './RelationDetails';
import { WorkOrderDetails } from './WorkOrderDetails';
import type { Component } from 'solid-js';

export const ViolationSummary: Component<{
  class?: string;
  loading?: boolean;
  violation?: MagicDoor.Api.AssociationViolationDto;
  modalType?: string;
}> = (props) => {
  const { t } = useLocalization();

  const navigate = useNavigate();
  const params = useParams<{ violationId: string; workOrderId?: string }>();

  const { setCurrentId, current } = useVendors();
  const { violation, createWorkOrder } = useViolations();

  const [showCreateWorkOrder, setShowCreateWorkOrder] = createSignal<boolean>(false);
  const [currentWorkOrderId, setCurrentWorkOrderId] = createSignal<string | undefined>(undefined);

  const navigateToNotification = (type: string, workOrderId?: string) => {
    if (type === 'vendor-notification' && workOrderId) {
      setCurrentWorkOrderId(workOrderId);
      console.log('workOrderId', workOrderId);
      navigate(`/associations/violations/${params.violationId}/${type}/${workOrderId}`, { replace: true });
    } else {
      navigate(`/associations/violations/${params.violationId}/${type}`, { replace: true });
    }
  };

  const closeModal = () => {
    navigate(`/associations/violations/${params.violationId}`, { replace: true });
  };

  const onCreateWorkOrder = () => {
    setShowCreateWorkOrder(true);
  };

  const onCreateWorkOrderSubmit = async (data: any) => {
    await createWorkOrder(data);
    setShowCreateWorkOrder(false);
  };

  createEffect(() => {
    if (props.violation?.hoaVendorId) {
      setCurrentId(props.violation?.hoaVendorId);
    }
  });

  createEffect(() => {
    const path = location.pathname;
    const match = path.match(/\/vendor-notification\/(\w+)/);
    if (match) {
      setCurrentWorkOrderId(match[1]);
    } else {
      setCurrentWorkOrderId(undefined);
    }
  });

  return (
    <div class={cn('flex flex-col gap-4 text-sm', props.class)}>
      <RelationDetails
        loading={props.loading}
        violation={props.violation}
        onSendLeaseNotification={() => navigateToNotification('lease-notification')}
      />

      <Show when={violation()?.workOrders}>
        <WorkOrderDetails
          loading={props.loading}
          workOrders={violation()?.workOrders}
          onCreateWorkOrder={onCreateWorkOrder}
          onSendVendorNotification={(workOrderId) => navigateToNotification('vendor-notification', workOrderId)}
        />
      </Show>

      <Show when={violation()?.owners}>
        <OwnerDropdownSummary loading={props.loading} owners={violation()?.owners} title={t('Owners')} isAssociationViolation />
      </Show>

      <Show when={violation()?.hoaVendorId}>
        <VendorSummary loading={current.loading} vendor={current()} isHoaVendor />
      </Show>

      <Show when={props.modalType === 'lease-notification'}>
        <CreateLeaseNotificationModal onClose={closeModal} />
      </Show>

      <Show when={props.modalType === 'owner-notification'}>
        <CreateOwnerNotificationModal onClose={closeModal} />
      </Show>

      <Show when={props.modalType === 'vendor-notification'}>
        <CreateVendorNotificationModal onClose={closeModal} workOrderId={(currentWorkOrderId() || violation()?.workOrders?.[0].id) ?? ''} />
      </Show>

      <Show when={showCreateWorkOrder()}>
        <CreateWorkOrderModal
          visible={showCreateWorkOrder()}
          onClose={() => setShowCreateWorkOrder(false)}
          onSubmit={onCreateWorkOrderSubmit}
        />
      </Show>
    </div>
  );
};

import { useNavigate } from '@solidjs/router';
import dayjs from 'dayjs';
import { createSignal, createEffect, onMount } from 'solid-js';
import { FormWrapper } from '~/components/common/BetterForm/Form';
import { useForm } from '~/components/common/BetterForm/context';
import { Button } from '~/components/common/Buttons';
import {
  CategoryContentSection,
  CategoryComponentSection,
  getErrorAndProgressProps,
  CategoryComponentWrapper,
} from '~/components/common/Category';
import { FileRepository } from '~/components/common/Upload/request';
import { CancelWithConfirmation } from '~/components/modals';
import MiddleModal from '~/components/modals/MiddleModal';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequests } from '~/contexts/local';
import { MAINTENANCE_REQUEST_URL } from '~/repositories/maintenanceRequestRepository';
import { AssignedTo } from './AssignedTo';
import { useAddOrEditMaintenanceModalContext } from './Context';
import { EssentialInfomation } from './EssentialInfomation';
import { SelectLeaseOrTenant } from './SelectLeaseOrTenant';
import { StatusAndPriority } from './StatusAndPriority';
import type { Section } from '~/components/common/Category';

const fileRepository = new FileRepository();

export const AddMaintenanceRequestForm = () => {
  const navigate = useNavigate();

  const { t } = useLocalization();
  const { addMaintenanceRequest } = useMaintenanceRequests();
  const { store, setStore } = useAddOrEditMaintenanceModalContext();

  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);

  let scrollContainer!: HTMLDivElement;
  const form = useForm();

  const close = () => navigate('/maintenance/maintenance-request', { scroll: false, replace: true });

  const sections: Section[] = [
    {
      key: 'essential-information',
      name: t('Essential information'),
      component: EssentialInfomation,
      fields: ['description'],
    },
    {
      key: 'select-lease-or-tenant',
      name: t('Select lease or tenant'),
      component: SelectLeaseOrTenant,
      fields: ['leaseId', 'requesterId'],
    },
    {
      key: 'status-and-priority',
      name: t('Status and priority'),
      component: StatusAndPriority,
      fields: ['status', 'due', 'permissionToEnter', 'urgency'],
    },
    {
      key: 'assigned-to',
      name: t('Assigned to'),
      component: () => <AssignedTo isCreate />,
      fields: ['assignedPropertyManagerId', 'internalDescription', 'disableAi'],
    },
  ];

  let oldDataStr = '';
  onMount(() => {
    oldDataStr = JSON.stringify(form.formStore);
  });
  createEffect(() => {
    const newDataStr = JSON.stringify(form.formStore);
    setShowCloseConfirmation(newDataStr !== oldDataStr);
  });

  async function addMaintenanceRequestHandler() {
    try {
      setStore({ isSubmiting: true });
      const response = await addMaintenanceRequest({
        ...(form.formStore as MagicDoor.Api.MaintenanceRequestDto),
      });
      const newFiles = store.newFiles || [];
      const uploadFileUrl = `${MAINTENANCE_REQUEST_URL}/${response.id}/files`;
      for (const file of newFiles) {
        await fileRepository.uploadFile(uploadFileUrl, file);
      }
      form.resetFields();
      navigate(`/maintenance/maintenance-request/${response.id}`, { replace: true });
      toast.success(t('{name} has been added successfully', { name: 'MaintenanceRequest' }));
    } finally {
      setStore({ isSubmiting: false });
    }
  }

  const closeModal = () => {
    form.resetFields();
    close();
  };

  return (
    <CategoryComponentWrapper getScrollElement={() => scrollContainer} sections={sections}>
      <MiddleModal
        confirmation={showCloseConfirmation()}
        onClose={closeModal}
        confirmationTitle={t('Are you sure you want to cancel?')}
        confirmationMessage={t('If you cancel the addition, the information already filled in cannot be saved')}>
        <div class="relative flex  pb-20">
          <CategoryComponentSection
            {...getErrorAndProgressProps(form)}
            headerTitle={
              <div class="align-center mb-2 mt-[-12px] flex justify-center text-lg font-semibold text-text-level01">
                {t('Maintenance request')}
              </div>
            }
            categoryFooterTitle={<span class="text-base">{t('Maintenance request')}</span>}
            class="mt-8 w-[330px]"
          />
          <FormWrapper
            initialValues={{
              permissionToEnter: true,
              status: 'pending',
              due: dayjs().add(7, 'day').format('YYYY-MM-DD'),
              urgency: 'medium',
            }}
            defaultForm={form}
            onFormSubmit={() => {
              addMaintenanceRequestHandler();
            }}
            class="grow overflow-hidden  px-2 pb-8">
            <div class="thinscroll h-modalMd overflow-auto px-2" ref={scrollContainer}>
              <CategoryContentSection />
            </div>

            <div class="absolute inset-x-0 bottom-0 mt-8 flex h-20 items-center justify-end border-t border-t-partingline px-8">
              <div class="flex gap-2">
                <CancelWithConfirmation onConfirm={closeModal} message={t('Are you sure you want to cancel?')} />
                <Button loading={store.isSubmiting} type="submit">
                  <div class="hidden lg:block">{t(`Create maintenance request`)}</div>
                  <div class="block lg:hidden">{t(`Create`)}</div>
                </Button>
              </div>
            </div>
          </FormWrapper>
        </div>
      </MiddleModal>
    </CategoryComponentWrapper>
  );
};

import { onMount, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import TextOverflow from '~/components/common/TextOverflow';
import { PropertyTitle } from '~/components/properties/PropertyTitle';
import { ListView, toast } from '~/components/ui';
import { useLocalization, usePropertyCredit } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import UnitItem from '~/pages/bills/components/UnitItem';
import { emptyPlaceholder } from '~/utils/constant';

export const PropertyCreditsTab = () => {
  const { t } = useLocalization();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('PropertyCreditListView');
  const { filtered, setFilter, deletePropertyCredit } = usePropertyCredit();

  onMount(() => setFilter({ page: page(), pageSize: pageSize() }));

  return (
    <div class="relative">
      <ListView
        onChange={({ page, pageSize }) => {
          setPage(page);
          setPageSize(pageSize);
          setFilter({ page, pageSize });
        }}
        title={t('Property credits')}
        totalPages={filtered()?.totalPages}
        page={filtered()?.currentPage}
        pageSize={filtered()?.pageSize}
        data={filtered()?.items}
        loading={filtered.loading}
        skeletonSize={10}
        titleActions={
          <div class="flex gap-2">
            <Button href={'add'} class="font-medium capitalize">
              <IconPlus class="size-5 text-white" />
              {t('Add property credit')}
            </Button>
          </div>
        }
        columns={[
          {
            title: t('Date'),
            render: (item) => <div class="w-24 text-text-level01">{item.dueDate}</div>,
          },
          {
            title: t('Property'),
            render: (item) => (
              <Show when={item.property} fallback={<span class="text-xs capitalize text-text-level03">{emptyPlaceholder}</span>}>
                <PropertyTitle property={item.property} />
              </Show>
            ),
          },
          {
            title: t('Unit'),
            render: (item) => (
              <Show when={item.unit} fallback={<span class="text-xs capitalize text-text-level03">{emptyPlaceholder}</span>}>
                <UnitItem unit={item.unit} />
              </Show>
            ),
          },
          {
            title: t('Memo'),
            render: (item) => (
              <div>
                <Show when={item.memo || item.lineItems[0].description} fallback={<span class="text-xs text-text-level03">--</span>}>
                  <TextOverflow text={(item.memo || item.lineItems[0].description) ?? ''} />
                </Show>
              </div>
            ),
          },
          {
            title: t('Chart of account'),
            render: (item) => <div class="w-24 text-text-level01">{item.chartOfAccountName}</div>,
          },
          {
            title: t('Amount'),
            headerClass: 'text-right w-36',
            class: 'text-right',
            render: (item) => <CnMoney color="normal" money={item.totalAmount} />,
          },
        ]}
        actions={(item) => [
          {
            label: t('Delete'),
            icon: IconDelete,
            onClick: async () => {
              await deletePropertyCredit(item.id);
              toast.success(t('{name} has been deleted successfully', { name: t('Property credit') }));
            },
          },
        ]}
      />
    </div>
  );
};

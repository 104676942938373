import { Route, Routes, useParams } from '@solidjs/router';
import { createMemo, onMount, Show } from 'solid-js';
import { AIScoreCell } from '~/components/common/AiScoreCell';
import { Avatar } from '~/components/common/Avatar';
import { Panel } from '~/components/common/Panels';
import { RentalApplicationEmpty } from '~/components/rental-application/RentalApplicationEmpty';
import StatusCol from '~/components/rental-application/StatusCol';
import { getTenantFullName, getTenantShortName } from '~/components/tenants/utils';
import { Table } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { AddUnit } from '~/pages/units/add-unit';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type ApplicationsTableProps = {
  class?: string;
};

const filterStatusKeys = ['noDecision', 'approved'];

export const ApplicationsTable: Component<ApplicationsTableProps> = (props) => {
  const { t } = useLocalization();
  const { unitId, propertyId, portfolioId } = useParams();
  const { getRentalApplications, rentalApplications, isLoading } = useRentalApplication();

  onMount(() => {
    getRentalApplications({
      unitIds: [unitId],
    });
  });

  const filteredRentalApplications = createMemo(() =>
    rentalApplications()?.filter((app) => filterStatusKeys.includes(app.applicationDecision))
  );

  const columns = createMemo<TableColumns<MagicDoor.Api.RentalApplicationListDto>>(() => [
    {
      title: t('Applicant'),
      render: (item) => (
        <div class="flex items-center space-x-2 text-sm">
          <Show when={item.firstName}>
            <Avatar name={getTenantShortName({ firstName: item.firstName, lastName: item.lastName })} />
          </Show>
          <div class="flex flex-col">
            <span>{getTenantFullName({ firstName: item.firstName, lastName: item.lastName })}</span>
            <span class="text-xs text-text-level03">{dateFormat('', item.createdAt)}</span>
          </div>
        </div>
      ),
    },
    {
      title: t('Status'),
      render: (item) => <StatusCol status={item.applicationDecision} />,
    },
    {
      title: t('Applied unit'),
      render: (item) => {
        const unitNamesStr = createMemo(() => {
          const unitNames =
            item.interests?.map((interest: { unit: { name: string } }) => interest.unit?.name).filter((name: string) => name) || [];
          return unitNames.length > 0 ? unitNames.join(', ') : emptyPlaceholder;
        });

        return <span class="text-sm">{unitNamesStr()}</span>;
      },
    },
    {
      title: t('AI score'),
      render: (item) => <AIScoreCell score={item.aiApplicationScore} />,
    },
    {
      title: t('Annual income'),
      render: (item) => <span class="text-sm">{currency(item.incomeInformation?.annualIncome || 0, { minimumFractionDigits: 2 })}</span>,
    },
  ]);

  return (
    <>
      <div class="text-left">
        <Panel title={t('Applications')} class={props.class}>
          <Show when={filteredRentalApplications()?.length || isLoading()}>
            <div class="thinscroll overflow-x-auto">
              <Table
                columns={columns()}
                loading={isLoading()}
                data={filteredRentalApplications()}
                rowLink={(item) => `/portfolios/${portfolioId}/properties/${propertyId}/application-details/${item.id}`}
              />
            </div>
          </Show>
          <Show when={!filteredRentalApplications()?.length && !isLoading()}>{RentalApplicationEmpty()}</Show>
        </Panel>
      </div>
      <Routes>
        <Route path="/edit-unit" component={AddUnit} />
      </Routes>
    </>
  );
};

import { useLocation, useNavigate, useParams } from '@solidjs/router';
import { For, Show } from 'solid-js';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import IconSummaryBg from '~/assets/images/units/summaryBg.svg';
import { DeactivatedFlag } from '~/components/common';
import { WhiteLinkButton } from '~/components/common/Buttons/LinkButton';
import DisplayAddress from '~/components/common/DisplayAddress';
import { FeedbackIcon } from '~/components/common/FeedbackIcon';
import { HeadLayout } from '~/components/head-layout';
import { LeasePeriod } from '~/components/leases';
import {
  isDraftLease,
  isEndedLease,
  isEvictionLease,
  isExpiredLease,
  isFutureLease,
  isLeased,
  isMonthToMonth,
  leaseStatusText,
} from '~/components/leases/utils';
import { PropertyImage, PropertyType } from '~/components/properties';
import { formatTenantNames } from '~/components/tenants/utils';
import { IconChevronDown, Popover, TabLinks } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import type { Component, Setter } from 'solid-js';
import type { TabLinkItem } from '~/components/ui';
type UnitSummaryProps = {
  leases?: MagicDoor.Api.HydratedLeaseDto[];
  unit?: MagicDoor.Api.HydratedUnitDto;
  property?: MagicDoor.Api.PropertyDto;
  unitNavbarItems?: TabLinkItem[];
  leaseNavbarItems?: TabLinkItem[];
  leaseNavbarDisabled?: boolean;
  currentLease?: MagicDoor.Api.HydratedLeaseDto;
  showLeaseSelect?: boolean;
  setCurrentLease?: Setter<MagicDoor.Api.HydratedLeaseDto | undefined>;
  source?: 'unit' | 'lease';
};

export const UnitSummary: Component<UnitSummaryProps> = (props) => {
  const { t } = useLocalization();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const bgColor = () => {
    if (isEndedLease(props.currentLease) || isFutureLease(props.currentLease) || !props.unit) {
      return '#898D97';
    } else if (isDraftLease(props.currentLease)) {
      return '#A126EC';
    } else if (isEvictionLease(props.currentLease)) {
      return '#F76B6B';
    } else if (isMonthToMonth(props.currentLease)) {
      return '#FA8511';
    } else if (isExpiredLease(props.currentLease)) {
      return '#C02BB1';
    } else if (isLeased(props.currentLease) || (props.unit && props.source === 'unit')) {
      return '#36CBAC';
    }
  };

  return (
    <div class="overflow-hidden text-white" style={{ '--bg-color': bgColor() }}>
      <div class="rounded-lg bg-[--bg-color]">
        <HeadLayout type={props.source === 'unit' ? 'unit' : 'lease'} backLink={() => navigate(-1)}>
          <div class="flex flex-wrap items-center bg-right bg-no-repeat" style={{ 'background-image': `url(${IconSummaryBg})` }}>
            <div class="flex shrink-0 grow-0 basis-96 items-center gap-2">
              <div class="relative size-11 shrink-0">
                <PropertyImage class="size-11 rounded-lg" property={props.property} data-slot="image" />
                <div class="absolute bottom-0 left-0 flex items-center justify-center rounded-bl-lg rounded-tr-lg bg-black/60">
                  <PropertyType type={props.property?.type} class="text-white" />
                </div>
              </div>
              <Show
                when={props.source !== 'unit'}
                fallback={
                  <div class="flex-1 text-white">
                    <div class="mr-2 truncate text-lg font-semibold">
                      {props.unit?.name ?? t('Loading')}
                      <DeactivatedFlag class="ml-2" active={props.unit?.active} />
                    </div>
                    <DisplayAddress class="text-wrap text-left text-xs" address={props.property?.address} />
                  </div>
                }>
                <div class="flex shrink-0 grow-0 basis-96 items-center px-5 py-3 text-lg transition-colors has-[[data-open]]:bg-black/10">
                  <Show
                    when={props.showLeaseSelect}
                    fallback={props.leases ? `${props.leases?.length || 0} ${t('Ongoing leases')}` : t('Loading')}>
                    <Show
                      when={
                        (props.leases?.length && props.leases?.length > 1) ||
                        (isEndedLease(props.currentLease) && props.leases?.length && props.leases?.length >= 1)
                      }
                      fallback={
                        <>
                          {leaseStatusText(props.currentLease)}
                          <Show when={props.currentLease}>
                            <LeasePeriod class="ml-2 text-xs text-white/90" lease={props.currentLease} />
                          </Show>
                          <div class="ml-2">
                            <FeedbackIcon
                              maxPreviewIcons={3}
                              theme="white"
                              type="leases"
                              initalSelectedIcons={props.currentLease?.icons}
                              entityId={props.currentLease?.id}
                            />
                          </div>
                        </>
                      }>
                      <Popover class="w-full">
                        <Popover.Trigger class="group flex w-full flex-col">
                          <div class="flex w-full items-center justify-between">
                            <div class="flex items-center capitalize">
                              {leaseStatusText(props.currentLease)}
                              <LeasePeriod class="ml-2 text-xs text-white/90" lease={props.currentLease} />
                            </div>
                            <IconChevronDown
                              class="size-6 rounded-full bg-black/20 transition-transform group-aria-expanded:rotate-180"
                              fill="currentColor"
                              stroke="none"
                            />
                          </div>
                          <DisplayAddress class="text-wrap text-left text-xs" address={props.property?.address} />
                        </Popover.Trigger>
                        <Popover.Content class="-mx-5 mt-4 flex min-w-[calc(var(--reference-width)+40px)] origin-top flex-col gap-1 rounded-lg bg-white p-2 shadow-lg">
                          <For each={props.leases}>
                            {(action) => (
                              <Popover.Trigger
                                class="flex cursor-pointer items-center gap-2 rounded-lg border  p-3 pr-6"
                                classList={{
                                  'border-essential-colour bg-[#A126EC10]': action?.id === props.currentLease?.id,
                                  'border-partingline hover:bg-black/5': action?.id !== props.currentLease?.id,
                                }}
                                onClick={() => {
                                  props?.setCurrentLease && props.setCurrentLease(action);
                                  const newLink = location.pathname.replace(params.leaseId, action.id);
                                  navigate(newLink, { replace: true });
                                }}>
                                <div>
                                  <div class="whitespace-nowrap">
                                    <LeasePeriod class="text-sm font-medium text-text-level01" lease={action} />
                                  </div>
                                  <div class="text-xs text-text-level03"> {formatTenantNames(action.tenants)}</div>
                                </div>
                                <div
                                  class="ml-2 flex-1 text-right text-sm font-medium"
                                  classList={{
                                    'text-green': isLeased(action),
                                    'text-essential-colour': isDraftLease(action),
                                    'text-[#F76B6B]': isEvictionLease(action),
                                    'text-dragonFruit': isExpiredLease(action),
                                    'text-[#FA8511]': isMonthToMonth(action),
                                    'text-[#898D97]': isEndedLease(action) || isFutureLease(action),
                                  }}>
                                  {leaseStatusText(action)}
                                </div>
                              </Popover.Trigger>
                            )}
                          </For>
                        </Popover.Content>
                      </Popover>
                    </Show>
                  </Show>
                </div>
              </Show>
            </div>
          </div>
        </HeadLayout>

        <div class="w-full overflow-auto rounded-lg bg-black/10">
          <div class="flex items-center">
            <TabLinks
              classList={{
                'cursor-not-allowed pointer-events-none': !props.unit,
              }}
              class="border-l border-transparent capitalize text-inherit *:text-inherit lg:border-white/25"
              items={props.unitNavbarItems || []}
            />
            <Show when={props.source === 'unit'}>
              <div class="px-3 md:block">
                <WhiteLinkButton href={`${location.pathname}/edit-unit`}>
                  <IconEdit class="text-white" />
                  <span class="hidden text-nowrap capitalize md:block">{t('Edit unit')}</span>
                </WhiteLinkButton>
              </div>
            </Show>
          </div>
        </div>
      </div>

      <div class="mt-2 flex flex-wrap items-center rounded-lg bg-[--bg-color]">
        <TabLinks
          boxClass="w-full"
          class="capitalize text-inherit *:text-inherit"
          classList={{ 'cursor-not-allowed pointer-events-none opacity-50 overflow-x-hidden': props.leaseNavbarDisabled }}
          items={props.leaseNavbarItems || []}
          replace
        />
      </div>
    </div>
  );
};

import { createMemo, createSignal, Show, For } from 'solid-js';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import LabeledSelect from '~/components/common/Inputs/LabeledSelect';
import { OptionButton } from '~/components/common/OptionButton';
import { LabeledPortfolioSearch } from '~/components/portfolios';
import { LabeledPropertySearch } from '~/components/properties';
import { IconUser } from '~/components/ui';
import { LabeledUnitSearch } from '~/components/units';
import { useLocalization } from '~/contexts/global';
import { IconLease, IconPortfolioColored, IconPropertyColored, IconUnitColored } from '~/pages/settings/components/Icons';
import { emptyPlaceholder } from '~/utils/constant';
import type { Component } from 'solid-js';

type SelectTargetProps = {
  model: any;
  onUpdate: (updates: Partial<any>) => void;
  errors: any;
};

export const SelectTarget: Component<SelectTargetProps> = (props) => {
  const { t } = useLocalization();
  const [targetSelected, setTargetSelected] = createSignal(false);

  const targetTypes = createMemo(() => [
    { value: 'lease', label: t('Leases'), Icon: IconLease },
    { value: 'tenant', label: t('Tenants'), Icon: IconUser },
    { value: 'owner', label: t('Owners'), Icon: IconUser },
    { value: 'vendor', label: t('Vendors'), Icon: IconUser },
  ]);

  const selectionTypes = createMemo(() => [
    { value: 'portfolio', label: t('Portfolio'), Icon: IconPortfolioColored },
    { value: 'property', label: t('Property'), Icon: IconPropertyColored },
    { value: 'unit', label: t('Unit'), Icon: IconUnitColored },
  ]);

  const handleTargetTypeChange = (value: string) => {
    props.onUpdate({
      targetType: value,
      portfolioIds: [],
      propertyIds: [],
      unitIds: [],
      selectionType: null,
      hasBalanceDue: '',
    });
    setTargetSelected(true);
  };

  const handleSelectionTypeChange = (value: string) => {
    props.onUpdate({
      selectionType: value,
      portfolioIds: [],
      propertyIds: [],
      unitIds: [],
    });
  };

  const handleIdSelection = async (ids: string | string[], type: 'portfolioIds' | 'propertyIds' | 'unitIds') => {
    const idArray = Array.isArray(ids) ? ids : [ids];
    props.onUpdate({ [type]: idArray });
  };

  const handleBalanceDueChange = (value: string) => {
    props.onUpdate({ hasBalanceDue: value === '' ? undefined : value === 'true' });
  };

  const balanceDueOptions = [
    { value: '', label: emptyPlaceholder },
    { value: 'true', label: t('Yes') },
    { value: 'false', label: t('No') },
  ];

  return (
    <div class="flex w-full flex-col gap-10 pt-10">
      <h4 class="w-full text-lg font-semibold">{t('Select target')}</h4>

      <div class="flex w-full flex-wrap gap-2">
        <For each={targetTypes()}>
          {(type) => (
            <OptionButton
              class="grow"
              label={type.label}
              isActive={props.model.targetType === type.value}
              Icon={type.Icon}
              onClick={() => handleTargetTypeChange(type.value)}
            />
          )}
        </For>
      </div>
      <Show when={props.errors.targetType}>
        <p class="w-full text-red-500">{props.errors.targetType}</p>
      </Show>

      <Show when={targetSelected()}>
        <Show when={props.model.targetType !== 'vendor'}>
          <div class="flex flex-col gap-4">
            <div class="flex w-full items-center">
              <LabeledSelect
                label={t('Has balance due')}
                options={balanceDueOptions}
                value={props.model.hasBalanceDue === undefined ? '' : props.model.hasBalanceDue.toString()}
                onInput={handleBalanceDueChange}
              />
            </div>

            <div class="flex w-full items-center">
              <Checkbox
                showLabel
                label={t('Include ended lease')}
                checked={props.model.includeEndedLease}
                onInput={(checked) => props.onUpdate({ includeEndedLease: checked })}
              />
            </div>
          </div>

          <div class="flex w-full flex-wrap gap-2">
            <For each={props.model.targetType === 'owner' ? selectionTypes().slice(0, 2) : selectionTypes()}>
              {(type) => (
                <OptionButton
                  class="grow"
                  label={type.label}
                  isActive={props.model.selectionType === type.value}
                  Icon={type.Icon}
                  onClick={() => handleSelectionTypeChange(type.value)}
                />
              )}
            </For>
          </div>
        </Show>

        <Show when={props.model.selectionType === 'portfolio'}>
          <div class="w-full">
            <LabeledPortfolioSearch
              label={t('Select portfolios')}
              multiple
              value={props.model.portfolioIds}
              onInput={(portfolios) => handleIdSelection(portfolios, 'portfolioIds')}
            />
          </div>
        </Show>

        <Show when={props.model.selectionType === 'property'}>
          <div class="w-full">
            <LabeledPropertySearch
              label={t('Select properties')}
              multiple
              value={props.model.propertyIds}
              onInput={(properties) => handleIdSelection(properties, 'propertyIds')}
            />
          </div>
        </Show>

        <Show when={props.model.selectionType === 'unit' && props.model.targetType !== 'owner'}>
          <div class="w-full">
            <LabeledUnitSearch
              multiple
              label={t('Select units')}
              value={props.model.unitIds}
              onInput={(units) => handleIdSelection(units, 'unitIds')}
            />
          </div>
        </Show>
      </Show>

      <Show when={props.errors.portfolioIds}>
        <p class="w-full text-red-500">{props.errors.portfolioIds}</p>
      </Show>
    </div>
  );
};

import { BaseRestRepository } from '~/repositories/baseRestRepository';

const RENTAL_APPLICATION_SETTINGS_URL = '/api/settings/rental-application/general';

export class rentalApplicationSettingsRepository extends BaseRestRepository {
  public getRentalApplicationSettings = async (): Promise<MagicDoor.Api.RentalApplicationSettingsDto> => {
    const url = RENTAL_APPLICATION_SETTINGS_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  };

  public deleteRentalApplicationSettings = async () => {
    const url = RENTAL_APPLICATION_SETTINGS_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  };

  public updateRentalApplicationSettings = async (
    payload: MagicDoor.Api.RentalApplicationSettingsDto
  ): Promise<MagicDoor.Api.RentalApplicationSettingsDto> => {
    const url = RENTAL_APPLICATION_SETTINGS_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  };
}

import { BaseRestRepository } from '~/repositories/baseRestRepository';
import type { BackendEntitiesEnum } from '~/utils/constant';

const FEEDBACK_ICON_URL = '/api';

export class FeedbackIconRepository extends BaseRestRepository {
  async setIcon(type: BackendEntitiesEnum[number], id: string, icon: string) {
    const url = `${FEEDBACK_ICON_URL}/${type}/${id}/icons/${icon}`;
    await this.fetchWithAuth(url, {
      method: 'POST',
    });
  }
  async deleteIcon(type: BackendEntitiesEnum[number], id: string, icon: string) {
    const url = `${FEEDBACK_ICON_URL}/${type}/${id}/icons/${icon}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }
}

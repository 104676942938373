import { Route, Routes } from '@solidjs/router';
import { BankAccountsListProvider } from '~/contexts/local';
import { AddDepositSlipView } from './add-deposit-slip/AddDepositSlipView';
import { DepositSlipDetail } from './deposit-slip-detail/DepositSlipDetail';
import { ListDepositSlips } from './list-deposit-slips/ListDepositSlips';

export const DepositSlipsRoutes = () => {
  return (
    <BankAccountsListProvider>
      <Routes>
        <Route path="*" component={ListDepositSlips} />
        <Route path="add" component={AddDepositSlipView} />
        <Route path="/:depositSlipId" component={DepositSlipDetail} />
      </Routes>
    </BankAccountsListProvider>
  );
};

import { createSignal, createMemo, createEffect } from 'solid-js';
import IconTempUnit from '~/assets/images/common/tempUnit.png';
import ModalBgIcon from '~/assets/images/leases/transactions/modalBgIcon.svg';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { LabeledInputSearch } from '~/components/common/Inputs/LabeledInputSearch';
import { LeaseStatus } from '~/components/leases';
import { Modal } from '~/components/modals/Modal';
import { Image, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLeaseDeposits } from '~/contexts/local';
import { emptyPlaceholder, formConfirmationContent } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component, JSX } from 'solid-js';
import type { ModalProps } from '~/components/modals/Modal';

type LeaseReleaseDepositModalProps = Omit<ModalProps, 'title'> & {
  currentLease: MagicDoor.Api.HydratedLeaseDto;
};

type DepositOption = {
  id: string;
  chartOfAccountName: string;
  amount: number;
  description: string;
  date: string;
};

export const LeaseReleaseDepositModal: Component<LeaseReleaseDepositModalProps> = (props) => {
  const { t } = useLocalization();
  const { releaseDeposit, leaseDesposit } = useLeaseDeposits();

  const formId = 'release_deposit_form';

  const [loading, setLoading] = createSignal<boolean>(false);
  const [formData, setFormData] = createSignal<MagicDoor.Api.ReleaseDepositRequestDto>({
    billId: undefined,
    releaseToLease: true,
    allocatedOnly: false,
  });
  const [formErrors, setFormErrors] = createSignal<Partial<MagicDoor.Api.ReleaseDepositRequestDto>>({});
  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);

  const setFormDataCommon = (value: string | boolean, key: keyof MagicDoor.Api.ReleaseDepositRequestDto) => {
    setFormData({
      ...formData(),
      [key]: value,
    });
  };

  const validator = (): boolean => {
    const errors: Partial<MagicDoor.Api.ReleaseDepositRequestDto> = {};

    if (!formData().billId) {
      errors.billId = t('Deposit to release is required');
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (ev: Event) => {
    ev.preventDefault();
    const validateResult = validator();
    if (!validateResult) return;

    setLoading(true);
    try {
      await releaseDeposit(props.currentLease.id, formData());

      props.onCancel && props.onCancel();
      toast.success(t('Deposit has been released successfully'));
    } finally {
      setLoading(false);
    }
  };

  const billOptions = createMemo<DepositOption[]>(() => {
    return leaseDesposit()
      .filter((transaction) => transaction.type === 'charge')
      .map((transaction) => ({
        id: transaction.id,
        chartOfAccountName: transaction?.bill?.lineItems
          ? transaction.bill.lineItems.map((item) => item.chartOfAccount.name).join(', ')
          : emptyPlaceholder,
        amount: transaction.amount,
        description: transaction.description || emptyPlaceholder,
        date: dateFormat(transaction.transactionDate),
      }));
  });

  const renderDepositOption = (option: DepositOption): JSX.Element => {
    return (
      <div class="flex w-full flex-col">
        <div class="flex justify-between font-semibold text-text-level02">
          <span>{option.chartOfAccountName}</span>
          <span class="text-sm ">
            {t('Amount')}: {currency(option.amount)}
          </span>
        </div>
        <div class="flex justify-between text-text-level03">
          <span class="truncate text-sm ">{option.description}</span>
          <span>{option.date}</span>
        </div>
      </div>
    );
  };

  createEffect(() => {
    setShowCloseConfirmation(formData().billId !== undefined);
  });

  return (
    <Modal
      {...props}
      title={t('Release deposit')}
      doneText={t('Release')}
      submitId={formId}
      loading={loading()}
      confirmation={showCloseConfirmation() ? formConfirmationContent(t) : false}>
      <form class="thinscroll flex flex-col gap-5 overflow-auto px-8 pb-16 pt-6" id={formId} onSubmit={handleSubmit}>
        <div class="flex h-16 w-full items-center justify-items-start rounded-lg bg-slate-100 p-2">
          <Image src={IconTempUnit} class="size-12 rounded-lg" />
          <div class="ml-2">
            <div class="text-sm">
              {props.currentLease.property.address?.streetAddress1} - {props.currentLease.unit.name}
            </div>
            <LeaseStatus lease={props.currentLease} />
          </div>
        </div>
        <div class="grid grid-cols-1 gap-y-6">
          <LabeledInputSearch
            class="col-span-full"
            required
            labelJSX={t('Deposit to release')}
            label={t('Deposit to release')}
            value={formData().billId}
            onSelect={(value) => setFormDataCommon(value as string, 'billId')}
            error={formErrors().billId}
            options={billOptions()}
            placeholder={t('Please select')}
            renderItem={renderDepositOption}
            renderMatchedElement={renderDepositOption}
            labelPropName="chartOfAccountName"
            valuePropName="id"
          />
          <Checkbox
            label={t('Release to lease')}
            checkBoxClass="text-sm"
            checked={formData().releaseToLease}
            onInput={(checked) => setFormDataCommon(checked, 'releaseToLease')}
            showLabel
          />
          <Checkbox
            checkBoxClass="text-sm"
            label={t('Allocated only')}
            checked={formData().allocatedOnly}
            onInput={(checked) => setFormDataCommon(checked, 'allocatedOnly')}
            showLabel
          />
        </div>
      </form>
      <div class="relative flex w-full items-center justify-end bg-pink-100 py-5">
        <img src={ModalBgIcon} class="absolute bottom-0 left-4 h-20 w-52" />
      </div>
    </Modal>
  );
};

import { A } from '@solidjs/router';
import { Show } from 'solid-js';
import { LinkButton } from '~/components/common/Buttons/LinkButton';
import IconField from '~/components/common/IconField';
import { SendEmail } from '~/components/common/SendEmail';
import { IconSend } from '~/components/common/SendEmail/Icons';
import { IconMail, IconMessage, IconPhone } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import type { Component, JSX } from 'solid-js';

export interface VendorSummaryProps {
  loading?: boolean;
  vendor?: MagicDoor.Api.VendorDto;
  title?: string;
  renderAdditionalFields?: (vendor: MagicDoor.Api.VendorDto) => JSX.Element;
  bottomContent?: JSX.Element;
  hideEmail?: boolean;
  hidePhone?: boolean;
  hideSendEmail?: boolean;
  hideSendChat?: boolean;
  isHoaVendor?: boolean;
}

export const VendorSummary: Component<VendorSummaryProps> = (props) => {
  const { t } = useLocalization();

  return (
    <Show when={props.vendor}>
      <div class="divide-y divide-partingline overflow-hidden rounded-lg border border-partingline bg-white">
        <div class="flex flex-col border">
          <div class="flex flex-col gap-0.5 bg-gray-level01 px-5 py-4">
            <A class="flex gap-1 hover:underline" href={`/maintenance/vendors/${props.vendor?.id}`}>
              <span>{props.vendor?.name}</span>
            </A>
            <div class="text-xs font-normal text-text-level03">{props.isHoaVendor ? t('HOA vendor') : t('Vendor')}</div>
          </div>

          <div class="flex flex-col gap-y-3 p-4">
            <Show when={!props.hidePhone}>
              <IconField name={t('Phone')} loading={props.loading} value={props.vendor?.contact.phone} src={IconPhone} />
            </Show>
            <Show when={!props.hideEmail}>
              <div class="flex items-center justify-between">
                <IconField name={t('Email')} loading={props.loading} value={props.vendor?.contact.email} src={IconMail} />
                <Show when={!props.hideSendEmail && props.vendor?.contact.email}>
                  <SendEmail
                    type="vendor"
                    id={props.vendor?.id}
                    showModal
                    icon={<IconSend class="size-5 text-essential-colour hover:text-essential-colour/80" />}
                    class="max-w-fit border-none bg-white p-0 text-essential-colour hover:bg-white"
                  />
                </Show>
              </div>
            </Show>

            <Show when={!props.hideSendChat && props.vendor?.chatId}>
              <div class="mt-2">
                <LinkButton
                  href={`/maintenance/vendors/${props.vendor?.id}/communications`}
                  variant="outlined"
                  class="flex w-full items-center justify-center text-base font-medium capitalize"
                  color="primary">
                  <IconMessage class="size-5" />
                  {t('Message')} {props.isHoaVendor ? t('HOA vendor') : t('Vendor')}
                </LinkButton>
              </div>
            </Show>

            {props.vendor && props.renderAdditionalFields?.(props.vendor)}
          </div>
        </div>

        {props.bottomContent}
      </div>
    </Show>
  );
};

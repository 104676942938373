import dayjs from 'dayjs';
import { createMemo, createSignal } from 'solid-js';
import { toast } from '~/components/ui';
import { useLocalization, usePortfolios } from '~/contexts/global';
import type { FilterItems } from '~/components/ui';
import type { LeaseListFilter } from '~/repositories/leaseRepository';

type Status = 'due' | 'active' | 'eviction' | 'ending';
type Balance = 'upcoming';
type Period = '30' | '60' | '90' | '365';

export type FilterValue = {
  status?: Status;
  portfolio?: string;
  property?: string;
  balance?: Balance;
  startPeriod?: Period;
  startDate?: string;
  endPeriod?: Period;
  endDate?: string;
};

export const useLeaseFilter = ({ status = true, date = true } = {}) => {
  const { t } = useLocalization();
  const [filterValue, setFilterValue] = createSignal<FilterValue>({});
  const [portfolioId, setPortfolioId] = createSignal<string>();
  const { portfolios } = usePortfolios();

  const allOption = createMemo(() => ({ label: t('All'), value: undefined }));

  const filterItems = createMemo(
    () =>
      [
        status && {
          type: 'select',
          key: 'status',
          label: t('Status'),
          options: [
            { label: t('All'), value: undefined },
            { label: t('Balance due'), value: 'due' },
            { label: t('Active'), value: 'active' },
            { label: t('Eviction'), value: 'eviction' },
            { label: t('Ending soon'), value: 'ending' },
          ],
        },
        {
          type: 'select',
          key: 'portfolio',
          label: t('Portfolio'),
          filterable: true,
          options: async () => {
            if (portfolios.loading) return new Promise<[]>(() => void 0);
            return [allOption(), ...(portfolios()?.map((item) => ({ value: item.id, label: item.name })) ?? [])];
          },
          onChange: (value: string) => {
            setFilterValue({ property: undefined });
            setPortfolioId(value);
          },
        },
        {
          type: 'select',
          key: 'property',
          label: t('Property'),
          filterable: true,
          options: async () => {
            if (portfolios.loading) return new Promise<[]>(() => void 0);
            const id = portfolioId();
            if (id == null) {
              const opts = portfolios()?.flatMap((item) => item.properties?.map((item) => ({ value: item.id, label: item.name })) ?? []);
              return [allOption(), ...(opts ?? [])];
            }
            const portfolio = portfolios()?.find((item) => item.id === id);
            const opts = portfolio?.properties?.map((item) => ({ value: item.id, label: item.name })) ?? [];
            return [allOption(), ...opts];
          },
          onChange: (value: string) => {
            const portfolio = portfolios()?.find((item) => item.properties?.some((prop) => prop.id === value));
            setFilterValue({ portfolio: portfolio?.id });
          },
        },
        // balance && {
        //   type: 'select',
        //   key: 'balance',
        //   label: t('Balance'),
        //   options: [allOption(), { label: t('Upcoming'), value: 'upcoming' }],
        // },
        date && { type: 'group', label: t('Start date') },
        date && {
          type: 'select',
          key: 'startPeriod',
          label: t('Time period'),
          options: [
            { label: t('All time'), value: undefined },
            { label: t('Last 30 days'), value: '30' },
            { label: t('Last 60 days'), value: '60' },
            { label: t('Last 90 days'), value: '90' },
            // { label: t('Last year'), value: '365' },
            { label: t('Custom'), value: 'custom' },
          ],
        },
        date && { type: 'customDate', key: 'startDate', label: t('Date') },
        date && { type: 'group', label: t('End date') },
        date && {
          type: 'select',
          key: 'endPeriod',
          label: t('Time period'),
          options: [
            { label: t('All time'), value: undefined },
            { label: t('Next 30 days'), value: '30' },
            { label: t('Next 60 days'), value: '60' },
            { label: t('Next 90 days'), value: '90' },
            // { label: t('Next year'), value: '365' },
            { label: t('Custom'), value: 'custom' },
          ],
        },
        date && { type: 'customDate', key: 'endDate', label: t('Date') },
      ].filter(Boolean) as FilterItems<FilterValue>
  );

  const parseFilter = (value: FilterValue): LeaseListFilter => {
    const params: LeaseListFilter = {};
    const day = dayjs();

    switch (value.status) {
      case 'due':
        params.balanceMoreThan = 0;
        break;
      case 'active':
        params.eviction = false;
        break;
      case 'eviction':
        params.eviction = true;
        break;
      case 'ending':
        params['LeaseEnd.Start'] = day.format('YYYY-MM-DD');
        params['LeaseEnd.End'] = day.add(30, 'days').format('YYYY-MM-DD');
        break;
    }

    params.portfolioId = value.portfolio;
    params.propertyId = value.property;

    // TODO: add balance filter
    // params.withBalance = value.balance === 'upcoming';

    if (value.startPeriod) {
      params['LeaseStart.Start'] = day.subtract(Number(value.startPeriod), 'days').format('YYYY-MM-DD');
      params['LeaseStart.End'] = day.format('YYYY-MM-DD');
    }

    if (value.startDate) {
      params['LeaseStart.Start'] = value.startDate;
      params['LeaseStart.End'] = value.startDate;
    }

    if (value.endPeriod) {
      params['LeaseEnd.Start'] = day.format('YYYY-MM-DD');
      params['LeaseEnd.End'] = day.add(Number(value.endPeriod), 'days').format('YYYY-MM-DD');
    }

    if (value.endDate) {
      params['LeaseEnd.Start'] = value.endDate;
      params['LeaseEnd.End'] = value.endDate;
    }
    if (params['LeaseEnd.Start'] && params['LeaseStart.End'] && params['LeaseEnd.Start'] < params['LeaseStart.End']) {
      toast(t('The end date cannot be less than the start date'), 'error');
      return {};
    }
    return params;
  };

  const handleFilterReset = () => {
    setFilterValue({});
    setPortfolioId(undefined);
  };

  return { filterValue, filterItems, parseFilter, handleFilterReset };
};

import type { Icon } from '~/components/ui';

export const IconBuildLocation: Icon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.8899 19.8109H15.1647V4.19654C15.1647 3.03974 14.2275 2.10254 13.0707 2.10254H6.2115C5.0547 2.10254 4.1175 3.03974 4.1175 4.19654V19.8109H3.0423C2.79565 19.8109 2.5591 19.9089 2.3847 20.0833C2.21029 20.2577 2.1123 20.4943 2.1123 20.7409C2.1123 20.9876 2.21029 21.2241 2.3847 21.3985C2.5591 21.573 2.79565 21.6709 3.0423 21.6709H20.8899C21.1366 21.6709 21.3731 21.573 21.5475 21.3985C21.7219 21.2241 21.8199 20.9876 21.8199 20.7409C21.8199 20.4943 21.7219 20.2577 21.5475 20.0833C21.3731 19.9089 21.1366 19.8109 20.8899 19.8109Z"
      fill="#E5BBFF"
    />
    <path
      d="M6.45508 6.16211H8.34388V8.05091H6.45508V6.16211ZM6.45508 10.4257H8.34388V12.3145H6.45508V10.4257ZM6.45508 14.7169H8.34388V16.6057H6.45508V14.7169ZM9.49948 6.16211H11.3883V8.05091H9.49948V6.16211Z"
      fill="#A126EC"
    />
    <path
      d="M22.0011 11.8213C22.1559 8.71327 19.7127 6.03847 16.6023 6.08407C13.6959 6.12727 11.3535 8.49607 11.3535 11.4121C11.3535 14.5777 13.7451 18.1045 16.1055 19.6489C16.2731 19.7585 16.4692 19.8162 16.6694 19.8149C16.8696 19.8136 17.065 19.7534 17.2311 19.6417C19.5375 18.0865 21.8295 15.2485 22.0011 11.8213Z"
      fill="white"
    />
    <path
      d="M15.164 18.9299V6.30469C12.9596 6.95749 11.3516 8.99629 11.3516 11.4131C11.3516 14.1563 13.1492 17.1695 15.164 18.9299ZM16.5896 19.8107C16.6376 19.8143 16.6844 19.8143 16.7324 19.8107H16.5896Z"
      fill="white"
    />
    <path
      d="M16.6802 15.1079C14.6426 15.1079 12.9854 13.4507 12.9854 11.4131C12.9854 9.37546 14.6426 7.71826 16.6802 7.71826C18.7178 7.71826 20.375 9.37546 20.375 11.4131C20.375 13.4495 18.7178 15.1079 16.6802 15.1079ZM16.6802 9.63826C15.701 9.63826 14.9054 10.4339 14.9054 11.4131C14.9054 12.3923 15.701 13.1879 16.6802 13.1879C17.6594 13.1879 18.455 12.3923 18.455 11.4131C18.455 10.4339 17.6582 9.63826 16.6802 9.63826Z"
      fill="#A126EC"
    />
  </svg>
);

export const IconBuidingBackground: Icon = (props) => (
  <svg width="257" height="78" viewBox="0 0 257 78" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.78">
      <g opacity="0.5">
        <rect x="166.522" y="13.8682" width="32.8467" height="63.3244" fill="#971DE1" />
        <rect x="194.03" y="24.1235" width="25.9718" height="53.0719" fill="#971DE1" />
        <rect x="233.745" y="41.6147" width="11.4582" height="35.5823" fill="#971DE1" />
        <rect x="245.203" y="18.6963" width="11.4582" height="58.4997" fill="#971DE1" />
        <rect x="71.0376" width="31.319" height="77.1955" fill="#971DE1" />
        <path d="M47.3638 31.3592L71.0439 19.2974V77.1939H47.3638V31.3592Z" fill="#971DE1" />
        <path d="M0 27.1396L21.0066 11.1577L42.0132 27.1396V77.196H0V27.1396Z" fill="#971DE1" />
      </g>
      <path
        d="M166.257 46.1279L195.222 60.1026C195.913 60.4362 196.353 61.1362 196.353 61.9039V77.9987H163.388V47.9292C163.388 46.4548 164.929 45.4872 166.257 46.1279Z"
        fill="url(#paint0_linear_4075_7418)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M151.089 12.3725L96.2445 27.2013C93.6279 27.9087 91.8105 30.2827 91.8105 32.9933V77.1982H158.655V18.1645C158.655 14.2137 154.903 11.3413 151.089 12.3725ZM111.953 36.6061C111.953 35.0091 113.247 33.7144 114.844 33.7144H142.025C143.622 33.7144 144.917 35.0091 144.917 36.6061C144.917 38.2032 143.622 39.4979 142.025 39.4979H114.844C113.247 39.4979 111.953 38.2032 111.953 36.6061ZM114.844 48.174C113.247 48.174 111.953 49.4687 111.953 51.0657C111.953 52.6628 113.247 53.9575 114.844 53.9575H142.025C143.622 53.9575 144.917 52.6628 144.917 51.0657C144.917 49.4687 143.622 48.174 142.025 48.174H114.844Z"
        fill="url(#paint1_linear_4075_7418)"
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear_4075_7418" x1="179.87" y1="43.8607" x2="179.87" y2="77.9985" gradientUnits="userSpaceOnUse">
        <stop stop-color="#B03DF5" />
        <stop offset="1" stop-color="#A126EC" />
      </linearGradient>
      <linearGradient id="paint1_linear_4075_7418" x1="125.233" y1="3.43808" x2="125.233" y2="70.2018" gradientUnits="userSpaceOnUse">
        <stop stop-color="#B03DF5" />
        <stop offset="1" stop-color="#A126EC" />
      </linearGradient>
    </defs>
  </svg>
);

import { Show, createSignal, mergeProps } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import ConfirmationModal from './ConfirmationModal';
import type { Component, JSX } from 'solid-js';

interface CancelWithConfirmationProps {
  children?: JSX.Element;
  onConfirm: () => void;
  onCancel?: () => void;
  message?: string;
  confirmText?: string;
  cancelText?: string;
  title?: string;
  enabled?: boolean;
  buttonsDisabled?: boolean;
}

const CancelWithConfirmation: Component<CancelWithConfirmationProps> = (originProps) => {
  const props = mergeProps(
    {
      enabled: true,
    },
    originProps
  );
  const [showConfirmationModal, setShowConfirmationModal] = createSignal(false);

  const { t } = useLocalization();

  const confirm = () => {
    setShowConfirmationModal(false);
    props.onConfirm();
  };

  const cancel = () => {
    setShowConfirmationModal(false);
    if (props.onCancel) props.onCancel();
  };

  return (
    <>
      <div onClick={() => (props.enabled ? setShowConfirmationModal(true) : confirm())}>
        {props.children || (
          <Button disabled={props.buttonsDisabled} type="button" variant="outlined">
            {t('Cancel')}
          </Button>
        )}
      </div>
      <Show when={showConfirmationModal()}>
        <ConfirmationModal
          onConfirm={confirm}
          onCancel={cancel}
          title={props.title || t('Confirmation')}
          message={props.message || t('Are you sure you want to cancel?')}
          confirmText={props.confirmText || t('Yes')}
          cancelText={props.cancelText || t('Go back')}
        />
      </Show>
    </>
  );
};

export default CancelWithConfirmation;

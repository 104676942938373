import { useAuth } from '~/contexts/global';
import { UnauthorizedError } from '~/errors/unauthorizedError';

const UnauthorizedPage = () => {
  const { signOut } = useAuth();
  signOut();

  console.warn('Test behavior: UnauthorizedError');
  throw new UnauthorizedError();
};

export default UnauthorizedPage;

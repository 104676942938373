import { For, createEffect } from 'solid-js';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { getPositionOfEvent, getRelativePosition } from '~/pdfsigner/ui/utils/utils';
import { CreateAnnotationUseCase } from '~/pdfsigner/usecases/createAnnotationUseCase';
import { DeleteAnnotationUseCase } from '~/pdfsigner/usecases/deleteAnnotationUseCase';
import { ResizeAnnotationUseCase } from '~/pdfsigner/usecases/resizeAnnotationUseCase';
import { SetAnnotationTextUseCase } from '~/pdfsigner/usecases/setAnnotationTextUseCase';
import { StartEditingUseCase } from '~/pdfsigner/usecases/startEditingUseCase';
import { StopTransformingUseCase } from '~/pdfsigner/usecases/stopTransformingUseCase';
import { Annotation } from './Annotation';
import type { JSX } from 'solid-js';
import type { PresentableAnnotation } from '~/pdfsigner/presenters/PdfPresenter';
import type { PresentableAnnotatedPdfPage } from '~/pdfsigner/ui/types/presentableAnnotatedPdfPage';

interface PDFPageProps {
  page: PresentableAnnotatedPdfPage;
  onStartDragging: (id: string, event: MouseEvent, page: number) => void;
}

export const PdfPage = (props: PDFPageProps): JSX.Element => {
  let canvas: HTMLCanvasElement | undefined = undefined;

  const { execute: addAnnotation } = useUseCase(CreateAnnotationUseCase);
  const { execute: resizeAnnotation } = useUseCase(ResizeAnnotationUseCase);
  const { execute: stopTransform } = useUseCase(StopTransformingUseCase);
  const { execute: startEditingAnnotation } = useUseCase(StartEditingUseCase);
  const { execute: deleteAnnotation } = useUseCase(DeleteAnnotationUseCase);
  const { execute: setText } = useUseCase(SetAnnotationTextUseCase);

  const renderPage = async () => {
    if (!canvas) {
      return;
    }
    const width = document.getElementById('pdfPage' + props.page.pageNumber)?.clientWidth;
    const context = canvas.getContext('2d');
    if (!width || !context) {
      return;
    }
    let viewport = props.page.page.getViewport({ scale: 1.0 });
    const scale = width / viewport.width;
    viewport = props.page.page.getViewport({ scale });
    canvas.width = viewport.width;
    canvas.height = viewport.height;
    await props.page.page.render({ canvasContext: context, viewport });
  };

  createEffect(() => {
    renderPage();
  });

  const handleStartResizing = async (id: string, event: MouseEvent) => {
    event.stopPropagation();
    await resizeAnnotation({
      id,
      relativePosition: getRelativePosition(getPositionOfEvent(event), props.page.pageNumber),
      startPage: props.page.pageNumber,
    });
    document.onmousemove = (event: MouseEvent) => resize(id, event);
    document.onmouseup = () => reset();
  };

  const resize = async (id: string, event: MouseEvent) => {
    await resizeAnnotation({
      id,
      relativePosition: getRelativePosition(getPositionOfEvent(event), props.page.pageNumber),
      startPage: props.page.pageNumber,
    });
  };

  const reset = async () => {
    await stopTransform();
    document.onmousemove = null;
    document.onmouseup = null;
  };

  const handleStartEditing = async (id: string, event: MouseEvent) => {
    event.stopPropagation();
    await startEditingAnnotation({ id, page: props.page.pageNumber });
  };

  const handleUpdateText = async (id: string, event: InputEvent, value: string) => {
    event.stopPropagation();
    await setText({ id, value });
  };

  const handleKeyPressed = async (id: string, event: KeyboardEvent) => {
    if (event.code === 'Delete' || event.code === 'Backspace') {
      await deleteAnnotation({ id });
    }
  };

  return (
    <div class="relative m-[16px] flex w-full flex-col shadow-md">
      <canvas
        ref={(el) => (canvas = el)}
        id={'pdfPage' + props.page.pageNumber}
        onMouseDown={(event) =>
          addAnnotation({
            page: props.page.pageNumber,
            relativePosition: getRelativePosition(getPositionOfEvent(event), props.page.pageNumber),
          })
        }
      />
      <For each={props.page.annotations}>
        {(annotation: PresentableAnnotation) => (
          <Annotation
            parentRect={canvas?.getBoundingClientRect()}
            presentableAnnotation={annotation}
            onKeyPressed={(id: string, event: KeyboardEvent) => handleKeyPressed(id, event)}
            onInput={(id: string, event: InputEvent, value: string) => handleUpdateText(id, event, value)}
            onStartEditting={(id: string, event: MouseEvent) => handleStartEditing(id, event)}
            onStartDragging={(id: string, event: MouseEvent) => props.onStartDragging(id, event, props.page.pageNumber)}
            onStartResizing={(id: string, event: MouseEvent) => handleStartResizing(id, event)}
          />
        )}
      </For>
    </div>
  );
};

import { BaseRestRepository } from '~/repositories/baseRestRepository';

const RENT_PAYMENT_SETTINGS_URL = '/api/settings/rent-payment';

export class RentPaymentSettingsRepository extends BaseRestRepository {
  public async addRentPaymentSettings(payload: MagicDoor.Api.RentPaymentSettingsDto) {
    const url = RENT_PAYMENT_SETTINGS_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async addRentPaymentSettingsByLevel(level: string, levelId: string, payload: MagicDoor.Api.RentPaymentSettingsDto) {
    const url = `${RENT_PAYMENT_SETTINGS_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getRentPaymentSettings(): Promise<MagicDoor.Api.RentPaymentSettingsDto> {
    const url = RENT_PAYMENT_SETTINGS_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getRentPaymentSettingsByLevel(level: string, levelId: string) {
    const url = `${RENT_PAYMENT_SETTINGS_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getAllRentPaymentSettings(): Promise<MagicDoor.Api.RentPaymentSettingsDtoSettingsWithOverridesDto> {
    const url = `${RENT_PAYMENT_SETTINGS_URL}/all`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async deleteRentPaymentSettingsByLevel(level: string, levelId: string) {
    const url = `${RENT_PAYMENT_SETTINGS_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }
}

import { Route, Routes } from '@solidjs/router';
import { onMount } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { TabLinks } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { ListCustomAiRunbooks } from '~/pages/ai-runbooks/list-runbooks/ListCustomAiRunbooks';
import { ListAiRunbooks } from './list-runbooks/ListAiRunbooks';

export const AiRunbookTabRoutes = () => {
  const { t } = useLocalization();

  const { getRunBooks } = useRunBooks();

  onMount(() => {
    getRunBooks({});
  });

  return (
    <div class="p-8">
      <TabLinks
        boxClass="mb-4 rounded-lg border border-partingline bg-white"
        items={[
          { label: t('MagicDoor runbook'), href: location.search },
          { label: t('Custom runbook'), href: `custom${location.search}` },
        ]}
        actions={
          <div class="flex items-center gap-2 pr-6">
            <Button href={'custom/add'} class="font-medium capitalize">
              <IconPlus class="size-5 text-white" />
              {t('Create custom runbook')}
            </Button>
          </div>
        }
      />
      <Routes>
        <Route path="/*" component={ListAiRunbooks} />
        <Route path="/custom/*" component={ListCustomAiRunbooks} />
      </Routes>
    </div>
  );
};

export const getStripeKey = () => {
  if (import.meta.env.DEV) {
    return import.meta.env.VITE_STRIPE_KEY;
  }
  const hostname = window.location.hostname;
  if (hostname.includes('test')) {
    return import.meta.env.VITE_STRIPE_KEY;
  }
  return import.meta.env.VITE_STRIPE_KEY_PROD;
};

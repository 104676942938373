import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { rentalApplicationSettingsRepository } from '~/repositories/settings/rentalApplicationSettingsRepository';
import type { Accessor } from 'solid-js';

interface RentalApplicationSettingsContextValue {
  isLoading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
  updateRentalApplicationSettings: (
    payload: MagicDoor.Api.RentalApplicationSettingsDto
  ) => Promise<MagicDoor.Api.RentalApplicationSettingsDto | undefined>;
  getRentalApplicationSettings: () => Promise<MagicDoor.Api.RentalApplicationSettingsDto | undefined>;
  deleteRentalApplicationSettings: () => Promise<void>;
  rentalApplicationSettings: Accessor<MagicDoor.Api.RentalApplicationSettingsDto | undefined>;
}

export const [RentalApplicationSettingsProvider, useRentalApplicationSettings] =
  createMagicDoorContext<RentalApplicationSettingsContextValue>('RentalApplicationSettings', () => {
    const repo = new rentalApplicationSettingsRepository();
    const [rentalApplicationSettings, setRentalApplicationSettings] = createSignal<MagicDoor.Api.RentalApplicationSettingsDto | undefined>(
      undefined
    );
    const [isLoading, setIsLoading] = createSignal<boolean>(false);
    const [error, setError] = createSignal<Error | undefined>(undefined);

    const getRentalApplicationSettings = async (): Promise<MagicDoor.Api.RentalApplicationSettingsDto | undefined> => {
      setIsLoading(true);

      try {
        const result = await repo.getRentalApplicationSettings();
        setRentalApplicationSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const updateRentalApplicationSettings = async (
      payload: MagicDoor.Api.RentalApplicationSettingsDto
    ): Promise<MagicDoor.Api.RentalApplicationSettingsDto | undefined> => {
      setIsLoading(true);

      try {
        const result = await repo.updateRentalApplicationSettings(payload);
        setRentalApplicationSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    const deleteRentalApplicationSettings = async (): Promise<void> => {
      setIsLoading(true);

      try {
        await repo.deleteRentalApplicationSettings();
        setRentalApplicationSettings(undefined);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    return {
      isLoading,
      error,
      updateRentalApplicationSettings,
      getRentalApplicationSettings,
      deleteRentalApplicationSettings,
      rentalApplicationSettings,
    };
  });

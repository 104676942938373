import { createSignal } from 'solid-js';
import { useForm } from '~/components/common/BetterForm';
import { FormItem, FormWrapper } from '~/components/common/BetterForm/Form';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { Modal } from '~/components/modals/Modal';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

export const CreateFineBillModal: Component<{
  visible: boolean;
  onClose: () => void;
  onSubmit: (data: any) => Promise<void>;
}> = (props) => {
  const { t } = useLocalization();
  const form = useForm();

  const [loading, setLoading] = createSignal(false);

  const onDone = async () => {
    try {
      setLoading(true);
      const { validateStatus } = form.validateForm();
      validateStatus && (await props.onSubmit(form.formStore));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal title={t('Create fine bill')} visible={props.visible} onCancel={props.onClose} onDone={onDone} loading={loading()}>
      <FormWrapper defaultForm={form} class="p-6">
        <FormItem label={t('Due date')} placeholder={t('Please select')} component={DueDateInputField} formFieldName="dueDate" />
      </FormWrapper>
    </Modal>
  );
};

import { Show, onMount } from 'solid-js';
import { LinkButton } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import { Empty } from '~/components/common/Empty';
import { LeasePeriod, LeaseStatus, LeaseStatusElement, LeaseTitle } from '~/components/leases';
import { Html, ListView } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLeasesList } from '~/contexts/local';
import { useLeaseFilter, useLocalPagination } from '~/hooks';
import { QuickLeaseNotifyBalanceDue } from '~/pages/leases/quick-lease-notify-balance-due';
import { diffDates } from '~/utils/date';
export const LeasesList = () => {
  const { t } = useLocalization();
  const { leases, getLeases, loading, pagination } = useLeasesList();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('LeasesListView');
  const { filterValue, filterItems, parseFilter, handleFilterReset } = useLeaseFilter();

  const necessaryParams = { ended: false };

  onMount(() => getLeases({ page: page(), pageSize: pageSize(), ...necessaryParams }));

  return (
    <ListView
      onChange={({ page, pageSize, search, filter }) => {
        setPage(page);
        setPageSize(pageSize);
        getLeases({ page, pageSize, search, ...parseFilter(filter), ...necessaryParams });
      }}
      title={t('Leases')}
      searchPlaceholder={t('Search leases')}
      totalPages={pagination()?.totalPages}
      page={pagination()?.current}
      pageSize={pagination()?.pageSize}
      filterValue={filterValue()}
      filterItems={filterItems()}
      onFilterReset={handleFilterReset}
      rowLink={(item) => `/leasing/leases/${item.id}`}
      skeletonSize={10}
      loading={loading()}
      data={leases()}
      empty={<Empty description={t('No Leases')} />}
      columns={[
        {
          title: t('Lease'),
          render: (item) => <LeaseTitle lease={item} />,
        },
        {
          title: t('Lease term'),
          headerClass: 'w-56',
          render: (item) => (
            <>
              <LeaseStatus lease={item} />
              <LeasePeriod lease={item} />
            </>
          ),
        },
        {
          title: t('Status'),
          headerClass: 'w-48',
          render: (item) => <LeaseStatusElement lease={item} />,
        },
        {
          title: t('Balance'),
          headerClass: 'w-32 text-right',
          class: 'text-right',
          render: (item) => <CnMoney money={item.balance} zeroHolder="--" />,
        },
        {
          title: t('Operation'),
          headerClass: 'w-72 text-right',
          render: (item) => (
            <div class="relative z-10 flex justify-end gap-3">
              <Show when={item.balance > 0 && diffDates(item.end, item.earliestUnpaidBillDueDate)}>
                <QuickLeaseNotifyBalanceDue targets={[item.id]} />
              </Show>
              <LinkButton
                href={`/leasing/leases/${item.id}/transactions/add-charge`}
                variant="outlined"
                rounded="full"
                class="border-link px-3 py-1 text-xs text-link hover:bg-link/10 [&_span]:whitespace-nowrap">
                {t('Post a charge')}
              </LinkButton>
            </div>
          ),
        },
      ]}
      footerSummary={
        <Html as="p" class="flex-1 text-xs text-text-level03 [&>b]:mx-1 [&>b]:font-bold [&>b]:text-essential-colour">
          {t('A total of <b>{count}</b> related lease contracts', {
            count: loading() ? '-' : pagination().total.toString(),
          })}
        </Html>
      }
    />
  );
};

import { PdfLoader } from '~/pdfsigner/repositories/pdfLoader';
import { UseCase } from './useCase';
import type { AnnotatedPdfPage } from './types/annotatedPdfPage';
import type { PDFDocumentProxy } from 'pdfjs-dist';

export class AddPdfUseCase extends UseCase {
  public async runLogic(files: FileList) {
    const pdfLoader = new PdfLoader();
    const pdfs = await pdfLoader.loadPdfs(files);
    if (!pdfs) {
      return;
    }
    const finalPdfs: PDFDocumentProxy[] = [];
    let finalPdfPages: AnnotatedPdfPage[] = [];
    for (let i = 0; i < pdfs.length; i++) {
      const annotatedPages: AnnotatedPdfPage[] = await pdfLoader.getPdfPages(pdfs[i]);
      finalPdfs.push(pdfs[i]);
      finalPdfPages = this.state.pdfs.pdfPages.concat(annotatedPages);
    }
    this.state.pdfs.pdfs = finalPdfs;
    this.state.pdfs.pdfPages = finalPdfPages;
  }
}

import { Route, Routes } from '@solidjs/router';
import { AttachmentsProvider, LeaseTemplateCategoryProvider, LeaseTemplateProvider } from '~/contexts/local';
import { AddLeaseTemplateCategoryModal } from './components/AddLeaseTemplateCategoryModal';
import { DeleteLeaseTemplateCategoryModal } from './components/DeleteLeaseTemplateCategoryModal';
import { CustomizeLeaseTemplates } from './customize-lease-template/CustomizeLeaseTemplates';
import { LeaseTemplateDetails } from './lease-template-details/LeaseTemplateDetails';
import { ListLeaseTemplate } from './list-lease-template/ListLeaseTemplate';

export const LeaseTemplateRoutes = () => {
  return (
    <AttachmentsProvider>
      <LeaseTemplateCategoryProvider>
        <LeaseTemplateProvider>
          <Routes>
            <Route path="/" component={ListLeaseTemplate} />
            <Route path="/customize-template" component={CustomizeLeaseTemplates} />
            <Route path="/add-category" component={AddLeaseTemplateCategoryModal} />
            <Route path="/:templateId" component={LeaseTemplateDetails} />
            <Route path="/edit-category/:categoryId" component={AddLeaseTemplateCategoryModal} />
            <Route path="/delete-category/:categoryId" component={DeleteLeaseTemplateCategoryModal} />
          </Routes>
        </LeaseTemplateProvider>
      </LeaseTemplateCategoryProvider>
    </AttachmentsProvider>
  );
};

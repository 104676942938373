import { For, Show, createSignal } from 'solid-js';
import IconFilledArrow from '~/assets/images/common/filledArrow.svg?component-solid';
import DisplayAddress from '~/components/common/DisplayAddress';
import { IconUser } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { IconPortfolioColored, IconPropertyColored, IconUnitColored } from '~/pages/settings/components/Icons';
import type { PortfolioTarget, PropertyTarget, UnitTarget } from './sharedTypes';

export const PortfolioTargetList = (props: { portfolioTargets: PortfolioTarget[] }) => {
  const { t } = useLocalization();
  const [expandedPortfolios, setExpandedPortfolios] = createSignal<string[]>([]);
  const [expandedProperties, setExpandedProperties] = createSignal<string[]>([]);
  const [expandedUnits, setExpandedUnits] = createSignal<string[]>([]);

  const toggleExpand = (id: string, expandedItems: string[], setExpandedItems: (items: string[]) => void) => {
    setExpandedItems(expandedItems.includes(id) ? expandedItems.filter((item) => item !== id) : [...expandedItems, id]);
  };

  const renderPortfolio = (portfolio: PortfolioTarget) => {
    const isExpanded = () => expandedPortfolios().includes(portfolio.portfolio.id);
    return (
      <div class="mb-4">
        <div
          class="rounded-md border p-4 hover:cursor-pointer"
          onClick={() => toggleExpand(portfolio.portfolio.id, expandedPortfolios(), setExpandedPortfolios)}>
          <div class="flex items-center justify-between">
            <div class="flex gap-2">
              <IconPortfolioColored />
              <div>
                <h6 class="font-semibold">
                  <a href={`/portfolios/${portfolio.portfolio.id}`} target="_blank" rel="noopener noreferrer" class="hover:underline">
                    {portfolio.portfolio.name}
                  </a>
                </h6>
                <p class="text-sm text-text-level02">
                  {portfolio.portfolio.propertyCount} {t('Properties')}
                </p>
              </div>
            </div>
            <IconFilledArrow class="size-6 flex-none transition-all duration-300 ease-in-out" classList={{ 'rotate-180': isExpanded() }} />
          </div>
        </div>
        <Show when={isExpanded()}>
          <For each={portfolio.properties}>{(property) => renderProperty(property, portfolio.portfolio.id)}</For>
        </Show>
      </div>
    );
  };

  const renderProperty = (property: PropertyTarget, portfolioId: string) => {
    const isExpanded = () => expandedProperties().includes(property.property.id);
    return (
      <div class="ml-4 mt-2 rounded-b-md border-b border-l-2 pl-4">
        <div
          class="flex w-full rounded-md border p-4 hover:cursor-pointer"
          onClick={() => toggleExpand(property.property.id, expandedProperties(), setExpandedProperties)}>
          <div class="flex w-full items-center justify-between">
            <div class="flex w-full gap-2">
              <IconPropertyColored />
              <div class="flex w-full flex-col">
                <h6 class="font-semibold">
                  <a
                    href={`/portfolios/${portfolioId}/properties/${property.property.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    class="hover:underline">
                    {property.property.name}
                  </a>
                </h6>
                <div class="flex w-full divide-x text-sm text-text-level02">
                  <div class="flex gap-1 pr-2">
                    <span>{property.property.unitCount}</span>
                    {t('Units')}
                  </div>
                  <DisplayAddress class="px-2" address={property.property.address} />
                </div>
              </div>
            </div>
            <IconFilledArrow class="size-6 flex-none transition-all duration-300 ease-in-out" classList={{ 'rotate-180': isExpanded() }} />
          </div>
        </div>
        <Show when={isExpanded()}>
          <For each={property.units}>{(unit) => renderUnit(unit, portfolioId, property.property.id)}</For>
        </Show>
      </div>
    );
  };

  const renderUnit = (unit: UnitTarget, portfolioId: string, propertyId: string) => {
    const isExpanded = () => expandedUnits().includes(unit.unit.id);
    return (
      <Show when={unit && unit.unit}>
        <div class="ml-4 mt-2 border-l-2 pl-4">
          <div
            class="flex w-full gap-2 rounded-md border p-4 hover:cursor-pointer"
            onClick={() => toggleExpand(unit.unit.id, expandedUnits(), setExpandedUnits)}>
            <IconUnitColored />
            <div class="flex w-full items-center justify-between">
              <div>
                <h6 class="font-semibold">
                  <a
                    href={`/portfolios/${portfolioId}/properties/${propertyId}/units/${unit.unit.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    class="hover:underline">
                    {unit.unit.name}
                  </a>
                </h6>
                <Show when={unit.tenants && Array.isArray(unit.tenants) && unit.tenants.length > 0}>
                  <p class="text-sm text-text-level02">
                    {unit.tenants.length} {t('Tenants')}
                  </p>
                </Show>
              </div>
              <IconFilledArrow
                class="size-6 flex-none transition-all duration-300 ease-in-out"
                classList={{ 'rotate-180': isExpanded() }}
              />
            </div>
          </div>
          <Show when={isExpanded()}>
            <For each={unit.tenants}>
              {(tenant) => (
                <div class="ml-4 mt-2 border-l-2 pl-4">
                  <div class="ml-4 mt-2 flex gap-2 rounded-md border p-2">
                    <IconUser />
                    <a href={`/users/tenants/${tenant.id}`} target="_blank" rel="noopener noreferrer" class="hover:underline">
                      {`${tenant.firstName} ${tenant.lastName ? tenant.lastName : ''}`}
                    </a>
                  </div>
                </div>
              )}
            </For>
          </Show>
        </div>
      </Show>
    );
  };

  return (
    <Show when={props.portfolioTargets && props.portfolioTargets.length > 0}>
      <div class="mt-4">
        <h4 class="mb-8 border-b text-xl font-semibold">{t('Portfolio targets')}</h4>
        <For each={props.portfolioTargets}>{renderPortfolio}</For>
      </div>
    </Show>
  );
};

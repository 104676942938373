import { RentReceivable } from '~/components/common/DataGraphs/RentReceivable';
import { OccupancyRate } from './OccupancyRate';
import { PortfolioDistribution } from './PortfolioDistribution';
import type { Component } from 'solid-js';

export const PortfolioSummary: Component<{
  loading: boolean;
  portfolioOverview?: MagicDoor.Api.PortfolioOverviewDto;
}> = (props) => (
  <div class="grid gap-5 xl:grid-cols-3">
    <PortfolioDistribution portfolioOverview={props.portfolioOverview} loading={props.loading} />
    <RentReceivable
      totalRent={props?.portfolioOverview?.summary?.totalRent}
      outstandingBalances={props?.portfolioOverview?.summary?.currentBalance}
      loading={props.loading}
    />
    <OccupancyRate portfolioOverview={props.portfolioOverview} loading={props.loading} />
  </div>
);

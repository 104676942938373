import { Route, Routes } from '@solidjs/router';
import { ChatProvider, ChatsListProvider, ChatsSettingsProvider } from '~/contexts/local';
import { AddCommunicationModal } from './add-chat/AddCommunciationModal';
import { ChatListView } from './list-chats/ChatListView';

const ChatRoutes = () => {
  return (
    <ChatsListProvider>
      <ChatsSettingsProvider>
        <ChatProvider>
          <Routes>
            <Route path="/:chatId?" component={ChatListView} />
          </Routes>
          <Routes>
            <Route path="/add" component={AddCommunicationModal} />
          </Routes>
        </ChatProvider>
      </ChatsSettingsProvider>
    </ChatsListProvider>
  );
};

export default ChatRoutes;

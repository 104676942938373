import { For, Show, createEffect, createSignal } from 'solid-js';
import IconFilledArrow from '~/assets/images/common/filledArrow.svg?component-solid';
import { Dropdown } from '~/components/common/Dropdown';
import { LabeledGroup } from '~/components/common/Inputs';
import { RingLoader } from '~/components/common/Loaders';
import { IconCheck } from '~/components/ui/Icons';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import type { Component, JSX } from 'solid-js';

export type Option = {
  label: string;
  value: any;
  [propName: string]: any;
};

export type DropdownMenuProps = {
  class?: string;
  label?: string;
  loading?: boolean;
  placeholder?: string;
  options: Option[];
  error?: string;
  value?: any;
  required?: boolean;
  onChange?: (selected: string) => void;
  onClick?: (isMouseDown: boolean) => void;
  renderLabel?: (label: string) => JSX.Element;
  contentPosition?: ('top' | 'left' | 'right' | 'bottom')[];
  labelJSX?: JSX.Element;
  renderOptionClass?: (option: any) => string;
  disabled?: boolean;
  buttonClass?: string;
};

export const DropdownMenu: Component<DropdownMenuProps> = (props) => {
  const [selected, setSelected] = createSignal<string | null>();
  const selectedOption = () => props.options.find((e) => e.value === selected());

  const handleSelect = (value: string) => {
    setSelected(value);
    props.onChange && props.onChange(value);
  };

  createEffect(() => {
    setSelected(props.value);
  });

  return (
    <LabeledGroup label={props.label} labelJSX={props.labelJSX} error={props.error} class={props.class} required={props.required}>
      <Dropdown
        contentPosition={props.contentPosition}
        contentClass="thinscroll max-h-dropdown overflow-y-auto"
        renderLabel={(isOpen, open) => (
          <button
            type="button"
            class={cn(
              'relative flex w-full cursor-pointer items-center justify-between gap-4 overflow-hidden rounded-md border  bg-inputbox-bg px-3 py-1.5 text-sm text-text-level01 focus:outline-none focus:ring-1 focus:ring-essential-colour',
              props.buttonClass
            )}
            classList={{
              'border-red-300': !!props.error,
              'ring-0': !!props.error,
            }}
            disabled={props.disabled}
            onMouseDown={() => props.onClick && props.onClick(true)}
            onMouseUp={() => props.onClick && props.onClick(false)}
            onClick={() => !props.disabled && open(!isOpen)}>
            <Show when={selected() != null} fallback={<span class="truncate text-auxiliary-text">{props.placeholder}</span>}>
              <span class="truncate">
                <Show when={props.renderLabel} fallback={selectedOption()?.label}>
                  {props.renderLabel?.(selectedOption()?.label ?? emptyPlaceholder)}
                </Show>
              </span>
            </Show>
            <IconFilledArrow class="size-6 flex-none transition-all duration-300 ease-in-out" classList={{ 'rotate-180': isOpen }} />
            <input
              aria-invalid="false"
              aria-hidden="true"
              tabIndex={-1}
              class="absolute inset-x-0 bottom-0 h-2 bg-transparent text-transparent outline-none"
              value={props.value || ''}
              required={props.required}
            />
          </button>
        )}>
        {(isOpen, open) => (
          <Show when={!props.loading} fallback={<RingLoader class="mx-auto" color="#A126EC" />}>
            <For each={props.options}>
              {(option) => (
                <li
                  class="flex w-full cursor-pointer items-center gap-2 whitespace-nowrap rounded-md border border-transparent p-2 hover:border hover:border-essential-colour hover:bg-essential-colour/10 hover:text-essential-colour"
                  onClick={() => {
                    handleSelect(option.value);
                    open(false);
                  }}>
                  <span class={`overflow-hidden ${props?.renderOptionClass?.(option)}`}>{option.label}</span>
                  <Show when={selected() === option.value}>
                    <IconCheck class="ml-auto size-4 text-essential-colour" />
                  </Show>
                </li>
              )}
            </For>
          </Show>
        )}
      </Dropdown>
    </LabeledGroup>
  );
};

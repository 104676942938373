import { createEffect, For } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
import { SaveBtn } from '~/pages/settings/components/edit-form/SaveBtn';
import type { Component } from 'solid-js';
import type { Profile } from '~/contexts/global';
import type { Section } from '~/pages/settings/account-settings/types';
import type { Promisable } from '~/utils/types';

const InformationFormItem: Component<Section> = (props) => {
  const { t } = useLocalization();
  return (
    <FormItem
      class={props.class}
      formFieldName={props.field}
      component={LabeledTextInput}
      rules={props?.rules}
      label={t(props.label)}
      labelClass="text-text-level03"
    />
  );
};

export const InformationForm: Component<{
  sections: Section[];
  onClose: () => void;
  onSubmit: (model: Profile) => Promisable<void>;
}> = (props) => {
  const form = useForm();

  createEffect(() => {
    form.setFormStore(
      props.sections.reduce((acc, item) => {
        acc[item.field] = item.value;
        return acc;
      }, {} as Record<string, string | undefined>)
    );
  });

  const isDirty = () => {
    return props.sections.some((section) => form.formStore[section.field] !== section.value);
  };

  const handleSave = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;
    await props.onSubmit(form.formStore as Profile);
    props.onClose();
  };

  return (
    <Form defaultForm={form} class="my-3 grid gap-x-5 gap-y-6 text-left md:grid-cols-2">
      <For each={props.sections}>{(section) => <InformationFormItem {...section} />}</For>
      <SaveBtn class="col-span-full" dirty={isDirty()} onCancel={props.onClose} onSave={handleSave} />
    </Form>
  );
};

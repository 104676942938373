import { BaseRestRepository } from '~/repositories/baseRestRepository';

export class runBooksSettingsRepository extends BaseRestRepository {
  public async changeRunBooksSettings(payload: MagicDoor.Api.MaintenanceRequestRunBookSettingsDto) {
    const url = '/api/settings/maintenance-request/run-books';
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getAllRunBooksSettings() {
    const url = '/api/settings/maintenance-request/run-books/all';
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async updateRunBooksSettingsByLevel(level: string, levelId: string, payload: MagicDoor.Api.MaintenanceRequestRunBookSettingsDto) {
    const url = `/api/settings/maintenance-request/run-books/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async deleteRunBooksSettingsByLevel(level: string, levelId: string) {
    const url = `/api/settings/maintenance-request/run-books/${level}/${levelId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }
}

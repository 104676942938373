import { Show, createEffect } from 'solid-js';
import { Section } from '~/components/common/FormSectionTitle';
import { RingLoader } from '~/components/common/Loaders';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions } from '~/contexts/local';
import { PropertiesReviewListView } from './PropertiesReviewListView';

export const PropertiesReview = () => {
  const { t } = useLocalization();
  const { propertyValidationRecords, propertyValidationRecordsActions } = useAddOwnerDistributions();

  createEffect(() => {
    propertyValidationRecordsActions.refetch();
  });

  return (
    <div class="px-[96px]">
      <p class="mb-2.5 text-xs font-thin normal-case text-text-level02">
        {t('All properties require assigned owners set in their respective accounts.')}
      </p>
      <Section title={t('Property review')} border class="mb-0 [&>*]:mb-0">
        <Show
          when={!propertyValidationRecords.loading}
          fallback={
            <div class="flex items-center justify-center pt-6">
              <RingLoader color="#A126EC" text={`${t('Loading')}...`} />
            </div>
          }>
          <PropertiesReviewListView />
        </Show>
      </Section>
    </div>
  );
};

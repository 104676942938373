import { createMemo } from 'solid-js';
import { RentReceivable } from '~/components/common/DataGraphs/RentReceivable';
import { LatestUpdatedAt } from '~/components/ui';
import { useProperties, useLocalization } from '~/contexts/global';
import { ListUnitsView } from '~/pages/units/list-units';
import PropertyDistribution from './units/PropertyDistribution';
import PropertyExpiringLease from './units/PropertyExpiringLease';

const PropertyUnitTab = () => {
  const { propertyOverview } = useProperties();
  const { t } = useLocalization();
  const exporingLeasedata = createMemo(() => {
    const { expiringLeaseCounts } = (propertyOverview() as MagicDoor.Api.PropertyOverviewDto) || {};

    return [
      {
        name: t('{count} Days', { count: '<30' }),
        value: expiringLeaseCounts?.lessThanOneMonth ?? 0,
        color: '#FF545D',
      },
      {
        name: t('{count} Days', { count: '31 - 60' }),
        value: expiringLeaseCounts?.oneToTwoMonths ?? 0,
        color: '#FA8511',
      },
      {
        name: t('{count} Days', { count: '61 - 90' }),
        value: expiringLeaseCounts?.twoToThreeMonths ?? 0,
        color: '#36CBAC',
      },
    ];
  });

  return (
    <>
      <LatestUpdatedAt class="px-8" time={propertyOverview()?.cacheDate ?? ''} />
      <div class="mb-4 grid grid-cols-3 gap-5 px-8">
        <PropertyDistribution class="col-span-3 flex flex-col overflow-hidden xl:col-span-1" />
        <RentReceivable
          totalRent={propertyOverview()?.summary?.totalRent}
          outstandingBalances={propertyOverview()?.summary?.currentBalance}
          class="col-span-3 flex flex-col overflow-hidden xl:col-span-1 "
        />
        <PropertyExpiringLease data={exporingLeasedata()} class="col-span-3 flex flex-col overflow-hidden xl:col-span-1" />
      </div>
      <ListUnitsView />
    </>
  );
};

export default PropertyUnitTab;

import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { RentalApplicationPaymentSettingRepository } from '~/repositories/settings/rentalApplicationPaymentSettingsRepository';
import type { Accessor } from 'solid-js';

interface RentalApplicationPaymentSettingContextValue {
  isLoading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
  updateRentalApplicationPaymentSettings: (
    payload: MagicDoor.Api.RentalApplicationPaymentSettingsDto
  ) => Promise<MagicDoor.Api.RentalApplicationPaymentSettingsDto | undefined>;
  getRentalApplicationPaymentSettings: () => Promise<MagicDoor.Api.RentalApplicationPaymentSettingsDto | undefined>;
  deleteRentalApplicationPaymentSettings: () => Promise<void>;
  rentalApplicationPaymentSettings: Accessor<MagicDoor.Api.RentalApplicationPaymentSettingsDto | undefined>;
}

export const [RentalApplicationPaymentSettingsProvider, useRentalApplicationPaymentSettings] =
  createMagicDoorContext<RentalApplicationPaymentSettingContextValue>('RentalApplicationPaymentSettings', () => {
    const repo = new RentalApplicationPaymentSettingRepository();
    const [rentalApplicationPaymentSettings, setRentalApplicationPaymentSettings] = createSignal<
      MagicDoor.Api.RentalApplicationPaymentSettingsDto | undefined
    >(undefined);
    const [isLoading, setIsLoading] = createSignal<boolean>(false);
    const [error, setError] = createSignal<Error | undefined>(undefined);

    const getRentalApplicationPaymentSettings = async (): Promise<MagicDoor.Api.RentalApplicationPaymentSettingsDto | undefined> => {
      setIsLoading(true);

      try {
        const result = await repo.getRentalApplicationPaymentSettings();
        setRentalApplicationPaymentSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const updateRentalApplicationPaymentSettings = async (
      payload: MagicDoor.Api.RentalApplicationPaymentSettingsDto
    ): Promise<MagicDoor.Api.RentalApplicationPaymentSettingsDto | undefined> => {
      setIsLoading(true);

      try {
        const result = await repo.updateRentalApplicationPaymentSettings(payload);
        setRentalApplicationPaymentSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const deleteRentalApplicationPaymentSettings = async (): Promise<void> => {
      setIsLoading(true);

      try {
        await repo.deleteRentalApplicationPaymentSettings();
        setRentalApplicationPaymentSettings(undefined);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    return {
      isLoading,
      error,
      updateRentalApplicationPaymentSettings,
      getRentalApplicationPaymentSettings,
      deleteRentalApplicationPaymentSettings,
      rentalApplicationPaymentSettings,
    };
  });

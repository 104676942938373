import { Show } from 'solid-js';
import { DeactivatedFlag } from '~/components/common';
import { pickProps, TitleWithFeedbackIcon } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import { Skeleton } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { PortfolioIcon } from './PortfolioIcon';
import type { Component } from 'solid-js';
import type { TitleWithFeedbackIconProps } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';

export const PortfolioTitle: Component<
  { class?: string; portfolio?: MagicDoor.Api.HydratedPortfolioDto } & Partial<TitleWithFeedbackIconProps>
> = (props) => {
  const { t } = useLocalization();

  const totalUnits = () => props.portfolio?.properties?.reduce((acc, i) => acc + i.unitCount, 0);

  return (
    <TitleWithFeedbackIcon
      {...pickProps(props)}
      type="portfolios"
      entityId={props.portfolio?.id}
      initalSelectedIcons={props.portfolio?.icons}>
      <div class={cn('flex items-center gap-2', props.class)}>
        <PortfolioIcon class="size-11 shrink-0 rounded-lg bg-light-pink p-2 md:block" data-slot="icon" />
        <div class="flex-1 items-center space-y-0.5" data-slot="content">
          <Show
            when={props.portfolio}
            fallback={
              <>
                <Skeleton class="h-7 w-20" />
                <Skeleton class="h-4 w-44" />
              </>
            }>
            <h1 class="relative z-10 w-32 truncate" data-slot="title">
              {props.portfolio?.name}
              <DeactivatedFlag class="ml-2" active={props.portfolio?.active} />
            </h1>
            <p class="text-xs capitalize opacity-80" data-slot="subtitle">
              {props.portfolio?.propertyCount} {t('Properties')}丨{totalUnits()} {t('Units')}
            </p>
          </Show>
        </div>
      </div>
    </TitleWithFeedbackIcon>
  );
};

import { useParams } from '@solidjs/router';
import { onMount } from 'solid-js';
import { ChatWindow } from '~/components/common/ChatWindow';
import { useRentalApplication } from '~/contexts/global';

export const Communication = () => {
  const { applicationStore, fetchApplication } = useRentalApplication();
  const params = useParams();

  onMount(() => {
    fetchApplication(params.applicationId);
  });

  return (
    <ChatWindow
      chatId={applicationStore.application?.chatId}
      participantType="rentalApplication"
      participantId={applicationStore.application?.id}
    />
  );
};

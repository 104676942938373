import { createMemo, createSignal } from 'solid-js';
import { useStripe } from '~/contexts/local';

export const useStripeUI = (bankAccount?: MagicDoor.Api.HydratedBankAccountDto) => {
  const { linkStripeWithAccount, stripeUrl, getStripeDetails, isStripeLoading } = useStripe();
  const [isStripeLinking, setIsStripeLinking] = createSignal<boolean>(false);
  const [localStripeDetails, setLocalStripeDetails] = createSignal<MagicDoor.Api.StripeAccountDetailsDto | undefined>(undefined);

  const isStripeConnected = createMemo(() => bankAccount?.stripe?.connected);
  const isDetailSubmitted = createMemo(() => bankAccount?.stripe?.detailsSubmitted);
  const isChargesEnabled = createMemo(() => bankAccount?.stripe?.chargesEnabled);

  const getStripeStatusBgColor = () => {
    if (!isChargesEnabled()) {
      if (isDetailSubmitted()) {
        return 'bg-[#FF8618]/20';
      } else {
        return 'bg-[#635BFF]/20';
      }
    } else {
      return 'bg-[#28D9A5]/20';
    }
  };

  const openStripeLink = async (bankAccountId: string | undefined) => {
    if (bankAccountId === undefined) return;
    setIsStripeLinking(true);
    await linkStripeWithAccount(bankAccountId).finally(() => {
      setIsStripeLinking(false);
    });
    const url = stripeUrl();
    if (url) {
      window.open(url, '_blank');
    }
  };

  const fetchStripeDetails = async (bankAccountId: string | undefined) => {
    if (bankAccountId === undefined) return;
    if (isChargesEnabled()) {
      const details = await getStripeDetails(bankAccountId);
      setLocalStripeDetails(() => details);
    }
  };

  return {
    isStripeConnected,
    isDetailSubmitted,
    isChargesEnabled,
    getStripeStatusBgColor,
    openStripeLink,
    isStripeLoading,
    isStripeLinking,
    linkStripeWithAccount,
    localStripeDetails,
    fetchStripeDetails,
  };
};

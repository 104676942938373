import { createEffect, createSignal } from 'solid-js';
import { SegmentForm, SegmentFormScrollSpy, useForm } from '~/components/common/BetterForm';
import { Button } from '~/components/common/Buttons';
import CallToActionButton from '~/components/common/Buttons/CallToActionButton';
import CancelWithConfirmation from '~/components/modals/CancelWithConfirmation';
import MiddleModal from '~/components/modals/MiddleModal';
import { toast } from '~/components/ui';
import { useLocalization, usePortfolios, useProperties } from '~/contexts/global';
import { PropertyInformationSection } from '~/pages/properties/form-sections/PropertyInformationSection';
import { isEmptyData } from '~/utils/tool';

export const AddProperty = (props: { onClose: () => void }) => {
  const { t } = useLocalization();
  const form = useForm();
  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);
  const { current } = usePortfolios();
  const { addProperty } = useProperties();

  const performSave = async () => {
    const portfolioId = current()?.id;
    if (portfolioId == null) return;
    const { images, ...payload } = form.formStore;
    const created = await addProperty({ ...payload, portfolioId } as MagicDoor.Api.CreatePropertyDto, images);
    props.onClose();
    toast.success(t('{name} has been added successfully', { name: created.name || t('Property') }));
  };

  createEffect(() => setShowCloseConfirmation(!isEmptyData(form.formStore)));

  return (
    <MiddleModal
      class="w-11/12 max-w-4xl"
      confirmation={showCloseConfirmation()}
      onClose={props.onClose}
      confirmationTitle={t('Are you sure you want to cancel?')}
      confirmationMessage={t('If you cancel the addition, the information already filled in cannot be saved')}>
      <SegmentForm defaultForm={form} onFormSubmit={performSave} class="flex flex-1 flex-col overflow-hidden">
        <div class="flex flex-1 overflow-hidden">
          <div class="relative hidden w-72 bg-inputbox-bg p-6 text-text-level03 xs:block">
            <div class="text-lg font-semibold text-text-level01">{t('Add property')}</div>
            <SegmentFormScrollSpy />
          </div>
          <div class="thinscroll flex-1 overflow-auto pb-10">
            <PropertyInformationSection />
          </div>
        </div>
        <div class="flex items-center justify-between border-t bg-white px-6 py-4">
          <div class="text-xs text-text-level03">{t('Better information that will attract renters to your property.')}</div>
          <div class="flex gap-2">
            <CancelWithConfirmation
              enabled={showCloseConfirmation()}
              onConfirm={props.onClose}
              message={t('If you cancel the addition, the information already filled in cannot be saved')}>
              <Button variant="outlined" disabled={addProperty.pending} type="button">
                {t('Cancel')}
              </Button>
            </CancelWithConfirmation>
            <CallToActionButton
              loading={addProperty.pending}
              disabled={addProperty.pending || !form.isValidate}
              type="submit"
              tooltipMessage={<div class="w-40 text-wrap">{t('Please fill out all the required fields that are in red')}</div>}>
              {t('Create property')}
            </CallToActionButton>
          </div>
        </div>
      </SegmentForm>
    </MiddleModal>
  );
};

import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

const BASE_URL = '/api/company/credit/property';

export type PropertyCreditListFilter = {
  page?: number;
  pageSize?: number;
  propertyIdOr?: string[];
  portfolioIdOr?: string[];
};

export class PropertyCreditRepository extends BaseRestRepository {
  public async getPropertyCredits(filter?: PropertyCreditListFilter): Promise<MagicDoor.Api.PaginatedBillsDto> {
    const url = urlWithQuery(BASE_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async addPropertyCredit(propertyId: string, payload: MagicDoor.Api.GivePropertyCreditDto): Promise<MagicDoor.Api.BillDto> {
    const url = `${BASE_URL}/${propertyId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }
  public async deletePropertyCredit(billId: string) {
    const url = `${BASE_URL}/bill/${billId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }
}

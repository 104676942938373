import { SelectAnnotationUseCase } from './selectAnnotationUseCase';
import { AnnotationType } from './types/annotation';
import { UseCase } from './useCase';
import type { StartEditingRequest } from './types/startEditingRequest';

export class StartEditingUseCase extends UseCase {
  public async runLogic(request: StartEditingRequest) {
    if (this.isPageWithingRange(request.page)) {
      return;
    }
    const selectAnnotationUseCase = new SelectAnnotationUseCase();
    await selectAnnotationUseCase.execute({ id: request.id, page: request.page });
    const selectedAnnotation = this.state.selectedAnnotation;
    if (selectedAnnotation && selectedAnnotation.type === AnnotationType.TEXT) {
      this.state.isEditing = true;
    }
  }
}

import { Dynamic } from 'solid-js/web';
import IconAiBot from '~/assets/images/chat/aiChatBot.svg';
import IconAiGradientBg from '~/assets/images/chat/aiMagicItGradientBg.png';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import IconPic from '~/assets/images/common/pic.svg?component-solid';
import IconBathroom from '~/assets/images/property/bathroom.svg';
import IconBedroom from '~/assets/images/property/bedroom.svg';
import IconDateAvaliable from '~/assets/images/property/date.svg';
import IconUnitSize from '~/assets/images/property/unitSize.svg';
import { Avatar } from '~/components/common/Avatar';
import { LinkButton } from '~/components/common/Buttons';
import { Dropdown } from '~/components/common/Dropdown';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { Panel } from '~/components/common/Panels';
import { propertyTypes } from '~/components/properties';
import { useLocalization } from '~/contexts/global';
import { UnitMetric } from '~/pages/units/components/UnitMetric';
import { currency } from '~/utils/number';
export const UnitInformationTab = () => {
  const { t } = useLocalization();
  const listing = {
    id: 'test123456789',
    image: 'https://img.zcool.cn/community/01c9d158cc87bca801219c77fd02f2.jpg@1280w_1l_2o_100sh.jpg',
    name: 'Unit 3535',
    address: '2522 S Dunsmuir Ave',
    type: 'multiFamily',
    status: 'Active',
    updateDate: '2024-05-26',
    views: 3000,
    guestCards: 11,
    applications: 8,
  };
  return (
    <div class="grid grid-cols-12 gap-5">
      <Panel class="col-span-9 px-6 py-5">
        <div>
          <div class="flex justify-between">
            <div>
              <div class="flex items-center gap-2 text-2xl font-semibold text-text-level01">
                <span>{listing.name}</span>
                <div class="flex items-center gap-1 rounded-full bg-[#8712CF] px-3 py-1 text-xs text-white">
                  <Dynamic component={propertyTypes[listing.type as MagicDoor.Api.PropertyType].icon} class="size-4" />
                  {propertyTypes[listing.type as MagicDoor.Api.PropertyType].name}
                </div>
              </div>
              <div class="text-sm font-medium text-text-level03">{listing.address}</div>
            </div>
            <LinkButton href="" variant="outlined" class="border-link py-2 font-medium text-link hover:bg-link/10">
              <IconEdit /> {t('Edit Unit')}
            </LinkButton>
          </div>
          <div class="my-6 flex gap-20">
            <div>
              <div class="text-xs text-text-level03">{t('Rent')}</div>
              <div class="text-xl font-semibold text-essential-colour">{currency(1)}</div>
            </div>
            <div>
              <div class="text-xs text-text-level03">{t('Security deposit')}</div>
              <div class="text-xl font-semibold text-text-level01">2</div>
            </div>
          </div>
          <div class="flex flex-wrap gap-10 py-6">
            <UnitMetric icon={IconBedroom} title={t('Bedroom')} value={'-'} />
            <UnitMetric icon={IconBathroom} title={t('Bathroom')} value={'-'} />
            <UnitMetric icon={IconUnitSize} title={t('Unit size')} value={'-'} suffix={t('Sqft')} />
            <UnitMetric icon={IconDateAvaliable} title={t('Date avaliable')} value={'-'} />
          </div>
          <div class="mt-6 flex items-center gap-3">
            <span class="text-xs capitalize text-text-level03">{t('Previous lease')}:</span>
            <Dropdown
              class="cursor-pointer"
              contentPosition={['bottom']}
              renderLabel={(isOpen, open) => (
                <div onClick={() => open(!isOpen)} class="relative flex items-center gap-2">
                  <div class="flex w-64 items-center gap-2 rounded-lg border border-input-border bg-light-faded p-3 text-left text-xs">
                    <Avatar name="J W" size="large" />
                    <div>
                      <div>8/16/2023 - 8/1/2024</div>
                      <div>James Walker,sophia martin</div>
                    </div>
                  </div>
                  <div class="rotate-180">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                      <rect x="22" y="22" width="22" height="22" rx="11" transform="rotate(180 22 22)" fill="#3C36DD" />
                      <path
                        d="M12.0704 7.9657C11.4824 7.36665 10.5173 7.36665 9.92932 7.9657L6.75557 11.1993C5.82421 12.1482 6.49647 13.75 7.82608 13.75L14.1736 13.75C15.5032 13.75 16.1755 12.1482 15.2441 11.1993L12.0704 7.9657Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              )}>
              {(_, open) => (
                <div class="flex flex-col gap-1">
                  {/* <For each={props.leases}>
                      {(action) => (

                      )}
                    </For> */}
                </div>
              )}
            </Dropdown>
          </div>
        </div>
      </Panel>
      <div class="col-span-3 overflow-hidden rounded-lg bg-white p-2">
        <iframe
          width="100%"
          height="100%"
          style={{ border: '0' }}
          loading="lazy"
          allowfullscreen
          referrerpolicy="no-referrer-when-downgrade"
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB89updWgLCG03Q81M-bZm0ESgVpSyCZ0I&q=Space+Needle,Seattle+WA"
        />
      </div>
      <Panel title={t('Facts and features')} class="col-span-6">
        <div class="flex w-full flex-wrap items-center gap-y-3 px-6 py-4">
          <span class="flex w-1/3 items-center gap-1 text-sm text-text-level02">
            <Checkbox checked={true} onInput={(val) => null} />
            Fitness center
          </span>
          <span class="flex w-1/3 items-center gap-1 text-sm text-text-level02">
            <Checkbox checked={true} onInput={(val) => null} />
            Clubhouse
          </span>
          <span class="flex w-1/3 items-center gap-1 text-sm text-text-level02">
            <Checkbox checked={true} onInput={(val) => null} />
            Dog Park
          </span>
          <span class="flex w-1/3 items-center gap-1 text-sm text-text-level02">
            <Checkbox checked={true} onInput={(val) => null} />
            Grill
          </span>
          <span class="flex w-1/3 items-center gap-1 text-sm text-text-level02">
            <Checkbox checked={true} onInput={(val) => null} />
            Gated
          </span>
          <span class="flex w-1/3 items-center gap-1 text-sm text-text-level02">
            <Checkbox checked={true} onInput={(val) => null} />
            Office
          </span>
          <span class="flex w-1/3 items-center gap-1 text-sm text-text-level02">
            <Checkbox checked={true} onInput={(val) => null} />
            Office
          </span>
          <span class="flex w-1/3 items-center gap-1 text-sm text-text-level02">
            <Checkbox checked={true} onInput={(val) => null} />
            Lounge
          </span>
          <span class="flex w-1/3 items-center gap-1 text-sm text-text-level02">
            <Checkbox checked={true} onInput={(val) => null} />
            Courtyard
          </span>
          <span class="flex w-1/3 items-center gap-1 text-sm text-text-level02">
            <Checkbox checked={true} onInput={(val) => null} />
            Pool
          </span>
        </div>
      </Panel>
      <Panel title={t('AI features')} class="col-span-6">
        <div class="px-6 py-2 text-text-level02">
          <div>{t('Here is what AI found from you pictures')}:</div>
          <div class="mt-2 flex flex-wrap items-center gap-1.5">
            <div class="rounded-md bg-light-pink px-3 py-1 text-sm text-[#B140F5]">Spacious Room</div>
            <div class="rounded-md bg-light-pink px-3 py-1 text-sm text-[#B140F5]">Modern Appliances</div>
          </div>
        </div>
      </Panel>
      <Panel
        title={t('Master listing descriprion')}
        class="col-span-full"
        actions={
          <div class="flex items-center gap-2">
            <LinkButton href="" variant="outlined" class="border-link py-2 font-medium text-link hover:bg-link/10">
              <IconEdit /> {t('Edit')}
            </LinkButton>
            <div class="mb-1.5 mr-1 rounded-lg bg-gradient-to-tl">
              <button
                class="flex h-10 w-fit items-center justify-center gap-2 bg-contain bg-left bg-no-repeat px-4	text-white"
                style={{
                  'background-image': `url(${IconAiGradientBg})`,
                  'background-position': 'left, right',
                  'background-repeat': 'no-repeat, no-repeat',
                }}>
                <img src={IconAiBot} class="hide-on-mobile" />
                <span class="flex whitespace-nowrap text-base	font-medium">{t('Magic AI')}</span>
              </button>
            </div>
          </div>
        }>
        <div class="px-12 py-7 text-sm text-[#575757]">
          Experience luxurious living at 150 Main Ave, Unit 1023. This spacious 2-bedroom, 2-bath apartment features an open-concept design,
          modern kitchen with stainless steel appliances, and a private balcony with city views. Enjoy access to premium amenities such as a
          fitness center, swimming pool, and resident lounge. Ideally located near shopping, dining, and public transport, making urban
          living a breeze. Schedule your tour today and discover the magic of this exceptional home.
        </div>
      </Panel>
      <div class="col-span-full grid grid-cols-4 gap-3">
        <img
          src="https://img.zcool.cn/community/01c9d158cc87bca801219c77fd02f2.jpg@1280w_1l_2o_100sh.jpg"
          class=" h-52 w-full rounded-lg"
        />
        <img src="https://img.zcool.cn/community/01c9d158cc87bca801219c77fd02f2.jpg@1280w_1l_2o_100sh.jpg" class="h-52 w-full rounded-lg" />
        <img src="https://img.zcool.cn/community/01c9d158cc87bca801219c77fd02f2.jpg@1280w_1l_2o_100sh.jpg" class="h-52 w-full rounded-lg" />
        <div class="relative overflow-hidden rounded-lg">
          <img
            src="https://img.zcool.cn/community/01c9d158cc87bca801219c77fd02f2.jpg@1280w_1l_2o_100sh.jpg"
            class=" h-52 w-full rounded-lg"
          />
          <div class="absolute inset-0 flex items-center justify-center bg-black/50">
            <IconPic class="size-6" />
            <span class="ml-1 text-2xl text-white">{4}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useNavigate } from '@solidjs/router';
import IconDeleteWarning from '~/assets/images/confirm-modal/delete.svg?component-solid';
import { Html, IconTrash, confirm, toast } from '~/components/ui';
import { useLocalization, useVendors } from '~/contexts/global';

interface DeleteVendorProps {
  vendorId: string | undefined;
}

export const DeleteVendor = (props: DeleteVendorProps) => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { current, deleteVendor } = useVendors();

  const handleDeleteClick = () =>
    confirm({
      title: t('Delete vendor'),
      content: (
        <>
          <IconDeleteWarning class="-mx-5 block bg-light-pink" />
          <Html as="p" class="mx-auto my-4 w-80 text-text-level03 [&>b]:font-bold [&>b]:text-primary">
            {t('Are you sure you want to delete <b>{name}</b> vendor?', { name: current()?.name ?? '' })}
          </Html>
        </>
      ),
      onResolve: async (confirmed) => {
        if (!confirmed) return;
        props.vendorId && (await deleteVendor(props.vendorId));
        navigate('/maintenance/vendors', { replace: true });
        toast.success(t('{name} has been deleted successfully', { name: t('Vendor') }));
      },
    });

  return (
    <button
      type="button"
      onClick={handleDeleteClick}
      class="flex items-center gap-2 rounded-md border px-4 py-2.5 text-sm font-medium text-text-level02 transition-colors hover:bg-partingline">
      <IconTrash class="size-4" />
      {t('Delete vendor')}
    </button>
  );
};

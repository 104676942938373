import { BaseRestRepository } from '~/repositories/baseRestRepository';

const tenantBalanceDueNotificationUrl = '/api/settings/tenant-balance-due-notifications';

export class tenantBalanceDueNotificationRepository extends BaseRestRepository {
  public async getTenantBalanceDueNotificationAll() {
    const url = tenantBalanceDueNotificationUrl + '/all';
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async updateTenantBalanceDueNotification(payload: MagicDoor.Api.TenantBalanceDueNotificationSettingsDto) {
    const url = tenantBalanceDueNotificationUrl;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getTenantBalanceDueNotificationByLevel(level: string, levelId: string) {
    const url = `${tenantBalanceDueNotificationUrl}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async updateTenantBalanceDueNotificationByLevel(
    level: string,
    levelId: string,
    payload: MagicDoor.Api.TenantBalanceDueNotificationSettingsDto
  ) {
    const url = `${tenantBalanceDueNotificationUrl}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async deleteTenantBalanceDueNotificationByLevel(level: string, levelId: string) {
    const url = `${tenantBalanceDueNotificationUrl}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }
}

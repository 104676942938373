import { createMemo, Show } from 'solid-js';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import { Avatar } from '~/components/common/Avatar';
import { LinkButton } from '~/components/common/Buttons';
import { IconCirclePlus, ListView, toast } from '~/components/ui';
import { useLocalization, useProperties } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import { emptyPlaceholder } from '~/utils/constant';
import { percentFormat } from '~/utils/number';

type PropertyOwnerDto = MagicDoor.Api.HydratedPropertyOwnerDto & MagicDoor.Api.OwnerDto;

export const PropertyOwner = () => {
  const { t } = useLocalization();
  const { current: currentProperty, loading, removePropertyOwner } = useProperties();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('PropertyOwnerListView');

  const owners = createMemo<PropertyOwnerDto[]>(() =>
    (currentProperty()?.owners || []).map(
      (item) =>
        ({
          ...item,
          ...item.owner,
        } as PropertyOwnerDto)
    )
  );

  // const showTip = createMemo<boolean>(() => {
  //   const total = owners().reduce((previous, current) => previous + (current.ownershipPercentage || 0), 0);
  //   return total != 1;
  // });

  const getName = (item: MagicDoor.Api.OwnerDto) => (item.lastName ? `${item.firstName} ${item.lastName}` : item.firstName);

  const removeOwner = async (ownerId: string) => {
    await removePropertyOwner(currentProperty()?.id as string, ownerId);
    toast.success(t('{name} has been deleted successfully', { name: 'Owner' }));
  };

  return (
    <div class="px-8 text-left">
      {/* <Show when={showTip() && !loading()}>
        <div class="mb-4 flex items-center gap-2 rounded-md border border-danger/20 bg-danger-light px-4 py-2 text-base text-danger">
          <IconAlert class="size-5" /> {t('Please update the ownership percentages to ensure they add up to 100%')}
        </div>
      </Show> */}
      <ListView
        skeletonSize={10}
        title={t('Owners')}
        data={owners()}
        loading={loading()}
        titleActions={
          <LinkButton href="add" class="px-2.5 font-medium" noScroll>
            <IconCirclePlus class="size-5 text-white" />
            <div class="hidden md:flex">{t('Add owner')}</div>
          </LinkButton>
        }
        page={page()}
        pageSize={pageSize()}
        onChange={({ page, pageSize }) => {
          setPage(page);
          setPageSize(pageSize);
        }}
        columns={[
          {
            title: t('Name'),
            render: (item) => (
              <div class="flex items-center justify-start gap-2">
                <Avatar class="size-9 shrink-0" name={getName(item)} />
                <div class="flex flex-col text-sm text-text-level01">{getName(item)}</div>
              </div>
            ),
          },
          {
            title: t('Phone'),
            render: (item) => <div>{item.phone || emptyPlaceholder}</div>,
          },
          {
            title: t('Email'),
            render: (item) => <div>{item.email || emptyPlaceholder}</div>,
          },
          {
            title: t('Active'),
            render: (item) => (
              <Show when={item.active} fallback={<div class=" text-light-gray-level01">{t('Inactive')}</div>}>
                <div class="text-green">{t('Active')}</div>
              </Show>
            ),
          },
          {
            title: t('Ownership percentage'),
            render: (item) => <div>{percentFormat(item.ownershipPercentage)}</div>,
          },
        ]}
        actions={(item) => [
          {
            label: t('Edit'),
            icon: IconPencil,
            href: `${item.id}`,
          },
          {
            label: t('Delete'),
            icon: IconDelete,
            onClick: () => removeOwner(item.id),
          },
        ]}
      />
    </div>
  );
};

import { useI18n, I18nContext, createI18nContext } from '@solid-primitives/i18n';
import { createContext, useContext, createSignal } from 'solid-js';
import enTranslations from '~/locales/en/translation.json';
import zhTranslations from '~/locales/zh/translation.json';
import type { JSX, Accessor } from 'solid-js';

export type TFunction = (_key: string, _params?: Record<string, any> | undefined) => string;

export interface LocalizationContextType {
  t: TFunction;
  changeLanguage(_toLocale?: string | undefined): string;
  currentLanguage: Accessor<string>;
}

const LocalizationContext = createContext<LocalizationContextType>();

interface ProviderProps {
  children: JSX.Element;
}

export function LocalizationProvider(props: ProviderProps) {
  const dict = {
    en: enTranslations,
    zh: zhTranslations,
  };

  let localeToUse = 'en';
  const storedLocale = localStorage.getItem('locale');
  if (storedLocale && storedLocale in dict) {
    localeToUse = storedLocale;
  }
  const i18nContextValue = createI18nContext(dict, localeToUse);
  return (
    <I18nContext.Provider value={i18nContextValue}>
      <InnerLocalizationProvider>{props.children}</InnerLocalizationProvider>
    </I18nContext.Provider>
  );
}

function InnerLocalizationProvider(props: ProviderProps) {
  const [innerTranslate, { locale }] = useI18n();
  const [currentLanguage, setCurrentLanguage] = createSignal<string>(locale());

  const t = (key: string, params?: Record<string, any>): string => {
    if (!key) {
      console.warn(`[I18N] Translation key is missing`);
      return '[Translation key is missing]';
    }

    if (/^{.*}$/.test(key)) {
      try {
        const { Key, Parameters } = JSON.parse(key);
        key = Key;
        params = params || Parameters;
      } catch (e) {
        console.warn(`[I18N] Failed to parse key as JSON: '${key}'`);
      }
    }

    let translatedText = innerTranslate(key, params);

    if (params) {
      for (const [paramKey, paramValue] of Object.entries(params)) {
        const regex = new RegExp(`{${paramKey}}`, 'g');
        translatedText = translatedText.replace(regex, paramValue);
      }
    }

    if (!translatedText && import.meta.env.DEV) {
      const objEmptyKeys = JSON.parse(localStorage.getItem('emptyKeys') || '{}');
      if (!objEmptyKeys[key]) {
        objEmptyKeys[key] = key;
        localStorage.setItem('emptyKeys', JSON.stringify(objEmptyKeys));
      }
      console.warn(`[I18N] Translation not found: '${key}'`);
      return `[Translation: '${key}']`;
    }

    return translatedText || key;
  };

  const changeLanguage = (toLocale?: string | undefined): string => {
    const currentLanguage = toLocale || 'en';

    localStorage.setItem('locale', currentLanguage);
    setCurrentLanguage(currentLanguage);
    return locale(currentLanguage);
  };

  const contextValue: LocalizationContextType = { t, changeLanguage, currentLanguage };
  return <LocalizationContext.Provider value={contextValue}>{props.children}</LocalizationContext.Provider>;
}

export function useLocalization(): LocalizationContextType {
  const context = useContext(LocalizationContext);
  if (!context) {
    throw new Error('useLocalization must be used within a LocalizationProvider');
  }
  return context;
}

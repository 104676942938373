import { createSignal, createEffect, onMount } from 'solid-js';
import type { Component, JSX } from 'solid-js';

interface TintedSVGProps {
  svg: string;
  color: string;
  class?: string;
  opacity?: number;
}

export const TintedSVG: Component<TintedSVGProps> = (props) => {
  const [svgContent, setSvgContent] = createSignal<string>('');
  const [svgElements, setSvgElements] = createSignal<JSX.Element[]>();

  const fetchSVG = async (path: string) => {
    try {
      const response = await fetch(path);
      const text = await response.text();
      setSvgContent(text);
    } catch (error) {
      console.error('Failed to fetch SVG:', error);
    }
  };

  onMount(() => {
    fetchSVG(props.svg);
  });

  createEffect(() => {
    if (svgContent()) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(svgContent(), 'image/svg+xml');
      const paths = Array.from(xmlDoc.querySelectorAll('path'));
      const jsxPaths = paths.map((path) => (
        <path {...Object.fromEntries(Array.from(path.attributes).map((attr) => [attr.name, attr.value]))} fill={props.color} />
      ));
      const svgRoot = xmlDoc.documentElement;
      setSvgElements([
        <svg
          {...Object.fromEntries(Array.from(svgRoot.attributes).map((attr) => [attr.name, attr.value]))}
          class={props.class}
          opacity={props.opacity}>
          {jsxPaths}
        </svg>,
      ]);
    }
  });

  return <>{svgElements()}</>;
};

import { Route, Routes } from '@solidjs/router';
import { Show, createMemo } from 'solid-js';
import { AddServiceBillTypeModal } from '~/components/service-bill';
import { LeaseDraft } from '~/components/units/LeaseDraft';
import { useUnits } from '~/contexts/global';
import { DeleteLeaseModal } from '~/pages/leases/lease-details/components/DeleteLeaseModal';
import { EndLeaseModal } from '~/pages/leases/lease-details/components/EndLeaseModal';
import { LeaseStartEvictionModal } from '~/pages/leases/lease-details/components/LeaseStartEvictionModal';
import { AddUnit } from '~/pages/units/add-unit';
import { MaintenanceRequestsTable } from '~/pages/units/unit-details/overview/MaintenanceRequestsTable';
import { LatestPaymentsTable } from './LatestPaymentsTable';
import { ListingSyndications } from './ListingSyndications';
import { OngoingLeasesTable } from './OngoingLeasesTable';
import { UnitBalances } from './UnitBalances';
import { UnitSummary } from './UnitSummary';
import { OverviewSkeleton } from './skeleton/OverviewSkeleton';
import type { Component } from 'solid-js';

export const Overview: Component = () => {
  const { unit } = useUnits();

  const balances = createMemo<number>(() => unit()?.activeLeases?.reduce((acc, item) => acc + item.balance, 0) || 0);

  const draftLease = createMemo(() => unit()?.activeLeases?.find((item) => item.draft));

  return (
    <>
      <Show when={!unit.loading} fallback={<OverviewSkeleton />}>
        <div class="grid grid-cols-3 gap-5 text-left">
          <UnitSummary class="col-span-3 lg:col-span-2" unit={unit()} />
          <UnitBalances class="col-span-3 lg:col-span-1" balance={balances()} />
          <LeaseDraft lease={draftLease()} />
          <OngoingLeasesTable class="col-span-3 lg:col-span-2" leases={unit()?.activeLeases ?? []} />
          <ListingSyndications class="col-span-3 lg:col-span-1" />
          <LatestPaymentsTable class="relative col-span-3 lg:col-span-2" />
          <MaintenanceRequestsTable class="col-span-3 lg:col-span-1" />
        </div>
      </Show>
      <Routes>
        <Route path="/end-lease" component={EndLeaseModal} />
        <Route path="/delete-lease" component={DeleteLeaseModal} />
        <Route path="/start-eviction" component={LeaseStartEvictionModal} />
        <Route path="/edit-unit" component={AddUnit} />
        <Route path="/add-service-bill" element={<AddServiceBillTypeModal type="unit" />} />
      </Routes>
    </>
  );
};

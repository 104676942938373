import { onMount, createEffect } from 'solid-js';
import IconQuestions from '~/assets/images/settings/rental/questions.svg';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useTenantPortalInviteSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { SaveBtn } from '~/pages/settings/components/edit-form/SaveBtn';

export const InviteNotification = () => {
  const { getTenantPortalInviteSettings, tenantPortalInviteSettings, updateTenantPortalInviteSettings } = useTenantPortalInviteSettings();
  const { t } = useLocalization();
  const form = useForm();

  onMount(async () => {
    await getTenantPortalInviteSettings();
  });

  createEffect(() => form.setFormStore(tenantPortalInviteSettings() || {}));

  const isDirty = () => {
    const data = (tenantPortalInviteSettings() as MagicDoor.Api.TenantPortalInviteTextNotificationSettingsDto) || {};
    const keys = Object.keys(data) as (keyof typeof data)[];
    return keys.some((key) => JSON.stringify(form.formStore[key as string]) !== JSON.stringify(data[key]));
  };

  const handleSave = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;
    try {
      await updateTenantPortalInviteSettings(form.formStore as MagicDoor.Api.TenantPortalInviteTextNotificationSettingsDto);
      toast(t('Tenant portal invite notification texts settings saved successfully'), 'success');
    } catch (error) {
      toast(t('Failed to save tenant portal invite notification texts settings'), 'error');
    }
  };

  const onCancel = () => {
    form.setFormStore(tenantPortalInviteSettings);
  };

  return (
    <ThreeSectionBox
      class="h-fit"
      topTitle={<SettingTitle title={t('Portal invite notification texts')} icon={IconQuestions} />}
      middle={
        <div class="flex flex-col gap-5 p-4">
          <div class="flex flex-col gap-4 p-2 text-left">
            <Form defaultForm={form} class="my-3 grid gap-x-5 gap-y-6 text-left  ">
              <FormItem
                component={LabeledTextArea}
                formFieldName="portalInviteMessage"
                type="text"
                class="w-full"
                rules={[
                  {
                    required: true,
                    message: t('Please enter the portal invite notification texts'),
                  },
                ]}
                label={t('Tenant portal invite notification texts')}
                placeholder={t('Please enter')}
              />
            </Form>
            <SaveBtn dirty={isDirty()} onCancel={onCancel} onSave={handleSave} />
          </div>
        </div>
      }
    />
  );
};

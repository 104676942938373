import { createMemo, splitProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { Avatar } from '~/components/common/Avatar';
import { pickProps, TitleWithFeedbackIcon } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import { cn } from '~/utils/classnames';
import { getOwnerFullName } from './utils';
import type { TitleWithFeedbackIconProps } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import type { PolymorphicComponent } from '~/utils/types';

export const OwnerHeader: PolymorphicComponent<
  'div',
  {
    class?: string;
    owner?: MagicDoor.Api.OwnerDto;
  } & Partial<TitleWithFeedbackIconProps>
> = (props) => {
  const [, rest] = splitProps(props, ['as', 'class', 'owner']);
  const initials = createMemo(() => getOwnerFullName(props.owner));

  return (
    <TitleWithFeedbackIcon {...pickProps(rest)} type="tenants" entityId={props.owner?.id}>
      <Dynamic component={props.as || 'div'} class={cn('flex items-center gap-2', props.class)} {...rest}>
        <Avatar class="size-9 border-2 bg-white" name={initials()} data-slot="avatar" />
        <span data-slot="name" class="font-medium text-white">
          {initials()}
        </span>
      </Dynamic>
    </TitleWithFeedbackIcon>
  );
};

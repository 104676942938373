import { DragAnnotationUseCase } from './dragAnnotationUseCase';
import { StopTransformingUseCase } from './stopTransformingUseCase';
import { UseCase } from './useCase';
import type { TransformRequest } from './types/transformRequest';

export class StopDraggingUseCase extends UseCase {
  public async runLogic(request: TransformRequest) {
    await new DragAnnotationUseCase().execute(request);
    await new StopTransformingUseCase().execute();
  }
}

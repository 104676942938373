import { createEffect, createMemo, createSignal, For, Show } from 'solid-js';
import IconClose from '~/assets/images/common/close.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { BaseModal } from '~/components/modals/BaseModal';
import { IconAlert, Image } from '~/components/ui';
import { useCustomLayout, useLocalization } from '~/contexts/global';
import { getHomeAllComponent } from '~/pages/dashboard/constant';
import { useCreateModel } from '~/utils/hooks';
import type { Component, Setter } from 'solid-js';
import type { HomeLayout } from '~/pages/dashboard/type';

interface CustomModuleModalProps {
  visible?: Setter<boolean>[];
}

export const CustomModuleModal: Component<CustomModuleModalProps> = (props) => {
  const { t } = useLocalization();
  const [visible, setVisible] = useCreateModel(props, 'visible', false);
  const { homeLayout, setHomeLayout } = useCustomLayout();
  const [data, setData] = createSignal<HomeLayout[]>(homeLayout());

  const isSelect = (key: string) => {
    return data().some((item) => item.key === key);
  };

  const restoreDefault = () => {
    setData(getHomeAllComponent());
  };

  const confirm = () => {
    setHomeLayout(data());
    setVisible(false);
  };

  const onChange = (value: HomeLayout) => {
    if (isSelect(value.key)) {
      const index = data().findIndex((item) => item.key === value.key);
      setData((prev) => prev.toSpliced(index, 1));
    } else {
      setData((prev) => prev.concat([value]));
    }
  };

  const disabledRemoveModule = createMemo<boolean>(() => data().length <= 1);

  createEffect(() => {
    setData(homeLayout());
  });

  return (
    <BaseModal class="lg:w-modalSm" visible={visible()} title={t('Custom module')} onClose={() => setVisible(false)}>
      <div class="px-6">
        <div class="flex w-full flex-wrap items-center gap-2.5 py-4">
          <span class="text-sm text-text-level03">{t('The selected module')}:</span>
          <For each={data()}>
            {(item) => (
              <div class="flex items-center gap-1.5 rounded-sm bg-light-gray px-1.5 py-0.5 text-xs text-title-gray">
                {t(item.title)}
                <Show when={!(disabledRemoveModule() && isSelect(item.key))}>
                  <IconClose class="size-3.5 cursor-pointer text-text-level03" onclick={() => onChange(item)} />
                </Show>
              </div>
            )}
          </For>
        </div>
        <Show when={disabledRemoveModule()}>
          <div class="flex h-8 w-full items-center gap-1 rounded-lg bg-[#FA85110F] px-4">
            <IconAlert class="size-3.5 text-warning" />
            <span class="text-xs text-warning">{t('Keep at least one module')}</span>
          </div>
        </Show>
        <div class="flex h-[400px] flex-col gap-y-3 overflow-y-auto py-3">
          <For each={getHomeAllComponent()}>
            {(item) => (
              <div class="flex w-full gap-x-4 rounded-3xl border border-input-border p-3">
                <div class="flex size-40 items-center justify-center rounded-xl bg-[#F6F7F9]  ">
                  <Image src={item.thumbnail} />
                </div>
                <div class="flex flex-col  justify-center border-l pl-5">
                  <div class="text-base font-medium text-title-gray">{t(item.title)}</div>
                  <div class="mt-1 text-sm text-text-level03">{t(item.description)}</div>
                  <div
                    classList={{
                      'pointer-events-none cursor-not-allowed': disabledRemoveModule() && isSelect(item.key),
                      'cursor-pointer': !(disabledRemoveModule() && isSelect(item.key)),
                    }}
                    onClick={() => onChange(item)}
                    class="mt-5 flex w-32 items-center gap-2 rounded-lg border border-essential-colour p-2 text-base font-medium text-essential-colour">
                    <Checkbox disabled={disabledRemoveModule() && isSelect(item.key)} checked={isSelect(item.key)} />
                    {t('Activated')}
                  </div>
                </div>
              </div>
            )}
          </For>
        </div>
        <div class="flex items-center justify-between py-4">
          <div class="flex items-center gap-2">
            <Button variant="outlined" onClick={restoreDefault}>
              {t('Restore default')}
            </Button>
            <span class="text-xs text-text-level03">
              {t('Selected')} {data().length || 0}
            </span>
          </div>
          <div class="flex items-center gap-2">
            <Button variant="outlined" onClick={() => setVisible(false)}>
              {t('Cancel')}
            </Button>
            <Button onClick={confirm}>{t('Confirm')}</Button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

import { createSignal } from 'solid-js';
import IconDirect from '~/assets/images/chat/chatDirect.svg';
import IconLease from '~/assets/images/chat/chatLease.svg';
import IconMaintenance from '~/assets/images/chat/chatMaintenance.svg';
import IconOwner from '~/assets/images/chat/chatOwner.svg';
import IconTenant from '~/assets/images/chat/chatTenant.svg';
import IconVendor from '~/assets/images/chat/chatVendor.svg';
import IconChatWorkOrder from '~/assets/images/chat/chatWorkOrder.svg';
import IconChatWorkOrderGroup from '~/assets/images/chat/chatWorkOrderGroup.svg';

export const [newChatAdded, setNewChatAdded] = createSignal<`${MagicDoor.Api.EntityType}` | 'unknown'>('unknown');

export const categoryIcons: { [key: string]: string } = {
  'Lease chat': IconLease,
  'Tenant chat': IconTenant,
  'Owner chat': IconOwner,
  'Vendor chat': IconVendor,
  'Work order chat': IconChatWorkOrder,
  'Work order group chat': IconChatWorkOrderGroup,
  'Maintenance chat': IconMaintenance,
  'Direct message': IconDirect,
};

export type CategorizedChats = {
  [key in keyof typeof chatCategories]: MagicDoor.Api.HydratedMagicChatDto[];
};

export const chatCategories: { [key: string]: string } = {
  lease: 'Lease chat',
  tenant: 'Tenant chat',
  owner: 'Owner chat',
  vendor: 'Vendor chat',
  maintenanceRequest: 'Maintenance chat',
  workOrder: 'Work order chat',
  workOrderGroup: 'Work order group chat',
  unknown: 'Direct message',
};

export function parseChats(rawChats: MagicDoor.Api.HydratedMagicChatDto[]): CategorizedChats {
  const categorizedChats: CategorizedChats = Object.keys(chatCategories).reduce((acc, key) => {
    acc[chatCategories[key as keyof typeof chatCategories]] = [];
    return acc;
  }, {} as CategorizedChats);

  rawChats.forEach((chat) => {
    const categoryTitle = chat.type ? chatCategories[chat.type] : 'Unknown';
    if (categoryTitle) {
      categorizedChats[categoryTitle].push(chat);
    }
  });

  return categorizedChats;
}

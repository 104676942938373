import IconTime from '~/assets/images/common/time.svg?component-solid';
import { useLocalization } from '~/contexts/global';
import { dateFormat } from '~/utils/date';
interface Props {
  time: string;
  class?: string;
}

export const LatestUpdatedAt = (props: Props) => {
  const { t } = useLocalization();
  return (
    <div class={`flex items-center gap-1 ${props.class}`}>
      <IconTime />
      <span class="text-text-level03">{t('Latest updated time:')}</span>
      <span class="text-[#504C5F]">{props.time ? dateFormat('', props.time) : '-'}</span>
    </div>
  );
};

import { createEffect, on } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import { Description } from '~/components/common/Description';
import { useLocalization } from '~/contexts/global';
import { useAddOrEditMaintenanceModalContext } from './Context';
import type { UploaderFile } from '~/components/common/Upload/Interface';

export const EssentialInfomation = () => {
  const { t } = useLocalization();
  const { getUnits, getLeases, setStore } = useAddOrEditMaintenanceModalContext();
  const { formStore, resetFields } = useFormContext();

  createEffect(() => {
    getUnits(formStore.propertyId);
  });

  createEffect(() => {
    getLeases({ unitId: formStore.unitId, propertyId: formStore.propertyId });
  });

  createEffect(
    on(
      () => formStore.propertyId,
      () => resetFields(['unitId', 'leaseId']),
      { defer: true }
    )
  );
  createEffect(
    on(
      () => formStore.unitId,
      () => resetFields(['leaseId']),
      { defer: true }
    )
  );

  return (
    <div class="flex flex-col gap-8">
      <FormItem
        label={t(`Description`)}
        rules={[{ required: true, message: t(`Please input description`) }]}
        formFieldName="description"
        component={Description}
        placeholder={t('Please enter')}
        rows={4}
        onFilesListChange={(files: UploaderFile[]) => {
          setStore({ newFiles: files });
        }}
      />
    </div>
  );
};

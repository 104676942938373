import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { maintenanceRequestSettingsRepository } from '~/repositories/settings/maintenanceRequestSettingsRepository';
import type { Accessor } from 'solid-js';

interface MaintenanceRequestSettingsContextValue {
  deleteMaintenanceRequestSettingsByLevel: (level: string, levelId: string) => Promise<void>;
  addMaintenanceRequestSettings: (payload: MagicDoor.Api.MaintenanceRequestSettingsDto) => Promise<void>;
  addMaintenanceRequestSettingsByLevel: (
    level: string,
    levelId: string,
    payload: MagicDoor.Api.MaintenanceRequestSettingsDto
  ) => Promise<void>;
  getMaintenanceRequestSettings: () => Promise<MagicDoor.Api.MaintenanceRequestSettingsDto | undefined>;
  getMaintenanceRequestSettingsByLevel: (
    level: string,
    levelId: string
  ) => Promise<MagicDoor.Api.MaintenanceRequestSettingsDto | undefined>;
  getAllMaintenanceRequestSettings: () => Promise<MagicDoor.Api.MaintenanceRequestSettingsDtoSettingsWithOverridesDto | undefined>;
  isLoading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
  maintenanceRequestSettings: Accessor<MagicDoor.Api.MaintenanceRequestSettingsDto | undefined>;
  allMaintenanceRequestSettings: Accessor<MagicDoor.Api.MaintenanceRequestSettingsDtoSettingsWithOverridesDto | undefined>;
}

export const [MaintenanceRequestSettingsProvider, useMaintenanceRequestSettings] =
  createMagicDoorContext<MaintenanceRequestSettingsContextValue>('MaintenanceRequestSettings', () => {
    const repo = new maintenanceRequestSettingsRepository();
    const [maintenanceRequestSettings, setMaintenanceRequestSettings] = createSignal<
      MagicDoor.Api.MaintenanceRequestSettingsDto | undefined
    >(undefined);
    const [allMaintenanceRequestSettings, setAllMaintenanceRequestSettings] = createSignal<
      MagicDoor.Api.MaintenanceRequestSettingsDtoSettingsWithOverridesDto | undefined
    >(undefined);
    const [isLoading, setIsLoading] = createSignal<boolean>(false);
    const [error, setError] = createSignal<Error | undefined>(undefined);

    const addMaintenanceRequestSettings = async (payload: MagicDoor.Api.MaintenanceRequestSettingsDto) => {
      setIsLoading(true);
      try {
        await repo.addMaintenanceRequestSettings(payload);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const addMaintenanceRequestSettingsByLevel = async (
      level: string,
      levelId: string,
      payload: MagicDoor.Api.MaintenanceRequestSettingsDto
    ) => {
      setIsLoading(true);
      try {
        await repo.addMaintenanceRequestSettingsByLevel(level, levelId, payload);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const getMaintenanceRequestSettings = async (): Promise<MagicDoor.Api.MaintenanceRequestSettingsDto | undefined> => {
      setIsLoading(true);
      try {
        const result = await repo.getMaintenanceRequestSettings();
        setMaintenanceRequestSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const getMaintenanceRequestSettingsByLevel = async (
      level: string,
      levelId: string
    ): Promise<MagicDoor.Api.MaintenanceRequestSettingsDto | undefined> => {
      setIsLoading(true);
      try {
        const result = await repo.getMaintenanceRequestSettingsByLevel(level, levelId);
        setMaintenanceRequestSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const getAllMaintenanceRequestSettings = async (): Promise<
      MagicDoor.Api.MaintenanceRequestSettingsDtoSettingsWithOverridesDto | undefined
    > => {
      setIsLoading(true);
      try {
        const result = await repo.getAllMaintenanceRequestSettings();
        setAllMaintenanceRequestSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const deleteMaintenanceRequestSettingsByLevel = async (level: string, levelId: string) => {
      setIsLoading(true);
      try {
        await repo.deleteMaintenanceRequestSettingsByLevel(level, levelId);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    return {
      deleteMaintenanceRequestSettingsByLevel,
      allMaintenanceRequestSettings,
      addMaintenanceRequestSettings,
      addMaintenanceRequestSettingsByLevel,
      getMaintenanceRequestSettings,
      getMaintenanceRequestSettingsByLevel,
      getAllMaintenanceRequestSettings,
      isLoading,
      maintenanceRequestSettings,
      error,
    };
  });

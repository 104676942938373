import { useNavigate } from '@solidjs/router';
import { createMemo, createSignal, onMount, Show } from 'solid-js';
import { useForm } from '~/components/common/BetterForm';
import Breadcrumb from '~/components/common/Breadcrumb';
import { RingLoader } from '~/components/common/Loaders';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useOwnerContribution } from '~/contexts/local';
import BillStore from '~/pages/bills/store';
import { AddContributionForm } from './components/AddContributionForm';

export const AddContributionCom = () => {
  const { t } = useLocalization();
  const { addOwnerContribution } = useOwnerContribution();
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = createSignal(false);

  const breadcrumbItems = createMemo(() => [
    { label: t('Owner contribution'), link: '/accounting/owner-funds/contributions' },
    { label: t('Add a contribution') },
  ]);

  const form = useForm();
  const [submitText, setSubmitText] = createSignal<string>('');
  const { store, paymentMethodOptions, getVendors, getProperties, getChartOfAccounts, getBankAccounts } = BillStore;

  let addContributionFormRef: any;

  const handleAddContribution = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) throw new Error();
    setSubmitLoading(true);

    try {
      setSubmitText(`${t('Add a contribution')}...`);
      const { ownerId = '', ...rest } = { ...form.formStore };
      await addOwnerContribution(rest as MagicDoor.Api.RegisterOwnerContributionDto, ownerId);

      navigate(-1);
      toast.success(t('{name} has been added successfully', { name: t('Owner contribution') }));
    } catch (err) {
      console.error(err);

      throw err;
    } finally {
      setSubmitText('');
      setSubmitLoading(false);
    }
  };

  onMount(() => {
    getVendors();
    getProperties();
    getChartOfAccounts();
    getBankAccounts();
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="relative">
        <MultiStepsPanel
          contentClass="[&>div]:h-full"
          onClose={() => navigate(-1)}
          title={t('Add a contribution')}
          disableNext={!form.isValidate}
          onSubmit={handleAddContribution}
          loading={true}>
          <AddContributionForm
            paymentMethodOptions={paymentMethodOptions}
            ref={addContributionFormRef}
            form={form}
            store={store}
            class="mx-auto my-10 w-full max-w-[1200px]"
          />
        </MultiStepsPanel>
        <Show when={submitLoading()}>
          <div class="absolute inset-0 z-50 mx-8 flex flex-col items-center justify-center rounded-lg bg-white">
            <RingLoader color="#a126ec" showIcon={true} size={100} borderWidth={8} text={submitText()} />
          </div>
        </Show>
      </div>
    </>
  );
};

import { createMagicDoorContext } from '~/contexts/utils';
import { CompaniesRepository } from '~/repositories/companiesRepository';
import { createLazyResource, createMutation } from '~/utils/resource';

const repo = new CompaniesRepository();

export const [CompaniesProvider, useCompanies] = createMagicDoorContext('Companies', () => {
  const [companies, { fetch, refetch, mutate }] = createLazyResource(() => repo.getCompanies());

  const updateSubUrl = createMutation(async (model: MagicDoor.Api.CompanySubUrlDto) => {
    const updated = await repo.updateSubUrl(model);
    mutate(updated);
    return updated;
  });

  const validateSubUrl = createMutation(async (model: MagicDoor.Api.CompanySubUrlDto) => {
    return await repo.validateSubUrl(model);
  });

  return {
    get companies() {
      fetch();
      return companies;
    },
    refetch,
    updateSubUrl,
    validateSubUrl,
  };
});

import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { companySettingsRepository } from '~/repositories/settings/companySettingsRepository';
import type { Accessor } from 'solid-js';

interface CompanySettingsContextValue {
  changeCompanySettings: (payload: MagicDoor.Api.CompanySettingsDto) => Promise<void>;
  getCompanySettings: () => Promise<MagicDoor.Api.CompanySettingsDto | undefined>;
  isLoading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
  companySettings: Accessor<MagicDoor.Api.CompanySettingsDto | undefined>;
}

export const [CompanySettingsProvider, useCompanySettings] = createMagicDoorContext<CompanySettingsContextValue>('CompanySettings', () => {
  const repo = new companySettingsRepository();
  const [companySettings, setCompanySettings] = createSignal<MagicDoor.Api.CompanySettingsDto | undefined>(undefined);
  const [isLoading, setIsLoading] = createSignal<boolean>(false);
  const [error, setError] = createSignal<Error | undefined>(undefined);

  const changeCompanySettings = async (payload: MagicDoor.Api.CompanySettingsDto) => {
    setIsLoading(true);
    try {
      await repo.changeCompanySettings(payload);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const getCompanySettings = async (): Promise<MagicDoor.Api.CompanySettingsDto | undefined> => {
    setIsLoading(true);
    try {
      const result = await repo.getCompanySettings();
      setCompanySettings(result);
      return result;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    changeCompanySettings,
    getCompanySettings,
    isLoading,
    companySettings,
    error,
  };
});

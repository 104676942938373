import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { RentPaymentSettingsRepository } from '~/repositories/settings/rentPaymentSettingsRepository';
import type { Accessor } from 'solid-js';

interface RentPaymentSettingsContextValue {
  addRentPaymentSettings: (payload: MagicDoor.Api.RentPaymentSettingsDto) => Promise<void>;
  addRentPaymentSettingsByLevel: (level: string, levelId: string, payload: MagicDoor.Api.RentPaymentSettingsDto) => Promise<void>;
  getRentPaymentSettings: () => Promise<MagicDoor.Api.RentPaymentSettingsDto | undefined>;
  getRentPaymentSettingsByLevel: (level: string, levelId: string) => Promise<MagicDoor.Api.RentPaymentSettingsDto | undefined>;
  getAllRentPaymentSettings: () => Promise<MagicDoor.Api.RentPaymentSettingsDtoSettingsWithOverridesDto | undefined>;
  isLoading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
  rentPaymentSettingsWithNoOverrides: Accessor<MagicDoor.Api.RentPaymentSettingsDto | undefined>;
  allRentPaymentSettings: Accessor<MagicDoor.Api.RentPaymentSettingsDtoSettingsWithOverridesDto | undefined>;
  deleteRentPaymentSettingsByLevel: (level: string, levelId: string) => Promise<void>;
}

export const [RentPaymentSettingsProvider, useRentPaymentSettings] = createMagicDoorContext<RentPaymentSettingsContextValue>(
  'RentPaymentSettings',
  () => {
    const repo = new RentPaymentSettingsRepository();
    const [rentPaymentSettingsWithNoOverrides, setRentPaymentSettingsWithNoOverrides] = createSignal<
      MagicDoor.Api.RentPaymentSettingsDto | undefined
    >(undefined);
    const [allRentPaymentSettings, setAllRentPaymentSettings] = createSignal<
      MagicDoor.Api.RentPaymentSettingsDtoSettingsWithOverridesDto | undefined
    >(undefined);
    const [isLoading, setIsLoading] = createSignal<boolean>(false);
    const [error, setError] = createSignal<Error | undefined>(undefined);

    const addRentPaymentSettings = async (payload: MagicDoor.Api.RentPaymentSettingsDto) => {
      setIsLoading(true);
      try {
        await repo.addRentPaymentSettings(payload);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const addRentPaymentSettingsByLevel = async (level: string, levelId: string, payload: MagicDoor.Api.RentPaymentSettingsDto) => {
      setIsLoading(true);
      try {
        await repo.addRentPaymentSettingsByLevel(level, levelId, payload);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const getRentPaymentSettings = async (): Promise<MagicDoor.Api.RentPaymentSettingsDto | undefined> => {
      setIsLoading(true);
      try {
        const result = await repo.getRentPaymentSettings();
        setRentPaymentSettingsWithNoOverrides(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const getRentPaymentSettingsByLevel = async (
      level: string,
      levelId: string
    ): Promise<MagicDoor.Api.RentPaymentSettingsDto | undefined> => {
      setIsLoading(true);
      try {
        const result = await repo.getRentPaymentSettingsByLevel(level, levelId);
        setRentPaymentSettingsWithNoOverrides(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const getAllRentPaymentSettings = async (): Promise<MagicDoor.Api.RentPaymentSettingsDtoSettingsWithOverridesDto | undefined> => {
      setIsLoading(true);
      try {
        const result = await repo.getAllRentPaymentSettings();
        setAllRentPaymentSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const deleteRentPaymentSettingsByLevel = async (level: string, levelId: string) => {
      setIsLoading(true);
      try {
        await repo.deleteRentPaymentSettingsByLevel(level, levelId);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    return {
      deleteRentPaymentSettingsByLevel,
      addRentPaymentSettings,
      addRentPaymentSettingsByLevel,
      getRentPaymentSettings,
      getRentPaymentSettingsByLevel,
      getAllRentPaymentSettings,
      allRentPaymentSettings,
      isLoading,
      rentPaymentSettingsWithNoOverrides,
      error,
    };
  }
);

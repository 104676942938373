import { FormItem } from '~/components/common/BetterForm';
import { useCategoryComponentWrapper } from '~/components/common/Category';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { useLocalization } from '~/contexts/global';

const PaymentSettingsSection = () => {
  const { onClickSection } = useCategoryComponentWrapper();

  const { t } = useLocalization();

  return (
    <div id="payment-settings">
      <div class="sticky top-0 z-10 mb-3 border-b bg-white py-3 text-lg font-semibold text-text-level01">{t('Payment settings')}</div>
      <FormItem
        class="py-6"
        onClick={() => onClickSection('paymentSettings')}
        label={t('One payment per bill')}
        options={[
          {
            label: t('Yes'),
            value: true,
          },
          {
            label: t('No'),
            value: false,
          },
        ]}
        placeholder={t('Please select')}
        onChangeMethodName="onChange"
        formFieldName={['paymentSettings', 'onePaymentPerBill']}
        component={DropdownMenu}
      />
    </div>
  );
};

export default PaymentSettingsSection;

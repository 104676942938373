import { useNavigate } from '@solidjs/router';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization, usePortfolios } from '~/contexts/global';
import { formConfirmationContent } from '~/utils/constant';
import type { Component } from 'solid-js';

export const AddPortfolioModal: Component = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const form = useForm();
  const { current, addPortfolio } = usePortfolios();

  const handleClose = () => {
    const currentId = current()?.id;
    navigate(currentId ? `/portfolios/${currentId}` : '/portfolios', { scroll: false, replace: true });
  };

  const handleSubmit = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;
    const created = await addPortfolio(form.formStore.portfolio);
    navigate(`/portfolios/${created.id}`, { replace: true });
    toast.success(t('{name} has been added successfully', { name: created.name }));
  };

  return (
    <Modal
      visible
      class="animate-zoom-in"
      loading={addPortfolio.loading}
      title={t('Create a portfolio')}
      doneText={t('Confirm')}
      onDone={handleSubmit}
      onCancel={handleClose}
      confirmation={form.formStore.portfolio ? formConfirmationContent(t) : false}>
      <Form defaultForm={form} class="mb-6 p-8" onFormSubmit={handleSubmit}>
        <FormItem
          component={LabeledTextInput}
          formFieldName="portfolio"
          rules={[
            { required: true, message: t('Portfolio name is required') },
            { length: 150, message: t('{name} must be {length} characters or less', { name: t('Portfolio'), length: '150'})}
          ]}
          label={t('Portfolio')}
          placeholder={t('Please enter')}
        />
      </Form>
    </Modal>
  );
};

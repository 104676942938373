import { Route, Routes } from '@solidjs/router';
import { AddTenantPage } from './add-tenant';
import { EditTenantPage } from './edit-tenant';
import { ListTenantsPage } from './list-tenants';
import { TenantDetailsPage } from './tenant-details';

const TenantsRoutes = () => {
  return (
    <Routes>
      <Route path="/add" component={AddTenantPage} />
      <Route path="/:tenantId/edit" component={EditTenantPage} />
      <Route path="/:tenantId/*" component={TenantDetailsPage} />
      <Route path="*" component={ListTenantsPage} />
    </Routes>
  );
};

export default TenantsRoutes;

import { Button } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import type { ButtonProps } from '~/components/common/Buttons';

export const Footer = (props: { onCancel: () => void; onOk: () => void; okProps?: Partial<ButtonProps> }) => {
  const { t } = useLocalization();
  return (
    <div class="flex justify-end border-t border-partingline p-6">
      <Button
        class="mr-2"
        variant="outlined"
        onClick={() => {
          props.onCancel();
        }}>
        {t('Cancel')}
      </Button>
      <Button
        {...props.okProps}
        onClick={() => {
          props.onOk();
        }}>
        {t('Save')}
      </Button>
    </div>
  );
};

import { createEffect, getOwner } from 'solid-js';
import { FormItem, SegmentFormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import LabeledAddressInput from '~/components/common/Inputs/form-inputs/LabeledAddressInput';
import { propertyTypes } from '~/components/properties';
import { useLocalization, useProperties } from '~/contexts/global';
import { addressFormat, addressRequired } from '~/utils/formRules';

export const PropertyInformationSection = () => {
  const { t } = useLocalization();
  const owner = getOwner();
  const form = useFormContext();
  const { current: currentProperty } = useProperties();

  const propertyTypeOptions = Object.entries(propertyTypes).map(([key, value]) => ({ label: t(value.name), value: key }));

  createEffect(() => {
    if (!currentProperty()) {
      form.setFieldsValue({
        type: 'singleFamily',
      });
    } else {
      form.setFieldsValue(currentProperty());
    }
  });

  const progress = () => {
    let fieldsFilled = 0;
    if (form.formStore.name) fieldsFilled++;
    if (form.formStore.address?.streetAddress1) fieldsFilled++;
    if (form.formStore.address?.city) fieldsFilled++;
    if (form.formStore.address?.zipCode) fieldsFilled++;
    if (form.formStore.address?.state) fieldsFilled++;
    if (form.formStore.type) fieldsFilled++;
    return fieldsFilled / 6;
  };

  return (
    <SegmentFormItem title={t('Property information')} progress={progress()} error={!form.isValidate} required>
      <div class="grid grid-cols-2 gap-7 gap-y-8 p-8">
        <FormItem
          class="col-span-full lg:col-span-1"
          label={t('Property name')}
          placeholder={t('Please enter the name')}
          component={LabeledTextInput}
          formFieldName="name"
          rules={[
            { required: true, message: t('Please enter the name') },
            { length: 200, message: t('Name cannot exceed 200 characters') },
          ]}
        />
        <FormItem
          class="col-span-full lg:col-span-1"
          label={t('Property type')}
          formFieldName="type"
          options={propertyTypeOptions}
          placeholder={t('Select a property type')}
          component={DropdownMenu}
          onChangeMethodName="onChange"
          rules={[{ required: true, message: t('Please select') }]}
        />
        <FormItem
          class="col-span-full"
          component={LabeledAddressInput}
          formFieldName="address"
          required
          rules={[addressRequired(t), addressFormat(owner, t)]}
        />
      </div>
    </SegmentFormItem>
  );
};

import { createMemo, createSignal } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { LabeledGroup } from './LabeledGroup';
import type { LabeledGroupProps } from './LabeledGroup';
import type { Component } from 'solid-js';

export interface TextAreaProps extends LabeledGroupProps {
  placeholder?: string;
  required?: boolean;
  value: string | number | undefined;
  rows?: number;
  onInput: (e: string) => void;
  onClick?: (isMouseDown: boolean) => void;
  validationFunction?: (value: string | number) => string | undefined;
  extraItem?: Component;
  minlength?: number;
  maxlength?: number;
}

const LabeledTextArea: Component<TextAreaProps> = (props) => {
  const [isTouched, setTouched] = createSignal<boolean>(false);
  const [setError] = createSignal<string | undefined>(undefined);

  const isInvalid = createMemo(() => !!props.error);
  const errorMessage = createMemo(() => {
    if (!isTouched() || !props.validationFunction) return undefined;
    return handleInputValidation(props.value, props.validationFunction, setError);
  });

  const handleInput = (value: string) => {
    props.onInput(value);
    setTouched(true);
  };

  const handleBlur = (): boolean => setTouched(true);

  return (
    <LabeledGroup {...props} error={errorMessage()}>
      <div
        class="border- w-full rounded-md border bg-inputbox-bg px-3 py-2 text-sm text-black placeholder:text-auxiliary-text focus:outline-none focus:ring-1 focus:ring-essential-colour "
        classList={{
          'border-red-300': isInvalid(),
          'ring-0': isInvalid(),
        }}>
        <textarea
          class="w-full bg-inputbox-bg focus:outline-none focus:ring-0"
          value={props.value || ''}
          required={props.required}
          rows={props.rows}
          minlength={props.minlength}
          maxlength={props.maxlength}
          onInput={(e) => handleInput(e.target.value)}
          onBlur={handleBlur}
          placeholder={props.placeholder}
          onMouseDown={() => props.onClick && props.onClick(true)}
          onMouseUp={() => props.onClick && props.onClick(false)}
        />
        <Dynamic component={props.extraItem} />
      </div>
    </LabeledGroup>
  );
};
export default LabeledTextArea;

const handleInputValidation = (
  value: string | number | undefined,
  validationFunction: (value: string | number) => string | undefined,
  setErrorFunction: (value: string | number | undefined) => void
) => {
  if (value) {
    const errorMessage = validationFunction(value);
    setErrorFunction(errorMessage);
    return errorMessage;
  }
};

import { Show } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { OtherTargetsList } from '~/pages/announcements/components/OtherTargetsList';
import { PortfolioTargetList } from '~/pages/announcements/components/PortfolioTargetList';
import { renderField, renderAttachments } from '~/pages/announcements/components/Utils';
type ReviewProps = {
  reviewData: any;
  model: any;
};

export const Review = (props: ReviewProps) => {
  const { t } = useLocalization();

  return (
    <div class="flex flex-col gap-4">
      <h4 class="border-b text-xl font-semibold">{t('Review')}</h4>

      <Show when={props.model}>
        <div class="w-1/2 rounded-md border p-3">
          <h5 class="font-semibold">{t('Target type')}</h5>
          <p>{props.model.targetType}</p>
        </div>

        <div class="normal-case">
          <h5 class="font-semibold">{t('Filters')}</h5>
          <ul class="list-inside list-disc">
            <li>
              {t('Has balance due')}:
              <span class="pl-1">
                {props.model.hasBalanceDue === true ? t('Yes') : props.model.hasBalanceDue === false ? t('No') : t('Not selected')}
              </span>
            </li>
            <li>
              {t('Include ended lease')}: {props.model.includeEndedLease ? t('Yes') : t('No')}
            </li>
          </ul>
        </div>

        <div>
          <h4 class="border-b text-xl font-semibold">{t('Content')}</h4>
          <div class="flex flex-col gap-2 py-4">
            <p>
              <strong>{t('Subject')}:</strong> {props.model.subject || t('Announcement')}
            </p>
            <div>
              <Show when={props.model.chatMessage}>{renderField('Chat message', props.model.chatMessage, t)}</Show>
              {renderAttachments(props.model.chatAttachments, 'Chat attachments', t)}
            </div>

            <div>
              <Show when={props.model.emailMessage}>{renderField('Email message', props.model.emailMessage, t)}</Show>
              {renderAttachments(props.model.emailAttachments, 'Email attachments', t)}
            </div>
          </div>
        </div>
      </Show>

      <Show when={props.reviewData}>
        <Show when={props.reviewData.portfolioTargets && props.reviewData.portfolioTargets.length > 0}>
          <PortfolioTargetList portfolioTargets={props.reviewData.portfolioTargets} />
        </Show>
        <Show when={!props.reviewData.portfolioTargets || props.reviewData.portfolioTargets.length === 0}>
          <OtherTargetsList targets={props.reviewData.owners} title="Owners" />
          <OtherTargetsList targets={props.reviewData.vendors} title="Vendors" />
          <OtherTargetsList targets={props.reviewData.tenants} title="Tenants" />
        </Show>
      </Show>
    </div>
  );
};

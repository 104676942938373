import { Route, Routes } from '@solidjs/router';
import { AddOrEditChartOfAccountModal } from './list-chart-of-accounts/AddOrEditChartOfAccountModal';
import ListChartOfAccounts from './list-chart-of-accounts/ListChartOfAccounts';

const ChartOfAccountRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="*" component={ListChartOfAccounts} />
      </Routes>
      <Routes>
        <Route path={['/add/:type', '/edit/:chartOfAccountId/:type']} component={AddOrEditChartOfAccountModal} />
      </Routes>
    </>
  );
};

export default ChartOfAccountRoutes;

import { BankAccountProvider } from '~/contexts/local';
import EditBankAccountPage from './EditBankAccountPage';

const BankAccountEditPage = () => {
  return (
    <BankAccountProvider>
      <EditBankAccountPage />
    </BankAccountProvider>
  );
};

export default BankAccountEditPage;

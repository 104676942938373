import UnderConstructionImg from '~/assets/images/dashboard/underConstruction.png';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

const UnderConstruction: Component<{ imgClass?: string }> = (props) => {
  const { t } = useLocalization();
  return (
    <div class="absolute inset-0 z-10 flex size-full items-center justify-center bg-white/80">
      <div class="flex flex-col items-center justify-center">
        <img src={UnderConstructionImg} class={props.imgClass} alt="UnderConstructionImg" />
        <div class="text-sm font-semibold text-essential-colour">{t('Under construction')}</div>
      </div>
    </div>
  );
};

export default UnderConstruction;

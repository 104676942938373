import { onMount, createMemo } from 'solid-js';
import IconChargeLateFees from '~/assets/images/settings/property/propertyChargeLateFee.svg';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRentalApplicationChartOfAccountsSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';

export const ChartOfAccount = () => {
  const { t } = useLocalization();
  const {
    rentalApplicationChartOfAccountsSetting,
    getRentalApplicationChartOfAccountsSetting,
    updateRentalApplicationChartOfAccountsSetting,
  } = useRentalApplicationChartOfAccountsSettings();

  onMount(async () => {
    await getRentalApplicationChartOfAccountsSetting();
  });

  const handleSave = async (data: MagicDoor.Api.RentalApplicationChartOfAccountSettingsDto) => {
    await updateRentalApplicationChartOfAccountsSetting(data);
    toast(t('Chart of account settings saved successfully'), 'success');
  };

  const sections = createMemo(() => {
    return [
      {
        field: 'rentalApplicationChartOfAccountId',
        label: 'The rental application chart of account',
        type: 'chartOfAccountSelect',
      },
      {
        field: 'creditReportChartOfAccountId',
        label: 'The creditReport chart of account',
        type: 'chartOfAccountSelect',
      },
      {
        field: 'disputeFeeChartOfAccountId',
        label: 'The disputeFee chart of account',
        type: 'chartOfAccountSelect',
      },
    ];
  });

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topTitle={<SettingTitle title={t('Chart of account')} icon={IconChargeLateFees} />}
        middle={
          <div class="flex flex-col gap-5 p-4">
            <div class="flex flex-col gap-4 p-2 text-left">
              <EditForm
                sections={sections()}
                onOk={(data) => handleSave(data)}
                model={rentalApplicationChartOfAccountsSetting() || ({} as MagicDoor.Api.RentalApplicationChartOfAccountSettingsDto)}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

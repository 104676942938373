import { onMount, createEffect } from 'solid-js';
import IconQuestions from '~/assets/images/settings/rental/questions.svg';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRentalApplicationSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { SaveBtn } from '~/pages/settings/components/edit-form/SaveBtn';
import { QuestionList } from './components/QuestionList';

export const StandardQuestions = () => {
  const { getRentalApplicationSettings, rentalApplicationSettings, updateRentalApplicationSettings } = useRentalApplicationSettings();
  const { t } = useLocalization();
  const form = useForm();

  onMount(async () => {
    await getRentalApplicationSettings();
  });

  createEffect(() => form.setFormStore(rentalApplicationSettings() || {}));

  const isDirty = () => {
    const data = (rentalApplicationSettings() as MagicDoor.Api.RentalApplicationSettingsDto) || {};
    const keys = Object.keys(data) as (keyof typeof data)[];
    return keys.some((key) => JSON.stringify(form.formStore[key as string]) !== JSON.stringify(data[key]));
  };

  const handleSave = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;
    try {
      const formData = {
        ...form.formStore,
        questions: form.formStore.questions.map((item: string) => item.replace(/^\s*/g, '')).filter((item: string) => item),
      };
      await updateRentalApplicationSettings(formData as MagicDoor.Api.RentalApplicationSettingsDto);
      form.setFormStore(formData);
      toast(t('Standard questions settings saved successfully'), 'success');
    } catch (error) {
      toast(t('Failed to save standard questions settings'), 'error');
    }
  };

  const onCancel = () => {
    form.setFormStore(rentalApplicationSettings);
  };

  return (
    <ThreeSectionBox
      class="h-fit"
      topTitle={<SettingTitle title={t('Standard questions')} icon={IconQuestions} />}
      middle={
        <div class="flex flex-col gap-5 p-4">
          <div class="flex flex-col gap-4 p-2 text-left">
            <Form defaultForm={form} class="my-3 grid gap-x-5 gap-y-6 text-left  ">
              <FormItem
                component={LabeledNumberInput}
                formFieldName="rentalApplicationRecencyInDays"
                class="w-1/2"
                rules={[
                  {
                    validator: (value: number) => value <= 100 && value >= 0,
                    message: t('The value of rentalApplicationRecencyInDays is between 0 and 100'),
                  },
                ]}
                label={t('Rental application recency in days')}
                placeholder={t('Please enter')}
              />
              <FormItem label={t('Questions')} component={QuestionList} formFieldName="questions" />
            </Form>
            <SaveBtn dirty={isDirty()} onCancel={onCancel} onSave={handleSave} />
          </div>
        </div>
      }
    />
  );
};

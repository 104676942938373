import { LatestPaymentsTableSkeleton } from './LatestPaymentsTableSkeleton';
import { MaintenanceRequestsTableSkeleton } from './MaintenanceRequestsTableSkeleton';
import { OngoingLeasesTableSkeleton } from './OngoingLeasesTableSkeleton';
import { UnitBalancesSkeleton } from './UnitBalancesSkeleton';
import { UnitSummarySkeleton } from './UnitSummarySkeleton';
import type { Component } from 'solid-js';
export const OverviewSkeleton: Component = () => {
  return (
    <div class="grid grid-cols-3 gap-5 text-left">
      <UnitSummarySkeleton class="col-span-3 lg:col-span-2" />
      <UnitBalancesSkeleton class="col-span-3 lg:col-span-1" />
      <OngoingLeasesTableSkeleton class="col-span-full" />
      <LatestPaymentsTableSkeleton class="col-span-3 lg:col-span-2" />
      <MaintenanceRequestsTableSkeleton class="col-span-3 lg:col-span-1" />
    </div>
  );
};

import { BaseRestRepository } from '~/repositories/baseRestRepository';

export const PROPERTY_MANAGERS_URL = '/api/property-managers';

export class PropertyManagersRepository extends BaseRestRepository {
  public async getPropertyManagers(): Promise<MagicDoor.Api.PropertyManagerDto[]> {
    const url = PROPERTY_MANAGERS_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async createPropertyManager(propertyManager: MagicDoor.Api.PropertyManagerDto): Promise<MagicDoor.Api.PropertyManagerDto> {
    const url = PROPERTY_MANAGERS_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(propertyManager),
    });
    return this.getJsonResponse(response);
  }

  public async updatePropertyManager(
    id: string,
    propertyManager: MagicDoor.Api.UpdatePropertyManagerDto
  ): Promise<MagicDoor.Api.PropertyManagerDto> {
    const url = `${PROPERTY_MANAGERS_URL}/${id}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(propertyManager),
    });
    return this.getJsonResponse(response);
  }

  public async deletePropertyManager(id: string): Promise<void> {
    const url = `${PROPERTY_MANAGERS_URL}/${id}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }

  public async getPropertyManagerById(id: string): Promise<MagicDoor.Api.PropertyManagerDto> {
    const url = `${PROPERTY_MANAGERS_URL}/${id}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async changePropertyManagerPassword(id: string, currentPassword: string, newPassword: string): Promise<void> {
    const url = `${PROPERTY_MANAGERS_URL}/${id}/password`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify({ currentPassword: currentPassword, clearTextPassword: newPassword }),
    });
    return this.getJsonResponse(response);
  }

  public async changePropertyManagerActiveStatus(id: string, active: boolean): Promise<void> {
    const url = `${PROPERTY_MANAGERS_URL}/${id}/active`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify({ active }),
    });
    return this.getJsonResponse(response);
  }

  public async getPropertyManagerPics(id: string): Promise<any> {
    const url = `${PROPERTY_MANAGERS_URL}/${id}/image.webp`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
}

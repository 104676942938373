import { Panel } from '~/components/common/Panels';
import { getDeposit, isEndedLease, isEvictionLease } from '~/components/leases/utils';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';

type BalancesProps = {
  class?: string;
  lease: MagicDoor.Api.HydratedLeaseDto | undefined;
};

export const RentAmount: Component<BalancesProps> = (props) => {
  const { t } = useLocalization();

  return (
    <Panel title={t('Rent amount')} class={cn('p-2', props.class)}>
      <div
        class="mt-1 flex flex-col items-center justify-center rounded-lg  py-3"
        classList={{
          'bg-success-light': !isEvictionLease(props.lease) && !isEndedLease(props.lease),
          'bg-[#F3F2F9]': isEvictionLease(props.lease) || isEndedLease(props.lease),
        }}>
        <div class="text-base font-semibold text-success">{currency(props?.lease?.currentRent)}</div>
        <div class="text-sm text-text-level02">
          {t('Deposits hold')}: {getDeposit(props.lease)}
        </div>
      </div>
    </Panel>
  );
};

import { createStore } from 'solid-js/store';

type ChatInputs = Record<string, string | undefined>;

const [chatInputs, setChatInputs] = createStore<ChatInputs>({});

export const setChatInput = (chatId: string, input: string) => {
  setChatInputs(chatId, input);
};

export const clearChatInput = (chatId: string) => {
  setChatInputs(chatId, undefined);
};

export const getChatInput = (chatId: string) => chatInputs[chatId] || '';

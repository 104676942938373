import type { SelectorProps } from '~/components/ui/Calendar/Calendar';

export const YearRange = {
  General: [new Date().getFullYear() - 50, new Date().getFullYear() + 50],
  Birthday: [new Date().getFullYear() - 125, new Date().getFullYear() - 18],
};

export function getInitialDate(params: Pick<SelectorProps, 'yearRange'>) {
  if (params.yearRange) {
    const currentYear = new Date();
    const [start, end] = params.yearRange ?? YearRange.General;
    if (currentYear.getFullYear() < start) currentYear.setFullYear(start);
    if (currentYear.getFullYear() > end) currentYear.setFullYear(end);
    return createDate(currentYear);
  }
  return createDate();
}

export const createDate = (input?: Date | string) => {
  const date = input ? new Date(input) : new Date();
  date.setHours(0, 0, 0, 0);
  return date;
};

import { useSearchParams } from '@solidjs/router';
import { createSignal, Show, Switch, Match, onMount } from 'solid-js';
import { PortfolioTitle } from '~/components/portfolios';
import { PropertyTitle } from '~/components/properties';
import { Select } from '~/components/ui';
import { IconCheck } from '~/components/ui/Icons';
import { useLocalization, usePortfolios, useProperties } from '~/contexts/global';
import { useReports } from '~/contexts/local';

type SelectType = 'portfolio' | 'property' | 'All';

export const PropertySelect = () => {
  const { t } = useLocalization();
  const [selectType, setSelectType] = createSignal<SelectType>('All');
  const { portfolios } = usePortfolios();
  const { properties } = useProperties();
  const [searchParams, setSearchParams] = useSearchParams<{
    portfolioIds?: string;
    propertyIds?: string;
  }>();

  const SelectTypeOptions = [
    { label: t('All'), value: 'All' },
    { label: t('Portfolio'), value: 'portfolio' },
    { label: t('Property'), value: 'property' },
  ];

  const { dealMultipleFilter } = useReports();

  onMount(() => {
    if (searchParams.portfolioIds) setSelectType('portfolio');
    if (searchParams.propertyIds) setSelectType('property');
  });

  const handlePortfolioChange = (value: string[], current: string) => {
    value = dealMultipleFilter(value, current);
    const params = {
      portfolioIds: '',
      propertyIds: '',
    };
    if (value.includes('All')) {
      setSearchParams(params);
    } else {
      params.portfolioIds = value.toString();
      setSearchParams(params);
    }
  };

  const handlePropertyChange = (value: string[], current: string) => {
    value = dealMultipleFilter(value, current);
    const params = {
      portfolioIds: searchParams.portfolioIds,
      propertyIds: '',
    };
    if (value.includes('All')) {
      setSearchParams(params);
    } else {
      params.propertyIds = value.toString();
      setSearchParams(params);
    }
  };

  const handleSelectType = (value: string) => {
    setSelectType(value as SelectType);

    setSearchParams({
      portfolioIds: '',
      propertyIds: '',
    });
  };

  return (
    <>
      <Select
        class="min-w-64 grow"
        placeholder={t('Select type')}
        options={SelectTypeOptions}
        value={selectType()}
        onChange={handleSelectType}
      />

      <Show when={selectType() !== 'All'}>
        <Switch>
          <Match when={selectType() === 'portfolio'}>
            <Select
              class="min-w-64 grow"
              placeholder={t('Portfolio')}
              options={[
                { label: t('All'), value: 'All' },
                ...(portfolios()?.map((item) => ({ label: item.name as string, value: item.id })) ?? []),
              ]}
              value={searchParams.portfolioIds ? searchParams.portfolioIds.split(',') : ['All']}
              onChange={handlePortfolioChange}
              multiple
              renderOption={(item: any) => {
                const portfolio = portfolios()?.find((p) => p.id === item.value);
                return (
                  <div class="flex items-center hover:bg-light-pink">
                    <Show
                      when={item.label !== 'All' && portfolio}
                      fallback={
                        <div class="flex cursor-pointer items-center gap-1 rounded-md px-3 py-2.5 text-title-gray transition-colors hover:bg-light-pink">
                          {item.label}
                        </div>
                      }>
                      <PortfolioTitle portfolio={portfolio} />
                    </Show>
                    <Show when={searchParams?.portfolioIds?.includes(item.value)}>
                      <IconCheck class="ml-auto size-4 text-essential-colour" />
                    </Show>
                  </div>
                );
              }}
            />
          </Match>
          <Match when={selectType() === 'property'}>
            <Select
              class="min-w-64 grow"
              placeholder={t('Property')}
              options={[
                { label: t('All'), value: 'All' },
                ...(properties()?.map((item) => ({ label: item.name as string, value: item.id })) || []),
              ]}
              value={searchParams.propertyIds ? searchParams.propertyIds.split(',') : ['All']}
              onChange={handlePropertyChange}
              multiple
              renderOption={(item: any) => {
                const property = properties()?.find((p) => p.id === item.value);
                return (
                  <div class="flex items-center hover:bg-light-pink">
                    <Show
                      when={item.label !== 'All' && property}
                      fallback={
                        <div class="flex cursor-pointer items-center gap-1 rounded-md px-3 py-2.5 text-title-gray transition-colors hover:bg-light-pink">
                          {item.label}
                        </div>
                      }>
                      <PropertyTitle property={property} />
                    </Show>
                    <Show when={searchParams?.propertyIds?.includes(item.value)}>
                      <IconCheck class="ml-auto size-4 text-essential-colour" />
                    </Show>
                  </div>
                );
              }}
            />
          </Match>
        </Switch>
      </Show>
    </>
  );
};

import { BankAccountsListProvider, RentPaymentSettingsProvider, RentPaymentFailureFeeSettingsProvider } from '~/contexts/local';
import { RentPaymentNotificationsWrap } from '~/pages/settings/property-settings/rent-payment/notifications';
import { PropertyRentPaymentSettings } from './PropertyRentPaymentSettings';
import { RentPaymentFailureFeeSettings } from './RentPaymentFailureFeeSettings';

export const PropertyRentPaymentSettingsWrapper = () => {
  return (
    <div class="flex flex-col gap-5">
      <RentPaymentSettingsProvider>
        <BankAccountsListProvider>
          <PropertyRentPaymentSettings />
        </BankAccountsListProvider>
      </RentPaymentSettingsProvider>
      <RentPaymentFailureFeeSettingsProvider>
        <RentPaymentFailureFeeSettings />
      </RentPaymentFailureFeeSettingsProvider>

      <RentPaymentNotificationsWrap />
    </div>
  );
};

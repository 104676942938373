import RoundedTable from '~/components/common/RoundedTable';
import UnderConstruction from '~/components/common/UnderConstruction';

//TODO - Fix with real data
function VendorNotes() {
  const columns = [
    { title: 'Details', key: 'details' },
    { title: 'Date updated', key: 'dateUpdated' },
    { title: 'Updated by', key: 'updatedBy' },
  ];

  const data = [
    { data: { details: 'Followed up with the vendor on plumbing issue', dateUpdated: '5/02/2024 9:00AM', updatedBy: 'Alice Johnson' } },
    { data: { details: 'Reviewed lease agreement for unit 405', dateUpdated: '5/05/2024 11:15AM', updatedBy: 'Bob Smith' } },
    { data: { details: 'Sent reminder for annual HVAC maintenance', dateUpdated: '5/07/2024 2:30PM', updatedBy: 'Charlie Brown' } },
    { data: { details: 'Discussed payment schedule with contractor', dateUpdated: '5/09/2024 10:45AM', updatedBy: 'Diana Prince' } },
    { data: { details: 'Scheduled pest control visit for building', dateUpdated: '5/11/2024 1:20PM', updatedBy: 'Edward Nigma' } },
    { data: { details: 'Updated vendor contact information', dateUpdated: '5/13/2024 3:50PM', updatedBy: 'Fiona Shrek' } },
    { data: { details: 'Reviewed tenant complaints about noise', dateUpdated: '5/14/2024 4:30PM', updatedBy: 'George Washington' } },
    { data: { details: 'Confirmed insurance details with provider', dateUpdated: '5/15/2024 8:30AM', updatedBy: 'Hannah Montana' } },
  ];

  return (
    <div class="relative rounded-lg border bg-white p-6 text-sm">
      <RoundedTable columns={columns} data={data} />
      <UnderConstruction />
    </div>
  );
}

export default VendorNotes;

import { Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import Tooltip from '~/components/common/Tooltip';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { IconSingleFamily, IconMultiFamily, IconCommercial, IconCondo, IconTownHome, IconOther } from './Icons';
import type { Component } from 'solid-js';

export const propertyTypes: Record<MagicDoor.Api.PropertyType, { name: string; icon: Component<{ class: string }> }> = {
  singleFamily: { name: 'Single family', icon: IconSingleFamily },
  multiFamily: { name: 'Multi family', icon: IconMultiFamily },
  commercial: { name: 'Commercial', icon: IconCommercial },
  condo: { name: 'Condo', icon: IconCondo },
  townHome: { name: 'Townhome', icon: IconTownHome },
  other: { name: 'Others', icon: IconOther },
};

export const PropertyType: Component<{ class?: string; type?: MagicDoor.Api.PropertyType }> = (props) => {
  const { t } = useLocalization();
  return (
    <Show when={props.type && propertyTypes[props.type]}>
      {(type) => (
        <>
          <Tooltip message={t(type().name)} align="right">
            <Dynamic component={type().icon} class={cn('size-6 text-link', props.class)} />
          </Tooltip>
        </>
      )}
    </Show>
  );
};

import { Panel } from '~/components/common/Panels';
import TableSkeleton from '~/components/skeletons/TableSkeleton';
import { useLocalization } from '~/contexts/global';
const OverviewSkeleton = () => {
  const { t } = useLocalization();
  const currentLeasescolumns = [
    {
      title: t('Units'),
    },
    {
      title: t('Lease term'),
    },
    {
      title: t('Tenants'),
    },
    {
      title: t('Amount'),
    },
    {
      title: t('Rent'),
    },
    {
      title: t('Deposit'),
    },
    {
      title: t('Last payment'),
    },
    {
      title: t('Balances'),
    },
  ];
  const tenantsColumns = [
    {
      title: t('Name'),
      key: 'name',
    },
    {
      title: t('Contact'),
      key: 'contact',
    },
    {
      title: '',
      key: '',
    },
  ];
  return (
    <div class="grid grid-cols-3 gap-5 text-left">
      <div class="skeleton-shimmer col-span-2 rounded-lg px-6 py-1">
        <div class="h-40" />
      </div>
      <div class="skeleton-shimmer rounded-lg px-6 py-1">
        <div class="h-40" />
      </div>

      <Panel title={t('Current leases')} class="col-span-full">
        <TableSkeleton columns={currentLeasescolumns} rowCount={3} />
      </Panel>
      <Panel title={t('Tenants')} class="col-span-2">
        <TableSkeleton columns={tenantsColumns} rowCount={3} />
      </Panel>
    </div>
  );
};

export default OverviewSkeleton;

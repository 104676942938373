import { useNavigate } from '@solidjs/router';
import { createSignal } from 'solid-js';
import ReactivateLeaseBg from '~/assets/images/units/reactivateLeaseBg.png';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLease } from '~/contexts/local';

export const ReactivateLeaseModal = () => {
  const navigate = useNavigate();
  const { t } = useLocalization();
  const { lease, reactivateLease, getLease } = useLease();

  const [modalLoading, setModalLoading] = createSignal<boolean>(false);

  const reactivate = async () => {
    const leaseId = lease()?.id;
    if (!leaseId) return;
    setModalLoading(true);
    try {
      await reactivateLease(leaseId);
      getLease(leaseId);
      navigate(-1);
      toast.success(t('Operation successful'));
    } finally {
      setModalLoading(false);
    }
  };
  return (
    <Modal
      size="sm"
      onDone={reactivate}
      visible={true}
      title={t('Reactivate lease')}
      doneText={t('Confirm')}
      loading={modalLoading()}
      onCancel={() => navigate(-1)}
      confirmation={false}>
      <div>
        <img class="size-full h-24" src={ReactivateLeaseBg} alt="ReactivateLeaseBg" />
        <div class="my-2 ml-8">{t('Please confirm whether to restore the lease')}</div>
      </div>
    </Modal>
  );
};

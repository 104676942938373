import { createSignal, Switch, Match } from 'solid-js';
import IconAccountLoginBg from '~/assets/images/settings/bgAccountLoginPW.svg';
import { Button } from '~/components/common/Buttons';
import { useLocalization, useAuth } from '~/contexts/global';
import { UpdatePassword } from './UpdatePassword';
import type { Component } from 'solid-js';

const InputItem: Component<{ label: string; value?: string; onClick: () => void }> = (props) => {
  const { t } = useLocalization();
  return (
    <div class="space-y-2 text-sm">
      <div>{props.label}</div>
      <div class="flex gap-2">
        <div class="flex-1 rounded-lg border border-current px-4 py-2.5 text-auxiliary-text">{props.value}</div>
        <Button variant="outlined" class="bg-essential-colour/10 py-2.5 text-white hover:bg-essential-colour/30" onClick={props.onClick}>
          {t('Update')}
        </Button>
      </div>
    </div>
  );
};

export const LoginAndPassword: Component = () => {
  const { t } = useLocalization();
  const [editing, setEditing] = createSignal<'login' | 'password'>();
  const { changePassword } = useAuth();

  // TODO: implement update email
  // const currentLogin = () => me()?.email.replace(/(.{2})(.*)(@.*)/, (_, a, b, c) => a + b.replace(/./g, '*') + c);

  const handlePasswordSubmit = async (model: { currentPassword: string; newPassword: string }) => {
    await changePassword(model.currentPassword, model.newPassword);
  };

  return (
    <Switch
      fallback={
        <div class="flex justify-between rounded-lg bg-dark-navy text-white">
          <div class="mx-6 my-8 max-w-md flex-1 space-y-6 text-left">
            {/* <InputItem label={t('Login email address')} value={currentLogin()} onClick={() => setEditing('login')} /> */}
            <InputItem label={t('Password')} value="***********" onClick={() => setEditing('password')} />
          </div>
          <img class="h-20" src={IconAccountLoginBg} alt={t('Account login & password')} />
        </div>
      }>
      {/* <Match when={editing() === 'login'}>
        <UpdateLogin onClose={setEditing} onSubmit={handleLoginSubmit} />
      </Match> */}
      <Match when={editing() === 'password'}>
        <UpdatePassword onClose={setEditing} onSubmit={handlePasswordSubmit} errors={changePassword.error} />
      </Match>
    </Switch>
  );
};

import IconZh from '~/assets/images/flags/chinese.png';
import IconEn from '~/assets/images/flags/english.png';
import LocalizationDropdown from '~/components/common/expandables/LocalizationDropdown';
import { useLocalization } from '~/contexts/global';

const LocalizationButton = () => {
  const localization = useLocalization();

  const languageOptions = [
    { id: 'en', value: 'en', label: 'English', img: IconEn, alt: 'English flag' },
    { id: 'zh', value: 'zh', label: 'Chinese', img: IconZh, alt: 'Chinese flag' },
  ];

  const currentLanguage = localization.currentLanguage();

  const initialOption = languageOptions.find((option) => option.value === currentLanguage) || languageOptions[0];

  const onOptionSelected = (option: any) => {
    localization.changeLanguage(option.value);
  };

  return <LocalizationDropdown options={languageOptions} onOptionSelected={onOptionSelected} initialOption={initialOption} />;
};

export default LocalizationButton;

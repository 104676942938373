import { Route, Routes } from '@solidjs/router';
import { MaintenanceRequestsProvider } from '~/contexts/local';
import { EditMaintenanceRequestView } from '~/pages/maintenance-request/edit-maintenance-request';
import { AddMaintenanceRequestModal } from './add-maintenance-request';
import { ListMaintenanceRequestPage } from './list-maintenance-request';
import { MaintenanceRequestDetailsPage } from './maintenance-request-details';

const MaintenanceRequestRoutes = () => {
  return (
    <MaintenanceRequestsProvider>
      <Routes>
        <Route path="/:maintenanceRequestId/edit" component={EditMaintenanceRequestView} matchFilters={{ maintenanceRequestId: /^\d+$/ }} />
        <Route path="/:maintenanceRequestId/*" component={MaintenanceRequestDetailsPage} matchFilters={{ maintenanceRequestId: /^\d+$/ }} />
        <Route path="*" component={ListMaintenanceRequestPage} />
      </Routes>
      <Routes>
        <Route path="/add" component={AddMaintenanceRequestModal} />
      </Routes>
    </MaintenanceRequestsProvider>
  );
};

export default MaintenanceRequestRoutes;

import { For, Show } from 'solid-js';
import { isFalsy } from '~/components/common/BetterForm/utils';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { safeCall } from '~/utils/tool';
import { CategoryComponent } from './CategoryComponent';
import { useCategoryComponentWrapper } from './Wrapper';
import type { SidebarProgress } from './CategoryComponent';
import type { Section } from './Wrapper';
import type { JSX } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm';

export const getErrorAndProgressProps = (form: BetterForm.form) => {
  return {
    getError: (section: Section) => {
      return Object.keys(form.configStore.fieldsErrors).some((key) => section.fields?.includes(key));
    },
    getProgress: (section: Section) => {
      return {
        progress: section.fields
          ? Math.floor(
              (section.fields.reduce((acc, key) => {
                const fieldValue = form.getFieldValue(key);
                return !isFalsy(fieldValue) ? acc + 1 : acc;
              }, 0) /
                section.fields.length) *
                100
            )
          : 0,
      };
    },
  };
};

export const CategoryComponentSection = function (props: {
  getError?: (section: Section) => boolean;
  getProgress?: (section: Section) => Partial<SidebarProgress>;
  categoryFooterTitle?: string | JSX.Element;
  mode?: 'progress' | 'plain';
  class?: string;
  footerClass?: string;
  onSetActiveCategory?: (key: string) => void;
  headerTitle?: string | JSX.Element;
}) {
  const { store, onClickSection } = useCategoryComponentWrapper();
  const { t } = useLocalization();

  return (
    <>
      <div
        class={cn(
          `sticky top-0 z-10 hidden min-w-[300px] flex-col justify-between rounded bg-white capitalize text-text-level03 lg:flex`,
          props.class
        )}>
        <Show when={props.headerTitle}>{props.headerTitle}</Show>
        <div class="mb-10 flex flex-1 flex-col gap-2 px-5">
          <For each={store.sections}>
            {(section, i) => (
              <>
                <Show when={section.required !== store.sections[i() - 1]?.required}>
                  <span class="pb-1 pt-5 text-text-level02">{t(section.required ? 'Required' : 'Optional')}</span>
                </Show>
                <CategoryComponent
                  label={section.name}
                  progress={safeCall(props.getProgress, section) || section.progress}
                  isActive={store.activeCategory === section.key}
                  categoryKey={section.key}
                  error={safeCall(props.getError, section) || section.error}
                  onSetActiveCategory={() => {
                    onClickSection(section.key);
                    props.onSetActiveCategory?.(section.key);
                  }}
                  mode={props.mode}
                  icon={section.icon}
                />
              </>
            )}
          </For>
        </div>
      </div>
    </>
  );
};

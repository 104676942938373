import { ValidationError } from '~/errors';
import { BaseRestRepository } from './baseRestRepository';

const STRIPE_URL = '/api/stripe';

export function validateId(id: string, entityName = 'Entity'): void {
  if (!id) throw new ValidationError(`${entityName} ID must be provided`);
}

export class StripeRepository extends BaseRestRepository {
  async getStripeDetails(bankAccountId: string): Promise<MagicDoor.Api.StripeAccountDetailsDto> {
    validateId(bankAccountId, 'BankAccount');
    const url = `${STRIPE_URL}/${bankAccountId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  async linkStripe(bankAccountId: string): Promise<MagicDoor.Api.StripeLinkDto> {
    validateId(bankAccountId, 'BankAccount');
    const url = `${STRIPE_URL}/${bankAccountId}/link`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  async checkStripeConnection(bankAccountId: string): Promise<MagicDoor.Api.StripeIsConnectedDto> {
    validateId(bankAccountId, 'BankAccount');
    const url = `${STRIPE_URL}/${bankAccountId}/is-connected`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
}

import { BaseRestRepository } from '~/repositories/baseRestRepository';

export type Level = `${MagicDoor.Api.SettingsLevel}`;

const RENT_PAYMENT_SETTINGS_URL = '/api/settings/rent-payment-processing-fees';

export class RentPaymentProcessingFeesSettingsRepository extends BaseRestRepository {
  public async getAllRentPaymentProcessingFees(): Promise<MagicDoor.Api.RentPaymentProcessingFeesSettingsDtoSettingsWithOverridesDto> {
    const url = `${RENT_PAYMENT_SETTINGS_URL}/all`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getLevelRentPaymentProcessingFees(
    level: Level,
    levelId: string
  ): Promise<MagicDoor.Api.RentPaymentProcessingFeesSettingsDto> {
    const url = `${RENT_PAYMENT_SETTINGS_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async deleteLevelRentPaymentProcessingFees(level: Level, levelId: string): Promise<void> {
    const url = `${RENT_PAYMENT_SETTINGS_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, { method: 'DELETE' });
    return this.getJsonResponse(response);
  }

  public async createLevelRentPaymentProcessingFees(
    level: Level,
    levelId: string,
    payload: MagicDoor.Api.RentPaymentProcessingFeesSettingsDto
  ): Promise<MagicDoor.Api.RentPaymentProcessingFeesSettingsDto> {
    const url = `${RENT_PAYMENT_SETTINGS_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(
      url,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
      false
    );
    return this.getJsonResponse(response);
  }

  public async getRentPaymentProcessingFees(): Promise<MagicDoor.Api.RentPaymentProcessingFeesSettingsDto> {
    const url = RENT_PAYMENT_SETTINGS_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async deleteRentPaymentProcessingFees(): Promise<void> {
    const url = RENT_PAYMENT_SETTINGS_URL;
    const response = await this.fetchWithAuth(url, { method: 'DELETE' });
    return this.getJsonResponse(response);
  }

  public async createRentPaymentProcessingFees(payload: MagicDoor.Api.RentPaymentProcessingFeesSettingsDto) {
    const url = RENT_PAYMENT_SETTINGS_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }
}

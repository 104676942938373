import { PropertyManagersProvider, RunBooksSettingsProvider } from '~/contexts/local';
import { RunBooks } from './RunBooks';

export const RunBooksWrap = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <PropertyManagersProvider>
        <RunBooksSettingsProvider>
          <RunBooks />
        </RunBooksSettingsProvider>
      </PropertyManagersProvider>
    </div>
  );
};

import { createEffect, createSignal } from 'solid-js';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import UnderConstruction from '~/components/common/UnderConstruction';
import { useLocalization } from '~/contexts/global';
import UpDownBarGraph, { getLastFiveMonths } from './UpDownBarGraph';
import type { BarData } from './UpDownBarGraph';
const VendorTransactions = () => {
  // const { transactions } = useTransactions();

  const [transactionData, setTransactionData] = createSignal<BarData[]>([]);
  const { t } = useLocalization();

  const generateDummyTransactions = () => {
    const months = getLastFiveMonths();
    return months.map((month) => ({
      axisLabel: month,
      segments: [
        { value: Math.floor(Math.random() * 50), color: 'warning', label: t('Credit') },
        { value: Math.floor(Math.random() * 100), color: 'green', label: t('Bills') },
      ],
    }));
  };

  createEffect(() => {
    const dummyData = generateDummyTransactions();
    setTransactionData(dummyData);
  });

  //TODO COMMENT BACK WHEN READY
  // createEffect(() => {
  //   if (transactions()) {
  //     const months = getLastFiveMonths();
  //     const mappedData = months.map((month) => {
  //       const transaction = transactions()?.find((t) => t.month === month);

  //       return {
  //         axisLabel: month,
  //         segments: [
  //           { value: transaction ? parseInt(transaction.creditAmount) : 0, color: 'warning', label: t('Credit') },
  //           { value: transaction ? parseInt(transaction.billAmount) : 0, color: 'green', label: t('Bills') },
  //         ],
  //       };
  //     });

  //     setTransactionData(mappedData);
  //   }
  // });

  return (
    <div class="relative">
      <ThreeSectionBox
        topTitle={<div>{t('Transactions')}</div>}
        topAction={
          <button class="flex items-center gap-1">
            <div>{t('Details')}</div>
            <IconViewArrow class="size-4 text-link" />
          </button>
        }
        middle={
          <div class="h-64 w-full">
            <UpDownBarGraph data={transactionData()} capHeight={400} />
          </div>
        }
      />
      <UnderConstruction />
    </div>
  );
};

export default VendorTransactions;

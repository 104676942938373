import { createMemo, createSignal, Show } from 'solid-js';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import IconTrash from '~/assets/images/maintenance/trash.svg?component-solid';
import IconWorkOrder from '~/assets/images/vendors/workOrders.svg?component-solid';
import IconBook from '~/assets/images/work-order/book.svg?component-solid';
import IconVendorHand from '~/assets/images/work-order/vendorHand.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import IconField from '~/components/common/IconField';
import { Panel } from '~/components/common/Panels/Panel';
import { ListFileThumbnailWithPreview } from '~/components/file-attachments/ListFileThumbnailWithPreview';
import { MaintenanceRequestUrgency } from '~/components/maintenance-requests';
import ConfirmationModal from '~/components/modals/ConfirmationModal';
import { IconCalendar, IconFlag, IconLockKeyhole, IconLockKeyholeOpen, ListView, Skeleton, TableActions, toast } from '~/components/ui';
import { useLocalization, useWorkOrders } from '~/contexts/global';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';

const PermissionToEnter: Component<{ permissionToEnter?: boolean }> = (props) => {
  const { t } = useLocalization();
  return (
    <Show when={props.permissionToEnter != null} fallback={<Skeleton class="h-9" />}>
      <Show
        when={props.permissionToEnter}
        fallback={
          <div class="flex items-center gap-2 rounded-md bg-current-alpha px-4 py-2 text-sm font-medium uppercase text-light-warning">
            <IconLockKeyhole class="size-5" />
            {t('Entry not granted')}
          </div>
        }>
        <div class="flex items-center gap-2 rounded-md bg-current-alpha px-4 py-2 text-base font-medium uppercase text-light-green">
          <IconLockKeyholeOpen class="size-5" />
          {t('Entry granted')}
        </div>
      </Show>
    </Show>
  );
};

const BillsTable: Component<{
  loading: boolean;
  isDraft: boolean;
  bills?: (MagicDoor.Api.WorkOrderBillDto & { chartOfAccounts?: string; price?: number })[];
}> = (props) => {
  const { t } = useLocalization();
  const { deleteDraftBill, workOrder, convertDraftBill } = useWorkOrders();
  const [alertVisible, setAlertVisible] = createSignal<boolean>(false);

  const removeDraftBill = async (billId: string) => {
    await deleteDraftBill(workOrder()?.id as string, billId);
    toast.success(t('{name} has been deleted successfully', { name: t('Bill') }));
  };

  const convertToBill = async (billId: string) => {
    if (!workOrder()?.vendorId) {
      setAlertVisible(true);
      return;
    }
    try {
      await convertDraftBill(workOrder()?.id as string, billId);
      toast.success(t('Operation successful'));
    } catch (error) {
      toast.error(t('Operation failed, please try again later'));
    }
  };

  return (
    <>
      <ListView
        title={t(props.isDraft ? 'Draft bills' : 'Bills')}
        titleActions={
          <Show when={props.isDraft}>
            <div class="flex items-center gap-2">
              <Button class="ml-auto text-sm capitalize" href="add-draft-bill">
                {t('Add draft bill')}
              </Button>
            </div>
          </Show>
        }
        data={props.bills}
        loading={props.loading}
        skeletonSize={4}
        rowLink={(item) => `${item.id}/edit-draft-bill`}
        columns={[
          {
            title: t('Date'),
            render: (item) => <div>{dateFormat(t('MM/DD/YYYY'), item.billDate)}</div>,
          },

          {
            title: t('Chart of account'),
            render: (item) => <div class=" font-medium"> {item.chartOfAccounts}</div>,
          },
          {
            title: t('Files'),
            render: (item) => <div class=" text-xs font-medium text-link"> {item.files?.map((item) => item.fileName).join(',')}</div>,
          },
          {
            title: t('Price'),
            render: (item) => <div class=" font-medium"> {currency(item.price)}</div>,
          },
          {
            title: t('Due date'),
            render: (item) => <div>{dateFormat(t('MM/DD/YYYY'), item.dueDate)}</div>,
          },
          {
            title: '',
            class: 'relative',
            render: (item) => (
              <div class="flex items-center gap-2">
                <Show when={props.isDraft}>
                  <Button
                    onClick={() => convertToBill(item.id)}
                    class="cursor-pointer rounded-lg border-none bg-[#FF8618] px-2.5 py-1 text-xs font-medium text-white hover:bg-[#FF8618]/80">
                    {t('Convert to bill')}
                  </Button>
                </Show>

                <TableActions
                  actions={[
                    {
                      icon: IconEdit,
                      label: t('Edit'),
                      href: `${item.id}/edit-draft-bill`,
                    },
                    {
                      icon: IconTrash,
                      label: t('Delete'),
                      onClick: () => removeDraftBill(item.id),
                    },
                  ]}
                />
              </div>
            ),
          },
        ]}
      />
      <Show when={alertVisible()}>
        <ConfirmationModal
          onConfirm={() => setAlertVisible(false)}
          onCancel={() => setAlertVisible(false)}
          cancelText={t('Cancel')}
          confirmText={t('Close')}
          message={t('Please assign vendor first')}
        />
      </Show>
    </>
  );
};

export const WorkOrderOverviewTab: Component = () => {
  const { t } = useLocalization();

  const { workOrder, updateWorkOrderStatus } = useWorkOrders();

  const draftBills = createMemo(() =>
    workOrder()
      ?.bills?.filter((bill) => !bill.billId)
      .map((bill) => {
        const chartOfAccountIds = bill.lineItems?.map((lineItem) => lineItem.chartOfAccountId);
        const chartOfAccounts = workOrder()
          ?.chartOfAccounts.filter((chartOfAccount) =>
            chartOfAccountIds?.some((chartOfAccountId) => chartOfAccountId === chartOfAccount.id)
          )
          .map((chartOfAccount) => chartOfAccount.name);
        return {
          ...bill,
          chartOfAccounts: chartOfAccounts?.join(','),
          price: bill.lineItems?.reduce((previous, current) => previous + (current.amount || 0), 0),
        };
      })
  );

  const bills = createMemo(() =>
    workOrder()
      ?.bills?.filter((bill) => bill.billId)
      .map((bill) => {
        const chartOfAccountIds = bill.lineItems?.map((lineItem) => lineItem.chartOfAccountId);
        const chartOfAccounts = workOrder()
          ?.chartOfAccounts.filter((chartOfAccount) =>
            chartOfAccountIds?.some((chartOfAccountId) => chartOfAccountId === chartOfAccount.id)
          )
          .map((chartOfAccount) => chartOfAccount.name);
        return {
          ...bill,
          chartOfAccounts: chartOfAccounts?.join(','),
          price: bill.lineItems?.reduce((previous, current) => previous + (current.amount || 0), 0),
        };
      })
  );

  const handleStatusChange = async (value: string) => {
    if (!value) return;
    await updateWorkOrderStatus(
      workOrder()?.id as string,
      {
        status: value,
      } as MagicDoor.Api.UpdateWorkOrderStatusDto
    );
    toast.success(t('Operation successful'));
  };

  return (
    <div class="flex flex-col gap-5">
      <Panel
        title={
          <Show
            when={!workOrder.loading}
            fallback={
              <div>
                <Skeleton class="h-7 w-64" />
                <Skeleton class="h-4 w-72 border-t-2 border-white" />
              </div>
            }>
            <div class="flex gap-2">
              <div class="hidden size-fit rounded-lg bg-safe-green p-4 xl:flex">
                <IconWorkOrder class="size-fit text-green" />
              </div>
              <div class="flex flex-col lg:min-w-64">
                <div>{workOrder()?.title}</div>
                <div class="text-xs font-normal normal-case text-text-level03">{`${workOrder()?.property?.name} - ${
                  workOrder()?.unit?.name || ''
                }`}</div>
              </div>
            </div>
          </Show>
        }
        actions={
          <div class="flex flex-wrap items-center gap-2">
            <DropdownMenu
              onChange={handleStatusChange}
              value={['pending', 'inProgress', 'closed'].includes(workOrder()?.status as string) ? workOrder()?.status : 'pending'}
              options={[
                { label: t('Pending'), value: 'pending' },
                { label: t('In progress'), value: 'inProgress' },
                { label: t('Closed'), value: 'closed' },
              ]}
              class="shrink-0"
            />
            <Show when={workOrder()?.maintenanceRequest}>
              <Button
                class="hidden shrink-0 whitespace-nowrap font-medium capitalize xl:flex"
                variant="outlined"
                href={`/maintenance/maintenance-request/${workOrder()?.maintenanceRequestId}`}>
                {t('Maintenance request')}
              </Button>
            </Show>
            <Button
              class="shrink-0 whitespace-nowrap font-medium capitalize"
              variant="solid"
              href={`/maintenance/work-orders/${workOrder()?.id}/edit`}>
              <IconEdit class="text-white" />
              {t('Edit')}
            </Button>
          </div>
        }
        actionClass="flex gap-2">
        <div class="flex flex-col px-5  py-6">
          <div class="rounded-lg bg-light-gray p-5">
            <Show when={workOrder.loading} fallback={<div class="font-medium normal-case text-title-gray">{workOrder()?.description}</div>}>
              <Skeleton />
              <Skeleton class="mt-1 w-2/3" />
            </Show>
            <ListFileThumbnailWithPreview loading={workOrder.loading} files={workOrder()?.files} />
          </div>
          <div class="mb-3 mt-6 flex items-center gap-1 text-sm text-text-level03">
            <IconFlag class="size-4" />
            {t('Priority')}
            <Show when={!workOrder.loading} fallback={<Skeleton class="ml-2 w-16 rounded-full" />}>
              <MaintenanceRequestUrgency class="ml-2" urgency={workOrder()?.urgency} />
            </Show>
          </div>

          <div class="mb-3 flex items-center gap-1 text-sm text-text-level03">
            <IconBook class="size-4" />
            {t('Reference')}:
            <Show when={!workOrder.loading} fallback={<Skeleton class="ml-2 w-16 rounded-full" />}>
              <div class="text-text-level01">#{workOrder()?.reference}</div>
            </Show>
          </div>
          <Show when={workOrder()?.internalDescription || workOrder.loading}>
            <div class="mb-3 flex items-center gap-1 text-sm text-text-level03">
              <IconBook class="size-4" />
              {t('Internal description')}:
              <Show when={!workOrder.loading} fallback={<Skeleton class="w-48" />}>
                <span class="normal-case text-link">{workOrder()?.internalDescription}</span>
              </Show>
            </div>
          </Show>
          <PermissionToEnter permissionToEnter={workOrder()?.permissionToEnter} />

          <div class="my-5 h-px bg-partingline" />
          <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            <Show when={workOrder()?.vendor || workOrder.loading}>
              <div class="flex w-full gap-2 rounded-lg border border-partingline px-4 py-3">
                <IconField
                  iconRounded={true}
                  name={t('Vendor')}
                  loading={workOrder.loading}
                  value={workOrder()?.vendor?.name}
                  src={IconVendorHand}
                />
              </div>
            </Show>
            <Show when={workOrder()?.dueDate || workOrder.loading}>
              <div class="flex w-full gap-2 rounded-lg border border-partingline px-4 py-3">
                <IconField
                  iconRounded={true}
                  name={t('Due date')}
                  loading={workOrder.loading}
                  value={dateFormat('', workOrder()?.dueDate ?? '')}
                  src={IconCalendar}
                />
              </div>
            </Show>
          </div>
        </div>
      </Panel>

      <BillsTable loading={workOrder.loading} isDraft bills={draftBills()} />
      <BillsTable loading={workOrder.loading} isDraft={false} bills={bills()} />
    </div>
  );
};

import { createSignal, Show, createMemo, createEffect } from 'solid-js';
import BellIcon from '~/assets/images/common/bell.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import Tooltip from '~/components/common/Tooltip';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLeasesList } from '~/contexts/local';
import { cn } from '~/utils/classnames';

interface QuickNotifyProps {
  class?: string;
  targets: string[];
  btnText?: string;
}

function allValuesInB(arrA: string[], arrB: string[]) {
  for (let i = 0; i < arrA.length; i++) {
    if (!arrB.includes(arrA[i])) {
      return false;
    }
  }
  return true;
}

export const QuickLeaseNotifyBalanceDue = (props: QuickNotifyProps) => {
  const { sendNotifyBalanceDue, sendNotifyBalanceDueList } = useLeasesList();

  const { t } = useLocalization();
  const [isSuccess, setIsSuccess] = createSignal<boolean | undefined>(undefined);
  const [isSend, setIsSend] = createSignal<boolean>(false);
  const [loading, setLoading] = createSignal<boolean>(false);

  createEffect(() => {
    if (sendNotifyBalanceDueList()?.length) {
      const hasSend = allValuesInB(props.targets, sendNotifyBalanceDueList());
      if (hasSend) {
        setIsSend(true);
        setIsSuccess(true);
      } else {
        setIsSend(false);
      }
    }
  });

  const handleSendAnnouncement = async (e?: MouseEvent) => {
    e?.stopPropagation();
    e?.preventDefault();

    if (isSend()) return;

    if (props.targets.length === 0) {
      toast(t('Please select at least one target'), 'error');
    }

    try {
      setLoading(true);

      await sendNotifyBalanceDue(props.targets);
      setIsSuccess(true);
      toast(t('Notification sent successfully'), 'success');
    } catch (error) {
      setIsSuccess(false);
    } finally {
      setLoading(false);
      setIsSend(true);
    }
  };

  const statusClass = createMemo(() => {
    if (!isSend() || isSuccess() === undefined) return;
    return isSuccess() ? 'bg-success border-success hover:bg-success/90 text-white' : 'bg-error border-error hover:bg-error/90 text-white';
  });

  return (
    <Tooltip disabled={isSend()} message={t('Double-click reminder')} class="cursor-pointer" align="left">
      <Button
        onDblClick={handleSendAnnouncement}
        loading={loading()}
        class={cn(
          'bg border-warning  bg-warning px-3 py-1 text-xs font-medium  text-white hover:bg-warning/90',
          props.class,
          statusClass()
        )}
        rounded="full">
        <BellIcon />
        <Show when={!isSend()}> {t(props.btnText || 'Remind')}</Show>
      </Button>
    </Tooltip>
  );
};

import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import CnMoney from '~/components/common/CnMoney';
import { ListView } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
export const ReceivablePayableListView: Component = () => {
  const { t } = useLocalization();

  const receivablesPayablesData = [
    {
      id: '1',
      date: '2024-06-30',
      status: 'Pending',
      chartOfAccount: 'Revenue',
      property: '1234 Elm Street',
      memo: 'Monthly rent',
      vendorPayee: 'Acme Corp',
      amount: 1500,
    },
    {
      id: '2',
      date: '2024-07-15',
      status: 'Paid',
      chartOfAccount: 'Maintenance',
      property: '5678 Maple Avenue',
      memo: 'Repair services',
      vendorPayee: 'Beta LLC',
      amount: 2000,
    },
    {
      id: '3',
      date: '2024-06-25',
      status: 'Overdue',
      chartOfAccount: 'Utilities',
      property: '9876 Oak Drive',
      memo: 'Electricity bill',
      vendorPayee: 'Gamma Inc',
      amount: 2500,
    },
  ];

  return (
    <>
      <ListView
        title={t('Company Receivables & Payables')}
        totalPages={10}
        page={1}
        pageSize={20}
        data={receivablesPayablesData}
        skeletonSize={10}
        searchPlaceholder={t('Search invoices')}
        rowLink={(item) => `/invoices/${item.id}`}
        columns={[
          {
            title: t('Date'),
            render: (item) => <div class="text-text-level01">{item.date}</div>,
          },
          {
            title: t('Status'),
            render: (item) => <div class="text-text-level01">{item.status}</div>,
          },
          {
            title: t('Chart of account'),
            render: (item) => <div class="text-text-level01">{item.chartOfAccount}</div>,
          },
          {
            title: t('Property'),
            render: (item) => <div class="text-text-level01">{item.property}</div>,
          },
          {
            title: t('Memo'),
            render: (item) => <div class="text-text-level01">{item.memo}</div>,
          },
          {
            title: t('Vendor/Payee'),
            render: (item) => <div class="text-text-level01">{item.vendorPayee}</div>,
          },
          {
            title: t('Amount'),
            headerClass: 'text-right w-36',
            class: 'text-right',
            render: (item) => <CnMoney money={item.amount} />,
          },
        ]}
        actions={(item) => [
          {
            label: t('Edit Invoice'),
            icon: IconPencil,
            href: `/invoices/${item.id}/edit`,
          },
          {
            label: t('Delete'),
            icon: IconDelete,
          },
        ]}
      />
    </>
  );
};

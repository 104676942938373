import { For, createEffect, createSignal, onCleanup, onMount } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { SectionAnchor } from './SectionAnchor';
import { SectionWrapper } from './SectionWrapper';
import type { Component, JSX } from 'solid-js';

export type SectionProps<T> = {
  model?: T;
  onChange?: (type: string, value: number | string) => void;
};

type SectionInfo<T> = {
  id: string;
  title: string;
  component: Component<SectionProps<T>>;
};

type MultiSectionPanelProps<T> = {
  class?: string;
  title: string;
  sections: SectionInfo<T>[];
  model: T;
  children: (id: string) => JSX.Element;
  actions?: JSX.Element;
};

export const MultiSectionPanel = <T extends object>(props: MultiSectionPanelProps<T>) => {
  const { t } = useLocalization();
  const [activeId, setActiveId] = createSignal();
  let refContainer!: HTMLDivElement;

  createEffect(() => {
    setActiveId(props.sections[0].id);
  });

  onMount(() => {
    const handleScroll = () => {
      const scrollY = refContainer.scrollTop;

      if (scrollY + 10 >= refContainer.scrollHeight - refContainer.clientHeight) {
        setActiveId(props.sections[props.sections.length - 1].id);
      } else {
        let i;
        for (i = 0; i < props.sections.length; ++i) {
          const section = refContainer.querySelector(`#${props.sections[i].id}`) as HTMLDivElement;
          if (section.offsetTop > scrollY) break;
        }

        setActiveId(props.sections[i].id);
      }
    };

    if (refContainer) {
      refContainer.addEventListener('scroll', handleScroll);
    }

    onCleanup(() => {
      if (refContainer) {
        refContainer.removeEventListener('scroll', handleScroll);
      }
    });
  });

  return (
    <Panel class={cn('flex flex-col divide-y divide-partingline text-left', props.class)}>
      <div class="flex flex-1 divide-x divide-partingline overflow-hidden">
        <div class="p-6">
          <h3 class="mb-4 text-lg font-semibold text-text-level01">{props.title}</h3>
          <div class="flex flex-col gap-2">
            <For each={props.sections}>
              {(section) => <SectionAnchor id={section.id} title={section.title} isActive={activeId() === section.id} />}
            </For>
          </div>
        </div>
        <div class="thinscroll flex w-full flex-col overflow-auto" ref={refContainer}>
          <For each={props.sections}>
            {({ id, title, component: Component }) => (
              <SectionWrapper id={id} title={title}>
                <Component model={props.model} />
              </SectionWrapper>
            )}
          </For>
        </div>
      </div>
      <div class="flex flex-none gap-2 px-8 py-5">
        <div>{props.actions}</div>
        <Button class="ml-auto" variant="outlined">
          {t('Cancel')}
        </Button>
        <Button>{t('Save changes')}</Button>
      </div>
    </Panel>
  );
};

import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import ExpiringLeasesBg from '~/assets/images/dashboard/expiringLeasesBg.svg';
import CircularDiagramImg from '~/assets/images/property/tempChart.png';
import { LinkButton } from '~/components/common/Buttons';
import UnderConstruction from '~/components/common/UnderConstruction';
import { useLocalization } from '~/contexts/global';
import Card from '~/pages/dashboard/components/Card';
import type { Component } from 'solid-js';

type ExpiringLeasesProps = {
  class?: string;
};

const ExpiringLeases: Component<ExpiringLeasesProps> = (props) => {
  const { t } = useLocalization();
  return (
    <Card
      class={props.class}
      title={t('Expiring leases')}
      bgClass="bg-[#FFFEF9]"
      bgImgSrc={ExpiringLeasesBg}
      actions={
        <LinkButton disabled href="/" class="px-3 py-1 text-xs" rounded="full" variant="outlined">
          {t('View all')}
          <IconViewArrow class="size-4 text-essential-colour" />
        </LinkButton>
      }>
      <div class="relative flex size-full items-center justify-center">
        <div class="flex items-center px-9">
          <img src={CircularDiagramImg} alt="CircularDiagramImg" />
          <div class="ml-8">
            <div class="flex flex-col items-center justify-center">
              <div class="flex items-center">
                <span class="size-3 rounded bg-[#FF545D]" />
                <span class="ml-1 text-base font-semibold">8</span>
              </div>
              <span class="text-xs text-text-level03">
                {'<30'} {t('Days')}
              </span>
            </div>
            <div class="flex flex-col items-center justify-center">
              <div class="mt-5 flex items-center">
                <span class="size-3 rounded bg-warning" />
                <span class="ml-1 text-base font-semibold">16</span>
              </div>
              <span class="text-xs text-text-level03">31 - 60 {t('Days')}</span>
            </div>
            <div class="flex flex-col items-center justify-center">
              <div class="mt-5 flex items-center">
                <span class="size-3 rounded bg-green" />
                <span class="ml-1 text-base font-semibold">22</span>
              </div>
              <span class="text-xs text-text-level03">61 - 90 {t('Days')}</span>
            </div>
          </div>
        </div>
        <UnderConstruction />
      </div>
    </Card>
  );
};

export default ExpiringLeases;

import { AnnotationType } from './types/annotation';
import { UseCase } from './useCase';
import type { LeaseTemplateUpdateRequest } from './types/leaseTemplateUpdateRequest';

export class UpdateLeaseTemplateUseCase extends UseCase {
  public async runLogic(request: LeaseTemplateUpdateRequest) {
    if (!request) {
      return;
    }
    let desiredCategoryIndex = request.leaseCategoryIndex;
    if (
      desiredCategoryIndex === undefined ||
      desiredCategoryIndex < 0 ||
      desiredCategoryIndex >= this.state.leaseTemplateCategories.length
    ) {
      desiredCategoryIndex = 0;
    }
    const selectedAnnotation = this.state.selectedAnnotation;
    if (
      selectedAnnotation !== undefined &&
      selectedAnnotation?.type === AnnotationType.AUTOFILL &&
      this.state.dataPathManager.doesDataPathWithKeyExist(request.dataPathKey) &&
      this.state.dataPathManager.getSelectedNodeKey() !== request.dataPathKey
    ) {
      selectedAnnotation.dataPathKey = request.dataPathKey;
      this.state.dataPathManager.selectNodeWithKey(request.dataPathKey);
    }
    this.state.pdfs.category = this.state.leaseTemplateCategories[desiredCategoryIndex];
    this.state.pdfs.title = request.title ? request.title : this.state.pdfs.title;
  }
}

import * as Sentry from '@sentry/browser';
import { Routes, Route } from '@solidjs/router';
import { ErrorBoundary, Show } from 'solid-js';
import { AiRunbooksRoutes } from '~/pages/ai-runbooks';
import SignInPage from '~/pages/auth/SignIn';
import DashboardPage from '~/pages/dashboard/Dashboard';
import { ViolationsRoutes } from '~/pages/violations';
import NotFound from './components/common/NotFound';
import { Layout, FullScreenLayout } from './components/layouts';
import { Toaster, Dialogs } from './components/ui';
import AnnouncementRoutes from './pages/announcements';
import BankAccountsRoutes from './pages/bank-accounts';
import BillsRoutes from './pages/bills';
import ChartOfAccountRoutes from './pages/chart-of-accounts';
import ChatRoutes from './pages/chats';
import { ChecksRoutes } from './pages/checks';
import { CompanyRevenueRoutes } from './pages/company-revenue';
import { DepositSlipsRoutes } from './pages/deposit-slips';
import ErrorFallback from './pages/error/ErrorFallback';
import { LeaseTemplateRoutes } from './pages/lease-templates';
import { LeasesRoutes } from './pages/leases';
import ListingRoutes from './pages/listing';
import MaintenanceRequestRoutes from './pages/maintenance-request';
import { OwnerRoutes } from './pages/owner';
import { OwnerFundsRoutes } from './pages/owner-funds';
import { PortfoliosRoutes } from './pages/portfolios';
import { PropertyManagerRoutes } from './pages/property-managers';
import { RentalApplicationRoutes } from './pages/rental-applications';
import ReportsRoutes from './pages/reports';
import SettingRoutes from './pages/settings';
import TenantsRoutes from './pages/tenants';
import { IconsPage } from './pages/test/IconsPage';
import { PlaygroundPage } from './pages/test/PlaygroundPage';
import UnauthorizedPage from './pages/test/UnauthorizedPage';
import UnknownErrorPage from './pages/test/UnknownErrorPage';
import UnreadMessagesRoutes from './pages/unread-messages';
import VendorRoutes from './pages/vendors';
import { WorkOrderRoutes } from './pages/work-orders';
import Navigator from './pdfsigner/ui/components/Navigator';
import type { Component } from 'solid-js';

const TailwindIndicator = () => (
  <Show when={import.meta.env.DEV}>
    <div class="fixed bottom-1 left-1 z-50 flex size-10 items-center justify-center rounded-full border bg-white font-mono text-sm opacity-50 shadow hover:opacity-100">
      <div class="block sm:hidden">xs</div>
      <div class="hidden sm:block md:hidden">sm</div>
      <div class="hidden md:block lg:hidden">md</div>
      <div class="hidden lg:block xl:hidden">lg</div>
      <div class="hidden xl:block 2xl:hidden">xl</div>
      <div class="hidden 2xl:block">2xl</div>
    </div>
  </Show>
);

const App: Component = () => (
  <ErrorBoundary
    fallback={(err, reset) => {
      Sentry.captureException(err);
      return <ErrorFallback error={err} reset={reset} />;
    }}>
    <Routes>
      <Navigator />
      <Route path="/" component={Layout}>
        <Route path="/" component={DashboardPage} />
        <Route path="/listing/*" component={ListingRoutes} />

        <Route path="/accounting">
          <Route path="/bank-accounts/*" component={BankAccountsRoutes} />
          <Route path="/bills/*" component={BillsRoutes} />
          <Route path="/chart-of-accounts/*" component={ChartOfAccountRoutes} />
          <Route path="/company-revenue/*" component={CompanyRevenueRoutes} />
          <Route path="/owner-funds/*" component={OwnerFundsRoutes} />
          <Route path="/deposit-slips/*" component={DepositSlipsRoutes} />
          <Route path="/checks/*" component={ChecksRoutes} />
        </Route>

        <Route path="/reports/*" component={ReportsRoutes} />

        <Route path="/users">
          <Route path="/tenants/*" component={TenantsRoutes} />
          <Route path="/property-managers/*" component={PropertyManagerRoutes} />
          <Route path="/owners/*" component={OwnerRoutes} />
        </Route>

        <Route path="/maintenance">
          <Route path="/vendors/*" component={VendorRoutes} />
          <Route path="/maintenance-request/*" component={MaintenanceRequestRoutes} />
          <Route path="/work-orders/*" component={WorkOrderRoutes} />
          <Route path="/ai-runbooks/*" component={AiRunbooksRoutes} />
        </Route>

        <Route path="/communications">
          <Route path="/announcements/*" component={AnnouncementRoutes} />
          <Route path="/chats/*" component={ChatRoutes} />
          <Route path="/dashboard/*" component={UnreadMessagesRoutes} />
        </Route>

        <Route path="/associations">
          <Route path="/violations/*" component={ViolationsRoutes} />
        </Route>

        <Route path="/portfolios/*" component={PortfoliosRoutes} />

        <Route path="/leasing">
          <Route path="/leases/*" component={LeasesRoutes} />
          <Route path="/lease-templates/*" component={LeaseTemplateRoutes} />
          <Route path="/*" component={RentalApplicationRoutes} />
        </Route>

        <Route path="/settings/*" component={SettingRoutes} />
      </Route>

      <Route path="/auth" component={FullScreenLayout}>
        <Route path="signin" component={SignInPage} />
      </Route>

      <Route path="/test">
        <Route path="unauthorized" component={UnauthorizedPage} />
        <Route path="unknown" component={UnknownErrorPage} />
        <Route path="play" component={PlaygroundPage} />
        <Route path="icons" component={IconsPage} />
      </Route>
      <Route path="*" component={FullScreenLayout}>
        <Route path="*" component={NotFound} />
      </Route>
    </Routes>
    <Dialogs />
    <Toaster />
    <TailwindIndicator />
  </ErrorBoundary>
);

export default App;

import { Show, For } from 'solid-js';
import { IconUser } from '~/components/ui';
import { useLocalization } from '~/contexts/global';

export type OtherTarget = {
  id: string;
  name?: string;
  firstName?: string;
  lastName?: string;
};

export const OtherTargetsList = (props: { targets: OtherTarget[]; title: string }) => {
  const { t } = useLocalization();

  const getTargetLink = (targetType: string, id: string) => {
    switch (targetType.toLowerCase()) {
      case 'vendors':
        return `/maintenance/vendors/${id}`;
      case 'tenants':
        return `/users/tenants/${id}`;
      case 'owners':
        return `/users/owners/${id}`;
      default:
        return `/details/${id}`;
    }
  };

  return (
    <Show when={props.targets && props.targets.length > 0}>
      <div class="mt-4">
        <h4 class="mb-8 border-b text-xl font-semibold">{t(props.title)}</h4>
        <For each={props.targets}>
          {(target) => (
            <Show when={target && target.id && (target.name || (target.firstName && target.lastName))}>
              <div class="mb-2 flex gap-2 rounded-md border p-2">
                <IconUser />
                <a href={getTargetLink(props.title, target.id)} target="_blank" rel="noopener noreferrer" class="hover:underline">
                  {target.name || `${target.firstName} ${target.lastName}`}
                </a>
              </div>
            </Show>
          )}
        </For>
      </div>
    </Show>
  );
};

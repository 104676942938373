import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { DepositPaymentSettingsRepository } from '~/repositories/settings/depositPaymentSettingsRepository';

export const [DepositPaymentSettingsProvider, useDepositPaymentSettings] = createMagicDoorContext('DepositPaymentSettings', () => {
  const repo = new DepositPaymentSettingsRepository();
  const [isLoading, setIsLoading] = createSignal(false);
  const [error, setError] = createSignal<Error | undefined>(undefined);
  const [allDepositSettings, setAllDepositSettings] = createSignal<
    MagicDoor.Api.DepositPaymentSettingsDtoSettingsWithOverridesDto | undefined
  >();

  const getAllDepositSettings = async () => {
    try {
      setIsLoading(true);
      const json = await repo.getAllDepositPaymentSettings();
      setAllDepositSettings(json);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const updateDepositPaymentSettings = async (payload: MagicDoor.Api.DepositPaymentSettingsDto) => {
    try {
      setIsLoading(true);
      await repo.updateDepositSettings(payload);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const updateOverrideDepositPaymentSettings = async (level: string, levelId: string, payload: MagicDoor.Api.DepositPaymentSettingsDto) => {
    try {
      setIsLoading(true);
      await repo.updateOverrideDepositSettings(level, levelId, payload);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const addOverrideSettings = async (level: string, levelId: string, payload: MagicDoor.Api.DepositPaymentSettingsDto) => {
    try {
      setIsLoading(true);
      await repo.addOverrideSettings(level, levelId, payload);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const removeOverrideSettings = async (level: string, levelId: string) => {
    try {
      setIsLoading(true);
      await repo.removeOverrideSettings(level, levelId);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error,
    isLoading,
    allDepositSettings,
    getAllDepositSettings,
    updateDepositPaymentSettings,
    addOverrideSettings,
    updateOverrideDepositPaymentSettings,
    removeOverrideSettings,
  };
});

import { BaseRestRepository } from '~/repositories/baseRestRepository';

const WORK_ORDER_SETTINGS_URL = '/api/settings/work-order';

export class WorkOrderSettingsRepository extends BaseRestRepository {
  public async getAllWorkOrderSettings(): Promise<MagicDoor.Api.WorkOrderSettingsDtoSettingsWithOverridesDto> {
    const url = `${WORK_ORDER_SETTINGS_URL}/all`;
    const res = await this.fetchWithAuth(url);
    return this.getJsonResponse(res);
  }

  public async updateWorkOrderSettings(payload: MagicDoor.Api.WorkOrderSettingsDto): Promise<MagicDoor.Api.WorkOrderSettingsDto> {
    const url = `${WORK_ORDER_SETTINGS_URL}`;
    const res = await this.fetchWithAuth(url, { method: 'POST', body: JSON.stringify(payload) });
    return this.getJsonResponse(res);
  }

  public async updateOverrideWorkOrderSettings(
    level: string,
    levelId: string,
    payload: MagicDoor.Api.WorkOrderSettingsDto
  ): Promise<MagicDoor.Api.WorkOrderSettingsDto> {
    const url = `${WORK_ORDER_SETTINGS_URL}/${level}/${levelId}`;
    const res = await this.fetchWithAuth(url, { method: 'POST', body: JSON.stringify(payload) });
    return this.getJsonResponse(res);
  }

  public async addOverrideSettings(
    level: string,
    levelId: string,
    payload: MagicDoor.Api.WorkOrderSettingsDto
  ): Promise<MagicDoor.Api.WorkOrderSettingsDto> {
    const url = `${WORK_ORDER_SETTINGS_URL}/${level}/${levelId}`;
    const res = await this.fetchWithAuth(url, { method: 'POST', body: JSON.stringify(payload) });
    return this.getJsonResponse(res);
  }

  public async removeOverrideSettings(level: string, levelId: string) {
    const url = `${WORK_ORDER_SETTINGS_URL}/${level}/${levelId}`;
    const res = await this.fetchWithAuth(url, { method: 'DELETE' });
    return this.getJsonResponse(res);
  }
}

import { useNavigate } from '@solidjs/router';
import { createEffect } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { LinkButton } from '~/components/common/Buttons';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Modal } from '~/components/modals/Modal';
import { IconBan, toast } from '~/components/ui';
import { useLocalization, usePortfolios } from '~/contexts/global';
import { formConfirmationContent } from '~/utils/constant';
import type { Component } from 'solid-js';

export const EditPortfolioModal: Component = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const form = useForm();
  const { current, updatePortfolio } = usePortfolios();

  createEffect(() => current() && form.setFormStore({ portfolio: current()?.name }));

  const isDirty = () => form.formStore.portfolio !== current()?.name;

  const handleClose = () => {
    const currentId = current()?.id;
    navigate(currentId ? `/portfolios/${currentId}` : '/portfolios', { scroll: false, replace: true });
  };

  const handleSubmit = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;
    const currentId = current()?.id;
    if (currentId == null) return;
    if (!isDirty()) return handleClose();
    const updated = await updatePortfolio(currentId, form.formStore.portfolio);
    handleClose();
    toast.success(t('{name} has been edited successfully', { name: updated.name }));
  };

  return (
    <Modal
      visible
      class="animate-zoom-in capitalize"
      loading={updatePortfolio.loading}
      title={t(current() == null ? 'Loading portfolio...' : 'Edit portfolio')}
      doneText={t('Confirm')}
      onDone={handleSubmit}
      onCancel={handleClose}
      confirmation={isDirty() ? formConfirmationContent(t) : false}>
      <Form defaultForm={form} class="mb-6 p-8" onFormSubmit={handleSubmit}>
        <FormItem
          component={LabeledTextInput}
          formFieldName="portfolio"
          rules={[
            { required: true, message: t('Portfolio name is required') },
            { length: 150, message: t('{name} must be {length} characters or less', { name: t('Portfolio'), length: '150'})}
          ]}
          label={t('Portfolio')}
          placeholder={t('Please enter')}
        />
      </Form>
      <div class="absolute bottom-6 left-6 flex">
        <LinkButton
          variant="white"
          class="border-text-level03 text-text-level03"
          href={current()?.id ? `/portfolios/${current()?.id}/deactivate` : '#'}
          noScroll>
          <IconBan class="size-4" />
          <div class="hidden md:flex">{t('Deactivate')}</div>
        </LinkButton>
      </div>
    </Modal>
  );
};

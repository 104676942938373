import { BaseRestRepository } from '~/repositories/baseRestRepository';

const propertyManagerNotificationUrl = '/api/settings/property-manager-notifications';

export class propertyManagerNotificationRepository extends BaseRestRepository {
  public async getPropertyManagerNotificationAll() {
    const url = propertyManagerNotificationUrl + '/all';
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async updatePropertyManagerNotification(payload: MagicDoor.Api.PropertyManagerNotificationSettingsDto) {
    const url = propertyManagerNotificationUrl;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getPropertyManagerNotificationByLevel(level: string, levelId: string) {
    const url = `${propertyManagerNotificationUrl}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async updatePropertyManagerNotificationByLevel(
    level: string,
    levelId: string,
    payload: MagicDoor.Api.PropertyManagerNotificationSettingsDto
  ) {
    const url = `${propertyManagerNotificationUrl}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async deletePropertyManagerNotificationByLevel(level: string, levelId: string) {
    const url = `${propertyManagerNotificationUrl}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }
}

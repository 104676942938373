import { useNavigate, useParams } from '@solidjs/router';
import { createSignal, onCleanup, onMount } from 'solid-js';
import PlusIcon from '~/assets/images/common/plus.svg?component-solid';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { Form, FormItem, FormList, useForm } from '~/components/common/BetterForm';
import { FormListRemoverWrapper } from '~/components/common/BetterForm/FormListRemover';
import { Button } from '~/components/common/Buttons';
import { Section } from '~/components/common/FormSectionTitle';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { WorkOrderBillFileListView } from '~/components/file-attachments/WorkOrderBillFileListView';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization, useWorkOrders } from '~/contexts/global';
import type { Component } from 'solid-js';

export const AddAndEditBillModal: Component = () => {
  const { t } = useLocalization();
  const form = useForm();
  const navigate = useNavigate();
  const { addDraftBill, updateDraftBill, addBillFile, workOrder, fileList, setFileList, workOrderActions } = useWorkOrders();
  const params = useParams<{ workOrderId: string; billId: string }>();
  const [isLoading, setIsLoading] = createSignal<boolean>(false);

  const handleClose = () => {
    navigate(-1);
  };

  const submit = async () => {
    if (!form.validateForm().validateStatus) return;
    setIsLoading(true);
    try {
      if (params.billId) {
        await updateDraftBill(params.workOrderId, params.billId, form.formStore as MagicDoor.Api.AddDraftWorkOrderBillDto);
        toast.success(t('{name} has been edited successfully', { name: t('Bill') }));
      } else {
        const r = await addDraftBill(params.workOrderId, form.formStore as MagicDoor.Api.AddDraftWorkOrderBillDto);

        for (const item of fileList() as any[]) {
          await addBillFile(params.workOrderId, r.id, item.file, item.description);
        }
        workOrderActions.refetch();
        toast.success(t('{name} has been added successfully', { name: t('Bill') }));
      }
      handleClose();
    } finally {
      setIsLoading(false);
    }
  };

  onMount(() => {
    if (params.billId) {
      const bill = workOrder()?.bills?.find((item) => item.id === params.billId);
      form.setFormStore({
        billDate: bill?.billDate,
        dueDate: bill?.dueDate,
        lineItems: bill?.lineItems,
      });
      return;
    }
    form.setFormStore('lineItems', [{}]);
  });

  onCleanup(() => {
    setFileList([]);
  });

  return (
    <Modal
      visible
      loading={isLoading()}
      onDone={submit}
      disabled={!form.isValidate}
      doneText={t('Confirm')}
      class="lg:w-modalSm"
      title={t(params.billId ? 'Edit bill' : 'Add draft bill')}
      onCancel={handleClose}>
      <Form class="overflow-x-auto p-5" defaultForm={form}>
        <Section title={t('Bill dates')} border>
          <div class="grid grid-cols-3 gap-5">
            <FormItem class="col-span-full lg:col-span-1" formFieldName="billDate" label={t('Bill date')} component={DueDateInputField} />
            <FormItem
              required
              class="col-span-full lg:col-span-1"
              formFieldName="dueDate"
              label={t('Due date')}
              rules={[{ required: true, message: t(`Please enter the {name}`, { name: t('Due date') }) }]}
              component={DueDateInputField}
            />
          </div>
        </Section>

        <Section title={t('Bill detail')} border>
          <FormList
            class="mt-4"
            formFieldName={'lineItems'}
            renderItemsGroup={({ index, remove }) => {
              return (
                <FormListRemoverWrapper class="grid grid-cols-3 gap-5" index={index} remove={remove} showRemove={true}>
                  <FormItem
                    class="col-span-full lg:col-span-1"
                    placeholder={t('Please select chart of account')}
                    label={t(`Chart of account`)}
                    formFieldName={['chartOfAccountId']}
                    component={LabeledChartOfAccountSelect}
                    types={['expense'] as MagicDoor.Api.ChartOfAccountType[]}
                    rules={[{ message: t(`Please select chart of account`), required: true }]}
                  />
                  <FormItem
                    class="col-span-full lg:col-span-1"
                    placeholder={t('Please input the amount')}
                    label={t(`Amount`)}
                    formFieldName={['amount']}
                    component={LabeledNumberInput}
                    rules={[{ message: t(`Please input the amount`), required: true }]}
                  />
                  <FormItem
                    class="col-span-full lg:col-span-1"
                    placeholder={t('Please input memo')}
                    label={t(`Memo`)}
                    formFieldName={['memo']}
                    component={LabeledTextInput}
                  />
                </FormListRemoverWrapper>
              );
            }}
            autoCombineFieldName={false}>
            {({ add }) => {
              return (
                <Button variant="text" color="link" onClick={add}>
                  <PlusIcon /> {t('Add line item')}
                </Button>
              );
            }}
          </FormList>
        </Section>

        <Section title={t('Bill invoice')}>
          <WorkOrderBillFileListView
            billId={params.billId}
            workOrderId={params.workOrderId}
            onlyUpload={params.billId ? false : true}
            autoUpload={params.billId ? true : false}
          />
        </Section>
      </Form>
    </Modal>
  );
};

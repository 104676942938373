import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import areaChartImg from '~/assets/images/dashboard/areaChart.png';
import MonthlyIcon from '~/assets/images/dashboard/billIcon.svg';
import IncomeStatisticsBg from '~/assets/images/dashboard/incomeStatisticsBg.svg';
import BillIcon from '~/assets/images/dashboard/monthlyIcon.svg';
import ChargeIcon from '~/assets/images/leases/transactions/charge.svg';
import { LinkButton } from '~/components/common/Buttons';
import UnderConstruction from '~/components/common/UnderConstruction';
import { useLocalization } from '~/contexts/global';
import Card from '~/pages/dashboard/components/Card';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';

type IncomeStatisticsProps = {
  class?: string;
};

const IncomeStatistics: Component<IncomeStatisticsProps> = (props) => {
  const { t } = useLocalization();
  return (
    <Card
      class={props.class}
      title={t('Income statistics')}
      description={t('Receipts and bills')}
      bgClass="bg-[#FFF9F9]"
      bgImgSrc={IncomeStatisticsBg}
      actions={
        <LinkButton disabled href="/" class="px-3 py-1 text-xs" rounded="full" variant="outlined">
          {t('View all')}
          <IconViewArrow class="size-4 text-essential-colour" />
        </LinkButton>
      }>
      <div class="relative">
        <div class="px-6">
          <img class="mt-8 w-full" src={areaChartImg} alt="areaChartImg" />
          <div class="mt-2 flex h-[78px] w-full items-center">
            <div class="flex h-12 w-1/2 items-center  border-r px-4">
              <img src={MonthlyIcon} alt="MonthlyIcon" />
              <div class="ml-3">
                <div class="text-xs text-text-level03">{t('Monthly income')}</div>
                <div class="text-lg font-semibold text-text-level01">{currency(20451)}</div>
              </div>
            </div>
            <div class="flex h-12 w-1/2 items-center px-4">
              <img src={BillIcon} alt="BillIcon" />
              <div class="ml-3">
                <div class="text-xs text-text-level03">{t('Bill')}</div>
                <div class="text-lg font-semibold text-text-level01">{currency(2021)}</div>
              </div>
            </div>
          </div>
          <div class="mt-2 flex h-7 items-center justify-between rounded-full bg-inputbox-bg px-3">
            <div class="flex items-center">
              <img src={ChargeIcon} alt="ChargeIcon" />
              <span class="ml-2 text-xs text-text-level03">{t('Recent payment received')}:</span>
            </div>
            <div class="flex items-center">
              <span class="text-xs text-text-level01">Maty Jane</span>
              &nbsp
              <span class="text-sm font-medium text-essential-colour">{currency(50)}</span>
            </div>
          </div>
        </div>
        <UnderConstruction />
      </div>
    </Card>
  );
};

export default IncomeStatistics;

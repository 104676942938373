import { useNavigate, useSearchParams } from '@solidjs/router';
import { createEffect, createMemo, createSignal, onCleanup, onMount, Show } from 'solid-js';
import { useForm } from '~/components/common/BetterForm';
import Breadcrumb from '~/components/common/Breadcrumb';
import { RingLoader } from '~/components/common/Loaders';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBills } from '~/contexts/local';
import BillStore from '~/pages/bills/store';
import AddBillForm from './components/AddBillForm';

export const AddBillView = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const breadcrumbItems = createMemo(() => [{ label: t('Bills'), link: '/accounting/bills' }, { label: t('Add a bill') }]);
  const [searchParams] = useSearchParams();
  const vendorId = searchParams.vendorId;

  const form = useForm();
  const { addBill, setFileList } = useBills();
  const { store, getUnits, getLeasesForUnit, setStore, getVendors, getProperties, getChartOfAccounts, getBankAccounts } = BillStore;
  const [submitText, setSubmitText] = createSignal<string>('');

  const handleAddBill = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) throw new Error();
    try {
      setSubmitText(`${t('Adding bill')}...`);
      setStore('submitLoading', true);
      await addBill(form.formStore as MagicDoor.Api.CreateVendorBillDto, setSubmitText);

      navigate(-1);
      toast.success(t('{name} has been added successfully', { name: t('Bill') }));
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      setSubmitText('');
      setStore('submitLoading', false);
    }
  };

  createEffect(() => {
    const propertyId = form.formStore.propertyId;
    form.setFormStore('unitId', undefined);
    form.setFormStore('leaseId', undefined);
    if (propertyId) {
      getUnits(propertyId);
    }
  });

  createEffect(() => {
    const unitId = form.formStore.unitId;
    form.setFormStore('leaseId', undefined);
    if (unitId) {
      getLeasesForUnit(unitId);
    } else {
      setStore('leases', []);
    }
  });

  onMount(() => {
    form.setFormStore('lineItems', [{}]);
    if (vendorId) {
      form.setFormStore('vendorId', vendorId);
    }
    getVendors();
    getProperties();
    getChartOfAccounts();
    getBankAccounts();
  });

  onCleanup(() => {
    setFileList([]);
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="relative">
        <MultiStepsPanel
          onClose={() => navigate(-1)}
          title={t('Add a bill')}
          disableNext={!form.isValidate}
          onSubmit={handleAddBill}
          loading={true}>
          <AddBillForm form={form} store={store} class="mx-auto my-10 w-full max-w-[1200px]" />
        </MultiStepsPanel>
        <Show when={store.submitLoading}>
          <div class="absolute inset-0 z-50 mx-8 flex flex-col items-center justify-center rounded-lg bg-white">
            <RingLoader color="#a126ec" showIcon={true} size={100} borderWidth={8} text={submitText()} />
          </div>
        </Show>
      </div>
    </>
  );
};

import { useNavigate } from '@solidjs/router';
import { Switch, Match } from 'solid-js';
import BackIcon from '~/assets/images/head-layout/back.svg';
import LeaseTextImage from '~/assets/images/head-layout/lease-text.svg';
import LeaseImage from '~/assets/images/head-layout/lease.svg';
import PropertyTextImage from '~/assets/images/head-layout/property-text.svg';
import PropertyImage from '~/assets/images/head-layout/property.svg';
import UnitTextImage from '~/assets/images/head-layout/unit-text.svg';
import UnitImage from '~/assets/images/head-layout/unit.svg';
import { cn } from '~/utils/classnames';
import type { Component, JSX } from 'solid-js';

type HeadLayoutProps = {
  type: 'unit' | 'property' | 'lease';
  backLink?: string | (() => void);
  children: JSX.Element;
  class?: string;
};

export const HeadLayout: Component<HeadLayoutProps> = (props) => {
  const navigate = useNavigate();
  function onClickBack() {
    if (typeof props.backLink === 'function') {
      props.backLink();
      return;
    }
    props.backLink && navigate(props.backLink);
  }

  return (
    <div class={cn('relative flex items-center rounded-t-lg px-5 py-2', props.class)}>
      <div class="-ml-4 flex size-16 items-center justify-center border-r-[1.5px] border-[#FFFFFF3D]">
        <img src={BackIcon} class="cursor-pointer" onClick={() => onClickBack()} />
      </div>
      <div class="flex-1 pl-6">{props.children}</div>
      <div class="relative mr-2 flex items-center gap-x-4">
        <Switch>
          <Match when={props.type === 'property'}>
            <img src={PropertyTextImage} />
            <img src={PropertyImage} class="-mb-8" />
          </Match>
          <Match when={props.type === 'lease'}>
            <img src={LeaseTextImage} />
            <img src={LeaseImage} class="-mb-8" />
          </Match>
          <Match when={props.type === 'unit'}>
            <img src={UnitTextImage} />
            <img src={UnitImage} class="-mb-8" />
          </Match>
        </Switch>
      </div>
    </div>
  );
};

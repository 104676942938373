import { Route, Routes } from '@solidjs/router';
import { LeaseDetailsView } from './lease-details';
import { UpdatesProvider } from './lease-details/UpdatePagesContext';
import { ListLeases } from './list-leases';
import { NewLeaseView } from './new-lease';

export const LeasesRoutes = () => {
  return (
    <UpdatesProvider>
      <Routes>
        <Route path="/new" component={NewLeaseView} />
        <Route path="/:leaseId/*" component={LeaseDetailsView} matchFilters={{ leaseId: /^\d+$/ }} />
        <Route path="*" component={ListLeases} />
      </Routes>
    </UpdatesProvider>
  );
};

import { cn } from '~/utils/classnames';
import type { Component, JSX } from 'solid-js';

type OptionButtonProps = {
  isActive?: boolean;
  Icon: Component<JSX.SvgSVGAttributes<SVGSVGElement>> | string;
  label: string;
  disabled?: boolean;
  class?: string;
  onClick?: () => void;
};

export const OptionButton: Component<OptionButtonProps> = (props) => {
  return (
    <div
      class={cn(
        'flex cursor-pointer items-center gap-1 divide-x divide-partingline rounded-lg border border-auxiliary-text p-3 text-text-level01',
        props.class,
        { 'border-essential-colour text-essential-colour bg-essential-colour/5': props.isActive },
        { 'bg-partingline cursor-not-allowed text-auxiliary-text': props.disabled }
      )}
      onClick={() => props.onClick && props.onClick()}>
      <div class="hidden md:flex">
        {typeof props.Icon === 'function' ? <props.Icon class="ml-1 mr-3 size-10" /> : <img src={props.Icon} />}
      </div>
      <span class="text-sm font-medium">{props.label}</span>
    </div>
  );
};

import { TimePicker } from '~/components/ui/TimePicker';
import { LabeledGroup } from './LabeledGroup';
import type { LabeledGroupProps } from './LabeledGroup';
import type { Component } from 'solid-js';

export interface TimePickerProps extends LabeledGroupProps {
  placeholder?: string;
  value?: string | undefined;
  onInput?: (e: string) => void;
  onClick?: (isMouseDown: boolean) => void;
  validationFunction?: (value: string | number) => string | undefined;
  labelClass?: string;
  suffix?: string;
  inputClass?: string;
  prepend?: string;
  disabled?: boolean;
  name?: string;
  labelContainerClass?: string;
}
const LabeledTimePicker: Component<TimePickerProps> = (props) => {
  const handleChange = (value: string) => {
    props.onInput && props.onInput(value);
  };
  return (
    <LabeledGroup {...props} labelClass={props.labelClass}>
      <TimePicker onChange={handleChange} value={props.value} />
    </LabeledGroup>
  );
};
export default LabeledTimePicker;

import { RentalApplicationPaymentSettingsProvider } from '~/contexts/local';
import { ApplicationFee } from './ApplicationFee';

export const RentalApplicationFeeWrap = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <RentalApplicationPaymentSettingsProvider>
        <ApplicationFee />
      </RentalApplicationPaymentSettingsProvider>
    </div>
  );
};

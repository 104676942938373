import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { LeaseAutoPaymentsRepository } from '~/repositories/leaseAutoPaymentsRepository';
import type { Accessor } from 'solid-js';

export interface LeaseAutoPaymentsValues {
  autoPayments: Accessor<MagicDoor.Api.HydratedTenantAutoPayDto[]>;
  isLoading: Accessor<boolean>;
  error: Accessor<Error | null>;
  getAutoPayments: (leaseId: string) => Promise<void>;
}

export const [LeaseAutoPaymentsProvider, useLeaseAutoPayments] = createMagicDoorContext<LeaseAutoPaymentsValues>(
  'LeaseAutoPayments',
  () => {
    const repo = new LeaseAutoPaymentsRepository();
    const [isLoading, setIsLoading] = createSignal(false);
    const [autoPayments, setAutoPayments] = createSignal<MagicDoor.Api.HydratedTenantAutoPayDto[]>([]);
    const [error, setError] = createSignal<Error | null>(null);

    const getAutoPayments = async (leaseId: string) => {
      setIsLoading(true);

      try {
        const response = await repo.getAutoPayments(leaseId);
        setAutoPayments(response);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    return { autoPayments, isLoading, error, getAutoPayments };
  }
);

import { For } from 'solid-js';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import IconSend from '~/assets/images/common/send.svg?component-solid';
import RealtorImg from '~/assets/images/units/listing/realtor.png';
import TruliaImg from '~/assets/images/units/listing/trulia.png';
import { Button } from '~/components/common/Buttons';
import InputSwitch from '~/components/common/Inputs/InputSwitch';
import { Panel } from '~/components/common/Panels';
import UnderConstruction from '~/components/common/UnderConstruction';
import { useLocalization } from '~/contexts/global';

export const PaidListingSites = () => {
  const { t } = useLocalization();
  const listingSites = [
    [
      {
        title: 'Zillow',
        icon: 'https://www.zillow.com/apple-touch-icon.png',
      },
      {
        title: 'Trulia rentals',
        icon: TruliaImg,
      },
      {
        title: 'Hotpads',
        icon: 'https://nodes3cdn.hotpads.com/hotpads-web/1715802771/hotpads-logo-with-text.min-2Wmox2P.svg',
      },
    ],
    [
      {
        title: 'Apartments.com',
        icon: 'https://www.apartments.com/apple-touch-icon.png',
      },
    ],
    [
      {
        title: 'Realtor.com',
        icon: RealtorImg,
      },
    ],
    [
      {
        title: 'Zumper.com',
        icon: 'https://d214hhm15p4t1d.cloudfront.net/nzr/ca68fc042cb0e1434ac8a39292b3e7b6b5d19ffd/img/logo/apple-touch-icon.png',
      },
      {
        title: 'Padmapper',
        icon: 'https://d214hhm15p4t1d.cloudfront.net/nzr/ca68fc042cb0e1434ac8a39292b3e7b6b5d19ffd/img/logo/apple-touch-icon.png',
      },
    ],
    [
      {
        title: 'Rent.com',
        icon: 'https://www.rent.com/apple-touch-icon.png',
      },
    ],
  ];
  return (
    <div class="relative">
      <Panel title={t('Paid listing sites')}>
        <For each={listingSites}>
          {(item) => (
            <div class="px-6">
              <div class="flex items-center justify-between border-b border-[#F5F5F5] py-4">
                <div class="flex items-center gap-4">
                  <For each={item}>
                    {(items, index) => (
                      <div class="relative flex h-12 w-64 items-center justify-between rounded-lg bg-input px-4">
                        <div class="flex items-center gap-3">
                          <img class="size-6" src={items.icon} alt="listing" />
                          <span>{items.title}</span>
                        </div>
                        <IconSend class="size-5 text-essential-colour" />
                      </div>
                    )}
                  </For>
                </div>
                <div class="flex items-center gap-2">
                  <Button color="link" variant="outlined" class="px-3 py-1 text-sm">
                    <IconEdit class="size-4 text-link" />
                    {t('Edit')}
                  </Button>
                  <InputSwitch checked />
                </div>
              </div>
            </div>
          )}
        </For>
      </Panel>
      <UnderConstruction />
    </div>
  );
};

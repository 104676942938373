import { emptyPlaceholder } from '~/utils/constant';
import { isUndefined } from './tool';

export function formatBigNumber(num: number, fixedNum = 1): string {
  if (num < 1000) {
    return num.toString();
  } else if (num >= 1000 && num < 1000000) {
    const formattedNumber = num / 1000;
    if (Number.isInteger(formattedNumber)) {
      return formattedNumber.toString() + 'k';
    } else {
      return formattedNumber.toFixed(fixedNum) + 'k';
    }
  } else if (num >= 1000000 && num < 1000000000) {
    const formattedNumber = num / 1000000;
    if (Number.isInteger(formattedNumber)) {
      return formattedNumber.toString() + 'm';
    } else {
      return formattedNumber.toFixed(fixedNum) + 'm';
    }
  } else if (num >= 1000000000 && num < 1000000000000) {
    const formattedNumber = num / 1000000000;
    if (Number.isInteger(formattedNumber)) {
      return formattedNumber.toString() + 'b';
    } else {
      return formattedNumber.toFixed(fixedNum) + 'b';
    }
  } else if (num >= 1000000000000) {
    const formattedNumber = num / 1000000000000;
    if (Number.isInteger(formattedNumber)) {
      return formattedNumber.toString() + 't';
    } else {
      return formattedNumber.toFixed(fixedNum) + 't';
    }
  }

  return num.toString();
}

export function currency(value: string | number | undefined, option?: Intl.NumberFormatOptions) {
  const result = Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...option,
  });
  return Number.isNaN(Number(value)) ? emptyPlaceholder : result;
}

export function isNumber(value: any): boolean {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

export function commaNumber(value: string | number | undefined | null, option?: Intl.NumberFormatOptions) {
  if (Number.isNaN(Number(value))) return emptyPlaceholder;

  return Number(value).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...option,
  });
}

export function percentFormat(number: number | undefined): string {
  const formatter = new Intl.NumberFormat('en', {
    style: 'percent',
    minimumFractionDigits: 2,
  });

  return isUndefined(number) ? emptyPlaceholder : formatter.format(number);
}

export function sumNumbers(numbers: any[]): number {
  return numbers.reduce((acc, cur) => (isNumber(cur) ? acc + Number(cur) : acc), 0);
}

import { createSignal, For, Show } from 'solid-js';
import IconClose from '~/assets/images/common/close.svg?component-solid';
import IconAddFile from '~/assets/images/leases/addFile.svg';
import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import { DragAndDrop } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import type { TextAreaProps } from '~/components/common/Inputs/LabeledTextArea';

const FileDisplay = (props: { file: File; onDelete: () => void }) => {
  return (
    <div class="flex min-w-0 grow basis-[calc(50%-0.5rem)] items-center justify-between rounded bg-primary/20 p-2">
      <span class="truncate text-sm">{props.file.name}</span>
      <button onClick={() => props.onDelete()} class="text-red-500 hover:text-red-700">
        <IconClose class="size-5" />
      </button>
    </div>
  );
};

const ImageUploader = (props: { type: 'chat' | 'email'; files: File[]; setFiles: (files: File[]) => void }) => {
  const { t } = useLocalization();

  const handleFilesChange = (newFiles: FileList) => {
    const updatedFiles = [...props.files, ...Array.from(newFiles)];
    props.setFiles(updatedFiles);
  };

  const handleDeleteFile = (index: number) => {
    const updatedFiles = props.files.filter((_, i) => i !== index);
    props.setFiles(updatedFiles);
  };

  return (
    <div class="space-y-4">
      <DragAndDrop class="cursor-pointer" multiple onDropFiles={handleFilesChange}>
        <div class="flex flex-col items-center gap-2 rounded border-2 border-dashed border-gray-300 p-4 text-xs normal-case text-gray-500 transition-colors duration-200 hover:border-gray-400">
          <img src={IconAddFile} alt="Add File" />
          <span>{t(`Drag & drop or click to add files`)}</span>
        </div>
      </DragAndDrop>

      <Show when={props.files.length > 0}>
        <div class="space-y-2">
          <h3 class="text-sm font-semibold text-gray-700">{t('Uploaded files')}</h3>
          <div class="flex flex-wrap gap-2">
            <For each={props.files}>{(file, index) => <FileDisplay file={file} onDelete={() => handleDeleteFile(index())} />}</For>
          </div>
        </div>
      </Show>
    </div>
  );
};

export const MessageInput = (props: TextAreaProps & { type: 'chat' | 'email'; onFilesChange: (files: File[]) => void }) => {
  const [files, setFiles] = createSignal<File[]>([]);

  const handleFilesChange = (newFiles: File[]) => {
    setFiles(newFiles);
    props.onFilesChange(newFiles);
  };

  return (
    <div class="flex flex-col gap-2 divide-y divide-gray-200">
      <LabeledTextArea
        {...props}
        extraItem={() => (
          <div class="border-t border-gray-200 pt-2">
            <ImageUploader type={props.type} files={files()} setFiles={handleFilesChange} />
          </div>
        )}
      />
    </div>
  );
};

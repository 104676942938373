import { useLocalization } from '~/contexts/global';
import { useViolations } from '~/contexts/local';
import { GenericNotificationModal } from './GenericNotificationModal';
import type { CreateNotificationModalProps } from './GenericNotificationModal';
import type { Component } from 'solid-js';

export const CreateOwnerNotificationModal: Component<CreateNotificationModalProps> = (props) => {
  const { t } = useLocalization();
  const { createOwnerNotification } = useViolations();

  const onSubmit = async (formData: FormData) => {
    await createOwnerNotification(formData);
    props.onClose();
  };

  return (
    <GenericNotificationModal onClose={props.onClose} title={t('Send owner notification')} onSubmit={onSubmit} notificationType="owner" />
  );
};

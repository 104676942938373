import { useLocalization } from '~/contexts/global';

interface PanelSkeletonProps {
  name: string;
}

const PanelSkeleton = (props: PanelSkeletonProps) => {
  const { t } = useLocalization();

  return (
    <div class="col-span-2 overflow-hidden rounded-lg border bg-white capitalize">
      <div class="flex items-center justify-between border-b border-partingline px-6 py-4">
        <h3 class="text-lg font-semibold">{props.name}</h3>
      </div>
      <div class="flex flex-col gap-2 p-3">
        <div class="flex rounded-md bg-essential-colour/20">
          <div class="skeleton-shimmer group relative inline-block h-14 w-full rounded-md" />
        </div>
        <div class="flex flex-col gap-1 text-sm">
          <div class="flex justify-between">
            {t('Pending')}:<div class="skeleton-shimmer h-6 w-20" />
          </div>
          <div class="flex justify-between">
            {t('Available')}:<div class="skeleton-shimmer h-6 w-20" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelSkeleton;

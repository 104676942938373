import IconField from '~/components/common/IconField';
import ExpandableSection from '~/components/common/expandables/ExpandableSection';
import { IconBuilding, IconCreditCard, IconRouter, IconWallet } from '~/components/ui';
import { useLocalization, useVendors } from '~/contexts/global';

const VendorBankInformation = () => {
  const { t } = useLocalization();
  const { current } = useVendors();

  return (
    <ExpandableSection title={t('Bank information')}>
      <div class="flex w-full flex-row flex-wrap gap-y-4 border-t py-4 xl:flex-col">
        <IconField class="basis-1/2" name={t('Bank name')} value={current()?.bankRouting?.bankName} src={IconBuilding} />
        <IconField class="basis-1/2" name={t('Account type')} value={current()?.bankRouting?.accountType} src={IconWallet} />
        <IconField class="basis-1/2" name={t('Routing number')} value={current()?.bankRouting?.routingNumber} src={IconRouter} />
        <IconField class="basis-1/2" name={t('Account number')} value={current()?.bankRouting?.accountNumber} src={IconCreditCard} />
      </div>
    </ExpandableSection>
  );
};

export default VendorBankInformation;

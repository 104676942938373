import IconLineArrow from '~/assets/images/common/lineArrow.svg';
import IconHeader from '~/assets/images/vendors/headerImg.png';
import { useLocalization } from '~/contexts/global';
import { VendorContactInformationSkeleton } from './VendorContactInformationSkeleton';

const VendorInformationSkeleton = () => {
  const { t } = useLocalization();

  return (
    <div class="flex w-full cursor-wait flex-col rounded-lg border border-solid bg-white ">
      <div class="relative flex h-24 flex-col justify-center p-4 text-left">
        <div class="skeleton-shimmer mb-2 h-6 w-1/3" />
        <div class="skeleton-shimmer h-4 w-1/4" />
        <img class="absolute left-0 top-0 size-full" src={IconHeader} alt="" />
      </div>
      <div class="border-b">
        <VendorContactInformationSkeleton />
      </div>
      <div class="border-b">
        <div class="flex w-full flex-col items-start px-4 py-1 text-xs text-text-level01">
          <div class="flex w-full justify-between py-3 text-left text-sm font-medium leading-5">
            {t('Payment information')}
            <img src={IconLineArrow} class="transition-all duration-300 ease-in-out" alt="Toggle" />
          </div>
        </div>
      </div>
      <div class="border-b">
        <div class="flex w-full flex-col items-start px-4 py-1 text-xs text-text-level01">
          <div class="flex w-full justify-between py-3 text-left text-sm font-medium leading-5">
            {t('Banking information')}
            <img src={IconLineArrow} class="transition-all duration-300 ease-in-out" alt="Toggle" />
          </div>
        </div>
      </div>
      <div class="border-b">
        <div class="flex w-full flex-col items-start px-4 py-1 text-xs text-text-level01">
          <div class="flex w-full justify-between py-3 text-left text-sm font-medium leading-5">
            {t('Insurance')}
            <img src={IconLineArrow} class="transition-all duration-300 ease-in-out" alt="Toggle" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorInformationSkeleton;

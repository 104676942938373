import { Show } from 'solid-js';
import { TinToggle } from '~/components/TinToggle';
import DisplayAddress from '~/components/common/DisplayAddress';
import IconField from '~/components/common/IconField';
import ExpandableSection from '~/components/common/expandables/ExpandableSection';
import { IconHash, IconMapPin, IconUser } from '~/components/ui';
import { useLocalization, useVendors } from '~/contexts/global';

const VendorPaymentInformation = () => {
  const { t } = useLocalization();
  const { current } = useVendors();

  return (
    <ExpandableSection title={t('Payment information')}>
      <div>
        <div class="flex w-full flex-row flex-wrap gap-y-4 border-t py-4 xl:flex-col">
          <IconField
            class="basis-1/3"
            name={t(`Taxpayer id (tin)`)}
            value={
              <Show when={current()?.irs1099Form?.taxId}>
                <TinToggle
                  number={current()?.irs1099Form?.taxId || ''}
                  type={current()?.irs1099Form?.taxIdType === 'ssn' ? 'ssn' : 'ein'}
                />
              </Show>
            }
            src={IconHash}
          />
          <IconField class="basis-1/3" name={t('Name')} value={current()?.irs1099Form?.overwriteName || 'Same as above'} src={IconUser} />
          <IconField
            class="basis-1/3"
            name={t('Address')}
            value={
              <Show when={current()?.irs1099Form?.overwriteAddress} fallback={t('Same as above')}>
                <DisplayAddress address={current()?.irs1099Form?.overwriteAddress} />
              </Show>
            }
            src={IconMapPin}
          />
        </div>
      </div>
    </ExpandableSection>
  );
};

export default VendorPaymentInformation;

import type { Icon } from '~/components/ui';

export const IconPreviewFailed: Icon = (props) => (
  <svg width="385" height="210" viewBox="0 0 385 210" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M64.9567 164.058L61.6141 162.265L58.2714 159.847L55.213 156.997L52.5525 153.773L50.4264 150.231L49.0393 146.53L48.5618 143.976L48.5391 141.421L48.9711 138.833L50.0171 138.742L51.0063 138.958L51.8817 139.457L52.5866 140.241L53.7236 141.932L56.475 145.724L59.8518 149.607L61.58 151.06L62.8534 151.639L63.5583 151.593L64.104 151.071L64.5133 149.822L64.5588 147.302L63.8198 142.182L61.4322 131.737L59.1128 121.293L58.3169 116.003L57.919 110.61L58.1009 106.659L58.6807 103.344L59.6244 100.563L61.0001 97.986L62.5578 96.022L64.2859 94.5802L66.2756 93.5812L68.288 93.1611L70.3914 93.2747L72.3925 93.9331L74.3821 95.2273L76.4173 97.2708L78.0318 99.6776L79.5212 102.924L80.8401 107.204L81.4995 111.03L82.6933 117.558L83.5233 121.157L84.5011 124.54L85.7062 127.628L86.9683 129.728L88.0143 130.716L89.0489 131.068L90.1745 130.875L91.2205 130.103L91.9709 129.047L93.3466 126.413L94.5631 123.575L95.8138 120.771L97.235 118.319L98.4061 116.9L99.8159 115.821L101.521 115.038L103.17 114.731L104.75 114.799L106.319 115.219L108.525 116.343L110.594 118.001L112.402 119.987L113.891 122.133L115.46 125.039L117.155 128.843L118.633 132.929L119.895 137.402L120.781 141.978L121.168 146.45L121.1 149.425L120.702 152.15L119.997 154.67L118.905 157.008L117.405 159.007L115.449 160.709L113.141 162.072L110.39 163.332L107.138 164.456L102.044 165.75L96.3823 166.681L90.5724 167.226L84.7398 167.385L78.9982 167.146L73.7795 166.545L68.8792 165.5L65.0591 164.161L64.9567 164.058Z"
      fill="url(#paint0_linear_7089_44384)"
    />
    <path
      d="M292.27 187.762L290.997 184.197L290.133 180.167L289.78 176.001L289.974 171.823L290.44 169.076L291.201 166.51L292.236 164.092L293.612 161.878L295.317 159.971L297.387 158.325L298.228 158.972L298.808 159.812L299.103 160.788L299.092 161.844L298.785 163.865L298.285 168.519L298.171 173.662L298.478 175.899L299.035 177.181L299.592 177.624L300.343 177.602L301.491 176.943L303.208 175.104L306.107 170.813L311.405 161.458L316.715 152.16L319.648 147.71L322.945 143.441L325.72 140.615L328.38 138.548L330.95 137.107L333.701 136.108L336.168 135.688L338.419 135.778L340.568 136.369L342.353 137.402L343.831 138.9L344.877 140.739L345.491 143.033L345.628 145.916L345.218 148.788L344.138 152.194L342.251 156.259L340.17 159.539L336.68 165.182L334.872 168.406L333.314 171.562L332.132 174.673L331.666 177.068L331.768 178.51L332.303 179.463L333.269 180.088L334.554 180.201L335.816 179.929L338.601 178.896L341.421 177.602L344.241 176.364L346.958 175.49L348.777 175.217L350.562 175.342L352.358 175.887L353.78 176.773L354.905 177.874L355.792 179.236L356.668 181.552L357.088 184.163L357.1 186.843L356.759 189.431L355.656 193.234L353.837 197.071L351.415 200.636L348.39 203.826L346.094 205.654L343.593 207.152L340.864 208.333L337.999 209.105L334.952 209.412L331.666 209.253L326.459 208.322L320.569 206.801L314.68 204.802L308.893 202.305L305.152 200.341L301.787 198.218L298.751 195.936L296.011 193.348L293.885 190.657L292.304 187.864L292.27 187.762Z"
      fill="url(#paint1_linear_7089_44384)"
    />
    <path
      d="M384.59 209.416L375.434 202.829L365.771 196.538L355.578 190.531L344.837 184.821L334.001 179.636L322.73 174.792L310.993 170.325L298.789 166.21L286.554 162.62L273.956 159.463L260.985 156.728L247.62 154.437L234.275 152.659L220.651 151.371L206.736 150.596L192.5 150.312L178.264 150.596L164.349 151.371L150.714 152.659L137.38 154.437L124.015 156.728L111.044 159.463L98.4459 162.62L86.2109 166.21L74.007 170.325L62.2696 174.792L50.9989 179.636L40.1533 184.821L29.4217 190.531L19.2293 196.538L9.56569 202.829L0.410156 209.416H384.59Z"
      fill="url(#paint2_linear_7089_44384)"
    />
    <path
      d="M315.328 99.9268L313.77 102.152L312.486 104.627L311.565 107.261L311.099 109.963L311.155 112.698L311.838 115.412L312.747 117.013L313.884 118.193L315.101 119.283L316.181 120.486L316.886 121.94L317.113 124.221L316.624 126.73L315.771 129.103L314.555 131.362L312.008 135.404L309.12 139.173L305.914 142.704L307.756 144.577L309.018 146.813L309.666 149.299L309.655 151.922L309.041 154.488L308.029 156.906L306.653 159.131L304.925 161.129L303.651 162.321L302.117 163.15L300.502 163.331L298.888 163.15L293.714 162.026L288.7 160.425L283.834 158.348L281.617 157.178L279.582 155.748L277.911 154.011L276.808 151.911L276.467 150.185L276.558 148.97L276.967 148.119L278.047 147.029L279.502 146.143L280.025 145.848L281.515 144.906L282.697 143.646L283.55 141.716L284.073 139.582L284.732 135.313L285.153 130.59L285.46 128.002L286.097 125.675L286.824 124.335L287.882 123.302L289.326 122.564L292.305 122.03L295.272 121.485L297.182 120.589L298.74 119.385L300.002 117.864L301.537 115.219L302.856 112.267L303.151 111.541L304.686 108.214L305.573 106.67L306.596 105.297L307.847 104.184L309.484 103.196L312.906 101.539L314.418 100.699L315.328 99.9268ZM303.197 123.461L301.628 125.425L297.91 130.692L295.761 134.2L293.51 138.378L291.52 142.817L289.906 147.563L289.121 150.821L288.678 154.079L288.575 157.337H293.453L293.862 154.045L295.34 145.746L296.58 140.558L298.319 134.768L300.491 129.012L303.197 123.461Z"
      fill="url(#paint3_linear_7089_44384)"
    />
    <path
      opacity="0.84"
      d="M124.272 49.0384L123.987 46.3819L123.112 43.9638L121.725 41.8295L119.871 40.0585L117.654 38.7643L115.096 37.9923L112.424 37.8447L109.9 38.3215L107.558 39.3546L105.523 40.8986L103.886 42.874L102.726 45.2807L100.236 45.5759L97.985 46.4841L96.0408 47.9486L94.5173 49.924L93.585 52.2399L93.3008 54.6467L93.6646 57.0534L94.6765 59.3353L96.2569 61.2653L98.2465 62.6616L100.532 63.5017L103.01 63.7174H123.351L125.249 63.4336L126.898 62.7638L128.353 61.6853L129.502 60.2889L130.252 58.6768L130.627 56.8037L130.491 54.8851L129.934 53.1935L128.967 51.6723L127.671 50.4235L126.113 49.5493L124.272 49.0384Z"
      fill="url(#paint4_linear_7089_44384)"
    />
    <path
      d="M169.36 13.8616V13.7935L169.178 11.5684L168.677 9.49082L167.859 7.53816L166.108 4.90435L163.8 2.73599L162.015 1.60072L160.048 0.760628L157.876 0.204348L155.659 0L153.522 0.147585L151.453 0.613043L148.553 1.89589L146.029 3.79179L144.597 5.35845L143.437 7.14082L142.516 9.17295L140.413 9.41135L138.48 9.96763L136.706 10.8418L135.103 12.0111L133.75 13.4075L132.636 15.0423L131.806 16.8473L131.317 18.7773L131.146 20.8889L131.374 22.9891L131.931 24.9077L132.818 26.6787L133.989 28.2795L135.387 29.6304L137.025 30.7543L138.832 31.5717L140.765 32.0713L142.88 32.2302H168.143L170.508 31.935L172.623 31.0836L174.453 29.744L175.909 28.007L176.898 25.9522L177.352 23.6135L177.193 21.2408L176.466 19.0725L175.249 17.1766L173.601 15.6213L171.611 14.5087L169.291 13.9184L169.36 13.8616Z"
      fill="url(#paint5_linear_7089_44384)"
    />
    <path
      d="M230.953 55.0469L269.909 66.6411L245.207 184.636L196.571 166.356L214.886 142.755L205.987 93.9924L230.953 55.0469Z"
      fill="url(#paint6_linear_7089_44384)"
    />
    <path
      d="M230.953 55.0469L269.909 66.6411L245.207 184.636L196.571 166.356L214.886 142.755L205.987 93.9924L230.953 55.0469Z"
      fill="url(#paint7_linear_7089_44384)"
    />
    <path
      d="M227.483 53.4212L227.122 53.3138L226.919 53.6306L201.965 92.5762L201.859 92.7419L201.894 92.9356L210.742 141.478L192.564 164.903L192.148 165.439L192.783 165.677L241.419 183.957L241.965 184.163L242.084 183.592L266.787 65.597L266.881 65.1466L266.44 65.0154L227.483 53.4212Z"
      fill="url(#paint8_linear_7089_44384)"
    />
    <path
      d="M227.483 53.4212L227.122 53.3138L226.919 53.6306L201.965 92.5762L201.859 92.7419L201.894 92.9356L210.742 141.478L192.564 164.903L192.148 165.439L192.783 165.677L241.419 183.957L241.965 184.163L242.084 183.592L266.787 65.597L266.881 65.1466L266.44 65.0154L227.483 53.4212Z"
      fill="url(#paint9_linear_7089_44384)"
    />
    <path
      d="M227.483 53.4212L227.122 53.3138L226.919 53.6306L201.965 92.5762L201.859 92.7419L201.894 92.9356L210.742 141.478L192.564 164.903L192.148 165.439L192.783 165.677L241.419 183.957L241.965 184.163L242.084 183.592L266.787 65.597L266.881 65.1466L266.44 65.0154L227.483 53.4212Z"
      stroke="url(#paint10_linear_7089_44384)"
    />
    <mask id="mask0_7089_44384" style={{ 'mask-type': 'alpha' }} maskUnits="userSpaceOnUse" x="125" y="40" width="166" height="147">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M148.231 40.9991L148.21 40.9951L125.66 159.279L267.601 186.339L290.151 68.055L225.852 55.7969L202.262 92.8609L211.42 141.631L188.658 169.493L125.836 158.472L148.231 40.9991Z"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_7089_44384)">
      <path
        d="M236.44 140.161C237.073 136.805 234.092 133.655 232.522 132.5C231.548 136.083 229.428 143.218 228.737 143.091C227.873 142.933 218.515 149.901 225.11 152.844C231.706 155.787 235.649 144.356 236.44 140.161Z"
        fill="url(#paint11_linear_7089_44384)"
        fill-opacity="0.7"
      />
      <path
        d="M170.452 128.068C171.085 124.712 174.584 122.748 176.254 122.185C175.82 125.868 175.104 133.26 175.709 133.37C176.465 133.509 181.687 143.145 175.322 143.716C168.958 144.287 169.661 132.263 170.452 128.068Z"
        fill="url(#paint12_linear_7089_44384)"
        fill-opacity="0.7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M177.174 117.036C177.805 115.94 179.097 115.417 180.357 115.647L231.741 125.051C233.001 125.281 234.016 126.227 234.206 127.473C234.69 130.656 234.651 133.969 234.022 137.307C230.957 153.559 214.983 164.265 198.342 161.22C181.701 158.174 170.695 142.531 173.76 126.279C174.389 122.941 175.561 119.836 177.174 117.036ZM185.906 121.276C185.234 121.255 184.647 121.719 184.524 122.369L181.975 135.891C181.555 138.118 182.852 140.315 185.029 141.064C195.675 144.725 206.826 146.765 218.096 147.115C220.401 147.186 222.413 145.595 222.833 143.367L225.382 129.846C225.505 129.196 225.126 128.555 224.491 128.337C212.069 124.065 199.057 121.683 185.906 121.276Z"
        fill="white"
      />
      <path
        d="M196.576 127.163C194.49 126.781 192.479 128.133 192.091 130.19L191.294 134.418C190.906 136.476 192.289 138.449 194.376 138.831C196.463 139.213 198.473 137.861 198.861 135.803L199.659 131.575C200.047 129.518 198.663 127.545 196.576 127.163Z"
        fill="white"
        stroke="#A1A7AF"
        stroke-width="0.4"
      />
      <path
        d="M209.529 133.38C209.896 131.434 211.799 130.15 213.78 130.513C215.761 130.875 217.07 132.747 216.703 134.693L215.906 138.921C215.539 140.866 213.635 142.15 211.654 141.788C209.673 141.425 208.365 139.554 208.732 137.608L209.529 133.38Z"
        fill="white"
      />
      <path
        d="M209.118 107.577L180.977 107.681C172.888 106.64 156.505 105.646 155.684 109.999C154.658 115.44 189.737 124.494 205.388 127.359C220.998 130.215 256.921 134.269 257.949 128.818C258.771 124.457 243.162 119.592 235.255 117.706L209.118 107.577Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M209.485 107.093L235.612 117.147C239.568 118.09 245.44 119.768 250.234 121.753C252.633 122.746 254.784 123.826 256.292 124.94C257.045 125.496 257.658 126.076 258.057 126.677C258.459 127.281 258.664 127.936 258.536 128.615C258.363 129.531 257.483 130.123 256.365 130.524C255.21 130.938 253.628 131.212 251.744 131.377C247.97 131.708 242.887 131.611 237.366 131.259C226.32 130.555 213.455 128.822 205.656 127.394C197.836 125.963 185.169 122.996 174.579 119.714C169.286 118.074 164.496 116.35 161.087 114.695C159.385 113.869 158.005 113.05 157.076 112.252C156.611 111.854 156.243 111.446 156.007 111.03C155.769 110.61 155.655 110.16 155.742 109.701C155.87 109.022 156.301 108.484 156.897 108.064C157.49 107.646 158.275 107.323 159.183 107.071C161 106.567 163.407 106.323 166.014 106.247C171.225 106.095 177.334 106.61 181.379 107.132L209.485 107.093ZM209.328 107.917L181.316 107.957L181.291 107.954C177.269 107.433 171.189 106.921 166.023 107.071C163.436 107.147 161.103 107.388 159.378 107.867C158.514 108.106 157.827 108.399 157.34 108.742C156.857 109.083 156.601 109.451 156.527 109.844C156.487 110.055 156.528 110.303 156.699 110.605C156.872 110.911 157.167 111.249 157.594 111.615C158.447 112.347 159.756 113.132 161.438 113.948C164.794 115.577 169.539 117.287 174.821 118.924C185.38 122.196 198.017 125.157 205.809 126.583C213.581 128.005 226.416 129.734 237.429 130.436C242.938 130.788 247.975 130.881 251.69 130.555C253.551 130.392 255.055 130.126 256.114 129.746C257.21 129.353 257.671 128.898 257.751 128.471C257.826 128.076 257.721 127.641 257.395 127.151C257.068 126.659 256.535 126.142 255.819 125.613C254.387 124.555 252.304 123.504 249.925 122.519C245.172 120.551 239.328 118.881 235.395 117.945L235.369 117.939L209.328 107.917Z"
        fill="url(#paint13_linear_7089_44384)"
      />
      <path
        d="M201.557 77.941C202.729 77.6568 204.863 76.053 205.234 74.9634C204.79 81.0263 197.804 82.5971 196.287 85.8811C194.769 89.1652 196.534 91.9814 195.87 94.9764C195.205 97.9713 190.651 98.2955 187.221 100.072C184.477 101.493 180.681 107.75 179.126 110.701L238.482 121.563C238.893 116.206 233.036 106.497 231.351 103.785C230.003 101.615 225.05 96.2501 222.742 93.8391C223.862 94.786 226.445 96.6537 227.823 96.5497C229.545 96.4195 229.281 90.4053 229.219 89.1473C229.156 87.8893 226.447 72.0779 221.32 68.0232C216.206 63.9782 203.343 68.8838 198.582 70.6993L198.548 70.7123C193.814 72.5174 193.427 77.9578 193.564 78.0813C194.074 78.543 196.14 76.3752 197.33 76.415C198.521 76.4548 200.093 78.2962 201.557 77.941Z"
        fill="white"
      />
    </g>
    <path
      d="M124.435 48.166H203.782L187.222 89.818L203.782 136.929L191.546 168.581H124.435V48.166Z"
      fill="url(#paint14_linear_7089_44384)"
    />
    <path
      d="M124.435 48.166H203.782L187.222 89.818L203.782 136.929L191.546 168.581H124.435V48.166Z"
      fill="url(#paint15_linear_7089_44384)"
    />
    <path
      d="M120.822 47.0195H200.17L183.61 88.6715L200.17 135.782L187.933 167.434H120.822V47.0195Z"
      fill="url(#paint16_linear_7089_44384)"
    />
    <path
      d="M120.822 47.0195H200.17L183.61 88.6715L200.17 135.782L187.933 167.434H120.822V47.0195Z"
      fill="url(#paint17_linear_7089_44384)"
    />
    <mask id="mask1_7089_44384" style={{ 'mask-type': 'alpha' }} maskUnits="userSpaceOnUse" x="120" y="47" width="81" height="121">
      <path
        d="M120.821 47.0195H200.169L183.609 88.6715L200.169 135.782L187.932 167.434H120.821V47.0195Z"
        fill="url(#paint18_linear_7089_44384)"
      />
      <path
        d="M120.821 47.0195H200.169L183.609 88.6715L200.169 135.782L187.932 167.434H120.821V47.0195Z"
        fill="url(#paint19_linear_7089_44384)"
      />
    </mask>
    <g mask="url(#mask1_7089_44384)">
      <path
        d="M155.526 130.486C155.518 126.728 158.895 123.885 160.584 122.933C160.874 127.002 161.623 135.139 162.3 135.134C163.145 135.127 170.774 144.466 164.013 146.394C157.252 148.323 155.535 135.184 155.526 130.486Z"
        fill="url(#paint20_linear_7089_44384)"
        fill-opacity="0.7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M160.517 117.179C160.973 115.865 162.261 115.032 163.671 115.022L221.142 114.598C222.552 114.587 223.843 115.4 224.305 116.708C225.485 120.049 226.125 123.636 226.133 127.373C226.169 145.57 211.111 160.432 192.498 160.569C173.885 160.706 158.768 146.068 158.732 127.871C158.724 124.134 159.35 120.537 160.517 117.179ZM170.828 119.961C170.097 120.077 169.558 120.7 169.56 121.428L169.59 136.567C169.595 139.06 171.45 141.168 173.957 141.528C186.217 143.292 198.689 143.2 210.941 141.255C213.447 140.857 215.293 138.723 215.288 136.229L215.258 121.09C215.257 120.363 214.716 119.748 213.984 119.643C199.679 117.585 185.125 117.692 170.828 119.961Z"
        fill="white"
      />
      <path
        d="M179.573 128.32C179.569 126.141 181.362 124.362 183.577 124.345C185.793 124.329 187.593 126.082 187.597 128.261L187.607 132.994C187.611 135.173 185.818 136.952 183.603 136.969C181.387 136.985 179.587 135.232 179.583 133.053L179.573 128.32Z"
        fill="white"
      />
      <path
        d="M198.856 128.175C198.851 125.997 200.644 124.217 202.86 124.201C205.075 124.184 206.875 125.937 206.879 128.116L206.889 132.85C206.893 135.028 205.101 136.808 202.885 136.824C200.669 136.84 198.869 135.088 198.865 132.909L198.856 128.175Z"
        fill="white"
      />
      <path
        d="M193.096 100.374L162.703 106.284C153.747 106.825 135.836 109.127 135.846 114C135.858 120.092 175.635 122.65 193.14 122.521C210.599 122.392 250.259 119.372 250.246 113.269C250.237 108.386 232.365 106.345 223.431 105.935L193.096 100.374Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M193.393 99.7741L223.702 105.257C228.171 105.461 234.863 106.065 240.453 107.223C243.25 107.802 245.798 108.525 247.657 109.418C248.586 109.865 249.367 110.365 249.923 110.932C250.482 111.502 250.838 112.168 250.84 112.928C250.842 113.953 250.012 114.775 248.887 115.438C247.724 116.124 246.07 116.746 244.068 117.312C240.057 118.448 234.544 119.391 228.505 120.148C216.421 121.662 202.16 122.44 193.438 122.504C184.691 122.569 170.389 121.971 158.268 120.606C152.209 119.924 146.678 119.048 142.652 117.962C140.643 117.42 138.982 116.819 137.814 116.149C137.23 115.814 136.748 115.449 136.407 115.048C136.063 114.643 135.847 114.18 135.846 113.666C135.845 112.906 136.2 112.236 136.758 111.659C137.313 111.085 138.094 110.574 139.023 110.115C140.883 109.195 143.435 108.436 146.237 107.817C151.837 106.579 158.545 105.877 163.025 105.608L193.393 99.7741ZM193.393 100.698L163.127 106.512L163.099 106.513C158.644 106.78 151.969 107.478 146.416 108.706C143.636 109.32 141.165 110.062 139.398 110.934C138.514 111.371 137.832 111.829 137.377 112.3C136.924 112.768 136.723 113.219 136.724 113.659C136.725 113.895 136.82 114.155 137.067 114.446C137.318 114.741 137.706 115.045 138.242 115.353C139.315 115.968 140.892 116.547 142.877 117.083C146.84 118.152 152.321 119.022 158.367 119.703C170.453 121.064 184.721 121.66 193.436 121.595C202.128 121.531 216.356 120.755 228.403 119.245C234.43 118.49 239.893 117.553 243.84 116.436C245.818 115.876 247.388 115.278 248.455 114.649C249.559 113.999 249.963 113.412 249.962 112.935C249.961 112.492 249.758 112.043 249.305 111.581C248.849 111.116 248.167 110.668 247.284 110.243C245.519 109.395 243.052 108.689 240.277 108.114C234.735 106.967 228.074 106.365 223.631 106.164L223.602 106.163L193.393 100.698Z"
        fill="url(#paint21_linear_7089_44384)"
      />
      <path
        d="M178.821 69.9012C180.029 69.3526 182.004 67.1798 182.181 65.9256C182.95 72.5697 175.724 75.7067 174.76 79.5686C173.797 83.4306 176.285 86.1106 176.184 89.4844C176.082 92.8581 171.227 94.1468 167.886 96.7735C165.213 98.8749 162.4 106.419 161.328 109.928L227.715 109.438C227.056 103.564 218.725 94.2779 216.345 91.6935C214.441 89.6259 207.983 84.8486 204.993 82.7184C206.397 83.5111 209.574 84.9975 211.041 84.6012C212.876 84.1058 211.351 77.6601 211.025 76.3134C210.698 74.9666 204.512 58.4365 198.137 55.1106C191.776 51.7926 178.884 59.7447 174.113 62.6879L174.079 62.7088C169.335 65.635 170.038 71.5946 170.211 71.6999C170.857 72.0938 172.643 69.3253 173.938 69.123C175.233 68.9207 177.311 70.587 178.821 69.9012Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear_7089_44384" x1="14.3897" y1="-44.8681" x2="101.747" y2="166.064" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E4AEED" />
        <stop offset="1" stop-color="#E8CFF8" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint1_linear_7089_44384" x1="369.89" y1="53.9539" x2="319.136" y2="173.556" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D6A3E2" />
        <stop offset="1" stop-color="#E9D3F1" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint2_linear_7089_44384" x1="195.783" y1="141.064" x2="191.807" y2="209.376" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DA9EE3" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint3_linear_7089_44384" x1="317.146" y1="37.9295" x2="263.06" y2="152.562" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DAB2ED" />
        <stop offset="1" stop-color="#DCCAEB" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint4_linear_7089_44384" x1="111.964" y1="27.9464" x2="111.964" y2="63.7174" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F4DAFB" />
        <stop offset="1" stop-color="#DAC5E3" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint5_linear_7089_44384" x1="154.249" y1="-12.3305" x2="154.249" y2="32.2302" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F4DAFB" />
        <stop offset="1" stop-color="#DAC5E3" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint6_linear_7089_44384" x1="234.537" y1="85.4416" x2="239.269" y2="141.835" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E4E4E4" />
        <stop offset="1" stop-color="#E5E5E5" />
      </linearGradient>
      <linearGradient id="paint7_linear_7089_44384" x1="233.24" y1="184.636" x2="233.24" y2="55.0469" gradientUnits="userSpaceOnUse">
        <stop offset="0.000184755" stop-color="#BB8BF9" />
        <stop offset="1" stop-color="#EDDEFF" />
      </linearGradient>
      <linearGradient id="paint8_linear_7089_44384" x1="230.925" y1="84.2951" x2="235.657" y2="140.689" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EFEFEF" />
        <stop offset="1" stop-color="#F9F9F9" />
      </linearGradient>
      <linearGradient id="paint9_linear_7089_44384" x1="229.628" y1="172.049" x2="229.628" y2="64.4804" gradientUnits="userSpaceOnUse">
        <stop offset="0.000184755" stop-color="#EFC2FF" />
        <stop offset="1" stop-color="#F8EFFF" />
      </linearGradient>
      <linearGradient id="paint10_linear_7089_44384" x1="200.527" y1="95.7588" x2="213.142" y2="98.6258" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E3CDFE" />
        <stop offset="1" stop-color="#F8EAFF" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint11_linear_7089_44384" x1="231.967" y1="132.398" x2="228.094" y2="153.559" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient id="paint12_linear_7089_44384" x1="176.74" y1="122.274" x2="172.867" y2="143.435" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient id="paint13_linear_7089_44384" x1="206.909" y1="110.887" x2="206.016" y2="126.995" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0" />
        <stop offset="1" stop-color="#AA51E5" />
      </linearGradient>
      <linearGradient id="paint14_linear_7089_44384" x1="166.01" y1="76.4089" x2="171.128" y2="128.779" gradientUnits="userSpaceOnUse">
        <stop stop-color="#ECECEC" />
        <stop offset="1" stop-color="#E9E9E9" />
      </linearGradient>
      <linearGradient id="paint15_linear_7089_44384" x1="164.108" y1="168.581" x2="164.108" y2="48.166" gradientUnits="userSpaceOnUse">
        <stop offset="0.000184755" stop-color="#BB8BF9" />
        <stop offset="1" stop-color="#EDDEFF" />
      </linearGradient>
      <linearGradient id="paint16_linear_7089_44384" x1="160.496" y1="56.2937" x2="160.496" y2="167.434" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F4F4F4" />
        <stop offset="1" stop-color="#F6F6F6" />
      </linearGradient>
      <linearGradient id="paint17_linear_7089_44384" x1="160.496" y1="156.804" x2="160.496" y2="56.8506" gradientUnits="userSpaceOnUse">
        <stop offset="0.000184755" stop-color="#EFC2FF" />
        <stop offset="1" stop-color="#F8EFFF" />
      </linearGradient>
      <linearGradient id="paint18_linear_7089_44384" x1="160.495" y1="56.2937" x2="160.495" y2="167.434" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F4F4F4" />
        <stop offset="1" stop-color="#F6F6F6" />
      </linearGradient>
      <linearGradient id="paint19_linear_7089_44384" x1="160.495" y1="156.804" x2="160.495" y2="56.8506" gradientUnits="userSpaceOnUse">
        <stop offset="0.000184755" stop-color="#EFC2FF" />
        <stop offset="1" stop-color="#F8EFFF" />
      </linearGradient>
      <linearGradient id="paint20_linear_7089_44384" x1="161.127" y1="122.929" x2="161.302" y2="146.597" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient id="paint21_linear_7089_44384" x1="191.391" y1="104.406" x2="193.745" y2="121.999" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0" />
        <stop offset="1" stop-color="#AA51E5" />
      </linearGradient>
    </defs>
  </svg>
);

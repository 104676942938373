import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { rentPaymentFailureFeeSettingsRepository } from '~/repositories/settings/rentPaymentFailureFeeSettingsRepository';
import type { Accessor } from 'solid-js';

interface RentPaymentFailureFeeSettingsContextValue {
  isLoading: Accessor<boolean>;
  error: Accessor<Error | undefined>;

  addRentPaymentFailureFeeSettingsByLevel: (
    level: string,
    levelId: string,
    payload: MagicDoor.Api.RentPaymentFailureFeeSettingsDto
  ) => Promise<void>;
  deleteRentPaymentFailureFeeSettingsByLevel: (level: string, levelId: string) => Promise<void>;

  updateRentPaymentFailureFeeSettings: (
    payload: MagicDoor.Api.RentPaymentFailureFeeSettingsDto
  ) => Promise<MagicDoor.Api.RentPaymentFailureFeeSettingsDto | undefined>;
  getRentPaymentFailureFeeSettings: () => Promise<MagicDoor.Api.RentPaymentFailureFeeSettingsDto | undefined>;
  deleteRentPaymentFailureFeeSettings: () => Promise<void>;

  getAllRentPaymentFailureFeeSettings: () => Promise<MagicDoor.Api.RentPaymentFailureFeeSettingsDtoSettingsWithOverridesDto | undefined>;

  allRentPaymentFailureFeeSettings: Accessor<MagicDoor.Api.RentPaymentFailureFeeSettingsDtoSettingsWithOverridesDto | undefined>;
  rentPaymentFailureFeeSettings: Accessor<MagicDoor.Api.RentPaymentFailureFeeSettingsDto | undefined>;
}

export const [RentPaymentFailureFeeSettingsProvider, useRentPaymentFailureFeeSettings] =
  createMagicDoorContext<RentPaymentFailureFeeSettingsContextValue>('RentPaymentFailureFeeSettings', () => {
    const repo = new rentPaymentFailureFeeSettingsRepository();
    const [rentPaymentFailureFeeSettings, setRentPaymentFailureFeeSettings] = createSignal<
      MagicDoor.Api.RentPaymentFailureFeeSettingsDto | undefined
    >(undefined);
    const [allRentPaymentFailureFeeSettings, setAllRentPaymentFailureFeeSettings] = createSignal<
      MagicDoor.Api.RentPaymentFailureFeeSettingsDtoSettingsWithOverridesDto | undefined
    >(undefined);
    const [isLoading, setIsLoading] = createSignal<boolean>(false);
    const [error, setError] = createSignal<Error | undefined>(undefined);

    const getRentPaymentFailureFeeSettings = async (): Promise<MagicDoor.Api.RentPaymentFailureFeeSettingsDto | undefined> => {
      setIsLoading(true);

      try {
        const result = await repo.getRentPaymentFailureFeeSettings();
        setRentPaymentFailureFeeSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const getAllRentPaymentFailureFeeSettings = async (): Promise<
      MagicDoor.Api.RentPaymentFailureFeeSettingsDtoSettingsWithOverridesDto | undefined
    > => {
      setIsLoading(true);
      try {
        const result = await repo.getAllRentPaymentFailureFeeSettings();
        setAllRentPaymentFailureFeeSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const updateRentPaymentFailureFeeSettings = async (
      payload: MagicDoor.Api.RentPaymentFailureFeeSettingsDto
    ): Promise<MagicDoor.Api.RentPaymentFailureFeeSettingsDto | undefined> => {
      setIsLoading(true);

      try {
        const result = await repo.updateRentPaymentFailureFeeSettings(payload);
        setRentPaymentFailureFeeSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    const deleteRentPaymentFailureFeeSettings = async (): Promise<void> => {
      setIsLoading(true);

      try {
        await repo.deleteRentPaymentFailureFeeSettings();
        setRentPaymentFailureFeeSettings(undefined);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    const addRentPaymentFailureFeeSettingsByLevel = async (
      level: string,
      levelId: string,
      payload: MagicDoor.Api.RentPaymentFailureFeeSettingsDto
    ) => {
      setIsLoading(true);
      try {
        await repo.addRentPaymentFailureFeeSettingsByLevel(level, levelId, payload);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const deleteRentPaymentFailureFeeSettingsByLevel = async (level: string, levelId: string) => {
      setIsLoading(true);
      try {
        await repo.deleteRentPaymentFailureFeeSettingsByLevel(level, levelId);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    return {
      isLoading,
      error,
      updateRentPaymentFailureFeeSettings,
      getRentPaymentFailureFeeSettings,
      deleteRentPaymentFailureFeeSettings,
      getAllRentPaymentFailureFeeSettings,
      addRentPaymentFailureFeeSettingsByLevel,
      deleteRentPaymentFailureFeeSettingsByLevel,
      rentPaymentFailureFeeSettings,
      allRentPaymentFailureFeeSettings,
    };
  });

import { createMemo } from 'solid-js';
import NoDataImg from '~/assets/images/empty/noData.svg';
import { Image } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';
export type EmptyProps = {
  imgSrc?: string;
  description?: string;
  size?: string;
  class?: string;
  descriptionClass?: string;
};
export const Empty: Component<EmptyProps> = (props) => {
  const { t } = useLocalization();
  const imgStyle = createMemo<object>(() => {
    return {
      width: props.size,
    };
  });
  return (
    <div class={cn('flex flex-col items-center justify-items-center py-10 text-center', props.class)}>
      <Image class="w-44" style={{ ...imgStyle() }} src={props.imgSrc || NoDataImg} />
      <div class={cn('mt-5 normal-case text-slate-500', props.descriptionClass)}>{props.description || t('No results found')}</div>
    </div>
  );
};

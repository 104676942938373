import { AppState } from './appState';
import { DataPathManager } from './dataPathManager';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import type { AnnotatedPdfPage } from '~/pdfsigner/usecases/types/annotatedPdfPage';
import type { Annotation, AnnotationType } from '~/pdfsigner/usecases/types/annotation';
import type { BoundingBox } from '~/pdfsigner/usecases/types/boundingBox';
import type { LeaseTemplateCategory } from '~/pdfsigner/usecases/types/leaseTemplateCategory';
import type { Position } from '~/pdfsigner/usecases/types/position';
import type { Signer } from '~/pdfsigner/usecases/types/signer';

export interface PdfPackage {
  id?: string;
  pdfs: PDFDocumentProxy[];
  pdfPages: AnnotatedPdfPage[];
  signers: Signer[];
  title?: string;
  category?: LeaseTemplateCategory;
}

export interface PdfState {
  pdfs: PdfPackage;
  selectedAnnotation: Annotation | undefined;
  isEditing: boolean;
  clickPosition: Position | undefined;
  initialBounds: BoundingBox | undefined;
  selectedAnnotationType: AnnotationType | undefined;
  maxNumberOfSigners: number;
  selectedSigner: Signer;
  leaseTemplateCategories: LeaseTemplateCategory[];
  dataPathManager: DataPathManager;
  fontSizes: number[];
  selectedFontSize: number;
}

const signers: Signer[] = [
  {
    name: 'Signer1',
    id: '1',
    isPropertyManager: false,
  },
];

class PdfAppState extends AppState<PdfState> {
  pdfs: PdfPackage = {
    pdfs: [],
    pdfPages: [],
    signers: signers,
  };
  selectedAnnotation: Annotation | undefined = undefined;
  isEditing = false;
  clickPosition: Position | undefined = undefined;
  initialBounds: BoundingBox | undefined = undefined;
  selectedAnnotationType: AnnotationType | undefined = undefined;
  maxNumberOfSigners = 10;
  selectedSigner: Signer = signers[0];
  leaseTemplateCategories: LeaseTemplateCategory[] = [];
  dataPathManager = new DataPathManager([]);
  fontSizes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 30, 36, 48, 60, 72, 96];
  selectedFontSize = 10;

  public getState(): PdfState {
    return {
      pdfs: this.pdfs,
      selectedAnnotation: this.selectedAnnotation,
      isEditing: this.isEditing,
      clickPosition: this.clickPosition,
      initialBounds: this.initialBounds,
      selectedAnnotationType: this.selectedAnnotationType,
      maxNumberOfSigners: this.maxNumberOfSigners,
      selectedSigner: this.selectedSigner,
      leaseTemplateCategories: this.leaseTemplateCategories,
      dataPathManager: this.dataPathManager,
      fontSizes: this.fontSizes,
      selectedFontSize: this.selectedFontSize,
    };
  }
}

export const pdfAppState = new PdfAppState();

import { BaseRestRepository } from './baseRestRepository';

const LANGUAGES_URL = '/api/public/languages';

export class TranslationsRepository extends BaseRestRepository {
  public async getLanguages(): Promise<MagicDoor.Api.PublicLanguage[]> {
    const response = await this.fetchWithAuth(LANGUAGES_URL);
    return this.getJsonResponse(response);
  }
}

import { Outlet } from '@solidjs/router';
import { Menu } from './Menu';
import { Navigation } from './Navigation';

const Layout = () => {
  return (
    <div class="min-h-dvh bg-inputbox-bg">
      <Menu />
      <main class="overflow-x-hidden pt-16 md:pl-64">
        <Navigation />
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;

export async function sendRequestsInPool(promisesFn: (() => Promise<any>)[], poolSize: number): Promise<any[]> {
  const results: any[] = [];

  async function sendNextPromise(): Promise<void> {
    if (promisesFn.length === 0) {
      return;
    }

    const promiseFn = promisesFn.shift();
    if (!promiseFn) return;

    try {
      const result = await promiseFn();
      results.push(result);
    } catch (error) {
      console.error(error);
    }

    await sendNextPromise();
  }

  const sendPool = [];
  for (let i = 0; i < poolSize; i++) {
    sendPool.push(sendNextPromise());
  }

  await Promise.all(sendPool);

  return results;
}

import { useNavigate } from '@solidjs/router';
import { createMemo, Show } from 'solid-js';
import EnabledIcon from '~/assets/images/aiRunBooks/enabledIcon.svg?component-solid';
import headerIcon from '~/assets/images/aiRunBooks/headerIcon.png';
import NotEnabledIcon from '~/assets/images/aiRunBooks/notEnabledIcon.svg?component-solid';
import IconDelete from '~/assets/images/common/delete.svg?component-solid';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import InputSwitch from '~/components/common/Inputs/InputSwitch';
import { DropdownActions, Skeleton, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import type { Component } from 'solid-js';
export const AIRunbookItem: Component<{
  runbook?: MagicDoor.Api.ListRunBookDto;
  onChangeStatus?: (value: boolean) => void;
  loading?: boolean;
}> = (props) => {
  const { t } = useLocalization();

  const { deleteRunBook } = useRunBooks();

  const isEnable = createMemo(() => props.runbook?.enabled);
  const navigate = useNavigate();

  const handleDeleteRunBook = async () => {
    await deleteRunBook(props.runbook?.id as string);
    toast.success(t('{name} has been deleted successfully', { name: t('AI Runbooks') }));
  };
  return (
    <div class={cn('overflow-hidden rounded-lg shadow-sm', isEnable() ? 'bg-white bg-gradient-mysticViolet' : 'bg-input')}>
      <div class={cn('relative flex h-15 items-center justify-between pl-4', isEnable() ? 'text-white' : 'text-text-level03')}>
        <div class="flex items-center gap-1.5">
          <Show when={isEnable()} fallback={<NotEnabledIcon />}>
            <EnabledIcon />
          </Show>
          <span>{t('Enable AI')}</span>
        </div>

        <div
          class=" absolute right-0 z-0 h-full w-1/2"
          style={{
            background: isEnable() ? `url(${headerIcon})` : 'none',
          }}
        />
        <div class="pr-4">
          <Show when={!props.loading} fallback={<Skeleton class="w-10" />}>
            <InputSwitch checked={isEnable()} onChange={props.onChangeStatus} />
          </Show>
        </div>
      </div>

      <div class=" flex h-[calc(100%-60px)] min-h-20 flex-col gap-4 divide-y overflow-hidden rounded-lg bg-white px-4 pt-4">
        <div
          class="flex flex-1 cursor-pointer flex-col gap-2"
          onClick={() => {
            navigate(`/maintenance/ai-runbooks/${props.runbook?.id}`);
          }}>
          <Show when={!props.loading} fallback={<Skeleton class="w-20" />}>
            <div class="font-semibold text-text-level01">{props.runbook?.title}</div>
          </Show>
          <Show when={!props.loading} fallback={<Skeleton class="w-full" />}>
            <div class="line-clamp-3 text-xs text-text-level03">{props.runbook?.description}</div>
          </Show>
        </div>
        <div class="flex h-24 flex-col items-start gap-2 pt-5 text-xs">
          {/* TODO: need api to get vendor */}
          <div class="font-medium text-text-level01">
            {t('Vendor')}: {emptyPlaceholder}
          </div>
          <div class="flex w-full items-center justify-between">
            <div class="mt-3 inline-flex items-center justify-center rounded-lg bg-input p-1 text-text-level03">
              {t('Usage')}: {props.runbook?.usageCount ?? 0}
            </div>
            <Show when={props.runbook}>
              <DropdownActions
                actions={[
                  {
                    icon: IconDelete,
                    label: t('Delete'),
                    onClick: handleDeleteRunBook,
                  },
                  {
                    label: t('Edit'),
                    icon: IconPencil,
                    onClick: () => navigate(`${props.runbook?.id}/edit`),
                  },
                ]}
              />
            </Show>
          </div>
        </div>
      </div>
    </div>
  );
};

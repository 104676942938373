import { mergeProps, onMount, Show } from 'solid-js';
import { createStore } from 'solid-js/store';
import IconMinus from '~/assets/images/common/minus.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { LabeledGroup } from '~/components/common/Inputs';
import { FileItem, Upload, UploadTrigger } from '~/components/common/Upload';
import { FileRepository } from '~/components/common/Upload/request';
import type { JSX } from 'solid-js';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { HydrateFile, UploaderFile } from '~/components/common/Upload/Interface';
import type { FileType } from '~/swagger/Api';

interface FileUploaderProps extends LabeledGroupProps {
  trigger: JSX.Element;
  url: string;
  type: FileType;
  initialFile?: HydrateFile;
  accept?: string;
  autoUpload?: boolean;
  ref?: any;
}

const FilePreview = (props: { file: HydrateFile; onClean: () => void }) => {
  return (
    <div class="flex items-center gap-2">
      <FileItem file={props.file} />
      <Button onClick={props.onClean} variant="text">
        <IconMinus />
      </Button>
    </div>
  );
};

export const FileUploader = (originProps: FileUploaderProps) => {
  const props = mergeProps(
    {
      autoUpload: true,
    },
    originProps
  );
  const file = new FileRepository();
  const [state, setState] = createStore<{
    file?: HydrateFile;
  }>({ file: undefined });

  const uploadFile = async (files: UploaderFile) => {
    await file.uploadFile(props.url, files, { params: { type: props.type } });
  };

  const onFilesListChange = async (files: UploaderFile[]) => {
    if (!files.length) return;
    setState({ file: files[0] });
    props.autoUpload && (await uploadFile(files[0]));
  };

  props.ref &&
    props.ref({
      async submit() {
        state.file && !state.file.fileId && (await uploadFile(state.file as UploaderFile));
      },
    });

  onMount(() => {
    if (props.initialFile) {
      setState({ file: props.initialFile });
    }
  });

  return (
    <Upload multiple={false} accept={props.accept} onFilesListChange={onFilesListChange}>
      <LabeledGroup {...props} labelContainerClass="h-full" labelClass={props.labelClass} error={props.error}>
        <div class="flex min-h-20 w-96 flex-1 cursor-pointer flex-col items-center justify-center gap-1 rounded-lg border border-partingline text-xs text-primary">
          <Show
            when={!state.file}
            fallback={
              <FilePreview
                file={state.file as HydrateFile}
                onClean={() => {
                  const currentFile = state.file;
                  if (currentFile?.fileId) {
                    file.deleteFile(`${props.url}/${currentFile.fileId}`);
                  }
                  setState({ file: undefined });
                }}
              />
            }>
            <UploadTrigger>
              <div class="flex cursor-pointer flex-col items-center gap-1">{props.trigger}</div>
            </UploadTrigger>
          </Show>
        </div>
      </LabeledGroup>
    </Upload>
  );
};

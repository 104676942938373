import type { Icon } from '~/components/ui';

export const IconAutoPayments: Icon = (props) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="18" cy="18" r="18" fill="#E0F2FF" />
    <path
      d="M26.5 12.0667C26.5 11.5879 26.1192 11.2002 25.6502 11.2002H10.3498C9.88017 11.2002 9.5 11.5885 9.5 12.0667V14.0189H26.4972L26.5 12.0667ZM23.9476 19.1059C24.8858 19.1059 25.7584 19.3778 26.4989 19.8406L26.4972 16.2798H9.5V23.3336C9.5 23.8124 9.88069 24.2002 10.3498 24.2002H19.1464C19.1408 24.1035 19.1317 24.0079 19.1317 23.9102C19.1317 21.257 21.2875 19.1059 23.9476 19.1059ZM15.138 22.7106H11.4972V21.7912H15.138V22.7106Z"
      fill="#FFD771"
    />
    <path
      d="M26.5 12.0667C26.5 11.5879 26.1192 11.2002 25.6502 11.2002H10.3498C9.88017 11.2002 9.5 11.5885 9.5 12.0667V14.0189H26.4972L26.5 12.0667ZM23.9476 19.1059C24.8858 19.1059 25.7584 19.3778 26.4989 19.8406L26.4972 16.2798H9.5V23.3336C9.5 23.8124 9.88069 24.2002 10.3498 24.2002H19.1464C19.1408 24.1035 19.1317 24.0079 19.1317 23.9102C19.1317 21.257 21.2875 19.1059 23.9476 19.1059ZM15.138 22.7106H11.4972V21.7912H15.138V22.7106Z"
      fill="url(#paint0_linear_118_701)"
    />
    <path
      d="M26.5 12.0667C26.5 11.5879 26.1192 11.2002 25.6502 11.2002H10.3498C9.88017 11.2002 9.5 11.5885 9.5 12.0667V14.0189H26.4972L26.5 12.0667ZM23.9476 19.1059C24.8858 19.1059 25.7584 19.3778 26.4989 19.8406L26.4972 16.2798H9.5V23.3336C9.5 23.8124 9.88069 24.2002 10.3498 24.2002H19.1464C19.1408 24.1035 19.1317 24.0079 19.1317 23.9102C19.1317 21.257 21.2875 19.1059 23.9476 19.1059ZM15.138 22.7106H11.4972V21.7912H15.138V22.7106Z"
      fill="url(#paint1_linear_118_701)"
    />
    <path
      d="M24.4999 20.2002C22.2909 20.2002 20.5 21.9914 20.5 24.2003C20.5 26.409 22.2909 28.2002 24.4999 28.2002C26.7092 28.2002 28.5 26.4091 28.5 24.2003C28.5 21.9914 26.7092 20.2002 24.4999 20.2002ZM26.19 25.1204H24.6524C24.5301 25.1203 24.4128 25.0717 24.3264 24.9853C24.2399 24.8989 24.1913 24.7818 24.1913 24.6596V22.5098C24.1945 22.3897 24.2445 22.2757 24.3307 22.192C24.4168 22.1082 24.5323 22.0614 24.6525 22.0614C24.7727 22.0614 24.8882 22.1082 24.9743 22.192C25.0605 22.2757 25.1105 22.3897 25.1137 22.5098V24.199H26.19C26.3102 24.2023 26.4244 24.2522 26.5082 24.3383C26.5921 24.4243 26.639 24.5396 26.639 24.6597C26.639 24.7798 26.5921 24.8951 26.5082 24.9811C26.4244 25.0672 26.3102 25.1171 26.19 25.1204Z"
      fill="white"
    />
    <path
      d="M24.4999 20.2002C22.2909 20.2002 20.5 21.9914 20.5 24.2003C20.5 26.409 22.2909 28.2002 24.4999 28.2002C26.7092 28.2002 28.5 26.4091 28.5 24.2003C28.5 21.9914 26.7092 20.2002 24.4999 20.2002ZM26.19 25.1204H24.6524C24.5301 25.1203 24.4128 25.0717 24.3264 24.9853C24.2399 24.8989 24.1913 24.7818 24.1913 24.6596V22.5098C24.1945 22.3897 24.2445 22.2757 24.3307 22.192C24.4168 22.1082 24.5323 22.0614 24.6525 22.0614C24.7727 22.0614 24.8882 22.1082 24.9743 22.192C25.0605 22.2757 25.1105 22.3897 25.1137 22.5098V24.199H26.19C26.3102 24.2023 26.4244 24.2522 26.5082 24.3383C26.5921 24.4243 26.639 24.5396 26.639 24.6597C26.639 24.7798 26.5921 24.8951 26.5082 24.9811C26.4244 25.0672 26.3102 25.1171 26.19 25.1204Z"
      fill="url(#paint2_linear_118_701)"
    />
    <path
      d="M24.4999 20.2002C22.2909 20.2002 20.5 21.9914 20.5 24.2003C20.5 26.409 22.2909 28.2002 24.4999 28.2002C26.7092 28.2002 28.5 26.4091 28.5 24.2003C28.5 21.9914 26.7092 20.2002 24.4999 20.2002ZM26.19 25.1204H24.6524C24.5301 25.1203 24.4128 25.0717 24.3264 24.9853C24.2399 24.8989 24.1913 24.7818 24.1913 24.6596V22.5098C24.1945 22.3897 24.2445 22.2757 24.3307 22.192C24.4168 22.1082 24.5323 22.0614 24.6525 22.0614C24.7727 22.0614 24.8882 22.1082 24.9743 22.192C25.0605 22.2757 25.1105 22.3897 25.1137 22.5098V24.199H26.19C26.3102 24.2023 26.4244 24.2522 26.5082 24.3383C26.5921 24.4243 26.639 24.5396 26.639 24.6597C26.639 24.7798 26.5921 24.8951 26.5082 24.9811C26.4244 25.0672 26.3102 25.1171 26.19 25.1204Z"
      fill="url(#paint3_linear_118_701)"
    />
    <defs>
      <linearGradient id="paint0_linear_118_701" x1="18" y1="11.2002" x2="18" y2="24.2002" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0CD9A8" />
        <stop offset="1" stop-color="#35F5C7" />
      </linearGradient>
      <linearGradient id="paint1_linear_118_701" x1="18" y1="11.2002" x2="18" y2="24.2002" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8CB8FA" />
        <stop offset="1" stop-color="#4293F2" />
      </linearGradient>
      <linearGradient id="paint2_linear_118_701" x1="24.5" y1="20.2002" x2="24.5" y2="28.2002" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FAA78C" />
        <stop offset="1" stop-color="#F28142" />
      </linearGradient>
      <linearGradient id="paint3_linear_118_701" x1="24.5" y1="20.2002" x2="24.5" y2="28.2002" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8CB8FA" />
        <stop offset="1" stop-color="#4293F2" />
      </linearGradient>
    </defs>
  </svg>
);

import { ChatWindow } from '~/components/common/ChatWindow';

interface VendorCommunicationsTabProps {
  vendorId?: string;
  vendorChatId?: string;
}

const VendorCommunicationsTab = (props: VendorCommunicationsTabProps) => {
  return (
    <ChatWindow
      participantId={props.vendorId}
      chatId={props.vendorChatId}
      participantType="vendor"
      class="h-section2 rounded-lg border-none"
    />
  );
};

export default VendorCommunicationsTab;

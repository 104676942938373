import { ContributionsSettingsProvider } from '~/contexts/local';
import { Contributions } from './Contributions';

export const ContributionsWrap = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <ContributionsSettingsProvider>
        <Contributions />
      </ContributionsSettingsProvider>
    </div>
  );
};

export type Model = {
  chatMessage: string;
  emailMessage: string;
  subject: string;
  targetType: string;
  hasBalanceDue: boolean;
  includeEndedLease: boolean;
  portfolioIds: string[] | undefined;
  propertyIds: string[] | undefined;
  unitIds: string[] | undefined;
  chatAttachments?: File[];
  emailAttachments?: File[];
};

export const initialModel: Model = {
  chatMessage: '',
  emailMessage: '',
  subject: '',
  targetType: '',
  hasBalanceDue: false,
  includeEndedLease: false,
  portfolioIds: undefined,
  propertyIds: undefined,
  unitIds: undefined,
  chatAttachments: undefined,
  emailAttachments: undefined,
};

export const formDataKeys: Record<keyof Model, string> = {
  chatMessage: 'ChatMessage',
  emailMessage: 'EmailMessage',
  subject: 'Subject',
  targetType: 'TargetType',
  hasBalanceDue: 'HasBalanceDue',
  includeEndedLease: 'IncludeEndedLease',
  portfolioIds: 'PortfolioIds',
  propertyIds: 'PropertyIds',
  unitIds: 'UnitIds',
  chatAttachments: 'ChatAttachments',
  emailAttachments: 'EmailAttachments',
};

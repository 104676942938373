import { createMemo, createSignal, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconRentPayment from '~/assets/images/settings/property/propertyRentPayment.svg';
import { Button } from '~/components/common/Buttons';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { Html, IconLoader, confirm, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRentPaymentProcessingFeesSettings } from '~/contexts/local';
import { HttpError } from '~/errors';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import Overrides from '~/pages/settings/components/Overrides';
import { AddOverridesModal } from '~/pages/settings/components/add-overrides-modal';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';
import type { ItemProps } from '~/pages/settings/components/edit-form/EditForm';

export const RentPaymentProcessingFeesSettings = () => {
  const { t } = useLocalization();
  const { allSettings, updateSettings, updateLevelSettings, deleteLevelSettings } = useRentPaymentProcessingFeesSettings();

  const [showModal, setShowModal] = createSignal<boolean>(false);

  const sections = createMemo<ItemProps[]>(() => {
    return [
      // {
      //   field: 'processingFeesIsCompanyBill',
      //   label: 'Processing fees is company bill',
      //   type: 'boolean',
      //   class: 'col-span-2',
      //   labelContainerClass: 'flex-row justify-between',
      // },
      {
        field: 'propertyManagerPaysProcessingFees',
        label: 'Pay the payment processing fee on behalf on the tenants',
        type: 'boolean',
        class: 'col-span-2',
        labelClass: 'w-1/2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        description:
          'Processing fees are $2.49, if you select to pay the processing fees for your tenants we will send a bill at the end of the month.',
      },
      {
        field: 'propertyManagerPaysProcessingFeesChartOfAccountId',
        label: 'Payment Processing chart of account',
        type: 'chartOfAccountSelect',
        class: 'col-span-2',
        labelClass: 'w-1/2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        description: 'What chart of account should be used for management fees.',
      },
      {
        field: 'propertyManagerProcessingFeeChartOfAccountId',
        label: 'Failed payment processing chart of account',
        type: 'chartOfAccountSelect',
        class: 'col-span-2',
        labelClass: 'w-1/2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        description:
          'When a payment fails because of issues like not enough funds, we have to recover the payment processing fee. Select what chart of account to use for the charge here.',
      },
      {
        field: 'propertyManagerProcessingFee',
        label: "Property manager's processing fee",
        type: 'number',
        prepend: '$',
        class: 'col-span-2',
        labelClass: 'w-1/2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        inputContainerClass: 'h-10',
        description:
          'We allow property mangers to charge a fee when tenants pay online, this amount will be included in the payment processing fee. The money will be deposited in the same bank account, but it will not be visible to property owners.',
      },
    ];
  });

  const handleError = (e: unknown) => {
    if (e instanceof HttpError) toast.error(Object.values(e.getErrors()));
    else if (e instanceof Error) toast.error(e.message);
    else toast.error(t('Something went wrong'));
  };

  const handleSave = async (data: MagicDoor.Api.RentPaymentProcessingFeesSettingsDto) => {
    try {
      await updateSettings(data);
      toast.success(t('Rent payment processing fees settings saved successfully'));
    } catch (e) {
      handleError(e);
    }
  };

  const handleCreateOverride = async (level: string, levelId: string) => {
    try {
      await updateLevelSettings(
        level as MagicDoor.Api.SettingsLevel,
        levelId,
        allSettings()?.settings ?? ({} as MagicDoor.Api.RentPaymentProcessingFeesSettingsDto)
      );
      toast.success(t('Rent payment processing fees override created successfully'));
    } catch (e) {
      handleError(e);
    }
    setShowModal(false);
  };

  const handleSaveOverride = async (data: MagicDoor.Api.RentPaymentProcessingFeesSettingsDto, level?: string, levelId?: string) => {
    if (!level || !levelId) {
      toast.error(t('Something went wrong'));
      return;
    }
    try {
      await updateLevelSettings(level as MagicDoor.Api.SettingsLevel, levelId, data);
      toast.success(t('Rent payment processing fees override saved successfully'));
    } catch (e) {
      handleError(e);
    }
  };

  const handleDeleteOverride = async (level: string, levelId: string) => {
    try {
      const confirmed = await confirm({
        title: t(`Delete override`),
        content: (
          <Html as="p" class="text-text-level03 [&>b]:font-bold [&>b]:text-primary">
            {t('Are you sure you want to delete <b>{level}</b> override?', { level })}
          </Html>
        ),
      });
      if (!confirmed) return; // eslint-disable-line solid/components-return-once
      await deleteLevelSettings(level as MagicDoor.Api.SettingsLevel, levelId);
      toast.success(t('Rent payment processing fees override deleted successfully'));
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <>
      <ThreeSectionBox
        class="self-start"
        topAction={
          <Button variant="white" class="rounded-3xl px-3 py-1 text-sm" onClick={() => setShowModal(true)}>
            <IconPlus /> <div class="hidden font-medium md:block">{t('Add new overrides')}</div>
          </Button>
        }
        topTitle={<SettingTitle title={t('Rent payment processing fees')} icon={IconRentPayment} />}
        middle={
          <Show when={!allSettings.loading} fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
            <div class="flex flex-col gap-5 p-4">
              <div class="flex flex-col gap-4 p-2 text-left">
                <EditForm
                  sections={sections()}
                  onOk={handleSave}
                  model={allSettings()?.settings || ({} as MagicDoor.Api.RentPaymentProcessingFeesSettingsDto)}
                />
              </div>
              <Overrides overrides={allSettings()?.overrides || []} onDelete={handleDeleteOverride} title={t('Rent payment overwrites')}>
                {(override) => {
                  return (
                    <div class="flex flex-col gap-4 p-2 text-left">
                      <EditForm
                        sections={sections()}
                        onOk={(data) => handleSaveOverride(data, override.level, override.levelId)}
                        model={override.settings || ({} as MagicDoor.Api.RentPaymentProcessingFeesSettingsDto)}
                      />
                    </div>
                  );
                }}
              </Overrides>
            </div>
          </Show>
        }
      />
      <Show when={showModal()}>
        <AddOverridesModal
          overrides={allSettings()?.overrides || []}
          onClose={() => setShowModal(false)}
          onDone={handleCreateOverride}
          loading={updateLevelSettings.pending}
        />
      </Show>
    </>
  );
};

import { Route, Routes } from '@solidjs/router';
import { AccountActivities } from './AccountActivities';
import { AccountInformation } from './AccountInformation';
import { AccountSecurity } from './AccountSecurity';

const AccountRoutes = () => {
  return (
    <Routes>
      <Route path="/information" element={<AccountInformation />} />
      <Route path="/activities" element={<AccountActivities />} />
      <Route path="/security" element={<AccountSecurity />} />
    </Routes>
  );
};

export default AccountRoutes;

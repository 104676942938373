import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist';
import { fileLoader } from './fileLoader';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import type { AnnotatedPdfPage } from '~/pdfsigner/usecases/types/annotatedPdfPage';

export class PdfLoader {
  public async loadPdfs(files: FileList): Promise<PDFDocumentProxy[] | undefined> {
    if (!files) {
      return undefined;
    }
    const documents = [];
    for (let i = 0; i < files.length; i++) {
      try {
        const arrayBuffer = await fileLoader.readFile(files[i]);
        GlobalWorkerOptions.workerSrc = '../../node_modules/pdfjs-dist/build/pdf.worker.min.mjs';
        const loadingTask = getDocument({ data: arrayBuffer });
        documents.push(await loadingTask.promise);
      } catch (error: any) {
        console.log(error);
      }
    }
    return documents;
  }

  public async loadPdf(url: string): Promise<PDFDocumentProxy | undefined> {
    if (!url) {
      return undefined;
    }
    try {
      GlobalWorkerOptions.workerSrc = '../../node_modules/pdfjs-dist/build/pdf.worker.min.mjs';
      const loadingTask = getDocument(url);
      return await loadingTask.promise;
    } catch (error: any) {
      console.log(error);
    }
    return undefined;
  }

  public async getPdfPages(pdfDocument: PDFDocumentProxy): Promise<AnnotatedPdfPage[]> {
    const result: AnnotatedPdfPage[] = [];
    for (let i = 1; i <= pdfDocument.numPages; i++) {
      const page = await pdfDocument.getPage(i);
      const annotatedPage: AnnotatedPdfPage = {
        page,
        annotations: [],
      };
      result.push(annotatedPage);
    }
    return result;
  }
}

import { For } from 'solid-js';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization } from '~/contexts/global';

const VendorTransactionsSkeleton = () => {
  const { t } = useLocalization();

  return (
    <ThreeSectionBox
      topTitle={<div>{t('Transactions')}</div>}
      topAction={
        <button class="flex items-center gap-1">
          <div>{t('Details')}</div>
          <IconViewArrow class="size-4 text-link" />
        </button>
      }
      middle={
        <div class="h-64 w-full">
          <div class="relative flex h-full justify-center p-4">
            <div class="absolute left-4 top-4 flex select-none flex-col gap-2 bg-white text-left">
              <div class="skeleton-shimmer h-8 w-40" />
              <div class="skeleton-shimmer h-4 w-24" />
            </div>

            <div class="flex w-full flex-row items-end justify-between gap-4">
              <For each={[...Array(5)]}>
                {() => (
                  <div class="flex h-full flex-1 flex-col justify-end rounded-md text-text-level03">
                    <div class="skeleton-shimmer h-28" />
                    <span class="skeleton-shimmer mt-3 h-4" />
                  </div>
                )}
              </For>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default VendorTransactionsSkeleton;

import { Show } from 'solid-js';
import IconAppMsg from '~/assets/images/chat/sendChat.svg?component-solid';
import IconEmailMsg from '~/assets/images/chat/sendEmail.svg?component-solid';
import IconTextMsg from '~/assets/images/chat/sendText.svg?component-solid';
import { dateFormat } from '~/utils/date';
import Tooltip from './Tooltip';

interface MessageSourceIconsProps {
  message: MagicDoor.Api.ChatMessageDto;
}

export const MessageSourceIcons = (props: MessageSourceIconsProps) => {
  const getTextTooltip = () => {
    if (props.message?.textStatus === 'delivered' && props.message?.textDeliveredAt) {
      return `SMS delivered at ${dateFormat('', props.message?.textDeliveredAt)}`;
    } else if (props.message?.textStatus === 'sent' && props.message?.textSentAt) {
      return `SMS sent at ${dateFormat('', props.message?.textSentAt)}`;
    }
    return 'SMS';
  };

  const getEmailTooltip = () => {
    // WAITING FOR BACKEND
    // if (props.message?.emailDeliveredAt) {
    //   return `Email delivered at ${dateFormat('', props.message?.emailDeliveredAt)}`;
    // } else if (props.message?.emailSentAt) {
    //   return `Email sent at ${dateFormat('', props.message?.emailSentAt)}`;
    // }
    return 'Email';
  };

  return (
    <div class="flex gap-0.5 text-auxiliary-text">
      <Show when={props.message.source === 'app'}>
        <Tooltip message="In app message" align="left">
          <IconAppMsg />
        </Tooltip>
      </Show>
      <Show when={props.message.source === 'email' || props.message.sendEmail}>
        <Tooltip message={getEmailTooltip()} align="left">
          <IconEmailMsg />
        </Tooltip>
      </Show>
      <Show when={props.message.source === 'text' || props.message.sendText}>
        <Tooltip message={getTextTooltip()} align="left">
          <IconTextMsg />
        </Tooltip>
      </Show>
    </div>
  );
};

import { A } from '@solidjs/router';
import IconHeader from '~/assets/images/vendors/headerImg.png';
import CnMoney from '~/components/common/CnMoney';
import IconField from '~/components/common/IconField';
import { IconMail, IconPhone, Image } from '~/components/ui';
import { useLocalization } from '~/contexts/global';

interface ParticipantDetailsProps {
  participant: MagicDoor.Api.HydratedChatParticipantDto;
  viewParticipantDetails: (id: string) => string | undefined;
}

export const ParticipantDetails = (props: ParticipantDetailsProps) => {
  const { t } = useLocalization();

  return (
    <div class="w-56 md:min-w-80">
      <div class="relative flex gap-2 p-4">
        <img class="absolute left-0 top-0 size-full" src={IconHeader} />
        <Image
          src={`https://i.pravatar.cc/150?u=${props.participant.id}`}
          alt="Profile Picture"
          class="hidden size-14 rounded-full border-2 border-white/50 md:flex"
        />
        <div class="flex flex-col justify-center">
          <div class="text-lg font-semibold">{props.participant.name}</div>
          <div class="text-xs font-normal capitalize text-text-level03">{props.participant.participantType}</div>
        </div>
      </div>

      <div class="flex flex-col gap-4 p-4">
        <IconField name={t('Phone')} value={props.participant.phoneNumber} src={IconPhone} iconRounded />
        <IconField name={t('Email')} value={props.participant.email} src={IconMail} iconRounded />
      </div>
      <div class="flex items-center justify-between gap-2 p-4">
        <div
          class={`flex w-full items-center justify-between rounded-md p-2 ${
            props.participant.tenant?.currentLease?.balance ? 'bg-danger-light' : 'bg-success-light'
          }`}>
          <div class="text-text-level03">{t('Balance due')}</div>
          <CnMoney money={props.participant.tenant?.currentLease?.balance} />
        </div>
      </div>
      <A
        href={props.viewParticipantDetails(props.participant.id) || ''}
        class="flex items-center justify-center border-t px-4 py-3 text-sm font-medium text-link hover:underline">
        {t('View details')}
      </A>
    </div>
  );
};

import { useBeforeLeave } from '@solidjs/router';
import { onCleanup, onMount } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import type { BeforeLeaveEventArgs } from '@solidjs/router';
import type { ParentComponent } from 'solid-js';

type PageCloseGuardProps = {
  isDirty?: boolean;
};

export const PageCloseGuard: ParentComponent<PageCloseGuardProps> = (props) => {
  const { t } = useLocalization();
  const handleBeforeUnload = (ev: BeforeUnloadEvent) => {
    if (props.isDirty) {
      ev.preventDefault();
    }
  };

  useBeforeLeave(({ defaultPrevented, preventDefault, retry }: BeforeLeaveEventArgs) => {
    if (props.isDirty && !defaultPrevented) {
      preventDefault();
      if (window.confirm(t('Discard unsaved changes - are you sure?'))) {
        retry(true);
      }
    }
  });

  onCleanup(() => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  });

  onMount(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
  });

  return <>{props.children}</>;
};

import { LeaseDepositTransactionDetailsModal } from '~/pages/leases/lease-details/components/lease-deposit-transaction/LeaseDepositTransactionDetailsModal';
import type { Component } from 'solid-js';
import type { ModalProps } from '~/components/modals/Modal';

type LeaseDepositDetailModalProps = Omit<ModalProps, 'title'> & {
  currentLease: MagicDoor.Api.HydratedLeaseDto;
  transactionDetail: MagicDoor.Api.HydratedLeaseTransactionDto;
};

export const LeaseDepositDetailModal: Component<LeaseDepositDetailModalProps> = (props) => {
  return <LeaseDepositTransactionDetailsModal {...props} showBillDate />;
};

import { Show } from 'solid-js';
import DisplayAddress from '~/components/common/DisplayAddress';
import { UnitImage } from '~/components/units';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import type { Component } from 'solid-js';
interface UnitItemProps {
  class?: string;
  unit?: any;
}

const UnitItem: Component<UnitItemProps> = (props) => {
  return (
    <div class={cn('flex items-center rounded-lg', props.class)}>
      <UnitImage class="mr-2 size-11 rounded-lg" unit={props.unit} />
      <div>
        <div class=" text-sm font-medium text-text-level01">{props.unit?.name || emptyPlaceholder}</div>
        <Show when={props.unit?.property}>
          <DisplayAddress
            class="text-[.75em] font-normal leading-[1.2em] text-current-alpha-8"
            address={props.unit?.property?.address}
            data-slot="address"
          />
        </Show>
      </div>
    </div>
  );
};

export default UnitItem;

import { Route, Routes } from '@solidjs/router';
import { RentalApplicationDetails } from '~/pages/properties/property-details/rental-application-details';
import { NewRentalApplication } from './add-rental-application';
import { DraftRentalApplicationsPage } from './list-rental-application/DraftRentalApplicationsPage';
import { RentalApplicationsPage } from './list-rental-application/RentalApplicationsPage';

export const RentalApplicationRoutes = () => (
  <Routes>
    <Route path="/rental-applications/new/*" component={NewRentalApplication} />
    <Route
      path={['/rental-applications/:applicationId/*', '/draft-rental-applications/:applicationId/*']}
      component={RentalApplicationDetails}
    />
    <Route path="/rental-applications/*" component={RentalApplicationsPage} />
    <Route path="/draft-rental-applications/*" component={DraftRentalApplicationsPage} />;
  </Routes>
);

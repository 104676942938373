import { Route, Routes, useLocation, useNavigate } from '@solidjs/router';
import { createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { getTenantFullName } from '~/components/tenants/utils';
import { TabLinks } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { Communication } from '~/pages/properties/property-details/rental-application-details/communication';
import { emptyPlaceholder } from '~/utils/constant';
import { RenterReport } from './report';

export const RentalApplicationDetails = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const location = useLocation();

  const { applicationStore } = useRentalApplication();

  const from = createMemo(() => location.pathname.slice(0, location.pathname.lastIndexOf('/')));

  const breadcrumbItems = createMemo(() => [
    { label: from().includes('draft-rental-applications') ? t('Draft rental applications') : t('Rental applications'), link: from() },
    { label: getTenantFullName(applicationStore.application) || emptyPlaceholder },
  ]);

  return (
    <div class="flex min-h-contentWithoutHeader flex-col">
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="mx-8 mt-6">
        <TabLinks
          class="mb-2 rounded-lg border border-partingline bg-white"
          items={[
            { label: t('Renter report'), href: '' },
            { label: t('Communication'), href: 'communications' },
          ]}
          replace
        />
        <Routes>
          <Route path="/" component={RenterReport} />
          <Route path="/communications" component={Communication} />
        </Routes>
      </div>
    </div>
  );
};

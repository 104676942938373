import { ChatWindow } from '~/components/common/ChatWindow';

interface LeaseCommunicationsTabProps {
  leaseId?: string | null;
  leaseChatId?: string | null;
}

const LeaseCommunicationsTab = (props: LeaseCommunicationsTabProps) => {
  return (
    <ChatWindow
      participantId={props.leaseId}
      chatId={props.leaseChatId}
      participantType="lease"
      class="h-section2 rounded-lg border-none"
    />
  );
};

export default LeaseCommunicationsTab;

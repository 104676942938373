import { A, useLocation } from '@solidjs/router';
import { For } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { cn } from '~/utils/classnames';
import type { Component, ValidComponent } from 'solid-js';

type TabItem = {
  name: string;
  path: string;
};

type TabProps = {
  items: TabItem[];
  class?: string;
  icon?: ValidComponent;
  title?: string;
};

export const Tabs: Component<TabProps> = (props) => {
  const location = useLocation();

  const isActiveTab = (tabPath: string) => location.pathname === `/settings/${tabPath}`;

  return (
    <div class={cn('flex w-60 flex-col gap-1 bg-inherit text-base', props.class)}>
      <div class="flex items-center justify-start gap-2 px-4 py-3 pl-2">
        {props.icon && <Dynamic component={props.icon} />}
        {props.title && <span class="text-xs text-text-level03">{props.title}</span>}
      </div>
      <For each={props.items}>
        {(tab) => (
          <A
            class={cn(
              'tab-item border-box relative flex min-w-24 shrink-0 grow-0 items-center justify-start rounded-md px-3 py-1.5 font-normal',
              {
                'text-text-level02 hover:bg-essential-colour/70 hover:text-white': !isActiveTab(tab.path),
                'bg-essential-colour text-white font-medium': isActiveTab(tab.path),
              }
            )}
            href={tab.path}>
            <span class="truncate">{tab.name}</span>
          </A>
        )}
      </For>
    </div>
  );
};

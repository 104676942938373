import { useNavigate, useParams } from '@solidjs/router';
import { createMemo, For, onMount, Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import processing from '~/assets/images/common/processing.gif';
import manualVerification from '~/assets/images/rental-application/manual-verfication.png';
import paymentUnion from '~/assets/images/rental-application/payment-union.png';
import submitted from '~/assets/images/rental-application/rental-application-submitted.png';
import RenterScreeningIcon from '~/assets/images/rental-application/renter-screening-id.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { toast } from '~/components/ui';
import { useLocalization, useRentalApplicationPayment } from '~/contexts/global';
import { twc, cn } from '~/utils/classnames';
import { rentalApplicationPaymentStatus } from '~/utils/constant';
import type { Component, ParentProps } from 'solid-js';

const Footer: Component<ParentProps> = (props) => {
  return (
    <div class="sticky bottom-0 right-0 flex justify-end p-7">
      <div class="flex gap-2">{props.children}</div>
    </div>
  );
};

export const PaymentLoading = (props: { class?: string }) => {
  return (
    <div class="mx-auto">
      <img src={processing} alt="processing" class={cn('w-28 pt-6', props.class)} />
    </div>
  );
};

const Wrapper = twc.div`thinscroll flex flex-1 flex-col items-center justify-center overflow-auto`;

const Processing = () => {
  const { t } = useLocalization();
  const params = useParams<{ applicationId: string; paymentStatus: keyof typeof status2View }>();
  const navigate = useNavigate();

  const { getPaymentStatus, store } = useRentalApplicationPayment();

  const refreshPaymentStatus = async () => {
    await getPaymentStatus(params.applicationId);
    if (!store.paymentStatus.paid) {
      setTimeout(() => {
        refreshPaymentStatus();
      }, 2000);
    } else {
      navigate(`../${rentalApplicationPaymentStatus.readyToConnect}`);
    }
  };

  onMount(() => {
    refreshPaymentStatus();
  });

  return (
    <Wrapper>
      <PaymentLoading />
      <div class="mb-3 text-lg font-semibold">{t('Payment processing...')}</div>
      <div class="text-sm text-text-level03">
        {t('Your transaction is being processed. This may take a couple of minutes. Please sit tight and don’t close your screen...')}
      </div>
    </Wrapper>
  );
};

const ReadyToConnect = () => {
  const { t } = useLocalization();
  const { handleScreening, store } = useRentalApplicationPayment();
  return (
    <>
      <Wrapper>
        <img src={paymentUnion} alt="processing" class="mb-16 w-[479px]" />
        <div class="mb-3 text-lg font-semibold">{t('Connecting to TransUnion to Prepare Questionnaire and Initiate ID Verification…')}</div>
        <div class="text-sm text-text-level03">
          {t("Please hold on tight and don't leave this page - we're securely fetching your questionnaire and setting up ID verification")}
        </div>
      </Wrapper>
      <Footer>
        <Button onClick={handleScreening} disabled={!store.paymentStatus.paid} loading={store.isScreening}>
          {t('Next')}
        </Button>
      </Footer>
    </>
  );
};

const ConfirmIdentity = () => {
  const { t } = useLocalization();
  const { store, getScreeningStatus } = useRentalApplicationPayment();

  onMount(() => {
    getScreeningStatus();
  });

  return (
    <Show when={!store.isCheckingScreeningStatus} fallback={<PaymentLoading />}>
      <Wrapper>
        <img src={manualVerification} alt="processing" class="w-80" />
        <div class="mb-3 text-lg font-semibold">{t('We are unable to verify your identity online.')}</div>
        <div class="text-center text-sm normal-case text-text-level03">
          {t('For completing your screening process, please contact our customer support at')}
          <span class="text-base text-text-level02"> (833) 458-6338</span>.<br />
          <span>
            {t(
              'While on the call, please remain on this screen for real-time updates. Once your call is complete, please stay on the page for next step.'
            )}
          </span>
        </div>
        <div class="mt-9 flex h-15 min-w-96 shrink-0 items-center rounded border border-partingline px-6 text-text-level02">
          <RenterScreeningIcon class="mr-2" />
          <span class="capitalize">{t('Renter screening id')}</span>
          <span class="grow text-end">{store.transUnionScreeningStatus.transunionScreeningRequestId}</span>
        </div>
      </Wrapper>
    </Show>
  );
};

const Submitted = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  return (
    <Wrapper>
      <img src={submitted} alt="processing" class="w-80" />
      <div class="mb-3 text-lg font-semibold">{t('Your rental application is successfully submitted.')}</div>
      <div class="mb-4 text-sm text-text-level03">{t('The application status will be notified by email. Please check your email.')}</div>
      <Button
        class="rounded-full"
        onClick={() => {
          toast.success(t('Rental application created successfully'));
          navigate('/leasing/rental-applications', { replace: true });
        }}>
        {t('Done')}
      </Button>
    </Wrapper>
  );
};

const Questionnaire = () => {
  const { t } = useLocalization();
  const { store, handleSubmitAnswers, updateAnswer, checkScreeningStatus } = useRentalApplicationPayment();

  onMount(() => {
    checkScreeningStatus();
  });

  const isLoading = createMemo(() => store.isLoadingQuestions || store.isCheckingScreeningStatus);

  return (
    <Show when={!isLoading()} fallback={<PaymentLoading />}>
      <Wrapper>
        <div class="flex h-section2 flex-col self-stretch overflow-y-auto bg-white p-6 pb-32">
          <For each={store?.transUnionScreeningQuestions?.questions}>
            {(question) => (
              <div class="mb-4 flex w-full flex-col">
                <div class="text-lg font-semibold">{question.questionDisplayName}</div>
                <div class="flex flex-col">
                  <For each={question.choices}>
                    {(choice) => (
                      <div class="flex p-2">
                        <input
                          class="cursor-pointer"
                          type="radio"
                          name={question.questionKeyName ?? ''}
                          value={choice.choiceKeyName ?? ''}
                          checked={store.selectedAnswers[question.questionKeyName ?? ''] === choice.choiceKeyName}
                          onChange={() => updateAnswer(question.questionKeyName ?? '', choice.choiceKeyName)}
                        />
                        <div class="ml-1 cursor-pointer" onClick={() => updateAnswer(question.questionKeyName ?? '', choice.choiceKeyName)}>
                          {choice.choiceDisplayName}
                        </div>
                      </div>
                    )}
                  </For>
                </div>
              </div>
            )}
          </For>
        </div>
      </Wrapper>
      <Footer>
        <Button onClick={handleSubmitAnswers} loading={store.isAnsweringQuestions}>
          {t('Next')}
        </Button>
      </Footer>
    </Show>
  );
};

const status2View = {
  [rentalApplicationPaymentStatus.processing]: Processing,
  [rentalApplicationPaymentStatus.readyToConnect]: ReadyToConnect,
  [rentalApplicationPaymentStatus.confirmIdentity]: ConfirmIdentity,
  [rentalApplicationPaymentStatus.submitted]: Submitted,
  [rentalApplicationPaymentStatus.questionnaire]: Questionnaire,
};

export const PaymentStatus = () => {
  const params = useParams<{ applicationId: string; paymentStatus: keyof typeof status2View }>();

  const Com = createMemo(() => status2View[params.paymentStatus] ?? null);
  const { setRentalApplicationId, store } = useRentalApplicationPayment();

  onMount(() => {
    setRentalApplicationId(params.applicationId);
  });

  return (
    <Show when={store.application.id}>
      <div class="flex flex-1 flex-col overflow-hidden">
        <Dynamic component={Com()} />
      </div>
    </Show>
  );
};

import { mergeProps } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import { BaseModal } from './BaseModal';
import type { BaseModalProps } from './BaseModal';
import type { Component } from 'solid-js';

export type MessageModalProps = BaseModalProps & {
  cancelText?: string;
  doneText?: string;
  loading?: boolean;
  onDone?: () => void;
};

export const MessageModal: Component<MessageModalProps> = (originProps) => {
  const { t } = useLocalization();
  const props = mergeProps(
    {
      cancelText: t('Cancel'),
      doneText: t('Confirm'),
    },
    originProps
  );

  return (
    <BaseModal
      portal={props.portal}
      size="sm"
      visible={props.visible}
      title={<span class="text-base">{props.title}</span>}
      onClose={props.onClose}>
      <div class="thinscroll flex-1 overflow-auto">{props.children}</div>
      <div class="flex justify-end gap-1.5 p-6">
        <Button variant="outlined" onClick={() => props.onClose && props.onClose()}>
          {props.cancelText}
        </Button>
        <Button loading={props.loading} onClick={() => props.onDone && props.onDone()}>
          {props.doneText}
        </Button>
      </div>
    </BaseModal>
  );
};

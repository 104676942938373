import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
import type { SectionProps } from '~/components/common/Panels/MultiSectionPanel';

type UnitInformationProps = SectionProps<MagicDoor.Api.HydratedUnitDto>;

export const UnitInformation: Component<UnitInformationProps> = (props) => {
  const { t } = useLocalization();
  return (
    <div class="grid grid-cols-2 gap-8">
      <LabeledTextInput
        label={t('Bedroom')}
        value={props.model?.beds}
        onInput={(e) => props.onChange && props.onChange('beds', Number(e))}
      />
      <LabeledTextInput
        label={t('Bathroom')}
        value={props.model?.baths}
        onInput={(e) => props.onChange && props.onChange('baths', Number(e))}
      />
      <LabeledTextInput
        label={t('Unit size(Sqft)')}
        value={props.model?.unitSizeSqft}
        onInput={(e) => props.onChange && props.onChange('unitSizeSqft', Number(e))}
      />
      {/* <LabeledTextInput label={t('Unit Type') + ' *'} value={props.unit} required onInput={(e) => setName(e)} /> */}
      <LabeledTextInput
        class="col-span-2"
        label={t('Title')}
        value={props.model?.unitSizeSqft}
        onInput={(e) => props.onChange && props.onChange('title', Number(e))}
      />
      <LabeledTextArea
        class="col-span-2"
        label={t('Description')}
        rows={5}
        value={'Description'}
        onInput={(e) => props.onChange && props.onChange('description', e)}
      />
    </div>
  );
};

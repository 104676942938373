import { BaseRestRepository } from '~/repositories/baseRestRepository';

const DEPOSIT_PAYMENT_SETTINGS_URL = '/api/settings/deposit-payment';

export class DepositPaymentSettingsRepository extends BaseRestRepository {
  public async getAllDepositPaymentSettings(): Promise<MagicDoor.Api.DepositPaymentSettingsDtoSettingsWithOverridesDto> {
    const url = `${DEPOSIT_PAYMENT_SETTINGS_URL}/all`;
    const res = await this.fetchWithAuth(url);
    return this.getJsonResponse(res);
  }

  public async updateDepositSettings(payload: MagicDoor.Api.DepositPaymentSettingsDto): Promise<MagicDoor.Api.DepositPaymentSettingsDto> {
    const url = `${DEPOSIT_PAYMENT_SETTINGS_URL}`;
    const res = await this.fetchWithAuth(url, { method: 'POST', body: JSON.stringify(payload) });
    return this.getJsonResponse(res);
  }

  public async updateOverrideDepositSettings(
    level: string,
    levelId: string,
    payload: MagicDoor.Api.DepositPaymentSettingsDto
  ): Promise<MagicDoor.Api.DepositPaymentSettingsDto> {
    const url = `${DEPOSIT_PAYMENT_SETTINGS_URL}/${level}/${levelId}`;
    const res = await this.fetchWithAuth(url, { method: 'POST', body: JSON.stringify(payload) });
    return this.getJsonResponse(res);
  }

  public async addOverrideSettings(
    level: string,
    levelId: string,
    payload: MagicDoor.Api.DepositPaymentSettingsDto
  ): Promise<MagicDoor.Api.DepositPaymentSettingsDto> {
    const url = `${DEPOSIT_PAYMENT_SETTINGS_URL}/${level}/${levelId}`;
    const res = await this.fetchWithAuth(url, { method: 'POST', body: JSON.stringify(payload) });
    return this.getJsonResponse(res);
  }

  public async removeOverrideSettings(level: string, levelId: string) {
    const url = `${DEPOSIT_PAYMENT_SETTINGS_URL}/${level}/${levelId}`;
    const res = await this.fetchWithAuth(url, { method: 'DELETE' });
    return this.getJsonResponse(res);
  }
}

import IconAccountLoginPW from '~/assets/images/settings/accountLoginPW.svg';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';

export const AccountActivities = () => {
  const { t } = useLocalization();

  return (
    <ThreeSectionBox
      coloredClass="border-input-border px-6"
      topTitle={<SettingTitle icon={IconAccountLoginPW} title={t('Activity logs')} />}
      middle={<div class="p-6 text-4xl">TODO: Implement AccountActivities</div>}
    />
  );
};

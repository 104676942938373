import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
import type { SectionProps } from '~/components/common/Panels/MultiSectionPanel';

type ContactInformationProps = SectionProps<MagicDoor.Api.HydratedUnitDto>;

export const ContactInformation: Component<ContactInformationProps> = (props) => {
  const { t } = useLocalization();
  return (
    <div class="grid grid-cols-2 gap-8">
      <LabeledTextInput label={t('Managing department')} value={undefined} onInput={(e) => props.onChange && props.onChange('', e)} />
      <LabeledTextInput label={t('Agent name')} value={undefined} onInput={(e) => props.onChange && props.onChange('', e)} />
      <LabeledTextInput label={t('Contact phone')} value={undefined} onInput={(e) => props.onChange && props.onChange('', e)} />
      <LabeledTextInput label={t('Contact email')} value={undefined} onInput={(e) => props.onChange && props.onChange('', e)} />
    </div>
  );
};

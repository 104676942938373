import { Route, Routes } from '@solidjs/router';
import { EditPropertyPage } from './edit-property';
import { PropertyDetailsPage } from './property-details';

export const PropertiesRoutes = () => (
  <Routes>
    <Route path="/:propertyId/edit" component={EditPropertyPage} />
    <Route path="/:propertyId/*" component={PropertyDetailsPage} />
  </Routes>
);

import { useParams } from '@solidjs/router';
import { createEffect } from 'solid-js';
import { useVendors } from '~/contexts/global';
import VendorDetailsView from './view-components/quick-view/VendorDetailsView';

export const VendorDetailsPage = () => {
  const params = useParams<{ vendorId: `${number}` }>();

  const { setCurrentId } = useVendors();
  createEffect(() => setCurrentId(params.vendorId));

  return <VendorDetailsView />;
};

import { createSignal } from 'solid-js';
import { checkMimeType } from '~/components/common/Upload/attrAccept';
import type { Component } from 'solid-js';
import type { DragAndDropProps } from '~/components/common/Upload/Interface';

export const DragAndDrop: Component<DragAndDropProps> = (props) => {
  const [active, setActive] = createSignal<boolean>(false);

  const dropFiles = (files?: FileList | null) => {
    if (files == null || files.length === 0) return;
    props.onDropFiles?.(filterFileList(files));
  };

  function filterFileList(fileList: FileList) {
    const dataTransfer = new DataTransfer();

    for (let i = 0; i < fileList.length; i++) {
      if (!props.accept || checkMimeType(fileList[i].type, props.accept)) {
        dataTransfer.items.add(fileList[i]);
      }
    }

    return dataTransfer.files;
  }

  const handleFileDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dropFiles(e.dataTransfer?.files);
    setActive(false);
  };

  let deep = 0;
  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter') {
      deep++;
      setActive(true);
    } else if (e.type === 'dragleave') {
      --deep === 0 && setActive(false);
    }
  };

  const handleSelectFiles = (e: Event & { currentTarget: HTMLInputElement }) => {
    e.preventDefault();
    dropFiles(e.currentTarget.files);
  };

  return (
    <label
      class={props.class}
      data-active={active() ? '' : undefined}
      onDrop={handleFileDrop}
      onDragEnter={handleDragOver}
      onDragOver={handleDragOver}
      onDragLeave={handleDragOver}>
      <input hidden type="file" accept={props.accept} multiple={props.multiple} onChange={handleSelectFiles} />
      {props.children}
    </label>
  );
};

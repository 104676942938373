import { BaseRestRepository } from '~/repositories/baseRestRepository';

const RENTAL_APPLICATION_PAYMENT_URL = '/api/settings/rental-application/chart-of-accounts';

export class RentalApplicationChartOfAccountsSettingsRepository extends BaseRestRepository {
  public async getRentalApplicationChartOfAccountsSetting(): Promise<MagicDoor.Api.RentalApplicationChartOfAccountSettingsDto> {
    const url = RENTAL_APPLICATION_PAYMENT_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async deleteRentalApplicationChartOfAccountsSetting() {
    const url = RENTAL_APPLICATION_PAYMENT_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }

  public async updateRentalApplicationChartOfAccountsSetting(
    payload: MagicDoor.Api.RentalApplicationChartOfAccountSettingsDto
  ): Promise<MagicDoor.Api.RentalApplicationChartOfAccountSettingsDto> {
    const url = RENTAL_APPLICATION_PAYMENT_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }
}

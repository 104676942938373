import { Route, Routes } from '@solidjs/router';
import { BillsProvider } from '~/contexts/local';
import { AddBillView } from './add-bill';
import BillDetailsPage from './bill-details';
import { ListBillsPage } from './list-bills';
import { PayBillView } from './pay-bill/PayBillView';

const BillsRoutes = () => {
  return (
    <BillsProvider>
      <Routes>
        <Route path="/add" component={AddBillView} />
        <Route path="/pay" component={PayBillView} />
        <Route path="/:billId/:type?/*" component={BillDetailsPage} />
        <Route path="*" component={ListBillsPage} />
      </Routes>
    </BillsProvider>
  );
};

export default BillsRoutes;

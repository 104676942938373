import IconPaymentMethod from '~/assets/images/settings/portal/portalPaymentMethod.svg';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { SettingToggleWithText } from '~/pages/settings/SettingToggleWithText';

export const PortalPaymentMethodSettings = () => {
  const { t } = useLocalization();

  return (
    <ThreeSectionBox
      class="h-fit"
      topTitle={<SettingTitle title={t('Payment method')} icon={IconPaymentMethod} />}
      middle={
        <div class="flex flex-col gap-5 p-4">
          <div class="flex flex-col gap-4 p-2 text-left">
            <SettingToggleWithText text={t('Enable online payments')} />
            <SettingToggleWithText text={t('View lease ledger')} />
          </div>
        </div>
      }
    />
  );
};

import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { AIRunbookTable } from './components/AiRunbookTable';

export const ListCustomAiRunbooks = () => {
  const { t } = useLocalization();
  const { customRunbooks } = useRunBooks();

  return (
    <div class="flex flex-col gap-4">
      <AIRunbookTable title={t('Custom runbooks')} runbooks={customRunbooks()} />
    </div>
  );
};

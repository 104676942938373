import IconAiBot from '~/assets/images/chat/aiChatBot.svg';
import { emptyPlaceholder } from '~/utils/constant';
import type { Component } from 'solid-js';

export const AIScoreCell: Component<{ score?: number | null }> = (props) => (
  <div class="min-w-20">
    <div
      class="flex  h-7 w-fit items-center justify-start gap-1.5 rounded-3xl px-2.5"
      style={{
        background: 'linear-gradient(94deg, #587DFF 25.19%, #7F11ED 100%), #D9D9D9',
      }}>
      <img src={IconAiBot} class="size-5" />
      <span class="text-nowrap text-lg font-bold text-white">{props.score ?? emptyPlaceholder}</span>
    </div>
  </div>
);

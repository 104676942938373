import { createSignal, createEffect, onCleanup, onMount } from 'solid-js';
import AudioChatReceived from '~/assets/sounds/chatReceived.mp3';
import { useSignalR } from '~/contexts/infrastructures';
import { createMagicDoorContext } from '~/contexts/utils';

interface GlobalChatContextValue {
  registerMessageReceivedCallback: (callback: (message: any) => void) => void;
}

export const [GlobalChatProvider, useGlobalChat] = createMagicDoorContext<GlobalChatContextValue>('GlobalChat', () => {
  const signalRService = useSignalR();

  const [messageCallbacks, setMessageCallbacks] = createSignal<((message: any) => void)[]>([]);
  const [userHasInteracted, setUserHasInteracted] = createSignal<boolean>(false);

  const registerMessageReceivedCallback = (callback: (message: any) => void) => {
    setMessageCallbacks((prev) => [...prev, callback]);
  };

  function playNotificationSound() {
    const chatReceivedAudio = new Audio(AudioChatReceived);
    chatReceivedAudio.play().catch((error) => console.error('Error playing sound:', error));
  }

  onMount(() => {
    const handleFirstUserInteraction = () => {
      setUserHasInteracted(true);
      window.removeEventListener('click', handleFirstUserInteraction);
      window.removeEventListener('keydown', handleFirstUserInteraction);
    };

    window.addEventListener('click', handleFirstUserInteraction);
    window.addEventListener('keydown', handleFirstUserInteraction);
  });

  createEffect(() => {
    if (signalRService) {
      signalRService
        .startConnection()
        .then(() => {
          console.log('Connected to SignalR');
          signalRService.onReceiveMessage((rawMessage) => {
            const message = typeof rawMessage === 'string' ? JSON.parse(rawMessage) : rawMessage;

            const allowedMessageTypes = ['participant', 'unknown'];
            const isAllowedMessageType = allowedMessageTypes.includes(message.messageType);
            const shouldPlaySound = userHasInteracted() && isAllowedMessageType;

            if (shouldPlaySound) {
              playNotificationSound();
            }
            messageCallbacks().forEach((cb) => cb(message));
          });
          signalRService.onChatCreate((chatId) => {
            try {
              messageCallbacks().forEach((cb) => cb(chatId));
            } catch (error) {
              console.error('Failed to fetch new chat details:', error);
            }
          });
        })
        .catch((error) => console.error('SignalR connection error:', error));

      onCleanup(() => {
        signalRService.stopConnection().catch(console.error);
      });
    }
  });

  return {
    registerMessageReceivedCallback,
  };
});

import { createMemo, createSignal, onMount, Show } from 'solid-js';
import NoTenantImg from '~/assets/images/empty/noTenant.svg';
import CnMoney from '~/components/common/CnMoney';
import { Empty } from '~/components/common/Empty';
import { LeaseStatusElement, LeaseTitle } from '~/components/leases';
import { TenantTitle } from '~/components/tenants';
import { IconBan, IconSquarePen, ListView } from '~/components/ui';
import { useLocalization, useTenants } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import { DeactivateTenant } from '~/pages/tenants/components/DeactivateTenant';
import type { Component } from 'solid-js';
export const TenantsListView: Component = () => {
  const { t } = useLocalization();
  const { filtered, setFilter } = useTenants();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('TenantsListView');

  const [deactivatingTenant, setDeactivatingTenant] = createSignal<MagicDoor.Api.HydratedTenantDto>();
  // const { filterItems, filterValue, handleFilterReset } = useLeaseFilter({ status: false, date: false });

  onMount(() => setFilter({ page: page(), pageSize: pageSize() }));

  const listData = createMemo(() =>
    filtered()?.items.map((item: MagicDoor.Api.HydratedTenantListDto) => ({
      ...item,
      property: filtered()?.properties?.find((property) => property.id === item?.currentLeases![0]?.propertyId),
      unit: filtered()?.units?.find((unit) => unit.id === item?.currentLeases![0]?.unitId),
    }))
  );

  return (
    <>
      <ListView
        onChange={({ page, pageSize, search }) => {
          setPage(page);
          setPageSize(pageSize);
          setFilter({ page, pageSize, search });
        }}
        title={t('Tenants Overview')}
        totalPages={filtered()?.totalPages}
        page={filtered()?.currentPage}
        pageSize={filtered()?.pageSize}
        data={listData()}
        loading={filtered.loading}
        skeletonSize={10}
        // filterValue={filterValue()}
        // filterItems={filterItems()}
        // onFilterReset={handleFilterReset}
        searchPlaceholder={t('Search tenants')}
        rowLink={(item) => `/users/tenants/${item.id}`}
        empty={<Empty imgSrc={NoTenantImg} description={t('No tenants yet')} />}
        columns={[
          {
            title: t('Tenant'),
            headerClass: 'w-52 xl:w-64',
            render: (item) => <TenantTitle tenant={item} />,
          },
          {
            title: t('Lease'),
            render: (item) => (
              <>
                <Show
                  when={item.currentLeases?.length}
                  fallback={<span class="text-xs capitalize text-text-level03">{t('No lease yet')}</span>}>
                  <LeaseTitle lease={item.currentLeases![0]} property={item.property} unit={item.unit} />
                </Show>
              </>
            ),
          },
          {
            title: t('Lease status'),
            headerClass: 'w-56',
            render: (item) => (
              <Show when={item.currentLeases?.length} fallback="-">
                <LeaseStatusElement lease={item?.currentLeases![0]} />
              </Show>
            ),
          },
          {
            title: t('Balance'),
            headerClass: 'text-right w-36',
            class: 'text-right',
            render: (item) => <CnMoney money={item.currentLeases![0]?.balance} />,
          },
        ]}
        actions={(item) => [
          {
            label: t('Edit tenant'),
            icon: IconSquarePen,
            href: `/users/tenants/${item.id}/edit`,
          },
          {
            label: t('Deactivate'),
            icon: IconBan,
            onClick: () => setDeactivatingTenant(item),
          },
        ]}
      />
      <DeactivateTenant tenant={deactivatingTenant()} onClose={setDeactivatingTenant} />
    </>
  );
};

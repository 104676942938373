import type { JSX, Component } from 'solid-js';

export type SectionWrapperProps = {
  id: string;
  children: JSX.Element;
  title: string;
};

export const SectionWrapper: Component<SectionWrapperProps> = (props) => {
  return (
    <div id={props.id} class="w-full">
      <div class="sticky top-0 z-10 border-b border-partingline bg-white">
        <h4 class="px-11 py-6 text-lg font-semibold">{props.title}</h4>
      </div>
      <div class="px-11 pb-10 pt-7">{props.children}</div>
    </div>
  );
};

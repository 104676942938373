import { splitProps } from 'solid-js';
import { LabeledGroup } from '~/components/common/Inputs';
import { SearchSelect } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { TenantRepository } from '~/repositories/tenantRepository';
import { TenantTitle } from './TenantTitle';
import { getTenantFullName } from './utils';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { EntrySearchProps } from '~/components/ui';

const repo = new TenantRepository();

export const TenantSearch = <T extends boolean | undefined = undefined>(props: EntrySearchProps<MagicDoor.Api.HydratedTenantDto, T>) => {
  const { t } = useLocalization();
  return (
    <SearchSelect
      placeholder={t('Search tenants')}
      fetcher={(query: string | undefined, page: number) =>
        repo.getTenants({ active: true, page, pageSize: 20, search: query || undefined })
      }
      renderSelected={getTenantFullName}
      renderItem={(item) => <TenantTitle feedbackIconReadonly tenant={item} />}
      {...props}
    />
  );
};

export const LabeledTenantSearch = <T extends boolean | undefined = undefined>(
  props: Omit<EntrySearchProps<MagicDoor.Api.HydratedTenantDto, T>, 'onSelect'> &
    LabeledGroupProps & {
      value?: T extends true ? string[] : string;
      onInput?: (
        value: T extends true ? string[] : string,
        selected: T extends true ? MagicDoor.Api.HydratedTenantDto[] : MagicDoor.Api.HydratedTenantDto
      ) => void;
    }
) => {
  const [searchProps, labeledProps] = splitProps(props, ['multiple', 'selected', 'placeholder', 'onInput', 'exclude']);

  // TODO: get entries by ids???

  return (
    <LabeledGroup {...labeledProps}>
      <TenantSearch
        {...searchProps}
        onSelect={(selected) => {
          props.onInput?.(
            (Array.isArray(selected) ? selected.map((t) => t.id) : selected.id) as T extends true ? string[] : string,
            selected
          );
        }}
      />
    </LabeledGroup>
  );
};

import { splitProps } from 'solid-js';
import { LabeledGroup } from '~/components/common/Inputs';
import { SearchSelect } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { BankAccountRepository } from '~/repositories/bankAccountRepository';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { EntrySearchProps } from '~/components/ui';

const repo = new BankAccountRepository();

export const BankAccountSearch = <T extends boolean | undefined = undefined>(
  props: EntrySearchProps<MagicDoor.Api.HydratedBankAccountDto, T>
) => {
  const { t } = useLocalization();
  async function getBankAccounts(query: string | undefined, page: number) {
    const response = await repo.getBankAccounts();
    return { items: response, totalPages: 1 };
  }
  return (
    <SearchSelect
      placeholder={t('Search bank accounts')}
      fetcher={(query: string | undefined, page: number) => getBankAccounts(query, page)}
      renderSelected={(item) => item.name}
      renderItem={(item) => item.name}
      {...props}
    />
  );
};

export const LabeledBankAccountSearch = <T extends boolean | undefined = undefined>(
  props: Omit<EntrySearchProps<MagicDoor.Api.HydratedBankAccountDto, T>, 'onSelect'> &
    LabeledGroupProps & {
      value?: T extends true ? string[] : string;
      onInput?: (
        value: T extends true ? string[] : string,
        selected: T extends true ? MagicDoor.Api.HydratedBankAccountDto[] : MagicDoor.Api.HydratedBankAccountDto
      ) => void;
    }
) => {
  const [searchProps, labeledProps] = splitProps(props, ['multiple', 'selected', 'placeholder', 'onInput', 'exclude']);

  return (
    <LabeledGroup {...labeledProps}>
      <BankAccountSearch
        {...searchProps}
        onSelect={(selected) => {
          props.onInput?.(
            (Array.isArray(selected) ? selected.map((t) => t.id) : selected.id) as T extends true ? string[] : string,
            selected
          );
        }}
      />
    </LabeledGroup>
  );
};

import { Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import UnderConstruction from '~/components/common/UnderConstruction';
import { IconCirclePlus } from '~/components/ui';
import { useLocalization, useTenants } from '~/contexts/global';
import { IconActiveCount, IconTenantsSummaryCard } from '~/pages/tenants/assets';
import { cn } from '~/utils/classnames';
import type { Component, ComponentProps } from 'solid-js';

const InfoCard: Component<ComponentProps<'div'>> = (props) => (
  <div
    {...props}
    class={cn(
      'relative flex h-52 flex-col items-start gap-0.5 overflow-hidden rounded-lg bg-gradient-to-bl from-white/30 to-transparent px-6 py-5 text-xs',
      props.class
    )}>
    <IconTenantsSummaryCard class="absolute right-0 top-0" />
    {props.children}
  </div>
);

export const TenantsSummary: Component = () => {
  const { t } = useLocalization();
  const { counts } = useTenants();

  return (
    <div class="grid gap-5 text-left md:grid-cols-2 lg:grid-cols-3">
      <InfoCard class="bg-essential-colour text-white">
        <span class="text-sm capitalize opacity-80">{t('Active tenants')}</span>
        <div class="mb-auto text-4xl font-semibold">{counts()?.activeTenants || '-'}</div>
        <Show when={counts()?.newTenantsToday}>
          <span class="flex items-center gap-1 rounded-full bg-white/40 px-2.5 py-1 text-essential-colour">
            <IconActiveCount class="size-3" />
            {counts()?.newTenantsToday || '-'}
          </span>
          <span class="opacity-80">{t('New today')}</span>
        </Show>
        <Button class="mt-1 border-0 [&_span]:gap-1" variant="white" rounded="full" size="sm" href="/users/tenants/add">
          <IconCirclePlus class="size-5" />
          {t('Add tenant')}
        </Button>
      </InfoCard>
      <InfoCard class="bg-[#0047ff] text-white">
        <span class="text-sm capitalize opacity-80">{t('Guest cards')}</span>
        <div class="mb-auto text-4xl font-semibold">356</div>
        <Show when={true}>
          <span class="flex items-center gap-1 rounded-full bg-white/40 px-2.5 py-1 text-[#0047ff]">
            <IconActiveCount class="size-3" />
            12
          </span>
        </Show>
        <span class="opacity-80">{t('New today')}</span>
        <Button
          class="mt-1 border-0 text-[#0047ff] [&_span]:gap-1"
          variant="white"
          rounded="full"
          size="sm"
          href="/users/tenants?filter=guest">
          {t('View')}
        </Button>
        <UnderConstruction />
      </InfoCard>
    </div>
  );
};

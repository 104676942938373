import { MaintenanceRequestSettingsProvider } from '~/contexts/local';
import { MaintenanceRequestSetting } from './MaintenanceRequestSetting';
import { RunBooksWrap } from './run-books';

export const PropertyMaintenanceRequestSettings = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <MaintenanceRequestSettingsProvider>
        <MaintenanceRequestSetting />
      </MaintenanceRequestSettingsProvider>
      <RunBooksWrap />
    </div>
  );
};

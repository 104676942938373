import { Show, createSignal, mergeProps, onMount } from 'solid-js';
import { IconSearch } from '~/components/ui/Icons';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

export interface SearchListInputProps {
  value?: string;
  placeholder?: string;
  onChange: (value?: string) => void;
  class?: string;
  isAlwaysExpanded?: boolean;
}

export const SearchListInput: Component<SearchListInputProps> = (rawProps) => {
  const props = mergeProps({ isAlwaysExpanded: true }, rawProps);
  const { t } = useLocalization();
  const [isExpanded, setIsExpanded] = createSignal<boolean>(true);
  let inputRef: HTMLInputElement | undefined;
  let formRef: HTMLFormElement | undefined;

  onMount(() => {
    setIsExpanded(props.isAlwaysExpanded);
  });

  const handleGetSearchText = () => {
    const formData = new FormData(formRef);
    return (formData.get('search') as string) || undefined;
  };

  const handleSubmit = (e: Event) => {
    if (!isExpanded()) {
      inputRef?.focus();
      e.preventDefault();
      return;
    }

    const searchText = handleGetSearchText();

    props.onChange(searchText);
    e.preventDefault();
  };

  const onInputBlur = (e: FocusEvent) => {
    const isTriggerInForm = !e.relatedTarget ? false : formRef?.contains(e.relatedTarget as Node);
    const searchText = handleGetSearchText();

    if (isTriggerInForm || props.isAlwaysExpanded || searchText) {
      return;
    }

    setIsExpanded(false);
  };

  return (
    <form
      ref={formRef}
      class="flex items-center rounded-lg border border-input-border bg-light-gray text-title-gray"
      onSubmit={handleSubmit}
      data-search>
      <input
        name="search"
        ref={inputRef}
        onBlur={onInputBlur}
        onFocus={() => setIsExpanded(true)}
        value={props.value ?? ''}
        class={cn(
          'rounded-lg bg-transparent py-2 text-sm outline-none transition-all placeholder:text-text-level03',
          isExpanded() ? 'w-44 px-4' : 'w-0'
        )}
        placeholder={props.placeholder ?? t('Search')}
        autocomplete="off"
      />
      <Show when={isExpanded()}>
        <hr class="h-5 w-px bg-[#e1def1]" />
      </Show>
      <button class="flex size-10 items-center justify-center outline-none" aria-label="Search">
        <IconSearch class="size-5" />
      </button>
    </form>
  );
};

import { useNavigate } from '@solidjs/router';
import IconWorkOrder from '~/assets/images/vendors/workOrders.svg?component-solid';
import { IconCalendar } from '~/components/ui/Icons';
import { dateFormat } from '~/utils/date';
import type dayjs from 'dayjs';

interface WorkOrderProps {
  status?: string;
  title?: string;
  address?: string;
  date: dayjs.Dayjs;

  workOrder: MagicDoor.Api.WorkOrderListDto;
}

function WorkOrderItems(props: WorkOrderProps) {
  const navigate = useNavigate();
  const map: Record<MagicDoor.Api.Urgency, any> = {
    urgent: { color: 'bg-[#36CBAC]', style: { background: 'linear-gradient(90deg, #36CBAC 0%, rgba(54, 203, 172, 0.06) 100%)' } },

    high: { color: 'bg-[#971CE2]', style: { background: 'linear-gradient(90deg, #971CE2 0%, rgba(151, 28, 226, 0.06) 100%)' } },
    medium: { color: 'bg-[#36CBAC]', style: { background: 'linear-gradient(90deg, #36CBAC 0%, rgba(54, 203, 172, 0.19) 100%)' } },

    low: { color: 'bg-[$FA8511]', style: { background: 'linear-gradient(90deg, #FA8511 0%, rgba(250, 133, 17, 0.06) 100%)' } },
    none: { color: 'bg-gray-500', style: {} },
  };

  const getStyle = (status: string | undefined) => {
    const style = map[status as MagicDoor.Api.Urgency]?.style || {};
    return style;
  };

  const getIconColor = (status: string | undefined) => {
    return map[status as MagicDoor.Api.Urgency]?.color || 'bg-gray-500';
  };

  const getFormattedDate = (date: dayjs.Dayjs) => {
    return dateFormat('', date);
  };

  const toDetail = () => {
    navigate(`/maintenance/work-orders/${props.workOrder.id}`);
  };

  return (
    <div
      class={`flex cursor-pointer items-center justify-between rounded-xl p-3`}
      style={getStyle(props.workOrder?.urgency)}
      onClick={toDetail}>
      <div class="flex items-center gap-3">
        <div class={`rounded-lg p-2 ${getIconColor(props.workOrder?.urgency)}`}>
          {' '}
          <IconWorkOrder class="text-white" />
        </div>
        <div class="text-left">
          <div class="text-sm font-medium">{props.title}</div>
          <div class="text-xs text-text-level03">{props.address}</div>
        </div>
      </div>
      <div class=" text-center">
        <div class="flex items-center gap-1 text-xs">
          <IconCalendar width={12} height={12} />
          <div>{getFormattedDate(props.date)}</div>
        </div>
        <div class="text-xs">{props.workOrder?.status}</div>
      </div>
    </div>
  );
}

export default WorkOrderItems;

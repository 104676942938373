import { Avatar } from '~/components/common/Avatar';
import { Button } from '~/components/common/Buttons';
import { IconLogOut, Popover, IconSettings } from '~/components/ui';
import { useAuth, useLocalization } from '~/contexts/global';

const ProfileButton = () => {
  const { t } = useLocalization();
  const { me, signOut } = useAuth();

  return (
    <Popover>
      <Popover.Trigger class="flex">
        <Avatar loading={me.loading} imgUrl={me()?.avatar} name={me()?.name ?? '-'} class="size-11" />
      </Popover.Trigger>
      <Popover.Content as="ul" class="my-1 min-w-52 rounded-md border border-partingline bg-white text-sm text-text-level02 shadow-lg">
        <li class="line-clamp-2 flex flex-col items-center border-b p-5">
          <Avatar loading={me.loading} imgUrl={me()?.avatar} name={me()?.name ?? '-'} class="my-4 size-16" />
          <h3 class="text-sm font-semibold">{me()?.name}</h3>
          <span class="text-xs">{me()?.email}</span>
        </li>
        <Popover.Trigger as="li" class="p-2">
          <Button
            type="button"
            class="flex w-full items-center justify-start gap-2 rounded-md bg-white px-3 py-2 text-sm text-inherit transition-colors hover:bg-light-pink hover:text-essential-colour"
            href="/settings/account/information"
            variant="text">
            <IconSettings class="size-4" /> {t('Settings')}
          </Button>
          <button
            type="button"
            class="flex w-full items-center justify-start gap-2 rounded-md px-3 py-2 transition-colors hover:bg-light-pink hover:text-essential-colour"
            onClick={signOut}>
            <IconLogOut class="size-4" /> {t('Sign out')}
          </button>
        </Popover.Trigger>
      </Popover.Content>
    </Popover>
  );
};

export default ProfileButton;

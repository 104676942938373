export const signerColors = [
  'rgb(204, 51, 51)',
  'rgb(204, 153, 51)',
  'rgb(204, 204, 51)',
  'rgb(51, 204, 51)',
  'rgb(51, 204, 153)',
  'rgb(51, 204, 204)',
  'rgb(51, 153, 204)',
  'rgb(51, 51, 204)',
  'rgb(153, 51, 204)',
  'rgb(204, 51, 204)',
];

export const signerColorsTranslucent = [
  'rgba(204, 51, 51, 0.2)',
  'rgba(204, 153, 51, 0.2)',
  'rgba(204, 204, 51, 0.2)',
  'rgba(51, 204, 51, 0.2)',
  'rgba(51, 204, 153, 0.2)',
  'rgba(51, 204, 204, 0.2)',
  'rgba(51, 153, 204, 0.2)',
  'rgba(51, 51, 204, 0.2)',
  'rgba(153, 51, 204, 0.2)',
  'rgba(204, 51, 204, 0.2)',
];

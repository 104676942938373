import { useNavigate } from '@solidjs/router';
import { createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import { Stepper } from '~/components/common/Stepper';
import { useLocalization } from '~/contexts/global';
import { useAnnouncements } from '~/contexts/local';
import { Content } from './components/Content';
import { Review } from './components/Review';
import { SelectTarget } from './components/SelectTarget';
import { initialModel } from './types';
import { useAnnouncementLogic } from './useAnnouncementLogic';

export const AddAnnouncement = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { addAnnouncement, fetchAnnouncementTargets, error } = useAnnouncements();

  const { currentStep, model, announcementReview, steps, updateModel, handleStepChange, handleSubmit, errors } = useAnnouncementLogic(
    initialModel,
    addAnnouncement,
    fetchAnnouncementTargets,
    navigate,
    error,
    t
  );
  console.log(errors(), 'errors');

  const breadcrumbItems = createMemo(() => [
    { label: t('Announcements'), link: '/communications/announcements' },
    { label: t('New announcement') },
  ]);

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <MultiStepsPanel
        title={t('New announcement')}
        stepper={<Stepper class="flex items-center justify-center p-9" steps={steps()} step={currentStep()} />}
        currentStep={currentStep()}
        class="flex"
        contentClass="[&>div]:mx-auto [&>div]:mb-10 [&>div]:max-w-3xl"
        submitText={t('Finish')}
        onStepChange={handleStepChange}
        onSubmit={handleSubmit}
        onClose={() => navigate(-1)}
        showPrevious>
        <SelectTarget model={model()} onUpdate={updateModel} errors={errors()} />
        <Content model={model()} onUpdate={updateModel} errors={errors()} />
        <Review reviewData={announcementReview()} model={model()} />
      </MultiStepsPanel>
    </>
  );
};

import { PropertyImage } from '~/components/properties';
import { useLocalization, usePortfolios } from '~/contexts/global';
import Card from '~/pages/dashboard/components/Card';
import type { Component } from 'solid-js';

type PropertyDistributionProps = {
  class?: string;
};

const ListingUnitTypes: Component<PropertyDistributionProps> = (props) => {
  const { t } = useLocalization();
  const { current } = usePortfolios();

  return (
    <Card
      class={props.class}
      title={t('Rent receivable')}
      contentClass="h-[174px]"
      header={
        <div class="flex items-center gap-3">
          <PropertyImage class="size-10 shrink-0 rounded-md" />
          <div class="flex-1">
            <div class="text-left text-base font-semibold leading-6">{t('Listing unit types')}</div>
            <div class="text-left text-sm font-normal leading-tight">{current()?.name}</div>
          </div>
        </div>
      }
      bgClass="h-auto">
      <div class="relative flex size-full items-center justify-center">
        <div class="flex w-full flex-col items-center justify-between px-8">
          <div class="m-5 flex w-full divide-x rounded-lg bg-light-gray py-6">
            <div class="flex-1 px-2">
              <h3 class="text-center text-xl font-semibold text-danger">0</h3>
              <p class="mt-0.5 flex justify-center gap-1 text-[11px]">1 {t('Bed')}</p>
            </div>
            <div class="flex-1 px-2">
              <h3 class="text-center text-xl font-semibold text-title-gray">0</h3>
              <p class="mt-0.5 flex justify-center gap-1 text-[11px]">2 {t('Bed')}</p>
            </div>
            <div class="flex-1 px-2">
              <h3 class="text-center text-xl font-semibold text-title-gray">0</h3>
              <p class="mt-0.5 flex justify-center gap-1 text-[11px]">3 {t('Bed')}</p>
            </div>
            <div class="flex-1 px-2">
              <h3 class="text-center text-xl font-semibold text-title-gray">0</h3>
              <p class="mt-0.5 flex justify-center gap-1 text-[11px]">&gt; 3 {t('Bed')}</p>
            </div>
            <div class="flex-1 px-2">
              <h3 class="text-center text-xl font-semibold text-title-gray">0</h3>
              <p class="mt-0.5 flex justify-center gap-1 text-[11px]">{t('Storage')}</p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ListingUnitTypes;

import { useNavigate, useParams } from '@solidjs/router';
import { createEffect } from 'solid-js';
import { useForm } from '~/components/common/BetterForm';
import { FormWrapper } from '~/components/common/BetterForm/Form';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Button } from '~/components/common/Buttons';
import {
  CategoryContentSection,
  CategoryComponentSection,
  getErrorAndProgressProps,
  CategoryComponentWrapper,
} from '~/components/common/Category';
import { FileRepository } from '~/components/common/Upload/request';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequests } from '~/contexts/local';
import { AssignedTo } from '~/pages/maintenance-request/add-maintenance-request/AssignedTo';
import {
  AddMaintenanceModalContext,
  useAddOrEditMaintenanceModalContext,
} from '~/pages/maintenance-request/add-maintenance-request/Context';
import { EssentialInfomation } from '~/pages/maintenance-request/edit-maintenance-request/EssentialInfomation';

const fileRepository = new FileRepository();

const EditMaintenanceRequest = () => {
  const { t } = useLocalization();
  const { updateMaintenanceRequest } = useMaintenanceRequests();
  const { getMaintenanceRequest, setStore, store } = useAddOrEditMaintenanceModalContext();
  const { maintenanceRequestId } = useParams();

  const navigate = useNavigate();
  const form = useForm();

  async function initFormData() {
    const info = await getMaintenanceRequest(maintenanceRequestId);
    form.setFieldsValue({ ...info, requesterId: info.tenant.id, categoryId: info.category?.id });
  }

  const onSubmit = async (form: MagicDoor.Api.MaintenanceRequestDto) => {
    try {
      setStore({ isSubmiting: true });

      const newFiles = store.newFiles || [];
      for (const file of newFiles) {
        await fileRepository.uploadFile(store.uploadFileUrl, file);
      }

      await updateMaintenanceRequest(maintenanceRequestId, form);
      navigate(`/maintenance/maintenance-request/${maintenanceRequestId}`, { replace: true });
      toast.success(t('{name} has been edited successfully', { name: 'MaintenanceRequest' }));
    } finally {
      setStore({ isSubmiting: false });
    }
  };

  createEffect(() => {
    initFormData();
  });

  return (
    <FormWrapper defaultForm={form} onFormSubmit={(form) => onSubmit(form as MagicDoor.Api.MaintenanceRequestDto)}>
      <CategoryComponentWrapper
        sections={[
          {
            key: 'essential-information',
            name: t('Essential information'),
            component: EssentialInfomation,
            fields: ['description', 'title', 'unitId', 'leaseId', 'urgency', 'due', 'task'],
          },
          {
            key: 'assigned-to',
            name: t('Assigned to'),
            component: AssignedTo,
            fields: ['assignedPropertyManagerId', 'internalDescription'],
          },
        ]}>
        <Breadcrumb
          backLink={() => navigate(-1)}
          items={[
            { label: t(`Vendor`), link: `../../../vendors` },
            { label: t(`Maintenance request`), link: '../../' },
            { label: t(`Edit maintenance request`) },
          ]}
        />
        <div class="m-8 flex h-section1 flex-col overflow-hidden rounded-lg bg-white text-sm">
          <div class="flex flex-1 overflow-hidden">
            <div class="hidden w-[330px] shrink-0 border-r border-r-partingline lg:block">
              <h2 class="p-6 text-lg font-medium text-text-level01 ">{t('Maintenance request')}</h2>
              <CategoryComponentSection {...getErrorAndProgressProps(form)} />
            </div>
            <div class="thinscroll flex grow flex-col items-stretch overflow-auto pb-8 text-left">
              <CategoryContentSection mode="padding" />
            </div>
          </div>
          <div class="flex h-24 items-center justify-end gap-2 border-t border-partingline px-5">
            <Button
              onClick={() => {
                navigate('../../');
              }}
              variant="outlined">
              {t('Cancel')}
            </Button>
            <Button loading={store.isSubmiting} type="submit">
              {t('Save changes')}
            </Button>
          </div>
        </div>
      </CategoryComponentWrapper>
    </FormWrapper>
  );
};

export const EditMaintenanceRequestView = () => {
  return (
    <AddMaintenanceModalContext>
      <EditMaintenanceRequest />
    </AddMaintenanceModalContext>
  );
};

import { createMemo, For, Show } from 'solid-js';
import { Panel } from '~/components/common/Panels/Panel';
import { Skeleton } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { RunbookTitle } from '~/pages/ai-runbooks/runbooks-details/components/RunbookTitle';
import type { Component } from 'solid-js';

enum RunBookActionExits {
  AskQuestion = 'askQuestion',
  CloseRequest = 'closeRequest',
  CreateWorkOrder = 'createWorkOrder',
  HandOverToHuman = 'handOverToHuman',
  ReselectRunBook = 'reselectRunBook',
}

export const RunbookOverviewTab: Component = () => {
  const { t } = useLocalization();

  const { runBook } = useRunBooks();

  const sumAIResults = createMemo(() => {
    let total = 0;
    const groupsByTypes = Object.keys(RunBookActionExits).reduce((acc, key) => {
      const count = runBook()?.actionExitCounts[RunBookActionExits[key as keyof typeof RunBookActionExits]] ?? '-';
      if (typeof count === 'number') {
        total += count;
      }
      acc.push({ type: key, count });
      return acc;
    }, [] as Array<{ type: string; count: string | number }>);
    return {
      total,
      groupsByTypes,
    };
  });

  return (
    <div class="flex flex-col gap-5">
      <RunbookTitle />
      <Panel
        title={t(`The results of AI`)}
        actions={
          <span class="text-text-level03">
            {t('Total')}: <span class="text-lg font-medium text-text-level01">{sumAIResults().total}</span>
          </span>
        }>
        <div class="grid grid-cols-5 gap-5 px-5 py-6">
          <For each={sumAIResults().groupsByTypes}>
            {(item) => (
              <div class="col-span-1 flex flex-col items-center justify-center gap-2">
                <div class="text-lg font-medium">{item.count}</div>
                <div class="text-sm text-text-level02">{item.type}</div>
              </div>
            )}
          </For>
        </div>
      </Panel>
      <Panel title={t('Description')}>
        <div class="flex flex-col px-5  py-6">
          <div class="rounded-lg bg-light-gray p-5">
            <Show when={runBook.loading} fallback={<div class="font-medium normal-case text-title-gray">{runBook()?.description}</div>}>
              <Skeleton />
              <Skeleton class="mt-1 w-2/3" />
            </Show>
          </div>
        </div>
      </Panel>
    </div>
  );
};

import { createEffect, createSignal, Match, on, Switch } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import { Description } from '~/components/common/Description';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Subsection } from '~/components/common/Subsection';
import { LabeledLeaseSearch } from '~/components/leases/LeaseSearch';
import { LabeledPropertySearch } from '~/components/properties/PropertySearch';
import { LabeledUnitSearch } from '~/components/units/UnitSearch';
import { LabeledVendorSearch } from '~/components/vendors/VendorSearch';
import { useLocalization, useWorkOrders } from '~/contexts/global';
import type { SubsectionListItem } from '~/components/common/Subsection';
import type { UploaderFile } from '~/components/common/Upload/Interface';

export const EssentialInfomation = () => {
  const { t } = useLocalization();

  const [current, setCurrent] = createSignal<string>('lease');
  const { resetFields } = useFormContext();
  const { setNewFiles } = useWorkOrders();

  const subsectionList: SubsectionListItem[] = [
    {
      label: 'Property',
      key: 'property',
    },
    {
      label: 'Unit',
      key: 'unit',
    },
    {
      label: 'Lease',
      key: 'lease',
    },
  ];

  createEffect(
    on(
      current,
      (v) => {
        if (v === 'property') {
          resetFields(['unitId', 'leaseId']);
        } else if (v === 'unit') {
          resetFields(['propertyId', 'leaseId']);
        } else {
          resetFields(['propertyId', 'unitId']);
        }
      },
      { defer: true }
    )
  );

  return (
    <div class="flex flex-col gap-8">
      <FormItem
        class="w-full"
        label={t(`Description`)}
        rules={[{ required: true, message: t(`Please input description`) }]}
        formFieldName="description"
        component={Description}
        placeholder={t('Please enter')}
        onFilesListChange={(files: UploaderFile[]) => {
          setNewFiles(files);
        }}
      />
      <FormItem label={t(`Title`)} formFieldName="title" component={LabeledTextInput} placeholder={t('Please enter')} />

      <div class="grid grid-cols-2 gap-8">
        <div class="flex flex-col">
          <div class="mb-2 flex text-sm uppercase text-label">{t('Option')}</div>
          <Subsection list={subsectionList} current={[current, setCurrent]} />
        </div>
        <Switch>
          <Match when={current() === 'property'}>
            <FormItem<any>
              label={t(`Property`)}
              formFieldName="propertyId"
              component={LabeledPropertySearch}
              rules={[{ required: true, message: t(`Select property`) }]}
              placeholder={t('Select property')}
            />
          </Match>
          <Match when={current() === 'unit'}>
            <FormItem<any>
              label={t(`Unit`)}
              formFieldName="unitId"
              component={LabeledUnitSearch}
              rules={[{ required: true, message: t(`Select unit`) }]}
              placeholder={t('Select unit')}
            />
          </Match>
          <Match when={current() === 'lease'}>
            <FormItem<any>
              label={t(`Select lease`)}
              formFieldName="leaseId"
              component={LabeledLeaseSearch}
              rules={[{ required: true, message: t(`Please select lease`) }]}
              placeholder={t('Please select lease')}
              filter={{
                ended: false,
              }}
            />
          </Match>
        </Switch>
      </div>

      <FormItem<any>
        class="w-full"
        label={t(`Vendor`)}
        formFieldName="vendorId"
        component={LabeledVendorSearch}
        placeholder={t('Please select vendor')}
      />

      <div class="grid grid-cols-2 gap-8">
        <FormItem
          class="col-span-full lg:col-span-1"
          label={t(`Priority`)}
          rules={[{ required: true, message: t(`Please select priority`) }]}
          formFieldName="urgency"
          options={[
            { label: t('Urgent'), value: 'urgent' },
            { label: t('High'), value: 'high' },
            { label: t('Medium'), value: 'medium' },
            { label: t('Low'), value: 'low' },
            { label: t('None'), value: 'none' },
          ]}
          onChangeMethodName="onChange"
          component={DropdownMenu}
          placeholder={t('Please select')}
        />

        <FormItem class="col-span-full lg:col-span-1" label={t(`Due date`)} formFieldName="dueDate" component={DueDateInputField} />
      </div>
    </div>
  );
};

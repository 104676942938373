import { createSignal } from 'solid-js';
import IconDeactivateWarning from '~/assets/images/confirm-modal/deactivate.svg?component-solid';
import { IconAlert } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { TextMessage } from './Common';
import { Modal } from './Modal';
import type { Component, JSX } from 'solid-js';
import type { Promisable } from '~/utils/types';

export const DeactivateModal: Component<{
  visible?: boolean;
  class?: string;
  onCancel?: () => Promisable<void>;
  onConfirm?: () => Promisable<void>;
  children?: JSX.Element;
}> = (props) => {
  const { t } = useLocalization();
  const [pending, setPending] = createSignal(false);

  const handleClose = async () => {
    await props.onCancel?.();
  };

  const handleDone = async () => {
    setPending(true);
    await props.onConfirm?.();
    setPending(false);
  };

  return (
    <Modal
      visible={props.visible ?? true}
      class={cn(
        'w-full max-w-lg [&_[data-slot=footer]]:border-t-0 [&_[data-slot=footer]]:pt-4 [&_[data-slot=title]]:border-b-0',
        props.class
      )}
      loading={pending()}
      title={
        <span class="flex items-center gap-1.5 capitalize text-danger">
          <IconAlert class="size-4" />
          {t('Confirm deactivate')}
        </span>
      }
      doneText={t('Confirm deactivate')}
      doneColor="warning"
      confirmation={false}
      onDone={handleDone}
      onCancel={handleClose}>
      <IconDeactivateWarning class="h-28 bg-light-pink" />
      <TextMessage>{props.children}</TextMessage>
    </Modal>
  );
};

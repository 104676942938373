import { BaseRestRepository } from '~/repositories/baseRestRepository';
import { urlWithQuery } from '~/utils/url';

const BASE_URL = '/api/run-books';

export class runBooksRepository extends BaseRestRepository {
  public async getRunBooks(filters: any): Promise<MagicDoor.Api.RunBooksPaginationDto> {
    const url = BASE_URL;
    const response = await this.fetchWithAuth(urlWithQuery(url, filters));
    return this.getJsonResponse(response);
  }

  public async getRunBookById(runBookId: string): Promise<MagicDoor.Api.HydratedRunBookDto> {
    const url = `${BASE_URL}/${runBookId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async enableRunBook(runBookId: string): Promise<void> {
    const url = `${BASE_URL}/${runBookId}/enable`;
    await this.fetchWithAuth(url, {
      method: 'PUT',
    });
  }

  public async disableRunBook(runBookId: string): Promise<void> {
    const url = `${BASE_URL}/${runBookId}/disable`;
    await this.fetchWithAuth(url, {
      method: 'PUT',
    });
  }

  public async deleteRunBook(runBookId: string) {
    const url = `${BASE_URL}/${runBookId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }

  public async addRunBook(payload: MagicDoor.Api.CreateRunBookDto): Promise<MagicDoor.Api.HydratedRunBookDto> {
    const url = BASE_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }
  public async updateRunBook(runBookId: string, payload: MagicDoor.Api.UpdateRunBookDto): Promise<void> {
    const url = `${BASE_URL}/${runBookId}`;
    await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
  }
}

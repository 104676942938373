import { For, Match, Switch, createEffect, createSignal } from 'solid-js';
import { DatePicker } from '~/components/ui/DatePicker';
import { IconListFilter, IconRefresh } from '~/components/ui/Icons';
import { Popover } from '~/components/ui/Popover';
import { Select } from '~/components/ui/Select';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { SelectOptions } from '~/components/ui/Select';

export type FilterItems<T extends Record<string, undefined | string | string[]>> = FilterItem<T>[];

type SelectFilterItem<T> = {
  type: 'select';
  label: string;
  key: keyof T;
  options: SelectOptions<string | undefined>;
  filterable?: boolean;
} & ({ multiple: true; onChange?: (value: string[]) => void } | { multiple?: false; onChange?: (value: string) => void });
type DateFilterItem<T> = { type: 'date'; label: string; key: keyof T; onChange?: (value: string) => void };
type CustomDateFilterItem<T> = { type: 'customDate'; label: string; key: keyof T; onChange?: (value: string) => void };
type GroupFilterItem = { type: 'group'; label: string };

export type FilterItem<T extends Record<string, undefined | string | string[]>> =
  | SelectFilterItem<T>
  | DateFilterItem<T>
  | CustomDateFilterItem<T>
  | GroupFilterItem;

export type FilterProps<T extends Record<string, undefined | string | string[]>> = {
  class?: string;
  overlayClass?: string;
  align?: 'start' | 'end';
  items: FilterItem<T>[];
  value?: T;
  onChange?: (value: T) => void;
  onReset?: (value: T) => void;
};

export const Filter = <T extends Record<string, undefined | string | string[]>>(props: FilterProps<T>) => {
  const { t } = useLocalization();

  const initialValue = () => JSON.parse(JSON.stringify(props.value ?? {})) as T;

  const [value, setValue] = createSignal<T>({} as T);
  const [lastValue, setLastValue] = createSignal<T>({} as T);

  createEffect(() => {
    setValue((prev) => ({ ...prev, ...props.value }));
    if (!Object.keys(props.value as any).length) {
      const emptyObj = Object.create({});
      setValue(emptyObj);
      setLastValue(emptyObj);
    }
  });

  const handleApply = () => {
    props.onChange?.(value());
    setLastValue(value() as any);
  };

  const handleReset = () => {
    setValue(initialValue() as any);
    props.onReset?.(initialValue() as any);
  };

  const handleCancel = () => {
    setValue(lastValue() as any);
  };

  const handleChange = (item: SelectFilterItem<T> | DateFilterItem<T>, value: undefined | string | string[]) => {
    item.onChange?.(value as any);
    // Clear the original date value when startPeriod is selected with a value other than custom
    if ((item.key === 'startPeriod' || item.key === 'endPeriod') && value !== 'custom') {
      setValue((prev) => ({ ...prev, [item.key]: value, startDate: undefined, endDate: undefined }));
    } else {
      setValue((prev) => ({ ...prev, [item.key]: value }));
    }
  };

  return (
    <Popover class="inline-block" data-filter>
      <Popover.Trigger
        class={cn(
          'flex size-10 items-center justify-center rounded-lg border border-input-border bg-light-faded text-title-gray transition-colors aria-expanded:border-essential-colour aria-expanded:bg-light-pink aria-expanded:text-essential-colour',
          props.class
        )}>
        <IconListFilter class="size-5" />
      </Popover.Trigger>
      <Popover.Content
        align={props.align ?? 'end'}
        class={cn('my-2 w-64 rounded-md bg-white text-sm shadow-xl ring-1 ring-partingline', props.overlayClass)}>
        <div class="space-y-2 p-4">
          <div class="flex items-center justify-between text-xs font-semibold text-text-level03">
            <span>{t('Filter by')}</span>
            <button
              class="flex items-center gap-1 rounded-full border border-essential-colour px-2 py-1 text-xs font-medium text-essential-colour transition-colors hover:bg-essential-colour/10"
              onClick={handleReset}>
              <IconRefresh size="12" />
              {t('Reset')}
            </button>
          </div>
          <For each={props.items}>
            {(item) => (
              <Switch>
                <Match when={item.type === 'select'}>
                  <Select
                    class="border-0 bg-light-gray text-text-level02"
                    prefix={item.label}
                    options={(item as SelectFilterItem<T>).options}
                    multiple={(item as SelectFilterItem<T>).multiple}
                    filterable={(item as SelectFilterItem<T>).filterable}
                    value={value()[(item as SelectFilterItem<T>).key]}
                    onChange={(value) => handleChange(item as SelectFilterItem<T>, value)}
                  />
                </Match>
                <Match when={item.type === 'customDate' && value().startPeriod === 'custom' && item.key === 'startDate'}>
                  <DatePicker
                    class="border-0 bg-light-gray text-text-level02"
                    value={value()[(item as DateFilterItem<T>).key] as string}
                    onChange={(value) => handleChange(item as DateFilterItem<T>, value)}
                  />
                </Match>
                <Match when={item.type === 'customDate' && value().endPeriod === 'custom' && item.key === 'endDate'}>
                  <DatePicker
                    class="border-0 bg-light-gray text-text-level02"
                    value={value()[(item as DateFilterItem<T>).key] as string}
                    onChange={(value) => handleChange(item as DateFilterItem<T>, value)}
                  />
                </Match>
                <Match when={item.type === 'date'}>
                  <DatePicker
                    class="border-0 bg-light-gray text-text-level02"
                    value={value()[(item as DateFilterItem<T>).key] as string}
                    onChange={(value) => handleChange(item as DateFilterItem<T>, value)}
                  />
                </Match>
                <Match when={item.type === 'group'}>
                  <div class="pt-2 text-xs font-semibold text-text-level03">{item.label}</div>
                </Match>
              </Switch>
            )}
          </For>
        </div>
        <div class="flex justify-end gap-2 border-t border-input-border p-4">
          <Popover.Trigger
            class="flex items-center gap-1 rounded-md border border-essential-colour px-3 py-2 text-sm font-medium text-essential-colour transition-colors hover:bg-essential-colour/10"
            onClick={handleCancel}>
            {t('Cancel')}
          </Popover.Trigger>
          <Popover.Trigger
            class="flex items-center gap-1 rounded-md border border-essential-colour bg-essential-colour px-4 py-2 text-sm font-medium text-white transition-colors hover:bg-essential-colour/90"
            onClick={handleApply}>
            {t('Apply')}
          </Popover.Trigger>
        </div>
      </Popover.Content>
    </Popover>
  );
};

import { useNavigate, useSearchParams } from '@solidjs/router';
import { createEffect, createMemo, createSignal, Show } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { PrintButton } from '~/components/common/Buttons';
import { IconLoader } from '~/components/ui';
import { useLocalization, usePortfolios, useProperties } from '~/contexts/global';
import { useReports } from '~/contexts/local';
import { PropertySelect } from './components/PropertySelect';
import { ReportTable, parseColumns, parseLines } from './components/Report';
import type { LineItem } from './components/Report';
import type { Component } from 'solid-js';

const BalanceSheetReport: Component<{ class?: string; report?: MagicDoor.Api.BalanceSheetReportDataDto }> = (props) => {
  const { t } = useLocalization();

  const lines = () => {
    if (props.report == null) return [];

    const res: LineItem[] = [];

    res.push({ label: t('Assets'), level: 0 });
    res.push(...parseLines(props.report.assets));
    res.push({ label: t('Total assets'), type: 'total', level: 0, values: props.report.totalAssets });

    res.push({ label: t('Liabilities'), level: 0 });
    res.push(...parseLines(props.report.liabilities));
    res.push({ label: t('Total liabilities'), type: 'total', level: 0, values: props.report.totalLiabilities });

    res.push({ label: t('Capital'), level: 0 });
    res.push(...parseLines(props.report.capital));
    res.push({ label: t('Total capital'), type: 'total', level: 0, values: props.report.totalCapital });

    return res;
  };

  return <ReportTable label={t('Account name')} lines={lines()} columns={parseColumns(props.report?.groupHeaders)} />;
};

export const BalanceSheetReportPage = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { portfolios } = usePortfolios();
  const { properties } = useProperties();

  const [printContainer, setPrintContainer] = createSignal<HTMLDivElement>();

  const [searchParams, setSearchParams] = useSearchParams<{
    reportAsOff?: string;
    portfolioIds?: string;
    propertyIds?: string;
    includeNoPropertyBills?: string;
    complanyBills?: string;
  }>();

  const { balanceSheetReport, getBalanceSheetReport, getFilterDisplay } = useReports();

  const breadcrumbItems = createMemo(() => [{ label: t('Reports'), link: '/reports' }, { label: t('Balance sheet') }]);

  createEffect(() => {
    const params: any = {
      includeNoPropertyBills: searchParams.includeNoPropertyBills === 'true',
      complanyBills: searchParams.complanyBills === 'true',
    };

    if (searchParams.propertyIds) params.propertyIds = searchParams.propertyIds?.split(',').filter(Boolean);
    else if (searchParams.portfolioIds) params.portfolioIds = searchParams.portfolioIds?.split(',').filter(Boolean);

    getBalanceSheetReport(params);
  });

  const printHeader = createMemo(() => {
    const portfolioIds = searchParams.portfolioIds;
    const propertyIds = searchParams.propertyIds;

    const filterDisplay = getFilterDisplay(propertyIds, portfolioIds, properties(), portfolios());

    return (
      <div>
        <div class="flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Balance sheet report')}</h1>
        </div>
        <div class="my-6 flex flex-wrap items-center gap-4">
          <Show when={filterDisplay.portfoliosStr}>
            <div>
              {t('Portfolio')} : {filterDisplay.portfoliosStr}
            </div>
          </Show>
          <Show when={filterDisplay.propertiesStr}>
            <div>
              {t('Property')} : {filterDisplay.propertiesStr}
            </div>
          </Show>
        </div>
      </div>
    );
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />

      <div class="m-8 rounded-lg border border-partingline bg-white p-8">
        <div class="flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Balance sheet report')}</h1>

          <PrintButton
            // disabled={balanceSheetReport.loading}
            printContainer={printContainer()!}
            printWrapClass="w-auto"
            isReport={true}
            printHeader={printHeader() as Element}
          />
        </div>
        <div class="my-6 flex flex-wrap items-center gap-3">
          <PropertySelect />
          {/* TODO: BACKEND WILL UPDATE THIS TO A NEW FORMAT, HIDE FOR NOW */}

          {/* <Checkbox
            showLabel
            label={<span class="text-sm text-title-gray">{t('Include No Property Bills')}</span>}
            checked={searchParams.includeNoPropertyBills === 'true'}
            onChange={(e) => setSearchParams({ includeNoPropertyBills: e.target.checked })}
          />
          <Checkbox
            showLabel
            label={<span class="text-sm text-title-gray">{t('Company Bills')}</span>}
            checked={searchParams.complanyBills === 'true'}
            onChange={(e) => setSearchParams({ complanyBills: e.target.checked })}
          /> */}
        </div>
        <div ref={(e) => setPrintContainer(e)}>
          <Show when={balanceSheetReport.loading} fallback={<BalanceSheetReport report={balanceSheetReport()} />}>
            <IconLoader class="mx-auto my-56 animate-spin" />
          </Show>
        </div>
      </div>
    </>
  );
};

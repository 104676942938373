import { Show, splitProps, createMemo } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { pickProps, TitleWithFeedbackIcon } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import { UnitImage } from '~/components/units';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { LeaseName } from './LeaseName';
import { LeasePeriod } from './LeasePeriod';
import { isMonthToMonth } from './utils';
import type { ComponentProps, ValidComponent } from 'solid-js';
import type { TitleWithFeedbackIconProps } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import type { Merge } from '~/utils/types';

export const LeaseTitle = <T extends ValidComponent>(
  props: Merge<
    ComponentProps<T>,
    {
      as?: T;
      class?: string;
      lease?: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto;
      property?: MagicDoor.Api.PropertyDto;
      unit?: MagicDoor.Api.UnitDto;
    } & Partial<TitleWithFeedbackIconProps>
  >
) => {
  const { t } = useLocalization();
  const [params, rest] = splitProps(props, ['as', 'class', 'lease']);
  const lease = createMemo(() => ({
    property: props.property,
    unit: props.unit,
    ...props.lease,
  }));
  return (
    <TitleWithFeedbackIcon {...pickProps(rest)} type="leases" entityId={params.lease?.id} initalSelectedIcons={params.lease?.icons}>
      <Dynamic component={params.as || 'div'} class={cn('flex items-center', params.class)} {...rest}>
        <UnitImage class="mr-2 size-11 rounded-lg" unit={params.lease?.unit} />
        <div class="text-sm">
          <LeaseName lease={lease() as any} />
          <div class="flex items-center text-xs text-text-level03">
            <Show when={isMonthToMonth(params.lease)}>{t('Month-To-Month')}：</Show>
            <LeasePeriod class="text-xs" lease={params.lease} />
          </div>
        </div>
      </Dynamic>
    </TitleWithFeedbackIcon>
  );
};

import { createEffect, createMemo, createSignal } from 'solid-js';
import { Panel } from '~/components/common/Panels';
import { MaintenanceRequestUrgency } from '~/components/maintenance-requests';
import { Select, Table, AnnularPieChart } from '~/components/ui';
import { useLocalization, useWorkOrders } from '~/contexts/global';
import { dateFormat } from '~/utils/date';
import type { TableColumns } from '~/components/ui';
import type { WorkOrderStatus } from '~/repositories/workOrderRepository';

type UserWorkOrdersProps = {
  class?: string;
};

export const UserWorkOrders = (props: UserWorkOrdersProps) => {
  const { t } = useLocalization();
  const { getUserWorkOrder, userWorkOrder } = useWorkOrders();

  const [workOrderStatus, setWorkOrderStatus] = createSignal<WorkOrderStatus | undefined>(undefined);

  const statusOptions = createMemo(() => [
    {
      label: t('All'),
      value: undefined,
    },
    {
      label: t('In progress'),
      value: 'inProgress',
      class: 'text-light-blue',
    },
    {
      label: t('Pending'),
      value: 'pending',
      class: 'text-danger',
    },
    {
      label: t('Closed'),
      value: 'closed',
      class: 'text-text-level03',
    },
  ]);

  const columns = createMemo<TableColumns<MagicDoor.Api.WorkOrderListDto>>(() => [
    {
      title: t('Status'),
      render: (row) => <div class={statusOptions().find((option) => option.value === row.status)?.class ?? ''}>{row.status}</div>,
    },
    {
      title: t('Title'),
      render: (row) => <div>{row.title}</div>,
    },
    {
      title: t('Priority'),
      render: (row) => <MaintenanceRequestUrgency urgency={row.urgency as 'urgent' | 'high' | 'medium' | 'low' | 'none' | undefined} />,
    },
    {
      title: t('Property'),
      render: (row) => <div>{userWorkOrder()?.properties?.find((property) => property.id === row.propertyId)?.name}</div>,
    },
    {
      title: t('Due date'),
      render: (row) => <div>{dateFormat('', row?.dueDate)}</div>,
    },
  ]);

  const statsData = createMemo(() => {
    return [
      {
        name: 'Urgent',
        color: '#F94874',
        value: userWorkOrder()?.items?.filter((item) => ['urgent', 'high'].includes(item.urgency)).length,
      },
      { name: 'Normal', color: '#FA8511', value: userWorkOrder()?.items?.filter((item) => item.urgency === 'medium').length },
      { name: 'Low', color: '#36CBAC', value: userWorkOrder()?.items?.filter((item) => item.urgency === 'low').length },
    ];
  });

  createEffect(() => {
    getUserWorkOrder({ status: workOrderStatus() || undefined });
  });

  return (
    <Panel
      title={t('My work orders')}
      class={props.class}
      actions={
        <Select
          prefix={t('Status')}
          filterable
          options={statusOptions()}
          value={workOrderStatus()}
          onChange={setWorkOrderStatus}
          class="w-32 md:w-44"
        />
      }>
      <div class="relative">
        <div class="flex w-full justify-center">
          <div class="flex w-1/4 flex-col items-center gap-3 border-r px-6 py-3">
            <AnnularPieChart angle={150} data={statsData()} />

            <div class="mt-[-90px] flex w-full justify-around gap-4 border-t py-2">
              <div class="text-sm">
                <span class="text-danger">{t('Urgent')}</span> {statsData()[0].value}
              </div>
              <div class="text-sm">
                <span class="text-warning">{t('Normal')}</span> {statsData()[1].value}
              </div>
              <div class="text-sm">
                <span class="text-green">{t('Low')}</span> {statsData()[2].value}
              </div>
            </div>
          </div>
          <div class="thinscroll max-h-72 w-3/4 overflow-x-auto">
            <Table
              columns={columns()}
              data={userWorkOrder()?.items}
              loading={userWorkOrder?.loading}
              rowLink={(item) => `/maintenance/work-orders/${item.id}`}
            />
          </div>
        </div>
      </div>
    </Panel>
  );
};

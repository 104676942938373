import { Show } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm';
import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { LabeledVendorSearch } from '~/components/vendors';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

const RunbookInformationSection: Component<{ isEdit: boolean }> = (props) => {
  const { t } = useLocalization();

  return (
    <div class="space-y-6">
      <div class="sticky top-0 z-10 border-b bg-white py-3 text-lg font-semibold text-text-level01">{t('Runbook information')}</div>
      <div class="grid grid-cols-2 gap-6">
        <Show when={!props.isEdit}>
          <FormItem<any>
            class="col-span-full"
            label={t(`Vendor`)}
            formFieldName="vendorId"
            component={LabeledVendorSearch}
            placeholder={t('Please select vendor')}
            rules={[{ required: true, message: t('Please select vendor') }]}
          />
        </Show>
        <FormItem
          label={t('Title')}
          component={LabeledTextInput}
          formFieldName="title"
          placeholder={t('Please enter the {name}', { name: t('Title') })}
        />
        <FormItem
          label={t('Description')}
          placeholder={t('Please enter the {name}', { name: t('Description') })}
          component={LabeledTextInput}
          formFieldName="description"
        />
        <FormItem
          class=" col-span-2"
          label={t('Script')}
          placeholder={t('Please enter the {name}', { name: t('Script') })}
          component={LabeledTextArea}
          formFieldName="script"
          rules={[
            {
              required: true,
              length: [50, 10000],
              message: t('{name} must be between {min} and {max} characters', { name: t('Script'), min: '50', max: '10000' }),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default RunbookInformationSection;

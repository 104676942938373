interface PrintOptions {
  bodyWidth?: number;
}

export const printHtml = (printContainer?: HTMLElement, options?: PrintOptions) => {
  const content = printContainer?.innerHTML;
  const printWindow = window.open('', '_blank');
  const finalOptions = {
    bodyWidth: options?.bodyWidth ?? 640,
  };

  if (printWindow) {
    let styles = '';
    const linkTags = document.getElementsByTagName('link');
    const styleTags = document.getElementsByTagName('style');

    for (let i = 0; i < linkTags.length; i++) {
      if (linkTags[i].rel === 'stylesheet') {
        styles += linkTags[i].outerHTML;
      }
    }

    for (let i = 0; i < styleTags.length; i++) {
      styles += styleTags[i].outerHTML;
    }

    printWindow.document.write('<html><head>');
    printWindow.document.write(styles);
    printWindow.document.write('</head><body onload="this.print();">');
    printWindow.document.write(`<div style="width: ${finalOptions.bodyWidth}px;">${content}</div>`);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
  }
};

import { LateFeesSettingsProvider } from '~/contexts/local';
import { PropertyChargeLateFees } from './PropertyChargeLateFees';

export const PropertyLateFees = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <LateFeesSettingsProvider>
        <PropertyChargeLateFees />
      </LateFeesSettingsProvider>
    </div>
  );
};

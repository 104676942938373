import { Route, Routes } from '@solidjs/router';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { TabLinks } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { ListCompletedWorkOrders } from './list-work-orders/ListCompletedWorkOrders';
import { ListWorkOrders } from './list-work-orders/ListWorkOrders';

export const WorkOrderTabRoutes = () => {
  const { t } = useLocalization();

  return (
    <div class="p-8">
      <TabLinks
        boxClass="mb-4 rounded-lg border border-partingline bg-white"
        items={[
          { label: t('Current'), href: location.search },
          { label: t('Completed'), href: `completed${location.search}` },
        ]}
        actions={
          <div class="flex items-center gap-2 pr-4">
            <Button href={'add'} class="font-medium capitalize">
              <IconPlus class="size-5 text-white" />
              {t('Add work order')}
            </Button>
          </div>
        }
      />
      {/* <UnderConstruction /> */}
      <Routes>
        <Route path="/*" component={ListWorkOrders} />
        <Route path="/completed" component={ListCompletedWorkOrders} />
      </Routes>
    </div>
  );
};

import { Route, Routes } from '@solidjs/router';
import { AddServiceBillTypeModal } from '~/components/service-bill';
import { AddUnit } from '~/pages/units/add-unit';
import { UnitsListView } from './components/UnitsListView';

export const ListUnitsView = () => {
  return (
    <>
      <UnitsListView />
      <Routes>
        <Route path={['/add', '/edit/:unitId']} component={AddUnit} />
        <Route path="/addServiceBill/:unitId" element={<AddServiceBillTypeModal type="unit" />} />
      </Routes>
    </>
  );
};

import { createSignal, createEffect } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { ChatRepository } from '~/repositories/chatRepository';
import { createTriggerResource } from '~/utils/resource';
import { useLatestMessage } from './ChatUpdateStore';
import type { Accessor } from 'solid-js';
import type { UnreadMessageFilter } from '~/repositories/chatRepository';

interface ChatsListContextValue {
  fetchChats: () => Promise<void>;
  chats: Accessor<MagicDoor.Api.HydratedMagicChatDto[]>;
  onChatAdded: (chat: MagicDoor.Api.HydratedMagicChatDto) => void;
  isLoading: Accessor<boolean>;
  error: Accessor<string | undefined>;
  chatCount: Accessor<number>;
  clearUnreadMessages: (chatId: string) => void;
  addnewCreatedChat: (newChat: any) => void;
  filtered: Accessor<MagicDoor.Api.UnreadMessagePaginationDto>;
  setFilter: (filter: UnreadMessageFilter) => Promise<void>;
}

export const [ChatsListProvider, useChatsList] = createMagicDoorContext<ChatsListContextValue>('ChatsList', () => {
  const repo = new ChatRepository();

  const [chats, setChats] = createSignal<MagicDoor.Api.HydratedMagicChatDto[]>([]);
  const [isLoading, setIsLoading] = createSignal<boolean>(false);
  const [error, setError] = createSignal<string | undefined>(undefined);
  const [chatCount, setChatCount] = createSignal<number>(0);
  const [filtered, setFilter, actions] = createTriggerResource((filter: UnreadMessageFilter) => repo.getUnreadMessage(filter));

  const fetchChats = async () => {
    setIsLoading(true);
    try {
      const response = await repo.getChats();

      const list: MagicDoor.Api.HydratedMagicChatDto[] = response.map(map);

      setChats(list);
      setChatCount(list.length);
      setError(undefined);
    } catch (e) {
      setError(e instanceof Error ? e.message : String(e));
    } finally {
      setIsLoading(false);
    }
  };

  const addnewCreatedChat = (newChat: any) => {
    setChats((prevChats) => [...prevChats, newChat]);
  };

  const latestMessage = useLatestMessage();

  createEffect(() => {
    const message = latestMessage();
    if (message) {
      fetchChats();
      clearUnreadMessages(message.chatId);
    }
  });

  const map = (chat: MagicDoor.Api.HydratedMagicChatDto): MagicDoor.Api.HydratedMagicChatDto => {
    return chat;
  };

  const onChatAdded = (chat: MagicDoor.Api.HydratedMagicChatDto) => {
    setChats((prevChats) => [map(chat), ...prevChats]);
    setChatCount((count) => count + 1);
  };

  const clearUnreadMessages = (chatId: string) => {
    setChats((chats) =>
      chats.map((chat) => {
        if (chat.id === chatId) {
          return { ...chat, unreadMessages: 0 };
        }
        return chat;
      })
    );
  };

  return {
    fetchChats,
    chats,
    onChatAdded,
    isLoading,
    error,
    chatCount,
    clearUnreadMessages,
    addnewCreatedChat,
    filtered: filtered,
    setFilter: (filter: UnreadMessageFilter) => setFilter(filter),
    refetch: actions.refetch,
  };
});

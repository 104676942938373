import { createContext, useContext, createSignal } from 'solid-js';
import type { ParentComponent, Accessor } from 'solid-js';

interface UpdatesContextType {
  updateSignal: Accessor<number>;
  triggerUpdate: () => void;
}

const UpdatesContext = createContext<UpdatesContextType | undefined>(undefined);

export const UpdatesProvider: ParentComponent = (props) => {
  const [updateSignal, setUpdateSignal] = createSignal(Date.now());

  const triggerUpdate = () => setUpdateSignal(Date.now());

  return <UpdatesContext.Provider value={{ updateSignal, triggerUpdate }}>{props.children}</UpdatesContext.Provider>;
};

export const useUpdates = () => {
  const context = useContext(UpdatesContext);
  if (!context) {
    throw new Error('useUpdates must be used within a UpdatesProvider');
  }
  return context;
};

import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { RentalApplicationChartOfAccountsSettingsRepository } from '~/repositories/settings/rentalApplicationChartOfAccountsSettings';
import type { Accessor } from 'solid-js';

interface RentalApplicationChartOfAccountsSettingContextValue {
  isLoading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
  updateRentalApplicationChartOfAccountsSetting: (
    payload: MagicDoor.Api.RentalApplicationChartOfAccountSettingsDto
  ) => Promise<MagicDoor.Api.RentalApplicationChartOfAccountSettingsDto | undefined>;
  getRentalApplicationChartOfAccountsSetting: () => Promise<MagicDoor.Api.RentalApplicationChartOfAccountSettingsDto | undefined>;
  deleteRentalApplicationChartOfAccountsSetting: () => Promise<void>;
  rentalApplicationChartOfAccountsSetting: Accessor<MagicDoor.Api.RentalApplicationChartOfAccountSettingsDto | undefined>;
}

export const [RentalApplicationChartOfAccountsSettingsProvider, useRentalApplicationChartOfAccountsSettings] =
  createMagicDoorContext<RentalApplicationChartOfAccountsSettingContextValue>('RentalApplicationChartOfAccountsSettings', () => {
    const repo = new RentalApplicationChartOfAccountsSettingsRepository();
    const [rentalApplicationChartOfAccountsSetting, setRentalApplicationChartOfAccountsSetting] = createSignal<
      MagicDoor.Api.RentalApplicationChartOfAccountSettingsDto | undefined
    >(undefined);
    const [isLoading, setIsLoading] = createSignal<boolean>(false);
    const [error, setError] = createSignal<Error | undefined>(undefined);

    const getRentalApplicationChartOfAccountsSetting = async (): Promise<
      MagicDoor.Api.RentalApplicationChartOfAccountSettingsDto | undefined
    > => {
      setIsLoading(true);

      try {
        const result = await repo.getRentalApplicationChartOfAccountsSetting();
        setRentalApplicationChartOfAccountsSetting(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const updateRentalApplicationChartOfAccountsSetting = async (
      payload: MagicDoor.Api.RentalApplicationChartOfAccountSettingsDto
    ): Promise<MagicDoor.Api.RentalApplicationChartOfAccountSettingsDto | undefined> => {
      setIsLoading(true);

      try {
        const result = await repo.updateRentalApplicationChartOfAccountsSetting(payload);
        setRentalApplicationChartOfAccountsSetting(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const deleteRentalApplicationChartOfAccountsSetting = async (): Promise<void> => {
      setIsLoading(true);

      try {
        await repo.deleteRentalApplicationChartOfAccountsSetting();
        setRentalApplicationChartOfAccountsSetting(undefined);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    return {
      isLoading,
      error,
      updateRentalApplicationChartOfAccountsSetting,
      getRentalApplicationChartOfAccountsSetting,
      deleteRentalApplicationChartOfAccountsSetting,
      rentalApplicationChartOfAccountsSetting,
    };
  });

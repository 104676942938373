import { Route, Routes } from '@solidjs/router';
import { createSignal, Show } from 'solid-js';
import { Empty } from '~/components/common/Empty';
import { isDraftLease } from '~/components/leases/utils';
import { AddServiceBillTypeModal } from '~/components/service-bill';
import { useLocalization } from '~/contexts/global';
import { useLease, ServiceBillProvider } from '~/contexts/local';
import { AddTenantModal } from '~/pages/leases/lease-details/components/AddTenantModal';
import { DeleteLeaseModal } from '~/pages/leases/lease-details/components/DeleteLeaseModal';
import { EndLeaseModal } from '~/pages/leases/lease-details/components/EndLeaseModal';
import { LeaseCancelEvictionModal } from '~/pages/leases/lease-details/components/LeaseCancelEvictionModal';
import { LeaseStartEvictionModal } from '~/pages/leases/lease-details/components/LeaseStartEvictionModal';
import { ReactivateLeaseModal } from '~/pages/leases/lease-details/components/ReactivateLeaseModal';
import { MaintenanceRequestsTable } from '~/pages/units/unit-details/overview/MaintenanceRequestsTable';
import { Balances } from './Balances';
import { LeaseNotice } from './LeaseNotice';
import { LeaseTerm } from './LeaseTerm';
import { MovedOutTenantsTable } from './MovedOutTenantsTable';
import { QuickAction } from './QuickAction';
import { RemoveTenant } from './RemoveTenant';
import { RentAmount } from './RentAmount';
import { TenantsTable } from './TenantsTable';
import OverviewSkeleton from './skeleton/OverviewSkeleton';
import type { Component } from 'solid-js';

export const Overview: Component = () => {
  const { t } = useLocalization();
  const { lease, loading } = useLease();
  const [movedOutTenant, setMovedOutTenant] = createSignal<MagicDoor.Api.TenantDto>();

  return (
    <>
      <Show when={!loading()} fallback={<OverviewSkeleton />}>
        <Show when={lease()} fallback={<Empty description={t('There are no leases under this unit')} />}>
          <div class="grid grid-cols-3 gap-5 text-left">
            <LeaseNotice lease={lease()} class="col-span-full" />
            <QuickAction lease={lease()} class="col-span-full lg:col-span-2" />
            <Balances lease={lease()} class="col-span-full lg:col-span-1" />
            <div class="col-span-full grid grid-cols-3 gap-5">
              <RentAmount class="col-span-full lg:col-span-2" lease={lease()} />
              <LeaseTerm class="col-span-full lg:col-span-1" lease={lease()} />
            </div>
            <TenantsTable
              class="col-span-full lg:col-span-2"
              leaseId={lease()?.id}
              tenants={lease()?.tenants ?? []}
              moveoutTenant={(tenant) => setMovedOutTenant(tenant)}
            />
            <div class="col-span-full lg:col-span-1" style={{ 'grid-auto-rows': 'auto' }}>
              <MaintenanceRequestsTable use={!isDraftLease(lease())} />
            </div>
          </div>
          <div class="grid w-full text-left">
            <MovedOutTenantsTable tenants={lease()?.tenants ?? []} />
          </div>
        </Show>
      </Show>

      <Routes>
        <Route path="/start-eviction" component={LeaseStartEvictionModal} />
        <Route path="/end-lease" component={EndLeaseModal} />
        <Route path="/cancel-eviction" component={LeaseCancelEvictionModal} />
        <Route path="/delete-lease" component={DeleteLeaseModal} />
        <Route path="/reactivate-lease" component={ReactivateLeaseModal} />
        <Route path="/add-tenant" component={AddTenantModal} />
        <Route path="/remove-tenant" element={<RemoveTenant tenant={movedOutTenant()} leaseId={lease()?.id} />} />
        <Route
          path={'/add-service-bill'}
          element={
            <ServiceBillProvider>
              <Show when={lease()}>
                <AddServiceBillTypeModal currentPropertyId={lease()?.propertyId} type="lease" currentLease={lease()} />
              </Show>
            </ServiceBillProvider>
          }
        />
      </Routes>
    </>
  );
};

import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

const BASE_URL = '/api/company/pay-outs';

export type PayOutListFilter = {
  page?: number;
  pageSize?: number;
  unitIdOr?: string[];
  propertyIdOr?: string[];
  portfolioIdOr?: string[];
  vendorIdOr?: string[];
  'PayOutDate.Start'?: string;
  'PayOutDate.End'?: string;
  'Amount.From'?: number;
  'Amount.To'?: number;
};

export class PayOutsRepository extends BaseRestRepository {
  public async getPayOuts(filter?: PayOutListFilter): Promise<MagicDoor.Api.PastCompanyPayOutDtoPaginationDto> {
    const url = urlWithQuery(BASE_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async addPayOut(payload: MagicDoor.Api.AddCompanyPayOutsDto): Promise<MagicDoor.Api.PayOutResultsDto> {
    const url = `${BASE_URL}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getRunDate(): Promise<MagicDoor.Api.LastCompanyPayOutRunDto> {
    const url = `${BASE_URL}/run-date`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async deletePayOut(transactionId: string) {
    const url = `${BASE_URL}/${transactionId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }

  public async calculatePayOuts(): Promise<MagicDoor.Api.CompanyPayOutDto> {
    const url = `${BASE_URL}/calculate`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
}

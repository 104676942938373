import IconAttachment from '~/assets/images/common/attachment.svg';
import IconEmail from '~/assets/images/contact/email.svg?component-solid';
import IconText from '~/assets/images/contact/text.svg?component-solid';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';

interface SendMessageOptionsProps {
  setSendEmail: (value: boolean) => void;
  setSendText: (value: boolean) => void;
  sendEmail: () => boolean;
  sendText: () => boolean;
  files: File[];
  setFiles: (files: File[]) => void;
}

const SendMessageOptions = (props: SendMessageOptionsProps) => {
  const { t } = useLocalization();

  let refInput: HTMLInputElement;

  function handleFiles(fileList: FileList | null) {
    const maxSize = 28.6;
    if (!fileList) return;

    const usedSize = props.files.reduce((acc, file) => acc + file.size, 0);
    const availableSpace = 1024 * 1024 * maxSize - usedSize;

    const newFiles = Array.from(fileList);
    let newFileSize = 0;
    const combinedFiles = [...props.files];

    for (let i = 0; i < newFiles.length; i++) {
      newFileSize += newFiles[i].size;
      if (newFileSize <= availableSpace) {
        combinedFiles.push(newFiles[i]);
      } else {
        toast.error(t('File size should be less than {size}', { size: maxSize + 'MB' }));
        break;
      }
    }

    props.setFiles(combinedFiles);
    if (refInput) {
      refInput.value = '';
    }
  }

  function handleClick() {
    refInput.click();
  }

  return (
    <div class="flex gap-2 px-8 pb-5 pt-3">
      <div class="border-r pr-2">
        <div onClick={handleClick} class="cursor-pointer text-essential-colour">
          <img src={IconAttachment} />
        </div>
        <input type="file" multiple hidden ref={(element) => (refInput = element)} onChange={(e) => handleFiles(e.target.files)} />
      </div>
      <div class="flex w-fit gap-2.5 rounded-full border px-3 py-0.5 text-xs text-text-level02">
        <Checkbox class="size-4 rounded before:w-0 hover:before:opacity-0" checked={props.sendEmail()} onInput={props.setSendEmail} />
        <div class="flex items-center gap-1">
          <IconEmail class="text-text-level02" />
          <span class="hide-on-mobile">{t('Email')}</span>
        </div>
      </div>
      <div class="flex w-fit gap-2.5 rounded-full border px-3 py-0.5 text-xs text-text-level02">
        <Checkbox class="size-4 rounded before:w-0 hover:before:opacity-0" checked={props.sendText()} onInput={props.setSendText} />
        <div class="flex items-center gap-1">
          <IconText class="text-text-level02" />
          <span class="hide-on-mobile">{t('Text message')}</span>
        </div>
      </div>
    </div>
  );
};

export default SendMessageOptions;

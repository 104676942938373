import IconRevenueBg from '~/assets/images/property/revenueBg.png';
import IconMoneyBag from '~/assets/images/transaction/moneyBag.png';
import IconNotebook from '~/assets/images/transaction/notebook.png';
import IconTempRevenueBarTable from '~/assets/images/transaction/tempRevenueBarTable.png';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';

export const CompanyRevenueOverview = () => {
  const { t } = useLocalization();

  return (
    <Panel title={t('Company revenue overview')}>
      <div class="flex gap-3">
        <div class="flex w-1/4 flex-col gap-3 px-6 py-3">
          <div
            class="relative flex h-2/3 w-full flex-col items-start	 rounded-md bg-[#7FEAD4] bg-left-bottom bg-no-repeat text-title-gray"
            style={{ 'background-image': `url(${IconRevenueBg})` }}>
            <div class="p-4">
              <div class="text-2xl font-semibold">$25,268</div>
              <div class="text-sm text-title-gray/60">{t('Operating income')}</div>
            </div>

            <div class=" absolute bottom-0 right-0 flex w-full items-end justify-end">
              <img src={IconMoneyBag} class="h-20 w-auto" />
            </div>
          </div>

          <div
            class="relative flex h-2/3 w-full flex-col items-start	 rounded-md bg-[#FEC690] bg-left-bottom bg-no-repeat text-title-gray"
            style={{ 'background-image': `url(${IconRevenueBg})` }}>
            <div class="p-4">
              <div class="text-2xl font-semibold">$8,268</div>
              <div class="text-sm text-title-gray/60">{t('Operating expense')}</div>
            </div>

            <div class=" absolute bottom-0 right-0 flex w-full items-end justify-end">
              <img src={IconNotebook} class="h-20 w-auto" />
            </div>
          </div>
        </div>
        <div class="flex max-h-64 w-3/4 border-l px-6 py-3">
          <img class="w-full" src={IconTempRevenueBarTable} />
        </div>
      </div>
    </Panel>
  );
};

import EmptyImg from '~/assets/images/rental-application/empty.png';
import { Empty } from '~/components/common/Empty';
import { useLocalization } from '~/contexts/global';

export const RentalApplicationEmpty = () => {
  const { t } = useLocalization();
  return (
    <div class="flex h-section2 items-center justify-center rounded-lg bg-white">
      <Empty imgSrc={EmptyImg} description={t(`There are no rental applications yet`)} />
    </div>
  );
};

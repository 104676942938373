import { LeaseTemplateCategoryRequiredError } from '~/pdfsigner/errors/categoryRequiredError';
import { LeaseTemplateSignersMissingError } from '~/pdfsigner/errors/signersMissingError';
import { LeaseTemplateTitleRequiredError } from '~/pdfsigner/errors/titleRequiredError';
import { leaseTemplateRepository } from '~/pdfsigner/repositories/leaseTemplateRepository';
import { AnnotationType } from './types/annotation';
import { UseCase } from './useCase';

export class SaveLeaseTemplateUseCase extends UseCase {
  public async runLogic() {
    if (!this.state.pdfs.title || this.state.pdfs.title === '') {
      throw new LeaseTemplateTitleRequiredError();
    }
    if (!this.state.pdfs.category) {
      throw new LeaseTemplateCategoryRequiredError();
    }
    if (!this.haveAllSignersBeenPlaced()) {
      throw new LeaseTemplateSignersMissingError();
    }
    const id = await leaseTemplateRepository.saveTemplate(this.state.pdfs);
    const shouldNavigate = !this.state.pdfs.id;
    this.state.pdfs.id = id;
    if (shouldNavigate) {
      this.eventEmitter?.emitNavigation(`/lease-templates/${id}`);
    }
  }

  private haveAllSignersBeenPlaced(): boolean {
    const placedSigners = new Set();
    this.state.pdfs.pdfPages.forEach((page) => {
      page.annotations.forEach((annotation) => {
        if (annotation.type === AnnotationType.SIGNATURE && !placedSigners.has(annotation.signerId)) {
          placedSigners.add(annotation.signerId);
        }
      });
    });
    return placedSigners.size === this.state.pdfs.signers.length;
  }
}

import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { PlaidRepository } from '~/repositories/plaidRepository';

interface PlaidContextValue {
  isPlaidLoading: () => boolean;
  error: () => Error | undefined;
  linkPlaidWithAccount: (bankAccountId: string | undefined) => Promise<void>;
  getAllPlaidTransfers: () => Promise<MagicDoor.Api.HydratedPlaidTransferDtoPaginationDto | undefined>;
  plaidTransferList: () => MagicDoor.Api.HydratedPlaidTransferDtoPaginationDto | undefined;
  getPlaidQuestionnaireUrl: (bankAccountId: string | undefined) => Promise<MagicDoor.Api.GetPlaidQuestionnaireUrlDto | undefined>;
  plaidUrl: () => string | undefined;
  openPlaidLink: (bankAccountId: string | undefined) => Promise<void>;
}

export const [PlaidProvider, usePlaid] = createMagicDoorContext<PlaidContextValue>('Plaid', () => {
  const [isPlaidLoading, setIsPlaidLoading] = createSignal<boolean>(false);
  const [error, setError] = createSignal<Error | undefined>(undefined);
  const [plaidTransferList, setPlaidTransferList] = createSignal<MagicDoor.Api.HydratedPlaidTransferDtoPaginationDto | undefined>();
  const [plaidUrl, setPlaidUrl] = createSignal<string | undefined>();

  const repo = new PlaidRepository();

  const linkPlaidWithAccount = async (bankAccountId: string | undefined) => {
    if (bankAccountId === undefined) return;

    try {
      setIsPlaidLoading(true);
      const accounts = await repo.addPlaidToBankAccount(bankAccountId);
      return accounts;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsPlaidLoading(false);
    }
  };

  const getPlaidQuestionnaireUrl = async (bankAccountId: string | undefined) => {
    if (bankAccountId === undefined) return;

    try {
      const response = await repo.getPlaidQuestionnaire(bankAccountId);
      setPlaidUrl(response.url);
      return response;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    }
  };

  const getAllPlaidTransfers = async () => {
    try {
      setIsPlaidLoading(true);
      const transfers = await repo.getPlaidTransfers();
      setPlaidTransferList(transfers);
      return transfers;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsPlaidLoading(false);
    }
  };

  const openPlaidLink = async (bankAccountId: string | undefined) => {
    if (bankAccountId === undefined) return;
    await getPlaidQuestionnaireUrl(bankAccountId);

    const url = plaidUrl();
    if (url) {
      window.open(url, '_blank');
    }
  };

  return {
    linkPlaidWithAccount,
    error,
    getPlaidQuestionnaireUrl,
    isPlaidLoading,
    plaidUrl,
    plaidTransferList,
    getAllPlaidTransfers,
    openPlaidLink,
  };
});

import { Show, createMemo, createSignal, mergeProps, children } from 'solid-js';
import PrintIcon from '~/assets/images/common/print.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import ConfirmationModal from '~/components/modals/ConfirmationModal';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { validArr } from '~/utils/tool';
import type { JSX, Component } from 'solid-js';
import type { ConfirmationModalProps } from '~/components/modals/ConfirmationModal';

export type MultiStepsProps = {
  class?: string;
  style?: any;
  contentClass?: string;
  contentStyle?: any;
  children?: JSX.Element | JSX.Element[];
  currentStep: number;
  showPageBar?: boolean;
  showPrevious?: boolean;
  hideSubButtonsOnLast?: boolean;
  successPage?: JSX.Element;
  confirmation?: false | Pick<ConfirmationModalProps, 'title' | 'message' | 'cancelText' | 'confirmText'>;
  submitText?: string;
  nextText?: string;
  disableNext?: boolean;
  onStepChange?: (step: number, isBack?: boolean) => Promise<void | boolean> | void | boolean;
  onSubmit?: () => Promise<void> | void;
  onClose?: () => void;
  stepper?: JSX.Element;
  steps?: Component[];
  loading?: boolean;
  cancelText?: string;
  showSubmit?: boolean;
  showPrint?: boolean;
  onPrint?: () => void;
};

export const MultiSteps: Component<MultiStepsProps> = (originProps) => {
  const { t } = useLocalization();
  const props = mergeProps(
    {
      size: 'md' as const,
      doneText: t('OK'),
      cancelText: t('Cancel'),
      confirmation: {
        title: t('Confirmation'),
        message: t('Are you sure you want to cancel?'),
        cancelText: t('Go back'),
        confirmText: t('Yes'),
      },
      steps: [],
      showSubmit: true,
      hideSubButtonsOnLast: false,
    },
    originProps
  );

  const resolvedChildren = children(() => props.children);

  function getChildrenArray() {
    return validArr(props.steps) ? props.steps : resolvedChildren.toArray();
  }

  const stepCount = () => (Array.isArray(getChildrenArray()) ? getChildrenArray().length : 1);
  const isLastStep = createMemo(() => props.currentStep === stepCount() - 1);
  const [isSubmitted, setIsSubmitted] = createSignal<boolean>(false);
  const [confirmModal, showConfirmModal] = createSignal<boolean>(false);
  const [loading, setLoading] = createSignal<boolean>(false);

  async function handleNextStep() {
    console.log;
    if (isLastStep()) {
      const isValid = props.onStepChange && (await props.onStepChange(props.currentStep));
      if (props.onSubmit && !isValid) {
        setLoading(true);
        try {
          await props.onSubmit();
          setIsSubmitted(true);
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      }
    } else {
      props.onStepChange && (await props.onStepChange(props.currentStep + 1));
    }
  }

  function handlePrevStep() {
    props.onStepChange && props.onStepChange(props.currentStep - 1, true);
  }

  function handleCancel() {
    if (props.confirmation) {
      showConfirmModal(true);
    } else {
      props.onClose && props.onClose();
    }
  }

  const handleConfirmCancel: () => void = () => {
    showConfirmModal(false);
    props.onClose && props.onClose();
  };

  const showPreviousButton = createMemo(() => props.showPrevious && props.currentStep > 0);

  return (
    <div class={cn('flex flex-1 flex-col overflow-hidden', props.class)} style={props.style}>
      <Show when={!isSubmitted()}>
        <div class={cn('thinscroll flex-1 overflow-auto p-3', props.contentClass)} style={props.contentStyle}>
          <div class="flex flex-col">
            {props?.stepper}
            <Show when={props.showPageBar}>
              <div>
                {t('Step')} {props.currentStep + 1} {t('of')} {stepCount()}
              </div>
            </Show>

            <Show when={Array.isArray(getChildrenArray())} fallback={resolvedChildren()}>
              {(getChildrenArray() as JSX.Element[])[props.currentStep]}
            </Show>
          </div>
        </div>
        <div class="sticky bottom-0 mt-auto flex justify-end gap-2 border-t border-partingline bg-white px-3 py-5 lg:px-6">
          <Show when={!(props.hideSubButtonsOnLast && isLastStep())}>
            <Show when={showPreviousButton()}>
              <Button class="mr-auto" variant="outlined" onClick={handlePrevStep}>
                {t('Previous')}
              </Button>
            </Show>
            <Button variant="outlined" onClick={handleCancel}>
              {props.cancelText || t('Cancel')}
            </Button>
          </Show>
          <Show when={props.showPrint}>
            <Button onClick={props.onPrint}>
              <div class="flex items-center">
                <PrintIcon />
                {t('Print')}
              </div>
            </Button>
          </Show>
          <Show when={props.showSubmit || !isLastStep()}>
            <Button disabled={props.disableNext} loading={loading() || props.loading} onClick={handleNextStep}>
              {isLastStep() ? props.submitText ?? t('Submit') : props.nextText ?? t('Next')}
            </Button>
          </Show>
        </div>
      </Show>
      <Show when={isSubmitted() && props.successPage}>{props.successPage}</Show>
      <Show when={confirmModal() && props.confirmation !== false}>
        <ConfirmationModal
          {...(props.confirmation as ConfirmationModalProps)}
          onConfirm={handleConfirmCancel}
          onCancel={() => showConfirmModal(false)}
        />
      </Show>
    </div>
  );
};

import { createEffect, For } from 'solid-js';
import UnderConstruction from '~/components/common/UnderConstruction';
import { usePortfolios } from '~/contexts/global';
import ListingCard from '~/pages/listing/components/ListingCard';
import { PortfolioSummary } from '~/pages/listing/components/PortfolioSummary';
import { PortfolioTabs } from '~/pages/listing/components/PortfolioTabs';

export const ListListingPage = () => {
  const { portfolios, current, setCurrentId } = usePortfolios();

  const listings = new Array(6).fill({
    id: 'test123456789',
    image: 'https://img.zcool.cn/community/01c9d158cc87bca801219c77fd02f2.jpg@1280w_1l_2o_100sh.jpg',
    name: 'Unit 3535',
    address: '2522 S Dunsmuir Ave',
    type: 'multiFamily',
    status: 'Active',
    updateDate: '2024-05-26',
    views: 3000,
    guestCards: 11,
    applications: 8,
  });

  createEffect(() => {
    if (portfolios()?.[0]?.id) {
      setCurrentId(portfolios()?.[0]?.id);
    }
  });
  return (
    <div class="h-section space-y-5 overflow-hidden p-8 pt-5">
      <PortfolioTabs portfolios={portfolios()} selected={current()} />
      <PortfolioSummary />
      <div class="grid w-full grid-cols-4 gap-3">
        <For each={listings}>{(listing) => <ListingCard listing={listing} class="col-span-1" />}</For>
      </div>
      <UnderConstruction />
    </div>
  );
};

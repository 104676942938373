export function printReports(
  printContainer: HTMLDivElement,
  printWrapClass?: string,
  printHeader?: Element,
  extraStyle = '',
  orientation?: 'portrait' | 'landscape'
) {
  const content = printContainer?.innerHTML;

  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  document.body.appendChild(iframe);

  const html = `
      <!DOCTYPE html>
      <html>
      <head>
        <meta charset="utf-8">
        <title>Print</title>
        <style type="text/css">
          @media print {
            body {
              -webkit-print-color-adjust: exact !important;
              print-color-adjust: exact !important;
            }
            [data-slot="report"] [data-slot="label"] {
              min-width: 1px !important;
            }
            .page-break {
              page-break-before: always;
            }
          }
          @page {
            margin: 10mm;
            size: ${orientation ?? 'portrait'};
          }
          body {
            margin: 0;
            padding: 0;
          }
          .print-wrapper {
            width: 100%;
            max-width: 100%;
            overflow-x: hidden;
            ${extraStyle}
          }
          table {
            width: 100% !important;
            table-layout: fixed;
          }
          td, th {
            word-wrap: break-word;
            overflow-wrap: break-word;
          }
          #orientationToggle {
            position: fixed;
            top: 10px;
            right: 10px;
            z-index: 9999;
          }
        </style>
      </head>
      <body>
        <button id="orientationToggle">Switch Orientation</button>
        <div class="print-wrapper ${printWrapClass || ''}">
          ${printHeader?.innerHTML || ''}
          ${content}
        </div>
        <script>
          let isLandscape = false;

          function setOrientation(landscape) {
            isLandscape = landscape;
            document.body.style.width = landscape ? '297mm' : '210mm';
            document.body.style.height = landscape ? '210mm' : '297mm';
            const style = document.createElement('style');
            style.textContent = \`@page { size: \${landscape ? 'landscape' : 'portrait'}; }\`;
            document.head.appendChild(style);
          }

          function detectOptimalOrientation() {
            const wrapper = document.querySelector('.print-wrapper');
            const contentWidth = wrapper.scrollWidth;
            const contentHeight = wrapper.scrollHeight;
            return contentWidth > contentHeight;
          }

          function scaleContent() {
            const wrapper = document.querySelector('.print-wrapper');
            const contentWidth = wrapper.scrollWidth;
            const pageWidth = isLandscape ? 297 : 210; // in mm
            const scale = (pageWidth - 20) / contentWidth; // 20mm for margins
            if (scale < 1) {
              wrapper.style.transform = \`scale(\${scale})\`;
              wrapper.style.transformOrigin = 'top left';
              document.body.style.height = (wrapper.getBoundingClientRect().height * scale) + 'px';
            }
          }

          function addPageBreaks() {
            const wrapper = document.querySelector('.print-wrapper');
            const pageHeight = isLandscape ? 210 : 297; // in mm
            let currentHeight = 0;
            wrapper.childNodes.forEach((node) => {
              if (node.nodeType === Node.ELEMENT_NODE) {
                const element = node;
                const elementHeight = element.offsetHeight;
                if (currentHeight + elementHeight > pageHeight) {
                  element.classList.add('page-break');
                  currentHeight = elementHeight;
                } else {
                  currentHeight += elementHeight;
                }
              }
            });
          }

          document.getElementById('orientationToggle').addEventListener('click', function() {
            setOrientation(!isLandscape);
            scaleContent();
            addPageBreaks();
          });

          window.onload = function() {
            ${JSON.stringify(orientation)} === undefined && setOrientation(detectOptimalOrientation());
            scaleContent();
            addPageBreaks();
          };

          // Hide the orientation toggle button when printing
          window.onbeforeprint = function() {
            document.getElementById('orientationToggle').style.display = 'none';
          };

          window.onafterprint = function() {
            document.getElementById('orientationToggle').style.display = 'block';
          };
        </script>
      </body>
      </html>
    `;

  const doc = iframe.contentWindow?.document;
  if (doc) {
    doc.open();
    doc.write(html);
    doc.close();

    iframe.onload = () => {
      setTimeout(() => {
        iframe.contentWindow?.print();
        setTimeout(() => {
          document.body.removeChild(iframe);
        }, 1000);
      }, 1000);
    };
  }
}

import { BaseRestRepository } from './baseRestRepository';

const ATTACHMENTS_URL = '/api/file-attachments/attachment';

type EntityType = `${MagicDoor.Api.EntityType}`;

export class FileRepository extends BaseRestRepository {
  public getFile(fileId: string, fileName: string, ext: string): string {
    const url = `/api/files/${fileId}/${fileName}.${ext}`;
    return url;
  }

  public async getAttachments(entityType: EntityType, entityId: string): Promise<MagicDoor.Api.HydratedFileAttachmentDto[]> {
    const url = `${ATTACHMENTS_URL}/${entityType}/${entityId}/files`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async addAttachment(
    entityType: EntityType,
    entityId: string,
    file: File,
    description?: string,
    signal?: AbortSignal
  ): Promise<MagicDoor.Api.HydratedFileAttachmentDto> {
    const url = `${ATTACHMENTS_URL}/${entityType}/${entityId}/files`;
    const formData = new FormData();
    formData.append('File', file);
    formData.append('Description', description || '');

    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      signal,
    });
    return this.getJsonResponse(response);
  }

  public async deleteAttachment(entityType: EntityType, entityId: string, fileAttachmentId: string): Promise<void> {
    const url = `${ATTACHMENTS_URL}/${entityType}/${entityId}/files/${fileAttachmentId}`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }
}

import { useParams } from '@solidjs/router';
import { createEffect, createSignal } from 'solid-js';
import AccountSettingIcon from '~/assets/images/leases/accountSetting.svg?component-solid';
import ModalBgIcon from '~/assets/images/leases/transactions/modalBgIcon.svg';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { LeaseOverviewBanner } from '~/components/leases';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLease, useLeaseRentTransactions } from '~/contexts/local';
import { useUpdates } from '~/pages/leases/lease-details//UpdatePagesContext';
import { formConfirmationContent } from '~/utils/constant';
import { currency, isNumber } from '~/utils/number';
import { isEmptyData } from '~/utils/tool';
import type { Component } from 'solid-js';
import type { ModalProps } from '~/components/modals/Modal';

type LeaseSetupChargeModalProps = Omit<ModalProps, 'title'> & {
  currentLease: MagicDoor.Api.HydratedLeaseDto;
};
type FormDataType = {
  issueDate?: string;
  chartOfAccountId?: string;
  amount?: string;
  description?: string;
};

export const LeaseCreditModal: Component<LeaseSetupChargeModalProps> = (props) => {
  const { t } = useLocalization();
  const { lease } = useLease();
  const { leaseId: id } = useParams();
  const { triggerUpdate } = useUpdates();

  const { addCredit } = useLeaseRentTransactions();

  const formId = 'setup_charge_form';

  const [loading, setLoading] = createSignal<boolean>(false);
  const [formData, setFormData] = createSignal<FormDataType>({
    issueDate: undefined,
    amount: undefined,
    chartOfAccountId: undefined,
    description: undefined,
  });
  const [amount, setAmount] = createSignal<string>();

  const [formErrors, setFormErrors] = createSignal<FormDataType>({});
  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);

  const setFormDataCommon = (value: string, key: string) => {
    setFormData({
      ...formData(),
      [key]: value,
    });

    if (key === 'amount') {
      setAmount(value);
    }
  };

  const handleSubmit = async (ev: Event) => {
    ev.preventDefault();
    const validateResult = validator();
    if (!validateResult) return;
    const leaseId = lease()?.id ?? id;
    if (!leaseId) return;

    setLoading(true);
    try {
      await addCredit(leaseId, {
        issueDate: formData().issueDate as string,
        chartOfAccountId: formData().chartOfAccountId as string,
        amount: Number(amount()),
        description: formData().description || null,
      });

      props.onCancel && props.onCancel();
      triggerUpdate();

      toast.success(t('{name} has been added successfully', { name: t('Credit') }));
    } finally {
      setLoading(false);
    }
  };

  const validator = (): boolean => {
    let result = true;
    const errors: FormDataType = {};
    Object.keys(formData()).forEach((key: string) => {
      if (key !== 'description') {
        const nameObj: any = {
          issueDate: 'Issue date',
          chartOfAccountId: 'Chart of account',
          amount: 'Amount',
        };
        errors[key as keyof FormDataType] = (() => {
          const name = nameObj[key];
          const value = formData()[key as keyof FormDataType];
          const translatedName = t(name);

          if (!value) {
            return t('{name} is required', { name: translatedName });
          }
          if (value && value.length > 255) {
            return t('{name} must be {length} characters or less', { name: translatedName, length: length.toString() });
          }
          if (key === 'amount' && !isNumber(amount())) {
            return t('{name} must be a number', { name: translatedName });
          }
          return undefined;
        })();
      }
    });
    for (const key in errors) {
      if (errors[key as keyof FormDataType]) {
        result = false;
        break;
      }
    }
    setFormErrors(errors);
    return result;
  };

  createEffect(() => {
    setShowCloseConfirmation(!isEmptyData(formData()));
  });

  return (
    <Modal
      {...props}
      title={t('Issue credit')}
      doneText={t('Save')}
      submitId={formId}
      loading={loading()}
      confirmation={showCloseConfirmation() ? formConfirmationContent(t) : false}>
      <form class="flex flex-col gap-5 px-8 py-6" id={formId} onSubmit={handleSubmit}>
        <LeaseOverviewBanner lease={props.currentLease} />
        <div class="grid grid-cols-2 gap-x-7 gap-y-6">
          <DueDateInputField
            class="col-span-full lg:col-span-1"
            required
            contentPosition={['right']}
            label={t('Issue date')}
            value={formData().issueDate}
            onInput={(value) => setFormDataCommon(value, 'issueDate')}
            error={formErrors().issueDate}
          />
        </div>
        <div>
          <div class="flex gap-2 border-b border-partingline py-3 text-text-level01">
            <AccountSettingIcon />
            <span class="font-semibold">{t('Chart of account settings')}</span>
          </div>
          <div class="grid grid-cols-2 gap-x-7 gap-y-6 pb-5 pt-4">
            <LabeledChartOfAccountSelect
              class="col-span-full lg:col-span-1"
              required
              label={t('Chart of account')}
              value={formData().chartOfAccountId}
              error={formErrors().chartOfAccountId}
              onInput={(value) => setFormDataCommon(value, 'chartOfAccountId')}
              placeholder={t('Please select')}
            />
            <LabeledNumberInput
              class="col-span-full lg:col-span-1"
              required
              label={t('Amount')}
              step={0.01}
              value={amount()}
              onInput={(value) => setFormDataCommon(`${value}`, 'amount')}
              error={formErrors().amount}
              placeholder={t('Please enter')}
            />
            <LabeledTextInput
              class="col-span-full lg:col-span-1"
              label={t('Memo')}
              value={formData().description}
              onInput={(value) => setFormDataCommon(value, 'description')}
              placeholder={t('Please enter')}
            />
          </div>
        </div>
      </form>
      <div class="relative mt-10 flex w-full items-center justify-end bg-pink-100 py-5">
        <img src={ModalBgIcon} class="absolute bottom-0 left-4  h-20 w-52" />
        <div class="mr-4 flex items-center">
          <span class="mr-2 text-xs uppercase lg:text-sm">{t('Total amount')}:</span>
          <span class="text-sm font-bold text-essential-colour lg:text-2xl">
            {currency(isNumber(formData().amount) ? formData().amount : 0)}
          </span>
        </div>
      </div>
    </Modal>
  );
};

import { useNavigate } from '@solidjs/router';
import { createSignal } from 'solid-js';
import endLeaseBg from '~/assets/images/units/endLeaseBg.png';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLease, useLeasesList } from '~/contexts/local';

export const EndLeaseModal = () => {
  const navigate = useNavigate();
  const { t } = useLocalization();
  const { lease, endLease, getLease } = useLease();
  const { refetchLeases } = useLeasesList();

  const [modalLoading, setModalLoading] = createSignal<boolean>(false);

  const endLeaseFunc = async () => {
    const leaseId = lease()?.id;
    if (!leaseId) return;
    setModalLoading(true);
    try {
      await endLease(leaseId);
      refetchLeases();
      getLease(leaseId);
      navigate(-1);
      toast.success(t('Operation successful'));
    } finally {
      setModalLoading(false);
    }
  };

  return (
    <Modal
      size="sm"
      onDone={endLeaseFunc}
      visible={true}
      title={t('End lease')}
      doneText={t('Confirm')}
      loading={modalLoading()}
      onCancel={() => navigate(-1)}
      confirmation={false}>
      <div>
        <img class="size-full h-24" src={endLeaseBg} alt="endLeaseBg" />
        <div class="my-2 ml-8">{t('Please confirm to end lease')}</div>
      </div>
    </Modal>
  );
};

import { RentPaymentNotificationsSettingsProvider } from '~/contexts/local';
import { Notifications } from './Notifications';

export const RentPaymentNotificationsWrap = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <RentPaymentNotificationsSettingsProvider>
        <Notifications />
      </RentPaymentNotificationsSettingsProvider>
    </div>
  );
};

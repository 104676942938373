import { AnnotationType } from '~/pdfsigner/usecases/types/annotation';
import { Presenter } from './Presenter';
import type { PresentableListBox } from '~/pdfsigner/ui/types/presentableListBox';
import type { PresentableOption } from '~/pdfsigner/ui/types/presentableOption';
import type { PresentableToolbar } from '~/pdfsigner/ui/types/presentableToolbar';
import type { PdfState } from '~/pdfsigner/usecases/state/pdfAppState';

export class ToolbarPresenter extends Presenter<PresentableToolbar> {
  createModel(state: PdfState): PresentableToolbar {
    return {
      title: state.pdfs.title ? state.pdfs.title : '',
      isTextButtonSelected: state.selectedAnnotationType === AnnotationType.TEXT,
      isSignatureButtonSelected: state.selectedAnnotationType === AnnotationType.SIGNATURE,
      isInitialsButtonSelected: state.selectedAnnotationType === AnnotationType.INITIALS,
      isDateButtonSelected: state.selectedAnnotationType === AnnotationType.DATE,
      isAutoFillButtonSelected: state.selectedAnnotationType === AnnotationType.AUTOFILL,
      numberOfSignersListBox: this.createSignersListBox(state),
      numberOfSelectedSigners: state.pdfs.signers.length,
      dataPaths: state.dataPathManager.getDataPaths(),
      selectedNode: state.dataPathManager.getSelectedNode(),
      isAutofillEnabled: state.selectedAnnotationType === AnnotationType.AUTOFILL,
      templateCategories: this.createTemplateCategoriesListBox(state),
      fontSizeListBox: this.createFontSizesListBox(state),
      selectedFontSize: state.selectedFontSize,
    };
  }

  private createSignersListBox(state: PdfState): PresentableListBox {
    const options: PresentableOption[] = [];
    for (let i = 0; i < state.maxNumberOfSigners; i++) {
      options.push({
        value: i,
        text: `${i + 1}`,
        isSelected: i === state.pdfs.signers.length,
      });
    }
    const isEnabled = true;
    return {
      options,
      isEnabled,
    };
  }

  private createFontSizesListBox(state: PdfState): PresentableListBox {
    const options: PresentableOption[] = [];
    for (let i = 0; i < state.fontSizes.length; i++) {
      options.push({
        value: state.fontSizes[i],
        text: `${state.fontSizes[i]}`,
        isSelected: i === state.selectedFontSize,
      });
    }
    const isEnabled = true;
    return {
      options,
      isEnabled,
    };
  }

  private createTemplateCategoriesListBox(state: PdfState): PresentableListBox {
    const options: PresentableOption[] = [];
    const isEnabled = true;
    state.leaseTemplateCategories.forEach((leaseTeplateCategory, index) => {
      options.push({
        value: index,
        text: `${leaseTeplateCategory.name}`,
        isSelected: leaseTeplateCategory === state.pdfs.category,
      });
    });
    return {
      options,
      isEnabled,
    };
  }
}

import { For, createEffect, onCleanup, onMount } from 'solid-js';
import usePresenter from '~/pdfsigner/hooks/usePresenter';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { PdfPresenter } from '~/pdfsigner/presenters/PdfPresenter';
import { getPageCoordinatesForPosition, getPositionOfEvent, getRelativePosition } from '~/pdfsigner/ui/utils/utils';
import { DragAnnotationUseCase } from '~/pdfsigner/usecases/dragAnnotationUseCase';
import { RenderUseCase } from '~/pdfsigner/usecases/renderUseCase';
import { StopDraggingUseCase } from '~/pdfsigner/usecases/stopDraggingUseCase';
import { PdfPage } from './PdfPage';
import type { JSX } from 'solid-js';
import type { PresentableAnnotatedPdfPage } from '~/pdfsigner/ui/types/presentableAnnotatedPdfPage';

export const PdfDocument = (): JSX.Element => {
  const { execute: dragAnnotation } = useUseCase(DragAnnotationUseCase);
  const { execute: stopDraggingAnnotation } = useUseCase(StopDraggingUseCase);
  const { execute: render } = useUseCase(RenderUseCase);
  const { model: presentablePdf } = usePresenter(PdfPresenter);

  const handleResize = async () => {
    await render();
  };

  const handleStartDragging = async (id: string, event: MouseEvent, page: number) => {
    event.stopPropagation();
    await dragAnnotation({ id, relativePosition: getRelativePosition(getPositionOfEvent(event), page), startPage: page, endPage: page });
    document.onmousemove = (event: MouseEvent) => drag(id, event, page);
    document.onmouseup = (event: MouseEvent) => stopDragging(id, event, page);
  };

  const drag = async (id: string, event: MouseEvent, page: number) => {
    await dragAnnotation({ id, relativePosition: getRelativePosition(getPositionOfEvent(event), page), startPage: page, endPage: page });
  };

  const stopDragging = async (id: string, event: MouseEvent, page: number) => {
    const pdf = presentablePdf();
    if (!pdf) {
      return;
    }
    const relativePosition = getRelativePosition(getPositionOfEvent(event), page);
    const newPosition = getPageCoordinatesForPosition(relativePosition.position, pdf.length);
    const endPage = newPosition ? newPosition.page : page;
    await stopDraggingAnnotation({ id, relativePosition: newPosition?.relativePosition, startPage: page, endPage: endPage });
    document.onmousemove = null;
    document.onmouseup = null;
  };

  onMount(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  });

  onCleanup(() => {
    window.removeEventListener('resize', handleResize);
  });

  createEffect(() => {
    requestAnimationFrame(handleResize);
  });

  return (
    <div class="flex w-full flex-col items-center">
      <For each={presentablePdf() || []}>
        {(page: PresentableAnnotatedPdfPage) => <PdfPage page={page} onStartDragging={handleStartDragging} />}
      </For>
    </div>
  );
};

import { Route, Routes } from '@solidjs/router';
import { createMemo } from 'solid-js';
import { Panel } from '~/components/common/Panels';
import { PhotosEditor } from '~/components/photos';
import { useLocalization, useUnits } from '~/contexts/global';
import { AddUnit } from '~/pages/units/add-unit';
import { getImageUrl } from '~/utils/file';
export const Photos = () => {
  const { t } = useLocalization();
  const { unit, addUnitImage, deleteUnitImage } = useUnits();

  const images = createMemo(() => {
    const current = unit();
    if (!current?.images) return [];
    return current.images.map((i) => ({ id: i.fileId, url: getImageUrl('unit', current.id, i.fileId) })) || [];
  });

  const handleUpload = async (files: File[]) => {
    const unitId = unit()?.id;
    if (unitId == null) return;

    for (const file of files) {
      await addUnitImage(unitId, { file });
    }
  };

  const handleDelete = async (fileId: string) => {
    const unitId = unit()?.id;
    if (unitId == null) return;
    await deleteUnitImage(unitId, fileId);
  };

  return (
    <>
      <Panel title={t('Unit photos')}>
        <PhotosEditor photos={images()} onUpload={handleUpload} onDelete={handleDelete} />
      </Panel>

      <Routes>
        <Route path="/edit-unit" component={AddUnit} />
      </Routes>
    </>
  );
};

import { For, Show } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/pdfsigner/hooks/usePresenter';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { ToolbarPresenter } from '~/pdfsigner/presenters/ToolbarPresenter';
import { ChangeFontSizeUseCase } from '~/pdfsigner/usecases/changeFontSizeUseCase';
import { ChangeNumberOfSignersUseCase } from '~/pdfsigner/usecases/changeNumberOfSignersUseCase';
import { SaveLeaseTemplateUseCase } from '~/pdfsigner/usecases/saveLeaseTemplateUseCase';
import { SetAnnotationTypesUseCase } from '~/pdfsigner/usecases/setAnnotationTypesUseCase';
import { AnnotationType } from '~/pdfsigner/usecases/types/annotation';
import { UpdateLeaseTemplateUseCase } from '~/pdfsigner/usecases/updateLeaseTemplateUseCase';
import FontSizeSelector from './FontSizeSelector';
import ListBox from './ListBox';
import { DropdownWithTree } from './TreeDropdown';
import type { JSX } from 'solid-js';
import type { DataPathNode } from '~/pdfsigner/usecases/types/dataPathNode';

const Toolbar = (): JSX.Element => {
  const { execute: updateLeaseTemplate } = useUseCase(UpdateLeaseTemplateUseCase);
  const { execute: changeNumberOfSigners } = useUseCase(ChangeNumberOfSignersUseCase);
  const { execute: changeFontSize } = useUseCase(ChangeFontSizeUseCase);
  const { execute: setAnnotationTypes } = useUseCase(SetAnnotationTypesUseCase);
  const { execute: save } = useUseCase(SaveLeaseTemplateUseCase);
  const { model: presentableToolbar } = usePresenter(ToolbarPresenter);

  const { t } = useLocalization();
  return (
    <Show when={presentableToolbar}>
      <div class="left-0 top-0 w-full">
        <div class="flex w-full flex-row items-center justify-start border-r border-gray-300 bg-dark-essential py-2">
          <div class="flex w-full flex-row">
            <div class="mx-2 flex grow flex-row items-center">
              <label for="title" class="m-2 text-white">
                {t('Title')}:
              </label>
              <input
                id="title"
                type="text"
                class="block w-full rounded-md border border-gray-300 bg-white p-0.5 px-2 text-black"
                value={presentableToolbar()?.title}
                onInput={(e) => updateLeaseTemplate({ title: e.currentTarget.value })}
              />
            </div>
            <div class="ml-2 mr-4 flex flex-row items-center">
              <label for="category" class=" text-white">
                {t('Category')}:
              </label>
              <ListBox
                listBox={presentableToolbar()?.templateCategories}
                onChange={(value) => updateLeaseTemplate({ leaseCategoryIndex: value })}
              />
            </div>
          </div>
        </div>
        <div class="flex w-full flex-row items-center justify-start border-r border-gray-300 bg-dark-essential pb-3">
          <div class="ml-5 flex items-center space-x-2 align-middle">
            <label for="number-select" class="text-white">
              {t('Signers')}:
            </label>
            <select
              id="number-select"
              class="mt-1 block rounded-md border border-gray-300 p-0.5"
              value={presentableToolbar()?.numberOfSelectedSigners}
              onInput={(e) => changeNumberOfSigners(parseInt(e.currentTarget.value, 10))}>
              <For each={[...Array(10).keys()]}>{(i) => <option value={i + 1}>{i + 1}</option>}</For>
            </select>
          </div>
          <FontSizeSelector
            options={presentableToolbar()?.fontSizeListBox.options}
            selectedValue={presentableToolbar()?.selectedFontSize}
            onChange={(value) => changeFontSize(value)}
          />
          <button
            class={`ml-5 rounded-lg border border-white px-4 py-1 ${
              presentableToolbar()?.isSignatureButtonSelected ? 'bg-light-essential text-black' : 'bg-transparent text-white'
            }`}
            onClick={() => setAnnotationTypes({ type: AnnotationType.SIGNATURE })}>
            {t('Signature')}
          </button>
          <button
            class={`ml-5 rounded-lg border border-white px-4 py-1 ${
              presentableToolbar()?.isInitialsButtonSelected ? 'bg-light-essential text-black' : 'bg-transparent text-white'
            }`}
            onClick={() => setAnnotationTypes({ type: AnnotationType.INITIALS })}>
            {t('Initials')}
          </button>
          <button
            class={`ml-5 rounded-lg border border-white px-4 py-1 ${
              presentableToolbar()?.isDateButtonSelected ? 'bg-light-essential text-black' : 'bg-transparent text-white'
            }`}
            onClick={() => setAnnotationTypes({ type: AnnotationType.DATE })}>
            {t('Date')}
          </button>
          <button
            class={`ml-5 rounded-lg border border-white px-4 py-1 ${
              presentableToolbar()?.isTextButtonSelected ? 'bg-light-essential text-black' : 'bg-transparent text-white'
            }`}
            onClick={() => setAnnotationTypes({ type: AnnotationType.TEXT })}>
            {t('Text')}
          </button>
          <button
            class={`ml-5 rounded-lg border border-white px-4 py-1 ${
              presentableToolbar()?.isAutoFillButtonSelected ? 'bg-light-essential text-black' : 'bg-transparent text-white'
            }`}
            onClick={() => setAnnotationTypes({ type: AnnotationType.AUTOFILL })}>
            {t('AutoFill')}
          </button>
          <DropdownWithTree
            data={presentableToolbar()?.dataPaths}
            selectedNode={presentableToolbar()?.selectedNode}
            enabled={presentableToolbar()?.isAutofillEnabled}
            class={`z-30 ml-4 h-[32px] min-w-[200px] rounded-md ${
              presentableToolbar()?.isAutofillEnabled === false
                ? 'cursor-not-allowed bg-gray-300 text-gray-500'
                : 'bg-white text-black hover:bg-gray-200'
            }`}
            onSelect={(node: DataPathNode) => updateLeaseTemplate({ dataPathKey: node.key })}
          />
          <button
            class={`ml-5 rounded-lg border border-white bg-dark-essential px-4 py-1 text-white hover:bg-light-essential`}
            onClick={() => save()}>
            {t('Save')}
          </button>
        </div>
      </div>
    </Show>
  );
};

export default Toolbar;

import { Route, Routes, useParams } from '@solidjs/router';
import { createEffect, createMemo, Show } from 'solid-js';
import { Empty } from '~/components/common/Empty';
import { AddServiceBillTypeModal } from '~/components/service-bill';
import { useLocalization } from '~/contexts/global';
import { useLeasesList, useLease } from '~/contexts/local';
import { DeleteLeaseModal } from '~/pages/leases/lease-details/components/DeleteLeaseModal';
import { EndLeaseModal } from '~/pages/leases/lease-details/components/EndLeaseModal';
import { LeaseStartEvictionModal } from '~/pages/leases/lease-details/components/LeaseStartEvictionModal';
import { AddUnit } from '~/pages/units/add-unit';
import { CurrentLease } from './CurrentLease';
import { DraftLeases } from './DraftLeases';
import { PastLeases } from './PastLeases';
import type { Component } from 'solid-js';

export const Leases: Component = () => {
  const params = useParams<{ unitId: string }>();
  const { t } = useLocalization();

  const { leases, getLeases, loading } = useLeasesList();
  const { lease } = useLease();

  createEffect(() => params.unitId && getLeases({ unitId: params.unitId }));

  const currentLeases = createMemo(() => {
    return leases()?.filter((item) => !item.draft && !item.ended) || [];
  });

  const draftLeases = createMemo(() => {
    return leases()?.filter((item) => item.draft) || [];
  });

  const pastLeases = createMemo(() => {
    return leases()?.filter((item) => item.ended) || [];
  });

  return (
    <>
      <Show when={leases()}>
        <div class="grid grid-cols-3 gap-5 text-left">
          <CurrentLease class="col-span-full" currentLeases={currentLeases()} isLoading={loading()} />

          <Show when={draftLeases().length > 0}>
            <DraftLeases class="col-span-full" draftLeases={draftLeases()} isLoading={loading()} />
          </Show>

          <Show when={pastLeases().length > 0}>
            <PastLeases class="col-span-full" pastLeases={pastLeases()} isLoading={loading()} />
          </Show>
        </div>

        <Routes>
          <Route path="/start-eviction" component={LeaseStartEvictionModal} />
          <Route path="/end-lease" component={EndLeaseModal} />
          <Route path="/delete-lease" component={DeleteLeaseModal} />
          <Route path="/edit-unit" component={AddUnit} />
          <Route
            path="/add-service-bill"
            element={
              <Show when={lease()}>
                <AddServiceBillTypeModal currentLease={lease()} type="lease" />
              </Show>
            }
          />
        </Routes>
      </Show>
      <Show when={!leases()}>
        <Empty description={t('There are no leases under this unit')} />
      </Show>
    </>
  );
};

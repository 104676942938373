import { Route, Routes } from '@solidjs/router';
import { ChecksProvider } from '~/contexts/local';
import { ChecksTabRoutes } from './ChecksTabRoutes';

export const ChecksRoutes = () => {
  return (
    <ChecksProvider>
      <Routes>
        <Route path="*" component={ChecksTabRoutes} />
      </Routes>
    </ChecksProvider>
  );
};

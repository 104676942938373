import { useParams } from '@solidjs/router';
import { onMount } from 'solid-js';
import { createStore } from 'solid-js/store';
import { LeaseRepository } from '~/repositories/leaseRepository';
import { MAINTENANCE_REQUEST_URL, MaintenanceRequestRepository } from '~/repositories/maintenanceRequestRepository';
import { PropertyManagersRepository } from '~/repositories/propertyManagersRepository';
import { TenantRepository } from '~/repositories/tenantRepository';
import { UnitRepository } from '~/repositories/unitRepository';
import { contextCreator } from '~/utils/contextCreator';
import type { UploaderFile } from '~/components/common/Upload/Interface';
import type { LeaseListFilter } from '~/repositories/leaseRepository';
const propertyManagersRepo = new PropertyManagersRepository();
const unitRepo = new UnitRepository();
const leaseRepo = new LeaseRepository();
const tenantRepo = new TenantRepository();
const maintenanceRepo = new MaintenanceRequestRepository();

export enum LeaseOrTenant {
  Lease = 'lease',
  Tenant = 'tenant',
}

export const [AddMaintenanceModalContext, useAddOrEditMaintenanceModalContext] = contextCreator('addMaintenanceModal', () => {
  const { maintenanceRequestId } = useParams();
  const [store, setStore] = createStore<{
    vendors: MagicDoor.Api.HydratedVendorDto[];
    managers: MagicDoor.Api.PropertyManagerDto[];
    properties: MagicDoor.Api.PropertyDto[];
    error: string;
    units: MagicDoor.Api.HydratedUnitDto[];
    leases: MagicDoor.Api.HydratedLeaseDto[];
    tenants: MagicDoor.Api.HydratedTenantDto[];
    activeCategory?: string;
    isSubmiting?: boolean;

    isLeaseOrTenant?: LeaseOrTenant;

    isSelected?: unknown;
    maintenanceInfo?: MagicDoor.Api.MaintenanceRequestDto;
    newFiles?: UploaderFile[];
    uploadFileUrl: string;
    disableAi?: boolean;
    urgency?: MagicDoor.Api.Urgency;
  }>({
    vendors: [],
    error: '',
    managers: [],
    properties: [],
    units: [],
    leases: [],
    tenants: [],
    activeCategory: 'essential-information',
    isSubmiting: false,
    isLeaseOrTenant: LeaseOrTenant.Lease,
    uploadFileUrl: `${MAINTENANCE_REQUEST_URL}/${maintenanceRequestId}/files`,
  });

  async function getPropertyManagers() {
    try {
      const res = await propertyManagersRepo.getPropertyManagers();
      setStore({ managers: res });
    } catch (error: any) {
      setStore({ error: error.message });
    }
  }

  async function getUnits(propertyId: string) {
    if (!propertyId) return;
    if (propertyId) {
      setStore({ units: [] });
    }
    try {
      const res = await unitRepo.getUnits({ propertyId });
      setStore({ units: res.items });
    } catch (error: any) {
      setStore({ error: error.message });
    }
  }

  async function getLeases(filter: LeaseListFilter) {
    try {
      const res = await leaseRepo.getLeases(filter);
      setStore({ leases: res.items });
    } catch (error: any) {
      setStore({ error: error.message });
    }
  }

  const getTenants = async () => {
    try {
      const tenants = await tenantRepo.getTenants();
      setStore({ tenants: tenants.items });
    } catch (error: any) {
      setStore({ error: error.message });
    }
  };

  const getMaintenanceRequest = async (id: string) => {
    const res = await maintenanceRepo.getMaintenanceRequest(id);

    getUnits(res.propertyId);
    getLeases({ propertyId: res.propertyId, unitId: res.unitId });

    setStore({ maintenanceInfo: res });
    return res;
  };

  onMount(() => {
    getPropertyManagers();
    getTenants();
  });

  return {
    store,
    getLeases,
    getUnits,
    setActiveCategory: (categoryKey: string) => {
      setStore({ activeCategory: categoryKey });
    },
    setStore,
    getMaintenanceRequest,
  };
});

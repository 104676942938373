import { Show } from 'solid-js';
import { ToggleButton } from '~/components/common/Buttons';
import { cn } from '~/utils/classnames';

interface SettingToggleWithTextProps {
  id?: string;
  text: string;
  textClass?: string;
  class?: string;
  initialState?: boolean;
  onUpdate?: (updates: boolean) => void;
}

export const SettingToggleWithText = (props: SettingToggleWithTextProps) => {
  return (
    <div class={cn('flex w-full justify-between', props.class)}>
      <div class={cn('font-regular text-sm', props.textClass)}>{props.text}</div>
      <Show when={props.initialState !== undefined}>
        <ToggleButton initialState={props.initialState} onToggle={props.onUpdate} />
      </Show>
    </div>
  );
};

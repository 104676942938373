import { Show } from 'solid-js';
import { FormWrapper } from '~/components/common/BetterForm/Form';
import DisplayAddress from '~/components/common/DisplayAddress';
import { Empty } from '~/components/common/Empty';
import { ForWithGutter } from '~/components/common/Gutter';
import IconField from '~/components/common/IconField';
import { IconClock, IconContact, IconMail, IconMapPin, IconPhone, IconUserX } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { NewRentalApplicationProvider } from '~/contexts/local';
import { RentalHistoryModal } from '~/pages/properties/property-details/rental-application-details/edit-components/RentalHistoryModal';
import { emptyPlaceholder } from '~/utils/constant';
import { diffDates } from '~/utils/date';
import { validArr } from '~/utils/tool';

export const RentalHistory = () => {
  const { t } = useLocalization();
  const { applicationStore } = useRentalApplication();
  return (
    <FormWrapper>
      <NewRentalApplicationProvider>
        <Show when={validArr(applicationStore.application?.residentialHistory)} fallback={<Empty />}>
          <ForWithGutter each={applicationStore.application?.residentialHistory}>
            {(section) => (
              <div class="flex flex-wrap gap-y-6">
                <IconField class="grow-1 h-auto basis-full lg:basis-1/2" iconRounded name={t(`Current address`)} src={IconMapPin}>
                  <DisplayAddress class="w-fit break-words" address={section.address} />
                </IconField>
                <IconField
                  class="grow-1 h-auto w-full basis-full lg:basis-1/2"
                  iconRounded
                  src={IconClock}
                  name={t(`Duration of the stay`)}>
                  {diffDates(section.moveOutDate, section.moveInDate)}
                </IconField>
                <div class="flex w-full flex-wrap gap-y-6 rounded-lg bg-light-gray px-6 py-3">
                  <IconField
                    class="grow-1 h-auto w-full basis-full lg:basis-1/2"
                    iconRounded
                    src={IconUserX}
                    name={t(`Reason for leaving`)}>
                    {section.reasonForLeaving}
                  </IconField>
                  <IconField
                    class="grow-1 h-auto w-full basis-full lg:basis-1/2"
                    iconRounded
                    src={IconContact}
                    name={t(`Previous property manager`)}>
                    {section.landlordName}
                  </IconField>
                  <IconField
                    class="grow-1 h-auto w-full basis-full lg:basis-1/2"
                    iconRounded
                    src={IconMail}
                    name={t(`Phone number of property manager`)}>
                    {emptyPlaceholder}
                  </IconField>
                  <IconField
                    class="grow-1 h-auto w-full basis-full lg:basis-1/2"
                    iconRounded
                    src={IconPhone}
                    name={t(`Phone number of property manager`)}>
                    {section.landlordPhone}
                  </IconField>
                </div>
              </div>
            )}
          </ForWithGutter>
        </Show>
        <RentalHistoryModal />
      </NewRentalApplicationProvider>
    </FormWrapper>
  );
};

import { createMemo } from 'solid-js';
import { toast } from '~/components/ui';
import { createMagicDoorContext } from '~/contexts/utils';
import { runBooksRepository } from '~/repositories/runBooksRepository';
import { createMutation, createTriggerResource } from '~/utils/resource';

const repo = new runBooksRepository();

export const [RunBooksProvider, useRunBooks] = createMagicDoorContext('RunBooks', () => {
  const [runBook, getRunBook, runBookActions] = createTriggerResource(repo.getRunBookById.bind(repo));

  const [runBooks, getRunBooks, runBooksActions] = createTriggerResource(repo.getRunBooks.bind(repo));

  const changeRunbookStatus = createMutation(async (runbookId: string, enable: boolean) => {
    if (enable) {
      await repo.enableRunBook(runbookId);
    } else {
      await repo.disableRunBook(runbookId);
    }

    toast.success('Modify success');

    runBooksActions.refetch();
  });

  const aiRunbooks = createMemo(() => {
    return runBooks()?.items.filter((runbook) => runbook.publicRunBook);
  });

  const customRunbooks = createMemo(() => {
    return runBooks()?.items.filter((runbook) => !runbook.publicRunBook);
  });

  const deleteRunBook = createMutation(async (runbookId: string) => {
    await repo.deleteRunBook(runbookId);
    runBooksActions.refetch();
  });

  const addRunBook = createMutation(async (payload: MagicDoor.Api.CreateRunBookDto) => {
    await repo.addRunBook(payload);
    runBooksActions.refetch();
  });

  const updateRunBook = createMutation(async (runbookId: string, payload: MagicDoor.Api.UpdateRunBookDto) => {
    await repo.updateRunBook(runbookId, payload);
    runBooksActions.refetch();
  });

  return {
    runBook,
    getRunBook,
    runBookActions,

    runBooks: runBooks,
    getRunBooks,
    runBooksActions,

    changeRunbookStatus,

    aiRunbooks,
    customRunbooks,

    deleteRunBook,
    addRunBook,
    updateRunBook,
  };
});

import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

export const typeMap: Record<`${MagicDoor.Api.TenantAutoPayType}`, string> = {
  currentBalance: 'Current balance',
  fixedAmount: 'Fixed amount',
  postedBalance: 'Posted balance',
};

export const frequencyMap: Record<`${MagicDoor.Api.ChargeFrequency}`, string> = {
  daily: 'Daily',
  monthly: 'Monthly',
  monthly2XSplit: 'Twice a month',
  weekly: 'Weekly',
};

export const AutoPaymentType: Component<{ class?: string; entry: MagicDoor.Api.HydratedTenantAutoPayDto }> = (props) => {
  const { t } = useLocalization();
  return (
    <div class={cn('flex items-center gap-2', props.class)}>
      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="19" cy="19" r="19" fill="#FBF1FF" />
        <path
          d="M11.0007 11.7133H11.8359V27.9993H22.4255V11.7133H23.2607V10H11V11.7133H11.0007ZM16.5122 16.3567V15.9748C16.5122 15.7122 16.6972 15.5004 16.9234 15.5004C17.1497 15.5004 17.3346 15.7122 17.3346 15.9748V16.3991C18.4178 16.5831 19.1555 17.2415 19.3296 18.0728C19.3678 18.1387 19.3884 18.2136 19.3892 18.2902C19.3892 18.5583 19.1664 18.7757 18.8886 18.7757C18.6109 18.7757 18.3663 18.5472 18.3663 18.2791C18.2871 17.8631 17.9172 17.5228 17.3346 17.3804V19.7271C18.8236 19.8667 19.8004 20.7369 19.8004 21.8002C19.8004 22.8663 18.8236 23.7337 17.3346 23.8733V24.2692C17.3346 24.5317 17.1497 24.7407 16.9234 24.7407C16.6951 24.7407 16.5122 24.5289 16.5122 24.2692V23.8761C15.2061 23.7531 14.3133 23.0614 14.1121 22.1711C14.0742 22.1044 14.0796 22.0398 14.0796 21.9593C14.0796 21.6912 14.2889 21.4655 14.5856 21.4655C14.8823 21.4655 15.1492 21.6912 15.1492 21.9593C15.2448 22.428 15.7399 22.8017 16.5129 22.9024V20.5223C15.0327 20.4439 14.0471 19.5459 14.0471 18.4382C14.0471 17.3332 15.032 16.438 16.5129 16.3567H16.5122ZM18.7193 21.803C18.7193 21.262 18.1943 20.8071 17.3312 20.698V22.9079C18.197 22.7989 18.7193 22.3468 18.7193 21.803ZM23.5419 14.2864V28H28V17.029L23.5419 14.2864ZM26.6059 23.7143H24.9346V22.0009H26.6059V23.7143ZM26.6059 20.5723H24.9346V18.859H26.6059M16.5122 19.5625V17.3249C15.6112 17.3888 15.0483 17.8631 15.0483 18.4437C15.0483 19.0243 15.6119 19.4959 16.5122 19.5625Z"
          fill="#0065FF"
        />
      </svg>
      <div>
        <h4 class="text-sm text-title-gray">{props.entry.type && t(typeMap[props.entry.type])}</h4>
        <p class="text-xs text-text-level03">{props.entry.frequency && t(frequencyMap[props.entry.frequency])}</p>
      </div>
    </div>
  );
};

import { FormItem } from '~/components/common/BetterForm';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { useLocalization } from '~/contexts/global';
import { permissionToEnterTypes, priorityTypes } from '~/utils/constant';
import { useAddOrEditMaintenanceModalContext } from './Context';

export const StatusAndPriority = () => {
  const { t } = useLocalization();
  const { store } = useAddOrEditMaintenanceModalContext();

  return (
    <div class="flex flex-col gap-8">
      <div class="grid grid-cols-2 gap-4">
        <FormItem
          class="col-span-full lg:col-span-1"
          label={t(`Permission to enter`)}
          options={permissionToEnterTypes(t)}
          rules={[
            {
              required: true,
              message: t(`Please select permission to enter`),
            },
          ]}
          formFieldName="permissionToEnter"
          onChangeMethodName="onChange"
          component={DropdownMenu}
          placeholder={t('Please select')}
        />

        <FormItem
          class="col-span-full lg:col-span-1"
          label={t(`Status`)}
          rules={[{ required: true, message: t(`Please select status`) }]}
          formFieldName="status"
          options={[
            { label: t('Pending'), value: 'pending', color: 'text-light-green' },
            { label: t('In progress'), value: 'inProgress', color: 'text-essential-colour' },
            { label: t('Closed'), value: 'closed', color: 'text-light-gray-level01' },
          ]}
          renderOptionClass={(option: any) => `bg-gray-100 rounded-full px-4 py-2 ${option.color}`}
          onChangeMethodName="onChange"
          component={DropdownMenu}
          placeholder={t('Please select')}
        />

        <FormItem class="col-span-full lg:col-span-1" label={t(`Due date`)} formFieldName="due" component={DueDateInputField} />

        <FormItem
          class="col-span-full lg:col-span-1"
          label={t(`Priority`)}
          rules={[{ required: true, message: t('Please select priority') }]}
          options={priorityTypes}
          formFieldName="urgency"
          onChangeMethodName="onChange"
          component={DropdownMenu}
          placeholder={t('Please select priority')}
          value={store.urgency}
        />
      </div>
    </div>
  );
};

import { BankAccountsListProvider, RentPaymentProcessingFeesSettingsProvider } from '~/contexts/local';
import { RentPaymentProcessingFeesSettings } from './RentPaymentProcessingFeesSettings';

export const RentPaymentProcessingFeesSettingsPage = () => {
  return (
    <RentPaymentProcessingFeesSettingsProvider>
      <BankAccountsListProvider>
        <RentPaymentProcessingFeesSettings />
      </BankAccountsListProvider>
    </RentPaymentProcessingFeesSettingsProvider>
  );
};

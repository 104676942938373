import { Show } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { LanguageSelect } from '~/components/languages';
import { useLocalization } from '~/contexts/global';
import type { Component, JSX } from 'solid-js';
import type { LabeledGroupProps } from '~/components/common/Inputs';

interface LabeledVendorInformationInputProps extends LabeledGroupProps {
  value: MagicDoor.VendorInformationModel;
  onInput?: (value: MagicDoor.VendorInformationModel) => void;
  onClick?: (isMouseDown: boolean) => void;
  nameError: string;
  header?: JSX.Element;
}

const LabeledVendorInformationInput: Component<LabeledVendorInformationInputProps> = (props) => {
  const { t } = useLocalization();
  return (
    <div id="vendor-information">
      <div class="sticky top-0 z-10 border-b bg-white py-3 text-lg font-semibold text-text-level01">{t('Vendor information')}</div>
      <Show when={props.header}>
        <div class="pb-3 pt-8">{props.header}</div>
      </Show>
      <div class="flex flex-col gap-5 py-6">
        <div class="flex gap-5">
          <div class="w-1/2">
            <FormItem
              label={t('Name')}
              placeholder={t('Please enter the supplier name')}
              onClick={props.onClick}
              component={LabeledTextInput}
              rules={[
                { required: true, message: t('Please enter the supplier name') },
                {
                  validator: (value: string) => !value || value.length < 201,
                  message: t('{name} must be {length} characters or less', { name: t('Name'), length: '200' }),
                },
              ]}
              formFieldName="name"
            />
          </div>
          <div class="w-1/2" onMouseDown={() => props.onClick?.(true)} onMouseUp={() => props.onClick?.(false)}>
            <LanguageSelect
              label={t('Language')}
              value={props.value.language}
              onChange={(selectedValue) => {
                props.onInput?.({ ...props.value, language: selectedValue });
              }}
              placeholder={t('Select language')}
            />
          </div>
        </div>
        <LabeledTextInput
          label={t('Memo')}
          placeholder={t('You can add memos to suppliers')}
          value={props.value.memo}
          onInput={(input: string | undefined) => props.onInput?.({ ...props.value, memo: input })}
          onClick={props.onClick}
          maxlength={1000}
        />
      </div>
    </div>
  );
};

export default LabeledVendorInformationInput;

import { useNavigate, useSearchParams } from '@solidjs/router';
import { createEffect, Show, createMemo, onMount, createSignal } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { PrintButton } from '~/components/common/Buttons';
import { DatePicker, IconLoader, Select } from '~/components/ui';
import { useLocalization, useProperties } from '~/contexts/global';
import { useReports } from '~/contexts/local';
import { ReportTable, parseColumns, parseLines } from './components/Report';
import type { LineItem } from './components/Report';
import type { Component } from 'solid-js';

const CashFlowReport: Component<{ class?: string; report?: MagicDoor.Api.CashFlowReportDto }> = (props) => {
  const { t } = useLocalization();

  const lines = () => {
    if (props.report == null) return [];

    const res: LineItem[] = [];

    res.push({ label: t('Income'), level: 0 });
    res.push(...parseLines(props.report.income));
    res.push({ label: t('Total income'), type: 'total', level: 0, values: props.report.incomeTotal });

    res.push({ label: t('Expenses'), level: 0 });
    res.push(...parseLines(props.report.expenses));
    res.push({ label: t('Total expenses'), type: 'total', level: 0, values: props.report.expensesTotal });

    res.push({ type: 'empty' });

    res.push({ label: t('Net income'), level: 0, values: props.report.netIncome });

    res.push({ type: 'empty' });

    res.push({ label: t('Other items'), level: 0 });
    res.push(...parseLines(props.report.otherItems));
    res.push({ label: t('Total other items'), type: 'total', level: 0, values: props.report.netOtherItems });

    res.push({ type: 'empty' });

    res.push({ label: t('Beginning cash'), level: 0, values: props.report.beginningCash });
    res.push({ label: t('Cash flow'), level: 0, values: props.report.cashFlow });
    res.push({ label: t('Ending cash'), level: 0, values: props.report.endingCash });

    return res;
  };

  return <ReportTable label={t('Account name')} lines={lines()} columns={parseColumns(props.report?.groupHeaders)} />;
};

const allowedGroupBy: `${MagicDoor.Api.CashFlowGroupBy}`[] = ['total', 'portfolio', 'property', 'year', 'quarter', 'month'];
const isGroupBy = (input: unknown): input is `${MagicDoor.Api.CashFlowGroupBy}` =>
  input != null && allowedGroupBy.includes(input as `${MagicDoor.Api.CashFlowGroupBy}`);

export const CashFlowReportPage = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const [printContainer, setPrintContainer] = createSignal<HTMLDivElement>();

  const [searchParams, setSearchParams] = useSearchParams<{
    start: string;
    end: string;
    propertyIds: string;
    groupBy: `${MagicDoor.Api.CashFlowGroupBy}`;
  }>();

  const { properties } = useProperties();
  const { cashFlowReport, getCashFlowReport, dealMultipleFilter } = useReports();
  const [routeLevel, setRouteLevel] = createSignal<number>(-1);

  const breadcrumbItems = createMemo(() => [{ label: t('Reports'), link: '/reports' }, { label: t('Cash flow') }]);

  onMount(() => {
    setSearchParams({
      start: searchParams.start ?? new Date(Date.now() - 1000 * 60 * 60 * 24 * 30).toISOString().split('T')[0],
      end: searchParams.end ?? new Date().toISOString().split('T')[0],
      groupBy: isGroupBy(searchParams.groupBy) ? searchParams.groupBy : 'total',
    });
  });

  const handlePropertyChange = (value: string[], current: string) => {
    value = dealMultipleFilter(value, current);

    if (value.includes('All')) {
      setSearchParams({ propertyIds: '' });
    } else {
      setSearchParams({ propertyIds: value.toString() });
    }
  };

  createEffect(() => {
    if (!searchParams.start || !searchParams.end || !searchParams.groupBy) return;
    if (!searchParams.propertyIds && properties.loading) return;
    getCashFlowReport({
      dateRange: { start: searchParams.start, end: searchParams.end },
      propertyIds: searchParams.propertyIds?.split(',') ?? properties()?.map((item) => item.id) ?? [],
      groupBy: searchParams.groupBy,
    });
    setRouteLevel((prevLevel) => prevLevel - 1);
  });

  const printHeader = createMemo(() => {
    const property = searchParams.propertyIds || 'All';
    const groupBy = searchParams.groupBy;
    const start = searchParams.start;
    const end = searchParams.end;
    return (
      <div>
        <div class="flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Cash flow report')}</h1>
        </div>
        <div class="my-6 flex flex-wrap items-center gap-4">
          <div>
            {t('Property')} : {property}
          </div>
          <div>
            {t('Group By')} : {groupBy}
          </div>
          <div>
            {t('Date range start')} : {start}
          </div>
          <div>
            {t('Date range end')} : {end}
          </div>
        </div>
      </div>
    );
  });

  const handleFillDatePicker = (groupBy: string, type?: string, value?: string | null) => {
    const date = value ? new Date(value) : new Date();
    let startDate = searchParams.start;
    let endDate = searchParams.end;
    const day = groupBy === 'year' ? 365 : groupBy === 'quarter' ? 90 : 30;
    if (type === 'start') {
      startDate = value || '';
      if (value && searchParams.end <= value) {
        date.setDate(date.getDate() + day);
        endDate = date.toISOString().split('T')[0];
      }
    }
    if (type === 'end') {
      endDate = value || '';
      if (value && searchParams.start >= value) {
        date.setDate(date.getDate() - day);
        startDate = date.toISOString().split('T')[0];
      }
    }
    if (!type && !value) {
      const today = new Date();
      endDate = today.toISOString().split('T')[0];
      today.setDate(today.getDate() - day);
      startDate = today.toISOString().split('T')[0];
    }
    setSearchParams({ ...searchParams, start: startDate, end: endDate, groupBy });
  };

  return (
    <>
      <Breadcrumb backLink={() => navigate(routeLevel())} items={breadcrumbItems()} />

      <div class="m-8 rounded-lg border border-partingline bg-white p-8">
        <div class="flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Cash flow report')}</h1>

          <PrintButton
            // disabled={cashFlowReport.loading || properties.loading}
            printContainer={printContainer()!}
            printWrapClass="w-auto"
            isReport={true}
            printHeader={printHeader() as Element}
            orientation={['property', 'protfolio'].includes(searchParams.groupBy) ? 'landscape' : 'portrait'}
            extraStyle="zoom: 0.5"
          />
        </div>
        <div class="my-6 flex flex-wrap items-center gap-3">
          <Select
            class="min-w-64 grow"
            placeholder={t('Property')}
            options={[
              { label: t('All'), value: 'All' },
              ...(properties()?.map((item) => ({ label: item.name as string, value: item.id })) ?? []),
            ]}
            value={searchParams.propertyIds ? searchParams.propertyIds.split(',') : ['All']}
            onChange={handlePropertyChange}
            multiple
          />
          <Select
            class="min-w-64 grow"
            placeholder={t('Group By')}
            options={[
              { label: t('Total'), value: 'total' },
              { label: t('Portfolio'), value: 'portfolio' },
              { label: t('Property'), value: 'property' },
              { label: t('Year'), value: 'year' },
              { label: t('Quarter'), value: 'quarter' },
              { label: t('Month'), value: 'month' },
            ]}
            value={searchParams.groupBy}
            onChange={(value) => {
              handleFillDatePicker(value);
            }}
          />
          <DatePicker
            class="min-w-64 grow"
            placeholder={t('Date range start')}
            value={searchParams.start}
            onChange={(value) => {
              handleFillDatePicker(searchParams.groupBy, 'start', value);
            }}
          />
          <DatePicker
            class="min-w-64 grow"
            placeholder={t('Date range end')}
            value={searchParams.end}
            onChange={(value) => {
              handleFillDatePicker(searchParams.groupBy, 'end', value);
            }}
          />
        </div>
        <div ref={(e) => setPrintContainer(e)}>
          <Show when={cashFlowReport.loading || properties.loading} fallback={<CashFlowReport report={cashFlowReport()} />}>
            <IconLoader class="mx-auto my-56 animate-spin" />
          </Show>
        </div>
      </div>
    </>
  );
};

import { onMount, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconPrint from '~/assets/images/common/print.svg?component-solid';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import { handlePrint } from '~/components/checks/PrintCheck';
import { Button } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import TextOverflow from '~/components/common/TextOverflow';
import { TransactionStatus } from '~/components/transactions';
import { IconSquarePen, ListView } from '~/components/ui';
import { useLocalization, usePayOuts } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import { dateFormat } from '~/utils/date';
import type { DropdownAction } from '~/components/ui';
import type { HydratedCheckDto } from '~/swagger/Api';

export const PayOutsTab = () => {
  const { t } = useLocalization();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('ManagementFeeListView');
  const { filtered, setFilter, deletePayOut, runDate } = usePayOuts();

  onMount(() => setFilter({ page: page(), pageSize: pageSize() }));

  return (
    <div class="relative">
      <ListView
        onChange={({ page, pageSize }) => {
          setPage(page);
          setPageSize(pageSize);
          setFilter({ page, pageSize });
        }}
        title={
          <span class="flex items-center gap-2">
            <span>{t('Pay outs')}</span>
            <span class="text-xs text-text-level03">
              ({t('Last run')}: {dateFormat(undefined, runDate()?.lastRun)})
            </span>
          </span>
        }
        totalPages={filtered()?.totalPages}
        page={filtered()?.currentPage}
        pageSize={filtered()?.pageSize}
        data={filtered()?.items}
        loading={filtered.loading}
        skeletonSize={10}
        titleActions={
          <div class="flex gap-2">
            <Button href={'add'} class="font-medium capitalize">
              <IconPlus class="size-5 text-white" />
              {t('Add payout')}
            </Button>
            <Button class="text-sm" href="calculate" variant="outlined">
              <IconSquarePen class="size-4" />
              {t('Calculate payout')}
            </Button>
          </div>
        }
        columns={[
          {
            title: t('Date'),
            render: (item) => <div>{dateFormat(undefined, item.transaction?.created)}</div>,
          },
          {
            title: t('Status'),
            render: (item) => <TransactionStatus status={item.transaction?.status as MagicDoor.Api.TransactionStatus} />,
          },
          {
            title: t('Memo'),
            render: (item) => (
              <div>
                <Show when={item.transaction?.memo} fallback={<span class="text-xs text-text-level03">--</span>}>
                  <TextOverflow text={item.transaction?.memo as string} />
                </Show>
              </div>
            ),
          },
          {
            title: t('Amount'),
            headerClass: 'text-right w-36',
            class: 'text-right',
            render: (item) => <CnMoney color="normal" money={item.transaction?.amount} />,
          },
        ]}
        actions={(item) =>
          [
            item.check && {
              label: t('Re-print'),
              icon: IconPrint,
              onClick: () => handlePrint(item.check as HydratedCheckDto),
            },
            {
              label: t('Delete'),
              icon: IconDelete,
              onClick: () => item.transaction?.id && deletePayOut(item.transaction?.id),
            },
          ].filter(Boolean) as DropdownAction[]
        }
      />
    </div>
  );
};

import { useNavigate, useParams } from '@solidjs/router';
import { Show, createEffect } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Panel } from '~/components/common/Panels';
import { IconLoader } from '~/components/ui';
import { useLocalization, useVendors } from '~/contexts/global';
import EditVendor from '~/pages/vendors/components/EditVendor';

const EditVendorPage = () => {
  const navigate = useNavigate();
  const params = useParams<{ vendorId: `${number}` }>();
  const { current, setCurrentId } = useVendors();

  const { t } = useLocalization();

  const breadcrumbItems = () => [
    { label: t('Vendor'), link: '/maintenance/vendors' },
    { label: current()?.name || t('Loading'), link: `/maintenance/vendors/${current()?.id}` },
    { label: t('Edit') },
  ];

  createEffect(() => setCurrentId(params.vendorId));

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <Show
        when={!current.loading}
        fallback={
          <Panel class="m-8 flex h-section1 flex-col items-center justify-center gap-2 border-0">
            <IconLoader class="animate-spin text-title-gray" />
            {t('Loading')}...
          </Panel>
        }>
        <EditVendor onClose={() => navigate(`/maintenance/vendors/${current()?.id}`)} />
      </Show>
    </>
  );
};

export default EditVendorPage;

import { createMagicDoorContext } from '~/contexts/utils';
import { ManagementFeesRepository } from '~/repositories/managementFeesRepository';
import { createMutation, createTriggerResource } from '~/utils/resource';
import type { ManagementFeeListFilter } from '~/repositories/managementFeesRepository';

const managementFeesRepo = new ManagementFeesRepository();

export const [ManagementFeeProvider, useManagementFee] = createMagicDoorContext('ManagementFee', () => {
  const [filtered, setFilter, actions] = createTriggerResource((filter: ManagementFeeListFilter) =>
    managementFeesRepo.getManagementFees(filter)
  );

  const addManagementFee = createMutation(async (payload: MagicDoor.Api.AddManagementFeesDto) => {
    const response = await managementFeesRepo.addManagementFee(payload);
    actions.refetch();
    return response;
  });

  const getManagementFee = createMutation(async (id: string) => {
    const response = await managementFeesRepo.getManagementFee(id);
    return response;
  });

  const deleteManagementFee = createMutation(async (managementFeeId: string) => {
    await managementFeesRepo.deleteManagementFee(managementFeeId);
    actions.refetch();
  });

  return {
    filtered: filtered,
    setFilter: (filter: ManagementFeeListFilter) => setFilter(filter),
    addManagementFee,
    deleteManagementFee,
    getManagementFee,
  };
});

import { createMagicDoorContext } from '~/contexts/utils';
import { ReportRepository } from '~/repositories/reportRepository';
import { createTriggerResource } from '~/utils/resource';

const repo = new ReportRepository();

export const [ReportsProvider, useReports] = createMagicDoorContext('Reports', () => {
  const [cashFlowReport, getCashFlowReport] = createTriggerResource(repo.getCashFlowReport.bind(repo));
  const [generalLedgerReport, getGeneralLedgerReport] = createTriggerResource(repo.getGeneralLedgerReport.bind(repo));
  const [balanceSheetReport, getBalanceSheetReport] = createTriggerResource(repo.getBalanceSheetReport.bind(repo));
  const [incomeStatementReport, getIncomeStatementReport] = createTriggerResource(repo.getIncomeStatementReport.bind(repo));
  const [ownerStatementReport, getOwnerStatementReport] = createTriggerResource(repo.getOwnerStatementReport.bind(repo));
  const [propertyDirectoryReport, getPropertyDirectoryReport] = createTriggerResource(repo.getPropertyDirectoryReport.bind(repo));
  const [ownerDirectroyReport, getOwnerDirectoryReport] = createTriggerResource(repo.getOwnerDirectoryReport.bind(repo));
  const [tenantDirectoryReport, getTenantDirectoryReport] = createTriggerResource(repo.getTenantDirectoryReport.bind(repo));
  const [bankAccountActivityReport, getBankAccountActivityReport] = createTriggerResource(repo.getBankAccountActivityReport.bind(repo));
  const [leaseUnpaidBillsReport, getLeaseUnpaidBillsReport] = createTriggerResource(repo.getLeaseUnpaidBillsReport.bind(repo));

  const dealMultipleFilter = (data: string[], current: string) => {
    if (current === 'All') return [];

    if (data?.includes('All')) return data.filter((item) => item !== 'All');
    return data;
  };

  const getFilterDisplay = (
    propertyIds?: string,
    portfolioIds?: string,
    properties?: MagicDoor.Api.HydratedPropertyDto[],
    portfolios?: MagicDoor.Api.HydratedPortfolioDto[]
  ) => {
    let propertiesStr = '';
    let portfoliosStr = '';

    if (propertyIds) {
      const property = properties?.reduce<string[]>((prev, next) => {
        if (propertyIds?.includes(next.id) && next.name) {
          prev.push(next.name);
        }
        return prev;
      }, []);
      propertiesStr = property?.join(', ') ?? '';
    }

    if (portfolioIds) {
      const portfolio = portfolios?.reduce<string[]>((prev, next) => {
        if (portfolioIds?.includes(next.id) && next.name) {
          prev.push(next.name);
        }
        return prev;
      }, []);
      portfoliosStr = portfolio?.join(', ') ?? '';
    }
    return { propertiesStr, portfoliosStr };
  };

  const [rentRollReport, getRentRollReport] = createTriggerResource(repo.getRentRollReport.bind(repo));

  return {
    cashFlowReport,
    getCashFlowReport,
    generalLedgerReport,
    getGeneralLedgerReport,
    balanceSheetReport,
    getBalanceSheetReport,
    incomeStatementReport,
    getIncomeStatementReport,
    dealMultipleFilter,
    ownerStatementReport,
    getOwnerStatementReport,
    rentRollReport,
    getRentRollReport,
    propertyDirectoryReport,
    getPropertyDirectoryReport,
    ownerDirectroyReport,
    getOwnerDirectoryReport,
    tenantDirectoryReport,
    getTenantDirectoryReport,
    bankAccountActivityReport,
    getBankAccountActivityReport,
    leaseUnpaidBillsReport,
    getLeaseUnpaidBillsReport,
    getFilterDisplay,
  };
});

import { useNavigate } from '@solidjs/router';
import { createSignal, For, Show } from 'solid-js';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import IconTopLeftArrow from '~/assets/images/common/topLeftArrow.png';
import IconAddFile from '~/assets/images/leases/addFile.svg';
import IconAddFolder from '~/assets/images/leases/addFolder.svg';
import IconBlankFile from '~/assets/images/leases/blankFile.svg';
import IconLeaseTemplateFolder from '~/assets/images/leases/leaseTemplateFolder.png';
import { LinkButton } from '~/components/common/Buttons';
import { Panel } from '~/components/common/Panels';
import { TintedSVG } from '~/components/common/TintedSvg';
import { FileUploadModal } from '~/components/file-attachments';
import { IconCircleEllipsis, DragAndDrop, DropdownActions, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLeaseTemplate, useLeaseTemplateCategory } from '~/contexts/local';
import { stringToColor } from '~/utils/strings';

export const ListLeaseTemplate = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const [showUploadModal, setShowUploadModal] = createSignal<boolean>(false);
  const [currentCategoryId, setCurrentCategoryId] = createSignal<string | undefined>();
  const [droppedFile, setDroppedFile] = createSignal<File | undefined>();

  const { leasetemplatecategories } = useLeaseTemplateCategory();
  const { leasetemplates, addLeaseTemplate, deleteLeaseTemplate, setCurrentId } = useLeaseTemplate();

  const handleFileUpload = async (categoryId: string, files: File[], name: string, description?: string) => {
    for (const file of files) {
      await addLeaseTemplate(categoryId, file, name, description ?? '');
    }
  };

  const handleDropFiles = (files: FileList) => {
    const validFile = files[0];

    if (validFile.type !== 'application/pdf') {
      return toast.error(t('Only PDF files are allowed'));
    }
    if (validFile.size > 100 * 1024 * 1024) {
      return toast.error(t('Attachment file should not exceed 100MB'));
    }

    setDroppedFile(() => validFile);
    setShowUploadModal(true);
  };

  const onDeleteTemplate = async (id: string) => {
    await deleteLeaseTemplate(id);
    toast.success(t('{name} has been deleted successfully', { name: 'Template' }));
  };

  const onTemplateClick = (id: string) => {
    setCurrentId(id);
    navigate(`/leasing/lease-templates/${id}`);
  };

  return (
    <div class="flex flex-col gap-4 p-6">
      <div class="w-full">
        <Panel class="flex w-full items-center justify-between px-2 py-4 md:px-6">
          <div>
            <span class="font-medium text-essential-colour">{leasetemplates()?.length} </span>
            {t('Templates in total')}
          </div>
          <div class="flex gap-2">
            <LinkButton href="/leasing/lease-templates/customize-template" class="font-medium" noScroll variant="outlined">
              <IconEdit class="text-primary" />
              <div class="hidden md:flex">{t('Customize template')}</div>
            </LinkButton>
            <LinkButton href="/leasing/lease-templates/add-category" class="font-medium" noScroll variant="outlined">
              <img src={IconAddFolder} />
              <div class="hidden md:flex">{t('Add new category')}</div>
            </LinkButton>
          </div>
        </Panel>
      </div>
      <div class="-mx-2 flex flex-wrap">
        <For each={leasetemplatecategories() || []}>
          {(category) => (
            <div class="mb-4 w-full px-2 md:w-1/2">
              <Panel class="h-[344px]">
                <div class="flex items-center justify-between bg-gradient-to-br from-[#F6E1FF] to-[#FDF9FF] px-5 py-3">
                  <div class="flex items-center gap-3">
                    <img src={IconLeaseTemplateFolder} />
                    <h2 class="truncate font-semibold text-title-gray">{category.name}</h2>
                  </div>
                  <div>
                    <LinkButton
                      href={`/leasing/lease-templates/edit-category/${category.id}`}
                      class="border-link/50 p-2 font-medium"
                      noScroll
                      variant="outlined">
                      <IconEdit class="text-link" />
                    </LinkButton>
                  </div>
                </div>
                <div
                  class="thinscroll grid h-[270px] gap-4 overflow-y-auto p-4"
                  style={{ 'grid-template-columns': 'repeat(auto-fit, minmax(200px, 1fr))' }}>
                  <DragAndDrop
                    class="flex h-28 cursor-pointer items-center justify-center rounded-md border-2 border-dashed border-text-level03 bg-[#FBFBFD] p-6"
                    onDropFiles={(files) => {
                      setCurrentCategoryId(category.id);
                      handleDropFiles(files);
                    }}
                    accept="application/pdf">
                    <img src={IconAddFile} />
                  </DragAndDrop>
                  <Show when={leasetemplates()?.filter((template) => template.leaseTemplateCategoryId === category.id).length === 0}>
                    <div class="col-span-full -mt-14 flex h-fit select-none items-center justify-center gap-1 text-center normal-case text-gray-500">
                      <img src={IconTopLeftArrow} class="mb-4 size-5" />
                      {t('No templates available, start by adding a new template here')}!
                    </div>
                  </Show>
                  <For each={leasetemplates()?.filter((template) => template.leaseTemplateCategoryId === category.id) || []}>
                    {(template) => (
                      <div
                        class=" flex h-28 w-full cursor-pointer flex-col justify-center rounded-md border bg-[#FBFBFD] p-4 hover:bg-input"
                        onClick={() => onTemplateClick(template.id)}>
                        <div class="flex w-full justify-between">
                          <div class="flex w-3/4 flex-col gap-2">
                            <div class="relative flex size-10 flex-col">
                              <TintedSVG svg={IconBlankFile} color={stringToColor(template.name)} opacity={0.5} class="size-10" />
                              <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-lg font-semibold text-white/80">
                                {template.name.charAt(0)}
                              </div>
                            </div>
                            <h3 class="truncate text-text-level02">{template.name}</h3>
                          </div>

                          <div onClick={(e) => e.stopPropagation()}>
                            <DropdownActions
                              children={<IconCircleEllipsis class="size-5" />}
                              actions={[
                                {
                                  label: t('Delete template'),
                                  onClick: () => onDeleteTemplate(template.id),
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </For>
                </div>
              </Panel>
            </div>
          )}
        </For>
      </div>

      <FileUploadModal
        entryId={currentCategoryId()}
        open={showUploadModal()}
        onClose={() => {
          setShowUploadModal(false);
          setDroppedFile(undefined);
        }}
        onConfirm={handleFileUpload}
        allowPdfOnly={true}
        allowNameInput={true}
        file={droppedFile()}
        accept="application/pdf"
      />
    </div>
  );
};
